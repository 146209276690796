import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, fonts, inputColors } from '../styles/variables';
import { timesPaleGrey } from '../assets';
import { LoadingPlaceholder } from '../styles/library/fontStyles';
import { Initials } from '../styles/library/imageStyles';
import { DateFormatUTC } from '../utils';

const AdvisorInfoBox = ({
  loaded = false,
  advisor,
  showHouseholdDetails = false,
  showClose,
  closeOnClick,
}) => {
  return (
    <AdvisorInfoContainer>
      <AdvisorAvatarContainer>
        {loaded ? (
          advisor.avatar ? (
            <AdvisorAvatar
              src={advisor.avatar}
              alt={advisor.name}
              data-image="advisor-avatar"
            />
          ) : (
            <Initials fontSize="22px">{advisor.initials}</Initials>
          )
        ) : null}
      </AdvisorAvatarContainer>
      <AdvisorTextContainer>
        <TopRow>
          <AdvisorName>
            {loaded ? (
              advisor.name
            ) : (
              <LoadingPlaceholder width="175px" height="20px" margin="0" />
            )}
          </AdvisorName>
          {showHouseholdDetails && (
            <HouseholdsLabel>
              {loaded ? (
                'Households'
              ) : (
                <LoadingPlaceholder width="60px" height="12px" margin="0" />
              )}
            </HouseholdsLabel>
          )}
        </TopRow>
        <MiddleRow marginTop={showHouseholdDetails ? '0' : '8px'}>
          <AdvisorEmailContainer>
            <AdvisorEmail>
              {loaded ? (
                advisor.email
              ) : (
                <LoadingPlaceholder width="150px" height="16px" margin="0" />
              )}
            </AdvisorEmail>
          </AdvisorEmailContainer>
          {showHouseholdDetails && (
            <HouseholdCount>
              {loaded ? (
                advisor.household_count?.total
              ) : (
                <LoadingPlaceholder
                  width="40px"
                  height="27.5px"
                  margin="3px 0 5px 0"
                />
              )}
            </HouseholdCount>
          )}
        </MiddleRow>
        <BottomRow marginTop={showHouseholdDetails ? '-3px' : '4px'}>
          <AdvisorLastLogin>
            {loaded ? (
              DateFormatUTC(advisor.last_login, `MMM D, YYYY h:mma`, true)
            ) : (
              <LoadingPlaceholder width="105px" height="14px" margin="0" />
            )}
          </AdvisorLastLogin>
          {showHouseholdDetails && (
            <HouseholdCountList>
              {loaded ? (
                `${advisor.household_count?.client} Client,
              ${advisor.household_count?.prospect} Prospect,
              ${advisor.household_count?.sample} Sample,
              ${advisor.household_count?.private} Private`
              ) : (
                <LoadingPlaceholder width="150px" height="14px" margin="0" />
              )}
            </HouseholdCountList>
          )}
        </BottomRow>
      </AdvisorTextContainer>
      {showClose && loaded && (
        <RemoveAdvisor
          src={timesPaleGrey}
          alt="close"
          onClick={closeOnClick ? () => closeOnClick() : null}
        />
      )}
    </AdvisorInfoContainer>
  );
};

const AdvisorInfoContainer = styled.div`
  padding: 15px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 6px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 5px 0;
  position: relative;
`;

const RemoveAdvisor = styled.img`
  position: absolute;
  right: 6px;
  top: 7px;
  width: 8px;
  height: 8px;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
`;

const AdvisorAvatarContainer = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background: ${colors.lightGrey};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 0 0 auto;
`;

const AdvisorAvatar = styled.img`
  border-radius: 100%;
  height: 60px;
  width: 60px;
`;

const AdvisorTextContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 auto;
  margin-left: 15px;
`;

const AdvisorName = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 17px;
  flex: 1 1 auto;
  text-align: left;
  margin-bottom: -5px;
`;

const AdvisorEmailContainer = styled.p`
  font-size: 12px;
  color: ${colors.lightBlue};
  flex: 1 1 auto;
  text-align: left;
  display: flex;
  margin-top: 0px;
`;

const AdvisorEmail = styled.span`
  word-break: keep-all;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

const AdvisorLastLogin = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
  flex: 1 1 auto;
  text-align: left;
`;

const HouseholdsLabel = styled.p`
  font-size: 9px;
  font-weight: ${fonts.bold};
  text-transform: uppercase;
  flex: 0 1 auto;
  text-align: right;
  color: ${inputColors.label};
`;

const HouseholdCount = styled.p`
  font-size: 24px;
  font-weight: ${fonts.semiBold};
  flex: 0 1 auto;
  text-align: right;
  margin: -2px 0 2px 0;
`;

const HouseholdCountList = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
  flex: 0 1 auto;
  text-align: right;
`;

const TopRow = styled.div`
  display: flex;
  align-items: flex-end;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
`;

const MiddleRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;

AdvisorInfoBox.propTypes = {
  loaded: PropTypes.bool,
  advisor: PropTypes.object.isRequired,
  showHouseholdDetails: PropTypes.bool,
  showClose: PropTypes.bool,
  closeOnClick: PropTypes.func,
};

export default AdvisorInfoBox;
