import styled, { ThemeProvider } from 'styled-components';
import { colors, maxDevice } from '../styles/variables';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from '../styles/library/fontStyles';

const Notification = ({
  notification,
  fullWidth,
  onClick,
  loading = false,
  width,
}) => {
  let fullWidthStyle = {
    maxWidth: '100%',
    mobileMaxWidth: '100%',
    borderBottom: `1px solid ${colors.lightGrey}`,
    padding: '20px 15px 15px 15px',
    fontSize: '14px',
  };
  let smallStyle = {
    maxWidth: '100%',
    mobileMaxWidth: '210px',
    borderBottom: 'none',
    padding: '10px 15px',
    fontSize: '12px',
  };

  return (
    <ThemeProvider theme={fullWidth ? fullWidthStyle : smallStyle}>
      {loading ? (
        <LoadingNotificationContainer>
          <NotificationLeftContent>
            <LoadingPlaceholder width={width} margin="0 0 3px 0" />
            <NotificationDatePlaceholder>
              <LoadingPlaceholder
                margin="0 8px 0 0"
                height="16px"
                width="80px"
              />
              <LoadingPlaceholder margin="0" height="16px" width="150px" />
            </NotificationDatePlaceholder>
          </NotificationLeftContent>
          <NotificationRightContent>
            <LoadingPlaceholder
              round="true"
              height="15px"
              width="15px"
              margin="0"
            />
          </NotificationRightContent>
        </LoadingNotificationContainer>
      ) : (
        <NotificationContainer onClick={() => onClick(notification)}>
          <NotificationLeftContent>
            <NotificationMessage
              dangerouslySetInnerHTML={{ __html: notification.html.detail }}
            ></NotificationMessage>
            <NotificationDate>
              {notification.relative}
              {fullWidth && (
                <NotificationFullDate>
                  {' '}
                  - {notification.date}
                </NotificationFullDate>
              )}
            </NotificationDate>
          </NotificationLeftContent>
          <NotificationRightContent>
            <svg height="20px" width="20px">
              {notification.is_unread ? (
                <circle
                  cx="10"
                  cy="10"
                  r="5"
                  stroke={colors.pink}
                  strokeWidth="1"
                  fill={colors.pink}
                />
              ) : (
                <circle
                  cx="10"
                  cy="10"
                  r="5"
                  stroke={colors.pink}
                  strokeWidth="1"
                  fill="none"
                />
              )}
            </svg>
          </NotificationRightContent>
        </NotificationContainer>
      )}
    </ThemeProvider>
  );
};

Notification.propTypes = {
  notification: PropTypes.object,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  width: PropTypes.string,
};

const NotificationContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: flex-start;
  transition: all 0.1s ease;
  margin: -4px -13px;
  width: 100%;
  border-bottom: ${(props) => props.theme.borderBottom};
  padding: ${(props) => props.theme.padding};
  &:hover {
    background: ${colors.lightGrey};
    transform: scale(1.01);
    cursor: pointer;
  }
`;
const LoadingNotificationContainer = styled(NotificationContainer)`
  &:hover {
    background: none;
    transform: scale(1);
    cursor: default;
  }
`;

const NotificationLeftContent = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  flex: 1 1 auto;
`;

const NotificationRightContent = styled.div`
  flex: 0 0 auto;
  width: 10px;
  padding-right: 10px;
`;

const NotificationMessage = styled.p`
  @media ${maxDevice.tablet} {
    max-width: ${(props) => props.theme.mobileMaxWidth};
    margin-right: 5px;
  }
  max-width: ${(props) => props.theme.maxWidth};
  font-size: ${(props) => props.theme.fontSize};
  white-space: wrap;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  margin-right: 10px;
  a {
    color: ${colors.lightBlue};
    display: inline-block;
    margin: 0 0.1px;
    &:hover {
      display: inline-block;
      color: ${colors.blue};
      transform: scale(1);
    }
  }
`;

const NotificationDate = styled.p`
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const NotificationDatePlaceholder = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-items: flex-start;
`;

const NotificationFullDate = styled.span`
  color: ${colors.paleGrey};
`;

export default Notification;
