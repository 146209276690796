import { PartnersContainer } from '../containers';
import { UpdatePageTitle, CloseDropdowns } from './../utils';

const Partners = () => {
  UpdatePageTitle('Partners');
  CloseDropdowns();

  return <PartnersContainer />;
};

export default Partners;
