/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import {
  Button,
  ModalTabs,
  TextInput,
  GenerateInput,
  QRCodeGenerator,
} from '..';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import {
  InputLabel,
  TextInputContainer,
  TextInputRow,
} from '../../styles/library/inputStyles';
import {
  timesWhite,
  check,
  copy as copyIcon,
  checkWhite,
  info,
} from '../../assets';
import { colors, inputColors, fonts } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import { SendTrackingEvent, SuccessNotification } from '../../utils';

const DiscoveryShareModal = ({
  hide,
  inboundLink,
  showCustom,
  invocationId,
}) => {
  const ref = useRef();
  const modalTabOptions = [
    { label: 'Link', value: 'link' },
    { label: 'QR Code', value: 'qrCode' },
  ];
  const [activeTab, setActiveTab] = useState('qrCode');
  const [buttonOptions, setButtonOptions] = useState({
    text: { label: 'Start Discovery', value: 'Start Discovery' },
    size: { label: 'Medium', value: 'medium' },
    style: { label: 'Solid', value: 'solid' },
    color: colors.darkGrey,
    includeLogo: true,
  });
  const [download, setDownload] = useState(false);
  const [copied, setCopied] = useState(false);
  const [buttonCode, setButtonCode] = useState('Generating');
  const [modalTabs, setModalTabs] = useState(modalTabOptions);
  const textOptions = [
    { label: 'Start Discovery', value: 'Start Discovery' },
    { label: 'Get Started', value: 'Get Started' },
    { label: 'Asset-Map', value: 'Asset-Map' },
  ];
  const sizeOptions = [
    { label: 'Small', value: 'small' },
    { label: 'Medium', value: 'medium' },
    { label: 'Large', value: 'large' },
  ];
  const styleOptions = [
    { label: 'Solid', value: 'solid' },
    { label: 'Outline', value: 'outline' },
    // { label: 'Outline Color', value: 'outline_color' },
  ];
  const colorOptions = [
    colors.darkGrey,
    inputColors.border,
    colors.lightBlue,
    colors.blue,
    colors.green,
    colors.pink,
    colors.orange,
  ];

  useEffect(() => {
    if (showCustom) {
      setActiveTab('link');
      setModalTabs([...modalTabOptions, { label: 'Button', value: 'button' }]);
    }
  }, [showCustom]);

  useEffect(() => {
    if (activeTab === 'button') {
      setButtonCode(codeValue());
    }
  }, [activeTab]);

  useEffect(() => {
    if (buttonOptions) {
      setButtonCode(codeValue());
    }
  }, [buttonOptions]);

  const copyCode = () => {
    if (activeTab === 'link') {
      return copyLink();
    }
    if (activeTab === 'qrCode') {
      SendTrackingEvent(
        invocationId,
        'action',
        'inbound_qr_download',
        'discovery'
      );
      return setDownload(true);
    }
    if (activeTab === 'button') {
      const buttonCode = codeValue();
      copy(buttonCode);
      SuccessNotification('Code Copied', 2.5, 'bottom-left', {
        size: ' 5px',
        style: 'solid',
      });
    }
  };

  // COPIES INBOUND LINK TO CLIPBOARD
  const copyLink = () => {
    copy(inboundLink);
    setCopied(true);
    SuccessNotification('Copied', 2.5, 'bottom-left', {
      size: ' 5px',
      style: 'solid',
    });
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  const updateButtonOptions = (e, isSelect = false, fieldName = null) => {
    if (isSelect) {
      setButtonOptions({
        ...buttonOptions,
        [fieldName]: e,
      });
    } else {
      setButtonOptions({
        ...buttonOptions,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const buttonInputs = [
    {
      type: 'select',
      label: 'Text',
      name: 'text',
      required: false,
      placeholder: 'Text',
      value: buttonOptions.text,
      options: textOptions,
      width: '100%',
      isVisible: true,
      onChange: (e) => updateButtonOptions(e, true, 'text'),
    },
    {
      type: 'select',
      label: 'Button Size',
      name: 'size',
      required: false,
      placeholder: 'Size',
      value: buttonOptions.size,
      options: sizeOptions,
      width: '100%',
      isVisible: true,
      onChange: (e) => updateButtonOptions(e, true, 'size'),
    },
    {
      type: 'select',
      label: 'Style',
      name: 'style',
      required: false,
      placeholder: 'Style',
      value: buttonOptions.style,
      options: styleOptions,
      width: '100%',
      isVisible: true,
      onChange: (e) => updateButtonOptions(e, true, 'style'),
    },
    {
      type: 'checkbox',
      label: 'Include Asset-Map Logo',
      name: 'includeLogo',
      required: false,
      value: buttonOptions.includeLogo,
      isVisible: true,
      onChange: () =>
        setButtonOptions({
          ...buttonOptions,
          includeLogo: !buttonOptions.includeLogo,
        }),
    },
  ];

  const generateImageStyle = (size) => {
    if (size === 'small') {
      return { width: '14px', height: '14px', marginRight: '10px' };
    }
    if (size === 'medium') {
      return { width: '18px', height: '18px', marginRight: '15px' };
    }
    if (size === 'large') {
      return { width: '24px', height: '24px', marginRight: '20px' };
    }
    return { width: '18px', height: '18px', marginRight: '20px' };
  };

  const generateSizeStyle = (size) => {
    if (size === 'small') {
      return { fontSize: '11px', padding: '5px 10px' };
    }
    if (size === 'medium') {
      return { fontSize: '13px', padding: '10px 15px' };
    }
    if (size === 'large') {
      return { fontSize: '18px', padding: '15px 20px' };
    }
    return { fontSize: '16px', padding: '10px 15px' };
  };

  const generateBackgroundStyle = (style, color) => {
    if (style === 'solid') {
      return {
        background: color,
        border: `1px solid ${color}`,
        color: colors.white,
      };
    }
    if (style === 'outline') {
      return {
        background: 'transparent',
        border: `1px solid ${color}`,
        color: color,
      };
    }
    return {
      background: color,
      border: `1px solid ${color}`,
      color: colors.white,
    };
  };

  const generateIcon = (style, color, size) => {
    if (
      style === 'solid' &&
      color !== colors.darkGrey &&
      color !== inputColors.border
    ) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 2884 2884"
          style={{ ...generateImageStyle(size) }}
        >
          <path
            d="M1014.5 2882.3C999.6 2878.7 984 2868.2 974.2 2854.8C957.4 2831.7 958.7 2873.7 958.7 2372.8V1924.9H510.8C7.79993 1924.9 51.5 1926.4 28 1908.5C17.9 1900.7 13.5 1895.5 7.59998 1884.1L0 1869.6V1441.8V1014L7.59998 999.5C13.5 988.2 17.9 982.9 28 975.1C51.5 957.2 7.79993 958.7 510.8 958.7H958.7V510.8C958.7 7.80005 957.2 51.5 975.1 28C982.9 17.9 988.1 13.5 999.5 7.60004L1014 0H1441.8H1869.6L1884.1 7.60004C1895.4 13.5 1900.7 17.9 1908.5 28C1926.4 51.5 1924.9 7.80005 1924.9 510.8V958.7H2372.8C2875.8 958.7 2832.1 957.2 2855.6 975.1C2865.7 982.9 2870.1 988.1 2876 999.5L2883.6 1014V1441.8V1869.6L2876 1884.1C2870.1 1895.4 2865.7 1900.7 2855.6 1908.5C2832.1 1926.4 2875.8 1924.9 2372.8 1924.9H1924.9V2372.8C1924.9 2875.8 1926.4 2832.1 1908.5 2855.6C1900.7 2865.7 1895.5 2870.1 1884.1 2876L1869.6 2883.6L1446.2 2884C1193.6 2884 1019.5 2883.4 1014.7 2882.3H1014.5ZM1804.9 2344.9V1924.9H1441.6H1078.3V2344.9V2764.9H1441.6H1804.9V2344.9ZM958.6 1441.9V1078.6H538.6H118.6V1441.9V1805.2H538.6H958.6V1441.9ZM1803.9 1441.9V1079.6H1441.6H1079.3L1078.7 1439.7C1078.5 1637.7 1078.7 1801.1 1079.3 1802.6C1079.9 1804.7 1153.6 1805.1 1442 1804.7L1803.8 1804.1V1441.8L1803.9 1441.9ZM2764.7 1441.9V1078.6H2344.7H1924.7V1441.9V1805.2H2344.7H2764.7V1441.9ZM1805 538.9V118.9H1441.7H1078.4V538.9V958.9H1441.7H1805V538.9Z"
            fill={colors.white}
          />
        </svg>
      );
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 67 67"
        style={{ ...generateImageStyle(size) }}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group" fillRule="nonzero">
            <path
              d="M43.3573328,-4.4408921e-14 L23,-4.4408921e-14 L23,21.7905328 C23,22.5243994 23.6,23.1238661 24.3333333,23.1238661 L42.0239995,23.1238661 C42.7559995,23.1238661 43.3573328,22.5243994 43.3573328,21.7905328 L43.3573328,-4.4408921e-14"
              id="path28"
              fill={colors.lightBlue}
              transform="translate(33.178666, 11.561933) rotate(-180.000000) translate(-33.178666, -11.561933) "
            ></path>
            <path
              d="M23,66.1245328 L43.3573328,66.1245328 L43.3573328,44.3333333 C43.3573328,43.6 42.7559995,43 42.0239995,43 L24.3333333,43 C23.6,43 23,43.6 23,44.3333333 L23,66.1245328"
              id="path30"
              fill={colors.orange}
              transform="translate(33.178666, 54.562266) rotate(-180.000000) translate(-33.178666, -54.562266) "
            ></path>
            <path
              d="M23.1239994,43.3562662 L23.1239994,23 L1.3333333,23 C0.599999985,23 -4.4408921e-14,23.6 -4.4408921e-14,24.3333333 L-4.4408921e-14,42.0229329 C-4.4408921e-14,42.7557328 0.599999985,43.3562662 1.3333333,43.3562662 L23.1239994,43.3562662"
              id="path32"
              fill={colors.pink}
            ></path>
            <path
              d="M43,23 L43,43.3562662 L64.7893328,43.3562662 C65.5239994,43.3562662 66.1226661,42.7557328 66.1226661,42.0229329 L66.1226661,24.3333333 C66.1226661,23.6 65.5239994,23 64.7893328,23 L43,23"
              id="path34"
              fill={colors.green}
            ></path>
            <polygon
              id="path36"
              fill={colors.blue}
              points="23 23 23 43.3573328 43.3573328 43.3573328 43.3573328 23"
            ></polygon>
          </g>
        </g>
      </svg>
    );
  };

  // const generateButton = () => {
  //   const { text, size, style, color, includeLogo } = buttonOptions;
  //   return (
  //     <button
  //       id="custom-button"
  //       style={{
  //         border: `1px solid ${color}`,
  //         background: color,
  //         display: 'flex',
  //         alignContent: 'center',
  //         alignItems: 'center',
  //         justifyContent: 'flex-start',
  //         borderRadius: '3px',
  //         letterSpacing: '0.3px',
  //         fontWeight: fonts.semiBold,
  //         cursor: 'pointer',
  //         fontFamily: `'Open Sans', sans-serif`,
  //         ...generateBackgroundStyle(style?.value, color),
  //         ...generateSizeStyle(size?.value),
  //       }}
  //       onClick={() => window.open(inboundLink, '_blank')}
  //     >
  //       {includeLogo && generateIcon(style?.value, color, size?.value)}
  //       {text?.value}
  //     </button>
  //   );
  // };

  const codeValue = () => {
    const button = document.getElementById('custom-button');
    if (button) {
      const elementHtml = button.outerHTML;
      return `<script type="text/javascript">
    var link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', 'https://fonts.googleapis.com/css?family=Open+Sans:600');
    document.head.appendChild(link);
    var buttonContainer = document.createElement("div");
    buttonContainer.innerHTML = '${elementHtml}';
    buttonContainer.onclick = function (event) {
      window.open('${inboundLink}', "_blank");
    };
    document.body.appendChild(buttonContainer);
    </script>`;
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {showCustom ? 'Share Discovery' : 'Create QR Code'}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          {showCustom && (
            <ModalTabs
              tabs={modalTabs}
              activeTab={activeTab}
              onClick={setActiveTab}
            />
          )}
          <ModalContentContainer>
            {activeTab === 'link' ? (
              <LinkContainer>
                <TextInputContainer>
                  <InputLabel>Lead Generation Link</InputLabel>
                  <TextInputRow>
                    <TextInput
                      type={'text'}
                      name={'link'}
                      onChange={() => null}
                      placeholder={`Inbound Link`}
                      value={inboundLink}
                      withIcon={true}
                      iconName={copied ? 'check' : 'copy'}
                      iconSrc={copied ? check : copyIcon}
                      onClick={copyLink}
                      onFocus={(e) => e.target.select()}
                    />
                  </TextInputRow>
                </TextInputContainer>
                <InfoBubble>
                  <InfoIcon src={info} alt="info" data-image="info" />
                  <p>
                    Copy your unique link and share it with new and prospective
                    clients
                  </p>
                </InfoBubble>
              </LinkContainer>
            ) : activeTab === 'qrCode' ? (
              <TabContent>
                <QRCodeGenerator
                  link={inboundLink}
                  download={download}
                  setDownload={setDownload}
                  showCustom={showCustom}
                />
              </TabContent>
            ) : activeTab === 'button' ? (
              <TabContent>
                {buttonInputs.map((input, index) => {
                  return GenerateInput(input, index);
                })}
                <ColorOptionsContainer>
                  <InputLabel>Color</InputLabel>
                  <ColorsList>
                    {colorOptions.map((color, index) => {
                      return (
                        <ColorSquareContainer key={index}>
                          <ColorSquare
                            color={color}
                            onClick={() =>
                              setButtonOptions({
                                ...buttonOptions,
                                color: color,
                              })
                            }
                          />
                          {buttonOptions.color === color && (
                            <CheckIcon
                              src={checkWhite}
                              alt="check"
                              data-image={`checked-${color}`}
                            />
                          )}
                        </ColorSquareContainer>
                      );
                    })}
                  </ColorsList>
                </ColorOptionsContainer>
                <PreviewContainer>
                  <InputLabel>Preview</InputLabel>
                  <ButtonPreviewContainer>
                    <button
                      id="custom-button"
                      style={{
                        border: `1px solid ${buttonOptions?.color}`,
                        background: buttonOptions?.color,
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        borderRadius: '3px',
                        letterSpacing: '0.3px',
                        fontWeight: fonts.semiBold,
                        cursor: 'pointer',
                        fontFamily: `'Open Sans', sans-serif`,
                        ...generateBackgroundStyle(
                          buttonOptions?.style?.value,
                          buttonOptions?.color
                        ),
                        ...generateSizeStyle(buttonOptions?.size?.value),
                      }}
                      onClick={() => window.open(inboundLink, '_blank')}
                    >
                      {buttonOptions?.includeLogo &&
                        generateIcon(
                          buttonOptions?.style?.value,
                          buttonOptions?.color,
                          buttonOptions?.size?.value
                        )}
                      {buttonOptions?.text?.value}
                    </button>
                  </ButtonPreviewContainer>
                </PreviewContainer>
                <TextAreaContainer>
                  <InputLabel>Code</InputLabel>
                  <TextArea readOnly={true} value={buttonCode}></TextArea>
                </TextAreaContainer>
              </TabContent>
            ) : null}
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={
                    activeTab === 'link'
                      ? 'Copy Link'
                      : activeTab === 'qrCode'
                      ? 'Download'
                      : 'Copy Code'
                  }
                  onClick={() => copyCode()}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const TextAreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0 10px 0;
`;

const TextArea = styled.textarea`
  border: 1px solid ${inputColors.border};
  color: ${colors.darkGrey};
  resize: none;
  font-size: 12px;
  border-radius: 6px;
  height: 150px;
  padding: 10px 15px;
  flex: 1 1 auto;
`;

const LinkContainer = styled.div`
  ${TextInputContainer} {
    flex: 1 1 auto;
    width: 100%;
    div {
      width: 100%;
      input {
        padding-right: 20px;
      }
    }
    img {
      right: 8px;
      top: 50%;
      width: 17px;
      height: 17px;
      transform: translateY(-50%);
    }
  }
`;

const InfoBubble = styled.div`
  border: 1px solid ${colors.lightGrey};
  background: ${colors.lighterGrey};
  padding: 10px 15px;
  border-radius: 3px;
  margin: 15px 0 10px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  p {
    color: ${colors.darkGrey};
    font-size: 11.5px;
    flex: 1 1 auto;
    text-align: left;
  }
`;

const InfoIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 15px;
`;

const ColorOptionsContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const ColorsList = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

const ColorSquareContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ColorSquare = styled.div`
  width: 50px;
  height: 50px;
  background: ${(props) => props.color};
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    opacity: 0.9;
  }
`;

const CheckIcon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
`;

const PreviewContainer = styled.div`
  text-align: left;
  margin-top: 15px;
`;

const ButtonPreviewContainer = styled.div`
  margin-top: 10px;
`;

DiscoveryShareModal.propTypes = {
  inboundLink: PropTypes.string,
  showCustom: PropTypes.bool,
  hide: PropTypes.func,
  invocationId: PropTypes.string,
};

export default DiscoveryShareModal;
