/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PageHeader, TabMenu } from '../../components';
import {
  ManagerAccountContainer,
  ManagerDiscoveryContainer,
  ManagerMembershipContainer,
  ManagerActivityContainer,
  ManagerSignalsContainer,
  ManagerIntegrationsContainer,
  ManagerStencilContainer,
  ManagerPortalPermissionsContainer,
} from '../../containers';
import {
  getTeamSettings,
  getLicensesCount,
  toggleShowInviteMemberModal,
  searchMemberActivity,
  getCustomerSignalsSettings,
  toggleShowBoBTransferModal,
  updateUserStoreValue,
  getManagerIntegrations,
  getCustomerScopes,
} from '../../store/actions';
import { Page } from '../../styles/library/layoutStyles';
import { UpdatePageTitle, CloseDropdowns, headerContent } from '../../utils';

const ManagerPreferences = ({ redirected = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  UpdatePageTitle('Team Settings');
  CloseDropdowns();
  const {
    customer,
    showHouseholdSignals,
    showActivityMonitor,
    user,
    teamSettings,
    showTransferBoBExternal,
    showManagerIntegrations,
    hasDiscoverySettings,
    showStencilLibrary,
    showHouseholdSharing,
  } = useSelector((state) => ({
    customer: state.user.user.customer,
    showHouseholdSignals: state.user.showHouseholdSignals,
    showActivityMonitor: state.user.showActivityMonitor,
    user: state.user.user,
    teamSettings: state.user.teamSettings,
    showTransferBoBExternal: state.user.showTransferBoBExternal,
    showManagerIntegrations: state.user.showManagerIntegrations,
    hasDiscoverySettings: state.user.teamHasDiscoverySettings,
    showStencilLibrary: state.user.showStencilLibrary,
    showHouseholdSharing: state.user.showHouseholdSharing,
  }));
  const [activeTab, setActiveTab] = useState('account');
  const [activeSubTab, setActiveSubTab] = useState('members');

  const [tabs, setTabs] = useState([
    {
      text: 'Account',
      value: 'account',
      visible: true,
    },
    {
      text: 'Discovery',
      value: 'discovery',
      visible: true,
    },
    {
      text: 'Membership',
      value: 'membership',
      visible: true,
    },
    {
      text: 'Stencils',
      value: 'stencils',
      visible: false,
    },
    {
      text: 'Activity',
      value: 'activity',
      visible: false,
    },
    {
      text: 'Signals',
      value: 'signals',
      visible: false,
    },
    {
      text: 'Integrations',
      value: 'integrations',
      visible: false,
    },
    {
      text: 'Client Portal',
      value: 'client_portal',
      visible: false,
    },
  ]);
  const [code, setCode] = useState('');
  const [pin, setPin] = useState('');

  //LOGIC TO DETECT THE BOB TRANSFER LINK AND REDIRECT WITH CODE AND PIN
  useEffect(() => {
    if (redirected) {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      const pin = queryParams.get('pin');
      if (code && pin) {
        setCode(code);
        setPin(pin);
      } else {
        window.alert(
          'Invalid URL. Make sure you are copying the complete URL.'
        );
      }
      location.pathname = `/manager/team`;
      location.search = `panel=membership`;
      navigate(location, { state: { panel: 'membership' } });
      dispatch(updateUserStoreValue('managerMembershipSubTab', 'transfers'));
    }
  }, [redirected]);

  useEffect(() => {
    if (user?.entitlements) {
      setTabs(
        tabs.map((tab) => {
          if (tab.value === 'activity') {
            tab.visible = showActivityMonitor;
          }
          if (tab.value === 'signals') {
            tab.visible = showHouseholdSignals;
          }
          if (tab.value === 'stencils') {
            tab.visible = showStencilLibrary;
          }
          if (tab.value === 'client_portal') {
            tab.visible = showHouseholdSharing;
          }
          return tab;
        })
      );
    }
  }, [user?.entitlements]);

  useEffect(() => {
    if (teamSettings?.entitlements) {
      setTabs(
        tabs.map((tab) => {
          if (tab.value === 'discovery') {
            tab.visible = hasDiscoverySettings;
          }
          if (tab.value === 'integrations') {
            tab.visible = showManagerIntegrations;
          }
          return tab;
        })
      );
    }
  }, [teamSettings?.entitlements]);

  useEffect(() => {
    if (customer && customer.id) {
      dispatch(getTeamSettings(customer.id));
    }
  }, [customer]);

  useEffect(() => {
    // if (activeTab === 'discovery') {
    //   dispatch(getTeamSettings(customer.id));
    // }
    if (activeTab === 'membership') {
      dispatch(getLicensesCount(customer.id));
    }
    if (activeTab === 'activity') {
      dispatch(searchMemberActivity());
    }
    if (activeTab === 'signals') {
      dispatch(getCustomerSignalsSettings(customer.id));
    }
    if (activeTab === 'integrations') {
      dispatch(getManagerIntegrations(customer.id));
    }
    if (activeTab === 'client_portal') {
      dispatch(getCustomerScopes(customer.id));
    }
  }, [activeTab]);

  useEffect(() => {
    if (location) {
      if (location?.search && location?.search.includes('?panel=')) {
        const tabSplit = location?.search.split('?panel=');
        const tabValue = tabSplit[1];
        const tabOptions = tabs.map((tab) => tab.value);
        if (tabOptions.includes(tabValue)) {
          setActiveTab(tabValue);
        } else {
          setActiveTab('account');
          location.search = `panel=account`;
        }
      } else {
        setActiveTab('account');
        location.search = `panel=account`;
      }
    }
  }, [location]);

  const tabOnClick = (tab) => {
    location.search = `panel=${tab}`;
    navigate(location, { state: { panel: tab } });
    dispatch(updateUserStoreValue('customerSettingsError', null));
  };

  return (
    <Page>
      <PageHeader
        content={{
          ...headerContent.teamSettings,
          showButton: activeTab === 'membership' ? true : false,
          buttonText:
            activeTab === 'membership'
              ? activeSubTab === 'transfers'
                ? 'Create Transfer'
                : 'Invite Members'
              : null,
        }}
        buttonFunction={() =>
          activeSubTab === 'transfers'
            ? dispatch(
                toggleShowBoBTransferModal(
                  true,
                  showTransferBoBExternal ? 'select' : 'source_search',
                  showTransferBoBExternal ? null : 'internal'
                )
              )
            : dispatch(toggleShowInviteMemberModal(true))
        }
      />
      <TabMenu
        tabs={tabs.filter((tab) => tab.visible)}
        activeTab={activeTab}
        onClick={tabOnClick}
        noBottomMargin={activeTab === 'membership'}
      />
      {activeTab === 'account' ? (
        <ManagerAccountContainer customerId={customer.id} />
      ) : activeTab === 'discovery' ? (
        <ManagerDiscoveryContainer customerId={customer.id} />
      ) : activeTab === 'membership' ? (
        <ManagerMembershipContainer
          customerId={customer.id}
          trackSubTab={setActiveSubTab}
          code={code}
          pin={pin}
        />
      ) : activeTab === 'stencils' ? (
        <ManagerStencilContainer customerId={customer.id} />
      ) : activeTab === 'activity' ? (
        <ManagerActivityContainer customerId={customer.id} />
      ) : activeTab === 'signals' ? (
        <ManagerSignalsContainer customerId={customer.id} />
      ) : activeTab === 'integrations' ? (
        <ManagerIntegrationsContainer customerId={customer.id} />
      ) : activeTab === 'client_portal' ? (
        <ManagerPortalPermissionsContainer customerId={customer.id} />
      ) : null}
    </Page>
  );
};

ManagerPreferences.propTypes = {
  redirected: PropTypes.bool,
};

export default ManagerPreferences;
