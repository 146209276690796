/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, maxDevice, fonts } from '../styles/variables';
import { ThemeProvider } from 'styled-components';

const EmptyImage = ({ imageSrc, imageName, text, styles = {} }) => {
  const theme = {
    fontSize: styles.fontSize,
    imageHeight: styles.imageHeight,
    containerMargin: styles.containerMargin,
    textMargin: styles.textMargin,
  };
  return (
    <ThemeProvider theme={theme}>
      <EmptyContainer>
        <NotFoundImage src={imageSrc} alt={imageName} data-image={imageName} />
        <NotFoundText>{text}</NotFoundText>
      </EmptyContainer>
    </ThemeProvider>
  );
};

const EmptyContainer = styled.div`
  margin: ${(props) =>
    props.theme.containerMargin
      ? props.theme.containerMargin
      : '40px 20px 0 20px'};
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const NotFoundImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: ${(props) =>
    props.theme.imageHeight ? props.theme.imageHeight : '430px'};
  margin: 10px;
`;

const NotFoundText = styled.p`
  @media ${maxDevice.tablet} {
    margin: 20px;
    font-size: ${(props) =>
      props.theme.fontSize
        ? `${parseInt(props.theme.fontSize) - 4}px`
        : '24px'};
  }
  margin: ${(props) =>
    props.theme.textMargin ? props.theme.textMargin : '20px 40px 20px 40px'};
  font-size: ${(props) =>
    props.theme.fontSize ? props.theme.fontSize : '28px'};
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

EmptyImage.propTypes = {
  imageSrc: PropTypes.string,
  imageName: PropTypes.string,
  text: PropTypes.string,
  styles: PropTypes.object,
};

export default EmptyImage;
