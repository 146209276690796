/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import {
  SimpleModal,
  Select,
  LoadingAnimation,
  Error,
  IntegrationSearchResult,
  LinkedIntegrationItem,
  IntegrationSearchBar,
} from '../../../components';
import { HouseholdFileStorage, HouseholdSalesforceAlign } from '../../';
import {
  searchHouseholdIntegration,
  syncHouseholdIntegration,
  linkHouseholdMemberToIntegration,
  unlinkHouseholdMemberFromIntegration,
  unlinkSalesforceMember,
  getHouseholdInfo,
  getIntegrationMembers,
  setIntegrationMembers,
  setIntegrationMembersError,
  createSalesforceRecord,
  searchSalesforceIntegration,
} from '../../../store/actions';
import { check, launchLink, sync } from '../../../assets';
import { PageContent } from '../../../styles/library/layoutStyles';
import {
  SecondaryHeading,
  TertiaryHeading,
} from '../../../styles/library/fontStyles';
import { ButtonThemes, ErrorThemes } from '../../../styles/themes';
import {
  colors,
  inputColors,
  messageColors,
  maxDevice,
  fonts,
} from '../../../styles/variables';
import {
  SendTrackingEvent,
  TransformIntegrationMembers,
  TransformSalesforceSearchResults,
  TransformSalesforceLinkedInstruments,
  DateFromNowUTC,
} from '../../../utils';

const HouseholdIntegrations = ({ householdId, selectedIntegration }) => {
  const dispatch = useDispatch();
  const {
    currentHousehold,
    allMemberCategories,
    loadedHouseholdFields,
    preferences,
    integrationResults,
    integrationResultsError,
    linkedMembers,
    linkedMembersError,
    justSynced,
    justLinkedMember,
    justUnlinkedMember,
    linkIntegrationMemberError,
    invocationId,
    linkedMember,
    householdMember,
    integrationMembers,
    integrationMembersError,
    justAlignedHousehold,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    preferences: state.user.user.preferences,
    integrationResults: state.households.integrationResults,
    integrationResultsError: state.households.integrationResultsError,
    linkedMembers: state.households.linkedMembers,
    linkedMembersError: state.households.linkedMembersError,
    justSynced: state.households.justSynced,
    justLinkedMember: state.households.justLinkedMember,
    justUnlinkedMember: state.households.justUnlinkedMember,
    linkIntegrationMemberError: state.households.linkIntegrationMemberError,
    invocationId: state.configs.invocationId,
    linkedMember: state.households.linkedMember,
    householdMember: state.households.householdMember,
    integrationMembers: state.user.integrationMembers,
    integrationMembersError: state.user.integrationMembersError,
    justAlignedHousehold: state.households.justAlignedHousehold,
  }));
  const [isLoadingIntegration, setIsLoadingIntegration] = useState(true);
  const [isFileStorage, setIsFileStorage] = useState(false);
  const [isMorningstar, setIsMorningstar] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncObject, setSyncObject] = useState({
    display: false,
    sync_url: null,
    synced_utc: null,
  });
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [linkedIntegrationMembers, setLinkedIntegrationMembers] = useState([]);
  const [hasMemberResults, setHasMemberResults] = useState(false);
  const [hasLinkedMembersError, setHasLinkedMembersError] = useState(false);
  const [hideSearchBar, setHideSearchBar] = useState(false);
  const [missingSearchValue, setMissingSearchValue] = useState(false);
  const [searchUrl, setSearchUrl] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [showMoreMessage, setShowMoreMessage] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [importMemberOptions, setImportMemberOptions] = useState([]);
  const [selectedImportMember, setSelectedImportMember] = useState(null);
  const [currentImport, setCurrentImport] = useState();
  const [selectValue, setSelectValue] = useState(false);
  const [importValue, setImportValue] = useState(false);
  const [unlinkMember, setUnlinkMember] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [loadingId, setLoadingId] = useState();
  const [importError, setImportError] = useState(false);
  const [emptyMemberError, setEmptyMemberError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //SALESFORCE SPECIFIC STATE
  const [isSalesforce, setIsSalesforce] = useState(false);
  const [isSalesforceAlign, setIsSalesforceAlign] = useState(false);
  const [householdExternalUrl, setHouseholdExternalUrl] = useState();
  const [linkedFinancials, setLinkedFinancials] = useState([]);
  const [searchTypes, setSearchTypes] = useState([
    { label: 'Member', value: 'member', selected: true },
    { label: 'Entity', value: 'entity', selected: false },
  ]);
  const [searchType, setSearchType] = useState('member');
  const [searchFirstName, setSearchFirstName] = useState('');
  const [missingFirstName, setMissingFirstName] = useState(false);
  const [searchLastName, setSearchLastName] = useState('');
  const [missingLastName, setMissingLastName] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setIntegrationMembers(false));
    };
  }, []);

  //HOOK TO RESET EVERYTHING WHEN INTEGRATION CHANGES (USER CLICKS TAB)
  useEffect(() => {
    if (selectedIntegration) {
      setIsLoadingIntegration(true);
      setResults([]);
      setShowResults(false);
      setIsLoadingResults(false);
      setShowMoreMessage(false);
      setIsSyncing(false);
      setSyncObject({
        display: false,
        sync_url: null,
        synced_utc: null,
      });
      setSearchValue('');
      setSearchFirstName('');
      setSearchLastName('');
      setLinkedIntegrationMembers([]);
      setSearchUrl('');
      setErrorMessage('');
      setHasLinkedMembersError(false);

      //CHECKS IF INTEGRATION IS FILE STORAGE
      setIsFileStorage(selectedIntegration?.file_storage);

      //CHECKS IF INTEGRATION IS MORNINGSTAR
      setIsMorningstar(selectedIntegration?.id === 'morningstar');

      //CHECKS IF INTEGRATION IS SALESFORCE
      setIsSalesforce(selectedIntegration?.id === 'axasf');
      setIsSalesforceAlign(false);

      //CLEARS INTEGRATION MEMBER ERROR IN STORE
      dispatch(setIntegrationMembersError(false));
    }
  }, [selectedIntegration]);

  //HOOK TO SET LOADING TO FALSE IF LINKED MEMBERS HAVE BEEN FETCHED
  useEffect(() => {
    if (selectedIntegration) {
      if (linkedMembers?.integration === selectedIntegration?.id) {
        setIsLoadingIntegration(false);
        if (isSalesforce) {
          //IF SALESFORCE DETERMINE IF ALIGN DISPLAYED BASED OF EXTERNAL URL
          setIsSalesforceAlign(
            linkedMembers.hasOwnProperty('external_url') &&
              !linkedMembers.external_url
          );
        }
      }
    }
  }, [selectedIntegration, linkedMembers]);

  //MORNINGSTAR SPECIFIC HOOK CODE FOR FETCHING RELATED MEMBERS TO ALREADY LINKED HOUSEHOLD
  useEffect(() => {
    if (linkedMembers) {
      if (linkedMembers?.linked_members.length !== 0 && isMorningstar) {
        setHideSearchBar(true);
        setIsLoadingResults(true);
        setShowResults(true);
        dispatch(getIntegrationMembers(linkedMembers.get_members_url));
      } else {
        setHideSearchBar(false);
      }
    }
  }, [linkedMembers]);

  //HOOK TO SET FETCHING LINKED MEMBER ERROR AND STOP LOADING
  useEffect(() => {
    if (linkedMembersError) {
      setIsLoadingIntegration(false);
      setHasLinkedMembersError(true);
    }
  }, [linkedMembersError]);

  //HOOK TO SET LINKING MEMBER ERROR AND STOP LOADING
  useEffect(() => {
    if (linkIntegrationMemberError) {
      setErrorMessage(
        'Unable to link member to household.  An error occurred while importing.'
      );
      setShowResults(true);
      setIsLoadingResults(false);
      setLoadingMembers(false);
      setLoadingId();
    }
  }, [linkIntegrationMemberError]);

  //HOOK TO GET UPDATED HOUSEHOLD INFO AFTER SYNC
  useEffect(() => {
    if (justSynced) {
      dispatch(getHouseholdInfo(householdId));
    }
  }, [justSynced]);

  //HOOK TO SET INTEGRATION SEARCH RESULTS
  useEffect(() => {
    if (integrationResults) {
      let results = integrationResults.results;
      if (isSalesforce) {
        results = TransformSalesforceSearchResults(
          results,
          currentHousehold.householdmembers
        );
      }
      setResults(results);
      setShowResults(true);
      setIsLoadingResults(false);
      if (integrationResults.hasOwnProperty('more')) {
        setShowMoreMessage(integrationResults.more);
      }
    }
  }, [integrationResults]);

  //HOOK TO UPDATE IMPORT TO MEMBER LIST ALONG WITH LINKED INTEGRATION MEMBER LIST
  useEffect(() => {
    if (
      linkedMembers &&
      currentHousehold.householdmembers &&
      loadedHouseholdFields
    ) {
      const transformedLinkedMembers = TransformIntegrationMembers(
        linkedMembers.linked_members,
        currentHousehold.householdmembers
      );
      const transformedUnlinkedMembers = TransformIntegrationMembers(
        linkedMembers.unlinked_members,
        currentHousehold.householdmembers
      );
      if (isSalesforce) {
        setLinkedIntegrationMembers([
          ...transformedLinkedMembers,
          ...transformedUnlinkedMembers.map((mem) => {
            mem.not_linked = true;
            return mem;
          }),
        ]);
        // setOpportunityMembersList(
        //   transformedMembers.filter((mem) => mem.member_type === 'member')
        // );
        if (linkedMembers?.sync_report) {
          if (linkedMembers.sync_report?.instrument) {
            const linkedInstruments = TransformSalesforceLinkedInstruments(
              linkedMembers.sync_report.instrument.linked,
              'new'
            );
            const syncedInstruments = TransformSalesforceLinkedInstruments(
              linkedMembers.sync_report.instrument.synced,
              'synced'
            );
            const deletedInstruments = TransformSalesforceLinkedInstruments(
              linkedMembers.sync_report.instrument.deleted,
              'removed'
            );
            const skippedInstruments = TransformSalesforceLinkedInstruments(
              linkedMembers.sync_report.instrument.skipped,
              'skipped'
            );
            const instrumentList = [
              ...linkedInstruments,
              ...syncedInstruments,
              ...deletedInstruments,
              ...skippedInstruments,
            ];
            setLinkedFinancials(instrumentList);
          }
        }
      } else {
        setLinkedIntegrationMembers(transformedLinkedMembers);
      }
      setImportMemberOptions([
        { label: 'New Member', value: 'new' },
        ...transformedUnlinkedMembers.map((mem) => {
          return mem.member_type === 'entity'
            ? {
                label: `${mem.name} (Entity)`,
                value: mem.id,
              }
            : {
                label: mem.name,
                value: mem.id,
              };
        }),
      ]);
      if (transformedUnlinkedMembers.length === 0) {
        setSelectedImportMember({ label: 'New Member', value: 'new' });
      } else {
        setSelectedImportMember(null);
      }
      setSyncObject({
        display: true,
        sync_url: linkedMembers.sync_url,
        synced_utc: linkedMembers.synced_utc,
      });
      setSearchUrl(linkedMembers.search_url);
      setIsSyncing(false);
      setLoadingMembers(false);
      setHouseholdExternalUrl(linkedMembers.external_url);
      setLoadingId();
    }
  }, [linkedMembers, currentHousehold.householdmembers, loadedHouseholdFields]);

  //HOOK TO UPDATE LABEL FOR FIRST OPTION IN IMPORT TO SELECT DROPDOWN
  useEffect(() => {
    if (importMemberOptions && importMemberOptions.length !== 0) {
      if (selectedIntegration?.id === 'axasf') {
        if (searchType === 'entity') {
          importMemberOptions[0].label = 'New Entity';
        }
        if (searchType === 'member') {
          importMemberOptions[0].label = 'New Member';
        }
      } else {
        importMemberOptions[0].label = 'New Member';
      }
    }
  }, [searchType, importMemberOptions]);

  //HOOK TO RESET IMPORT TO SELECT TO EMPTY AND SENDS TRACKING EVENT
  useEffect(() => {
    if (linkedMember && householdMember) {
      setSelectedImportMember(null);
      SendTrackingEvent(
        invocationId,
        'action',
        `integration_householdmember_${
          householdMember.newMember ? 'create' : 'link'
        }`,
        'household_integration',
        {
          integration: selectedIntegration.id,
          householdmember: householdMember.householdmember,
        }
      );
    }
  }, [linkedMember]);

  //HOOK TO GET UPDATED HOUSEHOLD INFO AND UPDATE SEARCH RESULTS AFTER LINKING
  useEffect(() => {
    if (justLinkedMember && !linkIntegrationMemberError) {
      dispatch(getHouseholdInfo(householdId));
      if (results.length !== 0 && !hasMemberResults) {
        searchIntegrationOnClick();
      } else {
        selectOnClick();
      }
    }
  }, [justLinkedMember]);

  //HOOK TO GET UPDATED HOUSEHOLD INFO AND UPDATE SEARCH RESULTS AFTER UNLINKING
  useEffect(() => {
    if (justUnlinkedMember) {
      dispatch(getHouseholdInfo(householdId));
      if (results.length !== 0) {
        searchIntegrationOnClick();
      }
      setSelectedImportMember(null);
    }
  }, [justUnlinkedMember]);

  //HOOK TO SET ERROR MESSAGE IF ERROR SEARCHING INTEGRATION
  useEffect(() => {
    if (integrationResultsError) {
      setIsLoadingResults(false);
      setErrorMessage('An error was encountered while searching');
    }
  }, [integrationResultsError]);

  //HOOK TO SET LIST OF INTEGRATION MEMBERS AS RESULTS AND STOP LOADING
  useEffect(() => {
    if (integrationMembers) {
      if (integrationMembers.hasOwnProperty('results')) {
        setHasMemberResults(true);
        setResults(integrationMembers.results);
        setIsLoadingResults(false);
        setShowResults(true);
        setLoadingMembers(false);
      }
    }
  }, [integrationMembers]);

  //HOOK TO DISPLAY ERROR WHILE FETCHING INTEGRATION MEMBERS
  useEffect(() => {
    if (integrationMembersError) {
      if (integrationMembersError.hasOwnProperty('detail')) {
        setErrorMessage(integrationMembersError.detail);
      } else {
        setErrorMessage('Error getting members');
      }
      setLoadingMembers(false);
      setImportError(true);
      setIsLoadingResults(false);
    }
  }, [integrationMembersError]);

  //HOOK TO RUN IMPORT CLICK FUNCTION
  useEffect(() => {
    if (importValue) {
      importOnClick();
    }
  }, [importValue]);

  //HOOK TO RUN SELECT CLICK FUNCTION (MORNINGSTAR SPECIFIC)
  useEffect(() => {
    if (selectValue) {
      selectOnClick();
    }
  }, [selectValue]);

  //HOOK TO REMOVE SEARCH RESULTS IF SALESFORCE HOUSEHOLD JUST ALIGNED
  useEffect(() => {
    if (justAlignedHousehold) {
      setShowResults(false);
      setResults([]);
    }
  }, [justAlignedHousehold]);

  //FUNCTION TO DETERMINE IF SYNC HOUSEHOLD SHOULD BE DISPLAYED
  const showSyncHousehold = () => {
    return (
      syncObject.display &&
      linkedIntegrationMembers.length !== 0 &&
      !isSalesforceAlign
    );
  };

  //FUNCTION TO SYNC HOUSEHOLD
  const syncOnClick = () => {
    setShowSyncModal(false);
    dispatch(
      syncHouseholdIntegration(
        syncObject.sync_url,
        householdId,
        selectedIntegration.key
      )
    );
    setIsSyncing(true);
  };

  //SYNC MODAL DISPLAY CONTENT
  const generateSyncModalContent = () => {
    return {
      heading: `Sync Household?`,
      text: `<p>Are you sure you want to sync <strong>${currentHousehold.name}</strong> with ${selectedIntegration.name}?</p></br/><p>Syncing may affect existing values and linkages in the current household.</p>`,
      minWidth: '350px',
      buttons: [
        {
          text: 'Cancel',
          function: () => setShowSyncModal(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes, Sync',
          function: () => syncOnClick(),
          theme: ButtonThemes.primary,
        },
      ],
    };
  };

  //FUNCTION THAT SETS THE UNLINK MEMBER AND SHOW UNLINK MODAL ON CLICK OF UNLINK TEXT
  const unlinkOnClick = (member) => {
    if (member && member?.category) {
      if (member.category === 'primary') {
        member.isPrimary = true;
        member.isHousehold = true;
      }
    }
    setUnlinkMember(member);
    setShowUnlinkModal(true);
  };

  //FUNCTION TO UNLINK MEMBER/ENTITY/HOUSEHOLD ALSO SETS UNLINK LOADING DISPLAY
  const unlinkOnClickModal = () => {
    setShowUnlinkModal(false);
    if (isSalesforce) {
      if (unlinkMember.isHousehold) {
        setLoadingId('household');
      } else {
        setLoadingId(unlinkMember.id);
      }
      return dispatch(
        unlinkSalesforceMember(
          householdId,
          unlinkMember.id,
          null,
          unlinkMember.isHousehold,
          true
        )
      );
    }
    SendTrackingEvent(
      invocationId,
      'action',
      'integration_householdmember_unlink',
      'household_integration',
      { integration: selectedIntegration.id, householdmember: unlinkMember.id }
    );
    dispatch(
      unlinkHouseholdMemberFromIntegration(
        unlinkMember.unlink_url,
        householdId,
        selectedIntegration.key
      )
    );
    setLoadingId(unlinkMember.id);
  };

  //UNLINK MEMBER/ENTITY/HOUSEHOLD MODAL CONTENT
  const generateUnlinkModalContent = () => {
    if (unlinkMember.unlink_url === false) {
      return {
        heading: `Member Unlink Unavailable`,
        text: `<p>The Household Member you are attempting to unlink <strong>${unlinkMember.name}</strong> was imported using the Entire Household import feature.</p><br/>
        <p> To successfully unlink this Household Member please delete the corresponding Contact in ${selectedIntegration.name} <a href="${unlinkMember.external_url}" target="_blank" rel="noopener noreferrer"><strong>${unlinkMember.name}</strong><img src=${launchLink} alt="link" data-image="${selectedIntegration}-link"/></a> and sync this Household.</p>`,
        minWidth: '350px',
      };
    }
    return {
      heading: unlinkMember.isHousehold
        ? `Unlink Household?`
        : `Unlink Household Member?`,
      text: unlinkMember.isHousehold
        ? unlinkMember.isPrimary
          ? `<p>This user is the Primary Member, unlinking them will unlink this <strong>Entire Household</strong> from ${selectedIntegration.name}?</p><br/><p>This action cannot be undone.</p>`
          : `<p>Are you sure you want to unlink this <strong>Entire Household</strong> from Salesforce?</p><br/><p>This action cannot be undone.</p>`
        : `<p>Are you sure you want to unlink this Household Member?</p><br/><p>This will unlink the ${selectedIntegration.name} record <strong>${unlinkMember.name}</strong> from Household Member <strong>${unlinkMember.name}</strong>.<p>`,
      minWidth: '350px',
      buttons: [
        {
          text: 'Cancel',
          function: () => setShowUnlinkModal(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes, Unlink',
          function: () => unlinkOnClickModal(),
          theme: ButtonThemes.primary,
        },
      ],
    };
  };
  //FUNCTION TO SET VALUE OF IMPORT TO SELECT AND REMOVE ERROR
  const importMemberOnChange = (e) => {
    setSelectedImportMember(e);
    setEmptyMemberError(false);
  };

  //FUNCTION TO UPDATE SEARCH BAR INPUT VALUES AND ERRORS
  const updateSearchValue = (value, input) => {
    if (isSalesforce) {
      if (searchType === 'member') {
        if (input === 'firstName') {
          setMissingFirstName(false);
          return setSearchFirstName(value);
        } else if (input === 'lastName') {
          setSearchLastName(value);
          return setMissingLastName(false);
        }
      }
    }
    setMissingSearchValue(false);
    return setSearchValue(value);
  };

  //FUNCTION TO SET VALUES AFTER A USER SEARCHES
  const justSearched = () => {
    setErrorMessage('');
    setIsLoadingResults(true);
    setShowMoreMessage(false);
    setShowResults(true);
  };

  //FUNCTION THAT DISPATCHES SEARCH INTEGRATION API CALL
  const searchIntegrationOnClick = () => {
    if (selectedIntegration && searchUrl !== '') {
      if (isSalesforce) {
        return searchSalesforceOnClick();
      }
      setImportError(false);
      setHasMemberResults(false);
      dispatch(searchHouseholdIntegration(searchUrl, searchValue));
      SendTrackingEvent(
        invocationId,
        'action',
        'integration_search',
        'household_integration',
        { integration: selectedIntegration.id, query: searchValue }
      );
      return justSearched();
    }
  };

  //EVENT HANDLER FOR HITTING ENTER WHILE SEARCHING INTEGRATION
  const onKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
      searchIntegrationOnClick();
    }
  };

  //FUNCTION TO DETERMINE IF SELECT MEMBER DROPDOWN SHOULD BE DISPLAYED
  const showImportToSelect = () => {
    if (results && results.length !== 0) {
      if (!isMorningstar || (isMorningstar && hasMemberResults)) {
        return true;
      }
    }
    return false;
  };

  //FUNCTION WHEN A USER CLICKS ON THE IMPORT TEXT
  const importOnClick = () => {
    if (loadingMembers) {
      return;
    }
    if (errorMessage) {
      setErrorMessage();
    }
    if (selectedImportMember) {
      setCurrentImport(importValue);
      setLoadingMembers(true);
      dispatch(
        linkHouseholdMemberToIntegration(
          importValue.link_url,
          selectedImportMember.value,
          householdId,
          selectedIntegration.key
        )
      );
    } else {
      setEmptyMemberError(true);
      setImportValue(false);
    }
  };

  //FUNCTION TO GET NEW INTEGRATION MEMBERS ON CLICK OF SELECT (MORNINGSTAR)
  const selectOnClick = () => {
    setResults([]);
    setIsLoadingResults(true);
    if (selectValue) {
      dispatch(getIntegrationMembers(selectValue.family_url));
    }
  };

  //FUNCTION THAT DETERMINES IF IMPORT OR SELECT IS USED ALONG WITH LOADING DISPLAY
  const generateImportMemberButton = (result) => {
    let importButtonText = 'Import';
    let onClickHandler = () => setImportValue(result);
    if (isMorningstar && !hasMemberResults) {
      importButtonText = 'Select';
      onClickHandler = () => setSelectValue(result);
    }
    if (result?.households?.length === 0) {
      return (
        <ImportLabel onClick={onClickHandler}>
          {((currentImport &&
            currentImport.id === result.id &&
            loadingMembers) ||
            importError) && (
            <LoadingAnimation
              dots={true}
              color={colors.lightBlue}
              smaller={true}
            />
          )}
          {importButtonText}
        </ImportLabel>
      );
    }
    if (result?.households.includes(householdId)) {
      return <ExistingMemberLabel>Household Member</ExistingMemberLabel>;
    }
    return (
      <ViewLink to={`/household/${result?.households[0]}/summary`}>
        {result?.just_created && (
          <img src={check} alt="check" data-image="created-check" />
        )}{' '}
        View
      </ViewLink>
    );
  };

  //SALESFORCE SPECIFIC INTEGRATION LOGIC
  //FUNCTION TO UNLINK ENTIRE HOUSEHOLD
  const householdUnlink = () => {
    const primaryMember = currentHousehold.householdmembers.find(
      (mem) => mem.category === 'primary'
    );
    if (primaryMember) {
      primaryMember.isHousehold = true;
    }
    setUnlinkMember(primaryMember);
    setShowUnlinkModal(true);
  };

  //FUNCTION TO CREATE A RECORD IN SALESFORCE
  const createSalesforceRecordOnClick = (member) => {
    let type;
    member.member_type === 'member'
      ? (type = 'link_householdmember')
      : (type = 'link_legalentity');
    dispatch(createSalesforceRecord(householdId, type, member.id));
    setLoadingId(member.id);
  };

  //FUNCTION THAT RUNS WHENS USERS CLICKS ON MEMBER/ENTITY SEARCH TYPE
  const salesforceSearchOptionOnClick = (option) => {
    const updatedSearchTypes = searchTypes.map((type) => {
      type.selected = option.value === type.value;
      return type;
    });
    setSearchTypes(updatedSearchTypes);
    setSearchType(option.value);
    setSelectedImportMember(null);
    setResults([]);
    setShowResults(false);
    setIsLoadingResults(false);
    if (option === 'member') {
      setSearchValue('');
      setMissingSearchValue(false);
    }
    if (option === 'entity') {
      setSearchFirstName('');
      setSearchLastName('');
      setMissingFirstName(false);
      setMissingLastName(false);
    }
  };

  //FUNCTION THAT RUNS WHEN USER SEARCHES SALESFORCE INTEGRATION
  const searchSalesforceOnClick = () => {
    let hasError = false;
    let data = {
      searchValue: searchValue,
    };
    if (searchType === 'member') {
      if (searchFirstName === '') {
        setMissingFirstName(true);
        hasError = true;
      }
      if (searchLastName === '') {
        setMissingLastName(true);
        hasError = true;
      }
      data = {
        firstName: searchFirstName,
        lastName: searchLastName,
      };
    }
    if (searchType === 'entity') {
      if (searchValue === '') {
        setMissingSearchValue(true);
        hasError = true;
      }
    }
    if (!hasError) {
      dispatch(searchSalesforceIntegration(searchUrl, searchType, data));
      return justSearched();
    }
  };

  //SEARCH FIRST/LAST NAME ENDPOINTS FOR SALESFORCE MEMBER SEARCH
  const searchInputs = [
    {
      type: 'text',
      key: 'firstName',
      placeholder: 'First Name',
      value: searchFirstName,
      onChange: updateSearchValue,
      onKeyDown: onKeyDown,
      hasError: missingFirstName,
    },
    {
      type: 'text',
      key: 'lastName',
      placeholder: 'Last Name',
      value: searchLastName,
      onChange: updateSearchValue,
      onKeyDown: onKeyDown,
      hasError: missingLastName,
    },
  ];

  return (
    <>
      {showSyncModal && (
        <SimpleModal
          hide={() => setShowSyncModal(false)}
          content={generateSyncModalContent()}
        />
      )}
      {showUnlinkModal && (
        <SimpleModal
          hide={() => setShowUnlinkModal(false)}
          content={generateUnlinkModalContent()}
        />
      )}
      {selectedIntegration ? (
        <PageContent style={{ marginBottom: '100px' }}>
          <HeadingContainer>
            <IntegrationImage
              src={selectedIntegration.image}
              alt={selectedIntegration.name}
              data-image={selectedIntegration.name}
              style={{
                maxHeight: selectedIntegration?.maxHeight
                  ? selectedIntegration?.maxHeight
                  : null,
                paddingTop: selectedIntegration?.paddingTop || null,
              }}
            />
            {showSyncHousehold() && (
              <SyncContainer>
                <SyncButton onClick={() => setShowSyncModal(true)}>
                  {isSyncing ? (
                    <SyncImageAnimation
                      src={sync}
                      alt="sync"
                      data-image="sync"
                    />
                  ) : (
                    <SyncImage src={sync} alt="sync" data-image="sync" />
                  )}

                  <SyncText>Sync Household</SyncText>
                </SyncButton>
                <LastSyncedText>
                  Last Synced: {DateFromNowUTC(syncObject.synced_utc)}
                </LastSyncedText>
              </SyncContainer>
            )}
          </HeadingContainer>
          {isFileStorage ? (
            <HouseholdFileStorage selectedIntegration={selectedIntegration} />
          ) : isSalesforceAlign ? (
            <HouseholdSalesforceAlign
              householdId={householdId}
              selectedIntegration={selectedIntegration}
            />
          ) : isLoadingIntegration ? (
            <LoadingAnimation />
          ) : hasLinkedMembersError ? (
            <ErrorDisplay>
              <Error
                errorMessage={`Error communicating with ${selectedIntegration.name}.`}
                theme={ErrorThemes.inverted}
              />
            </ErrorDisplay>
          ) : (
            <SelectedIntegrationContainer>
              <LeftContainer>
                <SecondaryHeading>
                  Import from {selectedIntegration.name}
                </SecondaryHeading>
                {hideSearchBar || (
                  <IntegrationSearchBar
                    selectedIntegration={selectedIntegration}
                    searchTypes={isSalesforce ? searchTypes : null}
                    selectSearchType={salesforceSearchOptionOnClick}
                    searchInputs={searchInputs}
                    searchValue={searchValue}
                    showSeparateSearchInputs={
                      isSalesforce && searchType === 'member'
                    }
                    updateSearchValue={updateSearchValue}
                    missingSearchValue={missingSearchValue}
                    onKeyDown={onKeyDown}
                    searchIntegrationOnClick={searchIntegrationOnClick}
                  />
                )}
                {showImportToSelect() && (
                  <SelectContainer>
                    <InputLabel>
                      Import to
                      <RequiredStar>*</RequiredStar>
                    </InputLabel>
                    <Select
                      selectedOption={selectedImportMember}
                      onChange={(e) => importMemberOnChange(e)}
                      options={importMemberOptions}
                      isSearchable={true}
                      placeholder={'Select New Member or Existing'}
                      closeMenuOnSelect={true}
                      autoFocus={false}
                      hasError={emptyMemberError}
                    />
                  </SelectContainer>
                )}
                {showResults && (
                  <ResultsContainer>
                    <TertiaryHeading>Results</TertiaryHeading>
                    <ResultsList>
                      {isLoadingResults ? (
                        <LoadingAnimation />
                      ) : errorMessage !== '' ? (
                        <ErrorDisplay>
                          <Error
                            errorMessage={errorMessage}
                            theme={ErrorThemes.inverted}
                          />
                        </ErrorDisplay>
                      ) : results && results.length !== 0 ? (
                        results.map((result, index) => {
                          return (
                            <IntegrationSearchResult
                              key={index}
                              result={result}
                              generateButton={generateImportMemberButton}
                            />
                          );
                        })
                      ) : (
                        <ResultsMessage>No Results</ResultsMessage>
                      )}
                    </ResultsList>
                    {showMoreMessage && (
                      <MoreResultsMessage>
                        Additional results available, refine query.
                      </MoreResultsMessage>
                    )}
                  </ResultsContainer>
                )}
              </LeftContainer>
              <RightContainer>
                {isSalesforce && (
                  <>
                    <SecondaryHeading>Household</SecondaryHeading>
                    <LinkedHouseholdContainer>
                      <HouseholdName>
                        <span>{currentHousehold.name}</span>
                        {householdExternalUrl !== '' && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={householdExternalUrl}
                          >
                            <LaunchImage
                              src={launchLink}
                              alt="launch"
                              data-image="launch"
                            />
                          </a>
                        )}
                      </HouseholdName>
                      <div>
                        {loadingId === 'household' ? (
                          <LoadingAnimationContainer>
                            <LoadingAnimation
                              dots={true}
                              color={colors.lightBlue}
                              smaller={true}
                            />
                            Unlinking
                          </LoadingAnimationContainer>
                        ) : householdExternalUrl !== '' ? (
                          <UnlinkText
                            onClick={() => {
                              householdUnlink();
                            }}
                          >
                            Unlink
                          </UnlinkText>
                        ) : (
                          <NotLinkedText>Not Linked</NotLinkedText>
                        )}
                      </div>
                    </LinkedHouseholdContainer>
                  </>
                )}
                <SecondaryHeading>
                  {isSalesforce ? 'Members' : 'Linked Household Members'}
                </SecondaryHeading>
                {linkedIntegrationMembers.length !== 0 ? (
                  linkedIntegrationMembers.map((member, index) => {
                    return (
                      <LinkedIntegrationItem
                        key={index}
                        item={member}
                        selectedIntegration={selectedIntegration}
                        preferences={preferences}
                        memberCategories={allMemberCategories}
                        unlinkOnClick={unlinkOnClick}
                        loadingId={loadingId}
                        createRecordOnClick={createSalesforceRecordOnClick}
                      />
                    );
                  })
                ) : (
                  <LinkedMembersContainer>
                    <ResultsMessage>No Linked Members</ResultsMessage>
                  </LinkedMembersContainer>
                )}
                {isSalesforce && (
                  <>
                    <LinkedFinancialsContainer>
                      <SecondaryHeading>Financials</SecondaryHeading>
                      {linkedFinancials.length !== 0 ? (
                        linkedFinancials.map((financial, index) => {
                          return (
                            <LinkedIntegrationItem
                              key={index}
                              item={financial}
                              isFinancial={true}
                            />
                          );
                        })
                      ) : (
                        <ResultsMessage>No Linked Financials</ResultsMessage>
                      )}
                    </LinkedFinancialsContainer>
                    <AdditionalActionsContainer>
                      <SecondaryHeading>Additional Actions</SecondaryHeading>
                      {/* <div>
                        <LinkText onClick={() => openCreateOpportunityModal()}>
                          Create Salesforce Opportunity
                        </LinkText>
                        <LinkText onClick={() => setShowActivityModal(true)}>
                          Update Salesforce Activity to Asset-Map Completed
                        </LinkText>
                      </div> */}
                      <div>
                        <LinkText
                          href={`${window.location.origin}/household/${householdId}/opportunity/`}
                          target="_blank"
                        >
                          Create Salesforce Opportunity
                        </LinkText>
                        <br />
                        <LinkText
                          href={`${window.location.origin}/household/${householdId}/salesforce_activity/`}
                          target="_blank"
                        >
                          Update Salesforce Activity to Asset-Map Completed
                        </LinkText>
                      </div>
                    </AdditionalActionsContainer>
                  </>
                )}
              </RightContainer>
            </SelectedIntegrationContainer>
          )}
        </PageContent>
      ) : null}
    </>
  );
};

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: -10px 0 10px 0;
  height: 80px;
`;

const IntegrationImage = styled.img`
  height: 100%;
  max-height: 65px;
`;

const SyncContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
`;

const SyncButton = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  padding: 0;
  border: none;
  cursor: pointer;
`;

const SyncImage = styled.img`
  width: 10px;
  margin-right: 8px;
  flex: 0 0 auto;
`;

const SyncText = styled.span`
  flex: 0 0 auto;
`;

const LastSyncedText = styled.p`
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const SyncImageAnimation = styled(SyncImage)`
  animation: ${rotation} 1s infinite ease-in-out;
`;

const SelectedIntegrationContainer = styled.div`
  @media ${maxDevice.tablet} {
    flex-wrap: wrap-reverse;
  }
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 40px;
`;

const LeftContainer = styled.div`
  @media ${maxDevice.tablet} {
    margin-right: 0px;
    width: 100%;
    padding-right: 0px;
  }
  flex: 1 1 50%;
  padding-right: 20px;
`;

const ErrorDisplay = styled.div`
  max-width: 400px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  div {
    flex: 0 0 auto;
  }
`;

const InputLabel = styled.label`
  margin: 0 0 5px 0;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const ImportLabel = styled.span`
  color: ${colors.lightBlue};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  &:hover {
    color: ${colors.blue};
  }
`;

const RequiredStar = styled.span`
  color: ${messageColors.error};
  margin-left: 2px;
`;

const SelectContainer = styled.div`
  margin: 10px 0 5px 0;
  display: flex;
  text-align: left;
  flex-direction: column;
  max-width: 500px;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

const ResultsContainer = styled.div`
  margin-top: 20px;
  max-width: 530px;
`;

const ResultsList = styled.div`
  margin-top: 5px;
`;

const ResultsMessage = styled.p`
  font-size: 13px;
  color: ${colors.paleGrey};
`;

const MoreResultsMessage = styled.p`
  text-align: center;
  color: ${colors.paleGrey};
  font-size: 14px;
`;

const RightContainer = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
    flex: 1 1 100%;
    padding-left: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  flex: 1 1 50%;
  padding-left: 20px;
`;

const LinkedHouseholdContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0 25px 0;
  font-size: 14px;
`;

const HouseholdName = styled.div`
  flex: 1 1 auto;
`;

const LoadingAnimationContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: ${colors.lightBlue};
`;

const LaunchImage = styled.img`
  cursor: pointer;
  width: 11px;
  margin-left: 8px;
`;

const UnlinkText = styled.span`
  flex: 0 0 auto;
  font-size: 13px;
  color: ${colors.lightBlue};
  cursor: pointer;
`;

const NotLinkedText = styled.span`
  flex: 0 0 auto;
  font-size: 13px;
  color: ${colors.paleGrey};
`;

const LinkedMembersContainer = styled.div`
  margin: 10px 0;
`;

const LinkedFinancialsContainer = styled.div`
  margin: 25px 0 5px 0;
`;

const AdditionalActionsContainer = styled.div`
  margin: 25px 0 5px 0;
  font-size: 14px;
`;

const LinkText = styled.a`
  cursor: pointer;
  display: inline-block;
  color: ${colors.lightBlue};
`;

const ExistingMemberLabel = styled.span`
  color: ${colors.paleGrey};
  font-size: 12px;
`;

const ViewLink = styled(Link)`
  color: ${colors.lightBlue};
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${colors.blue};
  }
  img {
    width: 12px;
    margin-right: 3px;
  }
`;

HouseholdIntegrations.propTypes = {
  householdId: PropTypes.string,
  selectedIntegration: PropTypes.object,
};

export default HouseholdIntegrations;
