import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, inputColors, buttonColors } from '../../styles/variables';

const Pagination = ({
  clickPrev,
  clickNext,
  goToPage,
  offset,
  count,
  limit,
  more,
  paddingBottom,
}) => {
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [range, setRange] = useState(1);

  useEffect(() => {
    if (offset === 0) {
      setShowPrev(false);
      setCurrentPage(1);
    } else {
      setShowPrev(true);
    }

    if (limit + offset >= count) {
      setShowNext(false);
    } else {
      setShowNext(true);
    }

    setRange(Math.ceil(count / limit));
  }, [offset, limit, count, setShowPrev, setShowNext]);

  // when to show more
  useEffect(() => {
    if (!showNext && more) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [more, showNext]);

  const prevClick = () => {
    setCurrentPage(parseInt(currentPage) - 1);
    clickPrev();
  };

  const nextClick = () => {
    setCurrentPage(parseInt(currentPage) + 1);
    clickNext();
  };

  const handleBlur = (e) => {
    const inputValue = e.target.value;
    let pageValue = inputValue;
    if (inputValue <= 0) {
      pageValue = 1;
    }
    if (inputValue > range) {
      pageValue = range;
    }
    setCurrentPage(pageValue);
    goToPage(pageValue);
    setShowInput(false);
  };

  const handleChange = (e) => {
    setCurrentPage(e.target.value);
  };

  return (
    <PaginationContainer paddingBottom={paddingBottom}>
      {goToPage && (
        <div>
          <CurrentPageSpan onClick={() => setShowInput(true)} show={showInput}>
            {currentPage}
          </CurrentPageSpan>
          <CurrentPageInput
            onBlur={handleBlur}
            onChange={handleChange}
            value={currentPage}
            show={showInput}
            placeholder={currentPage}
            size={range.toString().length + 1}
            max={range || 1}
            min={1}
            type="number"
          />{' '}
          of {range}
        </div>
      )}
      <Prev show={showPrev} onClick={prevClick}>
        Prev
      </Prev>
      <Next show={showNext} onClick={nextClick}>
        Next
      </Next>
      {showMore && (
        <More>
          Need more results? See <Link to={more.link}>{more.text}</Link>.
        </More>
      )}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  clickPrev: PropTypes.func,
  clickNext: PropTypes.func,
  goToPage: PropTypes.func,
  offset: PropTypes.number,
  count: PropTypes.number,
  limit: PropTypes.number,
  more: PropTypes.object,
  paddingBottom: PropTypes.string,
};

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 10px;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : null};
  div {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 14px;
    color: ${colors.paleGrey};
  }
`;

const CurrentPageSpan = styled.span`
  display: ${(props) => (!props.show ? 'inline' : 'none')};
  &:hover {
    color: ${colors.darkGrey};
    cursor: pointer;
  }
`;

const CurrentPageInput = styled.input`
  margin-top: -5px;
  border: 1px solid ${inputColors.border};
  color: ${colors.darkGrey};
  border-radius: 3px;
  display: ${(props) => (props.show ? 'inline' : 'none')};
`;

const Prev = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  cursor: pointer;
  &:hover {
    color: ${colors.darkGrey};
  }
`;

const Next = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  cursor: pointer;
  color: ${colors.paleGrey};
  &:hover {
    color: ${colors.darkGrey};
  }
`;

const More = styled.div`
  font-style: italic;
  color: ${colors.paleGrey};
  a {
    color: ${buttonColors.secondary};
  }
`;

export default Pagination;
