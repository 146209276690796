import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '..';
import { ButtonThemes } from '../../styles/themes';
import { colors, maxDevice, fonts } from '../../styles/variables';
import { DateFromNowUTC } from '../../utils';

const AuthDevice = ({ device, clearDevice }) => {
  return (
    <AuthDeviceOption>
      <AuthDeviceLeft>
        <img
          src={device.icon}
          alt={device.browser}
          data-image={device.browser}
        />
      </AuthDeviceLeft>
      <AuthOptionRight>
        <AuthContentLeft>
          <AuthLabelContainer>
            <AuthBrowserName>{device.browserLabel}</AuthBrowserName>
            <AuthDeviceName>{device.deviceLabel}</AuthDeviceName>
          </AuthLabelContainer>
          <AuthMethodDescription>{device.location}</AuthMethodDescription>
        </AuthContentLeft>
        <AuthContentRight>
          <Button
            text={'Clear'}
            onClick={() => clearDevice(device)}
            theme={ButtonThemes.inactiveBorder}
          />
          <LastSeenText>
            <strong>Last Seen:</strong>{' '}
            {device?.last_seen_utc
              ? DateFromNowUTC(device?.last_seen_utc)
              : 'Unknown'}
          </LastSeenText>
        </AuthContentRight>
      </AuthOptionRight>
    </AuthDeviceOption>
  );
};

const AuthDeviceOption = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 10px 12px;
  }
  border: 1px solid ${colors.hoverLightGrey};
  border-radius: 6px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 18px;
  background: white;
  margin-bottom: 15px;
  &:hover {
    background: ${colors.lighterGrey};
  }
`;

const AuthDeviceLeft = styled.div`
  @media ${maxDevice.sideNav} {
    width: 50px;
    height: 50px;
  }
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    @media ${maxDevice.sideNav} {
      max-width: 40px;
      max-height: 40px;
    }
    max-width: 50px;
    max-height: 50px;
  }
`;

const AuthOptionRight = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
`;

const AuthMethodDescription = styled.p`
  @media ${maxDevice.sideNav} {
    font-size: 11px;
  }
  font-size: 13px;
  color: ${colors.paleGrey};
`;

const AuthContentLeft = styled.div`
  flex: 1 1 auto;
  margin-right: 15px;
`;

const AuthContentRight = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  button {
    margin-bottom: 10px;
  }
`;

const LastSeenText = styled.p`
  @media ${maxDevice.sideNav} {
    font-size: 10px;
  }
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const AuthLabelContainer = styled.div`
  @media ${maxDevice.sideNav} {
    display: block;
  }
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
`;

const AuthBrowserName = styled.p`
  @media ${maxDevice.sideNav} {
    font-size: 14px;
    margin-right: 10px;
  }
  font-size: 16px;
  font-weight: ${fonts.semiBold};
  margin-right: 10px;
`;

const AuthDeviceName = styled.p`
  @media ${maxDevice.sideNav} {
    font-size: 11px;
  }
  font-size: 13px;
  color: ${colors.paleGrey};
`;

AuthDevice.propTypes = {
  device: PropTypes.object,
  clearDevice: PropTypes.func,
};

export default AuthDevice;
