/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { colors, fonts, targetMapColors } from '../../styles/variables';
import { FlexCenterAll } from '../../styles/library/layoutStyles';
import { DetermineTargetMapColor } from '../../utils';

const TargetMapPercentCircle = ({
  targetMap,
  width,
  emptyColor = colors.lightGrey,
  isList,
}) => {
  const generateColor = () => {
    const funded = targetMap.funded;
    if (funded <= 24) {
      return targetMapColors.red;
    } else if (funded <= 49) {
      return targetMapColors.orange;
    } else if (funded <= 74) {
      return targetMapColors.yellow;
    } else if (funded >= 75) {
      return targetMapColors.green;
    } else {
      return targetMap.color || DetermineTargetMapColor(targetMap.template);
    }
  };

  return (
    <ThemeProvider theme={{ isList: isList }}>
      <PercentContainer>
        <CircleProgressContainer $width={width}>
          <CircularProgressbarWithChildren
            value={targetMap.funded}
            strokeWidth={12}
            styles={{
              path: {
                stroke: generateColor(),
                strokeLinecap: 'butt',
              },
              trail: {
                stroke: emptyColor,
                strokeLinecap: 'butt',
              },
              text: {
                fill: colors.darkGrey,
                fontSize: '20px',
              },
            }}
          >
            <FundedPercent>
              {targetMap.funded}
              <sup>%</sup>
            </FundedPercent>
            <FundedLabel>Funded</FundedLabel>
          </CircularProgressbarWithChildren>
        </CircleProgressContainer>
      </PercentContainer>
    </ThemeProvider>
  );
};

const PercentContainer = styled.div`
  ${FlexCenterAll};
`;

const CircleProgressContainer = styled.div`
  width: ${(props) => (props.$width ? props.$width : '110px')};
  height: ${(props) => (props.$width ? props.$width : '110px')};
`;

const FundedPercent = styled.p`
  font-size: ${(props) => (props.theme.isList ? '18px' : '28px')};
  text-align: center;
  font-weight: ${fonts.bold};
  margin: -2px -6px 0 0;
  sup {
    font-size: ${(props) => (props.theme.isList ? '8px' : '10px')};
  }
`;

const FundedLabel = styled.p`
  font-size: ${(props) => (props.theme.isList ? '8px' : '10px')};
  text-align: center;
  font-weight: ${fonts.semiBold};
  margin-top: -3px;
`;

TargetMapPercentCircle.propTypes = {
  targetMap: PropTypes.object,
  width: PropTypes.string,
  emptyColor: PropTypes.string,
  isList: PropTypes.bool,
};

export default TargetMapPercentCircle;
