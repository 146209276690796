import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import App from './App';
// import store from './store/store';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store} stabilityCheck="never">
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change to register() below.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
