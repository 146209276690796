/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import {
  errorImage,
  walter,
  homeAlt,
  arrowBack,
  redo,
  logo,
  chevronDown,
} from '../assets';
import {
  UpdatePageTitle,
  CloseDropdowns,
  SuccessNotification,
  SendTrackingEvent,
} from '../utils';
import {
  heights,
  colors,
  maxDevice,
  messageColors,
  boxShadows,
  fonts,
} from '../styles/variables';
import { FlexCenterAll } from '../styles/library/layoutStyles';

const Error = () => {
  UpdatePageTitle('Error');
  CloseDropdowns();
  const { invocationId } = useSelector((state) => ({
    invocationId: state.configs.invocationId,
  }));
  const error = useRouteError();
  const [showMain, setShowMain] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showCallStack, setShowCallStack] = useState(false);

  useEffect(() => {
    const isEven = Math.round(Math.random()) === 0;
    setShowMain(isEven);
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    if (hasLoaded && error) {
      SendTrackingEvent(invocationId, 'action', 'error_display', null, {
        error: error?.stack ? error?.stack : error?.message,
      });
    }
  }, [error, hasLoaded]);

  const generateErrorText = () => {
    const currentLocation = window.location.href;
    const errorMessage = error?.message;
    const errorStack = error?.stack;
    const errorValue = `Error: ${errorMessage} occurred at ${currentLocation}. ${errorStack}`;
    copy(errorValue);
    SuccessNotification('Copied', 2.5, 'bottom-left', {
      size: ' 5px',
      style: 'solid',
    });
  };

  return (
    <ErrorPageContainer>
      <Navbar>
        <CenterContent>
          <a href={'/member/dashboard'}>
            <AppLogo src={logo} alt={'logo'} data-image="nav-logo" />
          </a>
        </CenterContent>
      </Navbar>
      {hasLoaded && (
        <ErrorContentContainer>
          <ErrorImageContainer
            src={showMain ? errorImage : walter}
            alt="Error"
            data-image="error-page"
          />
          <NotFoundText>
            {showMain
              ? `Uh Oh! It looks like something unexpected has happened.`
              : `Oh No! Looks like Walter got into the application.`}
          </NotFoundText>
          <ErrorMessageContainer
            onClick={() => setShowCallStack(!showCallStack)}
          >
            <ExpandContainer
              onClick={() => setShowCallStack(!showCallStack)}
              title="Show Error Call Stack"
              $expanded={showCallStack}
            >
              <img src={chevronDown} alt="expand" />
            </ExpandContainer>
            <code>
              <strong>Error: </strong>
              {error?.message}
            </code>
            {showCallStack && (
              <CallStack>
                <code>{error?.stack}</code>
              </CallStack>
            )}
          </ErrorMessageContainer>
          <ActionsRow>
            <ActionItem
              onClick={() => {
                window.history.back();
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }}
            >
              <img src={arrowBack} alt={'back'} data-image="back-arrow" />
              Go back
            </ActionItem>
            <ActionItem onClick={() => window.location.reload()}>
              <img src={redo} alt={'redo'} data-image="redo" />
              Refresh
            </ActionItem>
            <ActionItemLink href={'/member/dashboard'}>
              <img src={homeAlt} alt={'home'} data-image="home" />
              Go Home
            </ActionItemLink>
          </ActionsRow>
          <SupportContainer>
            <p>Still having problems?</p> Contact support with a screenshot of
            this page or{' '}
            <ClickValue onClick={() => generateErrorText()}>
              click here
            </ClickValue>{' '}
            to copy the error message.
          </SupportContainer>
        </ErrorContentContainer>
      )}
    </ErrorPageContainer>
  );
};

const ExpandContainer = styled.div`
  position: absolute;
  z-index: 8;
  bottom: -13px;
  transform: translateX(-50%);
  right: 50%;
  background: ${colors.white};
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: thin solid ${colors.lightGrey};
  ${FlexCenterAll};
  cursor: pointer;
  img {
    width: 13px;
    height: 13px;
    flex: 0 0 auto;
    transform: ${(props) => (props.$expanded ? 'rotate(180deg)' : null)};
  }
`;

const SupportContainer = styled.div`
  margin: 50px 0;
  font-size: 12px;
  color: ${colors.paleGrey};
  text-align: center;
  p {
    /* color: ${colors.darkGrey}; */
    font-size: 14px;
    font-weight: ${fonts.semiBold};
    margin-bottom: 8px;
  }
`;

const ClickValue = styled.span`
  font-weight: ${fonts.semiBold};
  cursor: pointer;
  color: ${colors.lightBlue};
`;

const ErrorMessageContainer = styled.div`
  @media ${maxDevice.mobileL} {
    padding: 8px 10px;
    border-radius: 3px;
    margin-bottom: 40px;
    font-size: 12px;
  }
  border: thin solid ${colors.lightGrey};
  background: ${colors.lighterGrey};
  padding: 12px 20px;
  border-radius: 3px;
  margin-bottom: 60px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  code {
    strong {
      font-family: monospace;
    }
    font-family: monospace;
    color: ${messageColors.error};
  }
`;

const CallStack = styled.p`
  font-size: 12px;
  max-width: 700px;
  margin-top: 5px;
  code {
    color: ${colors.paleGrey};
  }
`;

const ActionsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const ActionItem = styled.div`
  @media ${maxDevice.mobileL} {
    flex: 1 1 auto;
    width: 100%;
    margin: 5px 0;
  }
  flex: 0 0 auto;
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: thin solid ${colors.lightGrey};
  padding: 10px;
  border-radius: 6px;
  margin: 5px 15px;
  cursor: pointer;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  img {
    /* width: 20px; */
    height: 18px;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: ${boxShadows.boxShadowSoft};
  }
`;

const ActionItemLink = styled.a`
  @media ${maxDevice.mobileL} {
    flex: 1 1 auto;
    width: 100%;
    margin: 5px 0;
  }
  flex: 0 0 auto;
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: thin solid ${colors.lightGrey};
  padding: 10px;
  border-radius: 6px;
  margin: 5px 15px;
  cursor: pointer;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  img {
    /* width: 20px; */
    height: 18px;
    margin-right: 10px;
  }
  &:hover {
    box-shadow: ${boxShadows.boxShadowSoft};
  }
`;

const ErrorImageContainer = styled.img`
  @media ${maxDevice.mobileL} {
    width: 100%;
    max-width: 400px;
    height: auto;
    max-height: auto;
  }
  height: 100%;
  max-height: 350px;
  margin: 40px 0 10px 0;
`;

const ErrorPageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ErrorContentContainer = styled.div`
  @media ${maxDevice.tablet} {
    padding: 0 30px;
  }
  padding: 0 40px;
  position: relative;
  top: ${heights.navBar};
  height: calc(100vh - ${heights.navBar});
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const AppLogo = styled.img`
  width: 100%;
  position: relative;
  max-width: 180px;
  min-width: 150px;
`;

const Navbar = styled.nav`
  position: fixed;
  height: ${heights.navBar};
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: white;
  box-shadow: ${boxShadows.boxShadowNavbar};
  z-index: 500;
`;

const CenterContent = styled.div`
  flex: 0 0 12%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  a {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

const NotFoundText = styled.p`
  @media ${maxDevice.tablet} {
    margin: 40px 0;
    font-size: 24px;
  }
  @media ${maxDevice.mobileL} {
    margin: 40px 0;
    font-size: 20px;
  }
  margin: 20px 40px 20px 40px;
  font-size: 28px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Error;
