import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import apiMiddleware from '../utils/api';

// const configureStore = () => {
//   const composeEnhancers =
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//   //STORE IS CREATED AND USES API MIDDLEWARE
//   const store = createStore(
//     rootReducer,
//     composeEnhancers(applyMiddleware(apiMiddleware))
//   );

//   return store;
// };

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(apiMiddleware),
});

export default store;
