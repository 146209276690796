/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { darken } from 'polished';
import {
  getDelegateAdvisorList,
  setDelegateAdvisor,
  logoutDelegate,
} from '../../store/actions';
import { userProfiles, timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
} from '../../styles/library/modalStyles';
import { heights, colors, fonts } from '../../styles/variables';

const DelegateModal = ({ canHide, hide }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { delegateAdvisorList, user } = useSelector((state) => ({
    delegateAdvisorList: state.user.delegateAdvisorList,
    user: state.user.user,
  }));
  const [advisorList, setAdvisorList] = useState([]);
  const [loadingAdvisors, setLoadingAdvisors] = useState(true);

  useEffect(() => {
    dispatch(getDelegateAdvisorList());
  }, []);

  useEffect(() => {
    if (delegateAdvisorList) {
      setLoadingAdvisors(false);
      setAdvisorList(delegateAdvisorList);
    }
  }, [delegateAdvisorList]);

  const selectAdvisor = (advisor) => {
    dispatch(setDelegateAdvisor(advisor));
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground
        style={{ top: heights.navBar, background: 'rgba(0,0,0,.7)' }}
      />
      <ModalOverlay style={{ top: heights.navBar }}>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Change User Role</ModalHeading>
            {canHide && (
              <CloseButton
                src={timesWhite}
                onClick={() => hide()}
                data-image="close"
              />
            )}
          </ModalHeadingContainer>
          <ModalContentContainer
            style={{ maxHeight: '500px', overflowY: 'auto' }}
          >
            <SubHeader>Select an Advisor</SubHeader>
            <AdvisorListContainer>
              {loadingAdvisors ? (
                [1].map((index) => {
                  return (
                    <AdvisorContainerSkeleton key={index}>
                      <AdvisorAvatarContainer>
                        <AdvisorAvatarSkeleton />
                      </AdvisorAvatarContainer>
                      <AdvisorInfoContainer>
                        <AdvisorNameSkeleton />
                        <AdvisorEmailSkeleton />
                      </AdvisorInfoContainer>
                    </AdvisorContainerSkeleton>
                  );
                })
              ) : advisorList.length !== 0 ? (
                advisorList.map((advisor, index) => {
                  return (
                    <AdvisorContainer
                      key={index}
                      onClick={() => selectAdvisor(advisor)}
                    >
                      <AdvisorAvatarContainer>
                        <AdvisorAvatar
                          src={advisor.avatar}
                          alt="advisor avatar"
                          data-image={`advisor-avatar-${index}`}
                        />
                      </AdvisorAvatarContainer>
                      <AdvisorInfoContainer>
                        <AdvisorName>{advisor.name}</AdvisorName>
                        <AdvisorEmail>{advisor.email}</AdvisorEmail>
                      </AdvisorInfoContainer>
                    </AdvisorContainer>
                  );
                })
              ) : (
                <NoAdvisorsContainer>
                  <NoAdvisorsImage
                    src={userProfiles}
                    alt="No Advisors"
                    data-image="no-advisors"
                  />
                  <NoAdvisorsText>No Advisors</NoAdvisorsText>
                  <NoAdvisorsSubText>
                    {`Please contact your Team Manager to make sure you are selected as a Delegate for at least one advisor.`}
                  </NoAdvisorsSubText>
                </NoAdvisorsContainer>
              )}
            </AdvisorListContainer>
            {canHide && (
              <LogoutOption onClick={() => dispatch(logoutDelegate())}>
                Log out of {user.name}
              </LogoutOption>
            )}
          </ModalContentContainer>
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const NoAdvisorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const NoAdvisorsImage = styled.img`
  max-width: 350px;
`;

const NoAdvisorsText = styled.p`
  margin: 20px 15px 5px 15px;
  font-size: 24px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

const NoAdvisorsSubText = styled.p`
  margin: 5px 15px 0 15px;
  font-size: 13px;
  text-align: center;
  color: ${colors.paleGrey};
`;

const SubHeader = styled.div`
  color: ${colors.darkGrey};
  text-align: left;
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  margin: 10px 0;
`;

const LogoutOption = styled.p`
  margin-bottom: 5px;
  color: ${colors.lightBlue};
  cursor: pointer;
  &:hover {
    color: ${colors.blue};
  }
`;

const AdvisorListContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

const AdvisorContainer = styled.div`
  cursor: pointer;
  height: 74px;
  margin-bottom: 10px;
  flex: 1 1 auto;
  text-align: left;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  padding: 10px 15px;
  background: ${colors.lighterGrey};
  &:hover {
    background-color: ${() => darken(0.05, colors.lighterGrey)};
  }
`;

const AdvisorContainerSkeleton = styled(AdvisorContainer)`
  &:hover {
    background: ${colors.lighterGrey};
  }
`;

const AdvisorInfoContainer = styled.div`
  color: ${colors.darkGrey};
`;

const AdvisorAvatarContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const AdvisorAvatar = styled.img`
  border-radius: 50px;
  background: ${colors.lightGrey};
  width: 70px;
  height: 70px;
  &:hover {
    opacity: 0.9;
  }
`;

const AdvisorAvatarSkeleton = styled.div`
  border-radius: 50px;
  background: ${colors.lightGrey};
  width: 70px;
  height: 70px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const AdvisorName = styled.p`
  font-size: 18px;
  font-weight: ${fonts.semiBold};
`;

const AdvisorNameSkeleton = styled(AdvisorName)`
  font-size: 18px;
  font-weight: ${fonts.semiBold};
  height: 24px;
  background: ${colors.lightGrey};
  width: 100px;
  margin-bottom: 3px;
`;

const AdvisorEmail = styled.p`
  font-size: 14px;
  font-weight: ${fonts.light};
`;

const AdvisorEmailSkeleton = styled(AdvisorEmail)`
  height: 20px;
  background: ${colors.lightGrey};
  width: 150px;
`;

export default DelegateModal;
