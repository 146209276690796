/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import {
  MemberModal,
  EntityModal,
  ProfessionalModal,
  SimpleModal,
  StencilModal,
} from '../../components';
import {
  AssetModal,
  CashFlowModal,
  LiabilityModal,
  InsuranceModal,
  SideNavContainer,
} from '../../containers';
import {
  getHouseholdConfigs,
  getConsumerHouseholdRole,
  getConsumerHouseholdInfo,
  removeHouseholdInfo,
  setCurrencyObj,
  toggleShowMemberModal,
  toggleShowEntityModal,
  toggleShowProfessionalModal,
  toggleShowAssetModal,
  toggleShowCashFlowModal,
  toggleShowLiabilityModal,
  toggleShowInsuranceModal,
  getCustomUserPreferences,
  getHouseholdFields,
  setKffPanelValues,
  refreshHouseholdAssetMapImage,
  getHouseholdKFFs,
  getHouseholdAssetMapImage,
  toggleShowDeleteModal,
  deleteMember,
  deleteInstrument,
  setCanEditHousehold,
  deleteAssetMapLayout,
  toggleShowStencilModal,
  updateUserStoreValue,
  deleteProfessional,
  // exitClientPreviewMode,
} from '../../store/actions';
import {
  Page,
  CenteredContainerRow,
  LeftNavOutlet,
  // PreviewContainer,
} from '../../styles/library/layoutStyles';
import {
  CloseDropdowns,
  UpdatePageTitle,
  SendTrackingEvent,
} from '../../utils';
import { ButtonThemes } from '../../styles/themes';

const ConsumerHousehold = () => {
  const params = useParams();
  const householdId = params?.id;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  CloseDropdowns();
  const {
    isPreview,
    consumerHouseholdRole,
    currentHousehold,
    canEdit,
    sideNavWidth,
    householdKffs,
    kffPreferences,
    indexFields,
    amrImage,
    refreshedImage,
    householdCurrency,
    currency,
    currencies,
    showingMemberModal,
    memberDefaultTab,
    showingProfessionalModal,
    professionalDefaultTab,
    showingEntityModal,
    entityDefaultTab,
    showingAssetModal,
    assetDefaultTab,
    showingCashFlowModal,
    cashFlowDefaultTab,
    showingLiabilityModal,
    liabilityDefaultTab,
    showingInsuranceModal,
    insuranceDefaultTab,
    showingUploadFileModal,
    instrumentAction,
    instrumentId,
    memberAction,
    memberId,
    isNewMember,
    isPersonal,
    isExcluded,
    isNewEntity,
    isNewProfessional,
    isNewAsset,
    isNewCashFlow,
    isNewLiability,
    isNewInsurance,
    deleteModal,
    modifiedInstrument,
    modifiedInstruments,
    clonedInstrument,
    showingStencilModal,
    invocationId,
  } = useSelector((state) => ({
    isPreview: state.user.isPreview,
    consumerHouseholdRole: state.households.consumerHouseholdRole,
    currentHousehold: state.households.currentHousehold,
    canEdit: state.households.canEditHousehold,
    sideNavWidth: state.user.sideNavWidth,
    householdKffs: state.households.householdKffs,
    kffPreferences: state.user?.consumer?.kff,
    indexFields: state.configs.indexFields,
    amrImage: state.households.amrImage,
    refreshedImage: state.households.refreshedImage,
    householdCurrency: state.households.householdCurrency,
    currency: state.households.currentHousehold.currency,
    currencies: state.configs.currencies,
    showingMemberModal: state.members.showingMemberModal,
    memberDefaultTab: state.members.memberDefaultTab,
    showingProfessionalModal: state.members.showingProfessionalModal,
    professionalDefaultTab: state.members.professionalDefaultTab,
    showingEntityModal: state.members.showingEntityModal,
    entityDefaultTab: state.members.entityDefaultTab,
    showingAssetModal: state.instruments.showingAssetModal,
    assetDefaultTab: state.instruments.assetDefaultTab,
    showingCashFlowModal: state.instruments.showingCashFlowModal,
    cashFlowDefaultTab: state.instruments.cashFlowDefaultTab,
    showingLiabilityModal: state.instruments.showingLiabilityModal,
    liabilityDefaultTab: state.instruments.liabilityDefaultTab,
    showingInsuranceModal: state.instruments.showingInsuranceModal,
    insuranceDefaultTab: state.instruments.insuranceDefaultTab,
    showingUploadFileModal: state.households.showingUploadFileModal,
    instrumentAction: state.instruments.instrumentAction,
    instrumentId: state.instruments.instrumentId,
    memberAction: state.members.memberAction,
    memberId: state.members.memberId,
    isNewMember: state.members.isNewMember,
    isPersonal: state.members.isPersonal,
    isExcluded: state.members.isExcluded,
    isNewEntity: state.members.isNewEntity,
    isNewProfessional: state.members.isNewProfessional,
    isNewAsset: state.instruments.isNewAsset,
    isNewCashFlow: state.instruments.isNewCashFlow,
    isNewLiability: state.instruments.isNewLiability,
    isNewInsurance: state.instruments.isNewInsurance,
    deleteModal: state.households.deleteModal,
    modifiedInstrument: state.instruments.modifiedInstrument,
    modifiedInstruments: state.households.modifiedInstruments,
    clonedInstrument: state.instruments.clonedInstrument,
    showingStencilModal: state.households.showingStencilModal,
    invocationId: state.configs.invocationId,
  }));
  // const [hasHouseholdRole, setHasHouseholdRole] = useState(null);
  const [isViewOnly, setIsViewOnly] = useState(true);
  // const readOnlyRoles = ['connect', 'reader'];
  const writeRoles = ['owner', 'writer'];

  const setCurrency = (currency, currencies) => {
    let matchedCurrency = currencies.find((cur) => {
      return cur.code === currency;
    });
    if (matchedCurrency?.decimal === '') {
      matchedCurrency.decimal = '.';
    }
    dispatch(setCurrencyObj(matchedCurrency));
  };

  UpdatePageTitle(currentHousehold.name || 'Loading');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    dispatch(getHouseholdConfigs(householdId));
    dispatch(getConsumerHouseholdRole(householdId));
    dispatch(getHouseholdFields(householdId));
    dispatch(getHouseholdAssetMapImage(householdId, !isPreview));
    dispatch(getHouseholdKFFs(householdId, true));
    dispatch(updateUserStoreValue('justLoggedIn', false));
    return () => {
      dispatch(removeHouseholdInfo(householdId));
    };
  }, [householdId]);

  /*
    ROLES
    - connect - associated with household member
    - owner - has full access
    - writer - write access
    - advisor - can make suggestions
    - reader - read only access
  */
  useEffect(() => {
    if (consumerHouseholdRole) {
      if (writeRoles.includes(consumerHouseholdRole)) {
        setIsViewOnly(false);
        dispatch(setCanEditHousehold(true));
      } else {
        setIsViewOnly(true);
        dispatch(setCanEditHousehold(false));
      }
      // setHasHouseholdRole(true);
      dispatch(getConsumerHouseholdInfo(householdId));
      dispatch(getCustomUserPreferences());
    }
    //  else {
    //   setHasHouseholdRole(false);
    // }
  }, [consumerHouseholdRole]);

  useEffect(() => {
    if (refreshedImage === false && amrImage) {
      if (
        !amrImage.hasOwnProperty('refresh') &&
        (!amrImage.fresh || amrImage.is_error)
      ) {
        dispatch(
          refreshHouseholdAssetMapImage(
            householdId,
            amrImage.is_error ? 'force' : true,
            !isPreview
          )
        );
      }
    }
  }, [refreshedImage, amrImage]);

  useEffect(() => {
    if (modifiedInstrument || modifiedInstruments) {
      dispatch(getHouseholdKFFs(householdId, true));
    }
  }, [modifiedInstrument, modifiedInstruments]);

  useEffect(() => {
    if (clonedInstrument) {
      dispatch(getConsumerHouseholdInfo(householdId));
    }
  }, [clonedInstrument]);

  useEffect(() => {
    if (currency && currencies) {
      setCurrency(currency, currencies);
    }
  }, [currency, currencies]);

  //Need to check consumer kff array to make the transformations
  useEffect(() => {
    if (
      householdKffs !== null &&
      kffPreferences !== undefined &&
      indexFields !== undefined
    ) {
      const matchedKffs = kffPreferences?.map((kff) => {
        let matchingIndex = indexFields.find(
          (field) => field.fieldName === kff
        );
        let value = householdKffs[kff];
        return { ...matchingIndex, value: value };
      });
      dispatch(setKffPanelValues(matchedKffs));
    }
  }, [householdKffs, kffPreferences, indexFields]);

  useEffect(() => {
    if (instrumentAction && instrumentId) {
      SendTrackingEvent(
        invocationId,
        'action',
        `instrument_${instrumentAction}`,
        null,
        {
          household: householdId,
          instrument: instrumentId,
        }
      );
    }
  }, [instrumentAction, instrumentId]);

  useEffect(() => {
    if (memberAction && memberId) {
      SendTrackingEvent(
        invocationId,
        'action',
        `householdmember_${memberAction}`,
        null,
        {
          household: householdId,
          householdmember: memberId,
        }
      );
    }
  }, [memberAction, memberId]);

  const generateDeleteModalContent = () => {
    let yesButton = {
      text: 'Yes',
      theme: ButtonThemes.primary,
    };
    let displayType = deleteModal?.typeObject?.displayText;
    const group = deleteModal?.typeObject?.group;
    if (group === 'member') {
      yesButton.function = () =>
        dispatch(deleteMember(householdId, deleteModal.id, displayType, true));
    }
    if (group === 'professional') {
      yesButton.function = () =>
        dispatch(
          deleteProfessional(householdId, deleteModal.id, displayType, true)
        );
    }
    if (group === 'instrument') {
      yesButton.function = () =>
        dispatch(
          deleteInstrument(householdId, deleteModal.id, displayType, true)
        );
    }
    if (group === 'amr-layout') {
      yesButton.function = () =>
        dispatch(deleteAssetMapLayout(householdId, deleteModal.id, true));
    }
    return {
      heading: `Delete ${displayType}?`,
      text: `Are you sure you want to delete<br/><strong>${deleteModal.name}</strong>?<br/> This cannot be undone.`,
      buttons: [
        {
          text: 'No',
          function: () => dispatch(toggleShowDeleteModal(false)),
          theme: ButtonThemes.cancel,
        },
        yesButton,
      ],
    };
  };

  return (
    <>
      {deleteModal?.showModal && (
        <SimpleModal
          hide={() => dispatch(toggleShowDeleteModal(!deleteModal.showModal))}
          content={generateDeleteModalContent()}
        />
      )}
      {showingMemberModal && (
        <MemberModal
          viewOnly={isViewOnly}
          defaultTab={memberDefaultTab}
          isNew={isNewMember}
          isPersonal={isPersonal}
          hide={() => dispatch(toggleShowMemberModal(!showingMemberModal))}
          householdId={householdId}
          invisible={showingUploadFileModal}
          isConsumer={true}
        />
      )}
      {showingEntityModal && (
        <EntityModal
          viewOnly={isViewOnly}
          defaultTab={entityDefaultTab}
          isNew={isNewEntity}
          isExcluded={isExcluded}
          hide={() => dispatch(toggleShowEntityModal(!showingEntityModal))}
          householdId={householdId}
          invisible={showingUploadFileModal}
          isConsumer={true}
        />
      )}
      {showingProfessionalModal && (
        <ProfessionalModal
          viewOnly={isViewOnly}
          defaultTab={professionalDefaultTab}
          isNew={isNewProfessional}
          hide={() =>
            dispatch(toggleShowProfessionalModal(!showingProfessionalModal))
          }
          invisible={showingUploadFileModal}
          householdId={householdId}
          isConsumer={true}
        />
      )}
      {showingCashFlowModal && (
        <CashFlowModal
          viewOnly={isViewOnly}
          defaultTab={cashFlowDefaultTab}
          isNew={isNewCashFlow}
          hide={() => dispatch(toggleShowCashFlowModal(!showingCashFlowModal))}
          householdId={householdId}
          currency={householdCurrency}
          invisible={showingUploadFileModal}
          isConsumer={true}
        />
      )}
      {showingAssetModal && (
        <AssetModal
          viewOnly={isViewOnly}
          defaultTab={assetDefaultTab}
          isNew={isNewAsset}
          hide={() => dispatch(toggleShowAssetModal(!showingAssetModal))}
          householdId={householdId}
          currency={householdCurrency}
          invisible={showingUploadFileModal}
          isConsumer={true}
        />
      )}
      {showingLiabilityModal && (
        <LiabilityModal
          viewOnly={isViewOnly}
          defaultTab={liabilityDefaultTab}
          isNew={isNewLiability}
          hide={() =>
            dispatch(toggleShowLiabilityModal(!showingLiabilityModal))
          }
          householdId={householdId}
          currency={householdCurrency}
          invisible={showingUploadFileModal}
          isConsumer={true}
        />
      )}
      {showingInsuranceModal && (
        <InsuranceModal
          viewOnly={isViewOnly}
          defaultTab={insuranceDefaultTab}
          isNew={isNewInsurance}
          hide={() =>
            dispatch(toggleShowInsuranceModal(!showingInsuranceModal))
          }
          householdId={householdId}
          currency={householdCurrency}
          invisible={showingUploadFileModal}
          isConsumer={true}
        />
      )}
      {showingStencilModal && (
        <StencilModal
          hide={() => dispatch(toggleShowStencilModal(!showingStencilModal))}
          householdId={householdId}
          isConsumer={true}
        />
      )}
      <Page>
        {/* {isPreview && (
          <PreviewContainer onClick={() => dispatch(exitClientPreviewMode())}>
            Exit Preview
          </PreviewContainer>
        )} */}
        <CenteredContainerRow>
          <SideNavContainer isConsumer={true} />
          <LeftNavOutlet style={{ marginLeft: sideNavWidth }}>
            <Outlet context={{ canEdit, sideNavWidth }} />
          </LeftNavOutlet>
        </CenteredContainerRow>
      </Page>
      <form
        method="post"
        action={`/generate/comprehensive/pdf/${currentHousehold.id}/`}
        id="generate_pdf_form"
        target="_blank"
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ConsumerHousehold;
