/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReportGenerateItem } from '../';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import { colors, fonts } from '../../styles/variables';
import { reportsList } from '../../utils';

const ReportDetailsModal = ({ hide, report }) => {
  const ref = useRef();
  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (report) {
      const { details } = report;
      // const transformedMembers = members.map((member) => {
      //   let updatedMember = { ...member };
      //   updatedMember.label = member.name;
      //   updatedMember.value = member.id;
      //   updatedMember.selected = true;
      //   updatedMember.pages = 1;
      //   if (member.member_type === 'member') {
      //     updatedMember.showAge = true;
      //   }
      //   return updatedMember;
      // });

      const transformedReports = reportsList.map((report) => {
        let updated = {
          ...report,
        };
        updated.selected = details.includes(updated.value);
        updated.hasOptions = false;
        // const hasChildren = content[updated.childValue]?.length;
        // updated.hasOptions = hasChildren;
        // if (hasChildren) {
        //   const updatedMembers = content[updated.childValue].reduce(
        //     (acc, curr) => {
        //       const matching = transformedMembers.find(
        //         (member) => member.id === curr
        //       );
        //       if (matching) {
        //         return [...acc, matching];
        //       }
        //       return [...acc];
        //     },
        //     []
        //   );
        //   updated.children = updatedMembers;
        // }
        updated.expanded = false;
        return updated;
      });
      setReports(transformedReports);
    }
  }, [report]);

  const expandItem = (val) => {
    setReports(
      reports.map((report) => {
        if (report.value === val) {
          if (report.hasOptions) {
            report.expanded = !report.expanded;
          }
        }
        return report;
      })
    );
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Report Details</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <SubText>
              Here are the details about what was included in this Comprehensive
              Report.
            </SubText>
            <TabContent style={{ marginBottom: 0 }}>
              <ReportsListContainer>
                {reports.map((report, index) => {
                  return (
                    <ReportGenerateItem
                      key={index}
                      report={report}
                      full={false}
                      expandItem={expandItem}
                      last={index === reports.length - 1}
                    />
                  );
                })}
              </ReportsListContainer>
            </TabContent>
          </ModalContentContainer>
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const ReportsListContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SubText = styled.p`
  margin-bottom: 15px;
  font-size: 12px;
  color: ${colors.darkGrey};
`;

export const SelectDropdownOption = styled.div`
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
  label {
    flex: 1 1 auto;
    margin-left: 8px;
    width: 220px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SelectName = styled.span`
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
`;

export const SelectDescription = styled.span`
  font-weight: ${fonts.regular};
  margin-left: 8px;
  font-size: 11px;
  color: ${colors.paleGrey};
`;

ReportDetailsModal.propTypes = {
  hide: PropTypes.func,
  report: PropTypes.object,
};

export default ReportDetailsModal;
