/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import Chart from 'chart.js/auto';
import {
  Button,
  Select,
  TypeaheadSearch,
  LoadingAnimation,
} from '../../components';
import { searchMemberActivity } from '../../store/actions';
import { fileExport } from '../../assets';
import { fonts, inputColors, maxDevice, colors } from '../../styles/variables';
import { ButtonThemes, selectThemes } from '../../styles/themes';
import { ActivityOption } from '../../utils';

const ManagerActivityContainer = () => {
  const dispatch = useDispatch();
  const chartRef = useRef();
  const { teamSettings, activitySearchResults } = useSelector((state) => ({
    teamSettings: state.user.teamSettings,
    activitySearchResults: state.members.activitySearchResults,
  }));
  const [barChartData, setBarChartData] = useState();
  const [activityResults, setActivityResults] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedMembers, setSelectedMembers] = useState(null);
  const [days, setDays] = useState(30);
  const [daysSelected, setDaysSelected] = useState({ label: 30, value: 30 });
  const [selectedVerbs, setSelectedVerbs] = useState();
  const [groupNameToDisplay, setGroupNameToDisplay] = useState();
  const [chartValue, setChartValue] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [csvToExport, setCsvToExport] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [noOptionMessage, setNoOptionMessage] = useState(
    'Start typing to begin search'
  );
  const [query, setQuery] = useState('');
  const [verbOptions, setVerbOptions] = useState([
    { value: 'api_create_householdmember', label: 'Household Member Created' },
    { value: 'api_create_instrument', label: 'Instrument Created' },
    { value: 'api_update_householdmember', label: 'Household Member Updated' },
    { value: 'api_update_instrument', label: 'Instrument Updated' },
    { value: 'api_update_targetmap', label: 'Targetmap Updated' },
    { value: 'create_household', label: 'Household Created' },
    { value: 'create_targetmap', label: 'Targetmap Created' },
    { value: 'discovery_create_householdmember', label: 'Discovery Completed' },
    { value: 'discovery_create_concern', label: 'Discovery Sent' },
    { value: 'edit_household_info', label: 'Household Updated' },
    {
      value: 'generate_pdf_comprehensive',
      label: 'Comprehensive Report Generated',
    },
    { value: 'login', label: 'Logged In' },
  ]);
  const csvDlRef = useRef();
  const dayOptions = [];

  for (var i = 5; i < 31; i++) {
    let tmp_obj = { value: i, label: i };
    dayOptions.push(tmp_obj);
  }

  //this double useEffect seems weird, but is needed to prevent first
  useEffect(() => {
    if (firstLoad) {
    } else {
      if (Array.isArray(csvToExport) && csvToExport.length) {
        setCsvData(csvToExport);
      }
    }
  }, [csvToExport]);

  useEffect(() => {
    if (Array.isArray(csvData) && csvData.length) {
      // eslint-disable-next-line
      let action = csvDlRef.current?.link.click();
    }
  }, [csvData]);

  useEffect(() => {
    if (activitySearchResults) {
      if (!activitySearchResults.no_data) {
        setBarChartData(activitySearchResults.config);
      }
      setActivityResults(activitySearchResults);
      if (firstLoad) {
        setSelectedGroup(activitySearchResults.groups[0]);
        setFirstLoad(false);
      }
    }
  }, [activitySearchResults]);

  useEffect(() => {
    if (barChartData) {
      if (chartValue) {
        chartValue.destroy();
      }
      const myChartRef = chartRef?.current?.getContext('2d');
      Chart.defaults.font.family =
        "'Open Sans','Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

      var data = JSON.parse(barChartData);

      // eslint-disable-next-line no-unused-vars
      const myBarChart = new Chart(myChartRef, {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'User Activity - Last ' + days + ' days',
            },
            legend: {
              position: 'top',
              onClick: function (e, legendItem) {
                var index = legendItem.datasetIndex;
                var ci = this.chart;
                var alreadyHidden =
                  ci.getDatasetMeta(index).hidden === null
                    ? false
                    : ci.getDatasetMeta(index).hidden;

                ci.data.datasets.forEach(function (e, i) {
                  var meta = ci.getDatasetMeta(i);

                  if (i !== index) {
                    if (!alreadyHidden) {
                      meta.hidden = meta.hidden === null ? !meta.hidden : null;
                    } else if (meta.hidden === null) {
                      meta.hidden = true;
                    }
                  } else if (i === index) {
                    meta.hidden = null;
                  }
                });

                ci.update();
              },
            },
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              stacked: true,
              title: {
                display: true,
                text: 'Days Ago',
              },
              ticks: {
                major: {
                  enabled: true,
                },
              },
            },
            y: {
              display: true,
              beginAtZero: 0,
              suggestedMax: 50,
              stacked: true,
              title: {
                display: false,
              },
              ticks: {
                major: {
                  enabled: true,
                },
              },
            },
          },
        },
      });
      setChartValue(myBarChart);
    }
  }, [barChartData, activityResults]);

  const changeQuery = (newQuery) => {
    if (newQuery === null || newQuery === '' || newQuery === ' ') {
      setQuery('');
      setNoOptionMessage('Start typing to begin search');
    } else {
      setQuery(newQuery);
      setNoOptionMessage('No results');
    }
  };

  const loadOptions = () => {
    if (query) {
      return fetch(
        `/api/v3/activity-monitor/${selectedGroup.value}/member_search/${query}`
      ).then((res) => res.json());
    }
  };

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
    setSelectedMembers(null);
  };
  const handleMemberChange = (selections) => {
    if (selections != null) {
      if (selections.length <= 5) {
        setSelectedMembers(selections);
      } else {
        setSelectedMembers(selections.slice(0, 5));
      }
    } else {
      setSelectedMembers(selections);
    }
  };

  const handleDayChange = (daysSelected) => {
    setDays(daysSelected.value);
    setDaysSelected(daysSelected);
  };

  const handleVerbChange = (selectedVerb) => {
    setVerbOptions(
      verbOptions.map((verb) => {
        if (verb.value === selectedVerb.value) {
          verb.selected = !verb.selected;
        }
        return verb;
      })
    );
    const selectedVerbNames = verbOptions.reduce((acc, verb) => {
      if (verb.selected) {
        return [...acc, verb.label];
      }
      return acc;
    }, []);
    if (selectedVerbNames.length !== 0) {
      const labelValue = selectedVerbNames.join(', ');
      setSelectedVerbs({ label: labelValue, value: '' });
    } else {
      setSelectedVerbs('');
    }
  };

  const getActivityOnClick = () => {
    let member_ids = [];
    if (selectedMembers) {
      member_ids = selectedMembers.map((member) => member.value);
    }
    const selectedVerbs = verbOptions.reduce((acc, verb) => {
      if (verb.selected) {
        return [...acc, verb.value];
      }
      return acc;
    }, []);
    setBarChartData();
    if (member_ids.length === 0) {
      dispatch(
        searchMemberActivity(null, selectedGroup.value, days, selectedVerbs)
      );
      setGroupNameToDisplay(selectedGroup);
    } else {
      dispatch(
        searchMemberActivity(
          member_ids,
          selectedGroup.value,
          days,
          selectedVerbs
        )
      );
      setGroupNameToDisplay(selectedGroup);
    }
  };

  const resetOnClick = () => {
    setSelectedMembers(null);
    setSelectedVerbs('');
    setVerbOptions(
      verbOptions.map((verb) => {
        verb.selected = false;
        return verb;
      })
    );
    dispatch(searchMemberActivity());
    setSelectedGroup(activitySearchResults?.groups[0]);
    setGroupNameToDisplay(activitySearchResults?.groups[0].label);
    setBarChartData();
    setDaysSelected(dayOptions[25]);
    setDays(dayOptions[25].value);
  };

  const reducer = (accumulator, curr) => accumulator + curr;

  const exportTable = () => {
    if (!activitySearchResults.no_data) {
      let lastIndex = activityResults.data.login.length - 1;
      let csvTmp = [Object.keys(activityResults.series)];
      csvTmp[0].splice(0, 0, 'Days Ago');
      let tmp_obj = {};
      let idx = 1;
      csvTmp[0].forEach((item) => {
        if (item !== 'Days Ago') {
          tmp_obj[idx] = item;
        }
        idx = idx + 1;
      });
      let lastIndexCopy = lastIndex;
      for (let i = 0; i <= lastIndexCopy; i++) {
        let tmpRow = [];
        // eslint-disable-next-line
        csvTmp[0].forEach((item) => {
          if (item !== 'Days Ago') {
            if (activityResults.data[item]) {
              tmpRow.push(activityResults.data[item][lastIndex]);
            } else {
              tmpRow.push(0);
            }
          } else {
            tmpRow.push(i);
          }
        });
        csvTmp.push(tmpRow);
        lastIndex = lastIndex - 1;
      }
      let tmpRow = [];
      csvTmp[0].forEach((item) => {
        if (item !== 'Days Ago') {
          if (activityResults.data[item]) {
            tmpRow.push(activityResults.data[item].reduce(reducer));
          } else {
            tmpRow.push(0);
          }
        } else {
          tmpRow.push('Total');
        }
      });
      csvTmp.push(tmpRow);
      let consumableHeaders = [];
      csvTmp[0].forEach((item) => {
        if (activityResults.series[item]) {
          consumableHeaders.push(activityResults.series[item]);
        }
      });
      consumableHeaders.splice(0, 0, 'Days Ago');
      csvTmp[0] = consumableHeaders;
      setCsvToExport(csvTmp);
    }
  };

  return (
    <ActivityTabContainer>
      <ActivityResultsContainer>
        <FilterOptionsContainer>
          <FormHeader>Graph Filter Options</FormHeader>
          <FilterRow>
            <FilterItem>
              <InputLabel>Group</InputLabel>
              <SelectContainer>
                <Select
                  options={activitySearchResults?.groups}
                  onChange={handleGroupChange}
                  placeholder={'Select Group'}
                  selectedOption={selectedGroup}
                  isSearchable={true}
                  loadingIndicator={true}
                  autoFocus={false}
                  styles={selectThemes.inputSearch}
                />
              </SelectContainer>
            </FilterItem>
            <FilterItem $width="350px">
              <InputLabel>Specific Group Members (max 5)</InputLabel>
              <SelectContainer>
                <TypeaheadSearch
                  onChange={(e) => changeQuery(e)}
                  label={'Search Members of Selected Group'}
                  loadOptions={loadOptions}
                  showSearchIcon={true}
                  isMulti={true}
                  value={selectedMembers}
                  placeholder={'Search by name or email'}
                  noOptionMessage={noOptionMessage}
                  onSelectChange={(e) => handleMemberChange(e)}
                />
              </SelectContainer>
            </FilterItem>
            <FilterItem>
              <InputLabel>Days (min 5, max 30)</InputLabel>
              <SelectContainer>
                <Select
                  options={dayOptions}
                  onChange={handleDayChange}
                  placeholder={'Select Days'}
                  defaultValue={dayOptions[25]}
                  selectedOption={daysSelected}
                  isSearchable={true}
                  autoFocus={false}
                  styles={selectThemes.inputSearch}
                />
              </SelectContainer>
            </FilterItem>
            <FilterItem $width="350px">
              <InputLabel>Filter by Action</InputLabel>
              <SelectContainer>
                <Select
                  options={verbOptions}
                  onChange={handleVerbChange}
                  placeholder={'Select Action'}
                  selectedOption={selectedVerbs}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  autoFocus={false}
                  styles={selectThemes.inputSearch}
                  components={{ Option: ActivityOption }}
                />
              </SelectContainer>
            </FilterItem>
          </FilterRow>
          <ButtonGroup>
            <ButtonItem>
              <Button
                text="Reset Filters"
                theme={ButtonThemes.cancel}
                onClick={() => resetOnClick()}
              />
            </ButtonItem>
            {activityResults && (
              <ButtonItem>
                <Button
                  text="Get Activity"
                  onClick={() => getActivityOnClick()}
                />
              </ButtonItem>
            )}
            {activityResults && (
              <ButtonItem>
                <CSVButton onClick={() => exportTable()}>
                  <img src={fileExport} alt="export" data-image="export-csv" />{' '}
                  Export as CSV
                </CSVButton>
                {/* <Button text="Export CSV" onClick={() => exportTable()} /> */}
                <CSVLink
                  data={csvToExport}
                  filename="Activity_Monitor.csv"
                  ref={csvDlRef}
                />
              </ButtonItem>
            )}
          </ButtonGroup>
        </FilterOptionsContainer>

        {activityResults ? (
          <>
            <ActivityResultsHeading>
              Activity for{' '}
              {groupNameToDisplay?.label ? (
                <span>{groupNameToDisplay.label}</span>
              ) : (
                teamSettings?.name
              )}
            </ActivityResultsHeading>
            {activityResults?.no_data ? (
              <ActivityContainer>No Recent Data</ActivityContainer>
            ) : (
              <ActivityContainer>
                <CountContainer>
                  <CountTable>
                    <thead>
                      <tr>
                        <th>Activity</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityResults?.order.map((item) => {
                        return (
                          <tr key={item}>
                            <td>{activityResults.series[item]}</td>
                            <td>
                              {activityResults.data[item]
                                ? activityResults.data[item].reduce(reducer)
                                : '0'}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </CountTable>
                </CountContainer>
                {barChartData && (
                  <GraphContainer>
                    <canvas
                      id="activity-chart"
                      ref={chartRef}
                      width="800"
                      height="500"
                    />
                  </GraphContainer>
                )}
              </ActivityContainer>
            )}
          </>
        ) : (
          <LoadingAnimation />
        )}
      </ActivityResultsContainer>
    </ActivityTabContainer>
  );
};

const CSVButton = styled.button`
  font-weight: 600;
  color: ${colors.darkGrey};
  margin-left: 20px;
  background: ${colors.lightGrey};
  padding: 8px 16px;
  font-size: 11px;
  border-radius: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 13px;
    height: 13px;
    margin-right: 5px;
  }
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  margin-bottom: 20px;
`;

const FilterOptionsContainer = styled.div`
  margin-bottom: 40px;
  margin-top: 15px;
  ${FormHeader} {
    margin-bottom: 5px;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
`;

const FilterRow = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const FilterItem = styled.div`
  margin-top: 15px;
  width: ${(props) => (props?.$width ? props.$width : '220px')};
  justify-content: flex-start;
  font-size: 12px;
  flex-direction: column;
  display: flex;
  align-content: center;
  align-items: flex-start;
  flex: 0 0 auto;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
  @media ${maxDevice.sideNav} {
    width: 100%;
    margin-right: 0px;
  }
`;

const InputLabel = styled.label`
  text-align: left;
  margin: 0 0 3px 3px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonItem = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
  span {
    font-size: 12px;
  }
`;

const CountTable = styled.table`
  font-size: 12px;
  text-align: left;
  thead {
    tr {
      th {
        font-weight: ${fonts.semiBold};
        padding: 1px 5px;
        font-size: 14px;
        &:first-child {
          padding: 1px 20px 1px 0;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 1px 5px;
        &:first-child {
          padding: 1px 20px 1px 0;
        }
      }
    }
  }
`;

const ActivityContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 60px;
`;

const CountContainer = styled.div`
  flex: 0 0 auto;
  min-width: 250px;
`;

const GraphContainer = styled.div`
  flex: 0 0 auto;
  width: 800px;
  margin-left: 80px;
  padding-right: 20px;
`;

const ActivityResultsContainer = styled.div`
  margin-top: 30px;
`;

const ActivityResultsHeading = styled.h3`
  margin-bottom: 10px;
  font-weight: ${fonts.semiBold};
  font-size: 18px;
`;

const ActivityTabContainer = styled.div`
  padding: 0px 40px 100px 40px;
`;

export default ManagerActivityContainer;
