import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GenerateInput } from '../Inputs/InputGenerator';
import { CheckHasError, GenerateError } from '../../utils';
import Button from '../Button';
import { FlexCenterEnd } from '../../styles/library/layoutStyles';
import { submitTargetMapAltModal } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const TargetMapAltModelInputs = ({ targetMap, household }) => {
  const dispatch = useDispatch();
  const { targetMapAltError, targetMapAltValue } = useSelector((state) => ({
    targetMapAltError: state.targetMaps.targetMapAltError,
    targetMapAltValue: state.targetMaps.targetMapAltValue,
  }));
  const modelOptions = [
    { label: 'Alternative', value: 'atm' },
    { label: 'Cash Flow', value: 'cashflow' },
    { label: 'Net Present Value', value: 'npv' },
  ];
  const [modelValue, setModelValue] = useState({
    retirement_year: '',
    growth_rate: '',
    growth_rate_retirement: '',
    global_tax_rate_enabled: false,
    global_tax_rate: '',
    sweep_enabled: false,
    sweep_start: '',
    sweep_end: '',
    sweep_growth_rate: '',
    sweep_tax_rate: '',
    guardrail_enabled: false,
    guardrail: '',
    model: modelOptions[0],
    constant_dollars: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modelErrors, setModelErrors] = useState();

  useEffect(() => {
    if (targetMapAltValue) {
      setButtonLoading(false);
    }
  }, [targetMapAltValue]);

  useEffect(() => {
    if (targetMapAltError) {
      setButtonLoading(false);
      if (targetMapAltError?.data) {
        setModelErrors(targetMapAltError?.data);
      }
    }
  }, [targetMapAltError]);

  const updateCurrentValues = (e, isSelect = false, fieldName = null) => {
    setModelErrors();
    if (isSelect) {
      setModelValue({
        ...modelValue,
        [fieldName]: e,
      });
    } else {
      let value = e?.currentTarget?.value;
      if (e.currentTarget.type === 'number' && value) {
        value = parseInt(e.currentTarget.value);
      }
      setModelValue({
        ...modelValue,
        [e.currentTarget.name]: value,
      });
    }
  };

  const detailInputs = [
    {
      type: 'number',
      label: 'Retirement Year',
      name: 'retirement_year',
      width: '100%',
      required: false,
      tooltip: `Year between 2000 and 2100`,
      value: modelValue.retirement_year,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'retirement_year'),
      errorMessage: GenerateError(modelErrors, 'retirement_year'),
    },
    {
      type: 'number',
      label: 'Pre-Retirement Growth Rate',
      name: 'growth_rate',
      width: '100%',
      required: false,
      tooltip: `Decimal between -10.0 to 20.0`,
      value: modelValue.growth_rate,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'growth_rate'),
      errorMessage: GenerateError(modelErrors, 'growth_rate'),
    },
    {
      type: 'number',
      label: 'Post-Retirement Growth Rate',
      name: 'growth_rate_retirement',
      width: '100%',
      required: false,
      tooltip: `Decimal between -10.0 to 20.0`,
      value: modelValue.growth_rate_retirement,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'growth_rate_retirement'),
      errorMessage: GenerateError(modelErrors, 'growth_rate_retirement'),
    },
    {
      type: 'checkbox',
      label: 'Use Global Tax Rate',
      name: 'global_tax_rate_enabled',
      required: false,
      width: '100%',
      margin: '0px 0px 14px -3px',
      isVisible: true,
      onChange: () =>
        setModelValue({
          ...modelValue,
          global_tax_rate_enabled: !modelValue.global_tax_rate_enabled,
        }),
    },
    {
      type: 'number',
      label: 'Global Tax Rate',
      name: 'global_tax_rate',
      width: '100%',
      required: false,
      tooltip: `Decimal between 0.0 to 50.0`,
      value: modelValue.global_tax_rate,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'global_tax_rate'),
      errorMessage: GenerateError(modelErrors, 'global_tax_rate'),
    },
    {
      type: 'checkbox',
      label: 'Sweep Account',
      name: 'sweep_enabled',
      required: false,
      margin: '0px 0px 14px -3px',
      width: '100%',
      isVisible: true,
      onChange: () =>
        setModelValue({
          ...modelValue,
          sweep_enabled: !modelValue.sweep_enabled,
        }),
    },
    {
      type: 'number',
      label: 'Sweep Start',
      name: 'sweep_start',
      width: '100%',
      required: false,
      tooltip: `Year between 2000 and 2100`,
      value: modelValue.sweep_start,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'sweep_start'),
      errorMessage: GenerateError(modelErrors, 'sweep_start'),
    },
    {
      type: 'number',
      label: 'Sweep End',
      name: 'sweep_end',
      width: '100%',
      required: false,
      tooltip: `Year between 2000 and 2100`,
      value: modelValue.sweep_end,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'sweep_end'),
      errorMessage: GenerateError(modelErrors, 'sweep_end'),
    },
    {
      type: 'number',
      label: 'Sweep Growth Rate',
      name: 'sweep_growth_rate',
      width: '100%',
      required: false,
      tooltip: `Decimal between -10.0 to 20.0`,
      value: modelValue.sweep_growth_rate,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'sweep_growth_rate'),
      errorMessage: GenerateError(modelErrors, 'sweep_growth_rate'),
    },
    {
      type: 'number',
      label: 'Sweep Tax Rate',
      name: 'sweep_tax_rate',
      width: '100%',
      required: false,
      tooltip: `Decimal between 0.0 to 50.0`,
      value: modelValue.sweep_tax_rate,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'sweep_tax_rate'),
      errorMessage: GenerateError(modelErrors, 'sweep_tax_rate'),
    },
    {
      type: 'checkbox',
      label: 'Guardrail Enabled',
      name: 'guardrail_enabled',
      required: false,
      width: '100%',
      margin: '0px 0px 14px -3px',
      isVisible: true,
      onChange: () =>
        setModelValue({
          ...modelValue,
          guardrail_enabled: !modelValue.guardrail_enabled,
        }),
    },
    {
      type: 'number',
      label: 'Guardrail Value',
      name: 'guardrail',
      width: '100%',
      required: false,
      tooltip: `Decimal between 0.0 to 15.0`,
      value: modelValue.guardrail,
      isVisible: true,
      onChange: (e) => updateCurrentValues(e),
      hasError: CheckHasError(modelErrors, 'guardrail'),
      errorMessage: GenerateError(modelErrors, 'guardrail'),
    },
    {
      type: 'select',
      label: 'Model',
      name: 'model',
      required: false,
      placeholder: 'Select Model',
      value: modelValue.model,
      options: modelOptions,
      width: '100%',
      isVisible: true,
      onChange: (e) => updateCurrentValues(e, true, 'model'),
      hasError: CheckHasError(modelErrors, 'model'),
      errorMessage: GenerateError(modelErrors, 'model'),
    },
    {
      type: 'checkbox',
      label: 'Constant Dollars',
      name: 'constant_dollars',
      margin: '0px 0px 14px -3px',
      required: false,
      width: '100%',
      isVisible: true,
      onChange: () =>
        setModelValue({
          ...modelValue,
          constant_dollars: !modelValue.constant_dollars,
        }),
    },
  ];

  const sampleOutput = [
    {
      active: true,
      constant_dollars: false,
      interval: 'years',
      name: 'future-dollars',
      model: 'atm',
      type: 'series',
    },
    {
      active: true,
      constant_dollars: true,
      interval: 'years',
      name: 'constant-dollars',
      model: 'atm',
      type: 'series',
    },
  ];

  const submitValues = () => {
    const data = {
      ...modelValue,
      household: household?.uuid,
      targetmap: targetMap?.uuid,
      model: modelValue?.model?.value,
      output: sampleOutput,
    };
    dispatch(submitTargetMapAltModal(household?.uuid, data));
    setButtonLoading(true);
  };

  return (
    <AltModelInputsContainer>
      <InputsContainer>
        {detailInputs.map((input, index) => {
          return GenerateInput(input, index);
        })}
      </InputsContainer>
      <ButtonContainer>
        <Button
          text="Submit"
          onClick={submitValues}
          showLoading={buttonLoading}
        />
      </ButtonContainer>
    </AltModelInputsContainer>
  );
};

const AltModelInputsContainer = styled.div`
  padding: 10px 0;
`;

const InputsContainer = styled.div`
  column-count: 4;
  column-gap: 25px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  ${FlexCenterEnd};
  margin-bottom: 15px;
`;

TargetMapAltModelInputs.propTypes = {
  household: PropTypes.object,
  targetMap: PropTypes.object,
};

export default TargetMapAltModelInputs;
