/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Select,
  CheckBox,
  TextInput,
  Button,
  PageHeader,
  MemberCard,
  AddMemberCard,
  EditMemberCard,
} from '../../../components';
import {
  createHouseholdManual,
  getUserFields,
  removeCreatedHousehold,
  toggleShowStencilInfoModal,
} from '../../../store/actions';
import { user as userIcon, briefcase } from '../../../assets';
import { Page } from '../../../styles/library/layoutStyles';
import { ButtonLink } from '../../../styles/library/fontStyles';
import {
  SetDefaultItem,
  GetCountries,
  GetStates,
  ErrorNotification,
  states,
  headerContent,
  SendTrackingEvent,
  allowedDateFormats,
  DateNow,
  DateFormat,
  DateIsValid,
  DateSubtract,
  DateFormatFromList,
  // CheckHasValue,
} from '../../../utils';
import {
  colors,
  buttonColors,
  boxShadows,
  maxDevice,
  messageColors,
  heights,
  fonts,
} from '../../../styles/variables';

const HouseholdNewManualContainer = ({ isConsumer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user,
    preferences,
    countries,
    memberCategoriesUser,
    memberGenderOptionsUser,
    entityCategoriesUser,
    createdHousehold,
    createHouseholdError,
    invocationId,
  } = useSelector((state) => ({
    user: state.user.user,
    preferences: state.user.user.preferences,
    countries: state.configs.countries,
    memberCategoriesUser: state.configs.memberCategoriesUser,
    memberGenderOptionsUser: state.configs.memberGenderOptionsUser,
    entityCategoriesUser: state.configs.entityCategoriesUser,
    createdHousehold: state.households.createdHousehold,
    createHouseholdError: state.households.createHouseholdError,
    invocationId: state.configs.invocationId,
  }));
  const [selectedType, setSelectedType] = useState({
    label: 'Client',
    value: 'client',
  });
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [city, setCity] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [selectedState, setSelectedState] = useState();
  const [stateDisabled, setStateDisabled] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [showAddMember, setShowAddMember] = useState(true);
  const [showAddEntity, setShowAddEntity] = useState(false);
  const [currentMember, setCurrentMember] = useState({
    first_name: '',
    last_name: '',
    age: '',
    date_value: '',
  });
  const [missingFirstName, setMissingFirstName] = useState(false);
  const [missingLastName, setMissingLastName] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [missingRole, setMissingRole] = useState(false);
  const [missingEntityRole, setMissingEntityRole] = useState(false);
  const [entityNameError, setEntityNameError] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({ name: '' });
  const [membersList, setMembersList] = useState([]);
  const [addAnother, setAddAnother] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [isCreatingHousehold, setIsCreatingHousehold] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const householdOptions = [
    {
      label: 'Client',
      value: 'client',
    },
    {
      label: 'Prospective',
      value: 'prospect',
    },
    {
      label: 'Sample',
      value: 'sample',
    },
    {
      label: 'Stencil',
      value: 'stencil',
    },
  ];

  // REDIRECTS TO CREATED HOUSEHOLD IF ADD ANOTHER IS NOT CHECKED
  // IF ADD ANOTHER IS CHECKED IT CLEARS FORM
  useEffect(() => {
    if (createdHousehold && createdHousehold.hasOwnProperty('id')) {
      setIsCreatingHousehold(false);
      dispatch(removeCreatedHousehold());
      if (!addAnother) {
        if (user && user.hasOwnProperty('preferences')) {
          if (user.preferences.hasOwnProperty('amr')) {
            if (user.preferences.amr.stencil_helper) {
              dispatch(toggleShowStencilInfoModal(true));
            }
          }
        }
        navigate(`/household/${createdHousehold.id}/assetmap`);
        SendTrackingEvent(
          invocationId,
          'action',
          'household_create',
          'new_household_manual',
          {
            household: createdHousehold.id,
          }
        );
      } else {
        return resetPageFields();
      }
    }
  }, [createdHousehold]);

  //Fetch system fields, this will only set values that are used for manual HH
  useEffect(() => {
    if (user?.id) {
      dispatch(getUserFields(user?.preferences?.language));
    }
  }, [user]);

  // SETS SELECTED STATE USING USER PREFS
  useEffect(() => {
    if (states) {
      SetDefaultItem(states, user.state, setSelectedState);
    }
  }, [user.state]);

  useEffect(() => {
    if (createHouseholdError) {
      setIsCreatingHousehold(false);
      console.log('saving error', createHouseholdError?.data);
      let errorMessage = `Error creating household in following fields: ${Object.keys(
        createHouseholdError?.data
      )}.`;
      if (createHouseholdError?.data?.members) {
        console.log('saving member', createHouseholdError?.data?.members);
        const memberErrorKey = Object.keys(
          createHouseholdError?.data?.members[0]
        )[0];
        errorMessage = `Error creating household members. Error: ${memberErrorKey} : ${createHouseholdError?.data?.members[0][memberErrorKey]}`;
      }
      if (createHouseholdError?.data?.detail) {
        errorMessage = `Error creating household: ${createHouseholdError?.data?.detail}`;
      }
      setErrorMessage(errorMessage);
    }
  }, [createHouseholdError]);

  // SETS SELECTED COUNTRY USING USER PREFS
  useEffect(() => {
    if (countries && user && user.hasOwnProperty('preferences')) {
      SetDefaultItem(countries, user.preferences.country, setSelectedCountry);
    }
  }, [user, countries]);

  // DISABLES STATE SELECTOR IS COUNTRY IS NOT US
  useEffect(() => {
    if (selectedCountry && selectedCountry.value !== 'US') {
      setSelectedState(null);
      setStateDisabled(true);
    } else {
      setStateDisabled(false);
      SetDefaultItem(states, user.state, setSelectedState);
    }
  }, [selectedCountry, user.state]);

  // CREATES CATEGORIES FOR MEMBERS
  useEffect(() => {
    if (memberCategoriesUser) {
      setRoleOptions(memberCategoriesUser);
    }
  }, [memberCategoriesUser]);

  // CREATES CATEGORIES FOR ENTITIES
  useEffect(() => {
    if (entityCategoriesUser) {
      setEntityOptions(entityCategoriesUser);
    }
  }, [entityCategoriesUser]);

  // SETS ROLE OF EMPTY MEMBER CARD TO PRIMARY ON LOAD
  useEffect(() => {
    if (currentMember) {
      setCurrentMember({
        ...currentMember,
        role: {
          label: 'Primary',
          value: 'primary',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // CHECKS THAT MEMBER LIST HAS PRIMARY MEMBER AND THEN REMOVES THAT OPTION
  useEffect(() => {
    if (membersList.length >= 1) {
      let primaryMember = membersList.filter((member) => {
        return member.role.value === 'primary';
      });
      if (primaryMember.length === 1) {
        setRoleOptions(
          roleOptions.filter((role) => {
            return role.value !== 'primary';
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersList]);

  const resetErrors = () => {
    setMissingFirstName(false);
    setMissingLastName(false);
    setAgeError(false);
    setMissingRole(false);
    setEntityNameError(false);
    setMissingEntityRole(false);
  };

  // RESETS ALL FORM FIELDS IN ORDER TO ADD ANOTHER HOUSEHOLD
  const resetPageFields = () => {
    setSelectedType({
      label: 'Client',
      value: 'client',
    });
    setName('');
    setIsPrivate(false);
    setCity('');
    SetDefaultItem(states, user.state, setSelectedState);
    SetDefaultItem(countries, user.country, setSelectedCountry);
    setShowAddMember(true);
    setCurrentMember({
      first_name: '',
      last_name: '',
      age: '',
      date_value: '',
      role: {
        label: 'Primary',
        value: 'primary',
      },
    });
    setCurrentEntity({
      name: '',
    });
    setMembersList([]);
    setAddAnother(false);
  };

  // UPDATES STATE VALUE REUSABLE FUNCTION
  const updateCurrentValue = (updater, value, type, e, inputName) => {
    if (type === 'select') {
      updater({
        ...value,
        [inputName]: e,
      });
    } else {
      updater({
        ...value,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
    resetErrors();
  };

  // SETS STATE TO VALUE AND REMOVES ERROR
  const setValue = (setState, value) => {
    setState(value);
    setNameError(false);
  };

  const editItem = (item) => {
    resetErrors();
    setShowAddMember(false);
    setShowAddEntity(false);
    setMembersList(
      membersList.map((member) => {
        if (member === item) {
          member.isEditing = true;
          if (member.isMember) {
            setCurrentMember(member);
          } else {
            setCurrentEntity(member);
          }
        } else {
          member.isEditing = false;
        }
        return member;
      })
    );
  };

  // VALIDATES AND THEN SENDS API REQUEST TO CREATE HOUSEHOLD
  const addHousehold = () => {
    if (name === '') {
      setNameError(true);
      return ErrorNotification('Must Provide a Name');
    }
    if (membersList.length === 0) {
      setMissingFirstName(true);
      setMissingLastName(true);
      setAgeError(true);
      return ErrorNotification('Must add at least one member');
    }

    let state = '';
    if (selectedState && selectedState.value) {
      state = selectedState.value;
    }

    let transformedMembers = membersList.map((member) => {
      if (member.isMember) {
        let gender;
        if (member.hasOwnProperty('gender')) {
          gender = member.gender.value;
        }
        const memberObj = {
          category: member.role.value,
          first_name: member.first_name,
          last_name: member.last_name,
          gender,
        };
        if (member.using_dob) {
          memberObj.date_of_birth = DateFormat(
            member.age,
            preferences?.date_format,
            true
          );
        } else {
          memberObj.age = member.age;
        }
        return memberObj;
      } else {
        return {
          last_name: member.name,
          category: member.role.value,
        };
      }
    });

    let householdObj = {
      city,
      country: selectedCountry.value,
      is_private: isPrivate,
      members: transformedMembers,
      name,
      state,
      status: selectedType.value,
    };
    dispatch(createHouseholdManual(householdObj, isConsumer));
    setIsCreatingHousehold(true);
  };

  const validateMember = (member) => {
    let hasError = false;
    let ageLength = member.age.length;
    if (ageLength <= 3) {
      member.using_dob = false;
      let ageNum = parseInt(member.age);
      if (isNaN(ageNum)) {
        setAgeError(true);
        hasError = true;
      } else {
        if (ageNum >= 125) {
          setAgeError(true);
          hasError = true;
        }
        if (ageNum < 0) {
          setAgeError(true);
          hasError = true;
        }
      }
    } else {
      member.using_dob = true;
      const validDate = DateIsValid(member.age, preferences?.date_format, true);
      if (!validDate) {
        setAgeError(true);
        hasError = true;
      } else {
        setAgeError(false);
      }
    }
    if (member.first_name === '') {
      setMissingFirstName(true);
      hasError = true;
    }
    if (member.last_name === '') {
      setMissingLastName(true);
      hasError = true;
    }
    if (member.age === '') {
      setAgeError(true);
      hasError = true;
    }

    if (!member.hasOwnProperty('role')) {
      setMissingRole(true);
      hasError = true;
    }
    if (member.hasOwnProperty('role')) {
      if (!member.role.value) {
        setMissingRole(true);
        hasError = true;
      }
    }
    return hasError;
  };

  // VALIDATES ALL FIELDS ARE ENTERED AND THEN ADDS TO LIST AND RESETS FIELDS
  const addMember = () => {
    currentMember.isMember = true;
    const hasError = validateMember(currentMember);
    if (!hasError) {
      setMembersList([...membersList, currentMember]);
      setShowAddMember(false);
      setCurrentMember({
        first_name: '',
        last_name: '',
        age: '',
        date_value: '',
      });
    } else {
      console.log('missing fields');
    }
  };

  const validateEntity = (entity) => {
    if (entity.name === '') {
      setEntityNameError(true);
    }
    if (!entity.hasOwnProperty('role')) {
      setMissingEntityRole(true);
    }
    if (entity.hasOwnProperty('role')) {
      if (!entity.role.value) {
        setMissingEntityRole(true);
      }
    }
  };

  // VALIDATES ALL FIELDS ARE ENTERED AND THEN ADDS TO LIST AND RESETS FIELDS
  const addEntity = () => {
    currentEntity.isMember = false;
    validateEntity(currentEntity);
    if (currentEntity.name !== '' && currentEntity.hasOwnProperty('role')) {
      setMembersList([...membersList, currentEntity]);
      setShowAddEntity(false);
      setCurrentEntity({ name: '' });
    } else {
      console.log('missing fields');
    }
  };

  // DETERMINES WHICH NEW CARD TO DISPLAY
  const addNew = (type) => {
    resetErrors();
    setMembersList(
      membersList.map((member) => {
        member.isEditing = false;
        return member;
      })
    );
    if (type === 'member') {
      setCurrentMember({
        first_name: '',
        last_name: '',
        age: '',
      });
      setShowAddMember(true);
      setShowAddEntity(false);
    }
    if (type === 'entity') {
      setCurrentEntity({
        name: '',
      });
      setShowAddEntity(true);
      setShowAddMember(false);
    }
  };

  // FILTERS OUT REMOVED MEMBER ON CLICK
  const removeMember = (selectedMember) => {
    setMembersList(
      membersList.filter((member) => {
        return member !== selectedMember;
      })
    );
  };

  const checkDisabled = () => {
    if (currentMember && currentMember.hasOwnProperty('role')) {
      return currentMember.role.value === 'primary';
    } else {
      return false;
    }
  };

  const dateOnBlur = (e) => {
    let hasError = false;
    const date = e.currentTarget.value.trim();
    const dateLength = date.length;
    if (dateLength <= 3) {
      const parsed = parseInt(date);
      //Check is valid age number
      if (isNaN(parsed)) {
        hasError = true;
        setAgeError(true);
      } else {
        setCurrentMember({
          ...currentMember,
          age: date,
        });
      }
    } else {
      let dateAsMomentDate = DateFormatFromList(
        date,
        allowedDateFormats[preferences?.date_format],
        true
      );
      const isValid = DateIsValid(dateAsMomentDate);
      if (isValid) {
        // If date is in future, assume 2-digit date parsed to current century instead of previous
        if (dateAsMomentDate > DateNow()) {
          dateAsMomentDate = DateSubtract(dateAsMomentDate, 100);
        }
        const dateCorrectedInTextFormat = DateFormat(
          dateAsMomentDate,
          preferences?.date_format
        );
        setCurrentMember({
          ...currentMember,
          age: dateCorrectedInTextFormat,
        });
      } else {
        setAgeError(true);
        hasError = true;
      }
    }
    return hasError;
  };

  const onDateChange = (e) => {
    const selectedDate = DateFormat(e, preferences?.date_format);
    setCurrentMember({
      ...currentMember,
      age: selectedDate,
      date_value: selectedDate,
    });
  };

  const memberInputs = [
    {
      type: 'text',
      name: 'first_name',
      placeholder: 'First Name',
      maxLength: '30',
      value: currentMember,
      updater: setCurrentMember,
      onChange: updateCurrentValue,
      hasError: missingFirstName,
    },
    {
      type: 'text',
      name: 'last_name',
      placeholder: 'Last Name',
      maxLength: '50',
      value: currentMember,
      updater: setCurrentMember,
      onChange: updateCurrentValue,
      hasError: missingLastName,
    },
    {
      type: 'date',
      name: 'age',
      placeholder: `Age or (${preferences?.date_format.toLowerCase()})`,
      value: currentMember,
      updater: setCurrentMember,
      onChange: updateCurrentValue,
      hasError: ageError,
      onBlur: (e) => dateOnBlur(e),
      onDateChange: (e) => onDateChange(e),
      dateValue: currentMember.date_value,
      dateFormat: preferences?.date_format,
    },
    {
      type: 'select',
      name: 'gender',
      placeholder: 'Gender',
      value: currentMember,
      updater: setCurrentMember,
      onChange: updateCurrentValue,
      options: memberGenderOptionsUser,
    },
    {
      type: 'select',
      name: 'role',
      placeholder: 'Relationship',
      value: currentMember,
      updater: setCurrentMember,
      onChange: updateCurrentValue,
      isDisabled: checkDisabled(),
      options: roleOptions,
      hasError: missingRole,
    },
  ];

  const entityInputs = [
    {
      type: 'text',
      name: 'name',
      placeholder: 'Name',
      maxLength: '50',
      value: currentEntity,
      updater: setCurrentEntity,
      onChange: updateCurrentValue,
      hasError: entityNameError,
    },
    {
      type: 'select',
      name: 'role',
      placeholder: 'Category',
      value: currentEntity,
      updater: setCurrentEntity,
      onChange: updateCurrentValue,
      options: entityOptions,
      hasError: missingEntityRole,
    },
  ];

  const saveMember = (member) => {
    const hasMemberError = validateMember(currentMember);
    validateEntity(currentEntity);
    let updatedList = membersList.map((mem) => {
      if (mem === member) {
        if (mem.isMember) {
          mem = currentMember;
        } else {
          mem = currentEntity;
        }
        mem.isEditing = false;
      }
      return mem;
    });
    if (member.isMember) {
      if (
        currentMember.first_name !== '' &&
        currentMember.last_name !== '' &&
        !hasMemberError &&
        currentMember.role
      ) {
        setMembersList(updatedList);
      } else {
        return;
      }
    } else {
      if (currentEntity.name !== '' && currentEntity.role) {
        setMembersList(updatedList);
      } else {
        return;
      }
    }
  };

  const cancelEditing = (member) => {
    let updatedMembers = membersList.map((mem) => {
      if (mem === member) {
        mem.isEditing = false;
      }
      return mem;
    });
    resetErrors();
    setCurrentMember({
      first_name: '',
      last_name: '',
      age: '',
    });
    setCurrentEntity({ name: '' });
    setMembersList(updatedMembers);
  };

  const cancelNew = () => {
    resetErrors();
    setCurrentMember({
      first_name: '',
      last_name: '',
      age: '',
    });
    setCurrentEntity({ name: '' });
    setShowAddMember(false);
    setShowAddEntity(false);
  };

  return (
    <Page
      style={{
        minHeight: `calc(100vh - ${heights.navBar})`,
      }}
    >
      <ColumnContainerLayout>
        <PageHeader content={headerContent.householdManual} />
        <SecondRowContainer>
          <ThirdHeader>Details</ThirdHeader>
          <Row>
            <SelectContainer>
              <Select
                options={householdOptions}
                selectedOption={selectedType}
                onChange={(e) => setSelectedType(e)}
                isSearchable={true}
                placeholder={'Select Household Type'}
                autoFocus={false}
              />
            </SelectContainer>
            <CheckBoxContainer>
              <CheckBox
                label={'Make Private'}
                checked={isPrivate}
                onChange={() => setIsPrivate(!isPrivate)}
              />
            </CheckBoxContainer>
          </Row>
          <InputContainer>
            <TextInput
              type={'text'}
              maxLength={'60'}
              hasError={nameError}
              name={'name'}
              placeholder={'Household Name'}
              value={name}
              onChange={(e) => setValue(setName, e.target.value)}
            />
          </InputContainer>
          <Row>
            <InputContainer>
              <TextInput
                type={'text'}
                name={'city'}
                placeholder={'City'}
                maxLength={'60'}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </InputContainer>
            <SelectContainer
              style={{
                marginRight: '15px',
                maxWidth: '175px',
              }}
            >
              <Select
                options={GetStates(states)}
                selectedOption={selectedState}
                onChange={(e) => setSelectedState(e)}
                isSearchable={true}
                placeholder={'State'}
                isDisabled={stateDisabled}
                autoFocus={false}
              />
            </SelectContainer>
            <SelectContainer>
              <Select
                options={GetCountries(countries)}
                selectedOption={selectedCountry}
                onChange={(e) => setSelectedCountry(e)}
                isSearchable={true}
                placeholder={'Country'}
                autoFocus={false}
              />
            </SelectContainer>
          </Row>
        </SecondRowContainer>
        <ThirdRowContainer>
          <ThirdHeader>Members | Entities</ThirdHeader>
          <CardRowContainer>
            {membersList.map((member, index) => {
              return member.isEditing ? (
                <EditMemberCard
                  member={member}
                  key={index}
                  inputs={member.isMember ? memberInputs : entityInputs}
                  onSave={saveMember}
                  onCancel={cancelEditing}
                />
              ) : (
                <MemberCard
                  member={member}
                  key={index}
                  onEdit={editItem}
                  onRemove={removeMember}
                  index={index}
                />
              );
            })}

            {showAddMember && (
              <AddMemberCard
                isMember={true}
                inputs={memberInputs}
                onAdd={addMember}
                onCancel={cancelNew}
                membersList={membersList}
              />
            )}
            {showAddEntity && (
              <AddMemberCard
                isMember={false}
                inputs={entityInputs}
                onAdd={addEntity}
                onCancel={cancelNew}
                membersList={membersList}
              />
            )}
            {membersList.length >= 1 && !showAddMember && !showAddEntity && (
              <CardContainer>
                <NewCardBox
                  style={{
                    marginBottom: '20px',
                  }}
                  onClick={() => addNew('member')}
                >
                  <IconContainer>
                    <img src={userIcon} alt="user" data-image="user-icon" />
                  </IconContainer>
                  <LabelContainer>Add a Member</LabelContainer>
                </NewCardBox>
                <NewCardBox onClick={() => addNew('entity')}>
                  <IconContainer>
                    <img
                      src={briefcase}
                      alt="entity"
                      data-image="entity-icon"
                    />
                  </IconContainer>
                  <LabelContainer>Add an Entity</LabelContainer>
                </NewCardBox>
              </CardContainer>
            )}
          </CardRowContainer>
        </ThirdRowContainer>
        {errorMessage && (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}
        <ButtonRowContainer>
          {/* <CheckBoxContainer style={{ flex: '0 0 auto' }}>
            <CheckBox
              label={'Add Another'}
              checked={addAnother}
              onChange={() => setAddAnother(!addAnother)}
            />
          </CheckBoxContainer> */}
          <Button
            text="Add Household"
            onClick={addHousehold}
            showLoading={isCreatingHousehold}
          />
          <ButtonLink to={isConsumer ? '/dashboard' : '/household/new'}>
            Cancel
          </ButtonLink>
        </ButtonRowContainer>
      </ColumnContainerLayout>
    </Page>
  );
};

const ErrorMessageContainer = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 10px 20px 0px 20px;
  }
  padding: 20px 40px 0px 40px;
  font-weight: ${fonts.semiBold};
  color: ${messageColors.error};
`;

const ColumnContainerLayout = styled.div`
  margin: 10px 0;
  padding-bottom: 70px;
  font-size: 12px;
  input {
    font-size: 12px;
  }
`;

const ThirdHeader = styled.h3`
  margin-bottom: 15px;
  font-weight: ${fonts.semiBold};
  font-size: 16px;
`;

const SecondRowContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 10px 20px 25px 20px;
  }
  margin: 10px 40px 30px 40px;
`;

const InputContainer = styled.div`
  @media ${maxDevice.sideNav} {
    min-width: 100%;
    flex: 1 1 auto;
    margin-bottom: 15px;
    max-width: 100%;
  }
  margin: 0 15px 0 0;
  display: flex;
  max-width: 400px;
  flex: 1 1 auto;
  input {
    padding: 9.5px 10px;
    flex: 1 1 auto;
  }
`;

const SelectContainer = styled.div`
  @media ${maxDevice.sideNav} {
    min-width: 100%;
    flex: 1 1 auto;
  }
  flex: 1 1 auto;
  max-width: 225px;
  min-width: 150px;
`;

const CheckBoxContainer = styled.div`
  margin-left: 15px;
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  label {
    color: ${colors.paleGrey};
    font-weight: ${fonts.semiBold};
  }
`;

const Row = styled.div`
  @media ${maxDevice.sideNav} {
    ${SelectContainer} {
      min-width: 100%;
      flex: 1 1 auto;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ${InputContainer} {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      flex: 1 1 auto;
      margin-bottom: 15px;
      input {
        flex: 1 1 auto;
        width: 100%;
      }
    }
    ${CheckBoxContainer} {
      margin-left: 0;
    }
  }
  ${InputContainer} {
    max-width: 210px;
  }
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
`;

const ThirdRowContainer = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 25px 20px 15px 20px;
  }
  @media ${maxDevice.mobileL} {
    padding: 20px;
  }
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  background: ${colors.lightGrey};
  text-align: left;
  padding: 20px 40px;
`;

const ButtonRowContainer = styled.div`
  @media ${maxDevice.mobileL} {
    ${CheckBoxContainer} {
      display: block;
      min-width: 100%;
      margin-bottom: 10px;
    }
  }
  @media ${maxDevice.sideNav} {
    padding: 20px;
  }
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  text-align: right;
  padding: 20px 40px;
  button {
    margin-left: 15px;
  }
  ${ButtonLink} {
    margin-left: 10px;
    background: ${buttonColors.inactive};
  }
`;

const CardRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`;

const CardContainer = styled.div`
  @media ${maxDevice.sideNav} {
    width: 100%;
    margin-right: 0;
  }
  box-shadow: ${boxShadows.boxShadow};
  border-radius: 2px;
  padding: 20px 20px 20px 20px;
  background: white;
  min-width: 170px;
  width: 170px;
  height: 220px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: center;
  ${InputContainer} {
    flex: 0 0 auto;
  }
  div {
    input {
      padding: 5px 10px;
      width: 100%;
      flex: 1 1 auto;
      border-radius: 5px;
    }
  }
`;

const IconContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  flex: 0 0 auto;
  width: 58px;
  height: 58px;
  border-radius: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    height: 30px;
    opacity: 0.5;
  }
`;

const LabelContainer = styled.div`
  flex: 1 1 auto;
  margin-left: 10px;
  color: ${colors.paleGrey};
  font-weight: ${fonts.semiBold};
  font-size: 12px;
`;

const NewCardBox = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  padding: 20px 15px;
  height: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    box-shadow: ${boxShadows.boxShadowSoft};
    ${IconContainer} {
      border: 1px solid ${colors.darkGrey};
      img {
        opacity: 1;
      }
    }
    ${LabelContainer} {
      color: ${colors.darkGrey};
    }
  }
`;

HouseholdNewManualContainer.propTypes = {
  isConsumer: PropTypes.bool,
};

export default HouseholdNewManualContainer;
