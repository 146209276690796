import {
  BaseEdge,
  // getSmoothStepPath,
  // getStraightPath,
  // getBezierPath,
  getSimpleBezierPath,
} from 'reactflow';
import PropTypes from 'prop-types';

const ConnectorEdge = ({ id, sourceX, sourceY, targetX, targetY }) => {
  const [edgePath] = getSimpleBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
    </>
  );
};

ConnectorEdge.propTypes = {
  data: PropTypes.object,
};

export default ConnectorEdge;
