/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  HouseholdDetails,
  HouseholdSharing,
  HouseholdDeletedItems,
  HouseholdInvestorProfile,
  HouseholdIntegrations,
} from '../../containers';
import { PageHeader, TabMenu } from '../../components';
import {
  getLinkedIntegrationMembers,
  getHouseholdFolderOptions,
} from '../../store/actions';
import {
  headerContent,
  integrationOptions,
  UpdatePageTitle,
} from '../../utils';

const HouseholdInfo = ({ isConsumer }) => {
  UpdatePageTitle('Household Info');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const householdId = params.id;
  const { currentHousehold, loadedUser, integrations, showHouseholdSharing } =
    useSelector((state) => ({
      currentHousehold: state.households.currentHousehold,
      loadedUser: state.user.loadedUser,
      integrations: state.user.integrations,
      showHouseholdSharing: state.user.showHouseholdSharing,
    }));
  const [activeTab, setActiveTab] = useState();
  const [pageHeaderContent, setPageHeaderContent] = useState(
    headerContent.householdInfo
  );
  const [integrationsList, setIntegrationsList] = useState([]);
  const startingTabs = [
    {
      text: 'Details',
      value: 'details',
    },
    {
      text: 'Deleted Items',
      value: 'deleted',
    },
    {
      text: 'Investor Profile',
      value: 'investor',
    },
  ];
  const [tabs, setTabs] = useState(startingTabs);
  const [loadedTabs, setLoadedTabs] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState();

  //HOOK THAT MERGES LOCAL LIST OF INTEGRATIONS WITH VALUES FROM API
  useEffect(() => {
    if (integrations?.length) {
      setIntegrationsList(
        integrations.map((integration) => {
          const details = integrationOptions.find(
            (int) => int.id === integration.key
          );
          return { ...integration, ...details };
        })
      );
    }
  }, [integrations]);

  //HOOK THAT UPDATES ACTIVE TAB AND MAKES API CALLS FOR INTEGRATIONS
  useEffect(() => {
    if (loadedTabs && currentHousehold?.uuid) {
      if (location?.state?.panel || location?.search) {
        if (!location?.state?.panel) {
          setActiveTab(location?.state?.panel);
          const split = location?.search.split('?panel=');
          setActiveTab(split[1]);
        } else {
          setActiveTab(location?.state?.panel);
        }
        const matchedIntegration = integrationsList.find(
          (int) => int.key === location?.state?.panel
        );
        if (matchedIntegration) {
          //IF URL HAS KEY THAT MATCHES INTEGRATION KEY, SET IT AS SELECTED
          setSelectedIntegration(matchedIntegration);
          //IF INTEGRATIONS IS FILE STORAGE - MAKE API CALL TO GET FOLDERS
          if (matchedIntegration?.file_storage) {
            if (currentHousehold?.uuid) {
              dispatch(
                getHouseholdFolderOptions(
                  currentHousehold?.uuid,
                  matchedIntegration.key
                )
              );
            }
          } else {
            //IF NOT FILE STORAGE - MAKE REQUEST TO GET LINKED MEMBERS
            dispatch(
              getLinkedIntegrationMembers(householdId, matchedIntegration.key)
            );
          }
        }
      } else {
        if (activeTab) {
          setActiveTab(activeTab);
        } else {
          setActiveTab('details');
        }
      }
    }
  }, [currentHousehold?.uuid, location?.state?.panel, loadedTabs]);

  //HOOK THAT UPDATES TAB LIST TO INCLUDE ENABLED INTEGRATIONS
  useEffect(() => {
    if (integrationsList?.length && loadedUser && !loadedTabs) {
      const activeIntegrations = integrationsList.filter(
        (int) => int.is_enabled
      );
      const integrationTabs = activeIntegrations.map((integration) => {
        return {
          text: integration.name,
          value: integration.key,
        };
      });
      if (isConsumer) {
        setTabs([
          {
            text: 'Details',
            value: 'details',
          },
          {
            text: 'Deleted Items',
            value: 'deleted',
          },
        ]);
      } else {
        if (showHouseholdSharing) {
          setTabs([
            ...startingTabs,
            { text: 'Client Portal', value: 'client_portal' },
            ...integrationTabs,
          ]);
        } else {
          setTabs([...startingTabs, ...integrationTabs]);
        }
      }
      setLoadedTabs(true);
      if (location?.state?.panel) {
        setActiveTab(location.state.panel);
        const matchedIntegration = integrationsList.find(
          (int) => int.key === location.state.panel
        );
        if (matchedIntegration) {
          setSelectedIntegration(matchedIntegration);
        }
      }
    }
  }, [integrationsList, isConsumer, loadedUser]);

  //HOOK TO UPDATE LOCATION AND URL TO ACTIVE TAB VALUE
  useEffect(() => {
    if (activeTab) {
      const matchedIntegration = integrationsList.find(
        (int) => int.key === activeTab
      );
      if (matchedIntegration) {
        setSelectedIntegration(matchedIntegration);
        location.search = `panel=${matchedIntegration.key}`;
        navigate(location, { state: { panel: matchedIntegration.key } });
      } else {
        setSelectedIntegration();
        location.search = `panel=${activeTab}`;
        navigate(location, { state: { panel: activeTab } });
      }
    }
  }, [activeTab]);

  //HOOK TO CHANGE PAGE HEADER HEADING AND SUBTEXT FOR EACH TAB
  useEffect(() => {
    if (activeTab) {
      if (activeTab === 'details') {
        setPageHeaderContent(headerContent.householdInfo);
      } else if (activeTab === 'client_portal') {
        setPageHeaderContent(headerContent.householdSharing);
      } else if (activeTab === 'investor') {
        setPageHeaderContent(headerContent.investorProfile);
      } else if (activeTab === 'deleted') {
        setPageHeaderContent(headerContent.householdDeletedItems);
      } else if (selectedIntegration && selectedIntegration?.info) {
        setPageHeaderContent({
          ...headerContent.householdInfo,
          heading: selectedIntegration.name,
          subcontent: [selectedIntegration.info],
        });
      }
    }
  }, [activeTab, selectedIntegration]);

  return (
    <>
      <PageHeader content={pageHeaderContent} />
      <TabMenu
        tabs={tabs}
        activeTab={activeTab}
        onClick={setActiveTab}
        noBottomMargin={activeTab === 'deleted'}
      />
      {activeTab === 'details' ? (
        <HouseholdDetails householdId={householdId} isConsumer={isConsumer} />
      ) : activeTab === 'client_portal' ? (
        <HouseholdSharing householdId={householdId} />
      ) : activeTab === 'deleted' ? (
        <HouseholdDeletedItems
          householdId={householdId}
          isConsumer={isConsumer}
        />
      ) : activeTab === 'investor' ? (
        <HouseholdInvestorProfile householdId={householdId} />
      ) : (
        <HouseholdIntegrations
          householdId={householdId}
          selectedIntegration={selectedIntegration}
        />
      )}
    </>
  );
};

HouseholdInfo.propTypes = {
  isConsumer: PropTypes.bool,
};

export default HouseholdInfo;
