import { CheckHasValue } from '../../utils';
import { apiAction, getHouseholdInfo, getConsumerHouseholdInfo } from './';

export const MemberTypes = {
  UPDATE_MEMBER_STORE_VALUE: 'UPDATE_MEMBER_STORE_VALUE',
  CLOSE_ALL_MEMBER_MODALS: 'CLOSE_ALL_MEMBER_MODALS',
  GET_MEMBER_INFO: 'GET_MEMBER_INFO',
  GET_PROFESSIONAL_INFO: 'GET_PROFESSIONAL_INFO',
  GET_PRIMARY_MEMBER: 'GET_PRIMARY_MEMBER',
  CLEAR_MEMBER_INFO: 'CLEAR_MEMBER_INFO',
  GET_MEMBER_INTEGRATION_INFO: 'GET_MEMBER_INTEGRATION_INFO',
  CREATE_MEMBER: 'CREATE_MEMBER',
  DELETE_MEMBER: 'DELETE_MEMBER',
  UPDATE_MEMBER_INFO: 'UPDATE_MEMBER_INFO',
  CREATE_PROFESSIONAL: 'CREATE_PROFESSIONAL',
  DELETE_PROFESSIONAL: 'DELETE_PROFESSIONAL',
  UPDATE_PROFESSIONAL_INFO: 'UPDATE_PROFESSIONAL_INFO',
  UPDATE_ADVISORS: 'UPDATE_ADVISORS',
  SEARCH_MEMBER_ACTIVITY: 'SEARCH_MEMBER_ACTIVITY',
  TOGGLE_SHOW_MEMBER_MODAL: 'TOGGLE_SHOW_MEMBER_MODAL',
  TOGGLE_SHOW_ENTITY_MODAL: 'TOGGLE_SHOW_ENTITY_MODAL',
  TOGGLE_SHOW_PROFESSIONAL_MODAL: 'TOGGLE_SHOW_PROFESSIONAL_MODAL',
  TOGGLE_SHOW_ADVISOR_MODAL: 'TOGGLE_SHOW_ADVISOR_MODAL',
  LOAD_MEMBER_FOLDER: 'LOAD_MEMBER_FOLDER',
  LINK_MEMBER_FOLDER: 'LINK_MEMBER_FOLDER',
  UNLINK_MEMBER_FOLDER: 'UNLINK_MEMBER_FOLDER',
};

/*
  GENERIC FUNCTION USED TO CHANGE OR MODIFY SINGLE VALUE IN MEMBER STORE
  - fieldName <string>: field name in redux store to modify
  - value <any>: new value desired for field
*/
export const updateMemberStoreValue = (fieldName, value) => ({
  type: MemberTypes.UPDATE_MEMBER_STORE_VALUE,
  payload: { fieldName, value },
});

/*
 ACTION TO CLOSE ALL MEMBER MODALS IN APP
  - keepOpen <string>: not used but for ability to keep passed modal open
*/
export const closeAllMemberModals = (keepOpen) => ({
  type: MemberTypes.CLOSE_ALL_MEMBER_MODALS,
  payload: keepOpen,
});

//ACTION TO SET HOUSEHOLD MEMBER INFO IN STORE <object>
export const setMemberInfo = (memberInfo) => ({
  type: MemberTypes.GET_MEMBER_INFO,
  payload: memberInfo,
});

/*
  API CALL TO GET MEMBER/ENTITY INFO
  - householdId <string>: id of household for member/entity
  - memberId <string>: id of member/entity to be fetched
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const getMemberInfo = (householdId, memberId, isConsumer = false) => {
  return apiAction({
    path: `/household/${householdId}/member/${memberId}`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setMemberInfo,
    label: MemberTypes.GET_MEMBER_INFO,
  });
};

//ACTION TO SET HOUSEHOLD PROFESSIONAL INFO IN STORE <object>
export const setProfessionalInfo = (professionalInfo) => ({
  type: MemberTypes.GET_PROFESSIONAL_INFO,
  payload: professionalInfo,
});

/*
  API CALL TO GET PROFESSIONAL INFO
  - householdId <string>: id of household for professional
  - professionalId <string>: id of professional to be fetched
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const getProfessionalInfo = (
  householdId,
  professionalId,
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${householdId}/professional/${professionalId}`,
    isConsumer: isConsumer,
    method: 'GET',
    onSuccess: setProfessionalInfo,
    label: MemberTypes.GET_PROFESSIONAL_INFO,
  });
};

//ACTION TO SET PRIMARY HOUSEHOLD MEMBER INFO IN STORE <object>
export const setPrimaryMember = (primaryMember) => ({
  type: MemberTypes.GET_PRIMARY_MEMBER,
  payload: primaryMember,
});

/*
  API CALL TO GET PRIMARY MEMBER INFO
  - householdId <string>: id of household for primary member
  - memberId <string>: id of primary member to be fetched
*/
export const getPrimaryMember = (householdId, memberId) => {
  return apiAction({
    path: `/household/${householdId}/member/${memberId}`,
    method: 'GET',
    onSuccess: setPrimaryMember,
    label: MemberTypes.GET_PRIMARY_MEMBER,
  });
};

//ACTION TO CLEAR CURRENT MEMBER FROM STORE
export const clearMemberInfo = () => ({
  type: MemberTypes.CLEAR_MEMBER_INFO,
});

//ACTION TO SET HOUSEHOLD MEMBER INTEGRATION INFO IN STORE <object>
export const setMemberIntegrationInfo = (info) => ({
  type: MemberTypes.GET_MEMBER_INTEGRATION_INFO,
  payload: info,
});

/*
  API CALL TO GET MEMBER/ENTITY INTEGRATION INFO
  - householdId <string>: id of household for member/entity
  - memberId <string>: id of member/entity to be fetched
  - integration <string>: id value of integration to be fetched
*/
export const getMemberIntegrationInfo = (
  householdId,
  memberId,
  integration
) => {
  return apiAction({
    path: `/household/${householdId}/member/${memberId}/integration/${integration}`,
    method: 'GET',
    onSuccess: setMemberIntegrationInfo,
    label: MemberTypes.GET_MEMBER_INTEGRATION_INFO,
  });
};

/*
  API CALL TO CREATE MEMBER/ENTITY
  - householdId <string>: id of household for member/entity
  - member <object>: info needed to to be created
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const createMember = (householdId, member, isConsumer = false) => {
  let displayText =
    member.member_type.charAt(0).toUpperCase() + member.member_type.slice(1);
  const isEntity = member.member_type === 'entity';
  if (CheckHasValue(member.is_active, false) && !member.is_active) {
    displayText = isEntity ? 'Other Entity' : 'Personal Relationship';
  }
  return apiAction({
    path: `/household/${householdId}/member`,
    isConsumer: isConsumer,
    method: 'POST',
    data: member,
    passId: householdId,
    successMessage: `Created ${displayText}`,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: MemberTypes.CREATE_MEMBER,
  });
};

/*
  API CALL TO UPDATE MEMBER/ENTITY
  - householdId <string>: id of household for member/entity
  - memberId <string>: id of member/entity to be updated
  - member <object>: info to be updated
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const updateMember = (
  householdId,
  memberId,
  member,
  isConsumer = false
) => {
  let displayText =
    member.member_type.charAt(0).toUpperCase() + member.member_type.slice(1);
  if (CheckHasValue(member.is_active, false) && !member.is_active) {
    displayText = 'Personal Relationship';
  }
  return apiAction({
    path: `/household/${householdId}/member/${memberId}`,
    isConsumer: isConsumer,
    method: 'PUT',
    data: member,
    passId: householdId,
    successMessage: `Updated ${displayText}`,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: MemberTypes.UPDATE_MEMBER_INFO,
  });
};

/*
  API CALL TO DELETE MEMBER/ENTITY
  - householdId <string>: id of household for member/entity
  - memberId <string>: id of member/entity to be deleted
  - isEntity <boolean>: used for toast message text
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const deleteMember = (
  householdId,
  memberId,
  displayText = 'Member',
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${householdId}/member/${memberId}`,
    isConsumer: isConsumer,
    method: 'DELETE',
    successMessage: `Deleted ${displayText}`,
    passId: householdId,
    passSubId: memberId,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: MemberTypes.DELETE_MEMBER,
  });
};

/*
  API CALL TO CREATE PROFESSIONAL
  - householdId <string>: id of household for professional
  - professional <object>: info needed to to be created
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const createProfessional = (
  householdId,
  professional,
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${householdId}/professional`,
    isConsumer: isConsumer,
    method: 'POST',
    data: professional,
    passId: householdId,
    successMessage: `Created Professional`,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: MemberTypes.CREATE_PROFESSIONAL,
  });
};

/*
  API CALL TO UPDATE PROFESSIONAL
  - householdId <string>: id of household for professional
  - professionalId <string>: id of professional to be updated
  - professional <object>: info to be updated
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const updateProfessional = (
  householdId,
  professionalId,
  professional,
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${householdId}/professional/${professionalId}`,
    isConsumer: isConsumer,
    method: 'PUT',
    data: professional,
    passId: householdId,
    successMessage: `Updated Professional`,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: MemberTypes.UPDATE_PROFESSIONAL_INFO,
  });
};

/*
  API CALL TO DELETE PROFESSIONAL
  - householdId <string>: id of household for professional
  - professionalId <string>: id of professional to be deleted
  - isConsumer <bool>: whether user is consumer, uses different endpoint
*/
export const deleteProfessional = (
  householdId,
  professionalId,
  isConsumer = false
) => {
  return apiAction({
    path: `/household/${householdId}/professional/${professionalId}`,
    isConsumer: isConsumer,
    method: 'DELETE',
    successMessage: `Deleted Professional`,
    passId: householdId,
    passSubId: professionalId,
    onSuccess: isConsumer ? getConsumerHouseholdInfo : getHouseholdInfo,
    label: MemberTypes.DELETE_PROFESSIONAL,
  });
};

//ACTION TO SET ADVISOR LIST FROM SEARCH IN STORE <array>
export const setAdvisorSearchResults = (advisors) => ({
  type: MemberTypes.SEARCH_ADVISORS,
  payload: advisors,
});

/*
  API CALL TO GET SEARCH ADVISORS
  - householdId <string>: id of household for advisor
  - query <string>: text to search for advisor (name/email)
*/
export const searchAdvisors = (householdId, query) => {
  return apiAction({
    path: `/household/${householdId}/advisor_search?query=${query}`,
    method: 'GET',
    onSuccess: setAdvisorSearchResults,
    label: MemberTypes.SEARCH_ADVISORS,
  });
};

/*
  API CALL TO UPDATE HOUSEHOLD ADVISORS
  - householdId <string>: id of household for advisors
  - advisors <array>: list of advisor for this household
*/
export const updateAdvisors = (householdId, advisors) => {
  return apiAction({
    path: `/household/${householdId}/subadvisors`,
    method: 'PUT',
    data: advisors,
    passId: householdId,
    successMessage: `Updated Advisors`,
    onSuccess: getHouseholdInfo,
    label: MemberTypes.UPDATE_ADVISORS,
  });
};

//ACTION TO SET USER ACTIVITY IN STORE <object>
export const setMemberActivity = (activity) => ({
  type: MemberTypes.SEARCH_MEMBER_ACTIVITY,
  payload: activity,
});

/*
  API CALL TO SEARCH USER ACTIVITY - FOR TEAM MANAGER
  - memberId <string>: id of asset-map user for customer
  - groupId <string>: id of asset-map group
  - days <integer>: number of days of activity to show
  - customVerbs <array>: list of actions verbs to search for
*/
export const searchMemberActivity = (memberId, groupId, days, customVerbs) => {
  return apiAction({
    path: `/activity-monitor`,
    method: 'GET',
    data: {
      member_id: memberId,
      group_id: groupId,
      days: days,
      custom_verbs: customVerbs,
    },
    onSuccess: setMemberActivity,
    label: MemberTypes.SEARCH_MEMBER_ACTIVITY,
  });
};

/*
  ACTION TO SET TOGGLE DISPLAY OF MEMBER MODAL
  - showModal <boolean>: whether to show modal or not
  - editMember <boolean>: whether modal or for new or existing member
  - defaultTab <string>: what tab should modal open to, default is essentials
  - isPersonal <boolean>: if creating personal relationship instead of hh member
*/
export const toggleShowMemberModal = (
  showModal,
  editMember = false,
  defaultTab = 'essentials',
  isPersonal = false
) => {
  return {
    type: MemberTypes.TOGGLE_SHOW_MEMBER_MODAL,
    payload: {
      showModal: showModal,
      newMember: !editMember,
      defaultTab,
      isPersonal,
    },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF ENTITY MODAL
  - showModal <boolean>: whether to show modal or not
  - editEntity <boolean>: whether modal or for new or existing entity
  - defaultTab <string>: what tab should modal open to, default is essentials
  - isExcluded <boolean>: if creating other entity instead of household entity
*/
export const toggleShowEntityModal = (
  showModal,
  editEntity = false,
  defaultTab = 'essentials',
  isExcluded = false
) => {
  return {
    type: MemberTypes.TOGGLE_SHOW_ENTITY_MODAL,
    payload: {
      showModal: showModal,
      newEntity: !editEntity,
      defaultTab,
      isExcluded,
    },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF PROFESSIONAL MODAL
  - showModal <boolean>: whether to show modal or not
  - editProfessional <boolean>: whether modal or for new or existing professional
  - defaultTab <string>: what tab should modal open to, default is essentials
*/
export const toggleShowProfessionalModal = (
  showModal,
  editProfessional = false,
  defaultTab = 'essentials'
) => {
  return {
    type: MemberTypes.TOGGLE_SHOW_PROFESSIONAL_MODAL,
    payload: {
      showModal: showModal,
      newProfessional: !editProfessional,
      defaultTab,
    },
  };
};

/*
  ACTION TO SET TOGGLE DISPLAY OF ADVISOR MODAL
  - showModal <boolean>: whether to show modal or not
  - editAdvisor <boolean>: whether modal or for new or existing advisor
*/
export const toggleShowAdvisorModal = (showModal, editAdvisor = false) => {
  return {
    type: MemberTypes.TOGGLE_SHOW_ADVISOR_MODAL,
    payload: { showModal: showModal, newAdvisor: !editAdvisor },
  };
};

//ACTION TO SET HOUSEHOLD MEMBER FILE STORAGE FOLDER IN STORE <object>
export const setMemberFolder = (folder) => ({
  type: MemberTypes.LOAD_MEMBER_FOLDER,
  payload: folder,
});

/*
  API CALL TO GET MEMBER/ENTITY FOLDER FOR FILE STORAGE INTEGRATION
  - householdId <string>: id of household for member/entity
  - memberId <string>: id of member/entity to be fetched
  - integration <string>: integration id to be searched
  - folderId <string>: id of existing folder, if empty returns default
*/
export const getMemberStorageFolder = (
  householdId,
  memberId,
  integration,
  folderId
) => {
  return apiAction({
    path: `/household/${householdId}/member/${memberId}/integration/${integration}/load_folder${
      folderId ? `?folder=${folderId}` : ''
    }`,
    method: 'GET',
    onSuccess: setMemberFolder,
    label: MemberTypes.LOAD_MEMBER_FOLDER,
  });
};

/*
  API CALL TO LINK MEMBER/ENTITY FOLDER TO FILE STORAGE INTEGRATION
  - household <object>: household object for member/entity
  - member <object>: member/entity object to be linked
  - integration <string>: integration id to be linked
  - folderId <string>: id of existing folder, if new then it will create
  - type <string>: member or entity, used in toast message
*/
export const linkMemberFolder = (
  household,
  member,
  integration,
  folderId,
  type
) => {
  let memberId = member.uuid ? member.uuid : member.id;
  return apiAction({
    path: `/household/${household?.uuid}/member/${memberId}/integration/${integration}/link`,
    method: 'PUT',
    data: folderId === 'new' ? { create: true } : { folder: folderId },
    passId: household?.id,
    passSubId: member?.id,
    passThirdParam: integration,
    onSuccess: getMemberIntegrationInfo,
    successMessage: `${type} Linked`,
    errorMessage: 'Error Linking',
    label: MemberTypes.LINK_MEMBER_FOLDER,
  });
};

/*
  API CALL TO UNLINK MEMBER/ENTITY FOLDER FROM FILE STORAGE INTEGRATION
  - household <object>: household object for member/entity
  - member <object>: member/entity object to be unlinked
  - integration <string>: integration id to be unlinked
  - folderId <string>: id of existing folder to unlink
  - type <string>: member or entity, used in toast message
*/
export const unlinkMemberFolder = (household, member, integration, type) => {
  let memberId = member.uuid ? member.uuid : member.id;
  return apiAction({
    path: `/household/${household?.uuid}/member/${memberId}/integration/${integration}/unlink`,
    method: 'PUT',
    passId: household?.id,
    passSubId: member?.id,
    passThirdParam: integration,
    onSuccess: getMemberIntegrationInfo,
    successMessage: `${type} Unlinked`,
    errorMessage: 'Error Unlinking',
    label: MemberTypes.UNLINK_MEMBER_FOLDER,
  });
};
