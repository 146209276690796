import { combineReducers } from '@reduxjs/toolkit';
import configs from './configs';
import discovery from './discovery';
import dropdowns from '../features/dropdowns';
// import dropdowns from './dropdowns';
import households from './households';
import instruments from './instruments';
import marker from './marker';
import members from './members';
import notifications from './notifications';
import targetMaps from './targetMaps';
import user from './user';

const appReducer = combineReducers({
  user: user,
  households: households,
  members: members,
  instruments: instruments,
  targetMaps: targetMaps,
  notifications: notifications,
  discovery: discovery,
  configs: configs,
  marker: marker,
  dropdowns: dropdowns,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
