/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingAnimation } from '..';
import { launchLink } from '../../assets';
import { colors, fonts, inputColors } from '../../styles/variables';
import { DateFormat, GenerateEntityLabel } from '../../utils';

//COMPONENT TO DISPLAY LINKED/MEMBER FINANCIAL FROM INTEGRATION
const LinkedIntegrationItem = ({
  item,
  isFinancial = false,
  selectedIntegration,
  preferences,
  memberCategories,
  loadingId,
  unlinkOnClick,
  createRecordOnClick,
}) => {
  const generatePartnerDisplay = (item) => {
    if (item) {
      if (item.hasOwnProperty('partner_data') && item.partner_data) {
        if (selectedIntegration.key === 'redtail') {
          if (
            item.partner_data.hasOwnProperty('ContactRecord') &&
            item.partner_data.ContactRecord
          ) {
            return `${item.partner_data.ContactRecord.Firstname} ${item.partner_data.ContactRecord.Lastname}`;
          }
        }
        switch (selectedIntegration.key) {
          case 'wealthbox':
            return item.partner_data.name;
          case 'riskalyze':
            return `${item.partner_data.fname} ${item.partner_data.lname}`;
          case 'td':
            return `${item.partner_data.firstName} ${item.partner_data.lastName}`;
          case 'blackdiamond':
            return `${item.partner_data.first_name} ${item.partner_data.last_name}`;
          default:
            return item.name;
        }
      }
      return item.name;
    }
  };

  const showDOB = () => {
    return selectedIntegration.id !== 'axasf';
  };

  const generateFinancial = () => {
    return (
      <FinancialContainer>
        <ItemInfo>
          <LeftItemInfo>
            <NameContainer>
              <span>{item?.cooked?.Product_Family__c}</span>
              {item?.cooked?.Product__c && item?.cooked?.Product__c !== '' && (
                <FinancialFamily>
                  &#40;
                  {<span>{item.cooked.Product__c}</span>}
                  &#41;
                </FinancialFamily>
              )}
            </NameContainer>
            {item?.cooked?.Product_Type__c &&
              item?.cooked?.Product_Type__c !== '' && (
                <ProductType>{item?.cooked?.Product_Type__c}</ProductType>
              )}
          </LeftItemInfo>
          <RightItemInfo>{item.action}</RightItemInfo>
        </ItemInfo>
        <IntegrationInfo>
          <LeftIntegrationInfo>
            <IntegrationItemContainer>
              <span>{item.name}</span>
              {item?.cooked?.external_url && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.cooked.external_url}
                >
                  <LaunchImage
                    src={launchLink}
                    alt="launch"
                    data-image="launch"
                  />
                </a>
              )}
            </IntegrationItemContainer>
          </LeftIntegrationInfo>
          <RightIntegrationInfoFinancial>
            {item.message}
          </RightIntegrationInfoFinancial>
        </IntegrationInfo>
      </FinancialContainer>
    );
  };

  return (
    <LinkedItemsContainer>
      {isFinancial ? (
        generateFinancial()
      ) : (
        <ItemContainer>
          <ItemInfo>
            <LeftItemInfo>
              <NameContainer>
                {item.avatar && (
                  <img
                    src={item.avatar.thumbnail}
                    alt="avatar"
                    data-image="user-icon"
                  />
                )}
                <span>{item.name}</span>
                {item.member_type === 'member' && (
                  <MemberAge>&#40;{item.age}&#41;</MemberAge>
                )}
              </NameContainer>
              {showDOB() && item.member_type === 'member' && (
                <AgeContainer>
                  <AgeLabel>DOB</AgeLabel>
                  <AgeValue>
                    {preferences &&
                      DateFormat(item.date_of_birth, preferences.date_format)}
                  </AgeValue>
                </AgeContainer>
              )}
            </LeftItemInfo>
            <RightItemInfo>
              {GenerateEntityLabel(item.category, memberCategories)}
            </RightItemInfo>
          </ItemInfo>
          <IntegrationInfo>
            <LeftIntegrationInfo>
              <IntegrationItemContainer>
                {item.hasOwnProperty('risk_url') && item.risk_url && (
                  <RiskalyzeImage
                    src={item.risk_url}
                    alt="Nitrogen score"
                    data-image="riskalyze-score"
                  />
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: generatePartnerDisplay(item),
                  }}
                />
                {item.hasOwnProperty('partner_data') &&
                  item.partner_data &&
                  item.partner_data.hasOwnProperty('status') &&
                  item.partner_data.status !== null && 
                  typeof item.partner_data.status == 'string' && (
                    <StatusLabel>{item.partner_data.status}</StatusLabel>
                  )}
                {item.external_url && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.external_url}
                  >
                    <LaunchImage src={launchLink} alt="launch" />
                  </a>
                )}
              </IntegrationItemContainer>
            </LeftIntegrationInfo>
            {item.not_linked ? (
              item.id === loadingId ? (
                <LoadingAnimationContainer>
                  <LoadingAnimation
                    dots={true}
                    color={colors.lightBlue}
                    smaller={true}
                  />
                  Creating Record
                </LoadingAnimationContainer>
              ) : (
                <RightIntegrationInfo
                  onClick={() => {
                    createRecordOnClick(item);
                  }}
                >
                  + Create Salesforce Record
                </RightIntegrationInfo>
              )
            ) : item.id === loadingId ? (
              <LoadingAnimationContainer>
                <LoadingAnimation
                  dots={true}
                  color={colors.lightBlue}
                  smaller={true}
                />
                Unlinking
              </LoadingAnimationContainer>
            ) : (
              <RightIntegrationInfo
                onClick={() => {
                  unlinkOnClick(item);
                }}
              >
                Unlink
              </RightIntegrationInfo>
            )}
          </IntegrationInfo>
        </ItemContainer>
      )}
    </LinkedItemsContainer>
  );
};

const AgeContainer = styled.div`
  flex: 0 0 auto;
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const AgeLabel = styled.span`
  font-weight: ${fonts.semiBold};
  /* color: ${colors.darkGrey}; */
`;

const ProductType = styled.span`
  margin: 0 8px 0 0;
  color: ${colors.paleGrey};
  font-size: 12px;
  font-weight: ${fonts.regular};
`;

const FinancialFamily = styled.span`
  margin-left: 5px;
  color: ${colors.paleGrey};
  font-size: 11px;
  font-weight: ${fonts.regular};
`;

const AgeValue = styled.span`
  margin: 0 8px 0 5px;
`;

const LeftItemInfo = styled.div`
  flex: 1 1 auto;
`;

const RightItemInfo = styled.div`
  flex: 0 0 auto;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.paleGrey};
`;

const LinkedItemsContainer = styled.div`
  margin: 10px 0;
`;

const RiskalyzeImage = styled.img`
  width: 30px;
  margin-right: 10px;
`;

const NameContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    flex: 0 0 auto;
  }
  font-weight: ${fonts.semiBold};
  flex: 1 1 auto;
  font-size: 16px;
`;

const StatusLabel = styled.span`
  border: 1px solid ${colors.paleGrey};
  font-size: 10px;
  margin-left: 5px;
  background: white;
  color: ${colors.paleGrey};
  border-radius: 2px;
  padding: 1px 4px;
`;

const MemberAge = styled.span`
  margin-left: 5px;
  color: ${colors.paleGrey};
  font-size: 13px;
  font-weight: ${fonts.regular};
`;

const ItemContainer = styled.div`
  border: 1px solid ${inputColors.border};
  border-radius: 3px;
  overflow: hidden;
`;

const FinancialContainer = styled.div`
  border: 1px solid ${inputColors.border};
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const ItemInfo = styled.div`
  padding: 10px 15px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
`;

const IntegrationInfo = styled.div`
  background: rgba(238, 238, 238, 0.5);
  padding: 10px 15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const LeftIntegrationInfo = styled.div`
  flex: 1 1 auto;
`;

const RightIntegrationInfo = styled.div`
  flex: 0 0 auto;
  font-size: 13px;
  color: ${colors.lightBlue};
  cursor: pointer;
`;
const RightIntegrationInfoFinancial = styled.div`
  flex: 0 0 auto;
  color: ${colors.paleGrey};
  font-size: 10px;
`;

const IntegrationItemContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const LaunchImage = styled.img`
  cursor: pointer;
  width: 11px;
  margin-left: 8px;
`;

const LoadingAnimationContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: ${colors.lightBlue};
`;

LinkedIntegrationItem.propTypes = {
  item: PropTypes.object,
  isFinancial: PropTypes.bool,
  selectedIntegration: PropTypes.object,
  preferences: PropTypes.object,
  memberCategories: PropTypes.array,
  loadingId: PropTypes.string,
  unlinkOnClick: PropTypes.func,
  createRecordOnClick: PropTypes.func,
};

export default LinkedIntegrationItem;
