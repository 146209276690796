import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppRouter, ConsumerRouter } from '.';
import { Error } from '../pages';
import {
  BASENAME as basename,
  CONSUMER_BASENAME as consumerBasename,
} from '../utils/constants';
import { CheckIsConnectURL } from '../utils';

/*
  First router encountered by the app
  - App is wrapped Error Boundary which bubbles up errors to an error page instead of a white screen
  - If there is an error a tracking even will also be sent with details
  - First checks is url includes consumerBasename to determine which router to use
  - Router contains navbar and app router (which is everything displayed under navbar)
  - Basename refers to value prepended to app e.g. /ampux
*/
const EntryRouter = ({ isConsumer }) => {
  const appRouter = createBrowserRouter(
    [
      {
        path: '*',
        element: <AppRouter />,
        errorElement: <Error />,
      },
    ],
    {
      basename: `/${basename}`,
    }
  );

  const consumerRouter = createBrowserRouter(
    [{ path: '*', element: <ConsumerRouter />, errorElement: <Error /> }],
    {
      basename: `/${consumerBasename}`,
    }
  );

  return (
    <>
      {CheckIsConnectURL() ? (
        <RouterProvider
          router={consumerRouter}
          basename={consumerBasename}
          isConsumer={isConsumer}
        />
      ) : (
        <RouterProvider router={appRouter} basename={basename} />
      )}
    </>
  );
};

EntryRouter.propTypes = {
  isConsumer: PropTypes.bool,
};

export default EntryRouter;
