import { useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { darken } from 'polished';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Notification, TypeaheadSearch } from '.';
import { closeAllDropdowns, removeHouseholdInfo } from '../store/actions';
import {
  UseOutsideClick,
  HouseholdSearch,
  CreateCustomMarker,
  SendTrackingEvent,
} from '../utils';
import { selectThemes } from '../styles/themes';
import { noNotifications } from '../assets';
import { IconButton } from '../styles/library/imageStyles';
import { HouseholdContainer } from '../styles/library/dropdownStyles';
import {
  colors,
  fonts,
  boxShadows,
  inputColors,
  maxDevice,
} from '../styles/variables';
import PropTypes from 'prop-types';
import { InputLabel } from '../styles/library/inputStyles';

const NavDropdown = ({ content, theme, show, invocationId }) => {
  const navigate = useNavigate();
  const dropdownTheme = {
    float: theme.float,
    top: theme.top,
    right: theme.right,
    left: theme.left,
    width: theme.width,
    listPadding: theme.listPadding,
    mobileWidth: theme.mobileWidth,
    mobileRight: theme.mobileRight,
    mobileLeft: theme.mobileLeft,
    caretColor: theme.caretColor,
    caretRight: theme.caretRight,
    caretLeft: theme.caretLeft,
    maxHeight: theme.maxHeight,
    ...theme,
  };
  const dispatch = useDispatch();
  const ref = useRef();

  const [selectedOption, setSelectedOption] = useState();

  UseOutsideClick(ref, () => {
    if (show) dispatch(closeAllDropdowns());
  });

  const clickOnName = () => {
    dispatch(closeAllDropdowns());
    dispatch(removeHouseholdInfo());
  };

  const loadOptions = (input) => {
    if (input.length >= 2) {
      return HouseholdSearch(input, clickOnName, content.householdStartPage);
    }
  };

  const createDropdownItem = (item, index) => {
    switch (item.kind) {
      case 'text':
        return createDropdownLink(item, index);
      case 'textLabel':
        return createDropdownTextLabel(item, index);
      case 'function':
        return createDropdownFunction(item, index);
      case 'button':
        return createDropdownButton(item, index);
      case 'buttonLink':
        return createDropdownButtonLink(item, index);
      case 'icon':
        return createDropdownIcon(item, index);
      case 'input':
        return createDropdownInput(item, index);
      case 'notification':
        return createDropdownNotification(item, index);
      default:
        return createDropdownLink(item, index);
    }
  };

  const createDropdownLink = (item, index) => {
    return (
      <DropdownListItem
        key={index}
        style={
          item.endSection
            ? {
                borderBottom: `1px solid ${darken(0.2, colors.lightGrey)}`,
              }
            : {
                borderBottom: `1px solid ${colors.lightGrey}`,
              }
        }
      >
        {item.outside ? (
          <>
            <a
              href={item.link}
              onClick={item.onClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.label}
            </a>
            {item.version && (
              <VersionNumber id="version">{item.version}</VersionNumber>
            )}
          </>
        ) : item.oldUI ? (
          <a href={item.link} onClick={item.onClick}>
            {item.label}
          </a>
        ) : (
          <Link to={item.link} onClick={item.onClick}>
            {item.label}
            {item.superscript && <sup>{item.superscript}</sup>}
            {item.new && <NewTag>New</NewTag>}
          </Link>
        )}
      </DropdownListItem>
    );
  };

  const createDropdownTextLabel = (item, index) => {
    const { items } = content;
    const isLast = items.length - 1 === index;
    return (
      <DropdownListItemColumn
        key={index}
        id={item.id}
        style={{
          borderBottom: !isLast ? `1px solid ${colors.lightGrey}` : '',
        }}
      >
        <InputLabel margin="0">{item.label}</InputLabel>
        {item.link ? <a href={item.link}>{item.value}</a> : <p>{item.value}</p>}
      </DropdownListItemColumn>
    );
  };

  const createDropdownFunction = (item, index) => {
    return (
      <DropdownListItem
        key={index}
        style={
          item.endSection
            ? {
                borderBottom: `1px solid ${darken(0.2, colors.lightGrey)}`,
              }
            : {
                borderBottom: `1px solid ${colors.lightGrey}`,
              }
        }
      >
        <FunctionText onClick={() => item.function()}>
          {item.label}
        </FunctionText>
      </DropdownListItem>
    );
  };

  const createDropdownButton = (item, index) => {
    return (
      <DropdownListItem key={index}>
        <Button
          text={item.label}
          theme={item.theme}
          onClick={item.function}
          imageSrc={item.icon}
        />
      </DropdownListItem>
    );
  };

  const createDropdownButtonLink = (item, index) => {
    return (
      <DropdownListItem key={index}>
        <Link to={item.link} onClick={item.onClick}>
          <Button text={item.label} theme={item.theme} imageSrc={item.icon} />
        </Link>
      </DropdownListItem>
    );
  };

  const createDropdownIcon = (item, index) => {
    return (
      <DropdownListItem key={index}>
        {item.isCustom ? (
          CreateCustomMarker(item)
        ) : (
          <IconButton
            alt={item.label}
            src={item.icon}
            title={item.label}
            data-image={item.name}
            onClick={item.function}
            style={
              item.isSelected
                ? {
                    background: colors.lightGrey,
                    borderRadius: index === 0 ? '0 0 0 6px' : null,
                  }
                : { cursor: 'pointer' }
            }
          />
        )}
      </DropdownListItem>
    );
  };

  const handleChange = (selectedOption, { action }) => {
    setSelectedOption(selectedOption);
    if (action === 'input-change') {
      if (selectedOption.length > 1) {
        SendTrackingEvent(
          invocationId,
          'action',
          'nav_top_household_search',
          null,
          {
            query: selectedOption,
          }
        );
      }
    }
  };

  const redirectSearch = () => {
    if (selectedOption && selectedOption.hasOwnProperty('value')) {
      navigate(
        `/household/${selectedOption.value}/${content?.householdStartPage}`
      );
      dispatch(closeAllDropdowns());
      dispatch(removeHouseholdInfo());
      return setSelectedOption();
    }
  };

  const handleSelectChange = (value) => {
    if (value && value.hasOwnProperty('value')) {
      setSelectedOption(value);
      SendTrackingEvent(
        invocationId,
        'action',
        'nav_top_household_select',
        null,
        { household: value.value }
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
      redirectSearch();
    }
  };

  const createDropdownInput = (item, index) => {
    return (
      <DropdownListItem
        key={index}
        style={{
          background: colors.darkGrey,
          padding: '12px 10px',
          borderRadius: '0 0 5px 5px',
          width: '280px',
        }}
      >
        <TypeaheadSearch
          showSearchIcon={true}
          cacheOptions={false}
          placeholder={item.label}
          loadOptions={loadOptions}
          selectedOption={selectedOption}
          onChange={(e, action) => handleChange(e, action)}
          onSelectChange={(e) => handleSelectChange(e)}
          onKeyDown={(e) => onKeyDown(e)}
          styles={selectThemes.navbar}
        />
      </DropdownListItem>
    );
  };

  const createDropdownNotification = (item, index) => {
    return (
      <DropdownListItem key={index}>
        <Notification notification={item} onClick={item.onClick} />
      </DropdownListItem>
    );
  };

  const generateDropdownHeading = (content) => {
    if (content.headingIcon) {
      return (
        <DropdownHeadingContainer>
          <DropdownHeadingFlex>{content.heading}</DropdownHeadingFlex>
          <DropdownHeadingIcon
            src={content.headingIcon?.source}
            alt={content.headingIcon?.name}
            onClick={content.headingIcon?.function}
            data-image={content.headingIcon?.name}
          />
        </DropdownHeadingContainer>
      );
    } else {
      return content.heading;
    }
  };

  const generateEmptyState = (dropdown) => {
    if (dropdown === 'Notifications') {
      return (
        <NoNotificationsContainer>
          <NoNotificationsImage
            src={noNotifications}
            alt="No Notifications"
            data-image={`no-notifications`}
          />
          <NoNotificationsText>No Notifications</NoNotificationsText>
        </NoNotificationsContainer>
      );
    } else {
      return null;
    }
  };

  return (
    <ThemeProvider theme={dropdownTheme}>
      {show && (
        <>
          <DropdownContainer ref={ref}>
            {content.heading && (
              <DropdownHeading>
                {generateDropdownHeading(content)}
              </DropdownHeading>
            )}
            {content.impersonating && (
              <ImpersonatingContainer
                onClick={content.showCUR ? content?.delegate?.onClick : null}
                style={{
                  cursor: content.showCUR ? 'pointer' : 'default',
                }}
              >
                <DelegateNameContainer>
                  <DelegateLabel>Delegate</DelegateLabel>
                  <DelegateName>{content?.delegate?.name}</DelegateName>
                  {content.showCUR && <ChangeRole>Change User Role</ChangeRole>}
                </DelegateNameContainer>
                <AvatarImage
                  src={content?.delegate?.avatar}
                  alt="avatar"
                  data-image="delegate-avatar"
                />
              </ImpersonatingContainer>
            )}
            {content.impersonatingCustomer && (
              <ImpersonatingContainer
                onClick={content.showCUR ? content?.delegate?.onClick : null}
                style={{ cursor: 'pointer' }}
              >
                <DelegateNameContainer>
                  <DelegateLabel>CUSTOMER</DelegateLabel>
                  <CustomerName>{content?.customer?.name}</CustomerName>
                  <ChangeRole onClick={content?.customer?.onClick}>
                    Logout
                  </ChangeRole>
                </DelegateNameContainer>
              </ImpersonatingContainer>
            )}
            {content.clientPreview && (
              <ImpersonatingContainer style={{ cursor: 'default' }}>
                <DelegateNameContainer>
                  <DelegateLabel>CLIENT PREVIEW</DelegateLabel>
                  <CustomerName>
                    {content.memberName || 'Unknown Member'}
                  </CustomerName>
                  <ChangeRole onClick={content.exitClientPreview}>
                    Log Out
                  </ChangeRole>
                  {/* <ChangeRole onClick={content.exitClientPreview}>
                    Exit Preview
                  </ChangeRole> */}
                </DelegateNameContainer>
              </ImpersonatingContainer>
            )}
            {content.horizontal ? (
              <DropdownListHorizontal>
                {content.items.map((item, index) => {
                  return createDropdownItem(item, index);
                })}
              </DropdownListHorizontal>
            ) : (
              <DropdownList>
                {content && content?.items?.length >= 1
                  ? content.items.map((item, index) => {
                      return createDropdownItem(item, index);
                    })
                  : generateEmptyState(content.heading)}
              </DropdownList>
            )}
            {content.bottomButton && (
              <div style={{ padding: '10px 15px' }}>
                <Button
                  text={content.bottomButton.label}
                  theme={content.bottomButton.theme}
                  onClick={content.bottomButton.function}
                />
              </div>
            )}
            {content.bottomContent && (
              <div
                style={{
                  padding: '13px 15px',
                  borderTop: `1px solid ${colors.lightGrey}`,
                  fontSize: '13px',
                  display: 'flex',
                  color: colors.lightBlue,
                }}
              >
                {content.bottomContent.map((item, index) => {
                  if (item.type === 'link') {
                    return (
                      <BottomContainerItem key={index}>
                        <Link to={item.to} onClick={item.onClick}>
                          {item.text}
                        </Link>
                      </BottomContainerItem>
                    );
                  }
                  if (item.type === 'function') {
                    return (
                      <BottomContainerItem
                        key={index}
                        style={{ textAlign: 'right' }}
                        onClick={item.function}
                      >
                        {item.text}
                      </BottomContainerItem>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </DropdownContainer>
        </>
      )}
    </ThemeProvider>
  );
};

NavDropdown.propTypes = {
  theme: PropTypes.object,
  buttonFunc: PropTypes.func,
  content: PropTypes.object,
  show: PropTypes.bool,
  invocationId: PropTypes.string,
};

//Container for Dropdown Component - Pass themes as props to style when using
const DropdownContainer = styled.div`
  @media ${maxDevice.tablet} {
    right: ${(props) =>
      props.theme.tabletRight
        ? props.theme.tabletRight
        : props.theme.mobileRight};
    left: ${(props) => props.theme.mobileLeft};
    width: ${(props) => props.theme.mobileWidth};
  }
  @media ${maxDevice.sideNav} {
    right: ${(props) => props.theme.mobileRight};
    left: ${(props) => props.theme.mobileLeft};
    width: ${(props) => props.theme.mobileWidth};
  }
  border-radius: 0 0 6px 6px;
  /* box-shadow: 0px 1px 8px 0px rgb(206, 206, 206); */
  box-shadow: ${boxShadows.boxShadowDropdown};
  z-index: 9999 !important;
  float: ${(props) => props.theme.float};
  position: fixed;
  top: ${(props) => props.theme.top};
  right: ${(props) => props.theme.right};
  left: ${(props) => props.theme.left};
  background: white;
  width: ${(props) => props.theme.width};
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: ${(props) => props.theme.caretRight};
    left: ${(props) => props.theme.caretLeft};
    top: -6px;
    z-index: 9999 !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${colors.darkGrey};
    border-bottom: 6px solid ${(props) => props.theme.caretColor};
  }
`;

const DropdownHeadingContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;

const DropdownHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  padding: 10px 15px;
  background: ${colors.darkGrey};
  color: ${colors.white};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownHeadingFlex = styled.span`
  flex: 1 1 auto;
`;

const DropdownHeadingIcon = styled.img`
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  cursor: pointer;
  transition: all 0.1s ease;
  &:hover {
    transform: rotate(20deg);
  }
`;

const BottomContainerItem = styled.span`
  flex: 1 1 auto;
  text-align: left;
  color: ${colors.lightBlue};
  cursor: pointer;
  a {
    color: ${colors.lightBlue};
  }
  &:hover {
    transform: scale(1.01);
  }
`;

const DropdownList = styled.ul`
  @media (max-height: 600px) {
    max-height: ${(props) => props.theme.smallMaxHeight};
  }
  padding: 4px 15px;
  max-height: ${(props) => props.theme.maxHeight};
  height: 100%;
  overflow-y: auto;
  #name {
    display: none;
    @media ${maxDevice.sideNav} {
      display: block;
    }
  }
`;

const DropdownListHorizontal = styled.ul`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  li {
    flex: 1 1 auto;
    padding: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: 1px solid ${colors.lightGrey};
    border-bottom: 0px;
    &:last-child {
      border-right: 0;
    }
    &:hover {
      background: ${colors.hoverLighterGrey};
      &:first-child {
        border-radius: 0 0 0 6px;
      }
      &:last-child {
        border-radius: 0 0 6px 0;
      }
    }
  }
`;

const DropdownListItem = styled.li`
  padding: 8px 0;
  padding: ${(props) => props.theme.listPadding};
  display: block;
  font-size: 13px;
  transition: all 0.1s ease;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  a {
    color: ${colors.darkGrey};
    flex: 1 1 auto;
    &:hover {
      color: ${colors.paleGrey};
      display: block;
      /* transform: scale(1.01); */
    }
  }
  ${HouseholdContainer} {
    color: ${colors.lightBlue};
    width: 100%;
    display: block;
    &:hover {
      color: ${colors.blue};
      transform: scale(1.01);
    }
  }
`;

const DropdownListItemColumn = styled(DropdownListItem)`
  flex-direction: column;
  align-items: flex-start;
  p {
    font-size: 14px;
  }
  a {
    font-size: 14px;
    color: ${colors.lightBlue};
    &:hover {
      color: ${colors.lightBlue};
      opacity: 0.9;
    }
  }
`;

const ImpersonatingContainer = styled.div`
  padding: 10px 15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  transition: all 0.1s ease;
  color: ${colors.darkGrey};
  background: rgb(245, 245, 245);
  cursor: pointer;
`;

const AvatarImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50px;
  flex: 0 0 auto;
`;

const DelegateNameContainer = styled.div`
  flex: 1 1 auto;
  margin-right: 8px;
`;

const DelegateName = styled.p`
  font-weight: ${fonts.semiBold};
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomerName = styled(DelegateName)`
  width: 136px;
  font-size: 11px;
`;

const ChangeRole = styled.p`
  font-size: 10px;
  color: ${colors.lightBlue};
  cursor: pointer;
`;

const DelegateLabel = styled.p`
  font-size: 9px;
  color: ${colors.paleGrey};
  text-transform: uppercase;
  font-weight: ${fonts.semiBold};
`;

const FunctionText = styled.span`
  cursor: pointer;
  &:hover {
    color: ${colors.paleGrey};
    display: block;
  }
`;

const NoNotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
`;

const NoNotificationsImage = styled.img`
  flex: 1 1 auto;
  width: 100%;
  height: 200px;
`;

const NoNotificationsText = styled.p`
  flex: 0 0 auto;
  margin-top: 30px;
  font-size: 20px;
  font-weight: ${fonts.semiBold};
  color: ${colors.paleGrey};
`;

const VersionNumber = styled.span`
  color: ${colors.paleGrey};
  font-size: 11px;
  margin-left: 10px;
`;

const NewTag = styled.span`
  color: ${inputColors.label};
  background: ${colors.lightGrey};
  color: ${colors.blue};
  background: ${inputColors.primary25};
  font-weight: ${fonts.semiBold};
  float: right;
  text-transform: uppercase;
  padding: 2px 8px;
  border-radius: 2px;
  font-size: 10px;
`;

export default NavDropdown;
