/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import { PageHeader, DropZone, Button, Select } from '../../components';
import {
  checkboxGreen,
  square,
  hint,
  chevronDownRed,
  check,
} from '../../assets';
import {
  downloadCSVFile,
  uploadCSVFile,
  setUploadFile,
  getHouseholdInfo,
  clearUploadCSVResults,
} from '../../store/actions';
import { PageContent } from '../../styles/library/layoutStyles';
import {
  colors,
  inputColors,
  messageColors,
  fonts,
} from '../../styles/variables';
import {
  headerContent,
  UpdatePageTitle,
  // SuccessNotification,
  ErrorNotification,
  HtmlToString,
} from '../../utils';
import { useParams } from 'react-router-dom';

const HouseholdLoader = () => {
  UpdatePageTitle('Data Loader');
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  const { currentHousehold, downloadedCSV, uploadCSV, uploadedFile } =
    useSelector((state) => ({
      currentHousehold: state.households.currentHousehold,
      downloadedCSV: state.households.downloadedCSV,
      uploadCSV: state.households.uploadCSV,
      uploadedFile: state.households.uploadedFile,
    }));
  const [CSVLink, setCSVLink] = useState(
    `/household/${householdId}/loader/export`
  );
  const [selectedMember, setSelectedMember] = useState();
  const [membersList, setMembersList] = useState([]);
  const [onlyOnSync, setOnlyOnSync] = useState(false);
  const [fileToUpload, setFileToUpload] = useState();
  // const [clearUploadedFiles, setClearUploadedFiles] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [resultsCounts, setResultsCounts] = useState();
  const [expandErrors, setExpandErrors] = useState(false);
  const [errorsList, setErrorsList] = useState([]);
  const [uploadFileType, setUploadFileType] = useState();
  const fileOptions = [
    {
      label: 'Asset Map Default (.csv)',
      value: 'amdefault',
    },
    {
      label: 'Astute XML (.xml)',
      value: 'astutexml',
    },
  ];
  useEffect(() => {
    dispatch(downloadCSVFile(householdId));
    return () => {
      setShowResults(false);
      setResultsCounts();
      setExpandErrors(false);
      dispatch(clearUploadCSVResults());
      setUploadFileType(fileOptions[0]);
    };
  }, []);

  useEffect(() => {
    if (uploadedFile) {
      let cnt = uploadedFile.match(new RegExp('<', 'g'));
      if (cnt == null) {
        setUploadFileType(fileOptions[0]);
      } else {
        if (cnt?.length > 5) {
          setUploadFileType(fileOptions[1]);
        } else {
          setUploadFileType(fileOptions[0]);
        }
      }
      setFileToUpload(uploadedFile);
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (downloadedCSV && downloadedCSV?.url) {
      setCSVLink(downloadedCSV.url);
    }
  }, [downloadedCSV]);

  useEffect(() => {
    if (uploadCSV) {
      if (uploadCSV?.counts) {
        setResultsCounts(uploadCSV.counts);
        setShowResults(true);
      }
      if (uploadCSV?.error) {
        setErrorsList(uploadCSV.error);
      }
      dispatch(getHouseholdInfo(householdId));
      setButtonLoading(false);
      // setFileToUpload();
      // setClearUploadedFiles(false);
    }
  }, [uploadCSV]);

  useEffect(() => {
    if (currentHousehold && currentHousehold?.householdmembers) {
      const transformedMembers = currentHousehold.householdmembers.map(
        (member) => {
          member.label = HtmlToString(member.name);
          member.value = member.id;
          return member;
        }
      );
      setMembersList(transformedMembers);
      if (transformedMembers.length !== 0) {
        setSelectedMember(transformedMembers[0]);
        setUploadFileType(fileOptions[0]);
      }
    }
  }, [currentHousehold]);

  const uploadFile = () => {
    setShowResults(false);
    if (buttonLoading) {
      return;
    }
    if (!fileToUpload) {
      return ErrorNotification('Please Upload a File');
    }
    if (!selectedMember) {
      return ErrorNotification('Please Select a Member');
    }
    const uploadedModified = fileToUpload.replaceAll(/\n/gi, '\n');
    const data = {
      member: selectedMember.id,
      update_values_on_sync: onlyOnSync,
      data: String(uploadedModified),
      filetype: uploadFileType.value,
    };
    setButtonLoading(true);
    dispatch(uploadCSVFile(householdId, data));
    // setClearUploadedFiles(true);
  };

  const determinePlural = (num) => {
    return num !== 1;
  };

  return (
    <>
      <PageHeader content={headerContent.householdLoader} />
      <PageContent>
        <LoaderContainer>
          <p>
            In order to import data you must have a CSV file with the following
            columns:
          </p>
          <CodeChunkContainer>
            <CodeChunk>id*,name,source,value</CodeChunk>
          </CodeChunkContainer>
          <OptionsContainer>
            <div>
              You can download our{' '}
              <DownloadLink href="/ampux/files/sample_csv.csv" download>
                empty template
              </DownloadLink>
            </div>
            <MiddleText>OR</MiddleText>
            <div>
              You can download this household&apos;s{' '}
              <DownloadLink href={CSVLink} download>
                existing data
              </DownloadLink>{' '}
              as CSV
            </div>
          </OptionsContainer>

          <UploadFileContainer>
            <DropZone
              type="csv"
              // accepted=".csv, text/csv, text/xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              accepted={{
                'text/csv': ['.csv'],
                'text/xml': ['.xml'],
                'application/xml': ['.xml'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx'],
              }}
              subtext="Max File Size: 2MB CSV or XML"
              dispatchFunction={setUploadFile}
              // clearFiles={clearUploadedFiles}
            />
          </UploadFileContainer>
          <MemberSelectContainer>
            <SelectContainer>
              <InputLabel>
                Selected Member{' '}
                <Tooltip
                  title={
                    'Select the member that the imported instruments will be assigned to'
                  }
                  position="right"
                  trigger="mouseenter click"
                  followCursor="true"
                  delay={0}
                  arrow={true}
                  arrowSize={'small'}
                  animation={'fade'}
                  animateFill={false}
                  transitionFlip={false}
                  size={'small'}
                >
                  <TooltipIcon src={hint} alt="hint" data-image="input-hint" />
                </Tooltip>
              </InputLabel>
              <Select
                selectedOption={selectedMember}
                onChange={(e) => setSelectedMember(e)}
                options={membersList}
                isSearchable={true}
                placeholder="Select Member"
                autoFocus={false}
              />
              <br />
              <InputLabel>
                Select File Type{' '}
                <Tooltip
                  title={'Select the file format/source of your upload file'}
                  position="right"
                  trigger="mouseenter click"
                  followCursor="true"
                  delay={0}
                  arrow={true}
                  arrowSize={'small'}
                  animation={'fade'}
                  animateFill={false}
                  transitionFlip={false}
                  size={'small'}
                >
                  <TooltipIcon src={hint} alt="hint" data-image="input-hint" />
                </Tooltip>
              </InputLabel>
              <Select
                selectedOption={uploadFileType}
                onChange={(e) => setUploadFileType(e)}
                options={fileOptions}
                isSearchable={false}
                placeholder="Select File Type"
                autoFocus={false}
              />
            </SelectContainer>
          </MemberSelectContainer>
          <CheckBoxContainer>
            <CheckBoxClick onClick={() => setOnlyOnSync(!onlyOnSync)}>
              {onlyOnSync ? (
                <CheckBoxImage
                  src={checkboxGreen}
                  alt="checked"
                  data-image="checkbox-checked"
                />
              ) : (
                <CheckBoxImage
                  src={square}
                  alt="empty"
                  data-image="checkbox-unchecked"
                />
              )}
            </CheckBoxClick>
            <CheckboxLabel>Only Update Values on Sync</CheckboxLabel>
          </CheckBoxContainer>
          <AdditionalInfoContainer>
            <p>
              * The <code>id</code> column is optional if you only wish to
              import. It&apos;s inclusion allows the ability to update the
              information via upload at a later date.
            </p>
            <p>
              * The order of the columns does not matter. Any additional columns
              are ignored.
            </p>
          </AdditionalInfoContainer>
          <DownloadLink href={CSVLink} download>
            Export Household Financials
          </DownloadLink>
          <ButtonContainer>
            <Button
              text="Upload File"
              onClick={uploadFile}
              showLoading={buttonLoading}
              loadingText="Uploading"
            />
          </ButtonContainer>
          {showResults && (
            <ResultsContainer>
              <ResultsHeading>Results</ResultsHeading>
              <ProcessRow>
                <ProcessHeading>
                  {resultsCounts?.processed} Row
                  {determinePlural(resultsCounts?.processed) &&
                    's'} Processed, {resultsCounts?.success} Successful
                </ProcessHeading>
                {resultsCounts?.error === 0 && (
                  <RowIcon src={check} alt="check" data-image="check" />
                )}
              </ProcessRow>
              {resultsCounts.error !== 0 && (
                <ErrorRowContainer>
                  <ErrorRowHeaderContainer
                    onClick={() => setExpandErrors(!expandErrors)}
                  >
                    <ErrorRowHeading>
                      {resultsCounts?.error} Error
                      {determinePlural(resultsCounts?.error) && 's'} Found
                    </ErrorRowHeading>
                    <RowIcon
                      src={chevronDownRed}
                      alt="expand"
                      data-image="expand"
                    />
                  </ErrorRowHeaderContainer>
                  {expandErrors && (
                    <ErrorContentContainer>
                      {errorsList.map((error, index) => {
                        return (
                          <div key={index}>
                            Row {error.row}: {error.error}
                          </div>
                        );
                      })}
                    </ErrorContentContainer>
                  )}
                </ErrorRowContainer>
              )}
            </ResultsContainer>
          )}
        </LoaderContainer>
      </PageContent>
    </>
  );
};

const LoaderContainer = styled.div`
  font-size: 14px;
  max-width: 600px;
  margin-bottom: 100px;
`;

const CodeChunkContainer = styled.div`
  margin: 15px 0 25px 0;
`;

const CodeChunk = styled.span`
  padding: 8px 12px;
  background-color: ${colors.lightGrey};
  border: 1px solid ${inputColors.border};
  border-radius: 3px;
  font-family: monospace;
`;

const OptionsContainer = styled.div`
  margin: 40px 0 30px 0;
`;

const DownloadLink = styled.a`
  color: ${colors.lightBlue};
  cursor: pointer;
  font-size: 14px;
  &:hover {
    color: ${colors.blue};
  }
`;

const MiddleText = styled.p`
  color: ${colors.paleGrey};
  font-size: 15px;
  margin: 10px 0;
  font-weight: ${fonts.semiBold};
`;

const UploadFileContainer = styled.div`
  margin-bottom: 15px;
`;

const AdditionalInfoContainer = styled.div`
  margin: 30px 0 20px 0;
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const MemberSelectContainer = styled.div`
  margin: 20px 0 15px 0;
`;

const SelectContainer = styled.div`
  max-width: 332px;
  flex: 1 1 auto;
`;

const InputLabel = styled.label`
  margin: 0 0 5px 0;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const TooltipIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-left: 2px;
  margin-bottom: -1px;
  opacity: 0.75;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const CheckBoxClick = styled.span`
  cursor: pointer;
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const CheckBoxImage = styled.img`
  width: 13px;
  height: 13px;
`;

const CheckboxLabel = styled.span`
  flex: 1 1 auto;
  margin-left: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

const ResultsContainer = styled.div`
  margin-top: 20px;
`;

const ResultsHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const ProcessRow = styled.div`
  border: 1px solid ${colors.lightGrey};
  background: ${colors.lighterGrey};
  border-radius: 3px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const ProcessHeading = styled.div`
  flex: 1 1 auto;
`;

const ErrorRowContainer = styled.div`
  border: 1px solid ${messageColors.error};
  color: ${messageColors.error};
  background: ${messageColors.errorBackground};
  border-radius: 3px;
  padding: 10px;
`;

const ErrorRowHeaderContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ErrorRowHeading = styled.div`
  flex: 1 1 auto;
  font-weight: ${fonts.semiBold};
`;

const RowIcon = styled.img`
  flex: 0 0 auto;
  width: 100%;
  max-width: 19px;
  height: 100%;
  max-height: 19px;
`;

const ErrorContentContainer = styled.div`
  padding: 5px 10px 0 10px;
`;

export default HouseholdLoader;
