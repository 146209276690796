import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import {
  Button,
  TextInput,
  CheckBox,
  Select,
  Error,
  TypeaheadSearch,
  TextEditor,
  DetectModalKeys,
  InfoBox,
} from '..';
import { colors, inputColors, boxShadows, fonts } from '../../styles/variables';
import {
  timesWhite,
  user,
  errorCircleRed,
  chevronDown,
  times,
} from '../../assets';
import { UseOutsideClick } from '../../utils';
import {
  CloseButton,
  ModalBackground,
  ModalOverlay,
} from '../../styles/library/modalStyles';
import { selectThemes } from '../../styles/themes';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const SimpleModal = ({
  hide,
  content,
  updateError,
  onEnter,
  error,
  disableClickOutside = false,
  dismissFunction,
  currency,
}) => {
  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (disableClickOutside) {
      return;
    }
    if (!content.disableDismiss) {
      hide();
      if (updateError !== undefined) {
        updateError(false);
      }
    }
  });
  const hideFunction = () => {
    if (content.disableDismiss) {
      window.alert('Select a milestone to continue');
      if (dismissFunction) {
        return dismissFunction();
      }
    } else {
      hide();
      if (updateError !== undefined) {
        updateError(false);
      }
    }
  };

  const generateTextInput = (key, input) => {
    return (
      <TextInputContainer key={key} style={{ width: input.width }}>
        {input.label && (
          <InputLabel htmlFor={input.id}>
            {input.label}
            {input.hasError && (
              <Tooltip
                title={input.hasError[0]}
                position="top"
                trigger="mouseenter click"
                followCursor="true"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
              >
                <TooltipIconError
                  src={errorCircleRed}
                  alt="error"
                  data-image={`tooltip-error}`}
                />
              </Tooltip>
            )}
          </InputLabel>
        )}
        <TextInputRow>
          <TextInput
            type={input.type}
            name={input.name}
            maxLength={input.maxLength}
            // id={input.id}
            hasError={input.hasError}
            required={input.required}
            onKeyPress={input.onKeyPress}
            placeholder={input.placeholder}
            value={input.value}
            onChange={input.onChange}
            hasSuffix={input.hasSuffix}
            suffixValue={input.suffixValue}
          />
          {input.inputImage && (
            <InputImage
              src={input.inputImage}
              data-image={input.inputImage}
              alt={input.imageName}
              onClick={input.imageClick}
            />
          )}
        </TextInputRow>
      </TextInputContainer>
    );
  };

  const generateCurrencyInput = (key, input) => {
    const prefixValue =
      currency?.symbol_location === 'prefix' ? currency.symbol : '';
    const suffixValue =
      currency?.symbol_location === 'suffix' ? currency.symbol : '';
    return (
      <TextInputContainer key={key} style={{ width: input.width }}>
        {input.label && (
          <InputLabel htmlFor={input.id}>{input.label}</InputLabel>
        )}
        <TextInputRow>
          <NumericFormat
            thousandSeparator={currency?.thousand_separator}
            decimalSeparator={currency?.decimal}
            prefix={prefixValue ? `${prefixValue} ` : ''}
            suffix={suffixValue ? ` ${suffixValue}` : ''}
            allowNegative={true}
            className="number-formatter"
            inputMode="numeric"
            value={input.value}
            placeholder={input.placeholder}
            onValueChange={(values) => input.onChange(values, input)}
          />
        </TextInputRow>
      </TextInputContainer>
    );
  };

  const generateTextArea = (key, input) => {
    return (
      <TextInputContainer key={key} style={{ width: input.width }}>
        {input.label && (
          <InputLabel htmlFor={input.id}>{input.label}</InputLabel>
        )}
        <TextInputRow>
          {input.useEditor ? (
            <TextEditor
              value={input.value}
              onChange={input.onChange}
              error={input.hasError}
              id={input.id}
              showClear={input.showClear}
              theme={input.theme}
            />
          ) : (
            <TextArea
              value={input.value}
              onChange={input.onChange}
              id={`textarea-${input.name}`}
              maxLength={input?.maxLength}
              placeholder={input?.placeholder}
              height={input.height}
            />
          )}
          {input.inputImage && (
            <InputImage
              src={input.inputImage}
              alt={input.imageName}
              data-image={input.inputImage}
              onClick={input.imageClick}
            />
          )}
        </TextInputRow>
      </TextInputContainer>
    );
  };

  const generateCheckbox = (key, input) => {
    return (
      <CheckboxContainer key={key}>
        <CheckBox
          label={input.label}
          checked={input.value}
          onClick={input.onChange}
          onChange={input.onChange}
        />
      </CheckboxContainer>
    );
  };

  const generateSelectContainer = (key, input) => {
    return (
      <SelectContainer key={key}>
        {input.label && <InputLabel>{input.placeholder}</InputLabel>}
        <Select
          selectedOption={input.selectedOption}
          onChange={input.onChange}
          options={input.options}
          closeMenuOnSelect={input.closeMenuOnSelect}
          isSearchable={true}
          placeholder={input.placeholder}
          autoFocus={false}
          isDisabled={input.isDisabled}
          components={
            input.customComponent ? { Option: input.customComponent } : null
          }
        />
      </SelectContainer>
    );
  };

  const generateTypeaheadSearchContainer = (key, input) => {
    return (
      <SelectContainer key={key}>
        {input.label && <InputLabel>{input.label}</InputLabel>}
        <TypeaheadSearch
          showSearchIcon={input.showSearchIcon}
          largeIcon={false}
          value={input.value}
          defaultValue={input.defaultValue}
          loadOptions={input.loadOptions}
          placeholder={input.placeholder}
          onSelectChange={input.onChange}
          isMulti={input.isMulti}
          onChange={input.onInputChange}
          styles={selectThemes.inputSearch}
          inputValue={input.searchValue}
          noOptionMessage={input.noOptionMessage}
        />
      </SelectContainer>
    );
  };

  const generateInput = (input, index) => {
    if (!input.isHidden) {
      if (input.type === 'text' || input.type === 'number') {
        return generateTextInput(index, input);
      } else if (input.type === 'currency') {
        return generateCurrencyInput(index, input);
      } else if (input.type === 'textarea') {
        return generateTextArea(index, input);
      } else if (input.type === 'checkbox') {
        return generateCheckbox(index, input);
      } else if (input.type === 'select') {
        return generateSelectContainer(index, input);
      } else if (input.type === 'typeahead') {
        return generateTypeaheadSearchContainer(index, input);
      }
    }
  };

  const generateButtonRowItem = (key, item) => {
    return (
      <ButtonRowItem key={key} onClick={item.onClick}>
        <img src={item.image} alt={item.text} data-image={item.name} />
        <p>{item.text}</p>
      </ButtonRowItem>
    );
  };

  const generateIntegrationCard = (key, item) => {
    return (
      <IntegrationCard key={key}>
        <IntegrationHeading>{item.text}</IntegrationHeading>
        {/* {item.household_name && <p>{item.household_name}</p>} */}
        <MembersList>
          {item.members.map((member, index) => {
            return (
              <Member key={index}>
                <img src={user} alt="user" data-image="user" />
                {member.name}
              </Member>
            );
          })}
        </MembersList>
        <ActionButton onClick={item.onClick}>{item.buttonText}</ActionButton>
      </IntegrationCard>
    );
  };

  const generateInviteRowItem = (key, item) => {
    return (
      <InviteRowItem
        key={key}
        onClick={item.onClick}
        style={
          item.selected
            ? {
                background: 'white',
                border: `1px solid ${colors.lightGrey}`,
                boxShadow: boxShadows.boxShadowSoft,
              }
            : null
        }
      >
        <LeftContainer>
          <img
            src={item.image}
            alt={item.text}
            data-image={item.name}
            style={
              item.selected
                ? {
                    opacity: '1',
                  }
                : null
            }
          />
        </LeftContainer>
        <RightContainer
          style={
            item.selected
              ? {
                  color: colors.darkGrey,
                }
              : null
          }
        >
          <LabelText>{item.text}</LabelText>
          <SubText>{item.subtext}</SubText>
        </RightContainer>
      </InviteRowItem>
    );
  };

  const generateLicenseRowItem = (key, item) => {
    return (
      <LicenseRowItem
        key={key}
        style={{ borderBottom: item.last ? '1px solid transparent' : null }}
      >
        <LeftContainerLicense>
          <ChevronUp
            src={chevronDown}
            alt="up"
            data-image={`arrow-up-${item.name}`}
            onClick={() => item.onClick(item.name, true)}
          />
          <TextInput
            type={'number'}
            name={item.name}
            onBlur={item.onBlur}
            hasError={item.hasError}
            placeholder={'0'}
            value={item.value}
            onChange={item.onChange}
          />
          <ChevronIcon
            src={chevronDown}
            alt="down"
            data-image={`arrow-down-${item.name}`}
            onClick={() => item.onClick(item.name, false)}
          />
        </LeftContainerLicense>
        <RightContainer>
          <LabelText style={{ color: item.color }}>{item.text}</LabelText>
          <SubText>{item.subtext}</SubText>
        </RightContainer>
      </LicenseRowItem>
    );
  };

  const generateLicenseConfirmItem = (key, item) => {
    return (
      <LicenseConfirmItem
        key={key}
        style={{ borderBottom: item.last ? '1px solid transparent' : null }}
      >
        <LicenseAmount>{item.value}</LicenseAmount>
        <MiddleContent>
          <img src={times} alt="times" data-image="close" />
        </MiddleContent>
        <LabelText>{item.text} Licenses</LabelText>
      </LicenseConfirmItem>
    );
  };

  const generateHorizontalContent = (item, index) => {
    if (item.type === 'buttonRow') {
      return generateButtonRowItem(index, item);
    } else if (item.type === 'integration') {
      return generateIntegrationCard(index, item);
    }
  };

  const generateVerticalContent = (item, index) => {
    if (item.type === 'invite') {
      return generateInviteRowItem(index, item);
    }
    if (item.type === 'license') {
      return generateLicenseRowItem(index, item);
    }
    if (item.type === 'confirm_license') {
      return generateLicenseConfirmItem(index, item);
    }
  };

  const generateButtonList = (item, index) => {
    return (
      <ButtonOptionContainer key={index}>
        <Button
          key={index}
          text={item.name}
          onClick={() => item.onClick(item.id)}
        />
      </ButtonOptionContainer>
    );
  };

  return ReactDOM.createPortal(
    <ThemeProvider
      theme={{
        marginTop: content.marginTop ? content.marginTop : '16vh',
      }}
    >
      {onEnter && <DetectModalKeys onEnter={onEnter} onEsc={hideFunction} />}
      <ModalBackground />
      <ModalOverlay style={{ background: 'rgba(0,0,0,0.2)' }}>
        <ModalContainer
          role="dialog"
          ref={ref}
          style={content.minWidth ? { minWidth: content.minWidth } : null}
        >
          <ModalHeadingContainer>
            <ModalHeading>{content.heading}</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hideFunction()}
              alt="close"
              data-image="close-simple-modal"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            {content.infoText ? (
              <ModalInfoText>{content.infoText}</ModalInfoText>
            ) : content.infoTextAlt ? (
              <ModalInfoTextAlt>{content.infoTextAlt}</ModalInfoTextAlt>
            ) : content.text ? (
              <ModalText dangerouslySetInnerHTML={{ __html: content.text }} />
            ) : null}
            {content.infoBox?.message && (
              <InfoBox
                margin={content.infoBox?.margin}
                fontSize={content.infoBox?.fontSize}
                message={content.infoBox?.message}
              />
            )}
            {content.topImage && (
              <TopImage src={content.topImage} alt="integration" />
            )}
            {content.inputs &&
              content.inputs.length !== 0 &&
              content.inputs.map((input, index) => {
                return generateInput(input, index);
              })}
            {content.middleText && (
              <ModalText
                dangerouslySetInnerHTML={{ __html: content.middleText }}
              />
            )}
            {content.internalLink && (
              <ModalText margin={content.internalLink.margin}>
                {content.internalLink.plainText}
                <Link to={content.internalLink.link}>
                  {content.internalLink.linkText}
                </Link>
              </ModalText>
            )}
            {content.horizontalContent &&
              content.horizontalContent.length !== 0 && (
                <HorizontalContentContainer>
                  {content.horizontalContent.map((item, index) => {
                    return generateHorizontalContent(item, index);
                  })}
                </HorizontalContentContainer>
              )}
            {content.verticalContent &&
              content.verticalContent.length !== 0 && (
                <>
                  {content.verticalHeader && (
                    <VerticalHeader>{content.verticalHeader}</VerticalHeader>
                  )}
                  <VerticalContentContainer>
                    {content.verticalContent.map((item, index) => {
                      return generateVerticalContent(item, index);
                    })}
                  </VerticalContentContainer>
                </>
              )}
            {content.buttonList && content.buttonList.length !== 0 && (
              <ButtonListContentContainer>
                {content.buttonList.map((item, index) => {
                  return generateButtonList(item, index);
                })}
              </ButtonListContentContainer>
            )}
            {content.afterInputs &&
              content.afterInputs.length !== 0 &&
              content.afterInputs.map((input, index) => {
                return generateInput(input, index);
              })}{' '}
            {content.afterText &&
              (content.toolTipText ? (
                <Tooltip
                  title={content.toolTipText}
                  position="top"
                  trigger="mouseenter click"
                  followCursor="true"
                  delay={0}
                  arrow={true}
                  arrowSize={'small'}
                  animation={'fade'}
                  animateFill={false}
                  transitionFlip={false}
                  size={'small'}
                >
                  <TooltipText>{content.afterText}</TooltipText>
                </Tooltip>
              ) : (
                <ModalText
                  dangerouslySetInnerHTML={{ __html: content.afterText }}
                />
              ))}
            {content.infoBoxEnd?.message && (
              <InfoBox
                margin={content.infoBoxEnd?.margin}
                fontSize={content.infoBoxEnd?.fontSize}
                message={content.infoBoxEnd?.message}
              />
            )}
            {content.buttons && content.buttons.length !== 0 && (
              <ButtonsRowContainer>
                {content.buttons.map((button, index) => {
                  return (
                    <ButtonContainer
                      key={index}
                      style={index === 1 ? { marginLeft: '10px' } : null}
                    >
                      <Button
                        text={button.text}
                        onClick={button.function}
                        theme={button.theme}
                        showLoading={button.showLoading}
                        loadingText={button.loadingText}
                      />
                    </ButtonContainer>
                  );
                })}
              </ButtonsRowContainer>
            )}
          </ModalContentContainer>
          {error && error !== '' && <Error errorMessage={error} />}
        </ModalContainer>
      </ModalOverlay>
    </ThemeProvider>,
    document.body
  );
};

const TooltipText = styled.span`
  cursor: pointer;
  font-size: 13px;
  color: ${colors.lightBlue};
`;

const ButtonListContentContainer = styled.div`
  margin: 15px 30px 15px 30px;
`;

const ButtonOptionContainer = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  button {
    flex: 1 1 auto;
  }
`;

const ModalContainer = styled.div`
  min-width: 280px;
  max-width: 320px;
  margin: 16vh 20px 20px 20px;
  margin-top: ${(props) => props.theme.marginTop};
  background: white;
  border-radius: 6px;
  text-align: center;
  z-index: 9999 !important;
`;

const ModalHeadingContainer = styled.div`
  padding: 12px 22px;
  background: ${colors.darkGrey};
  border-radius: 6px 6px 0 0;
  position: relative;
`;

const ModalHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  background: ${colors.darkGrey};
  color: ${colors.white};
  font-size: 15px;
  text-transform: capitalize;
`;

const ModalContentContainer = styled.div`
  padding: 15px 15px;
`;

const ModalInfoText = styled.p`
  font-size: 11px;
  margin-bottom: 15px;
  color: ${colors.darkGrey};
`;

const ModalInfoTextAlt = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  color: ${colors.darkGrey};
`;

const ModalText = styled.p`
  font-size: 13px;
  margin: ${(props) => (props.margin ? props.margin : '0 0 15px 0')};
  color: ${colors.darkGrey};
  word-break: break-word;
  a {
    color: ${colors.lightBlue};
  }
  img {
    width: 10px;
    height: 10px;
    margin: 0 1px 0 3px;
  }
`;

const TopImage = styled.img`
  width: 180px;
  margin: 10px 0 15px 0;
`;

const InputImage = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 5px;
  flex: 0 0 auto;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transform: scale(1.01);
  }
`;

const TextArea = styled.textarea`
  border: 1px solid ${inputColors.border};
  color: ${colors.darkGrey};
  resize: none;
  font-size: 12px;
  border-radius: 6px;
  height: 100%;
  flex: 1 1 auto;
  max-height: 500px;
  min-height: ${(props) => (props.height ? props.height : '200px')};
  padding: 15px;
  .ql-bubble .ql-tooltip-editor input[type='text'] {
    left: 0;
  }
`;

const HorizontalContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
`;

const VerticalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  margin: 0 -10px 5px -10px;
`;

const ButtonRowItem = styled.div`
  flex: 1 1 auto;
  background: ${colors.lightGrey};
  border-radius: 4px;
  padding: 14px 18px 18px 18px;
  margin: 0 10px;
  height: 50px;
  width: 100px;
  max-width: 100px;
  cursor: pointer;
  display: block;
  img {
    width: 30px;
    height: 30px;
  }
  p {
    margin-top: 5px;
    font-size: 11px;
    color: ${colors.darkGrey};
    font-weight: ${fonts.semiBold};
  }
  &:hover {
    transform: scale(1.01);
    opacity: 0.85;
  }
`;

const InviteRowItem = styled.div`
  flex: 1 1 auto;
  background: ${colors.lightGrey};
  border-radius: 4px;
  border: 1px solid ${colors.lightGrey};
  margin: 3px 10px;
  cursor: pointer;
  display: block;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 5px;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const LeftContainer = styled.div`
  flex: 0 0 auto;
  width: 40px;
  img {
    width: 25px;
    height: 25px;
    opacity: 0.5;
  }
`;

const LeftContainerLicense = styled.div`
  flex: 0 0 auto;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  input {
    width: 30px;
    padding: 7px 5px;
    text-align: center;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const ChevronIcon = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const ChevronUp = styled(ChevronIcon)`
  transform: rotate(180deg);
`;

const RightContainer = styled.div`
  margin-left: 10px;
  flex: 1 1 auto;
  text-align: left;
  color: ${colors.paleGrey};
`;

const LabelText = styled.p`
  margin-top: -2px;
  font-size: 12px;
  font-weight: ${fonts.semiBold};
`;

const SubText = styled.p`
  font-size: 10px;
  font-weight: ${fonts.regular};
`;

const LicenseRowItem = styled.div`
  flex: 1 1 auto;
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 5px;
  margin: 0 15px;
  ${RightContainer} {
    margin-left: 25px;
  }
  ${LabelText} {
    font-size: 13px;
    font-weight: ${fonts.semiBold};
    text-transform: uppercase;
    color: ${colors.darkGrey};
  }
`;

const LicenseConfirmItem = styled(LicenseRowItem)`
  justify-content: center;
  padding: 13px 5px;
  margin: 0 15px;
  ${LabelText} {
    width: 165px;
  }
`;

const LicenseAmount = styled.div`
  width: 40px;
  font-size: 16px;
  color: ${colors.darkGrey};
`;

const MiddleContent = styled.div`
  margin: 0 18px;
  img {
    width: 10px;
    height: 10px;
    opacity: 0.6;
  }
`;

const ActionButton = styled.p`
  margin-top: 10px;
  color: ${colors.white};
  background: ${colors.paleGrey};
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  flex: 0 0 auto;
  font-weight: ${fonts.semiBold};
  cursor: pointer;
`;

const IntegrationCard = styled.div`
  flex: 1 1 auto;
  background: ${colors.lighterGrey};
  border: 1px solid ${inputColors.border};
  border-radius: 4px;
  padding: 10px;
  margin: 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    transform: scale(1.01);
    border: 1px solid ${colors.green};
    ${ActionButton} {
      background: ${colors.green};
      color: ${colors.white};
    }
  }
`;

const IntegrationHeading = styled.p`
  font-size: 14px;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
  margin-bottom: 8px;
  text-align: left;
  flex: 0 0 auto;
`;

const MembersList = styled.div`
  width: 100%;
  font-size: 12px;
  margin: 4px 0 4px 5px;
  color: ${colors.darkGrey};
  flex: 1 1 auto;
  img {
    width: 10px;
    margin-right: 8px;
  }
`;

const Member = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const InputLabel = styled.label`
  text-align: left;
  margin: 0 0 3px 3px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const VerticalHeader = styled.p`
  text-align: left;
  margin: 0 0 3px 3px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  input {
    flex: 1 1 auto;
    padding: 8px 10px;
    font-size: 12px;
  }
`;

const TextInputRow = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  .number-formatter {
    background: white;
    border: 1px solid ${inputColors.border};
    color: ${colors.darkGrey};
    padding: 9.5px 15px;
    border-radius: 6px;
    flex: 1 1 auto;
    &::placeholder {
      color: ${colors.paleGrey};
      opacity: 1;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin-left: -4px;
  label {
    font-size: 11px;
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
`;

const ButtonsRowContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  flex: 1 1 50%;
  button {
    width: 100%;
  }
`;

const TooltipIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-left: 5px;
  margin-bottom: -1px;
  opacity: 0.75;
`;

const TooltipIconError = styled(TooltipIcon)`
  opacity: 1;
`;

SimpleModal.propTypes = {
  hide: PropTypes.func,
  content: PropTypes.object,
  updateError: PropTypes.func,
  onEnter: PropTypes.func,
  error: PropTypes.string,
  disableClickOutside: PropTypes.bool,
  dismissFunction: PropTypes.func,
  currency: PropTypes.object,
};

export default SimpleModal;
