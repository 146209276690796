import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showingUserDropdown: false,
  showingNotificationDropdown: false,
  showingMarkerDropdown: false,
  showingHouseholdDropdown: false,
  showingSearchDropdown: false,
  showingAdvisorDropdown: false,
};

/*
  SETS ALL DROPDOWN DISPLAY VALUES TO FALSE UNLESS PASSED PARAMS
  - field <string> - what field name in initial state to change
  - show <boolean> - whether to show dropdown or not
*/
const closeAllOtherDropdowns = (field, show) => {
  const newState = {};
  for (const [key] of Object.entries(initialState)) {
    let newValue = false;
    if (field === key) {
      newValue = show;
    }
    newState[key] = newValue;
  }
  return newState;
};

export const dropdownsSlice = createSlice({
  name: 'dropdowns',
  initialState,
  reducers: {
    toggleShowUserDropdown: (state, action) => {
      return closeAllOtherDropdowns('showingUserDropdown', action.payload);
    },
    toggleShowNotificationDropdown: (state, action) => {
      return closeAllOtherDropdowns(
        'showingNotificationDropdown',
        action.payload
      );
    },
    toggleShowMarkerDropdown: (state, action) => {
      return closeAllOtherDropdowns('showingMarkerDropdown', action.payload);
    },
    toggleShowHouseholdDropdown: (state, action) => {
      return closeAllOtherDropdowns('showingHouseholdDropdown', action.payload);
    },
    toggleShowSearchDropdown: (state, action) => {
      return closeAllOtherDropdowns('showingSearchDropdown', action.payload);
    },
    toggleShowAdvisorDropdown: (state, action) => {
      return closeAllOtherDropdowns('showingAdvisorDropdown', action.payload);
    },
    closeAllDropdowns: () => {
      return closeAllOtherDropdowns();
    },
  },
});

export const {
  toggleShowUserDropdown,
  toggleShowNotificationDropdown,
  toggleShowMarkerDropdown,
  toggleShowHouseholdDropdown,
  toggleShowSearchDropdown,
  toggleShowAdvisorDropdown,
  closeAllDropdowns,
} = dropdownsSlice.actions;

export default dropdownsSlice.reducer;
