/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '..';
import { plusGreen, pencilFilled, trashRed, errorCircle } from '../../assets';
import { colors, messageColors, fonts } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import {
  takeProposalAction,
  takeProposalActionLegal,
} from '../../store/actions';
import { DateFormatUTC } from '../../utils';

const ProposalsTab = ({ proposalContent, hasError }) => {
  const dispatch = useDispatch();
  const { currentHousehold, currentProposal } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    currentProposal: state.households.currentProposal,
  }));
  const [proposal, setProposal] = useState('');
  // const [tableValues, setTableValues] = useState(false);
  const [showLoadingButton, setShowLoadingButton] = useState(false);

  useEffect(() => {
    if (proposalContent) {
      const proposalObject = generateProposalObject(proposalContent);
      setProposal(proposalObject);
      // if (proposalContent?.action === 'modify') {
      //   setTableValues(proposalContent?.diff_history_table);
      // } else {
      //   setTableValues(false);
      // }
    }
  }, [proposalContent]);

  useEffect(() => {
    if (hasError) {
      setShowLoadingButton(false);
    }
  }, [hasError]);

  const transformFieldName = (fieldName) => {
    return fieldName.replaceAll('_', ' ');
  };

  const generateFieldNames = (obj) => {
    if (obj?.diff_history_table?.length) {
      const fieldNames = obj?.diff_history_table.map((item) =>
        transformFieldName(item.field_name)
      );
      const formatter = new Intl.ListFormat('en', {
        style: 'long',
        type: 'conjunction',
      });
      return formatter.format(fieldNames);
    } else {
      return 'unknown';
    }
  };

  const generateProposalObject = (obj) => {
    switch (obj?.action) {
      case 'add':
        obj.label = `A new ${obj?.type} named <strong>${obj?.name}</strong> has been created as a draft.`;
        obj.icon = plusGreen;
        obj.color = colors?.green;
        obj.loadingText = `Creating`;
        obj.buttonText = `Create ${obj?.type}`;
        break;
      case 'delete':
        obj.label = `<strong>${obj?.name}</strong> has been deleted.`;
        obj.icon = trashRed;
        obj.color = messageColors.error;
        obj.loadingText = `Deleting`;
        obj.buttonText = `Delete ${obj?.type}`;
        break;
      case 'modify':
        obj.label = `Changes to <strong>${generateFieldNames(
          obj
        )}</strong> have been made to <strong>${obj?.name}</strong>.`;
        // obj.label = `Proposed changes have been made to <strong>${obj?.name}</strong>.`;
        obj.icon = pencilFilled;
        obj.color = colors.darkGrey;
        obj.loadingText = `Accepting`;
        obj.buttonText = `Commit Changes`;
        break;
      default:
        obj.label = 'An unknown action has taken place.';
        obj.icon = errorCircle;
        obj.color = colors.darkGrey;
        obj.loadingText = `Updating`;
        obj.buttonText = `Commit Changes`;
    }
    return obj;
  };

  const acceptRejectProposal = (value) => {
    // setShowLoadingButton(true);
    if (proposal?.type === 'legal') {
      dispatch(
        takeProposalActionLegal(
          currentHousehold?.uuid,
          currentProposal?.id,
          proposal?.id,
          value
        )
      );
    } else {
      dispatch(
        takeProposalAction(
          currentHousehold?.uuid,
          currentProposal?.id,
          proposal?.id,
          value
        )
      );
    }
  };

  // const generateLinkedDisplay = (obj, i) => {
  //   let name = obj;
  //   let hasRatio = false;
  //   if (obj?.id) {
  //     name = obj?.id;
  //     if (proposalContent?.membersList) {
  //       const matchingMember = proposalContent?.membersList.find(
  //         (mem) => mem.id === obj.id
  //       );
  //       if (matchingMember) {
  //         name = matchingMember?.name;
  //         hasRatio = true;
  //       }
  //     }
  //   } else {
  //     if (proposalContent?.assetList) {
  //       const matchingAsset = proposalContent?.assetList.find(
  //         (asset) => asset.id === obj
  //       );
  //       if (matchingAsset) {
  //         name = matchingAsset?.name;
  //       }
  //     }
  //   }
  //   return (
  //     <LinkedLabel key={i}>
  //       <span>{name}</span>
  //       {hasRatio && <LinkedRatio>{`${obj?.ratio}%`}</LinkedRatio>}
  //     </LinkedLabel>
  //   );
  // };

  // const convertDisplay = (value, fieldName) => {
  //   if (Array.isArray(value)) {
  //     const convertedList = value.map((mem, i) => {
  //       return generateLinkedDisplay(mem, i);
  //     });
  //     return convertedList;
  //   } else {
  //     if (value === null || value === undefined) {
  //       return 'null';
  //     } else if (fieldName === 'associated') {
  //       return generateLinkedDisplay(value, 0);
  //     } else {
  //       return value.toString();
  //     }
  //   }
  // };

  //Need to create a field dictionary to convert values
  //Types - text, number, currency, date, members, assets, associated, notes
  // const fields = [
  //   {
  //     value: 'amount',
  //     display: 'Amount',
  //     type: 'currency',
  //   },
  //   {
  //     value: 'name',
  //     display: 'Name',
  //     type: 'text',
  //   },
  // ];

  return (
    <ProposalContainer>
      <ProposalHeading>Drafts</ProposalHeading>
      <ProposalDetailsContainer>
        <LeftContent>
          <IconContainer color={proposal?.color}>
            <Icon src={proposal?.icon} alt={proposal?.action} />
          </IconContainer>
        </LeftContent>
        <RightContent>
          <ProposalLabel
            dangerouslySetInnerHTML={{
              __html: proposal?.label,
            }}
          />
          <ProposalDate>
            {DateFormatUTC(
              proposal?.last_modified_utc,
              'MMM D YYYY h:mma',
              true
            )}
          </ProposalDate>
        </RightContent>
      </ProposalDetailsContainer>
      {/* {tableValues && (
        <DiffTableContainer>
          <thead>
            <tr>
              <th>Field Name</th>
              <th>Previous Value</th>
              <th>Proposed Value</th>
            </tr>
          </thead>
          <tbody>
            {tableValues.map((row, index) => {
              return (
                <tr key={index}>
                  <FieldName>{transformFieldName(row?.field_name)}</FieldName>
                  <PreviousValue>
                    {convertDisplay(row?.previous_value, row?.field_name)}
                  </PreviousValue>
                  <td>
                    {convertDisplay(row?.proposed_value, row?.field_name)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </DiffTableContainer>
      )} */}
      <ButtonsContainer>
        <ButtonContainer>
          <Button
            text={'Reject'}
            onClick={() => acceptRejectProposal(false)}
            theme={ButtonThemes.cancel}
          />
          <Button
            text={proposal?.buttonText}
            theme={
              proposal?.action === 'delete'
                ? ButtonThemes.error
                : ButtonThemes.primary
            }
            onClick={() => acceptRejectProposal(true)}
            showLoading={showLoadingButton}
            loadingText={proposal?.loadingText}
          />
        </ButtonContainer>
      </ButtonsContainer>
    </ProposalContainer>
  );
};

// const LinkedLabel = styled.span`
//   font-size: 11px;
//   display: inline-block;
//   /* background: ${colors.lightGrey}; */
//   margin-right: 8px;
//   /* margin-bottom: 3px; */
//   /* padding: 3px 5px; */
//   /* border-radius: 2px; */
// `;

// const LinkedRatio = styled.span`
//   margin-left: 4px;
//   color: ${colors.paleGrey};
//   font-size: 10px;
// `;

// const DiffTableContainer = styled.table`
//   margin: 15px 5px;
//   font-size: 11px;
//   width: calc(100% - 10px);
//   thead {
//     font-weight: ${fonts.bold};
//     text-transform: uppercase;
//     font-size: 9px;
//     border-bottom: 1px solid ${colors.darkGrey};
//     th {
//       padding: 0 2px 2px 2px;
//     }
//   }
//   tbody {
//     tr {
//       border-bottom: 1px solid ${colors.hoverLighterGrey};
//       td {
//         padding: 4px 2px;
//       }
//     }
//   }
// `;

// const FieldName = styled.td`
//   text-transform: capitalize;
//   font-weight: ${fonts.bold};
// `;

// const PreviousValue = styled.td`
//   color: ${colors.paleGrey};
// `;

const ProposalContainer = styled.div`
  text-align: left;
`;

const ProposalHeading = styled.p`
  font-size: 16px;
  font-weight: ${fonts.semiBold};
  margin-bottom: 10px;
  text-align: left;
`;

const ProposalDetailsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const LeftContent = styled.div``;

const IconContainer = styled.div`
  width: 35px;
  height: 35px;
  border: 2px solid ${(props) => props.color};
  border-radius: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
`;

const RightContent = styled.div`
  margin-left: 15px;
`;

const ProposalLabel = styled.p`
  text-align: left;
  font-size: 13px;
`;

const ProposalDate = styled.p`
  text-align: left;
  color: ${colors.paleGrey};
  font-size: 11px;
`;

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    &:first-child {
      margin-right: 15px;
    }
    &:last-child {
      text-transform: capitalize;
      width: 140px;
    }
  }
`;

ProposalsTab.propTypes = {
  proposalContent: PropTypes.object,
  hasError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ProposalsTab;
