/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TargetMapPercentBar, LoadingAnimation } from '../../components';
import {
  pin,
  pinSolid,
  checkboxGreen,
  ellipsisH,
  gripLines,
  square,
  bullseyeSolid,
  bullseye,
} from '../../assets';
import { maxDevice, fonts, colors, inputColors } from '../../styles/variables';
import {
  UseDoubleClick,
  HtmlToString,
  DetermineTargetMapColor,
  UseOutsideClick,
  DetectEnterKeyPress,
  SendTrackingEvent,
  UseSingleAndDoubleClick,
} from '../../utils';
import {
  DropdownContainer,
  DropdownHeading,
  DropdownContent,
} from '../../styles/library';

const TargetMapSummaryItem = ({
  targetMap,
  index,
  currency,
  actions,
  updating,
  saveName,
  modifyTargetMap,
  canSelect,
  draggable,
  invocationId,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const householdId = params.id;
  const inputRef = useRef();
  const nameRef = useRef();
  const menuIconRef = useRef();
  const dropdownRef = useRef();
  const [targetMapName, setTargetMapName] = useState(targetMap?.name);
  const [editTargetMapName, setEditTargetMapName] = useState(false);
  const [isShowingMenu, setIsShowingMenu] = useState(false);

  UseOutsideClick(inputRef, () => {
    if (editTargetMapName) {
      setEditTargetMapName(false);
    }
  });

  UseOutsideClick(menuIconRef, () => {
    if (isShowingMenu) {
      setIsShowingMenu(false);
    }
  });

  const saveTargetMapName = () => {
    const newName = targetMapName.trim();
    const changedName = targetMap?.name !== newName;
    if (changedName) {
      setEditTargetMapName(false);
      return saveName(targetMap.id, newName);
    }
    setEditTargetMapName(false);
  };

  const updateTargetMapName = (e) => {
    return setTargetMapName(e.currentTarget.value);
  };

  UseDoubleClick({
    onDoubleClick: (e) => {
      if (editTargetMapName === targetMap.id) {
        setEditTargetMapName(false);
      } else {
        setEditTargetMapName(targetMap.id);
      }
    },
    ref: nameRef,
  });

  const onSingleClick = (e) => {
    e && e.stopPropagation();
    return navigate(`/household/${householdId}/targetmap/${targetMap.id}`);
  };

  const nameOnClick = UseSingleAndDoubleClick(onSingleClick);

  return (
    <Draggable
      draggableId={targetMap.id}
      index={index}
      isDragDisabled={!draggable}
    >
      {(provided) => (
        <TargetMapContainer
          $selected={targetMap.selected}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TargetMapHeaderContainer>
            <LabelContainer>
              <svg height="10" width="10">
                <circle
                  cx="5"
                  cy="5"
                  r="5"
                  fill={
                    targetMap.color ||
                    DetermineTargetMapColor(targetMap.template)
                  }
                />
              </svg>
              <LabelInput
                ref={inputRef}
                id="test"
                type="text"
                value={targetMapName}
                name="name"
                autoFocus={true}
                onKeyPress={(e) => DetectEnterKeyPress(e, saveTargetMapName)}
                onChange={(e) => updateTargetMapName(e)}
                onBlur={() => saveTargetMapName()}
                visible={editTargetMapName === targetMap.id}
              />
              <LabelType
                ref={nameRef}
                onClick={nameOnClick}
                visible={editTargetMapName !== targetMap.id}
                title={HtmlToString(targetMap.name)}
              >
                {HtmlToString(targetMap.name)}
              </LabelType>
              {updating && (
                <span style={{ marginLeft: '10px' }}>
                  <LoadingAnimation
                    dots={true}
                    smaller={true}
                    color={
                      targetMap.color ||
                      DetermineTargetMapColor(targetMap.template)
                    }
                  />
                </span>
              )}
            </LabelContainer>
            <GripLines
              src={gripLines}
              alt="grip"
              data-image={`grip-${index}`}
              $draggable={draggable}
              title={
                draggable ? 'Drag to reorder' : 'Cannot reorder while filtering'
              }
            />
            <ActionsContainer>
              <ActionItem onClick={() => modifyTargetMap(targetMap, 'pin')}>
                <ActionItemIcon
                  src={targetMap.pinned ? pinSolid : pin}
                  alt={targetMap.pinned ? 'pinned' : 'pin'}
                  data-image={`pin-${targetMap.id}`}
                  data-pinned={targetMap.pinned}
                  title={
                    targetMap.pinned ? 'Unpin Target-Map' : 'Pin Target-Map'
                  }
                  $margin="0 0 -1px 0"
                  $active={targetMap.pinned}
                />
              </ActionItem>
              <ActionItem onClick={() => modifyTargetMap(targetMap, 'active')}>
                <ActionItemIcon
                  src={targetMap.active ? bullseyeSolid : bullseye}
                  alt={targetMap.active ? 'active' : 'inactive'}
                  data-image={`target-${targetMap.id}`}
                  data-active={targetMap.active}
                  title={
                    targetMap.active ? 'Deselect as Target' : 'Select as Target'
                  }
                  $active={targetMap.active}
                />
              </ActionItem>
              {canSelect && (
                <ActionItem
                  onClick={() => modifyTargetMap(targetMap, 'select')}
                >
                  <ActionItemIcon
                    src={targetMap.selected ? checkboxGreen : square}
                    showLabel={true}
                    style={{
                      background: targetMap.selected ? colors.white : null,
                    }}
                    alt={targetMap.selected ? 'selected' : 'checkbox'}
                    data-image={`compare-${targetMap.id}`}
                    data-selected={targetMap.selected}
                    $width="14px"
                    $height="14px"
                  />
                </ActionItem>
              )}
              <MoreMenuIcon
                ref={menuIconRef}
                src={ellipsisH}
                alt="more"
                data-image={`more-${targetMap.id}`}
                onClick={() => {
                  SendTrackingEvent(
                    invocationId,
                    'click',
                    'targetmap_more_menu',
                    null,
                    {
                      targetmap: targetMap.id,
                      showingMenu: !isShowingMenu,
                    }
                  );
                  setIsShowingMenu(!isShowingMenu);
                }}
              />
              <DropdownContainer
                ref={dropdownRef}
                style={
                  isShowingMenu ? { display: 'block' } : { display: 'none' }
                }
              >
                <DropdownHeading textAlign="left">Actions</DropdownHeading>
                <DropdownContent>
                  {actions.map((action, index) => {
                    return (
                      <DropdownItem
                        key={action.id}
                        onClick={() => action.onClick(targetMap)}
                      >
                        <>
                          <DropdownItemImage
                            showLabel={true}
                            src={action.icon}
                            alt={action.id}
                            data-image={`${action.id}-${targetMap.id}`}
                          />
                          <DropdownItemText>{action.label}</DropdownItemText>
                        </>
                      </DropdownItem>
                    );
                  })}
                </DropdownContent>
              </DropdownContainer>
            </ActionsContainer>
          </TargetMapHeaderContainer>
          <TargetMapPercentBar
            isList={true}
            targetMap={targetMap}
            currency={currency}
          />
        </TargetMapContainer>
      )}
    </Draggable>
  );
};

const TargetMapContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 11px;
  }
  margin-bottom: 15px;
  background: white;
  border-radius: 4px;
  font-size: 14px;
  /* border: 1px solid ${colors.hoverLightGrey}; */
  border: ${(props) =>
    `1px solid ${props.$selected ? 'rgb(200, 200, 200)' : colors.hoverLightGrey}`};
  box-shadow: ${(props) =>
    props.$selected ? `rgb(125 125 125 / 60%) 0px 0px 5px 0px` : null};
`;

const TargetMapHeaderContainer = styled.div`
  @media ${maxDevice.tablet} {
    padding: 8px 10px;
  }
  padding: 8px 10px;
  position: relative;
  background: ${colors.lightGrey};
  display: flex;
  border-radius: 4px 4px 0 0;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  /* cursor: grab; */
  flex-wrap: wrap;
`;

const DropdownItem = styled.div`
  margin: 8px 0;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    opacity: 0.75;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const MoreMenuIcon = styled.img`
  width: 12px;
  height: 12px;
  margin: 0 3px 0 15px;
  cursor: pointer;
`;

const LabelContainer = styled.div`
  flex: 1 1 200px;
  color: ${colors.darkGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 8px;
    flex: 0 0 auto;
  }
`;

const LabelType = styled.span`
  @media ${maxDevice.tablet} {
    max-width: 300px;
  }
  @media ${maxDevice.mobileL} {
    max-width: 260px;
  }
  @media ${maxDevice.mobileM} {
    max-width: 240px;
  }
  font-weight: ${fonts.semiBold};
  flex: 0 0 auto;
  cursor: pointer;
  padding: ${(props) => (props.visible ? '2px 0' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  width: ${(props) => (props.visible ? 'auto' : 0)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 600px;
`;

const LabelInput = styled.input`
  border: ${inputColors.border};
  border-radius: 3px;
  margin: -2px 0;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  flex: ${(props) => (props.visible ? '1 1 250px' : '0 0 auto')};
  width: ${(props) => (props.visible ? 'auto' : 0)};
  padding: ${(props) => (props.visible ? '4px 8px' : '0')};
`;

const GripLines = styled.img`
  @media ${maxDevice.tablet} {
    display: none;
  }
  opacity: 0.3;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  margin: 0 10px;
  cursor: ${(props) => (props.$draggable ? 'grab' : 'not-allowed')};
`;

const ActionsContainer = styled.div`
  @media ${maxDevice.sideNav} {
    justify-content: flex-start;
    flex: 1 1 auto;
    width: 100%;
    margin-top: 5px;
    margin-left: -10px;
    flex-wrap: wrap;
  }
  flex: 1 1 200px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

const ActionItem = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
  font-size: 11px;
  margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : '12px')};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    opacity: 0.8;
  }
`;

const ActionItemIcon = styled.img`
  @media ${maxDevice.mobileL} {
    width: 10px;
    height: 10px;
  }
  width: ${(props) => (props.$width ? props.$width : '15px')};
  height: ${(props) => (props.$height ? props.$height : '15px')};
  margin: ${(props) => (props.$margin ? props.$margin : null)};
  transform: ${(props) => (props.$active ? `scale(1.1)` : null)};
`;

const DropdownItemImage = styled.img`
  width: 16px;
  height: 16px;
  max-height: 14px;
  margin-right: 7px;
`;

const DropdownItemText = styled.p``;

TargetMapSummaryItem.propTypes = {
  targetMap: PropTypes.object,
  index: PropTypes.number,
  currency: PropTypes.object,
  actions: PropTypes.array,
  showLabel: PropTypes.bool,
  updating: PropTypes.bool,
  saveName: PropTypes.func,
  modifyTargetMap: PropTypes.func,
  canSelect: PropTypes.bool,
  draggable: PropTypes.bool,
};

export default TargetMapSummaryItem;
