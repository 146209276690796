// functions that should exist in javascript but dont

// Conditionally adding to an array
// use like so:
// const arr = [
// 	...insertIf(cond, 'a'),
// 	'b',
// ];
export const insertIf = (condition, ...elements) => {
    return condition ? elements : [];
}
