/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import QRCodeStyling from 'qr-code-styling';
import { GenerateInput } from './';
import { checkWhite, download as downloadIcon, plusLogo } from '../assets';
import { InputLabel } from '../styles/library/inputStyles';
import { colors, fonts } from '../styles/variables';
import PropTypes from 'prop-types';

const customQRCode = new QRCodeStyling({
  width: 400,
  height: 400,
  image: plusLogo,
  type: 'svg',
  margin: 10,
  dotsOptions: {
    color: colors.darkGrey,
    type: 'square',
  },
  cornersSquareOptions: {
    color: colors.darkGrey,
    type: 'square',
  },
  cornersDotOptionsHelper: {
    color: colors.darkGrey,
    type: 'square',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 10,
  },
});

const QRCodeGenerator = ({
  link,
  download,
  setDownload,
  showCustom,
  showOnlyQRCode = false,
  size,
}) => {
  const ref = useRef();
  const [url, setUrl] = useState('');
  const [qrCode, setQRCode] = useState({
    size: { label: 'Large', value: 400 },
    color: colors.darkGrey,
    includeLogo: true,
  });
  const sizeOptions = [
    { label: 'Small', value: 250 },
    { label: 'Medium', value: 300 },
    { label: 'Large', value: 400 },
  ];
  const colorOptions = [
    colors.darkGrey,
    colors.lightBlue,
    colors.blue,
    colors.green,
    colors.pink,
    colors.orange,
  ];

  useEffect(() => {
    if (ref?.current) {
      customQRCode.append(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (link !== '') {
      setUrl(`${link}?qr=1`);
    }
  }, [link]);

  useEffect(() => {
    if (url) {
      customQRCode.update({
        data: url,
      });
    }
  }, [url]);

  useEffect(() => {
    if (download) {
      setDownload(false);
      downloadQRCode('png');
    }
  }, [download]);

  useEffect(() => {
    customQRCode.update({
      width: size ? size : qrCode.size.value,
      height: size ? size : qrCode.size.value,
      data: url,
      image: qrCode.includeLogo ? plusLogo : null,
      cornersSquareOptions: {
        color: qrCode.color,
        type: 'square',
      },
      cornersDotOptionsHelper: {
        color: qrCode.color,
        type: 'square',
      },
    });
  }, [qrCode]);

  const downloadQRCode = (type) => {
    return customQRCode.download({
      name: 'Discovery QR Code',
      extension: type,
    });
  };

  const updateQRCode = (e, isSelect = false, fieldName = null) => {
    if (isSelect) {
      setQRCode({
        ...qrCode,
        [fieldName]: e,
      });
    } else {
      setQRCode({
        ...qrCode,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const qrCodeInputs = [
    {
      type: 'text',
      label: 'URL',
      maxLength: '100',
      name: 'text',
      width: '100%',
      required: false,
      placeholder: 'https://asset-map.com',
      value: url,
      disabled: !showCustom,
      isVisible: showCustom,
      onChange: (e) => setUrl(e.currentTarget.value),
    },
    {
      type: 'select',
      label: 'Size',
      name: 'size',
      required: false,
      placeholder: 'Size',
      value: qrCode.size,
      options: sizeOptions,
      width: '100%',
      isVisible: showCustom,
      onChange: (e) => updateQRCode(e, true, 'size'),
    },
    {
      type: 'checkbox',
      label: 'Include Asset-Map Logo',
      name: 'includeLogo',
      required: false,
      value: qrCode.includeLogo,
      isVisible: true,
      onChange: () =>
        setQRCode({
          ...qrCode,
          includeLogo: !qrCode.includeLogo,
        }),
    },
  ];

  return (
    <>
      {showOnlyQRCode ? (
        <QRCodeValue ref={ref} />
      ) : (
        <>
          {qrCodeInputs.map((input, index) => {
            return GenerateInput(input, index);
          })}
          {showCustom && (
            <ColorOptionsContainer>
              <InputLabel>Color</InputLabel>
              <ColorsList>
                {colorOptions.map((color, index) => {
                  return (
                    <ColorSquareContainer key={index}>
                      <ColorSquare
                        color={color}
                        onClick={() => setQRCode({ ...qrCode, color: color })}
                      />
                      {qrCode.color === color && (
                        <CheckIcon src={checkWhite} alt="check" />
                      )}
                    </ColorSquareContainer>
                  );
                })}
              </ColorsList>
            </ColorOptionsContainer>
          )}
          <QRCodeContainer>
            <InputLabel>Preview</InputLabel>
            <QRValueContainer>
              <QRCodeValue ref={ref} size={qrCode?.size?.value} />
            </QRValueContainer>
          </QRCodeContainer>
          {showCustom && (
            <DownloadContainer>
              <DownloadButton onClick={() => downloadQRCode('svg')}>
                <img src={downloadIcon} alt="download" data-image="download" />
                Download as SVG
              </DownloadButton>
            </DownloadContainer>
          )}
        </>
      )}
    </>
  );
};

const DownloadContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const DownloadButton = styled.button`
  border: 1px solid ${colors.lightGrey};
  background: ${colors.lightGrey};
  color: ${colors.darkGrey};
  padding: 5px 18px 5px 15px;
  font-weight: ${fonts.semiBold};
  font-size: 12px;
  border-radius: 25px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }
  &:hover {
    border: thin solid ${colors.hoverLightGrey};
    opacity: 0.9;
  }
`;

const QRCodeContainer = styled.div`
  width: 100%;
`;

const QRValueContainer = styled.div`
  margin-top: 5px;
  background: ${colors.lightGrey};
  width: 100%;
  padding: 15px 0 10px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const QRCodeValue = styled.div`
  transform: scale(0.9);
  /* display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start; */
  /* margin: ${(props) =>
    props.size === 150
      ? '0'
      : props.size === 250
      ? '0 0 0 -5px'
      : '0 0 0 -10px'}; */
`;

const ColorOptionsContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const ColorsList = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ColorSquareContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ColorSquare = styled.div`
  width: 60px;
  height: 60px;
  background: ${(props) => props.color};
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    opacity: 0.9;
  }
`;

const CheckIcon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
`;

QRCodeGenerator.propTypes = {
  link: PropTypes.string,
  download: PropTypes.bool,
  setDownload: PropTypes.func,
  showCustom: PropTypes.bool,
  showOnlyQRCode: PropTypes.bool,
  size: PropTypes.number,
};

export default QRCodeGenerator;
