/* eslint-disable array-callback-return */
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import {
  ButtonsRowContainer,
  ModalIcon,
} from '../../styles/library/modalStyles';
import { Button } from '..';
import {
  trashDark,
  copy,
  checkboxGreen,
  square,
  check,
  undo,
  sync,
} from '../../assets';
import { ButtonThemes } from '../../styles/themes';
import { messageColors } from '../../styles/variables';

const ModalButtons = ({
  isNew,
  showCopy,
  justCopied,
  showUndo = false,
  deleteFunction,
  copyFunction,
  isHidden,
  updateHidden,
  hide,
  saveFunction,
  showLoading,
  loadingText,
  showSync = false,
  syncFunction,
  isSyncing = false,
}) => {
  return (
    <ButtonsRowContainer>
      {!isNew && (
        <>
          <ModalIcon
            src={showUndo ? undo : trashDark}
            alt={showUndo ? 'undo' : 'delete'}
            title={showUndo ? 'Undo' : 'Delete'}
            data-image={showUndo ? 'undo' : 'delete'}
            onClick={() => deleteFunction()}
          />
          {showCopy && (
            <ModalIcon
              src={copy}
              alt="copy"
              data-image="copy"
              title="Clone Instrument"
              onClick={() => copyFunction()}
            />
          )}
          {showSync ? (
            isSyncing ? (
              <SyncIconAnimation src={sync} alt="sync" data-image="sync" />
            ) : (
              <SyncIcon
                src={sync}
                alt="sync"
                data-image="sync"
                title="Sync Household"
                onClick={() => syncFunction()}
              />
            )
          ) : null}
        </>
      )}
      {updateHidden && (
        <OptionValue onClick={() => updateHidden(!isHidden)}>
          <CheckboxItem
            src={isHidden ? checkboxGreen : square}
            data-image={`checkbox-${isHidden ? 'checked' : 'unchecked'}`}
            alt="box"
            style={isHidden ? { opacity: 1 } : { opacity: 0.4 }}
          />
          <OptionLabel>Hide</OptionLabel>
        </OptionValue>
      )}
      <CopiedContainer
        style={{
          display: !isNew && justCopied ? 'flex' : 'none',
          opacity: justCopied ? '1' : '0',
        }}
        out={!justCopied}
      >
        <img src={check} alt="check" data-image="check" />
        <CopiedLabel>Cloned</CopiedLabel>
      </CopiedContainer>
      <ButtonContainer>
        <Button
          text={'Cancel'}
          onClick={() => hide()}
          theme={ButtonThemes.cancel}
        />
        <Button
          text={isNew ? 'Create' : 'Update'}
          onClick={() => saveFunction()}
          showLoading={showLoading}
          loadingText={
            loadingText ? loadingText : isNew ? 'Creating' : 'Saving'
          }
        />
      </ButtonContainer>
    </ButtonsRowContainer>
  );
};

ModalButtons.propTypes = {
  isNew: PropTypes.bool,
  showCopy: PropTypes.bool,
  deleteFunction: PropTypes.func,
  showUndo: PropTypes.bool,
  copyFunction: PropTypes.func,
  isHidden: PropTypes.bool,
  justCopied: PropTypes.bool,
  updateHidden: PropTypes.func,
  hide: PropTypes.func,
  saveFunction: PropTypes.func,
  showLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  showSync: PropTypes.bool,
  syncFunction: PropTypes.func,
  isSyncing: PropTypes.bool,
};

const ButtonContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    &:first-child {
      margin-right: 15px;
    }
    &:last-child {
      width: 120px;
    }
  }
`;

const OptionValue = styled.div`
  margin: 0 8px 0 0;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const OptionLabel = styled.div`
  flex: 1 1 auto;
  margin-left: 7px;
  font-size: 12px;
`;

const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;
const fadeOut = keyframes`
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
`;

const CopiedContainer = styled.div`
  margin-left: 3px;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.out ? 'hidden' : 'visible')};
  transition: visibility 1s linear;
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 100ms linear;
  /* animation: ${fadeIn} ease-in 50ms, ${fadeOut} ease-out 50ms; */
  img {
    width: 10px;
    height: 10px;
  }
`;

const CopiedLabel = styled.p`
  margin-left: 5px;
  font-size: 12px;
  color: ${messageColors.success};
`;

const CheckboxItem = styled.img`
  width: 16px;
  height: 16px;
  max-height: 14px;
  flex: 0 0 auto;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const SyncIcon = styled.img`
  width: 14px;
  height: 14px;
  flex: 0 0 auto;
  cursor: pointer;
  margin-right: 15px;
  &:hover {
    opacity: 0.8;
    transform: scale(1.01);
  }
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const SyncIconAnimation = styled(SyncIcon)`
  animation: ${rotation} 1s infinite ease-in-out;
`;

export default ModalButtons;
