import styled from 'styled-components';
import { colors } from '../styles/variables';

const AssetMapApplet = (props) => {
  return <AssetMapAppletContainer>{props?.text}</AssetMapAppletContainer>;
};

const AssetMapAppletContainer = styled.div`
  background: ${colors.lightGrey};
  height: 100%;
  padding: 20px;
  font-weight: bold;
  font-size: 14px;
`;

export default AssetMapApplet;
