import { apiAction } from './';

export const NotificationTypes = {
  GET_UNREAD_COUNT: 'GET_UNREAD_COUNT',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  TOGGLE_NOTIFICATION_READ: 'TOGGLE_NOTIFICATION_READ',
  MARK_ALL_NOTIFICATIONS_READ: 'MARK_ALL_NOTIFICATIONS_READ',
  // MARK_ALL_UNREAD_NOTIFICATIONS: 'MARK_ALL_UNREAD_NOTIFICATIONS',
};

//ACTION TO SET NOTIFICATION COUNT IN STORE <integer>
export const setUnreadCount = (count) => ({
  type: NotificationTypes.GET_UNREAD_COUNT,
  payload: count.count,
});

//API CALL TO GET NUMBER OF UNREAD NOTIFICATIONS
export const getUnreadCount = () => {
  return apiAction({
    path: `/member/notification/count`,
    method: 'GET',
    onSuccess: setUnreadCount,
    label: NotificationTypes.GET_UNREAD_COUNT,
  });
};

//ACTION TO SET NOTIFICATIONs LIST IN STORE <array>
export const setNotifications = (notifications) => ({
  type: NotificationTypes.GET_NOTIFICATIONS,
  payload: notifications,
});

//API CALL TO GET LIST OF NOTIFICATIONS - OFFSET PARAM FOR PAGINATION
export const getNotifications = (offset = 0) => {
  return apiAction({
    path: `/member/notification?limit=25&offset=${offset}`,
    method: 'GET',
    onSuccess: setNotifications,
    label: NotificationTypes.GET_NOTIFICATIONS,
  });
};

//API CALL TO TOGGLE READ STATE OF NOTIFICATION
export const toggleNotificationRead = (notification) => {
  let notificationId = notification.id;
  let isUnread = notification.is_unread;
  let flag;
  isUnread ? (flag = 'read') : (flag = 'unread');
  return apiAction({
    path: `/member/notification/mark/${notificationId}/${flag}`,
    method: 'PUT',
    onSuccess: getNotifications,
    label: NotificationTypes.TOGGLE_NOTIFICATION_READ,
  });
};

//API CALL TO SET ALL NOTIFICATIONS AS READ
export const markAllNotificationsRead = () => {
  return apiAction({
    path: `/member/notification/mark/all/read`,
    method: 'PUT',
    onSuccess: getNotifications,
    label: NotificationTypes.MARK_ALL_NOTIFICATIONS_READ,
  });
};

//API CALL TO SET ALL NOTIFICATIONS AS UNREAD - NOT USED
// export const notificationsMarkAllUnread = () => {
//   return apiAction({
//     path: `/member/notification/mark/all/unread`,
//     method: 'PUT',
//     onSuccess: getNotifications,
//     label: NotificationTypes.MARK_ALL_UNREAD_NOTIFICATIONS,
//   });
// };
