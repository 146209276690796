/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SimpleModal, Toggle } from '../../components';
import { boxShadows, colors, maxDevice } from '../../styles/variables';
import { CapitalizeFirstLetter, integrationOptions } from '../../utils';
import {
  HeaderSubContent,
  LoadingPlaceholder,
  SecondaryHeading,
} from '../../styles/library/fontStyles';
import { updateManagerIntegration } from '../../store/actions';
import { ButtonThemes } from '../../styles/themes';

const ManagerIntegrationsContainer = ({ customerId }) => {
  const dispatch = useDispatch();
  const {
    managerIntegrations,
    updatedManagerIntegration,
    updateManagerIntegrationError,
  } = useSelector((state) => ({
    managerIntegrations: state.user.managerIntegrations,
    updatedManagerIntegration: state.user.updatedManagerIntegration,
    updateManagerIntegrationError: state.user.updateManagerIntegrationError,
  }));
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [integrationsList, setIntegrationsList] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [updatingIntegration, setUpdatingIntegration] = useState(false);
  const [updateIntegrationError, setUpdateIntegrationError] = useState('');

  useEffect(() => {
    if (managerIntegrations) {
      const integrationsList = managerIntegrations?.integration_disabled_map;
      let updated = [];
      if (integrationsList) {
        updated = Object.entries(integrationsList).map((e) => {
          const integration = e[0];
          const value = e[1];
          let details = integrationOptions.find(
            (int) => int.id === integration
          );
          if (details === undefined) {
            details = {
              name: `${integration} Missing`,
              id: 'missing',
              image: null,
              disabled: true,
            };
          }
          return {
            key: integration,
            ...details,
            disabled: value,
          };
        });
      }
      setIntegrationsList(updated);
      setLoadingIntegrations(false);
    }
  }, [managerIntegrations]);

  useEffect(() => {
    if (updatedManagerIntegration) {
      setShowConfirmModal(false);
      setUpdatingIntegration(false);
    }
  }, [updatedManagerIntegration]);

  useEffect(() => {
    if (updateManagerIntegrationError) {
      const status = selectedIntegration?.disabled ? 'Enabling' : 'Disabling';
      let errorMessage = `Error ${status} ${selectedIntegration.name}`;
      setUpdateIntegrationError(errorMessage);

      setUpdatingIntegration(false);
    }
  }, [updateManagerIntegrationError]);

  useEffect(() => {
    if (showConfirmModal === false && !loadingIntegrations) {
      setUpdatingIntegration(false);
      setUpdateIntegrationError('');
    }
  }, [showConfirmModal, loadingIntegrations]);

  const toggleIntegrationOnClick = (integration) => {
    setSelectedIntegration(integration);
    setShowConfirmModal(true);
  };

  const updateIntegrationValue = () => {
    setUpdatingIntegration(true);
    dispatch(updateManagerIntegration(customerId, selectedIntegration));
  };

  const generateConfirmContent = () => {
    if (selectedIntegration?.id) {
      const { name, disabled } = selectedIntegration;
      let actionText = disabled ? 'enable' : 'disable';
      const text = `Are you sure you want to ${actionText}<br/><strong>${name}</strong>?`;
      return {
        heading: `${actionText} ${name}?`,
        text: text,
        infoBox: {
          message: `This will ${actionText} ${name} for <strong>all</strong> members${
            !disabled ? ', even if they currently have it active.' : '.'
          }`,
          fontSize: '11px',
          margin: '8px 0 15px 0',
        },
        marginTop: '12vh',
        minWidth: '360px',
        buttons: [
          {
            text: 'Close',
            function: () => setShowConfirmModal(false),
            theme: ButtonThemes.cancel,
          },
          {
            text: CapitalizeFirstLetter(actionText),
            function: () => updateIntegrationValue(),
            showLoading: updatingIntegration,
            loadingText: disabled ? 'Enabling' : 'Disabling',
            theme: ButtonThemes.primary,
          },
        ],
      };
    }
  };

  return (
    <>
      {showConfirmModal && (
        <SimpleModal
          hide={() => setShowConfirmModal(false)}
          content={generateConfirmContent()}
          error={updateIntegrationError}
        />
      )}
      <IntegrationsSettingsContainer>
        <SecondaryHeading>Integrations</SecondaryHeading>
        <HeaderSubContent $margin="10px 0">
          Select which integrations should be available to all users.
        </HeaderSubContent>
        {/* <GlobalToggleContainer>
          <Toggle
            props={{
              text: 'All Integrations',
              name: 'all',
              value: allActive,
              theme: { fontColor: colors.darkGrey, fontSize: '14px' },
            }}
            onClick={toggleGlobal}
          />
        </GlobalToggleContainer> */}
        <IntegrationsList>
          {loadingIntegrations ? (
            <>
              {[1, 2, 3, 4, 5].map((item) => {
                return (
                  <IntegrationCard key={item}>
                    <LoadingPlaceholder height="45px" width="180px" />
                    <LoadingPlaceholder margin="13px 5px 0 5px" height="25px" />
                  </IntegrationCard>
                );
              })}
            </>
          ) : (
            <>
              {integrationsList.map((integration, index) => {
                return (
                  <IntegrationCard
                    key={index}
                    $disabled={integration.disabled}
                    data-integration={integration.id}
                    data-enabled={!integration.disabled}
                  >
                    <CardImageContainer>
                      <CardImage
                        src={integration.image}
                        alt={integration.name}
                        $disabled={integration.disabled}
                        data-image={`integration-${integration.id}`}
                        style={{
                          height: integration?.height
                            ? integration?.height
                            : null,
                          padding: integration?.padding
                            ? integration?.padding
                            : null,
                        }}
                      />
                    </CardImageContainer>
                    <ToggleContainer>
                      <Toggle
                        props={{
                          text: integration.name,
                          name: integration.id,
                          value: !integration.disabled,
                          theme: {
                            bg: integration?.color,
                            smaller: true,
                          },
                        }}
                        onClick={() => toggleIntegrationOnClick(integration)}
                      />
                    </ToggleContainer>
                  </IntegrationCard>
                );
              })}
            </>
          )}
        </IntegrationsList>
      </IntegrationsSettingsContainer>
    </>
  );
};

const IntegrationsSettingsContainer = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 0px 20px 100px 20px;
  }
  padding: 0px 40px 100px 40px;
`;

// const GlobalToggleContainer = styled.div`
//   margin: 15px 0 10px 0;
// `;

const IntegrationsList = styled.div`
  @media ${maxDevice.tablet} {
    justify-content: center;
  }
  @media ${maxDevice.mobileL} {
    margin: 10px 15px 15px 20px;
  }
  @media ${maxDevice.mobileS} {
    margin: 10px 15px 15px 15px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 0px -8px 40px -8px;
`;

const IntegrationCard = styled.div`
  padding: 15px;
  margin: 8px;
  flex-direction: column;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.lightGrey};
  border-radius: 5px;
  width: 180px;
  height: 70px;
  box-shadow: ${(props) => (props.$disabled ? null : boxShadows.boxShadowSoft)};
  background: ${(props) =>
    props.$disabled ? colors.lighterGrey : colors.white};
`;

const CardImageContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const CardImage = styled.img`
  height: ${(props) => (props.$height ? props.$height : '100%')};
  width: 100%;
  max-height: 40px;
  max-width: 160px;
  filter: ${(props) => (props.$disabled ? 'grayscale(100%)' : null)};
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
`;

const ToggleContainer = styled.div`
  margin: 10px 5px 5px 5px;
`;

ManagerIntegrationsContainer.propTypes = {
  customerId: PropTypes.string,
};

export default ManagerIntegrationsContainer;
