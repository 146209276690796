/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from 'react-dom';
import { useRef, useState, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  ModalButtons,
  ModalTabs,
  DetectModalKeys,
  GenerateInput,
  Error,
  LoadingAnimation,
  ProposalsTab,
} from '..';
import {
  timesWhite,
  chevronRight,
  greyCirclePlus,
  arrowBack,
  circleAdd,
  circleDelete,
  circleEdit,
  errorCircle,
} from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ModalLoadingOverlayContainer,
  LoadingOverlayContainer,
  LoadingOverlay,
} from '../../styles/library/modalStyles';
import {
  LegalApplicableMemberOption,
  LegalEmpoweredMemberOption,
  UseHandleInstrumentError,
  CheckHasError,
  GenerateError,
  RemoveError,
  DateFormat,
  BeneficiaryOption,
  UseGenerateMembersList,
  FilterMembersByContext,
  TransformApplicables,
  TransformEmpowered,
  TransformLegalBeneficiaries,
  GenerateAvatarMemberIconOld,
} from '../../utils';
import {
  createLegal,
  clearLegal,
  updateHouseholdStoreValue,
  updateLegal,
  getLegal,
  createProposalLegal,
  readProposalLegal,
  updateProposalLegal,
  toggleShowDeleteModal,
} from '../../store/actions';
import styled from 'styled-components';
import { colors, colorsRGB, assetMapColors } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';

const legalReducer = (legal, action) => {
  legal[action.type] = action.payload;
  return legal;
};

const LegalModal = ({
  hide,
  householdId,
  isNew,
  isList,
  proposalId,
  showProposalMode,
  refreshListView,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    legalCategories,
    currentHousehold,
    legalError,
    preferences,
    legal,
    clearedLegal,
    membersLegals,
    currentProposal,
    proposalLegal,
    mode,
    modalPage,
  } = useSelector((state) => ({
    legalCategories: state.configs.legalCategories,
    currentHousehold: state.households.currentHousehold,
    legalError: state.households.legalError,
    preferences: state.user.user.preferences,
    legal: state.households.legal,
    clearedLegal: state.households.clearedLegal,
    membersLegals: state.households.membersLegals,
    currentProposal: state.households.currentProposal,
    proposalLegal: state.households.proposalLegal,
    mode: state.households.modalState,
    modalPage: state.households.modalPage,
  }));

  const initLegalState = {
    category: '',
    applicable_members: [],
    reference_name: '',
    location: '',
    empowered_members: [],
    beneficiary_members: [],
    date_created: '',
    date_collected: '',
    notes: '',
  };
  const [legalState, dispatchLegal] = useReducer(legalReducer, initLegalState);

  const [typeOptions, setTypeOptions] = useState();
  const [membersList, setMembersList] = useState();
  const [hasMembersList, setHasMembersList] = useState(false);
  const [membersOptions, setMembersOptions] = useState();
  const [beneficiariesOptions, setBeneficiariesOptions] = useState();
  const [legalType, setLegalType] = useState();
  const [hasApplicables, setHasApplicables] = useState(false);
  const [hasEmpowered, setHasEmpowered] = useState(false);
  const [hasBeneficiaries, setHasBeneficiaries] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();
  const [hasLegal, setHasLegal] = useState(false);
  const [categoryMap, setCategoryMap] = useState();
  const [modalHeader, setModalHeader] = useState('');
  const [hasLegals, setHasLegals] = useState(false);
  const [legalsList, setLegalsList] = useState([]);
  const [hasCategories, setHasCategories] = useState(false);
  const [modalState, setModalState] = useState();
  const [showBackButton, setShowBackButton] = useState();
  const [hasTabs, setHasTabs] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [modalLocation, setModalLocation] = useState();
  const [selectedLegal, setSelectedLegal] = useState();
  const [currentLegal, setCurrentLegal] = useState();
  const [greyTheme, setGreyTheme] = useState(false);
  const [modalTabOptions, setModalTabOptions] = useState();
  const [legalIsProposal, setLegalIsProposal] = useState(false);
  const [proposalContent, setProposalContent] = useState();

  useEffect(() => {
    return () => {
      dispatch(clearLegal());
    };
  }, []);

  useEffect(() => {
    if (clearedLegal) {
      setTypeOptions();
      setMembersList();
      setMembersOptions();
      setHasMembersList(false);
      setLegalType();
      setHasApplicables(false);
      setHasEmpowered(false);
      setBeneficiariesOptions();
      setHasBeneficiaries(false);
      setLoadedData(false);
      setModalError('');
      setModalErrors();
      setErrorTitle();
      setHasLegal(false);
      setCategoryMap();
      setHasCategories(false);
      dispatch(updateHouseholdStoreValue('clearedLegal', false));
      setHasLegals(false);
      setLegalsList([]);
      setShowBackButton(false);
      setHasTabs(false);
      setActiveTab();
      setGreyTheme(false);
      setLegalIsProposal(false);
    }
  }, [clearedLegal]);

  // Modal State Management
  useEffect(() => {
    if (isList) {
      setModalState('list');
    } else {
      if (isNew) {
        setModalState('add');
        setLoadedData(true);
      } else {
        setModalState('edit');
      }
    }
  }, [isNew, isList]);

  useEffect(() => {
    if (modalState === 'list') {
      setModalHeader('Legal Instruments');
      setHasTabs(false);
      setShowBackButton(false);
      setLegalIsProposal(false);
      setCurrentLegal();
      setProposalContent();
    }
    if (modalState === 'add') {
      setModalHeader('Add Legal');
    }
    if (modalState === 'edit') {
      setModalHeader('Edit Legal');
    }
  }, [modalState]);

  useEffect(() => {
    if (modalPage) {
      if (modalPage?.modal === 'legal') {
        setButtonLoading(false);
        setModalState(modalPage?.page);
        if (modalPage?.page === 'list') {
          handleClickBack();
        }
      }
    }
  }, [modalPage]);

  // Tab management
  useEffect(() => {
    if (location && modalState) {
      let locationArr = location.pathname.split('/');
      setModalLocation(locationArr.pop());
      if (modalLocation === 'assetmap' && modalState !== 'list') {
        setModalTabOptions([
          { label: 'Instrument', value: 'instrument' },
          { label: 'Notes', value: 'notes' },
          ...(showProposalMode && modalState === 'edit' && legalIsProposal
            ? [{ label: 'Drafts', value: 'proposals' }]
            : []),
        ]);
        setActiveTab('instrument');
      }
    }
  }, [location, modalState, showProposalMode, legalIsProposal]);

  useEffect(() => {
    if (modalTabOptions) {
      setHasTabs(true);
    }
  }, [modalTabOptions]);

  // when to show back to list view button
  useEffect(() => {
    if (activeTab) {
      setShowBackButton(activeTab === 'instrument');
    }
  }, [activeTab]);

  // when to use grey theme
  useEffect(() => {
    if (modalState && typeof showBackButton === 'boolean') {
      if (!showBackButton) {
        setGreyTheme(false);
      }
      if (showBackButton && modalState === 'edit') {
        setGreyTheme(true);
      }
    }
  }, [modalState, showBackButton]);

  // Data Management
  useEffect(() => {
    if (legal) {
      setHasLegal(true);
      setCurrentLegal(legal);
      setLegalIsProposal(false);
    }
  }, [legal]);

  useEffect(() => {
    if (proposalLegal) {
      setHasLegal(true);
      setLegalIsProposal(true);
      setCurrentLegal(proposalLegal);
      setProposalContent({
        ...proposalLegal?.proposal_action_data,
        name: proposalLegal?.reference_name,
        type: 'legal',
        membersList: membersList,
      });
    }
  }, [proposalLegal]);

  useEffect(() => {
    if (membersLegals) {
      setHasLegals(true);
    }
  }, [membersLegals]);

  useEffect(() => {
    if (legalCategories) {
      const optionsArray = Object.entries(legalCategories);
      const sortedOptions = optionsArray.sort(
        (a, b) => a[1].priority - b[1].priority
      );
      const categoryMapper = {};
      const sortedData = sortedOptions.map((option) => {
        categoryMapper[option[0]] = {
          value: option[1].key,
          label: option[1].name,
          index: option[1].priority - 1,
        };
        return categoryMapper[option[0]];
      });
      setCategoryMap(categoryMapper);
      setLegalType(sortedData[0]);
      dispatchLegal({ type: 'category', payload: sortedData[0].value });
      setTypeOptions(sortedData);
      setHasCategories(true);
    }
  }, [legalCategories]);

  useEffect(() => {
    if (hasMembersList) {
      const beneficiariesOptions = FilterMembersByContext(
        membersList,
        'instrument_beneficiary'
      );
      setBeneficiariesOptions(beneficiariesOptions);
      const membersOptions = FilterMembersByContext(
        membersList,
        'instrument_owner'
      );
      setMembersOptions(membersOptions);
    }
  }, [hasMembersList]);

  UseGenerateMembersList(
    currentHousehold,
    setMembersList,
    true,
    setHasMembersList
  );

  UseHandleInstrumentError(
    legalError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle,
    setLoadedData
  );

  const updateLegalType = (e) => {
    if (typeof e === 'string') e = categoryMap[e];
    setLegalType(e);
    dispatchLegal({ type: 'category', payload: e?.value || e });
  };

  const updateApplicableMembers = (e, overwrite) => {
    let selectedApplicables = [
      ...legalState.applicable_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      selectedApplicables = [...(Array.isArray(e) ? e : [e])];
    }
    // select or deselect?
    const match = legalState.applicable_members.some(
      (am) => am.uuid === e.uuid
    );
    if (match) {
      selectedApplicables = legalState.applicable_members.filter(
        (am) => am.uuid !== e.uuid
      );
      // update the membersList
      setMembersList(
        membersList.map((member) => {
          if (member.uuid === e.uuid) {
            member.isApplicable = false;
          }
          return member;
        })
      );
    }
    // set optiosn dropdown
    const membersOptions = FilterMembersByContext(
      membersList,
      'instrument_owner'
    );
    setMembersOptions(membersOptions);
    // set selected
    dispatchLegal({
      type: 'applicable_members',
      payload: TransformApplicables(membersOptions, selectedApplicables).filter(
        (member) => member.isApplicable === true
      ),
    });
    // show selected
    setHasApplicables(true);
  };

  const updateEmpoweredMembers = (e, overwrite) => {
    let selectedEmpowereds = [
      ...legalState.empowered_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      selectedEmpowereds = [...(Array.isArray(e) ? e : [e])];
    }
    // if passing in a single value then you are selecting or deselecting that single member
    if (!Array.isArray(e)) {
      const alreadySelected = legalState.empowered_members.some(
        (am) => am.uuid === e.uuid
      );
      if (alreadySelected) {
        // remove from selected members list
        selectedEmpowereds = legalState.empowered_members.filter(
          (am) => am.uuid !== e.uuid
        );
        // update the membersList
        setMembersList(
          membersList.map((member) => {
            if (member.uuid === e.uuid) {
              member.isEmpowered = false;
            }
            return member;
          })
        );
      }
    }
    // set options dropdown
    const membersOptions = FilterMembersByContext(
      membersList,
      'instrument_owner'
    );
    setMembersOptions(membersOptions);
    // set selected
    dispatchLegal({
      type: 'empowered_members',
      payload: TransformEmpowered(membersOptions, selectedEmpowereds).filter(
        (member) => member.isEmpowered === true
      ),
    });
    // show selected
    setHasEmpowered(true);
  };

  const updateBeneficiaries = (e, overwrite) => {
    let selectedBeneficiaries = [
      ...legalState.beneficiary_members,
      ...(Array.isArray(e) ? e : [e]),
    ];
    if (overwrite) {
      selectedBeneficiaries = [...(Array.isArray(e) ? e : [e])];
    }
    // select or deselect?
    const match = legalState.beneficiary_members.some(
      (am) => am.uuid === e.uuid
    );
    if (match) {
      selectedBeneficiaries = legalState.beneficiary_members.filter(
        (am) => am.uuid !== e.uuid
      );
      // update the membersList
      setMembersList(
        membersList.map((member) => {
          if (member.uuid === e.uuid) {
            member.isBeneficiary = false;
          }
          return member;
        })
      );
    }
    // set options (dropdown)
    const beneficiariesOptions = FilterMembersByContext(
      membersList,
      'instrument_beneficiary'
    );
    setBeneficiariesOptions(beneficiariesOptions);
    // set selected
    dispatchLegal({
      type: 'beneficiary_members',
      payload: TransformLegalBeneficiaries(
        beneficiariesOptions,
        selectedBeneficiaries
      ).filter((member) => member.isBeneficiary === true),
    });
    // show selected
    setHasBeneficiaries(true);
  };

  const changeValue = (e, field, overwrite = false) => {
    switch (field) {
      case 'category':
        updateLegalType(e);
        break;
      case 'applicable_members':
        updateApplicableMembers(e, overwrite);
        if (CheckHasError(modalErrors, 'applicable_members')) {
          setModalErrors(RemoveError(modalErrors, 'applicable_members'));
        }
        break;
      case 'reference_name':
      case 'location':
        dispatchLegal({
          type: field,
          payload:
            e?.target?.value !== null && e?.target?.value !== undefined
              ? e?.target?.value
              : e,
        });
        break;
      case 'empowered_members':
        updateEmpoweredMembers(e, overwrite);
        break;
      case 'beneficiary_members':
        updateBeneficiaries(e, overwrite);
        break;
      case 'date_created':
      case 'date_collected':
        dispatchLegal({
          type: field,
          payload: e !== '' ? DateFormat(e, preferences.date_format) : '',
        });
        break;
      default: // notes
        dispatchLegal({ type: field, payload: e });
        break;
    }
  };

  useEffect(() => {
    if (modalErrors) {
      if (!modalErrors || Object.entries(modalErrors).length === 0) {
        setModalError('');
      }
    }
  }, [modalErrors]);

  const validationChecker = (name) => {
    if (CheckHasError(modalErrors, name)) {
      const updatedErrors = RemoveError(modalErrors, name);
      setModalErrors(updatedErrors);
    }
  };

  const legalInputs = [
    {
      type: 'select',
      label: 'Type',
      name: 'category',
      required: true,
      placeholder: 'Type',
      value: legalType,
      options: typeOptions,
      width: '100%',
      isVisible: true,
      onChange: (e) => changeValue(e, 'category'),
      hasError: CheckHasError(modalErrors, 'category'),
      errorMessage: GenerateError(modalErrors, 'category'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'select',
      label: 'Applicable Members',
      name: 'applicable_members',
      required: true,
      placeholder: 'Applicable members',
      customSelect: true,
      customComponent: LegalApplicableMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: membersOptions,
      onChange: (e) => changeValue(e, 'applicable_members'),
      selectedOptions: legalState.applicable_members,
      showSelected: hasApplicables,
      hasSelected: true,
      tooltip: 'On whose behalf; e.g. Principal, Grantor, Testator',
      hasError: CheckHasError(modalErrors, 'applicable_members'),
      errorMessage: GenerateError(modalErrors, 'applicable_members'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'text',
      label: 'Reference Name',
      name: 'reference_name',
      required: true,
      placeholder: 'e.g. Will, Trust, POA',
      value: legalState.reference_name,
      onChange: (e) => changeValue(e, 'reference_name'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'reference_name'),
      errorMessage: GenerateError(modalErrors, 'reference_name'),
      onFocus: () => validationChecker('reference_name'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'text',
      label: 'Location',
      name: 'location',
      required: true,
      placeholder: "e.g. Institution, Attorney's Office",
      value: legalState.location,
      onChange: (e) => changeValue(e, 'location'),
      isVisible: true,
      width: '100%',
      hasError: CheckHasError(modalErrors, 'location'),
      errorMessage: GenerateError(modalErrors, 'location'),
      onFocus: () => validationChecker('location'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'select',
      label: 'Empowered Members',
      name: 'empowered_members',
      placeholder: 'Empowered members',
      customSelect: true,
      customComponent: LegalEmpoweredMemberOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: membersOptions,
      onChange: (e) => changeValue(e, 'empowered_members'),
      selectedOptions: legalState.empowered_members,
      showSelected: hasEmpowered,
      hasSelected: true,
      tooltip:
        'The authorized or responsible party, e.g. executor, trustee, agent, attorney-in-fact, personal representative',
      hasError: CheckHasError(modalErrors, 'empowered_members'),
      errorMessage: GenerateError(modalErrors, 'empowered_members'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'select',
      label: 'Primary Beneficiary',
      name: 'beneficiary_members',
      placeholder: 'Primary Beneficiary',
      customSelect: true,
      customComponent: BeneficiaryOption,
      width: '100%',
      isVisible: true,
      value: { label: 'Select Members', value: '' },
      options: beneficiariesOptions,
      onChange: (e) => changeValue(e, 'beneficiary_members'),
      selectedOptions: legalState.beneficiary_members,
      showSelected: hasBeneficiaries,
      hasSelected: true,
      hasError: CheckHasError(modalErrors, 'beneficiary_members'),
      errorMessage: GenerateError(modalErrors, 'beneficiary_members'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'date',
      label: 'Date Executed',
      name: 'date_created',
      width: '48%',
      required: false,
      placeholder: 'mm/yyyy',
      dateFormat: 'MM/yyyy',
      value: legalState.date_created,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_created'),
      hasError: CheckHasError(modalErrors, 'date_created'),
      errorMessage: GenerateError(modalErrors, 'date_created'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'date',
      label: 'Data Collected',
      name: 'date_collected',
      width: '48%',
      required: false,
      placeholder: 'mm/dd/yyyy',
      dateFormat: 'MM/DD/YYYY',
      value: legalState.date_collected,
      isVisible: true,
      onChange: (e) => changeValue(e, 'date_collected'),
      hasError: CheckHasError(modalErrors, 'date_collected'),
      errorMessage: GenerateError(modalErrors, 'date_collected'),
      tab: hasTabs ? 'instrument' : null,
    },
    {
      type: 'textarea',
      label: 'Notes',
      name: 'notes',
      showClear: true,
      id: 'asset',
      value: legalState.notes,
      onChange: (e) => changeValue(e, 'notes'),
      hasError: CheckHasError(modalErrors, 'notes'),
      errorMessage: GenerateError(modalErrors, 'notes'),
      tab: hasTabs ? 'notes' : null,
    },
  ];

  // EDIT
  useEffect(() => {
    if (modalState === 'edit' && hasLegal && hasMembersList) {
      for (let field in currentLegal) {
        changeValue(currentLegal[field], field);
      }
      setLoadedData(true);
    }
  }, [modalState, hasLegal, hasMembersList]);

  const onSaveLegal = () => {
    setButtonLoading(true);
    setModalErrors();
    if (modalState === 'add') {
      dispatch(
        showProposalMode
          ? createProposalLegal(
              currentHousehold?.uuid,
              proposalId,
              legalState,
              categoryMap[legalState.category].label
            )
          : createLegal(
              householdId,
              legalState,
              categoryMap[legalState.category].label
            )
      );
    } else {
      dispatch(
        showProposalMode
          ? updateProposalLegal(
              currentHousehold?.uuid,
              proposalId,
              legalState,
              legalState?.id,
              categoryMap[legalState.category].label,
              legalState?.proposal_action_data?.id
            )
          : updateLegal(
              householdId,
              legal.id,
              legalState,
              categoryMap[legalState.category].label
            )
      );
    }
  };

  const onDeleteLegal = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'legal',
        currentLegal.reference_name,
        currentLegal.id,
        true,
        currentLegal
      )
    );
  };

  // LIST
  const makeLegalsList = (legals, members) => {
    const list = [...legals].map((legal, index) => {
      return {
        index: index,
        value: legal?.reference_name,
        label: legal?.reference_name,
        id: legal.id,
        members: [...legal.applicable_members.filter(Boolean)].map((am) => {
          return [...members].filter((member) => member.uuid === am.uuid)[0];
        }),
        name: legal?.reference_name,
        year: DateFormat(legal?.date_created, 'YYYY'),
        is_proposal: legal?.is_proposal,
        proposal_action: legal?.proposal_action,
        proposal_action_id: legal?.proposal_action_id,
      };
    });
    setLegalsList(list);
  };

  useEffect(() => {
    if (refreshListView && membersLegals) {
      if (modalState === 'list') {
        makeLegalsList(membersLegals, membersList);
      }
    }
  }, [refreshListView, membersLegals]);

  useEffect(() => {
    if (modalState === 'list' && hasLegals && hasMembersList && hasCategories) {
      setLoadedData(true);
      makeLegalsList(membersLegals, membersList);
    }
  }, [modalState, hasLegals, hasMembersList, hasCategories]);

  const handleClickLegal = (legal) => {
    setModalState('edit');
    setLoadedData(false);
    setHasLegal(false);
    if (showProposalMode && legal?.is_proposal) {
      // TODO proposal mode and sidebar glass applet opening a modal use case...
      dispatch(
        readProposalLegal(
          currentHousehold.uuid,
          currentProposal?.id,
          legal?.proposal_action_id
        )
      );
    } else {
      dispatch(getLegal(currentHousehold.id, legal.id));
    }
    setShowBackButton(true);
    setSelectedLegal(legal);
  };

  const handleClickCategory = (category) => {
    setModalState('add');
    setShowBackButton(true);
    updateLegalType(category.value);
  };

  const handleClickBack = () => {
    setModalState('list');
    setShowBackButton(false);
    setMembersList(
      membersList.map((member) => {
        member.isSelected = false;
        member.isApplicable = false;
        member.isEmpowered = false;
        member.isBeneficiary = false;
        delete member.ratio;
        return member;
      })
    );
    for (let field in initLegalState) {
      changeValue(initLegalState[field], field, true);
    }
  };

  const generateTabs = () => {
    return (
      <ModalTabs
        tabs={modalTabOptions}
        activeTab={activeTab}
        onClick={setActiveTab}
      />
    );
  };

  const generateHeader = () => {
    if (modalState === 'list') {
      return <HeaderSection>Summary</HeaderSection>;
    }
    if (modalState === 'edit') {
      if (showBackButton) {
        const selectedLegalInput = {
          type: 'select',
          label: 'Current Legal',
          name: 'legal',
          required: true,
          placeholder: 'Legal',
          value: selectedLegal,
          options: legalsList,
          width: '100%',
          isVisible: true,
          onChange: (e) => handleClickLegal(e),
          errorMessage: GenerateError(modalErrors, 'legal'),
          tab: hasTabs ? 'instrument' : null,
          hide:
            (modalLocation === 'assetmap' && modalState !== 'edit') ||
            modalLocation === 'financials',
        };
        return (
          <HeaderSection>
            <BackButtonContainer>
              <BackButton onClick={() => handleClickBack()}>
                <img src={arrowBack} alt={'back'} data-image="back-arrow" />
                Back to Summary
              </BackButton>
            </BackButtonContainer>
            <HeaderInput>{GenerateInput(selectedLegalInput, 0)}</HeaderInput>
          </HeaderSection>
        );
      }
    }
    if (modalState === 'add') {
      if (showBackButton) {
        return (
          <HeaderSection>
            <BackButtonContainer onClick={() => handleClickBack()}>
              <BackButton>
                <img src={arrowBack} alt={'back'} data-image="back-arrow" />
                Back to Summary
              </BackButton>
            </BackButtonContainer>
          </HeaderSection>
        );
      }
    }
  };

  const generateProposalIcon = (legal) => {
    switch (legal?.proposal_action) {
      case 'add':
        legal.title = `Created`;
        legal.icon = circleAdd;
        break;
      case 'delete':
        legal.title = `Deleted`;
        legal.icon = circleDelete;
        break;
      case 'modify':
        legal.title = `Edited`;
        legal.icon = circleEdit;
        break;
      default:
        legal.title = 'An unknown action has taken place.';
        legal.icon = errorCircle;
    }
    return (
      <ProposalIconContainer
        title={legal.title}
        className="proposal_icon"
        data-proposal-action={`proposal-${legal?.action}-icon`}
      >
        <img src={legal?.icon} alt={legal?.action} />
      </ProposalIconContainer>
    );
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEsc={hide} />
      {mode === 'default' && <ModalBackground />}
      <ModalOverlay mode={mode}>
        <ModalContainer role="dialog" ref={ref} mode={mode}>
          <ModalHeadingContainer>
            <ModalHeading>{modalHeader}</ModalHeading>
            {mode === 'default' && (
              <CloseButton
                src={timesWhite}
                onClick={() => hide()}
                alt="close"
                data-image="close"
              />
            )}
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainer>
            {!loadedData && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                  {/* <LoadingAnimation smaller={true} /> */}
                  {/* <p>Loading</p> */}
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            {hasTabs && generateTabs()}
            <ModalContentContainer>
              {generateHeader()}
              {modalState !== 'list' && (
                <>
                  <Section greyTheme={greyTheme}>
                    {hasTabs ? (
                      activeTab === 'proposals' ? (
                        <ProposalsTab
                          proposalContent={proposalContent}
                          hasError={modalError && modalError !== ''}
                        />
                      ) : (
                        <TabContent>
                          {legalInputs
                            .filter((input) => input.tab === activeTab)
                            .filter((input) => !input.hide)
                            .map((input, index) => {
                              return GenerateInput(input, index);
                            })}
                        </TabContent>
                      )
                    ) : (
                      <TabContent>
                        {legalInputs
                          .filter((input) => !input.hide)
                          .map((input, index) => {
                            return GenerateInput(input, index);
                          })}
                      </TabContent>
                    )}
                  </Section>
                  {activeTab !== 'proposals' && (
                    <ModalButtons
                      isNew={modalState === 'add'}
                      hide={mode === 'default' ? hide : handleClickBack}
                      //   isHidden={buttonContent.isHidden}
                      saveFunction={onSaveLegal}
                      showLoading={buttonLoading}
                      loadingText={modalState === 'add' ? 'Creating' : 'Saving'}
                      deleteFunction={onDeleteLegal}
                    />
                  )}
                </>
              )}
              {modalState === 'list' && (
                <>
                  {legalsList?.length > 0 && (
                    <SectionWithBottomBorder>
                      <SectionHeader>Active</SectionHeader>
                      <ActiveLegals>
                        {legalsList.map((legal, index) => {
                          return (
                            <LegalRow
                              key={index}
                              onClick={() => handleClickLegal(legal)}
                              isProposal={legal?.is_proposal}
                              isDelete={legal?.proposal_action === 'delete'}
                            >
                              <LegalLeft>
                                <LegalName>{legal.name}</LegalName>
                                <LegalYear>
                                  {legal.year ? '(' + legal.year + ')' : ''}
                                </LegalYear>
                              </LegalLeft>
                              <LegalRight>
                                {legal.members
                                  .filter(Boolean)
                                  .map((member, index) => {
                                    return (
                                      <LegalMember
                                        title={member.name}
                                        key={index}
                                        src={GenerateAvatarMemberIconOld(
                                          member
                                        )}
                                        alt="user"
                                      />
                                    );
                                  })}
                                <ArrowButton
                                  onClick={() => handleClickLegal(legal)}
                                >
                                  <img
                                    src={chevronRight}
                                    alt="arrow"
                                    data-image="arrow-right"
                                  />
                                </ArrowButton>
                              </LegalRight>
                              {legal?.is_proposal &&
                                generateProposalIcon(legal)}
                            </LegalRow>
                          );
                        })}
                      </ActiveLegals>
                    </SectionWithBottomBorder>
                  )}
                  {hasCategories && (
                    <Section>
                      <SectionHeader>Add</SectionHeader>
                      <ActiveAddables>
                        {Object.values(categoryMap).map((category, index) => {
                          return (
                            <LegalRow
                              key={index}
                              onClick={() => handleClickCategory(category)}
                            >
                              <LegalLeft>
                                <LegalCategory>{category.label}</LegalCategory>
                              </LegalLeft>
                              <LegalRight>
                                <PlusButton
                                  onClick={() => handleClickCategory(category)}
                                >
                                  <img
                                    src={greyCirclePlus}
                                    alt="plus"
                                    data-image="plus"
                                  />
                                </PlusButton>
                              </LegalRight>
                            </LegalRow>
                          );
                        })}
                      </ActiveAddables>
                      <ButtonContainer>
                        {mode === 'default' && (
                          <Button
                            text={'Close'}
                            onClick={() => hide()}
                            alt="close"
                            data-image="close"
                            theme={ButtonThemes.primary}
                          />
                        )}
                      </ButtonContainer>
                    </Section>
                  )}
                </>
              )}
            </ModalContentContainer>
          </ModalLoadingOverlayContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const BackButtonContainer = styled.div`
  font-size: 11px;
  text-align: left;
  align-items: center;
  display: flex;
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: 15px;
`;

const BackButton = styled.div`
  display: 'inline-flex';
  cursor: pointer;
  img {
    width: 12px;
    height: 12px;
    color: black;
    padding-right: 5px;
  }
`;

const HeaderInput = styled.div`
  padding-top: 10px;
  font-weight: 500;
`;

const HeaderSection = styled.div`
  font-size: 11px;
  text-align: left;
  font-weight: 600;
  padding: 5px 0px;
`;

const SectionHeader = styled.div`
  font-weight: 600;
`;

const SectionWithBottomBorder = styled.div`
  font-size: 11px;
  text-align: left;
  padding: 10px 0px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const ActiveLegals = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

const LegalRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.lightGrey};
  margin-bottom: 10px;
  &:hover {
    transform: scale(1.01);
    background-color: ${colors.grey};
    cursor: pointer;
  }
  ${({ isProposal }) =>
    isProposal &&
    `
    position: relative;
    border: 1px solid ${colors.paleGrey};
    background: ${assetMapColors.draft};`}
  ${({ isDelete, isProposal }) =>
    isProposal &&
    isDelete &&
    `
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23E24585' fill-opacity='0.3' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  `}
`;

const LegalLeft = styled.div`
  display: flex;
  padding-left: 5px;
  align-items: center;
`;

const LegalRight = styled.div`
  display: flex;
  padding-right: 5px;
  align-items: center;
`;

const LegalName = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const LegalYear = styled.div`
  color: ${colorsRGB.paleGrey};
  padding-left: 2px;
  font-size: 10px;
`;

const LegalMember = styled.img`
  max-width: 12px;
  width: 100%;
  max-height: 12px;
  height: 100%;
  margin-right: 4px;
  padding-right: 2px;
`;

const ArrowButton = styled.button`
  img {
    width: 10px;
    height: 10px;
  }
`;

const Section = styled.div`
  font-size: 11px;
  text-align: left;
  padding: 10px 0px;
  ${({ greyTheme }) =>
    greyTheme &&
    `
    padding: 10px 20px;
    margin: 0px -20px;
    background: ${colors.hoverLighterGrey};
  `}
`;

const ActiveAddables = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

const LegalCategory = styled.div`
  font-size: 12px;
  color: ${colorsRGB.paleGrey};
`;

const PlusButton = styled.button`
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
`;

const ButtonContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 120px;
  }
`;

const ProposalIconContainer = styled.div`
  position: absolute;
  z-index: 4;
  right: -8px;
  top: -6px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
`;

LegalModal.propTypes = {
  hide: PropTypes.func,
  householdId: PropTypes.string,
  isNew: PropTypes.bool,
  isList: PropTypes.bool,
  proposalId: PropTypes.string,
  showProposalMode: PropTypes.bool,
  refreshListView: PropTypes.bool,
};

export default LegalModal;
