/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Select, Error, DetectModalKeys, ModalTabs } from '..';
import {
  getStencilOptions,
  getStencilLibraryOptions,
  getStencilMembers,
  applyStencil,
  getStencilAssetMapImage,
  toggleShowCloneStencilModal,
  updateHouseholdStoreValue,
  toggleShowStencilImagePreviewModal,
  // refreshHouseholdAssetMapImage,
} from '../../store/actions';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import {
  HtmlToString,
  StencilOption,
  StencilLibraryOption,
  SendTrackingEvent,
} from '../../utils';
import { ButtonThemes } from '../../styles/themes';
import { colors, fonts } from '../../styles/variables';

const StencilModal = ({ hide, householdId, isConsumer }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    householdStencils,
    applyingStencil,
    stencilAmrImage,
    // refreshedImage,
    householdStencilMembers,
    householdmembers,
    user,
    showStencilLibrary,
    hasAssetmapPlusStencilLibrary,
    hasCustomerStencilLibrary,
    clonedStencil,
    invocationId,
    applyStencilError,
  } = useSelector((state) => ({
    householdStencils: state.households.householdStencils,
    applyingStencil: state.households.applyingStencil,
    stencilAmrImage: state.households.stencilAmrImage,
    // refreshedImage: state.households.refreshedImage,
    householdStencilMembers: state.households.stencilMembers,
    householdmembers: state.households.currentHousehold.householdmembers,
    user: state.user.user,
    showStencilLibrary: state.user.showStencilLibrary,
    hasAssetmapPlusStencilLibrary: state.user.hasAssetmapPlusStencilLibrary,
    hasCustomerStencilLibrary: state.user.hasCustomerStencilLibrary,
    clonedStencil: state.households.clonedStencil,
    invocationId: state.configs.invocationId,
    applyStencilError: state.households.applyStencilError,
  }));
  const [stencilOptions, setStencilOptions] = useState([]);
  const [stencilError, setStencilError] = useState('');
  const [selectedStencil, setSelectedStencil] = useState();
  const [applyEntireHousehold, setApplyEntireHousehold] = useState(true);
  const [householdMembers, setHouseholdMembers] = useState([]);
  const [selectedHouseholdMember, setSelectedHouseholdMember] = useState();
  const [stencilMembers, setStencilMembers] = useState([]);
  const [selectedStencilMember, setSelectedStencilMember] = useState();
  const [viewStencil, setViewStencil] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [tabs, setTabs] = useState([
    { label: 'My Stencils', value: 'personal', visible: true },
    {
      label: 'Public Library',
      value: 'assetmap',
      visible: true,
    },
    { label: 'Asset-Map+', value: 'assetmap_plus', visible: false },
    { label: 'Shared', value: 'shared', visible: false },
  ]);
  const [activeTab, setActiveTab] = useState('personal');

  const tabDetails = {
    personal:
      'These are stencils you have created or added to your own library. Select one to apply to your current Household view.',
    assetmap:
      'These stencils are created and maintained by Asset-Map. Try them out to get started, if you want to modify them you can always add them to your Personal Library.',
    assetmap_plus:
      'These premium additional stencils are created and maintained by Asset-Map. Try them out to get started, if you want to modify them you can always add them to your Personal Library.',
    shared:
      'These stencils are created and maintained by your organization. Try them out to get started, if you want to modify them you can always add them to your Personal Library.',
  };

  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('stencilAmrImage', null));
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (showStencilLibrary) {
        const updatedTabs = tabs.map((tab) => {
          if (tab.value === 'assetmap_plus') {
            tab.visible = hasAssetmapPlusStencilLibrary;
          } else if (tab.value === 'shared') {
            tab.visible = hasCustomerStencilLibrary;
          }
          return tab;
        });
        setTabs(updatedTabs);
      } else {
        dispatch(getStencilOptions(isConsumer));
      }
    }
  }, [user, showStencilLibrary]);

  useEffect(() => {
    if (activeTab && showStencilLibrary) {
      setSelectedStencil(null);
      setApplyEntireHousehold(true);
      dispatch(getStencilLibraryOptions(activeTab));
      setStencilError(false);
      SendTrackingEvent(
        invocationId,
        'click',
        'stencil_library_tab',
        'household_amr',
        {
          stencil_library_tab: activeTab,
        }
      );
    }
  }, [activeTab, showStencilLibrary]);

  useEffect(() => {
    if (stencilAmrImage?.url) {
      viewStencil.image = {
        url: stencilAmrImage.url,
        fresh: stencilAmrImage.fresh,
        is_error: stencilAmrImage.is_error,
        refresh: stencilAmrImage.refresh,
        reason: stencilAmrImage.reason,
        generated_utc: stencilAmrImage.generated_utc,
        is_default: stencilAmrImage?.default || false,
      };
      dispatch(
        toggleShowStencilImagePreviewModal(true, {
          ...viewStencil,
        })
      );
    }
  }, [stencilAmrImage?.url]);

  // useEffect(() => {
  //   if (refreshedImage === false && stencilAmrImage) {
  //     if (
  //       !stencilAmrImage.hasOwnProperty('refresh') &&
  //       (!stencilAmrImage.fresh || stencilAmrImage.is_error)
  //     ) {
  //       dispatch(
  //         refreshHouseholdAssetMapImage(
  //           householdId,
  //           stencilAmrImage.is_error ? 'force' : true,
  //           false,
  //           true
  //         )
  //       );
  //     }
  //   }
  // }, [refreshedImage, stencilAmrImage]);

  useEffect(() => {
    if (applyStencilError) {
      setStencilError(applyStencilError);
      setButtonLoading(false);
      dispatch(updateHouseholdStoreValue('applyStencilError', null));
    }
  }, [applyStencilError]);

  useEffect(() => {
    if (clonedStencil) {
      setActiveTab('personal');
      dispatch(updateHouseholdStoreValue('clonedStencil', false));
    }
  }, [clonedStencil]);

  useEffect(() => {
    if (householdmembers) {
      setHouseholdMembers(
        householdmembers.map((member) => {
          member.label = HtmlToString(member.name);
          member.value = member.id;
          return member;
        })
      );
    }
  }, [householdmembers]);

  useEffect(() => {
    if (selectedStencil && selectedStencil.hasOwnProperty('value')) {
      dispatch(getStencilMembers(selectedStencil.value, isConsumer));
      setApplyEntireHousehold(true);
      setStencilError('');
    }
  }, [selectedStencil]);

  useEffect(() => {
    if (householdStencilMembers) {
      setStencilMembers(
        householdStencilMembers.map((member) => {
          member.label = HtmlToString(member.name);
          member.value = member.id;
          return member;
        })
      );
    }
  }, [householdStencilMembers]);

  useEffect(() => {
    if (householdStencils) {
      setStencilOptions(
        householdStencils.map((stencil) => {
          stencil.label = stencil.name;
          stencil.value = stencil.id;
          if (stencil?.is_customer_stencil) {
            stencil.org = user?.customer?.name || 'Customer';
          }
          return stencil;
        })
      );
    }
  }, [householdStencils]);

  useEffect(() => {
    if (!applyEntireHousehold) {
      setSelectedStencilMember(stencilMembers[0]);
      const firstMember = householdMembers[0];
      if (firstMember && firstMember?.name && firstMember?.id) {
        setSelectedHouseholdMember({
          label: firstMember.name,
          value: firstMember.id,
        });
      }
    }
  }, [applyEntireHousehold]);

  const applyStencilFunction = () => {
    if (!applyingStencil) {
      if (selectedStencil) {
        const data = {
          stencil: selectedStencil.value,
          source: '',
          target: '',
        };

        if (!applyEntireHousehold) {
          if (selectedStencilMember) {
            data.source = selectedStencilMember.value;
          }
          if (selectedHouseholdMember) {
            data.target = selectedHouseholdMember.value;
          }
        }
        setButtonLoading(true);
        dispatch(applyStencil(householdId, data, isConsumer));
      } else {
        setStencilError('Please select a stencil.');
      }
    }
  };

  const cloneStencil = (stencil) => {
    dispatch(toggleShowCloneStencilModal(true, stencil));
  };

  const viewStencilImage = (stencil) => {
    if (stencil?.image?.fresh || stencil?.image?.reason === 'disabled') {
      dispatch(
        toggleShowStencilImagePreviewModal(true, {
          ...stencil,
        })
      );
    } else {
      dispatch(
        toggleShowStencilImagePreviewModal(true, {
          ...stencil,
          imageLoading: true,
        })
      );
      // refresh the image
      dispatch(getStencilAssetMapImage(stencil?.id, true));
    }
    setViewStencil(stencil);
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={applyStencilFunction} onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {showStencilLibrary ? (
                <>
                  Stencils<sup>™</sup>
                </>
              ) : (
                <>
                  Apply Stencil<sup>™</sup>
                </>
              )}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          {showStencilLibrary && (
            <ModalTabs
              tabs={tabs.filter((tab) => tab.visible)}
              activeTab={activeTab}
              onClick={setActiveTab}
            />
          )}
          <ModalContentContainer>
            {showStencilLibrary ? (
              <SubText>{tabDetails[activeTab]}</SubText>
            ) : (
              <SubText>
                The Stencil feature allows you to apply financial instruments
                (i.e. assets, income, liabilities, insurance) from a selected
                Stencil Household to the current Household on view.
              </SubText>
            )}
            <TabContent>
              <SelectContainer>
                <Select
                  selectedOption={selectedStencil}
                  onChange={(e) =>
                    setSelectedStencil({ label: e.label, value: e.value })
                  }
                  components={{
                    Option: showStencilLibrary
                      ? (e) =>
                          StencilLibraryOption(
                            e,
                            viewStencilImage,
                            activeTab !== 'personal',
                            cloneStencil
                          )
                      : (e) => StencilOption(e),
                  }}
                  options={stencilOptions}
                  isSearchable={true}
                  placeholder={'Select a Stencil'}
                  autoFocus={true}
                  isDisabled={applyingStencil}
                  closeMenuOnSelect={true}
                  onEnter={applyStencilFunction}
                />
              </SelectContainer>
              {selectedStencil && selectedStencil.hasOwnProperty('value') && (
                <OptionContainer>
                  <QuestionContainer>
                    Apply stencil to entire household?
                  </QuestionContainer>
                  <RadioContainer>
                    <input
                      type="radio"
                      id="yes"
                      name="household"
                      value="yes"
                      checked={applyEntireHousehold}
                      onChange={() => setApplyEntireHousehold(true)}
                    />
                    <label htmlFor="yes" style={{ marginRight: '10px' }}>
                      Yes
                    </label>
                    <input
                      type="radio"
                      id="no"
                      name="household"
                      value="no"
                      checked={!applyEntireHousehold}
                      onChange={() => setApplyEntireHousehold(false)}
                    />
                    <label htmlFor="no">No</label>
                  </RadioContainer>
                </OptionContainer>
              )}
              {!applyEntireHousehold && (
                <SelectedMembersContainer>
                  <SelectContainer>
                    <SelectLabel>Select Member from Stencil</SelectLabel>
                    <SelectSubText>
                      Select the member from the stencil you wish to copy.
                    </SelectSubText>
                    <Select
                      selectedOption={selectedStencilMember}
                      onChange={(e) =>
                        setSelectedStencilMember({
                          label: e.label,
                          value: e.value,
                        })
                      }
                      options={stencilMembers}
                      isSearchable={true}
                      placeholder={'Select a Member'}
                      autoFocus={false}
                      isDisabled={false}
                      closeMenuOnSelect={true}
                      onEnter={applyStencilFunction}
                    />
                  </SelectContainer>
                  <SelectContainer>
                    <SelectLabel>Select Household Member</SelectLabel>
                    <SelectSubText>
                      Select the member you wish to apply the stencil to.
                    </SelectSubText>
                    <Select
                      selectedOption={selectedHouseholdMember}
                      onChange={(e) =>
                        setSelectedHouseholdMember({
                          label: e.label,
                          value: e.value,
                        })
                      }
                      options={householdMembers}
                      isSearchable={true}
                      placeholder={'Select a Member'}
                      autoFocus={false}
                      isDisabled={false}
                      closeMenuOnSelect={true}
                      onEnter={applyStencilFunction}
                    />
                  </SelectContainer>
                </SelectedMembersContainer>
              )}
            </TabContent>
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={'Apply Stencil'}
                  onClick={() => applyStencilFunction()}
                  showLoading={buttonLoading || applyingStencil}
                  loadingText={'Applying'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {stencilError && stencilError !== '' && (
            <Error errorMessage={stencilError} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const SubText = styled.p`
  margin-bottom: 15px;
  font-size: 12px;
  color: ${colors.darkGrey};
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 100%;
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

const OptionContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  width: 100%;
  color: ${colors.darkGrey};
  border-top: thin solid ${colors.lightGrey};
  border-bottom: thin solid ${colors.lightGrey};
  padding: 12px 0;
`;

const SelectLabel = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 13px;
  margin: 0 0 3px 2px;
`;

const SelectSubText = styled.p`
  font-weight: ${fonts.light};
  font-size: 11px;
  margin: 0 0 6px 2px;
  color: ${colors.paleGrey};
`;

const SelectedMembersContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  width: 100%;
  color: ${colors.darkGrey};
  ${SelectContainer} {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  color: ${colors.darkGrey};
  label {
    margin-left: 3px;
    cursor: pointer;
  }
`;

const QuestionContainer = styled.div`
  flex: 1 1 auto;
  text-align: left;
`;

StencilModal.propTypes = {
  hide: PropTypes.func,
  householdId: PropTypes.string,
  isConsumer: PropTypes.bool,
};

export default StencilModal;
