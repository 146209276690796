import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, TextInput, Error } from '..';
import { AuthFactorOption } from '../';
import { AuthCodeModal, ResendCodeModal } from '../../containers';
import { addAuthFactor } from '../../store/actions';
import { timesWhite, emailBlue, sms } from '../../assets';
import {
  ModalBackground,
  SmallerModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
} from '../../styles/library/modalStyles';
import { LinkTextSmall, LinkText } from '../../styles/library/fontStyles';
import { CenterFlexContainer } from '../../styles/library/layoutStyles';
import { colors, fonts } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';

const AuthFactorModal = ({
  hide,
  noHide = false,
  screen,
  setAuthScreenValue,
  authFactorOptions,
  type,
  selectedOption,
  selectMFAMethod,
  setScreenValue,
  removeAuthMethod,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { user, addAuthFactorError, resendCodeError } = useSelector(
    (state) => ({
      user: state.user.user,
      addAuthFactorError: state.user.addAuthFactorError,
      resendCodeError: state.user.resendCodeError,
    })
  );
  const [authFactorValue, setAuthFactorValue] = useState('');
  const [authValue, setAuthValue] = useState('');
  const [authId, setAuthId] = useState(null);
  const [authFactorError, setAuthFactorError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unknown Error');
  // const [showErrorBanner, setShowErrorBanner] = useState(false);

  useEffect(() => {
    return () => {
      setAuthFactorValue('');
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (type === 'email' && user?.email) {
        setAuthFactorValue(user.email);
      }
      if (type === 'phone' && user?.phone_number) {
        setAuthFactorValue(user.phone_number);
      }
    }
  }, [user, type]);

  // useEffect(() => {
  //   console.log('authFactorReturned', authFactorReturned);
  //   if (authFactorReturned && authFactorReturned?.content) {
  //     setAuthValue(authFactorReturned.content);
  //   }
  // }, [authFactorReturned]);

  useEffect(() => {
    if (selectedOption) {
      setAuthValue(selectedOption?.content);
      setAuthId(selectedOption?.id);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (addAuthFactorError) {
      setAuthFactorError(true);
      setShowError(true);
      if (addAuthFactorError?.status === 500) {
        setErrorMessage('Server Error');
      } else {
        setErrorMessage(
          addAuthFactorError?.data?.content || addAuthFactorError?.data?.detail
        );
      }
    } else {
      setAuthFactorError(false);
      setErrorMessage('');
      setShowError(false);
    }
  }, [addAuthFactorError]);

  useEffect(() => {
    if (resendCodeError) {
      setShowError(true);
      if (resendCodeError?.status === 500) {
        setErrorMessage('Server Error');
      } else {
        setErrorMessage(resendCodeError?.data?.detail);
      }
    } else {
      setErrorMessage('');
      setShowError(false);
    }
  }, [resendCodeError]);

  const validateSendCode = () => {
    if (authFactorValue === '' || authFactorValue.length < 4) {
      setAuthFactorError(true);
    } else {
      setAuthFactorError(false);
      dispatch(
        addAuthFactor({
          factor_type: type === 'phone' ? 'sms' : 'email',
          content: authFactorValue,
        })
      );
    }
  };

  const generateHeading = () => {
    switch (screen) {
      case 'setup':
        return 'Set-Up MFA';
      case 'add':
        return 'Add Authentication Method';
      case 'edit':
        return 'Edit Authentication Method';
      case 'code':
        return 'Enter Authentication Code';
      case 'resend':
        return 'Resend Authentication Code';
      case 'resent':
        return 'Authentication Code Sent';
      default:
        return 'Error';
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <SmallerModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>{generateHeading()}</ModalHeading>
            {noHide || (
              <CloseButton
                src={timesWhite}
                onClick={() => hide()}
                data-image="close"
              />
            )}
          </ModalHeadingContainer>
          <ModalContentContainer>
            {screen === 'setup' && (
              <>
                <PrimaryText>
                  You currently do not have any form of MFA enabled, your
                  organization requires MFA to be active.
                </PrimaryText>
                <SecondaryText>Select MFA Method</SecondaryText>
                <AuthMethodList>
                  {authFactorOptions.map((option, index) => {
                    return (
                      <AuthFactorOption
                        key={index}
                        option={option}
                        isSelect={true}
                        selectMFAMethod={selectMFAMethod}
                        isLast={index === authFactorOptions.length - 1}
                      />
                    );
                  })}
                </AuthMethodList>
                <CenterFlexContainer>
                  <LinkTextSmall>
                    <a
                      href="https://usersupport.asset-map.com/en/articles/5174071-multi-factor-authentication"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Why am I seeing this?
                    </a>
                  </LinkTextSmall>
                </CenterFlexContainer>
              </>
            )}
            {(screen === 'add' || screen === 'edit') && (
              <>
                <IconContainer>
                  <img
                    src={type === 'phone' ? sms : emailBlue}
                    alt={type}
                    data-image={type}
                  />
                </IconContainer>
                <ModalText>
                  Provide the{' '}
                  {type === 'email' ? 'email address' : 'phone number'} you
                  would like your authentication code to be sent to in order to
                  confirm your identity.
                </ModalText>
                <TextInputContainer>
                  <TextInput
                    disabled={false}
                    type={'text'}
                    hasError={authFactorError}
                    required={true}
                    placeholder={
                      type === 'email' ? 'Email Address' : 'Phone Number'
                    }
                    value={authFactorValue}
                    onChange={(e) => setAuthFactorValue(e.currentTarget.value)}
                  />
                </TextInputContainer>
                <ButtonContainer>
                  <Button
                    text={type === 'phone' ? 'Send Code*' : 'Send Code'}
                    onClick={() => validateSendCode()}
                    theme={ButtonThemes.primaryFull}
                  />
                </ButtonContainer>
                {type === 'phone' && (
                  <AdditionalDetails>
                    *Msg & data rates may apply
                  </AdditionalDetails>
                )}
                {noHide && (
                  <CenterFlexContainer>
                    <LinkText
                      style={{ flex: '1 1 auto', textAlign: 'left' }}
                      onClick={() => {
                        setAuthScreenValue('setup');
                      }}
                    >
                      Back
                    </LinkText>
                  </CenterFlexContainer>
                )}
              </>
            )}
            {screen === 'code' && (
              <AuthCodeModal
                isAdd={true}
                authValue={authValue}
                authId={authId}
                setScreenValue={setScreenValue}
                selectedOption={selectedOption}
                removeAuthMethod={removeAuthMethod}
              />
            )}
            {(screen === 'resend' || screen === 'resent') && (
              <ResendCodeModal
                isAdd={true}
                authValue={authValue}
                authId={authId}
                hide={hide}
                noHide={noHide}
                showResendScreen={screen === 'resend'}
                showCodeSentScreen={screen === 'resent'}
                setScreenValue={setScreenValue}
              />
            )}
          </ModalContentContainer>
          {showError && <Error errorMessage={errorMessage} />}
        </SmallerModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const IconContainer = styled.div`
  margin: 5px auto 0 auto;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 100px;
  background: rgba(62, 177, 200, 0.15);
  img {
    width: 50px;
    height: 50px;
  }
`;

const ModalText = styled.p`
  font-size: 15px;
  margin: 20px auto;
  font-weight: ${fonts.semiBold};
  max-width: 350px;
  color: ${colors.darkGrey};
  span {
    font-weight: ${fonts.light};
    /* color: ${colors.paleGrey}; */
  }
`;

const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  input {
    flex: 1 1 auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 20px 0 10px 0;
  button {
    padding: 13px 15px;
    font-size: 14px;
    flex: 1 1 auto;
  }
`;

const AdditionalDetails = styled.p`
  text-align: left;
  font-size: 11px;
  color: ${colors.paleGrey};
`;

const PrimaryText = styled.div`
  margin-top: 5px;
  text-align: center;
  color: ${colors.darkGrey};
  font-size: 14px;
  font-weight: ${fonts.semiBold};
`;

const SecondaryText = styled.div`
  margin: 25px 0 5px 0;
  text-align: left;
  color: ${colors.paleGrey};
  font-size: 12px;
  font-weight: ${fonts.semiBold};
`;

const AuthMethodList = styled.div``;

AuthFactorModal.propTypes = {
  hide: PropTypes.func,
  noHide: PropTypes.bool,
  screen: PropTypes.string,
  setAuthScreenValue: PropTypes.func,
  authFactorOptions: PropTypes.array,
  type: PropTypes.string,
  selectedOption: PropTypes.object,
  selectMFAMethod: PropTypes.object,
  setScreenValue: PropTypes.func,
  removeAuthMethod: PropTypes.func,
};

export default AuthFactorModal;
