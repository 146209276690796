/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { PageHeader, IntegrationCard } from '../components';
import { Page } from '../styles/library/layoutStyles';
import { colors, maxDevice, fonts } from '../styles/variables';
import {
  headerContent,
  integrationPartners,
  SendTrackingEvent,
} from '../utils';

const PartnersContainer = () => {
  const { invocationId } = useSelector((state) => ({
    invocationId: state.configs.invocationId,
  }));

  const [partnersList, setPartnersList] = useState([]);
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);

  useEffect(() => {
    setPartnersList(integrationPartners.sort((a, b) => a.order - b.order));
    setLoadingIntegrations(false);
  }, []);

  const visitIntegration = (integration) => {
    window.open(integration.url, '_blank');
    return SendTrackingEvent(
      invocationId,
      'click',
      'external_partner',
      'partner',
      { partner: integration.id }
    );
  };

  return (
    <Page>
      <ColumnContainerLayout>
        <PageHeader content={headerContent.partners} />
        {loadingIntegrations && (
          <CardsContainer>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => {
              return (
                <Card key={item}>
                  <CardContent>
                    <CardImageContainer>
                      <ImagePlaceholder />
                    </CardImageContainer>
                    <CardInfoPlaceholder />
                    <ButtonPlaceholder />
                    <CardSmallTextPlaceholder />
                    <CardPermissionsTextPlaceholder />
                  </CardContent>
                </Card>
              );
            })}
          </CardsContainer>
        )}
        <CardsContainer>
          {partnersList.map((integration, index) => {
            return (
              <IntegrationCard
                key={index}
                cardId={index}
                integration={integration}
                visitIntegration={visitIntegration}
              />
            );
          })}
        </CardsContainer>
      </ColumnContainerLayout>
    </Page>
  );
};

const ColumnContainerLayout = styled.div`
  margin: 10px 0;
`;

const CardsContainer = styled.div`
  @media ${maxDevice.tablet} {
    justify-content: center;
  }
  @media ${maxDevice.mobileL} {
    margin: 10px 15px 15px 20px;
  }
  @media ${maxDevice.mobileS} {
    margin: 10px 15px 15px 15px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 10px 30px 60px 30px;
`;

const Card = styled.div`
  @media (max-width: 1360px) {
    flex: 1 1 40%;
    max-width: 40%;
    min-width: 40%;
  }
  @media ${maxDevice.tablet} {
    flex: 1 1 85%;
    max-width: 85%;
    min-width: 85%;
  }
  @media ${maxDevice.mobileL} {
    /* min-width: 250px; */
    margin: 0 0px 20px 0px;
    height: 320px;
  }
  @media ${maxDevice.mobileS} {
    padding: 15px 13px;
    margin: 0 0px 10px 0px;
  }
  border: 1px solid ${colors.lightGrey};
  border-radius: 0 0 5px 5px;
  background: ${colors.lighterGrey};
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 360px;
  max-width: 360px;
  min-width: 300px;
  height: 280px;
  padding: 20px 25px;
  margin: 0 10px 20px 10px;
  button {
    margin: 0px 0 25px 0;
    width: 150px;
  }
  /* &:hover {
    transform: scale(1.01);
  } */
`;

const CardContent = styled.div`
  width: 100%;
  button {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const CardImageContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 45px;
`;

const CardInfo = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 11px;
  }
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  height: 50px;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 auto;
  p {
    display: inline;
    max-width: 300px;
    &:first-child {
      margin-bottom: 8px;
    }
  }
  a {
    color: ${colors.lightBlue};
  }
  /* border-bottom: 1px solid ${colors.lightGrey}; */
`;

const CardInfoPlaceholder = styled(CardInfo)`
  margin: 25px 0;
  height: 45px;
  background: ${colors.lightGrey};
`;

const CardSmallText = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
`;

const CardSmallTextPlaceholder = styled(CardSmallText)`
  max-width: 240px;
  height: 20px;
  margin: 0 auto;
  margin-top: 20px;
  background: ${colors.lightGrey};
`;

const CardPermissionsText = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
  margin-top: 12px;
`;

const CardPermissionsTextPlaceholder = styled(CardPermissionsText)`
  max-width: 140px;
  height: 20px;
  margin: 0 auto;
  margin-top: 10px;
  background: ${colors.lightGrey};
`;

const ImagePlaceholder = styled.p`
  height: 50px;
  background: ${colors.lightGrey};
  max-width: 300px;
  width: 100%;
`;

const ButtonPlaceholder = styled.p`
  height: 38px;
  background: ${colors.lightGrey};
  width: 150px;
  margin: 0 auto;
  border-radius: 5px;
`;

export default PartnersContainer;
