import styled from 'styled-components';
import { colors, maxDevice } from '../styles/variables';
import PropTypes from 'prop-types';
import { SecondaryHeading } from '../styles/library/fontStyles';

const SubHeader = ({ content }) => {
  const { heading, subcontent } = content;
  return (
    <HeaderContainer>
      <HeaderContent>
        <SecondaryHeading>{heading}</SecondaryHeading>
        <SubContent>
          {subcontent.map((content, index) => {
            return <p key={index}>{content}</p>;
          })}
        </SubContent>
      </HeaderContent>
    </HeaderContainer>
  );
};

SubHeader.propTypes = {
  content: PropTypes.object,
  header: PropTypes.string,
  subcontent: PropTypes.array,
};

const HeaderContainer = styled.div`
  @media ${maxDevice.mobileL} {
    margin: 10px 20px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  text-align: left;
  margin: 15px 40px;
`;

const HeaderContent = styled.div`
  flex: 1 1 auto;
`;

const SubContent = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 11px;
  }
  font-size: 12px;
  max-width: 900px;
  margin: 10px 0 15px 0;
  color: ${colors.paleGrey};
  p {
    &:first-child {
      margin-bottom: 2px;
    }
  }
`;

export default SubHeader;
