import { NotificationTypes, ApiTypes as API } from '../actions';
import { TransformNotificationDate } from '../../utils';

const initialState = {
  unread: null,
  count: 0,
  next: null,
  previous: null,
  notifications: null,
  loadedNotifications: false,
  interval: null,
  notificationsError: {},
  markAllReadError: {},
  markAllUnreadError: {},
  toggleReadError: {},
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case NotificationTypes.GET_UNREAD_COUNT:
      return {
        ...state,
        unread: action.payload,
      };
    case NotificationTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loadedNotifications: true,
        notifications: TransformNotificationDate(action.payload.results),
      };
    case API.API_ERROR:
      switch (action.payload.label) {
        case NotificationTypes.GET_NOTIFICATIONS:
          return {
            ...state,
            notificationsError: action.payload.error,
          };
        case NotificationTypes.MARK_ALL_NOTIFICATIONS_READ:
          return {
            ...state,
            markAllReadError: action.payload.error,
          };
        case NotificationTypes.TOGGLE_NOTIFICATION_READ:
          return {
            ...state,
            toggleReadError: action.payload.error,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default notifications;
