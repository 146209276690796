/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNotifications,
  markAllNotificationsRead,
  toggleNotificationRead,
} from '../store/actions';
import styled from 'styled-components';
import { PageHeader, Notification, Pagination } from '../components';
import { colors, maxDevice, fonts } from '../styles/variables';
import { noNotifications } from '../assets';
import { headerContent, SendTrackingEvent } from '../utils';
import { Page } from '../styles/library/layoutStyles';

const NotificationsContainer = () => {
  const dispatch = useDispatch();
  const {
    notifications,
    loadedNotifications,
    count,
    showingNotificationDropdown,
    invocationId,
  } = useSelector((state) => ({
    notifications: state.notifications.notifications,
    loadedNotifications: state.notifications.loadedNotifications,
    count: state.notifications.count,
    showingNotificationDropdown: state.dropdowns.showingNotificationDropdown,
    invocationId: state.configs.invocationId,
  }));
  const headerButtonFunction = () => {
    dispatch(markAllNotificationsRead());
  };
  const notificationClick = (notification) => {
    dispatch(toggleNotificationRead(notification));
    return SendTrackingEvent(
      invocationId,
      'action',
      'nav_top_notification_mark_read',
      null
    );
  };
  const [offset, setOffset] = useState(0);

  const notificationPlaceholders = [
    { width: '30%' },
    { width: '40%' },
    { width: '30%' },
    { width: '60%' },
    { width: '30%' },
    { width: '50%' },
    { width: '40%' },
    { width: '50%' },
    { width: '50%' },
    { width: '60%' },
    { width: '20%' },
    { width: '60%' },
    { width: '30%' },
  ];

  useEffect(() => {
    if (showingNotificationDropdown) {
      setOffset(0);
    }
  }, [showingNotificationDropdown]);

  useEffect(() => {
    if (notifications && !showingNotificationDropdown) {
      dispatch(getNotifications(offset));
    }
  }, [offset]);

  return (
    <Page>
      <PageHeader
        content={headerContent.notifications}
        buttonFunction={headerButtonFunction}
      />
      <NotificationsListContainer>
        {loadedNotifications ? (
          <>
            {notifications && notifications.length >= 1 ? (
              notifications.map((item, index) => {
                return (
                  <Notification
                    key={index}
                    notification={item}
                    fullWidth={true}
                    onClick={notificationClick}
                  />
                );
              })
            ) : (
              <NoNotificationsContainer>
                <NoNotificationsImage
                  src={noNotifications}
                  alt="No Notifications"
                  data-image="no-notifications"
                />
                <NoNotificationsMessage>
                  No Notifications
                </NoNotificationsMessage>
              </NoNotificationsContainer>
            )}
          </>
        ) : (
          <>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => {
              return (
                <Notification
                  key={index}
                  loading="true"
                  width={notificationPlaceholders[index].width}
                  notification={index}
                  fullWidth={true}
                  onClick={null}
                />
              );
            })}
          </>
        )}
      </NotificationsListContainer>
      {count !== 0 && count > 25 && (
        <PaginationContainer>
          <Pagination
            clickNext={() => setOffset(offset + 25)}
            clickPrev={() => setOffset(offset - 25)}
            goToPage={(page) => setOffset(25 * (page - 1))}
            limit={25}
            offset={offset}
            count={count}
          />
        </PaginationContainer>
      )}
    </Page>
  );
};

const PaginationContainer = styled.div`
  margin: -40px 20px 20px 20px;
`;

const NotificationsListContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -25px 20px 60px 20px;
  }
  margin: -25px 40px 60px 40px;
`;

const NoNotificationsContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const NoNotificationsImage = styled.img`
  display: block;
  flex: 1 1 auto;
  width: 100%;
  max-width: 600px;
`;

const NoNotificationsMessage = styled.p`
  flex: 0 0 auto;
  margin: 30px 0;
  font-size: 28px;
  font-weight: ${fonts.semiBold};
  color: ${colors.paleGrey};
`;

export default NotificationsContainer;
