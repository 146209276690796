/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

const DetectModalKeys = ({ onEnter, onEsc }) => {
  const [clickedEnterId, setClickedEnterId] = useState(false);

  useHotkeys('enter, return', () => setClickedEnterId(uuidv4()), {
    capture: true,
  });

  useHotkeys('esc, escape', () => (onEsc ? onEsc() : null));

  useEffect(() => {
    if (clickedEnterId) {
      onEnter();
    }
  }, [clickedEnterId]);

  return <></>;
};

DetectModalKeys.propTypes = {
  onEnter: PropTypes.func,
  onEsc: PropTypes.func,
};

export default DetectModalKeys;
