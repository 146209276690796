/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DropZone, FormChunk } from '../../components';
import {
  setCustomLogo,
  updateCustomUserPreferences,
  updateCustomUserLogo,
  removeCustomUserLogo,
} from '../../store/actions';
import { colors, maxDevice, fonts } from '../../styles/variables';

const CustomPreferencesContainer = () => {
  const dispatch = useDispatch();
  const {
    customLogo,
    customLDD,
    customSettingsError,
    hasDiscoverySettings,
    hasDiscoverySettingsInbound,
    hasDiscoveryInboundEnabled,
  } = useSelector((state) => ({
    customLogo: state.user.customLogo,
    customLDD: state.user.customLDD,
    customSettingsError: state.user.customSettingsError,
    hasDiscoverySettings: state.user.hasDiscoverySettings,
    hasDiscoverySettingsInbound: state.user.hasDiscoverySettingsInbound,
    hasDiscoveryInboundEnabled: state.user.hasDiscoveryInboundEnabled,
  }));
  const [loadedSettings, setLoadedSettings] = useState(false);
  const [discoverySettings, setDiscoverySettings] = useState({
    discovery_redirect_url: '',
    is_enable_discovery_lead: false,
  });
  const [loadingCustomLogo, setLoadingCustomLogo] = useState(false);
  const [loadingDeleteCustomLogo, setLoadingDeleteCustomLogo] = useState(false);
  const [clearLogoFile, setClearLogoFile] = useState(false);
  const [organizationLogo, setOrganizationLogo] = useState();
  const [disclosure, setDisclosure] = useState('');
  const [disclaimer, setDisclaimer] = useState('');
  const [discovery, setDiscovery] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [discoveryText, setDiscoveryText] = useState(
    "If you're interested in Discovery, please reach out to your sales rep to discuss options."
  );
  const [hasRemoveLogo, setHasRemoveLogo] = useState(false);

  useEffect(() => {
    if (hasDiscoverySettings !== null && hasDiscoverySettingsInbound !== null) {
      if (
        hasDiscoverySettings &&
        (hasDiscoverySettingsInbound || hasDiscoveryInboundEnabled)
      ) {
        setDiscoveryText(
          'Here you can update the Discovery Disclaimer, change the Redirect URL after Discovery interview completion and toggle whether advisors inbound discovery link is enabled.'
        );
      } else if (hasDiscoverySettings) {
        setDiscoveryText(
          'Here you can update the Discovery Disclaimer and change the Redirect URL after Discovery interview completion.'
        );
      } else {
        setDiscoveryText(
          "If you're interested in Discovery, please reach out to your sales rep to discuss options."
        );
      }
    }
  }, [
    hasDiscoverySettings,
    hasDiscoverySettingsInbound,
    hasDiscoveryInboundEnabled,
  ]);

  useEffect(() => {
    if (customLDD) {
      setHasRemoveLogo(customLDD?.entitlements.includes('remove_logo'));
      setOrganizationLogo(customLDD.logo);
      // setDisclosure(customLDD.disclosure);
      // setDisclaimer(customLDD.disclaimer);
      setDiscovery(
        customLDD?.discovery_disclaimer ? customLDD.discovery_disclaimer : ''
      );
      if (customLDD.disclosure) {
        setDisclosure(customLDD.disclosure);
      } else {
        setDisclosure(customLDD?.default_texts['disclosure']);
      }
      if (customLDD.disclaimer) {
        setDisclaimer(customLDD.disclaimer);
      } else {
        setDisclaimer(customLDD?.default_texts['disclosure']);
      }
      setDiscoverySettings({
        discovery_redirect_url: customLDD?.discovery_redirect_url
          ? customLDD?.discovery_redirect_url
          : '',
        is_enable_discovery_lead: customLDD?.is_enable_discovery_lead,
      });
      setLoadingCustomLogo(false);
      setLoadingDeleteCustomLogo(false);
      setClearLogoFile(true);
      setLoadedSettings(true);
    }
  }, [customLDD]);

  useEffect(() => {
    if (customSettingsError && customSettingsError.hasOwnProperty('data')) {
      let errors = [];
      for (const [key, value] of Object.entries(customSettingsError.data)) {
        errors.push({
          field: key,
          message: value,
        });
      }
      setErrorFields(errors);
    }
  }, [customSettingsError]);

  useEffect(() => {
    if (customLogo) {
      setOrganizationLogo(customLogo);
      setClearLogoFile(false);
    }
  }, [customLogo]);

  const updateDiscoverySettings = (e) => {
    setDiscoverySettings({
      ...discoverySettings,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const updateToggle = (e) => {
    setDiscoverySettings({
      ...discoverySettings,
      [e.name]: !e.value,
    });
  };

  let disclosureInput = [];
  if (loadedSettings) {
    disclosureInput = [
      {
        name: 'disclosure',
        placeholder: 'Report Disclosure',
        type: 'textarea',
        editor: true,
        showClear: true,
        showUseDefault: true,
        defaultText: customLDD?.default_texts['disclosure'],
        id: 'disclosure',
        width: '100%',
        value: disclosure,
        onChange: (e) => setDisclosure(e),
      },
    ];
  }

  let disclaimerInput = [];
  if (loadedSettings) {
    disclaimerInput = [
      {
        name: 'disclaimer',
        placeholder: 'Report Disclaimer',
        type: 'textarea',
        editor: true,
        showClear: true,
        showUseDefault: true,
        defaultText: customLDD?.default_texts['disclaimer'],
        id: 'disclaimer',
        width: '100%',
        value: disclaimer,
        onChange: (e) => setDisclaimer(e),
      },
    ];
  }

  const checkIsAvailable = (value) => {
    if (value === 'inbound') {
      if (
        (hasDiscoverySettingsInbound || hasDiscoveryInboundEnabled) &&
        hasDiscoverySettings
      ) {
        return true;
      }
    } else {
      return hasDiscoverySettings;
    }
  };

  const discoverySettingsInputs = [
    {
      name: 'discovery',
      placeholder: 'Discovery Disclaimer',
      type: 'textarea',
      editor: true,
      showClear: true,
      id: 'discovery',
      width: '100%',
      value: discovery,
      available: checkIsAvailable(),
      tooltip:
        'Set the text that appears on the bottom of the self sign up page.',
      onChange: (e) => setDiscovery(e),
    },
    {
      name: 'discovery_redirect_url',
      label: 'Discovery Redirect URL',
      placeholder: 'https://www.asset-map.com',
      type: 'text',
      available: checkIsAvailable(),
      tooltip:
        'Set the URL to redirect to after Discovery completion. Empty field will use the Asset-Map Default.',
      width: '100%',
    },
    {
      name: 'is_enable_discovery_lead',
      theme: {},
      type: 'toggle',
      available: checkIsAvailable('inbound'),
      showLabel: true,
      label: 'Discovery Inbound Link',
      tooltip:
        'Toggle whether advisors custom inbound discovery link is active.',
      width: '100%',
      text: 'Inbound Discovery Link Enabled',
      onClick: updateToggle,
    },
  ];

  const generateCustomObj = () => {
    let obj = {
      disclosure,
      disclaimer,
      discovery_disclaimer: discovery,
      discovery_redirect_url: discoverySettings.discovery_redirect_url,
      is_enable_discovery_lead: discoverySettings.is_enable_discovery_lead,
    };
    dispatch(updateCustomUserPreferences(obj));
    setErrorFields([]);
  };

  const updateInfo = (type) => {
    if (type === 'custom') {
      return generateCustomObj();
    }
  };

  const updateUserLogo = () => {
    if (customLogo) {
      if (organizationLogo) {
        setLoadingCustomLogo(true);
        dispatch(updateCustomUserLogo({ logo: organizationLogo }));
      }
    }
  };

  const deleteCustomUserLogo = () => {
    if (hasRemoveLogo) {
      setLoadingDeleteCustomLogo(true);
      dispatch(removeCustomUserLogo());
    }
  }

  const customSettings = [
    {
      type: 'textarea',
      header: 'Report Disclosure',
      inputs: disclosureInput,
      borderBottom: false,
    },
    {
      type: 'textarea',
      header: 'Report Disclaimer',
      inputs: disclaimerInput,
      borderBottom: false,
    },
    {
      header: 'Discovery Settings',
      formText: discoveryText,
      value: discoverySettings,
      inputs: discoverySettingsInputs.filter((input) => input.available),
      onChange: updateDiscoverySettings,
      borderBottom: false,
    },
    // {
    //   type: 'textarea',
    //   header: 'Discovery Disclaimer',
    //   formText:
    //     'Set the text that appears on the bottom of the self sign up page.',
    //   inputs: discoveryDisclaimerInput,
    //   borderBottom: false,
    // },
    // {
    //   header: 'Discovery Redirect',
    //   formText:
    //     'What URL you would like users to be redirected to after completing their Discovery Interview? Keep this field blank if you want to keep the Asset-Map default.',
    //   inputs: discoveryRedirectInputs,
    //   value: discoverySettings,
    //   onChange: updateDiscoverySettings,
    //   borderBottom: false,
    // },
  ];

  return (
    <ContainerLayout>
      <LeftContent>
        {customSettings.map((form, index) => {
          return (
            <FormChunk
              key={index}
              type={form.type}
              header={form.header}
              formText={form.formText}
              inputs={form.inputs}
              borderBottom={form.borderBottom}
              errorFields={errorFields}
              value={form.value}
              onChange={form.onChange}
              onClick={form.onClick}
              button={form.button}
              style={form.style}
              extraData={form.extraData}
              headers={form.headers}
              hasErrorDisplay={form.hasErrorDisplay}
              errorMessages={form.errorMessages}
            />
          );
        })}
        <ButtonContainer>
          <Button text={'Update'} onClick={() => updateInfo('custom')} />
        </ButtonContainer>
      </LeftContent>
      <RightContent>
        <FormContainer>
          <FormHeader>Logo</FormHeader>
          <ProfilePictureContainer>
            <UploadContainer>
              <DropZone
                dispatchFunction={setCustomLogo}
                clearFiles={clearLogoFile}
              />
            </UploadContainer>
            <ModifyContainer>
              <PreviewContainer>
                {organizationLogo ? (
                  <LogoPreview
                    src={organizationLogo}
                    alt="logo"
                    data-image="organization-logo"
                  />
                ) : (
                  <LogoPlaceholder>Preview</LogoPlaceholder>
                )}
                <ButtonColumn>
                  <Button
                    text="Save Upload"
                    onClick={() => updateUserLogo()}
                    showLoading={loadingCustomLogo}
                    loadingText={'Saving'}
                  />
                  <Button
                    text="Remove"
                    onClick={deleteCustomUserLogo}
                    showLoading={loadingDeleteCustomLogo}
                    loadingText={'Deleting'}
                  />
                </ButtonColumn>
              </PreviewContainer>
            </ModifyContainer>
          </ProfilePictureContainer>
        </FormContainer>
      </RightContent>
    </ContainerLayout>
  );
};

const ProfilePictureContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
`;

const UploadContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    justify-content: center;
    width: 100%;
  }
  flex: 1 1 auto;
  width: 250px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid ${colors.lightGrey};
`;

const ModifyContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    margin-bottom: 40px;
  }
  flex: 1 1 auto;
  width: 200px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const PreviewContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    margin-left: 0px;
  }
  margin-left: 30px;
  padding: 0 0 20px 0;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const LogoPreview = styled.img`
  width: 100%;
  max-height: 150px;
  max-width: 275px;
  margin-bottom: 20px;
`;

const LogoPlaceholder = styled.div`
  height: 150px;
  width: 150px;
  margin-bottom: 30px;
  background: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.lightGrey};
  color: ${colors.paleGrey};
`;

const ButtonColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  button {
    width: 150px;
    margin-bottom: 8px;
    background: ${colors.paleGrey};
  }
`;

const ContainerLayout = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  margin: -25px 0px 0px 0px;
  background: ${colors.lighterGrey};
`;

const LeftContent = styled.div`
  @media ${maxDevice.laptopL} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }
  @media ${maxDevice.sideNav} {
    padding: 20px 20px 100px 20px;
    overflow-x: hidden;
    min-height: 500px;
  }
  flex: 1 1 auto;
  min-height: 800px;
  max-width: 50%;
  min-width: 50%;
  background: white;
  padding: 30px 40px 100px 40px;
`;

const FormContainer = styled.div`
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  span {
    flex: 1 1 auto;
  }
  p {
    flex: 0 0 auto;
    font-size: 11px;
    color: ${colors.paleGrey};
    cursor: pointer;
  }
`;

const RightContent = styled.div`
  @media ${maxDevice.laptop} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  @media ${maxDevice.sideNav} {
    padding: 20px;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  flex: 1 1 auto;
  max-width: 50%;
  padding: 30px 40px 20px 40px;
  /* position: relative; */
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 130px;
  }
`;

export default CustomPreferencesContainer;
