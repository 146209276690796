/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { GenerateInput, Button, Error, DetectModalKeys } from '..';
import {
  createTargetMapInstrument,
  updateTargetMapInstrument,
  toggleShowDeleteModal,
} from '../../store/actions';
import { timesWhite, trashDark } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
  ModalIcon,
} from '../../styles/library/modalStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  MatchSelectItem,
  CheckHasError,
  GenerateError,
  UseHandleInstrumentError,
  CreateAmountPlaceholder,
  CheckOverwritten,
  HtmlToString,
  DetectEnterKeyPress,
  DateFormat,
} from '../../utils';

const CustomCashFlowModal = ({
  hide,
  isNew,
  householdId,
  instrumentInfo,
  targetMapId,
  currency,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    currentHousehold,
    targetMapInstrumentError,
    instrumentDateReference,
    preferences,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    targetMapInstrumentError: state.targetMaps.targetMapInstrumentError,
    instrumentDateReference: state.configs.instrumentDateReference,
    preferences: state.user.user.preferences,
  }));
  const [selectedMember, setSelectedMember] = useState();
  const [dateType, setDateType] = useState({ label: 'Age', value: 'age' });
  const [currentCashFlow, setCurrentCashFlow] = useState({
    name: '',
    description: '',
    amount: '',
    start_age: '',
    end_age: '',
    start_date: '',
    end_date: '',
    inflation_rate: '',
    tax_rate: '',
    is_future_value: false,
    is_fixed_duration: false,
    is_active: true,
  });
  const [membersList, setMembersList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();

  UseHandleInstrumentError(
    targetMapInstrumentError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle
  );

  useEffect(() => {
    if (instrumentInfo) {
      setDateType(
        MatchSelectItem(instrumentDateReference, instrumentInfo.date_reference)
      );
      setSelectedMember(instrumentInfo.memberObj);
      setCurrentCashFlow(instrumentInfo);
    }
  }, [instrumentInfo, currentHousehold]);

  useEffect(() => {
    if (isNew) {
      setSelectedMember(membersList[0]);
    }
  }, [isNew, membersList]);

  useEffect(() => {
    if (
      currentHousehold &&
      currentHousehold.hasOwnProperty('householdmembers')
    ) {
      const householdMembers = [...currentHousehold.householdmembers];
      setMembersList(
        householdMembers.filter((mem) => {
          if (mem.member_type === 'member') {
            const updatedMemberName = HtmlToString(mem.name);
            mem.label = `${updatedMemberName} (${mem.age})`;
            mem.value = mem.id;
            return mem;
          }
        })
      );
    }
  }, [currentHousehold]);

  const checkHasValue = (value) => {
    if (value === '' || value === null || value === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const saveOnEnter = () => {
    if (!buttonLoading) {
      return saveCashFlow();
    }
  };

  const saveCashFlow = () => {
    setErrorTitle();
    // if (!checkHasValue(currentCashFlow.amount)) {
    //   setModalErrors({
    //     ...modalErrors,
    //     amount: ['A valid integer is required.'],
    //   });
    //   return setModalError('A valid integer is required.');
    // }
    let amountValue = parseInt(currentCashFlow.amount);

    let inflationRate = 0;
    if (
      currentCashFlow.inflation_rate !== null &&
      currentCashFlow.inflation_rate !== ''
    ) {
      inflationRate = parseInt(currentCashFlow.inflation_rate);
    }
    let taxRate = 0;
    if (currentCashFlow.tax_rate !== null && currentCashFlow.tax_rate !== '') {
      taxRate = parseInt(currentCashFlow.tax_rate);
    }

    let instrumentObject = {
      name: currentCashFlow.name,
      description: currentCashFlow.description,
      member: selectedMember.value,
      amount: amountValue,
      is_future_value: currentCashFlow.is_future_value,
      date_reference: dateType.value,
      tax_rate: taxRate,
      inflation_rate: inflationRate,
      is_active: currentCashFlow.is_active,
    };

    if (dateType.value === 'age') {
      if (checkHasValue(currentCashFlow.start_age)) {
        instrumentObject.start_age = currentCashFlow.start_age;
      }

      if (checkHasValue(currentCashFlow.end_age)) {
        instrumentObject.end_age = currentCashFlow.end_age;
      }
    }
    if (dateType.value === 'date') {
      if (checkHasValue(currentCashFlow.start_date)) {
        instrumentObject.start_date = DateFormat(
          currentCashFlow.start_date,
          'YYYY-MM-DD'
        );
      }
      if (checkHasValue(currentCashFlow.end_date)) {
        instrumentObject.end_date = DateFormat(
          currentCashFlow.end_date,
          'YYYY-MM-DD'
        );
      }
    }

    setButtonLoading(true);
    if (isNew) {
      dispatch(
        createTargetMapInstrument(
          householdId,
          targetMapId,
          'income',
          instrumentObject
        )
      );
    } else {
      dispatch(
        updateTargetMapInstrument(
          householdId,
          targetMapId,
          'income',
          currentCashFlow.id,
          instrumentObject
        )
      );
    }
  };

  const updateCurrentCashFlow = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors();
    if (isSelect) {
      setCurrentCashFlow({
        ...currentCashFlow,
        [fieldName]: e,
      });
    } else {
      setCurrentCashFlow({
        ...currentCashFlow,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const checkWidth = () => {
    if (!checkDisabled()) {
      return '68%';
    } else {
      return '100%';
    }
  };

  const checkDisabled = () => {
    if (instrumentInfo) {
      if (instrumentInfo.hasOwnProperty('instrument')) {
        if (
          instrumentInfo.instrument === null ||
          instrumentInfo.instrument === undefined ||
          instrumentInfo.instrument === ''
        ) {
          return false;
        }
        return true;
      }
    } else {
      return false;
    }
  };

  const formatFunction = (values, input) => {
    setModalError('');
    setModalErrors();
    let setValue;
    if (values.hasOwnProperty('floatValue')) {
      setValue = values.floatValue;
    } else if (values.currentTarget?.value) {
      setValue = values.currentTarget.value;
    }

    if (setValue === 'NaN') {
      setValue = '';
    }

    setCurrentCashFlow({
      ...currentCashFlow,
      [input.name]: setValue,
    });
  };

  const essentialInputs = [
    {
      type: 'text',
      label: 'Name',
      name: 'name',
      required: true,
      placeholder: 'Name',
      value: currentCashFlow.name,
      width: '48%',
      disabled: checkDisabled(),
      isVisible: true,
      onChange: (e) => updateCurrentCashFlow(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'name'),
      errorMessage: GenerateError(modalErrors, 'name'),
    },
    {
      type: 'text',
      label: 'Description',
      maxLength: '120',
      name: 'description',
      width: '48%',
      required: false,
      placeholder: 'Description',
      disabled: checkDisabled(),
      isVisible: true,
      value: currentCashFlow.description,
      onChange: (e) => updateCurrentCashFlow(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'description'),
      errorMessage: GenerateError(modalErrors, 'description'),
    },
    {
      type: 'select',
      label: 'Link to Member',
      name: 'member',
      required: false,
      placeholder: 'Link to Member',
      value: selectedMember,
      options: membersList,
      width: '100%',
      isVisible: true,
      onChange: (e) => setSelectedMember(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'member'),
      errorMessage: GenerateError(modalErrors, 'member'),
    },
    {
      type: 'calculatorCurrency',
      label: CheckOverwritten(isNew, instrumentInfo)
        ? 'Amount (Overwritten)'
        : 'Amount',
      maxLength: '80',
      name: 'amount',
      width: checkWidth(),
      required: false,
      placeholder: isNew
        ? 'Annual Amount Available'
        : CreateAmountPlaceholder(
            instrumentInfo,
            currency,
            'Annual Amount Available'
          ),
      isVisible: true,
      value: currentCashFlow.amount,
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'amount'),
      errorMessage: GenerateError(modalErrors, 'amount'),
    },
    {
      type: 'checkbox',
      label: 'Future Value',
      name: 'is_future_value',
      required: false,
      width: '28%',
      value: currentCashFlow.is_future_value,
      isVisible: !checkDisabled(),
      onChange: () =>
        setCurrentCashFlow({
          ...currentCashFlow,
          is_future_value: !currentCashFlow.is_future_value,
        }),
      // hasError: layoutNameFieldError,
    },
    {
      type: 'select',
      label: 'Date Reference',
      name: 'date_reference',
      width: '100%',
      required: false,
      placeholder: 'Date Reference',
      value: dateType,
      options: instrumentDateReference,
      isVisible: true,
      onChange: (e) => setDateType(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'date_reference'),
      errorMessage: GenerateError(modalErrors, 'date_reference'),
    },

    {
      type: 'number',
      label: 'Start Age',
      maxLength: '80',
      name: 'start_age',
      width: '48%',
      required: false,
      placeholder: 'Start Age',
      value: currentCashFlow.start_age,
      isVisible: dateType.value === 'age',
      onChange: (e) => updateCurrentCashFlow(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'start_age'),
      errorMessage: GenerateError(modalErrors, 'start_age'),
    },
    {
      type: 'date',
      label: 'Start Date',
      name: 'start_date',
      width: '48%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentCashFlow.start_date,
      isVisible: dateType.value === 'date',
      onChange: (e) => updateCurrentCashFlow(e, true, 'start_date'),
      hasError: CheckHasError(modalErrors, 'start_date'),
      errorMessage: GenerateError(modalErrors, 'start_date'),
    },
    {
      type: 'number',
      label: 'End Age',
      maxLength: '80',
      name: 'end_age',
      width: '48%',
      required: false,
      placeholder: 'End age',
      value: currentCashFlow.end_age,
      isVisible: dateType.value === 'age',
      onChange: (e) => updateCurrentCashFlow(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'end_age'),
      errorMessage: GenerateError(modalErrors, 'end_age'),
    },
    {
      type: 'date',
      label: 'End Date',
      name: 'end_date',
      width: '48%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentCashFlow.end_date,
      isVisible: dateType.value === 'date',
      onChange: (e) => updateCurrentCashFlow(e, true, 'end_date'),
      hasError: CheckHasError(modalErrors, 'end_date'),
      errorMessage: GenerateError(modalErrors, 'end_date'),
    },
    {
      type: 'number',
      label: 'Adjustment (COLA)',
      maxLength: '2',
      name: 'inflation_rate',
      width: '48%',
      required: false,
      hasSuffix: true,
      suffixValue: '%',
      placeholder: 'COLA',
      tooltip: `Cost of Living Adjustment: Use this box to update/change the desired COLA for this cash flow. If this field is left blank, the COLA will automatically be set to 0%.`,
      value: currentCashFlow.inflation_rate,
      isVisible: true,
      onChange: (e) => updateCurrentCashFlow(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'inflation_rate'),
      errorMessage: GenerateError(modalErrors, 'inflation_rate'),
    },
    {
      type: 'number',
      label: 'Tax Rate',
      maxLength: '2',
      name: 'tax_rate',
      width: '48%',
      required: false,
      hasSuffix: true,
      suffixValue: '%',
      placeholder: 'Tax Rate',
      tooltip: `Percentage lost to taxes.`,
      value: currentCashFlow.tax_rate,
      isVisible: true,
      onChange: (e) => updateCurrentCashFlow(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'tax_rate'),
      errorMessage: GenerateError(modalErrors, 'tax_rate'),
    },
    {
      type: 'checkbox',
      label: 'Included in this Target-Map',
      name: 'is_active',
      required: false,
      width: '48%',
      value: currentCashFlow.is_active,
      isVisible: true,
      onChange: () =>
        setCurrentCashFlow({
          ...currentCashFlow,
          is_active: !currentCashFlow.is_active,
        }),
      // hasError: layoutNameFieldError,
    },
  ];

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={saveOnEnter} onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {isNew
                ? 'Add Target-Map Custom Cash Flow'
                : 'Edit Target-Map Custom Cash Flow'}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <TabContent>
              {essentialInputs.map((input, index) => {
                return GenerateInput(input, index, currency);
              })}
            </TabContent>
            <ButtonsRowContainer>
              {isNew || currentCashFlow.instrument !== null ? null : (
                <ModalIcon
                  src={trashDark}
                  alt="delete"
                  data-image="delete"
                  onClick={() =>
                    dispatch(
                      toggleShowDeleteModal(
                        true,
                        'targetmapIncome',
                        currentCashFlow.name,
                        targetMapId,
                        currentCashFlow.id
                      )
                    )
                  }
                />
              )}
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={isNew ? 'Create' : 'Update'}
                  onClick={() => saveCashFlow()}
                  showLoading={buttonLoading}
                  loadingText={isNew ? 'Creating' : 'Saving'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

CustomCashFlowModal.propTypes = {
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  householdId: PropTypes.string,
  instrumentInfo: PropTypes.object,
  targetMapId: PropTypes.string,
  currency: PropTypes.object,
};

export default CustomCashFlowModal;
