import cogoToast from 'cogo-toast';
const defaultPosition = 'bottom-left';
const defaultHideAfter = 2.5;
const borderWidth = '5px';

//GENERAL TOAST NOTIFICATION WHERE YOU CAN PASS IN ALL OPTIONS
export const ToastNotification = (
  type = 'success',
  text = 'Updated',
  hideAfter = defaultHideAfter,
  position = defaultPosition,
  style = { size: borderWidth, style: 'solid' }
) => {
  return cogoToast[type](text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
  });
};

//SUCCESS TOAST NOTIFICATION WHERE YOU CAN PASS IN CUSTOM OPTIONS
export const SuccessNotification = (
  text = 'Updated',
  hideAfter = defaultHideAfter,
  position = defaultPosition,
  style = { size: borderWidth, style: 'solid' }
) => {
  return cogoToast.success(text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
  });
};

//SUCCESS TOAST NOTIFICATION WHERE YOU CAN DO ACTION ON CLICK THEN HIDE
export const SuccessNotificationClick = (
  text = 'Updated',
  onClick,
  hideAfter = 0,
  position = defaultPosition,
  style = { size: borderWidth, style: 'solid' }
) => {
  const { hide } = cogoToast.success(text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
    onClick: () => {
      onClick();
      return hide();
    },
  });
};

//ERROR TOAST NOTIFICATION WHERE YOU CAN PASS IN CUSTOM OPTIONS
export const ErrorNotification = (
  text = 'Error',
  hideAfter = defaultHideAfter,
  position = defaultPosition,
  style = { size: borderWidth, style: 'solid' }
) => {
  return cogoToast.error(text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
  });
};

//INFO TOAST NOTIFICATION WHERE YOU CAN PASS IN CUSTOM OPTIONS
export const InfoNotification = (
  text = 'Info',
  hideAfter = defaultHideAfter,
  position = defaultPosition,
  style = { size: borderWidth, style: 'solid' }
) => {
  return cogoToast.info(text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
  });
};

//SUCCESS TOAST NOTIFICATION WHERE YOU CAN DO ACTION ON CLICK THEN HIDE
export const InfoNotificationClick = (
  text = 'Updated',
  onClick,
  hideAfter = 0,
  position = defaultPosition,
  role = 'status',
  style = { size: borderWidth, style: 'solid' }
) => {
  const { hide } = cogoToast.info(text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
    role: role,
    onClick: () => {
      onClick();
      return hide();
    },
  });
};

//LOADING TOAST NOTIFICATION WHERE YOU CAN PASS IN CUSTOM OPTIONS
export const LoadingNotification = (
  text = 'Loading',
  hideAfter = defaultHideAfter,
  position = defaultPosition,
  style = { size: borderWidth, style: 'solid' }
) => {
  return cogoToast.loading(text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
  });
};

//WARN TOAST NOTIFICATION WHERE YOU CAN PASS IN CUSTOM OPTIONS
export const WarnNotification = (
  text = 'Warn',
  hideAfter = defaultHideAfter,
  position = defaultPosition,
  style = { size: borderWidth, style: 'solid' }
) => {
  return cogoToast.warn(text, {
    hideAfter: hideAfter,
    position: position,
    bar: style,
  });
};
