import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

const FlyOut = ({ theme, navWidth }) => {
  return (
    <ThemeProvider theme={theme}>
      <FlyOutMenu style={{ left: navWidth }}>hi</FlyOutMenu>
    </ThemeProvider>
  );
};

FlyOut.propTypes = {
  theme: PropTypes.object,
  navWidth: PropTypes.string,
};

const FlyOutMenu = styled.div`
  background: white;
  padding: 10px;
  position: absolute;
  top: 45px;
  display: none;
`;

export default FlyOut;
