import styled, { keyframes } from 'styled-components';
import Pluralize from 'react-pluralize';
import PropTypes from 'prop-types';
import { arrowBack, timesPaleGrey } from '../assets';
import { TextInput } from './';
import { fonts, colors, boxShadows } from '../styles/variables';
import { InputThemes } from '../styles/themes';

const BulkActions = ({
  show,
  count,
  actions = [],
  closeMenu,
  search = {
    showingSearch: false,
    closeSearch: null,
    goBack: null,
    searchValue: '',
    searchOnChange: null,
  },
  right = '0px',
}) => {
  const { showingSearch, goBack, searchValue, searchOnChange } = search;
  const showBackButton = actions.length >= 1;
  return (
    show && (
      <BulkActionsContainer $right={right}>
        {showingSearch ? (
          <>
            {showBackButton && (
              <ActionContainer>
                <ActionIcon
                  src={arrowBack}
                  alt={'back arrow'}
                  title={'Back'}
                  onClick={goBack}
                  style={{ margin: '0' }}
                  data-image="bulk-action-back-arrow"
                />
              </ActionContainer>
            )}

            <SelectedCount>
              <Pluralize singular={'match'} plural="matches" count={count} />
            </SelectedCount>
            <TextInput
              type="text"
              value={searchValue}
              placeholder="Search financials by name or location"
              onChange={(e) => searchOnChange(e)}
              theme={InputThemes.bulkSearch}
              autoFocus={true}
            />
          </>
        ) : (
          <>
            <SelectedCount>{count} selected</SelectedCount>
            {actions.map((action, index) => {
              return (
                <ActionContainer key={index} onClick={() => action.onClick()}>
                  <ActionIcon
                    src={action.icon}
                    alt={action.value}
                    width={action.iconWidth}
                    title={action.title}
                    data-image={`bulk-action-${action.value}`}
                  />
                  <ActionText>{action.label}</ActionText>
                </ActionContainer>
              );
            })}
          </>
        )}
        <ActionContainer>
          <ActionIcon
            src={timesPaleGrey}
            alt={'close'}
            title={'Close'}
            onClick={closeMenu}
            style={{ margin: '0 0 0 5px' }}
            width={'10px'}
          />
        </ActionContainer>
      </BulkActionsContainer>
    )
  );
};

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
    img {
      transform: scale(1.05);
    }
  }
`;

const ActionText = styled.p`
  font-size: 10px;
  font-weight: ${fonts.regular};
`;

const ActionIcon = styled.img`
  width: ${(props) => (props.width ? props.width : '13px')};
  height: 13px;
  margin-right: 5px;
`;

const fadeInRight = (right) => keyframes`
  from {
    right: 0;
    top: 80px;
  }
  to {
    right: calc(${right} + 25px);
    top: 80px;
  }
`;

const BulkActionsContainer = styled.div`
  position: fixed;
  z-index: 6;
  right: ${(props) => `calc(${props.$right} + 25px)`};
  top: 80px;
  padding: 10px 15px;
  background: white;
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  box-shadow: ${boxShadows.boxShadowSoft};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 22px;
  font-weight: ${fonts.bold};
  transform: scale(1);
  animation: ${fadeInRight} 0.3s ease-in-out;
  animation: ${(props) => fadeInRight(props.$right)} 0.3s ease-in-out;
  ${ActionContainer} {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  input {
    width: 200px;
    margin-right: 8px;
  }
`;

const SelectedCount = styled.p`
  margin-right: 10px;
  text-align: center;
  span {
    text-align: center;
    min-width: 72px;
    display: inline-block;
  }
`;

BulkActions.propTypes = {
  show: PropTypes.bool,
  count: PropTypes.number,
  actions: PropTypes.array,
  closeMenu: PropTypes.func,
  search: PropTypes.object,
  right: PropTypes.string,
};

export default BulkActions;
