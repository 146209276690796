import { HouseholdIndexContainer } from '../../containers';
import { UpdatePageTitle, CloseDropdowns } from '../../utils';

const HouseholdIndex = () => {
  UpdatePageTitle('Households');
  CloseDropdowns();

  return <HouseholdIndexContainer />;
};

export default HouseholdIndex;
