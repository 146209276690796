/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, fonts, boxShadows } from '../styles/variables';
import { ImageIcon } from '../styles/library/imageStyles';
import {
  ellipsisH,
  sync,
  // gripLines
} from '../assets';
import PropTypes from 'prop-types';
import { UseOutsideClick } from '../utils';
import { Pagination } from './';

const Panel = ({ item, transform, showingMenu, toggleMenu, household }) => {
  const ref = useRef();

  UseOutsideClick(ref, () => {
    if (showingMenu) {
      toggleMenu(item);
    }
  });

  return (
    <ChildContainer
      style={{
        opacity: item.disabled ? 0.5 : null,
        cursor: item.disabled ? 'not-allowed' : null,
        backgroundImage: item.disabled
          ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238a888e' fill-opacity='0.3' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
          : null,
      }}
      data-panel={item?.value}
    >
      <ChildContainerHeader>
        <Header data-heading={item?.heading}>
          {item.disabled || item.noLink ? (
            <HeaderLabelText>{item.heading}</HeaderLabelText>
          ) : item.fileStorage ? (
            <ImageHeaderLabel>
              <HeaderIcon
                src={item.icon}
                alt={item.value}
                data-image={item.value}
              />
              {/* <span onClick={item.onClick}>{item.heading}</span> */}
              <a
                href={`/api/v3/household/${household.uuid}/integration/${item.value}/go`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.heading}
              </a>
            </ImageHeaderLabel>
          ) : (
            <HeaderLabel to={item.link}>{item.heading}</HeaderLabel>
          )}
        </Header>
        {/* <GrabIcon>
          <ImageIcon src={gripLines} alt="grip" />
        </GrabIcon> */}
        <MenuIcon>
          {item.menuItems && (
            <ImageIcon
              src={ellipsisH}
              alt="menu"
              data-panel-menu-button={item?.value}
              onClick={() => toggleMenu(item)}
              data-image={item?.value}
            />
          )}
        </MenuIcon>
        {item.menuItems && (
          <DropdownContainer
            ref={ref}
            data-panel-dropdown={item?.value}
            style={showingMenu ? { display: 'block' } : { display: 'none' }}
          >
            <DropdownHeading>Actions</DropdownHeading>
            <DropDownContent>
              {item.menuItems.map((menuItem, index) => {
                return menuItem.link ? (
                  <DropdownItemLink key={index} to={menuItem.link}>
                    {menuItem.image && (
                      <DropdownItemImage
                        src={menuItem.image}
                        alt={menuItem.label}
                        data-image={menuItem.name}
                      />
                    )}
                    <DropdownItemText>{menuItem.label}</DropdownItemText>
                  </DropdownItemLink>
                ) : (
                  <DropdownItem key={index} onClick={menuItem.onClick}>
                    {menuItem.image && (
                      <DropdownItemImage
                        src={menuItem.image}
                        alt={menuItem.label}
                        data-image={menuItem.name}
                      />
                    )}
                    <DropdownItemText>{menuItem.label}</DropdownItemText>
                  </DropdownItem>
                );
              })}
            </DropDownContent>
          </DropdownContainer>
        )}
      </ChildContainerHeader>
      <ChildContainerContent data-panel-content={item?.value}>
        <div
          style={{
            opacity: item.refreshing ? '.5' : '1',
            minHeight: item.value === 'assetMap' ? '180px' : null,
          }}
        >
          {transform(item)}
        </div>
        {item.refreshing && (
          <RefreshDisplay>
            <SyncImage src={sync} alt="sync" data-image="sync" />
            Refreshing
          </RefreshDisplay>
        )}
      </ChildContainerContent>
      {item?.footer?.isVisible && (
        <ChildContainerFooter>
          {item?.footer?.image && (
            <SyncIcon src={item.footer.image} alt="sync" data-image="sync" />
          )}
          {item.footer.text}
        </ChildContainerFooter>
      )}
      {item.pagination && (
        <PanelPagination>
          <Pagination
            clickPrev={item.pagination.prev}
            clickNext={item.pagination.next}
            limit={item.pagination.limit}
            offset={item.pagination.offset}
            count={item.pagination.total}
            more={item.pagination.more}
          />
        </PanelPagination>
      )}
    </ChildContainer>
  );
};

const RefreshDisplay = styled.div`
  position: absolute;
  /* text-transform: uppercase; */
  text-align: center;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  padding: 5px 15px;
  border-radius: 50px;
  top: 20px;
  z-index: 2;
  border: thin solid ${colors.hoverLightGrey};
  box-shadow: ${boxShadows.boxShadowSoft};
  background: ${colors.lightGrey};
  color: ${colors.darkGrey};
  /* transform: translateY(-50%); */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const SyncImage = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  animation: ${rotation} 1.5s infinite ease-in-out;
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 99;
  background: white;
  width: 60px;
  right: 4px;
  top: 0;
  margin-top: 32px;
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  width: 145px;
  box-shadow: ${boxShadows.boxShadow};
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: 13px;
    top: -6px;
    z-index: 9999 !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${colors.darkGrey};
  }
`;

const DropdownHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  padding: 8px 12px;
  background: ${colors.darkGrey};
  color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropDownContent = styled.div`
  padding: 10px 12px;
`;

const DropdownItem = styled.div`
  margin: 8px 0;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    opacity: 0.75;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const DropdownItemLink = styled(Link)`
  margin: 8px 0;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.darkGrey};
  &:hover {
    opacity: 0.75;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const DropdownItemImage = styled.img`
  width: 16px;
  height: 16px;
  max-height: 14px;
  margin-right: 7px;
`;

const DropdownItemText = styled.p``;

const ChildContainer = styled.div`
  margin-bottom: 15px;
  border: 1px solid ${colors.paleGrey};
  border-radius: 5px;
`;

const ChildContainerHeader = styled.div`
  background: ${colors.lightGrey};
  border-radius: 5px 5px 0 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  position: relative;
  img {
    width: 18px;
    height: 18px;
  }
`;

const Header = styled.div`
  flex: 1 1 33%;
  text-align: left;
`;

const HeaderLabel = styled(Link)`
  color: ${colors.darkGrey};
  text-align: left;
  font-weight: ${fonts.semiBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImageHeaderLabel = styled.div`
  text-align: left;
  font-weight: ${fonts.semiBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  a {
    color: ${colors.darkGrey};
  }
  span {
    flex: 0 0 auto;
    cursor: pointer;
  }
`;

const HeaderIcon = styled.img`
  width: 22px;
  height: 22px;
  flex: 0 0 auto;
  margin-right: 8px;
`;

const HeaderLabelText = styled.span`
  color: ${colors.darkGrey};
  text-align: left;
  font-weight: ${fonts.semiBold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// const GrabIcon = styled.div`
//   flex: 0 0 auto;
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   cursor: grab;
// `;

const MenuIcon = styled.div`
  flex: 1 1 33%;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
`;

const ChildContainerContent = styled.div`
  padding: 5px 15px;
  position: relative;
`;

const ChildContainerFooter = styled.div`
  padding: 10px 15px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.lightGrey};
  margin: 0 12px;
  color: ${colors.midGrey};
  font-weight: 600;
`;

const SyncIcon = styled.img`
  margin-right: 10px;
  width: 12px;
  height: 12px;
  opacity: 0.6;
`;

const PanelPagination = styled.div`
  padding: 5px 15px 10px 15px;
  font-size: 13px;
  div {
    margin: 0;
    justify-content: flex-start;
    div:first-child {
      margin-top: 0;
      margin-left: 0;
    }
    div:nth-child(2),
    div:nth-child(3) {
      margin-left: auto;
      margin-top: 0;
    }
  }
`;

Panel.propTypes = {
  item: PropTypes.object,
  transform: PropTypes.func,
  showingMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
  household: PropTypes.object,
};

export default Panel;
