import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { GenerateInput, LoadingAnimation } from './';
import {
  toggleShowCashFlowModal,
  toggleShowAssetModal,
  toggleShowLiabilityModal,
  toggleShowInsuranceModal,
  updateAssetMapProposal,
  getAssetMapProposal,
  updateHouseholdStoreValue,
  getAssetMapProposals,
} from '../store/actions';
import {
  lightBlueCirclePlus,
  pinkCirclePlus,
  orangeCirclePlus,
  blueCirclePlus,
  timesPaleGrey,
  info,
  eye,
  eyeClosed,
  notePad,
  timesDarkGrey,
  swap,
  // save,
} from '../assets';
import { colors, boxShadows, fonts, inputColors } from '../styles/variables';
import { DRAFTS_SUPPORT as supportLink } from '../utils/constants';
import { DetectEnterKeyPress, UseDoubleClick, UseOutsideClick } from '../utils';

const ProposalBar = ({
  sideNavWidth,
  sidebarWidth,
  amrShowLegend,
  proposal,
  hide,
  householdId,
}) => {
  const dispatch = useDispatch();
  const nameRef = useRef();
  const inputRef = useRef();
  const menuRef = useRef();
  const notesRef = useRef();
  const {
    updatedProposal,
    assetMapProposalError,
    assetMapProposals,
    localHousehold,
    proposalHousehold,
    updatedProposalInstrument,
  } = useSelector((state) => ({
    updatedProposal: state.households.updatedProposal,
    assetMapProposalError: state.households.assetMapProposalError,
    assetMapProposals: state.households.assetMapProposals,
    localHousehold: state.households.localHousehold,
    proposalHousehold: state.households.proposalHousehold,
    updatedProposalInstrument: state.instruments.updatedProposalInstrument,
  }));
  const [proposalName, setProposalName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [updatingProposalName, setUpdatingProposalName] = useState(false);
  const [showChangeProposal, setShowChangeProposal] = useState(false);
  const [showProposalList, setShowProposalList] = useState(false);
  const [proposalList, setProposalList] = useState([]);
  const [showProposalNotes, setShowProposalNotes] = useState(false);
  const [proposalNotes, setProposalNotes] = useState('');
  const [updatingProposalNotes, setUpdatingProposalNotes] = useState(false);
  const [isShowingChanges, setIsShowingChanges] = useState(true);

  const centerItems = [
    {
      label: 'Cash Flow',
      icon: lightBlueCirclePlus,
      value: 'cash flow',
      onClick: () => dispatch(toggleShowCashFlowModal(true, true)),
    },
    {
      label: 'Asset',
      icon: pinkCirclePlus,
      value: 'asset',
      onClick: () => dispatch(toggleShowAssetModal(true, true)),
    },
    {
      label: 'Liability',
      icon: orangeCirclePlus,
      value: 'liability',
      onClick: () => dispatch(toggleShowLiabilityModal(true, true)),
    },
    {
      label: 'Insurance',
      icon: blueCirclePlus,
      value: 'insurance',
      onClick: () => dispatch(toggleShowInsuranceModal(true, true)),
    },
  ];

  UseOutsideClick(menuRef, () => {
    if (showProposalList) {
      setShowProposalList(false);
    }
  });

  // UseOutsideClick(notesRef, () => {
  //   if (showProposalNotes) {
  //     setShowProposalNotes(false);
  //   }
  // });

  useEffect(() => {
    return () => {
      updateHouseholdStoreValue('localHousehold', null);
      updateHouseholdStoreValue('proposalHousehold', null);
    };
  }, []);

  useEffect(() => {
    if (assetMapProposals) {
      setShowChangeProposal(assetMapProposals.count > 1);
      setProposalList(
        assetMapProposals?.results.filter((prop) => prop.id !== proposal.id)
      );
      setShowProposalList(false);
    }
  }, [assetMapProposals, proposal?.id]);

  useEffect(() => {
    if (updatedProposalInstrument) {
      setIsShowingChanges(true);
    }
  }, [updatedProposalInstrument]);

  useEffect(() => {
    if (proposal?.name) {
      setProposalName(proposal?.name);
    }
  }, [proposal?.name]);

  useEffect(() => {
    if (proposal?.description !== null) {
      setProposalNotes(proposal?.description);
    }
  }, [proposal?.description]);

  useEffect(() => {
    if (assetMapProposalError) {
      setIsEditingName(false);
      setProposalName(proposal?.name);
      setUpdatingProposalName(false);
      setUpdatingProposalNotes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetMapProposalError]);

  useEffect(() => {
    if (updatedProposal) {
      setIsEditingName(false);
      setUpdatingProposalName(false);
      setUpdatingProposalNotes(false);
      setIsShowingChanges(true);
      dispatch(getAssetMapProposals(householdId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProposal]);

  useEffect(() => {
    if (isEditingName) {
      inputRef.current.focus();
    }
  }, [isEditingName]);

  UseDoubleClick({
    onDoubleClick: (e) => {
      setIsEditingName(true);
    },
    ref: nameRef,
  });

  const updateProposalName = () => {
    if (isEditingName) {
      const originalName = proposal?.name.trim();
      const newName = proposalName.trim();
      const changedName = originalName !== newName;
      if (changedName) {
        setUpdatingProposalName(true);
        setIsEditingName(false);
        return dispatch(
          updateAssetMapProposal(
            householdId,
            {
              id: proposal.id,
              name: newName,
            },
            false
          )
        );
      }
      setIsEditingName(false);
    }
  };

  const updateProposalNotes = () => {
    if (showProposalNotes) {
      const changedNotes = proposal?.description !== proposalNotes;
      if (changedNotes) {
        setUpdatingProposalNotes(true);
        dispatch(
          updateAssetMapProposal(
            householdId,
            {
              id: proposal.id,
              name: proposalName,
              description: proposalNotes,
            },
            false
          )
        );
      }
      setShowProposalNotes(false);
    }
  };

  const proposalOnClick = (proposal) => {
    dispatch(getAssetMapProposal(householdId, proposal?.id));
    setIsShowingChanges(true);
  };

  const toggleViewChanges = () => {
    //If they want to hide changes - switch currentHousehold field to use saved real household
    //If they want to show changes - switch currentHousehold field to use saved proposal household
    dispatch(
      updateHouseholdStoreValue(
        'currentHousehold',
        isShowingChanges ? localHousehold : proposalHousehold
      )
    );
    setIsShowingChanges(!isShowingChanges);
  };

  return (
    <ProposalBarContainer
      $sideNavWidth={sideNavWidth}
      $sidebarWidth={sidebarWidth}
      $amrShowLegend={amrShowLegend}
    >
      <LeftContent>
        <InfoLink href={supportLink} target="_blank" rel="noreferrer">
          <img
            src={info}
            alt="info"
            data-image="info"
            title="Drafts Support Article"
          />
        </InfoLink>
        <ProposalNameContainer>
          <ProposalNameInput
            ref={inputRef}
            type="text"
            maxLength="56"
            value={proposalName}
            name="name"
            onChange={(e) => setProposalName(e.currentTarget.value)}
            onBlur={() => updateProposalName()}
            onKeyPress={(e) => DetectEnterKeyPress(e, updateProposalName)}
            visible={isEditingName}
          />
          <ProposalName
            ref={nameRef}
            visible={!isEditingName}
            title={`${proposal?.name} (Double click to edit)`}
          >
            {proposal?.name}
          </ProposalName>
          {updatingProposalName && (
            <span style={{ marginLeft: '5px' }}>
              <LoadingAnimation
                dots={true}
                smaller={true}
                color={colors.paleGrey}
              />
            </span>
          )}
        </ProposalNameContainer>
      </LeftContent>
      <CenterContent>
        {centerItems.map((item, index) => {
          return (
            <AddInstrumentContainer
              key={index}
              onClick={item.onClick}
              data-value={item.value}
            >
              <img src={item.icon} alt={item.value} />
              <p>{item.label}</p>
            </AddInstrumentContainer>
          );
        })}
      </CenterContent>
      <RightContent>
        <ListContainer>
          <ActionContainer onClick={() => setShowProposalNotes(true)}>
            {updatingProposalNotes && (
              <span style={{ marginBottom: '3px' }}>
                <LoadingAnimation
                  dots={true}
                  smaller={true}
                  color={colors.paleGrey}
                />
              </span>
            )}
            <ActionIcon src={notePad} alt="notes" data-image="notes" />
            <ActionText>Notes</ActionText>
          </ActionContainer>
          {showProposalNotes && (
            <ProposalNotesContainer
              ref={notesRef}
              showChangeProposal={showChangeProposal}
            >
              <NotesCloseButton
                onClick={() => updateProposalNotes()}
                title="Close and Save"
              >
                <img src={timesDarkGrey} alt="close" data-image="close" />
              </NotesCloseButton>
              {GenerateInput({
                name: 'notes',
                placeholder: 'Notes',
                type: 'textarea',
                editor: true,
                showClear: false,
                id: 'notes',
                width: '100%',
                height: '150px',
                value: proposalNotes,
                onChange: (e) => setProposalNotes(e),
              })}
              {/* onBlur: (val) => updateProposalNotes(val), */}
            </ProposalNotesContainer>
          )}
        </ListContainer>
        <ActionContainer onClick={toggleViewChanges} width="100px">
          <ActionIcon
            src={isShowingChanges ? eyeClosed : eye}
            alt={isShowingChanges ? 'eye closed' : 'eye'}
            data-image={isShowingChanges ? 'eye-closed' : 'eye'}
            width={isShowingChanges ? '15px' : null}
          />
          <ActionText>
            {isShowingChanges ? 'Hide Changes' : 'Show Changes'}
          </ActionText>
        </ActionContainer>
        {showChangeProposal && (
          <ListContainer>
            <ActionContainer onClick={() => setShowProposalList(true)}>
              <ActionIcon
                src={swap}
                alt="draft"
                data-image="proposal"
                width="14px"
              />
              <ActionText>Change Draft</ActionText>
            </ActionContainer>
            {showProposalList && (
              <ProposalListContainer ref={menuRef}>
                {proposalList.map((proposal, index) => {
                  return (
                    <ProposalItem
                      onClick={() => proposalOnClick(proposal)}
                      key={index}
                      isLast={proposalList.length === index + 1}
                    >
                      <p>{proposal?.name}</p>
                    </ProposalItem>
                  );
                })}
              </ProposalListContainer>
            )}
          </ListContainer>
        )}
        {/* <ActionContainer>
          <ActionIcon src={save} alt="save" />
          <ActionText>Save As</ActionText>
        </ActionContainer> */}
        <CloseButton onClick={() => hide()}>
          <img src={timesPaleGrey} alt="close" data-image="close" />
        </CloseButton>
      </RightContent>
    </ProposalBarContainer>
  );
};

const fadeInMenu = keyframes`
  from {
    bottom: 23px;
  }
  to {
    bottom: 35px;
  }
  `;

const ListContainer = styled.div`
  position: relative;
`;

const ProposalItem = styled.div`
  cursor: pointer;
  padding: 6px 3px;
  border-bottom: ${(props) =>
    props.isLast ? null : `1px solid ${colors.lightGrey}`};
  p {
    white-space: nowrap;
    font-size: 11px;
    font-weight: ${fonts.semiBold};
    width: 124px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const ProposalListContainer = styled.div`
  @media (max-width: 890px) {
    bottom: 40px;
  }
  position: absolute;
  /* left: 0; */
  left: 50%;
  bottom: 35px;
  width: 100%;
  min-width: 130px;
  /* width: calc(100% - 16px); */
  text-align: left;
  background: white;
  color: ${colors.darkGrey};
  border: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowNavbarAlt};
  padding: 3px 6px;
  border-radius: 3px;
  z-index: 6;
  /* display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; */
  transform: scale(1) translateX(-50%);
  animation: ${fadeInMenu} 0.2s ease-in-out;
`;

const ProposalNotesContainer = styled.div`
  @media (max-width: 900px) {
    min-width: 220px;
  }
  position: absolute;
  /* left: ${(props) => (props.showChangeProposal ? '6px' : null)};
  right: ${(props) => (props.showChangeProposal ? null : '6px')}; */
  left: 50%;
  transform: translateX(-50%);
  bottom: 35px;
  width: 100%;
  min-width: 275px;
  min-height: 100px;
  text-align: left;
  background: white;
  color: ${colors.darkGrey};
  box-shadow: ${boxShadows.boxShadowNavbarAlt};
  border-radius: 6px;
  z-index: 6;
  transform: scale(1) translateX(-50%);
  animation: ${fadeInMenu} 0.2s ease-in-out;
`;

const NotesCloseButton = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: ${colors.lightGrey};
  position: absolute;
  top: 9px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 9px;
  }
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
`;

const ProposalNameContainer = styled.div`
  flex: 1 1 250px;
  /* color: ${colors.darkGrey}; */
  color: ${colors.white};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const ProposalName = styled.span`
  @media (max-width: 1400px) {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 1100px) {
    max-width: 250px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 1010px) {
    max-width: 200px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  @media (max-width: 800px) {
    max-width: 180px;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  font-weight: ${fonts.semiBold};
  margin: 0 10px 0 5px;
  font-size: 15px;
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: ${(props) => (props.visible ? '2px 0' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  width: ${(props) => (props.visible ? 'auto' : '0px')};
  height: ${(props) => (props.visible ? 'auto' : '0px')};
`;

const ProposalNameInput = styled.input`
  @media (max-width: 1100px) {
    font-size: 12px;
  }
  border: 1px solid ${inputColors.border};
  border-radius: 3px;
  margin: -2px 0;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  flex: ${(props) => (props.visible ? '1 1 250px' : '0 0 auto')};
  width: ${(props) => (props.visible ? 'auto' : 0)};
  padding: ${(props) => (props.visible ? '4px 8px' : '0')};
`;

const ActionContainer = styled.div`
  @media (max-width: 1290px) {
    margin: 0 8px;
    width: ${(props) => (props.width ? '85px' : null)};
  }
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0 10px;
  width: ${(props) => props.width};
  img {
    filter: brightness(0) invert(1);
  }
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
`;

const ActionText = styled.p`
  @media (max-width: 1290px) {
    font-size: 10px;
  }
  font-size: 12px;
  font-weight: ${fonts.regular};
`;

const ActionIcon = styled.img`
  @media (max-width: 1290px) {
    width: 11px;
    height: 11px;
  }
  width: ${(props) => (props.width ? props.width : '13px')};
  height: ${(props) => (props.width ? props.width : '13px')};
  margin-right: 5px;
`;

const CloseButton = styled.div`
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.01);
    opacity: 0.9;
  }
  img {
    width: 11px;
    height: 11px;
    filter: brightness(0) invert(1);
  }
`;

const AddInstrumentContainer = styled.div`
  @media (max-width: 1290px) {
    margin: 0 8px;
  }
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 10px;
  p {
    @media (max-width: 1290px) {
      font-size: 10px;
    }
    font-size: 12px;
  }
  img {
    @media (max-width: 1290px) {
      width: 11px;
      height: 11px;
      margin-right: 5px;
    }
    width: 13px;
    height: 13px;
    margin-right: 8px;
  }
  &:hover {
    transform: scale(1.01);
    opacity: 0.9;
  }
`;

const fadeInBottom = keyframes`
  from {
    bottom: 0px;
  }
  to {
    bottom: ${(props) => (props.amrShowLegend ? '60px' : '20px')};
  }
  `;

const ProposalBarContainer = styled.div`
  width: ${(props) =>
    `calc(100% - ${props.$sideNavWidth} - 70px - ${props.$sidebarWidth} )`};
  min-height: 25px;
  max-height: 75px;
  position: fixed;
  bottom: ${(props) => (props.$amrShowLegend ? '60px' : '20px')};
  left: ${(props) => `calc(${props.$sideNavWidth} + 20px)`};
  /* background: white; */
  background: ${colors.darkGrey};
  color: white;
  border: 1px solid ${colors.lightGrey};
  box-shadow: ${boxShadows.boxShadowSoft};
  padding: 10px 15px;
  border-radius: 4px;
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  transform: scale(1);
  animation: ${fadeInBottom} 0.3s ease-in-out;
`;

// const ProposalBarContainerTop = styled.div`
//   width: calc(100% - 30px);
//   min-height: 35px;
//   max-height: 75px;
//   position: fixed;
//   top: 0;
//   left: 0;
//   background: ${colors.darkGrey};
//   padding: 10px 15px;
//   z-index: 500;
//   color: white;
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   font-size: 13px;
// `;

const LeftContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  @media (max-width: 1080px) {
    flex: 1 1 20%;
  }
  flex: 1 1 30%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  /* border: 1px solid grey; */
`;

const InfoLink = styled.a`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-right: 5px;
  img {
    width: 13px;
    height: 13px;
    /* opacity: 0.3; */
    filter: brightness(0) invert(1);
  }
`;

const CenterContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 30%;
  }
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  flex: 1 1 40%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
`;

const RightContent = styled.div`
  @media (max-width: 1290px) {
    flex: 1 1 35%;
  }
  flex: 1 1 30%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
`;

ProposalBar.propTypes = {
  sideNavWidth: PropTypes.string,
  amrShowLegend: PropTypes.bool,
  proposal: PropTypes.object,
  hide: PropTypes.func,
  householdId: PropTypes.string,
};

export default ProposalBar;
