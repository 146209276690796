//TEST LOCAL OBJECTS FOR LOCALIZATION ONLY USED ON DASHBOARD AT THE MOMENT
export const en_us = {
  dashboard: {
    content: {
      actionMenu: {
        searchBar: 'Search Households',
        householdIndex: 'View Household Index',
        addHousehold: 'Add Household',
      },
      lowerItems: {
        support: {
          label: 'Support Documentation',
          text: 'View our library of articles, videos and webinars to learn all about Asset-Map.',
          cta: 'Get Support',
        },
        discovery: {
          label: 'Discovery',
          text: 'Send our questionnaire to prospective clients and build an Asset-Map automatically.',
          cta: 'Launch Discovery',
        },
        integrations: {
          label: 'Integrations',
          text: 'Integrate other financial data with Asset-Map for a complete financial picture.',
          cta: 'Enable Integrations',
        },
        signals: {
          label: 'Signals',
          text: 'Help diagnose the potential impact of disruptive financial events.',
          cta: 'Learn about Signals',
        },
      },
    },
  },
};

export const es_us = {
  dashboard: {
    content: {
      actionMenu: {
        searchBar: 'Buscar Hogares',
        householdIndex: 'Índice de Hogares',
        addHousehold: 'Agregar Hogar',
      },
      lowerItems: {
        support: {
          label: 'Servicio',
          text: 'Utilice nuestra guía de inicio para aprender todas las características de Asset-Map y cómo comenzar.',
          cta: 'Obtener Servicio',
        },
        discovery: {
          label: 'Entrevista',
          text: 'Envíe nuestro cuestionario a posibles clientes y cree un Asset-Map automáticamente.',
          cta: 'Iniciar Entrevista',
        },
        integrations: {
          label: 'Integraciones',
          text: 'Integre otros datos financieros con Asset-Map para obtener una imagen financiera completa.',
          cta: 'Habilitar integraciones',
        },
        signals: {
          label: 'Señales',
          text: 'Ayude a diagnosticar el impacto potencial de eventos financieros disruptivos',
          cta: 'Más información sobre Señales',
        },
      },
    },
  },
};
