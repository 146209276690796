import PropTypes from 'prop-types';
import { HouseholdNewManualContainer } from '../../containers';
import { UpdatePageTitle, CloseDropdowns } from '../../utils';

const HouseholdNewManual = ({ isConsumer = false }) => {
  UpdatePageTitle('New Household');
  CloseDropdowns();

  return <HouseholdNewManualContainer isConsumer={isConsumer} />;
};

HouseholdNewManual.propTypes = {
  isConsumer: PropTypes.bool,
};

export default HouseholdNewManual;
