//ADDS EVENT LISTENER TO OBSERVE ACTIONS
export const SubscribeEventListener = (eventName, listener) => {
  // console.log('add event listener', eventName);
  document.addEventListener(eventName, listener);
};

//REMOVES EVENT LISTENER TO STOP LISTENING
export const UnsubscribeEventListener = (eventName, listener) => {
  // console.log('remove event listener', eventName);
  document.removeEventListener(eventName, listener);
};

//DISPATCHES AN EVENT THAT CAN BE OBSERVED BY EVENT LISTENER
export const DispatchEventData = (eventName, data) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};
