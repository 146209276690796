/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { LoadingAnimation } from '..';
import {
  FlexCenterAll,
  FlexCenterEnd,
  FlexCenterStart,
  LoadingContainer,
} from '../../styles/library/layoutStyles';
import { colors, fonts } from '../../styles/variables';
import {
  chevronDownDark,
  commentBubble,
  eyeClosedDark,
  eyeSolid,
} from '../../assets';
import {
  DateFormatUTC,
  DetermineSignalColor,
  GenerateSignalColorIcon,
  GenerateSignalIcon,
  SendTrackingEvent,
  signalDetails,
  signalLabels,
} from '../../utils';
import {
  ExpandableItem,
  ExpandedDetails,
  ExpandHeadingContainer,
  ExpandHeadingLabel,
  ExpandIcon,
  LabelText,
} from '../../styles/library/sidebarStyles';
import {
  getSignalComments,
  // getSignalInstruments,
  setFocusedItems,
  toggleShowNewTargetMapModal,
  toggleShowSignalCommentsModal,
  toggleSnoozeSignal,
  updateHouseholdStoreValue,
} from '../../store/actions';

const SignalsSidebar = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params?.id;
  const {
    householdSignals,
    hasSignalsSnooze,
    preferences,
    updatedFocusMode,
    invocationId,
  } = useSelector((state) => ({
    householdSignals: state.households.householdSignals,
    hasSignalsSnooze: state.user.hasSignalsSnooze,
    preferences: state.user.user.preferences,
    updatedFocusMode: state.households.updatedFocusMode,
    invocationId: state.configs.invocationId,
  }));
  const [loadingSignals, setLoadingSignals] = useState(true);
  const [signals, setSignals] = useState([]);
  const [focusedSignal, setFocusedSignal] = useState();
  const [loadingInstruments, setLoadingInstruments] = useState(false);

  useEffect(() => {
    if (householdSignals) {
      const signalOptions = householdSignals?.signals.reduce((acc, signal) => {
        const matching = signalLabels.find((ind) => ind.key === signal.name);
        const existingSignal = signals.find((ind) => ind.key === signal.name);
        matching.isExpanded = existingSignal
          ? existingSignal?.isExpanded
          : false;
        matching.snoozed = signal?.snooze?.snoozed || signal?.snooze?.muted;
        matching.muted = signal?.snooze?.muted;
        matching.snoozed_utc = DateFormatUTC(
          signal?.snooze?.utc,
          preferences ? preferences.date_format : 'MM/DD/YYYY'
        );
        if (signal.enabled) {
          return [...acc, { ...signal, ...matching }];
        }
        return acc;
      }, []);
      signalOptions.sort((a, b) => a.order - b.order);
      setSignals(signalOptions);
      setTimeout(() => {
        setLoadingSignals(false);
      }, 400);
    } else {
      setSignals([]);
    }
    return () => {
      resetFocusedItems();
    };
  }, [householdSignals]);

  useEffect(() => {
    if (updatedFocusMode) {
      setLoadingInstruments(false);
      dispatch(updateHouseholdStoreValue('updatedFocusMode', false));
    }
  }, [updatedFocusMode]);

  const resetFocusedItems = () => {
    dispatch(updateHouseholdStoreValue('focusedItems', null));
    dispatch(updateHouseholdStoreValue('inFocusMode', false));
    dispatch(updateHouseholdStoreValue('updatedFocusMode', false));
    setLoadingInstruments(false);
  };

  const onExpandSignal = (signal) => {
    setSignals(
      signals.map((s) => {
        if (s.key === signal?.key) {
          s.isExpanded = !s.isExpanded;
        }
        return s;
      })
    );
    SendTrackingEvent(invocationId, 'click', 'signal_expand', 'sidebar_amr', {
      signal: signal?.key,
      is_expanded: signal?.isExpanded,
    });
  };

  const actionOnClick = (signal, isSnooze = false) => {
    const signalDetails = { ...signal, isSnooze };
    if (!isSnooze) {
      dispatch(getSignalComments(householdId, signal.key));
    }
    dispatch(toggleShowSignalCommentsModal(true, signalDetails));
  };

  const wakeSignal = (signal) => {
    return dispatch(
      toggleSnoozeSignal(householdId, { signal: signal?.key }, false)
    );
  };

  const focusSignalOnClick = (signal) => {
    if (isFocused(signal)) {
      setFocusedSignal(null);
      resetFocusedItems();
    } else {
      if (!loadingInstruments) {
        setLoadingInstruments(true);
        const signalInstruments = signal?.instruments;
        dispatch(setFocusedItems(signalInstruments));
        // dispatch(getSignalInstruments(householdId, signal.key));
        setFocusedSignal(signal.key);
      }
      SendTrackingEvent(invocationId, 'click', 'signal_focus', 'sidebar_amr', {
        signal: signal?.key,
      });
    }
  };

  const isFocused = (signal) => {
    return signal?.key === focusedSignal;
  };

  const onTargetMapClick = (consideration, signal) => {
    dispatch(toggleShowNewTargetMapModal(true, consideration?.targetMapType));
    SendTrackingEvent(
      invocationId,
      'click',
      'signal_create_target_map',
      'sidebar_amr',
      {
        signal: signal?.key,
        targetmap: consideration?.targetMapType,
      }
    );
  };

  return (
    <>
      {loadingSignals ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <>
          {signals.map((signal) => {
            return (
              <ExpandableItem
                key={signal.key}
                $inFocusMode={focusedSignal}
                $isFocused={isFocused(signal)}
              >
                <ExpandHeadingContainer
                  $isExpanded={signal.isExpanded}
                  onClick={() => onExpandSignal(signal)}
                >
                  <ExpandHeadingLabel>
                    {GenerateSignalIcon(signal?.name, colors.darkGrey, {
                      marginRight: '15px',
                      flex: '0 0 auto',
                      width: '20px',
                      height: '20px',
                    })}
                    <LabelText>{signal.label}</LabelText>
                    {GenerateSignalColorIcon(
                      signal?.calculated,
                      signal?.snoozed,
                      true,
                      {
                        width: '20px',
                        height: '20px',
                      }
                    )}
                  </ExpandHeadingLabel>
                  <ExpandIcon
                    // onClick={() => onExpandSignal(signal)}
                    $isExpanded={signal.isExpanded}
                  >
                    <img src={chevronDownDark} alt="down" />
                  </ExpandIcon>
                </ExpandHeadingContainer>
                {signal.isExpanded && (
                  <ExpandedDetails>
                    <FocusContainer>
                      <ActionItem onClick={() => focusSignalOnClick(signal)}>
                        {loadingInstruments && isFocused(signal) ? (
                          <DotsContainer>
                            <LoadingAnimation
                              dots={true}
                              smaller={true}
                              color={colors.paleGrey}
                            />
                          </DotsContainer>
                        ) : (
                          <ActionItemIcon
                            src={isFocused(signal) ? eyeSolid : eyeClosedDark}
                            alt={isFocused(signal) ? 'focused' : 'unfocused'}
                            data-image={
                              isFocused(signal)
                                ? `focused-${signal.key}`
                                : `unfocused-${signal.key}`
                            }
                            data-focused={isFocused(signal)}
                            title={
                              isFocused(signal)
                                ? 'Show Related Financials'
                                : 'Stop Showing Related Financials'
                            }
                            $active={isFocused(signal)}
                          />
                        )}
                      </ActionItem>
                    </FocusContainer>
                    <SignalIconContainer
                      style={{
                        border: `2px solid ${DetermineSignalColor(
                          signal?.state,
                          signal?.snoozed
                        )}`,
                      }}
                    >
                      {GenerateSignalIcon(signal?.name, colors.darkGrey)}
                      <ColorStatusContainer>
                        {GenerateSignalColorIcon(
                          signal?.calculated,
                          signal?.snoozed
                        )}
                      </ColorStatusContainer>
                    </SignalIconContainer>
                    {signal?.snoozed && (
                      <SignalSnoozedContainer>
                        <SignalSnoozed>Snoozed</SignalSnoozed>
                        <SnoozeDate>
                          {signal?.muted
                            ? 'Indefinitely'
                            : `Until ${signal?.snoozed_utc}`}
                        </SnoozeDate>
                      </SignalSnoozedContainer>
                    )}
                    <SignalDetails>
                      {signalDetails[signal?.name]?.details ||
                        'Error: Missing Details'}
                      <SignalAdditional>
                        {signalDetails[signal?.name]?.additional}
                      </SignalAdditional>
                    </SignalDetails>
                    <SignalConsiderations>
                      <ConsiderationsHeading>
                        Considerations
                      </ConsiderationsHeading>
                      <ConsiderationsList>
                        {signalDetails[signal?.name]?.considerations?.map(
                          (consideration, index) => {
                            return (
                              <li key={index}>
                                {consideration?.targetMapType ? (
                                  <ConsiderationLink
                                    onClick={() =>
                                      onTargetMapClick(consideration, signal)
                                    }
                                  >
                                    {consideration?.text}
                                  </ConsiderationLink>
                                ) : (
                                  consideration?.text
                                )}
                              </li>
                            );
                          }
                        )}
                      </ConsiderationsList>
                      {/* <ConsiderationsList>
                        {signalDetails[signal?.name]?.considerations?.map(
                          (consideration, index) => {
                            return consideration?.targetMapType ? (
                              <ConsiderationContainer key={index}>
                                <ConsiderationButton
                                  onClick={() => {
                                    dispatch(
                                      toggleShowNewTargetMapModal(
                                        true,
                                        consideration?.targetMapType
                                      )
                                    );
                                  }}
                                >
                                  {consideration?.text}
                                </ConsiderationButton>
                              </ConsiderationContainer>
                            ) : (
                              <li key={index}>{consideration?.text}</li>
                            );
                          }
                        )}
                      </ConsiderationsList> */}
                    </SignalConsiderations>
                    <ActionOptions>
                      <LeftActionContainer>
                        {hasSignalsSnooze && (
                          <ActionContainer style={{ marginRight: '10px' }}>
                            <ActionButton
                              onClick={() => {
                                signal.snoozed
                                  ? wakeSignal(signal)
                                  : actionOnClick(signal, true);
                              }}
                            >
                              {signal.snoozed ? 'Wake' : 'Snooze'}
                            </ActionButton>
                          </ActionContainer>
                        )}
                      </LeftActionContainer>
                      <RightActionContainer>
                        <ActionContainer>
                          {signal?.comments && signal.comments !== 0 ? (
                            <CommentCount>{signal.comments}</CommentCount>
                          ) : null}
                          {/* {signal?.comments && <CommentCircle />} */}
                          <CommentIcon
                            src={commentBubble}
                            alt="message"
                            title="Comments"
                            data-image={`comments-${signal.key}`}
                            onClick={() => actionOnClick(signal, false)}
                          />
                        </ActionContainer>
                      </RightActionContainer>
                    </ActionOptions>
                  </ExpandedDetails>
                )}
              </ExpandableItem>
            );
          })}
        </>
      )}
    </>
  );
};

const FocusContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  ${FlexCenterAll};
`;

const DotsContainer = styled.div`
  position: relative;
  top: -2px;
  right: -8px;
`;

const ActionItem = styled.button`
  flex: 0 0 auto;
  padding: 0;
  ${FlexCenterEnd};
  &:hover {
    opacity: 0.8;
  }
`;

const ActionItemIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const SignalIconContainer = styled.div`
  ${FlexCenterAll};
  position: relative;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  margin: 5px auto 15px auto;
`;

const ColorStatusContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -9px;
`;

const SignalSnoozedContainer = styled.div`
  margin: 10px 0 15px 0;
`;

const SignalSnoozed = styled.p`
  font-size: 15px;
  font-weight: ${fonts.semiBold};
  text-align: center;
  ${'' /* margin-bottom: 5px; */}
  text-transform: uppercase;
  color: ${colors.paleGrey};
`;

const SnoozeDate = styled.p`
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  text-align: center;
  color: ${colors.paleGrey};
`;

const SignalDetails = styled.div`
  font-size: 12px;
  font-weight: ${fonts.semiBold}
  color: ${colors.darkGrey};
  text-align: center;
  margin: 0 0 10px 0;
`;

const SignalAdditional = styled.span`
  font-style: italic;
  font-weight: ${fonts.regular};
`;

const ConsiderationLink = styled.span`
  color: ${colors.lightBlue};
  cursor: pointer;
`;

const SignalConsiderations = styled.div`
  font-size: 11px;
  color: ${colors.darkGrey};
  text-align: left;
  margin: 25px 0 7px 0;
`;

const ConsiderationsHeading = styled.p`
  font-size: 12px;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
`;

const ConsiderationsList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  li {
    margin-bottom: 3px;
  }
`;

// const ConsiderationContainer = styled.button`
//   display: ${FlexCenterAll};
//   width: 100%;
//   margin: 20px 0;
// `;

// const ConsiderationButton = styled.button`
//   background: ${colors.lightGrey};
//   color: ${colors.darkGrey};
//   font-size: 11px;
//   padding: 5px 10px;
//   border-radius: 20px;
//   display: ${FlexCenterAll};
// `;

const ActionOptions = styled.div`
  flex: 0 0 auto;
  ${FlexCenterStart};
  width: 100%;
  margin: 30px 0 10px 0;
`;

const LeftActionContainer = styled.div`
  ${FlexCenterStart};
  flex: 1 0 auto;
`;

const RightActionContainer = styled.div`
  ${FlexCenterEnd};
  flex: 1 1 auto;
`;

const ActionContainer = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }
  svg {
    margin-left: 7px;
  }
`;

const ActionButton = styled.button`
  color: ${colors.lightBlue};
  font-size: 13px;
  text-align: center;
  cursor: pointer;
`;

const CommentIcon = styled.img`
  margin-right: 10px;
`;

const CommentCount = styled.span`
  position: absolute;
  font-size: 8px;
  top: -4px;
  right: -1px;
  width: 8px;
  color: ${colors.paleGrey};
`;

export default SignalsSidebar;
