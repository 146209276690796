import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from './';
import { user, userShield, link, milestone, sync } from '../assets';
import {
  FormatCurrency,
  IsNegative,
  GenerateSignalIcon,
  GenerateSignalColorIcon,
  GenerateEntityIcon,
  GenerateStatusDisplay,
  DateFromNowUTC,
} from '../utils';
import { colors, maxDevice, messageColors, fonts } from '../styles/variables';
import { LoadingPlaceholder } from '../styles/library/fontStyles';

const ListItem = ({
  item,
  index,
  value,
  heading,
  showIcon,
  showImage,
  showNew = false,
  onClickSync,
  onClick,
  loading = false,
  syncing = false,
}) => {
  const { justSynced } = useSelector((state) => ({
    justSynced: state.households.justSynced,
  }));
  const [isSyncing, setIsSyncing] = useState(false);

  useEffect(() => {
    if (justSynced) {
      setIsSyncing(false);
    }
  }, [justSynced]);

  useEffect(() => {
    setIsSyncing(syncing);
  }, [syncing]);

  const generateIcon = () => {
    if (value === 'signals') {
      return GenerateSignalIcon(item?.name, colors.darkGrey, {
        marginRight: '15px',
        flex: '0 0 auto',
        width: '18px',
        height: '18px',
      });
    }
    let icon = user;
    let text = 'member';
    if (value === 'advisors') {
      icon = userShield;
      text = 'advisor';
    }
    if (value === 'members') {
      if (item.member_type === 'member') {
        icon = user;
      }
      if (item.member_type === 'entity') {
        icon = GenerateEntityIcon(item.category);
        text = 'entity';
      }
    }
    if (value === 'milestones') {
      icon = milestone;
      text = 'milestone';
    }
    return <ListItemIcon src={icon} alt={text} data-image={text} />;
  };

  const integrationOnClick = (item) => {
    setIsSyncing(true);
    onClickSync(item);
  };

  const generateIntegrationInfo = () => {
    if (item && item.hasOwnProperty('value')) {
      if (item.value.hasOwnProperty('enabled') && item.value.enabled) {
        if (item.value.hasOwnProperty('sync')) {
          if (item.value.sync?.available) {
            return (
              <LastSyncContainer>
                {item.value.sync.hasOwnProperty('last') && (
                  <>
                    <LastSyncText>Synced:</LastSyncText>
                    {item.value.sync.last ? (
                      <span>{DateFromNowUTC(item.value.sync.last)}</span>
                    ) : (
                      <span>Never</span>
                    )}
                    {item.value.sync.hasOwnProperty('available') &&
                    item.value.sync.available &&
                    isSyncing ? (
                      <SyncIconAnimation
                        src={sync}
                        alt="sync"
                        data-image={`sync-${item.id}`}
                      />
                    ) : (
                      <SyncIcon
                        src={sync}
                        alt="sync"
                        onClick={() => integrationOnClick(item)}
                        data-image={`sync-${item.id}`}
                      />
                    )}
                  </>
                )}
              </LastSyncContainer>
            );
          } else {
            return (
              <EnableLink onClick={() => onClick(item)}>Manage</EnableLink>
            );
          }
        }
        return null;
      } else {
        return <EnableLink onClick={() => onClick(item)}>Enable</EnableLink>;
      }
    }
    return null;
  };

  const generateSignalInfo = () => {
    return (
      <SignalValueContainer>
        {GenerateSignalColorIcon(item?.calculated, item?.snoozed, true, {
          width: '16px',
          height: '16px',
        })}
      </SignalValueContainer>
    );
  };

  const formatItem = (item) => {
    if (item.format === 'currencyFormatter') {
      return (
        <span
          style={{ color: IsNegative(item.value) ? messageColors.error : null }}
        >
          {FormatCurrency(item.value, item.currency)}
        </span>
      );
    }
    if (item.format === 'ratioFormatter') {
      return `${item.value}%`;
    }
    return item.value;
  };

  return (
    <>
      {loading ? (
        <ListItemContainer style={index === 0 ? { borderTop: 'none' } : null}>
          {showImage ? (
            <LoadingPlaceholder width="100%" height="200px" margin="0" />
          ) : (
            <>
              {showIcon && (
                <LoadingPlaceholder
                  width="20px"
                  height="20px"
                  round="true"
                  margin="0 15px 0 0"
                />
              )}
              <ListItemText>
                <LoadingPlaceholder width="100%" margin="0" />
              </ListItemText>
              <ListItemAdditional>
                <LoadingPlaceholder width="100%" margin="0" />
              </ListItemAdditional>
            </>
          )}
        </ListItemContainer>
      ) : showNew ? (
        <ShowNewContainer>
          <p>{item.label}</p>
          <div>
            <Button
              text={item.buttonText || `Add ${item.value}`}
              onClick={item.onClick}
            />
          </div>
        </ShowNewContainer>
      ) : (
        <ListItemContainer style={{ borderTop: index === 0 ? 'none' : null }}>
          {showImage ? (
            <ListImageLink to={item.link}>
              <ListImage
                src={item.src}
                alt="Asset-Map Preview"
                data-image="amr-preview"
              />
            </ListImageLink>
          ) : heading && showIcon ? (
            generateIcon()
          ) : null}
          {item.is_linked && (
            <LinkedImage
              src={link}
              alt="linked"
              data-image={`linked-${index}`}
            />
          )}
          {item.value === undefined ? (
            <ListItemFullText>{item.label}</ListItemFullText>
          ) : !showImage ? (
            <>
              {item.textClick ? (
                <>
                  <ListItemTextClick
                    onClick={() => item.textClick(item)}
                    style={item.textClick ? { cursor: 'pointer' } : null}
                  >
                    {item.label}
                  </ListItemTextClick>
                  {item.status && GenerateStatusDisplay(item.status)}
                </>
              ) : (
                <ListItemText>{item.label}</ListItemText>
              )}
              <ListItemAdditional>
                {value === 'integrations' ? (
                  generateIntegrationInfo()
                ) : value === 'signals' ? (
                  generateSignalInfo()
                ) : item.format ? (
                  <TextLink>{formatItem(item)}</TextLink>
                ) : (
                  <TextLink dangerouslySetInnerHTML={{ __html: item.value }} />
                )}
              </ListItemAdditional>
            </>
          ) : null}
        </ListItemContainer>
      )}
    </>
  );
};

ListItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onClickSync: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  heading: PropTypes.string,
  showIcon: PropTypes.bool,
  showImage: PropTypes.bool,
  showNew: PropTypes.bool,
  loading: PropTypes.bool,
  syncing: PropTypes.bool,
};

const ListItemContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  border-top: 1px solid ${colors.lightGrey};
  padding: 12px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  min-height: 20px;
`;

const ShowNewContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  padding: 8px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  p {
    flex: 1 1 auto;
    /* font-style: italic; */
    color: ${colors.paleGrey};
  }
`;

const ListItemIcon = styled.img`
  margin-right: 15px;
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
`;

const ListImageLink = styled(Link)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: block;
  background: ${colors.lightGrey};
  overflow-y: auto;
  max-height: 500px;
`;

const ListImage = styled.img`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${colors.lightGrey};
`;

const ListItemText = styled.div`
  /* @media ${maxDevice.tablet} {
    max-width: 200px;
  }
  @media ${maxDevice.mobileL} {
    max-width: 150px;
  }
  @media ${maxDevice.mobileM} {
    max-width: 120px;
  }
  @media ${maxDevice.mobileS} {
    max-width: 90px;
  }
  flex: 1 1 auto; */
  /* max-width: 255px; */
  flex: 1 1 auto;

  text-align: left;
  /* white-space: nowrap;
  overflow: hidden; */
  /* min-height: 20px; */
  margin-right: 10px;
  color: ${(props) => (props.color ? props.color : colors.darkGrey)};
  /* text-overflow: ellipsis; */
  a {
    color: ${colors.lightBlue};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

const ListItemTextClick = styled(ListItemText)`
  color: ${colors.lightBlue};
  cursor: pointer;
  flex: 0 1 auto;

  &:hover {
    color: ${colors.blue};
  }
`;

const ListItemFullText = styled.div`
  flex: 1 1 auto;
  text-align: left;
`;

const ListItemAdditional = styled.div`
  color: ${colors.paleGrey};
  flex: 1 1 auto;
  text-transform: capitalize;
  /* min-width: 100px; */
  text-align: right;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

const EnableLink = styled.div`
  cursor: pointer;
  color: ${colors.lightBlue};
  &:hover {
    color: ${colors.blue};
  }
`;

const LinkedImage = styled.img`
  width: 16px;
  max-height: 13px;
  margin-right: 5px;
`;

const TextLink = styled.span`
  a {
    text-transform: lowercase;
    color: ${colors.lightBlue};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

const LastSyncContainer = styled.div`
  font-size: 12px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

const LastSyncText = styled.span`
  font-weight: ${fonts.semiBold};
  margin-right: 5px;
`;

const SyncIcon = styled.img`
  margin-left: 5px;
  width: 10px;
  height: 10px;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const SignalValueContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const SyncIconAnimation = styled(SyncIcon)`
  animation: ${rotation} 1s infinite ease-in-out;
`;

export const StatusIcon = styled(Link)`
  display: flex;
  flex: 0 0 auto;
  margin: 0 2px 0 -3px;
  svg {
    width: 13px;
    height: 13px;
  }
`;

export default ListItem;
