/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  PageHeader,
  SimpleModal,
  LoadingAnimation,
  TargetMapSummaryItem,
  Button,
} from '../../components';
import {
  toggleShowDeleteModal,
  toggleShowEditTargetMapModal,
  toggleShowNewTargetMapModal,
  updateTargetMap,
  updateTargetMapsOrder,
  cloneTargetMap,
  getTargetMaps,
  updateTargetMapStoreValue,
  updateTargetMapSummaryValue,
} from '../../store/actions';
import {
  pencil,
  pdf,
  trashDark,
  copy,
  targetIllustration,
  settingsDark,
  times,
  chevronDown,
  undo,
} from '../../assets';
import { maxDevice, fonts, colors } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  HtmlToString,
  DetermineTargetMapColor,
  DetectEnterKeyPress,
  ErrorNotification,
  SendTrackingEvent,
  TargetMapMultiOption,
  MemberOption,
  GenerateAge,
} from '../../utils';
import { SecondaryHeading } from '../../styles/library/fontStyles';
import Filter, { FilterContainer } from '../../components/Table/Filter';
import { FlexCenterAll } from '../../styles/library/layoutStyles';

const HouseholdTargetMaps = ({ showPDF }) => {
  UpdatePageTitle('Target-Maps');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const householdId = params.id;
  const {
    currentHousehold,
    currency,
    deletedTargetMap,
    editedTargetMap,
    showingEditTargetMapModal,
    showTargetMaps,
    targetMapEditError,
    targetMaps,
    isStencil,
    hasTargetMapTemplates,
    hasTargetMapCompare,
    sideNavWidth,
    invocationId,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    currency: state.households.householdCurrency,
    deletedTargetMap: state.targetMaps.deletedTargetMap,
    editedTargetMap: state.targetMaps.editedTargetMap,
    showingEditTargetMapModal: state.targetMaps.showingEditTargetMapModal,
    showTargetMaps: state.user.showTargetMaps,
    targetMapEditError: state.targetMaps.targetMapEditError,
    targetMaps: state.targetMaps.targetMaps,
    isStencil: state.households.isStencil,
    hasTargetMapTemplates: state.user.hasTargetMapTemplates,
    hasTargetMapCompare: state.user.hasTargetMapCompare,
    sideNavWidth: state.user.sideNavWidth,
    invocationId: state.configs.invocationId,
  }));
  const [targetMapList, setTargetMapList] = useState([]);
  const [filterTargetMapList, setFilterTargetMapList] = useState([]);
  const [updatedOrder, setUpdatedOrder] = useState(false);
  const [editModalError, setEditModalError] = useState('');
  const [loadingTargetMaps, setLoadingTargetMaps] = useState(true);
  const [hasNoTargetMaps, setHasNoTargetMaps] = useState(false);
  const [isSavingTargetMap, setIsSavingTargetMap] = useState(false);
  const [updatingTargetMapName, setUpdatingTargetMapName] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [activeFilterValue, setActiveFilterValue] = useState({
    label: 'All',
    value: null,
  });
  const [selectedTargetMap, setSelectedTargetMap] = useState({
    name: '',
    duration: '',
    return_rate: '',
    inflation_rate: '',
  });
  const [editTargetMapError, setEditTargetMapError] = useState({
    name: false,
    duration: false,
    return_rate: false,
    inflation_rate: false,
  });
  const defaultColorKeys = [
    { label: 'Loss of Life', value: 'death' },
    {
      label: 'Long-term Disability',
      value: 'disability',
    },
    { label: 'Long-term Care', value: 'ltc' },
    {
      label: 'Education',
      value: 'education',
    },
    {
      label: 'Retirement',
      value: 'retirement',
    },
    { label: 'Custom', value: 'custom' },
  ];
  const [colorKeys, setColorKeys] = useState(defaultColorKeys);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [selectedTargetMaps, setSelectedTargetMaps] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [hasActiveFilter, setHasActiveFilter] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState({
    label: 'All',
    value: null,
  });
  const [selectedCategories, setSelectedCategories] = useState({
    label: 'All',
    value: null,
  });

  useEffect(() => {
    if (showTargetMaps !== null) {
      if (showTargetMaps) {
        dispatch(getTargetMaps(householdId));
      } else {
        setLoadingTargetMaps(false);
      }
    }
  }, [showTargetMaps]);

  useEffect(() => {
    if (hasTargetMapTemplates) {
      setColorKeys([...defaultColorKeys, { label: 'Template', value: 'user' }]);
    }
  }, [showTargetMaps]);

  useEffect(() => {
    if (colorKeys) {
      const tmCategories = colorKeys.map((type) => {
        type.color = DetermineTargetMapColor(type.value);
        type.isSelected = false;
        return type;
      });
      setCategoriesList(tmCategories);
    }
  }, [colorKeys]);

  useEffect(() => {
    if (targetMaps) {
      targetMaps.map((targetmap) => {
        targetmap.name = HtmlToString(targetmap.name);
        targetmap.selected = false;
        return targetmap;
      });
      setTargetMapList(targetMaps.sort((a, b) => b.priority - a.priority));
      setFilterTargetMapList(
        targetMaps.sort((a, b) => b.priority - a.priority)
      );
      resetFilters();
      setLoadingTargetMaps(false);
      setUpdatingTargetMapName(false);
      if (targetMaps.length === 0) {
        setHasNoTargetMaps(true);
      } else {
        setHasNoTargetMaps(false);
      }
    }
  }, [targetMaps]);

  useEffect(() => {
    if (targetMapList) {
      const selected = targetMapList.filter((tm) => tm.selected);
      setSelectedTargetMaps([...selected]);
    }
  }, [targetMapList]);

  useEffect(() => {
    if (
      currentHousehold &&
      currentHousehold.hasOwnProperty('householdmembers')
    ) {
      const members = currentHousehold.householdmembers.reduce((acc, mem) => {
        if (mem.member_type === 'member') {
          mem.isSelected = false;
          mem.age = GenerateAge(mem.date_of_birth);
          return [...acc, mem];
        } else {
          return acc;
        }
      }, []);
      setMembersList(members);
    }
  }, [currentHousehold]);

  const generateLabel = (array, name) => {
    const length = array.length;
    const plural = length !== 1;
    const label =
      length === 0 ? 'All' : `${length} ${name}${plural ? 's' : ''} Selected`;
    return label;
  };

  useEffect(() => {
    if (filtersChanged && membersList && categoriesList) {
      const matchingMembers = membersList.filter((mem) => mem.isSelected);
      const memLabel = generateLabel(matchingMembers, 'Member');
      setSelectedMembers({
        label: memLabel,
        value: null,
      });
      const matchingCategories = categoriesList.filter((cat) => cat.isSelected);
      const catLabel = generateLabel(matchingCategories, 'Type');
      setSelectedCategories({
        label: catLabel,
        value: null,
      });
    }
  }, [filtersChanged]);

  useEffect(() => {
    if (filtersChanged) {
      //Filter based off active value
      const activeFilterTargetMaps = targetMapList.reduce((acc, tm) => {
        if (activeFilterValue.value === true) {
          if (tm.active) {
            return [...acc, tm];
          }
        } else if (activeFilterValue.value === false) {
          if (!tm.active) {
            return [...acc, tm];
          }
        } else {
          return [...acc, tm];
        }
        return acc;
      }, []);
      const hasActiveFilter = activeFilterValue.value !== null;

      //Filter based off category value(s)
      let categoryFilterTargetMaps = [...activeFilterTargetMaps];
      const selectedCategories = categoriesList.reduce((acc, cat) => {
        if (cat.isSelected) {
          return [...acc, cat.value];
        }
        return acc;
      }, []);
      if (selectedCategories.length) {
        categoryFilterTargetMaps = categoryFilterTargetMaps.reduce(
          (acc, tm) => {
            if (selectedCategories.includes(tm.type)) {
              return [...acc, tm];
            }
            return acc;
          },
          []
        );
      }
      const hasCategoryFilter = selectedCategories.length !== 0;

      //Filter based off selected member(s)
      let memberFilterTargetMaps = [...categoryFilterTargetMaps];
      const selectedMembers = membersList.reduce((acc, mem) => {
        if (mem.isSelected) {
          return [...acc, mem.id];
        }
        return acc;
      }, []);
      if (selectedMembers.length) {
        memberFilterTargetMaps = memberFilterTargetMaps.reduce((acc, tm) => {
          const tmMems = tm.members.map((mem) => mem.id);
          const includesAny = selectedMembers.some((v) => tmMems.includes(v));
          if (includesAny) {
            return [...acc, tm];
          }
          return acc;
        }, []);
      }
      const hasMemberFilter = selectedMembers.length !== 0;

      setHasActiveFilter(
        hasActiveFilter || hasCategoryFilter || hasMemberFilter
      );

      setFilterTargetMapList(memberFilterTargetMaps);
      setFiltersChanged(false);
    }
  }, [filtersChanged]);

  useEffect(() => {
    if (!showingEditTargetMapModal) {
      setEditTargetMapError({
        name: false,
        duration: false,
        return_rate: false,
        inflation_rate: false,
      });
      setEditModalError('');
    }
  }, [showingEditTargetMapModal]);

  useEffect(() => {
    if (deletedTargetMap) {
      dispatch(getTargetMaps(householdId));
    }
  }, [deletedTargetMap]);

  useEffect(() => {
    if (editedTargetMap) {
      setIsSavingTargetMap(false);
      dispatch(getTargetMaps(householdId));
    }
  }, [editedTargetMap]);

  useEffect(() => {
    if (targetMapEditError) {
      setIsSavingTargetMap(false);
      setEditTargetMapError(targetMapEditError.data);
      if (Object.keys(targetMapEditError.data).length > 1) {
        setEditModalError('Please fix errors and save again');
      } else {
        setEditModalError(
          targetMapEditError.data[Object.keys(targetMapEditError.data)[0]]
        );
      }
      dispatch(updateTargetMapStoreValue('targetMapEditError', null));
    }
  }, [targetMapEditError]);

  useEffect(() => {
    if (updatedOrder) {
      let targetMapIds = targetMapList.map((targetMap) => targetMap.id);
      dispatch(updateTargetMapsOrder(householdId, targetMapIds));
      setUpdatedOrder(false);
    }
  }, [updatedOrder]);

  const editTargetMap = (targetMap) => {
    setSelectedTargetMap(targetMap);
    dispatch(toggleShowEditTargetMapModal(true));
  };

  const editTargetMapLink = (targetMap) => {
    return navigate(`/household/${householdId}/targetmap/${targetMap.id}`);
  };

  const copyTargetMap = (targetMap) => {
    dispatch(cloneTargetMap(householdId, targetMap.id));
  };

  const downloadTargetMap = (targetMap) => {
    return DownloadReport({
      value: 'targetmap',
      childValue: 'targetmaps',
      hasOptions: true,
      children: [{ selected: true, id: targetMap.id }],
    });
  };

  const openDeleteModal = (targetMap) => {
    dispatch(
      toggleShowDeleteModal(true, 'targetmap', targetMap.name, targetMap.id)
    );
  };

  const actions = [
    { id: 'edit', label: 'Edit', icon: pencil, onClick: editTargetMapLink },
    {
      id: 'settings',
      label: 'Settings',
      icon: settingsDark,
      onClick: editTargetMap,
    },
    { id: 'clone', label: 'Clone', icon: copy, onClick: copyTargetMap },
    { id: 'pdf', label: 'PDF', icon: pdf, onClick: downloadTargetMap },
    {
      id: 'delete',
      label: 'Delete',
      icon: trashDark,
      onClick: openDeleteModal,
    },
  ];

  const updateSelectedTargetMap = (e) => {
    return setSelectedTargetMap({
      ...selectedTargetMap,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setUpdatedOrder(true);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const updatedList = reorder(
      targetMapList,
      result.source.index,
      result.destination.index
    );
    setTargetMapList(updatedList);
    setFilterTargetMapList(updatedList);
  }

  const saveEditedTargetMap = () => {
    if (showingEditTargetMapModal && !isSavingTargetMap) {
      setIsSavingTargetMap(true);
      return dispatch(
        updateTargetMap(householdId, selectedTargetMap.id, selectedTargetMap)
      );
    }
  };

  const saveTargetMapName = (id, name) => {
    setUpdatingTargetMapName(id);
    return dispatch(updateTargetMap(householdId, id, { name: name }));
  };

  const editTargetMapModalContent = {
    heading: 'Target-Map Settings',
    minWidth: '350px',
    inputs: [
      {
        type: 'text',
        name: 'name',
        maxLength: '100',
        required: true,
        label: 'Target-Map Name',
        placeholder: 'Name',
        value: HtmlToString(selectedTargetMap.name),
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.name,
      },
      {
        type: 'number',
        name: 'duration',
        hasSuffix: true,
        suffixValue: 'yrs',
        maxLength: '5',
        required: false,
        label: 'Duration (yrs) before event start',
        placeholder: 'Duration (yrs)',
        value: selectedTargetMap.duration,
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.duration,
      },
      {
        type: 'number',
        name: 'return_rate',
        hasSuffix: true,
        maxLength: '5',
        required: true,
        label: 'Expected Net Return on Capital',
        placeholder: 'Expected Net Return on Capital',
        value: selectedTargetMap.return_rate,
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.return_rate,
      },

      {
        type: 'number',
        name: 'inflation_rate',
        hasSuffix: true,
        maxLength: '5',
        required: true,
        placeholder: 'Annual Increase of Contributions',
        label: 'Annual Increase of Contributions',
        value: selectedTargetMap.inflation_rate,
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.inflation_rate,
      },
    ],
    buttons: [
      {
        text: 'Cancel',
        function: () => dispatch(toggleShowEditTargetMapModal(false)),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Update',
        showLoading: isSavingTargetMap,
        loadingText: 'Updating',
        function: () => saveEditedTargetMap(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  const headerButtonFunction = () => {
    dispatch(toggleShowNewTargetMapModal(true));
  };

  const toggleSelectTargetMap = (targetMap) => {
    const maxSelected = selectedTargetMaps.length >= 3;
    if (maxSelected && !targetMap.selected) {
      return ErrorNotification('Cannot select more than 3 Target-Maps');
    }
    const updatedTargetMaps = targetMapList.map((tm) => {
      const copied = { ...tm };
      if (tm.id === targetMap.id) {
        copied.selected = !tm.selected;
      }
      return copied;
    });
    setTargetMapList(updatedTargetMaps);
    setFilterTargetMapList(updatedTargetMaps);
  };

  const modifyTargetMap = (targetMap, action) => {
    if (action === 'select') {
      toggleSelectTargetMap(targetMap);
    }
    if (action === 'pin') {
      dispatch(
        updateTargetMapSummaryValue(
          householdId,
          targetMap,
          {
            pinned: !targetMap.pinned,
          },
          `Target-Map ${targetMap.pinned ? 'Unpinned' : 'Pinned'}`
        )
      );
      SendTrackingEvent(invocationId, 'click', 'targetmap_pin', null, {
        targetmap: targetMap.id,
        pinned: !targetMap.pinned,
      });
    }
    if (action === 'active') {
      dispatch(
        updateTargetMapSummaryValue(
          householdId,
          targetMap,
          {
            active: !targetMap.active,
          },
          `Target-Map ${targetMap.active ? 'Inactive' : 'Active'}`
        )
      );
      SendTrackingEvent(invocationId, 'click', 'targetmap_active', null, {
        targetmap: targetMap.id,
        active: !targetMap.active,
      });
    }
  };

  const clearAllSelected = () => {
    const updatedTargetMaps = targetMapList.map((tm) => {
      const copied = { ...tm };
      copied.selected = false;
      return copied;
    });
    setTargetMapList(updatedTargetMaps);
    setFilterTargetMapList(updatedTargetMaps);
  };

  const enterCompareMode = () => {
    const targetMapParams = selectedTargetMaps.reduce((acc, curr) => {
      return acc + `/${curr.id}`;
    }, '');
    SendTrackingEvent(invocationId, 'action', 'targetmap_compare', null, {
      targetmaps: selectedTargetMaps.map((tm) => tm.id),
      in_compare: true,
    });
    return navigate(
      `/household/${householdId}/targetmap/compare${targetMapParams}`
    );
  };

  const generateFilter = (filter) => {
    if (filter?.selectedOption) {
      if (!Array.isArray(filter.selectedOption)) {
        return (
          <span>
            :{' '}
            {filter?.selectedOption?.label
              ? filter?.selectedOption?.label
              : 'All'}
          </span>
        );
      }
    }
  };

  const updateMembers = (e) => {
    setMembersList(
      membersList.map((member) => {
        if (e.id === member.id) {
          member.isSelected = !member.isSelected;
        }
        return member;
      })
    );
    setFiltersChanged(true);
  };

  const updateCategories = (e) => {
    setCategoriesList(
      categoriesList.map((cat) => {
        if (e.value === cat.value) {
          cat.isSelected = !cat.isSelected;
        }
        return cat;
      })
    );
    setFiltersChanged(true);
  };

  const filterTrackingEvent = (name) => {
    SendTrackingEvent(invocationId, 'click', 'targetmap_filter', null, {
      filterName: name,
    });
  };

  const filters = [
    {
      filterType: 'select',
      key: 'category',
      label: 'Target-Map Type',
      placeholder: 'Select Type',
      options: categoriesList,
      style: { width: '215px' },
      closeMenuOnSelect: false,
      isSearchable: false,
      components: { Option: TargetMapMultiOption },
      selectedOption: selectedCategories,
      onChangeCallback: (e) => {
        updateCategories(e);
        filterTrackingEvent('targetmap_category');
      },
    },
    {
      filterType: 'select',
      key: 'members',
      label: 'Associated Members',
      placeholder: 'Select Members',
      components: { Option: MemberOption },
      options: membersList,
      style: { width: '215px' },
      closeMenuOnSelect: false,
      isSearchable: false,
      selectedOption: selectedMembers,
      onChangeCallback: (e) => {
        updateMembers(e);
        filterTrackingEvent('targetmap_members');
      },
    },
    {
      filterType: 'select',
      key: 'is_active',
      label: 'Is Target',
      isSingle: true,
      options: [
        { label: 'All', value: null },
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      style: { width: '215px' },
      selectedOption: activeFilterValue,
      onChangeCallback: (e) => {
        setActiveFilterValue(e);
        setFiltersChanged(true);
        filterTrackingEvent('targetmap_active');
      },
    },
  ];

  const resetFilters = () => {
    setMembersList(
      membersList.map((mem) => {
        mem.isSelected = false;
        return mem;
      })
    );
    setCategoriesList(
      categoriesList.map((cat) => {
        cat.isSelected = false;
        return cat;
      })
    );
    setActiveFilterValue({
      label: 'All',
      value: null,
    });
    setFiltersChanged(true);
  };

  return (
    <>
      {showingEditTargetMapModal && (
        <SimpleModal
          hide={() =>
            dispatch(toggleShowEditTargetMapModal(!showingEditTargetMapModal))
          }
          content={editTargetMapModalContent}
          error={editModalError}
          currency={currency}
          onEnter={saveEditedTargetMap}
        />
      )}
      <PageContainer>
        <PageHeader
          content={{
            ...headerContent.targetMaps,
            showButton: !isStencil && showTargetMaps,
          }}
          buttonFunction={headerButtonFunction}
        />
        {loadingTargetMaps ? (
          <LoadingContainer>
            <LoadingAnimation />
          </LoadingContainer>
        ) : (
          <PageContent $showingCompare={selectedTargetMaps?.length}>
            {!showTargetMaps || isStencil ? (
              <EmptyContainer>
                <NotFoundImage
                  src={targetIllustration}
                  alt="No Target-Maps"
                  data-image="no-target-maps"
                />
                <NotFoundText>
                  {isStencil
                    ? 'Target-Maps are not available for Stencil Households'
                    : `Contact support to have Target-Maps enabled for your account.`}
                </NotFoundText>
              </EmptyContainer>
            ) : !hasNoTargetMaps ? (
              <>
                <HeaderContent>
                  <SecondaryHeading>
                    Funding Status & Priorities
                  </SecondaryHeading>
                  <ColorKeyContainer>
                    {colorKeys.map((color, index) => {
                      return (
                        <ColorKey key={index}>
                          <svg height="10" width="10">
                            <circle
                              cx="5"
                              cy="5"
                              r="5"
                              fill={DetermineTargetMapColor(color.value)}
                            />
                          </svg>
                          {color.label}
                        </ColorKey>
                      );
                    })}
                  </ColorKeyContainer>
                </HeaderContent>
                <FiltersContainer>
                  <FilterListRow>
                    <SectionLabel
                      $expanded={showFilterRow}
                      onClick={() => setShowFilterRow(!showFilterRow)}
                      style={{ cursor: 'pointer' }}
                    >
                      Filters{' '}
                      <ChevronIcon
                        src={chevronDown}
                        alt="arrow"
                        data-image={`chevron-${showFilterRow ? 'down' : 'left'}`}
                        style={{
                          transform: !showFilterRow ? 'rotate(90deg)' : null,
                        }}
                      />
                    </SectionLabel>
                    {!showFilterRow && (
                      <FiltersList>
                        {filters.map((filter, index) => {
                          return (
                            <FilterChip
                              key={index}
                              onClick={() => setShowFilterRow(!showFilterRow)}
                            >
                              <FilterName>{filter?.label}</FilterName>
                              {generateFilter(filter)}
                            </FilterChip>
                          );
                        })}
                      </FiltersList>
                    )}
                  </FilterListRow>
                  {showFilterRow && (
                    <FiltersRow>
                      {hasActiveFilter && (
                        <ResetButton onClick={resetFilters}>
                          <ResetIcon
                            src={undo}
                            alt="reset"
                            data-image={`reset-filters`}
                          />
                          Reset
                        </ResetButton>
                      )}
                      {filters.map((filter, index) => {
                        return (
                          <Filter
                            data={filter}
                            type={filter.filterType}
                            key={index}
                          />
                        );
                      })}
                    </FiltersRow>
                  )}
                </FiltersContainer>
                <TargetMapListContainer>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {filterTargetMapList.map((targetMap, index) => (
                            <TargetMapSummaryItem
                              index={index}
                              key={targetMap.id}
                              targetMap={targetMap}
                              currency={currency}
                              actions={actions}
                              saveName={saveTargetMapName}
                              updating={updatingTargetMapName === targetMap.id}
                              modifyTargetMap={modifyTargetMap}
                              canSelect={hasTargetMapCompare}
                              draggable={!hasActiveFilter}
                              invocationId={invocationId}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {filterTargetMapList.length === 0 && (
                    <EmptyContainer>
                      <NotFoundImage
                        src={targetIllustration}
                        alt="No Target-Maps"
                        data-image="no-target-maps"
                      />
                      <NotFoundText>
                        No Target-Maps match selected filters
                      </NotFoundText>
                    </EmptyContainer>
                  )}
                </TargetMapListContainer>
              </>
            ) : (
              <EmptyContainer>
                <NotFoundImage src={targetIllustration} alt="No Target-Maps" />
                <NotFoundText>No Target-Maps</NotFoundText>
              </EmptyContainer>
            )}
          </PageContent>
        )}
        {!!selectedTargetMaps?.length && (
          <SelectedFooter $sideNavWidth={sideNavWidth}>
            <LeftContent>
              <SelectedText>
                Compare <span>({selectedTargetMaps.length})</span>
              </SelectedText>
              <SelectedRow>
                {selectedTargetMaps?.length &&
                  selectedTargetMaps.map((tm, index) => {
                    return (
                      <ChipContainer key={index}>
                        <svg height="8" width="8">
                          <circle
                            cx="4"
                            cy="4"
                            r="4"
                            fill={
                              tm.color || DetermineTargetMapColor(tm.template)
                            }
                          />
                        </svg>
                        <SelectedTargetMapName>{tm.name}</SelectedTargetMapName>
                        <img
                          src={times}
                          alt="close"
                          data-image={`remove-tm-${tm.id}`}
                          onClick={() => {
                            toggleSelectTargetMap(tm);
                          }}
                        />
                      </ChipContainer>
                    );
                  })}
              </SelectedRow>
            </LeftContent>
            <ButtonsRowContainer>
              <Button
                text="Clear All"
                theme={ButtonThemes.cancel}
                onClick={() => clearAllSelected()}
              />
              <Button text="Compare" onClick={enterCompareMode} />
            </ButtonsRowContainer>
          </SelectedFooter>
        )}
      </PageContainer>
    </>
  );
};

const ResetButton = styled.button`
  ${FlexCenterAll}
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${fonts.semiBold};
  color: rgb(102, 102, 102);
  background: rgb(238, 238, 238);
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid rgb(238, 238, 238);
  &:hover {
    opacity: 0.9;
  }
`;

const ResetIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-left: 5px;
  cursor: pointer;
  margin-right: 5px;
`;

const SelectedFooter = styled.div`
  width: ${(props) => `calc(100% - ${props.$sideNavWidth} - 60px)`};
  left: ${(props) => props.$sideNavWidth};
  padding: 25px 30px;
  background: ${colors.white};
  position: fixed;
  bottom: 0;
  box-shadow: 0px -3px 7px rgba(50, 50, 50, 0.4);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;

const LeftContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const SelectedText = styled.div`
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  flex: 0 0 auto;
  margin: 5px 20px 5px 0;
`;

const SelectedRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const SelectedTargetMapName = styled.span`
  margin: 0 5px 0 5px;
  flex: 0 0 auto;
  cursor: pointer;
  font-size: 12px;
`;

const ChipContainer = styled.div`
  flex: 0 0 auto;
  margin: 3px 5px 3px 0px;
  border-radius: 3px;
  padding: 6px 8px 6px 8px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: ${colors.lightGrey};
  ${SelectedTargetMapName} {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    cursor: pointer;
  }
  img {
    margin-left: 3px;
    width: 7px;
    height: 7px;
    cursor: pointer;
    flex: 0 0 auto;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const ButtonsRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  text-align: right;
  margin: 5px 0;
  button {
    margin-left: 15px;
  }
`;

const EmptyContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const NotFoundImage = styled.img`
  max-height: 430px;
  margin: 10px;
`;

const NotFoundText = styled.p`
  @media ${maxDevice.tablet} {
    margin: 20px;
    font-size: 24px;
  }
  margin: 20px 40px 20px 40px;
  font-size: 28px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

const TargetMapListContainer = styled.div`
  margin-bottom: 40px;
  min-height: 300px;
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  position: relative;
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: ${(props) =>
      props.$showingCompare ? '0px 20px 110px 20px' : '0px 20px 20px 20px'};
  }
  margin: ${(props) =>
    props.$showingCompare ? '0px 40px 110px 40px' : '0px 40px 25px 40px'};
`;

const HeaderContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ColorKeyContainer = styled.div`
  @media (max-width: 930px) {
    justify-content: flex-start;
  }
  font-size: 11px;
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const ColorKey = styled.div`
  @media (max-width: 930px) {
    margin-right: 12px;
    margin-left: 0;
    margin-top: 5px;
  }
  margin-left: 12px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 6px;
  }
`;

const LoadingContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const FilterListRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
`;

const FiltersContainer = styled.div`
  margin: 10px 0 5px 0;
`;

const SectionLabel = styled.div`
  font-weight: ${fonts.semiBold};
  margin-bottom: ${(props) => (props.$expanded ? '-2px' : '8px')};
  color: ${colors.darkGrey};
  font-size: 13px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const FiltersList = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0 0 8px 0px;
`;

const FilterChip = styled.div`
  cursor: pointer;
  background: ${colors.lightGrey};
  padding: 4px 8px;
  font-size: 10px;
  margin-left: 8px;
  border-radius: 3px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  span {
    flex: 0 0 auto;
  }
`;

const ChevronIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 4px;
  opacity: 0.8;
`;

const FilterName = styled.div`
  font-weight: ${fonts.semiBold};
`;

const FiltersRow = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 15px;
  ${FilterContainer} {
    margin: 0 0 0 15px;
  }
  ${FilterContainer} {
    @media (max-width: 800px) {
      margin-bottom: 8px;
    }
  }
`;

HouseholdTargetMaps.propTypes = {
  targetMap: PropTypes.object,
  showPDF: PropTypes.bool,
};

export default HouseholdTargetMaps;
