import { HouseholdNewContainer } from '../../containers';
import { UpdatePageTitle, CloseDropdowns } from '../../utils';

const HouseholdNew = () => {
  UpdatePageTitle('New Household');
  CloseDropdowns();

  return <HouseholdNewContainer />;
};
export default HouseholdNew;
