import { useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, fonts, boxShadows } from '../styles/variables';
import PropTypes from 'prop-types';
import { UseOutsideClick } from '../utils';

const DropdownMenu = (props) => {
  const {
    heading = 'Actions',
    items = [],
    passedData,
    customStyle = {},
    showMoreMenu,
    setShowMoreMenu,
  } = props;

  const ref = useRef();

  UseOutsideClick(ref, () => {
    if (showMoreMenu) {
      setShowMoreMenu(false);
    }
  });

  return (
    <DropdownContainer ref={ref} customStyle={customStyle}>
      <DropdownHeading>{heading}</DropdownHeading>
      <DropDownContent>
        {items.map((item, index) => {
          return item.link ? (
            <DropdownItemLink key={index} to={item.link}>
              {item.icon && (
                <DropdownItemImage
                  src={item.icon}
                  alt={item.label}
                  data-image={item.value}
                />
              )}
              <DropdownItemText>{item.label}</DropdownItemText>
            </DropdownItemLink>
          ) : (
            <DropdownItem
              key={index}
              onClick={() => item.onClick(item.value, passedData)}
            >
              {item.icon && (
                <DropdownItemImage
                  src={item.icon}
                  alt={item.label}
                  data-image={item.value}
                />
              )}
              <DropdownItemText>{item.label}</DropdownItemText>
            </DropdownItem>
          );
        })}
      </DropDownContent>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 99;
  background: white;
  margin: ${(props) =>
    props.customStyle?.margin ? props.customStyle?.margin : '0'};
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  right: ${(props) =>
    props.customStyle?.right ? props.customStyle?.right : '4px'};
  top: ${(props) => props.customStyle?.top};
  width: ${(props) =>
    props.customStyle?.width ? props.customStyle?.width : '145px'};
  box-shadow: ${boxShadows.boxShadow};
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: 13px;
    top: -6px;
    z-index: 9999 !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${colors.darkGrey};
  }
`;

const DropdownHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  padding: 8px 12px;
  background: ${colors.darkGrey};
  color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropDownContent = styled.div`
  padding: 10px 12px;
`;

const DropdownItem = styled.div`
  margin: 8px 0;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    opacity: 0.75;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const DropdownItemLink = styled(Link)`
  margin: 8px 0;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.darkGrey};
  &:hover {
    opacity: 0.75;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const DropdownItemImage = styled.img`
  width: 16px;
  height: 16px;
  max-height: 14px;
  margin-right: 7px;
`;

const DropdownItemText = styled.p``;

DropdownMenu.propTypes = {
  ref: PropTypes.object,
  heading: PropTypes.string,
  items: PropTypes.array,
  passedData: PropTypes.object,
  customStyle: PropTypes.object,
  showMoreMenu: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setShowMoreMenu: PropTypes.func,
};

export default DropdownMenu;
