import styled, { keyframes, ThemeProvider } from 'styled-components';
import { colors } from '../styles/variables';
import PropTypes from 'prop-types';

const LoadingAnimation = ({
  margin,
  dots = false,
  color = colors.lightBlue,
  smaller = false,
  button = false,
  table = false,
}) => {
  return dots ? (
    <DotsContainer
      margin={margin}
      color={color}
      smaller={smaller}
      button={button}
      table={table}
    >
      <Bounce1 />
      <Bounce2 />
      <Bounce3 />
    </DotsContainer>
  ) : (
    <ThemeProvider theme={{ smaller }}>
      <LogoContainer margin={margin}>
        <Row>
          <LightBlueSquare />
        </Row>
        <Row>
          <PinkSquare />
          <CenterSquare />
          <GreenSquare />
        </Row>
        <Row>
          <OrangeSquare />
        </Row>
      </LogoContainer>
    </ThemeProvider>
  );
};

const animation = {
  duration: '.8s',
  delay: '.3s',
  iteration: 'infinite',
  distance: '20px',
  smallDistance: '5px',
};

const sides = {
  long: '35px',
  smallLong: '11px',
  short: '30px',
  smallShort: '8px',
  height: '30px',
  smallHeight: '8px',
};

const Bounce1 = styled.div``;
const Bounce2 = styled.div``;
const Bounce3 = styled.div``;

const bounceDelay = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

const DotsContainer = styled.div`
  margin: 0;
  width: ${(props) => (props.smaller ? '25px' : '33px')};
  margin-right: ${(props) => (props.table ? '0px' : '5px')};
  margin-left: ${(props) => (props.smaller ? '-5px' : '10px')};
  margin: ${(props) =>
    props.table ? '0px' : props.margin ? props.margin : null};
  text-align: center;
  div {
    width: ${(props) => (props.smaller ? '6px' : '11px')};
    height: ${(props) => (props.smaller ? '6px' : '11px')};
    background-color: ${colors.lightBlue};
    opacity: ${(props) => (props.button ? '1' : '.7')};
    border-radius: 100%;
    display: inline-block;
    animation: ${bounceDelay} 1.2s infinite ease-in-out both;
  }
  ${Bounce1}, ${Bounce2}, ${Bounce3} {
    background-color: ${(props) => props.color};
  }
  ${Bounce1} {
    animation-delay: -0.32s;
  }
  ${Bounce2} {
    animation-delay: -0.16s;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const BaseSquare = styled.div`
  animation-duration: ${animation.duration};
  animation-delay: ${animation.delay};
  animation-iteration-count: ${animation.iteration};
  animation-fill-mode: forwards;
`;

const lightBlueAnimation = (smaller) => keyframes`
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-${
      smaller ? animation.smallDistance : animation.distance
    });
    height: ${smaller ? sides.smallHeight : sides.height};
    border-radius: 50px;
  }
  60% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const LightBlueSquare = styled(BaseSquare)`
  background: ${colors.lightBlue};
  height: ${(props) => (props.theme.smaller ? sides.smallLong : sides.long)};
  width: ${(props) => (props.theme.smaller ? sides.smallShort : sides.short)};
  border-radius: ${(props) =>
    props.theme.smaller ? '1px 1px 0 0' : '3px 3px 0 0'};
  animation-name: ${(props) => lightBlueAnimation(props.theme.smaller)};
`;

const greenAnimation = (smaller) => keyframes`
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateX(${
      smaller ? animation.smallDistance : animation.distance
    });
    width: ${smaller ? sides.smallHeight : sides.height};
    border-radius: 50px;
  }
  60% {
    transform: translateX(5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const GreenSquare = styled(BaseSquare)`
  background: ${colors.green};
  height: ${(props) => (props.theme.smaller ? sides.smallShort : sides.short)};
  width: ${(props) => (props.theme.smaller ? sides.smallLong : sides.long)};
  border-radius: ${(props) =>
    props.theme.smaller ? '0 1px px 0' : '0 3px 3px 0'};
  animation-name: ${(props) => greenAnimation(props.theme.smaller)};
`;

const pinkAnimation = (smaller) => keyframes`
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateX(-${
      smaller ? animation.smallDistance : animation.distance
    });
    width: ${smaller ? sides.smallHeight : sides.height};
    border-radius: 50px;
  }
  60% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const PinkSquare = styled(BaseSquare)`
  background: ${colors.pink};
  height: ${(props) => (props.theme.smaller ? sides.smallShort : sides.short)};
  width: ${(props) => (props.theme.smaller ? sides.smallLong : sides.long)};
  border-radius: ${(props) =>
    props.theme.smaller ? '1px 0 0 1px' : '3px 0 0 3px'};
  animation-name: ${(props) => pinkAnimation(props.theme.smaller)};
`;

const orangeAnimation = (smaller) => keyframes`
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(
      ${smaller ? animation.smallDistance : animation.distance});
    height: ${smaller ? sides.smallHeight : sides.height};
    border-radius: 50px;
  }
  60% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
`;

const OrangeSquare = styled(BaseSquare)`
  background: ${colors.orange};
  height: ${(props) => (props.theme.smaller ? sides.smallLong : sides.long)};
  width: ${(props) => (props.theme.smaller ? sides.smallShort : sides.short)};
  border-radius: ${(props) =>
    props.theme.smaller ? '0 0 1px 1px' : '0 0 3px 3px'};
  animation-name: ${(props) => orangeAnimation(props.theme.smaller)};
`;

const CenterSquare = styled(BaseSquare)`
  background: ${colors.blue};
  animation-name: center;
  height: ${(props) => (props.theme.smaller ? sides.smallShort : sides.short)};
  width: ${(props) => (props.theme.smaller ? sides.smallShort : sides.short)};
  @keyframes center {
    10% {
      border-radius: 50px;
    }
    100% {
      border-radius: 0;
    }
  }
`;

const LogoContainer = styled.div`
  margin: ${(props) => (props.margin ? props.margin : null)};
  height: ${(props) => (props.theme.smaller ? '50px' : '150px')};
  width: ${(props) => (props.theme.smaller ? '50px' : '150px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  animation-name: logo;
  animation-duration: ${animation.duration};
  animation-delay: ${animation.delay};
  animation-iteration-count: ${animation.iteration};
  animation-fill-mode: forwards;
  transform: rotate(0deg);
  @keyframes logo {
    0% {
      transform: rotate(36deg);
    }
    10% {
      transform: rotate(72deg);
    }
    20% {
      transform: rotate(108deg);
    }
    30% {
      transform: rotate(144deg);
    }
    40% {
      transform: rotate(180deg);
    }
    50% {
      transform: rotate(216deg);
    }
    60% {
      transform: rotate(252deg);
    }
    70% {
      transform: rotate(288deg);
    }
    80% {
      transform: rotate(324deg);
    }
    90% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

LoadingAnimation.propTypes = {
  margin: PropTypes.string,
  dots: PropTypes.bool,
  color: PropTypes.string,
  smaller: PropTypes.bool,
  button: PropTypes.bool,
  table: PropTypes.bool,
};

export default LoadingAnimation;
