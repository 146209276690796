/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { GenerateInput, Button, Error, DetectModalKeys } from '..';
import { updateTargetMapInstrument } from '../../store/actions';
import { timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  MatchSelectItem,
  CheckHasError,
  GenerateError,
  UseHandleInstrumentError,
  CreateAmountPlaceholder,
  CheckOverwritten,
  PruneObjectNulls,
  DetectEnterKeyPress,
  DateFormat,
} from '../../utils';

const CustomInsuranceModal = ({
  hide,
  householdId,
  instrumentInfo,
  targetMapId,
  currency,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    currentHousehold,
    targetMapInstrumentError,
    instrumentDateReference,
    preferences,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    targetMapInstrumentError: state.targetMaps.targetMapInstrumentError,
    instrumentDateReference: state.configs.instrumentDateReference,
    preferences: state.user.user.preferences,
  }));
  const [dateType, setDateType] = useState({ label: 'Age', value: 'age' });
  const [placeholderInsurance, setPlaceholderInsurance] = useState({
    amount: '',
    start_age: '',
    start_date: '',
    inflation_rate: '',
    tax_rate: '',
    is_active: true,
  });
  const [currentInsurance, setCurrentInsurance] = useState({
    description: '',
    name: '',
    amount: '',
    start_age: '',
    start_date: '',
    inflation_rate: '',
    tax_rate: '',
    is_active: true,
  });
  const [ownersList, setOwnersList] = useState([]);
  const [insuredList, setInsuredList] = useState([]);
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();

  UseHandleInstrumentError(
    targetMapInstrumentError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle
  );

  useEffect(() => {
    if (instrumentInfo) {
      setDateType(
        MatchSelectItem(instrumentDateReference, instrumentInfo.date_reference)
      );
      setCurrentInsurance(PruneObjectNulls(instrumentInfo));
      setOwnersList(
        instrumentInfo.members.filter((mem) => mem.relation === 'owner')
      );
      setInsuredList(
        instrumentInfo.members.filter((mem) => mem.relation === 'insured')
      );
      setBeneficiaryList(
        instrumentInfo.members.filter((mem) => mem.relation === 'beneficiary')
      );
      setPlaceholderInsurance(instrumentInfo);
    }
  }, [instrumentInfo, currentHousehold]);

  const checkHasValue = (value) => {
    if (value === '' || value === null || value === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const saveOnEnter = () => {
    if (!buttonLoading) {
      return saveInsurance();
    }
  };

  const saveInsurance = () => {
    setErrorTitle();

    let amountValue = parseInt(currentInsurance.amount);

    let inflationRate = 0;
    if (
      currentInsurance.inflation_rate !== null &&
      currentInsurance.inflation_rate !== ''
    ) {
      inflationRate = parseInt(currentInsurance.inflation_rate);
    }
    let taxRate = 0;
    if (
      currentInsurance.tax_rate !== null &&
      currentInsurance.tax_rate !== ''
    ) {
      taxRate = parseInt(currentInsurance.tax_rate);
    }

    let instrumentObject = {
      name: currentInsurance.name,
      description: currentInsurance.description,
      amount: amountValue,
      date_reference: dateType.value,
      tax_rate: taxRate,
      inflation_rate: inflationRate,
      is_active: currentInsurance.is_active,
    };

    if (dateType.value === 'age') {
      if (checkHasValue(currentInsurance.start_age)) {
        instrumentObject.start_age = currentInsurance.start_age;
      }
    }

    if (dateType.value === 'date') {
      if (checkHasValue(currentInsurance.start_date)) {
        instrumentObject.start_date = DateFormat(
          currentInsurance.start_date,
          'YYYY-MM-DD'
        );
      }
    }

    setButtonLoading(true);

    dispatch(
      updateTargetMapInstrument(
        householdId,
        targetMapId,
        'insurance',
        currentInsurance.id,
        instrumentObject
      )
    );
  };

  const updateCurrentInsurance = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors('');
    if (isSelect) {
      setCurrentInsurance({
        ...currentInsurance,
        [fieldName]: e,
      });
    } else {
      setCurrentInsurance({
        ...currentInsurance,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const formatFunction = (values, input) => {
    setModalError('');
    setModalErrors();
    let setValue;
    if (values.hasOwnProperty('floatValue')) {
      setValue = values.floatValue;
    } else if (values.currentTarget?.value) {
      setValue = values.currentTarget.value;
    }

    if (setValue === 'NaN') {
      setValue = '';
    }

    setCurrentInsurance({
      ...currentInsurance,
      [input.name]: setValue,
    });
  };

  const essentialInputs = [
    {
      type: 'text',
      label: 'Name',
      name: 'name',
      required: false,
      placeholder: 'Name',
      value: currentInsurance.name,
      width: '48%',
      isVisible: true,
      disabled: true,
      onChange: (e) => updateCurrentInsurance(e),
    },
    {
      type: 'text',
      label: 'Insurance Provider',
      maxLength: '120',
      name: 'description',
      width: '48%',
      required: false,
      placeholder: 'Insurance Provider',
      isVisible: true,
      value: currentInsurance.description,
      disabled: true,
      onChange: (e) => updateCurrentInsurance(e),
    },
    {
      type: 'member-display',
      label: 'Owner',
      width: '100%',
      isVisible: true,
      value: ownersList,
    },
    {
      type: 'member-display',
      label: 'Insured',
      width: '100%',
      isVisible: true,
      value: insuredList,
    },
    {
      type: 'member-display',
      label: 'Primary Beneficiary',
      width: '100%',
      isVisible: true,
      value: beneficiaryList,
    },
    {
      type: 'calculatorCurrency',
      label: CheckOverwritten(false, instrumentInfo, currentHousehold)
        ? 'Amount (Overwritten)'
        : 'Amount',
      maxLength: '80',
      name: 'amount',
      width: '100%',
      required: false,
      placeholder: CreateAmountPlaceholder(instrumentInfo, currency),
      isVisible: true,
      value: currentInsurance.amount,
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'amount'),
      errorMessage: GenerateError(modalErrors, 'amount'),
    },
    {
      type: 'number',
      label: 'Adjustment (COLA)',
      maxLength: '2',
      name: 'inflation_rate',
      width: '48%',
      required: false,
      hasSuffix: true,
      suffixValue: '%',
      placeholder: placeholderInsurance.inflation_rate,
      value: currentInsurance.inflation_rate,
      isVisible: true,
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'inflation_rate'),
      errorMessage: GenerateError(modalErrors, 'inflation_rate'),
    },
    {
      type: 'number',
      label: 'Tax Rate',
      maxLength: '2',
      name: 'tax_rate',
      width: '48%',
      required: false,
      hasSuffix: true,
      suffixValue: '%',
      placeholder: placeholderInsurance.tax_rate,
      tooltip: `Percentage lost to taxes.`,
      value: currentInsurance.tax_rate,
      isVisible: true,
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'tax_rate'),
      errorMessage: GenerateError(modalErrors, 'tax_rate'),
    },
    {
      type: 'select',
      label: 'Date Reference',
      name: 'date_reference',
      width: '48%',
      required: false,
      placeholder: 'Date Reference',
      value: dateType,
      options: instrumentDateReference,
      isVisible: true,
      onChange: (e) => setDateType(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'date_reference'),
      errorMessage: GenerateError(modalErrors, 'date_reference'),
    },
    {
      type: 'number',
      label: 'Start Age',
      maxLength: '80',
      name: 'start_age',
      width: '48%',
      required: false,
      placeholder: placeholderInsurance.start_age,
      value: currentInsurance.start_age,
      isVisible: dateType.value === 'age',
      onChange: (e) => updateCurrentInsurance(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'start_age'),
      errorMessage: GenerateError(modalErrors, 'start_age'),
    },
    {
      type: 'date',
      label: 'Start Date',
      name: 'start_date',
      width: '48%',
      required: false,
      placeholder: placeholderInsurance.start_date,
      dateFormat: preferences?.date_format,
      value: currentInsurance.start_date,
      isVisible: dateType.value === 'date',
      onChange: (e) => updateCurrentInsurance(e, true, 'start_date'),
      hasError: CheckHasError(modalErrors, 'start_date'),
      errorMessage: GenerateError(modalErrors, 'start_date'),
    },
    {
      type: 'checkbox',
      label: 'Included in this Target-Map',
      name: 'is_active',
      required: false,
      width: '48%',
      value: currentInsurance.is_active,
      isVisible: true,
      onChange: () =>
        setCurrentInsurance({
          ...currentInsurance,
          is_active: !currentInsurance.is_active,
        }),
    },
  ];

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={saveOnEnter} onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Edit Target-Map Insurance</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <TabContent>
              {essentialInputs.map((input, index) => {
                return GenerateInput(input, index, currency);
              })}
            </TabContent>
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={'Update'}
                  onClick={() => saveInsurance()}
                  showLoading={buttonLoading}
                  loadingText={'Saving'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

CustomInsuranceModal.propTypes = {
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  householdId: PropTypes.string,
  instrumentInfo: PropTypes.object,
  targetMapId: PropTypes.string,
  currency: PropTypes.object,
};

export default CustomInsuranceModal;
