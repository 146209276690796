/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EntryRouter } from './routes';
import {
  getAuthStatus,
  getUserInfo,
  logUserOut,
  updateUserStoreValue,
} from './store/actions';
import { GlobalStyle, AppContainer } from './styles/globalStyle';
import { InfoNotificationClick } from './utils/notifications';

/*
  Parent of entire app
  - Renders global styles - resets and configs basic css for all html types
  - Renders container div for entire app which renders the entry router
*/
const App = () => {
  const dispatch = useDispatch();
  const authTimerRef = useRef();
  const { user, isConsumer, authStatus } = useSelector((state) => ({
    user: state.user.user,
    isConsumer: state.user.isConsumer,
    authStatus: state.user.authStatus,
  }));
  const [showingToast, setShowingToast] = useState(false);
  const minTimeout = 120;

  const toastOnClick = () => {
    dispatch(getUserInfo());
    setShowingToast(false);
  };

  //HOOK TO SET TIMEOUT FOR GETTING USER'S AUTH STATUS
  useEffect(() => {
    if (authStatus) {
      //DEFAULT TIMEOUT LENGTH
      let timeoutLength = 3600;
      //IF USER IS AUTHENTICATED SET A TIMEOUT TO CHECK
      if (authStatus?.status === 'authenticated') {
        //IF LIFETIME IS LESS THAN OUR MIN TIMEOUT SHOW AN EXTEND MESSAGE
        if (authStatus?.lifetime <= minTimeout) {
          //STILL WILL CHECK STATUS AT END OF LIFETIME
          timeoutLength = (authStatus?.lifetime + 1) * 1000;
          setShowingToast(true);
          //DISPLAY TOAST MESSAGE ASKING USER TO EXTEND SESSION
          if (!showingToast) {
            InfoNotificationClick(
              'You are about to be logged out due to inactivity. Click to extend your session.',
              toastOnClick,
              0,
              'top-center',
              'extend-session'
            );
          }
        } else {
          //IF LIFETIME IS ABOVE MIN TIMEOUT SET TIMEOUT TO GET AGAIN
          timeoutLength = (authStatus?.lifetime - minTimeout + 1) * 1000;
        }
        //TIMEOUT FUNCTION TO GET AUTH STATUS AT END OF CALCULATED LIFETIME
        authTimerRef.current = setTimeout(() => {
          dispatch(getAuthStatus());
        }, timeoutLength);
      } else {
        //IF USER IS NOT AUTHENTICATED RETURNED FROM AUTH STATUS LOG THEM OUT AND REMOVE MESSAGE
        const element = document.getElementById('ct-container');
        if (element) {
          element.remove();
          setShowingToast(false);
        }
        dispatch(updateUserStoreValue('isTimeout', true));
        dispatch(logUserOut());
      }
    }
    return () => {
      clearTimeout(authTimerRef.current);
    };
  }, [authStatus]);

  /*
    Hook to render Intercom chat bubble (support)
    - Checks if user has intercom access
    - Then sets intercom will all values needed from user
    - Also the intercom bubble is hidden on certain routes
  */
  useEffect(() => {
    if (user) {
      if (user.hasOwnProperty('third_party')) {
        if (user.third_party.hasOwnProperty('intercom')) {
          console.log('user intercom', user.third_party.intercom);
          if (isConsumer) {
            return window.Intercom('shutdown');
          } else {
            const intercomData = user.third_party.intercom;
            if (window?.Intercom) {
              // setIntercomId(intercomData.app_id);
              const { pathname } = window.location;
              const blockedRoutes = ['assetmap', 'targetmap'];
              const splitPathName = pathname.split('/');
              const blockedRoute = splitPathName.some((r) =>
                blockedRoutes.includes(r)
              );
              window.Intercom('boot', {
                app_id: intercomData.app_id,
                hide_default_launcher: blockedRoute,
                user_id: intercomData.uuid,
                uuid: intercomData.uuid,
                sitehash: intercomData.sitehash,
                site: intercomData.site,
                subscription_tier: intercomData.subscription_tier,
                user_hash: intercomData.user_hash,
                name: user.name,
                email: user.email,
                created_at: intercomData.created_at,
                custom_launcher_selector: '.launch-intercom',
              });
            }
          }
        }
      }
    }
  }, [user]);

  return (
    <>
      <GlobalStyle />
      <AppContainer>
        <EntryRouter isConsumer={isConsumer} />
      </AppContainer>
    </>
  );
};

export default App;
