import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { showLoginScreen } from '../../store/actions';
import { Button } from '../../components';
import { LineBreak } from '../../styles/library/layoutStyles';
import {
  AuthText,
  EmailLink,
  LinkStyle,
} from '../../styles/library/fontStyles';
import { ButtonThemes } from '../../styles/themes';

const HelpModal = ({ isConsumer = false }) => {
  const dispatch = useDispatch();

const commonText = `If you are unable to remember your username or password,
or if you no longer have access to the email address associated with
the account, 
`;

return (
  <>
    {!isConsumer ? (
      <>
        <AuthText>
          {commonText} please email us at (
          <EmailLink href="mailto:support@asset-map.com">
            support@asset-map.com
          </EmailLink>
          ) and we&apos;ll be happy to assist you.
        </AuthText>
        <LineBreak />
        <AuthText>
          If you do not have an account with Asset-Map,{' '}
          <LinkStyle
            href="https://www.asset-map.com/schedule-a-demo"
            target="_blank"
          >
            schedule a demo today
          </LinkStyle>
          .
        </AuthText>{' '}
      </>
    ) : (
      <>
        <AuthText>
          {commonText} please contact your advisor or{' '}
          <LinkStyle
            href="https://intercom.help/asset-map-client-portal-support/en/"
            target="_blank"
          >
            click here to access our help center
          </LinkStyle>
          .
        </AuthText>{' '}
      </>
    )}

    <Button
      onClick={() => dispatch(showLoginScreen())}
      theme={ButtonThemes.primaryFull}
      text="Return to Sign In"
    />
  </>
);
};

HelpModal.propTypes = {
  isConsumer: PropTypes.bool,
};

export default HelpModal;
