/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InviteModal } from '../../components';
import {
  getInviteDetails,
  toggleShowInviteModal,
  updateUserStoreValue,
} from '../../store/actions';
import { Page } from '../../styles/library/layoutStyles';

const ConsumerInvite = ({ redirected, isVerify = false }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { showingInviteModal } = useSelector((state) => ({
    showingInviteModal: state.user.showingInviteModal,
  }));
  const [inviteScreenValue, setInviteScreenValue] = useState('loading');

  //DETECT THE CONSUMER INVITE REDIRECT AND SHOW MODAL
  useEffect(() => {
    if (redirected) {
      dispatch(getInviteDetails(params.id));
      dispatch(updateUserStoreValue('consumerInviteId', params.id));
      dispatch(toggleShowInviteModal(true, 'loading'));
    }
  }, [redirected]);

  //CHECK IF THE URL IS FOR VERIFY THEN SHOW THAT MODAL SCREEN
  useEffect(() => {
    if (isVerify) {
      dispatch(toggleShowInviteModal(true));
      setInviteScreenValue('verify');
    }
  }, [isVerify]);

  return (
    <Page>
      {showingInviteModal && (
        <InviteModal
          isAuth={true}
          screen={inviteScreenValue}
          hide={() => {
            dispatch(toggleShowInviteModal(false, 'loading'));
          }}
          setScreenValue={setInviteScreenValue}
        />
      )}
    </Page>
  );
};

ConsumerInvite.propTypes = {
  redirected: PropTypes.bool,
  isVerify: PropTypes.bool,
};

export default ConsumerInvite;
