/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Error, GenerateInput, PermissionListItem } from '../';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import { colors, fonts } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';

const CreateRoleModal = ({
  hide,
  role,
  error,
  loading,
  buttonLoading,
  onChange,
  onSave,
}) => {
  const ref = useRef();

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Create Permission Set</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            {GenerateInput({
              isVisible: true,
              width: '100%',
              required: true,
              label: 'Name',
              type: 'text',
              mobileWidth: '100%',
              value: role?.name,
              placeholder: `Permission Set Name`,
              hasError: role?.nameError,
              errorMessage: role?.nameErrorMessage,
              onChange: (e) => onChange(e, true),
            })}
            <PermissionsContainer>
              <PermissionsHeading>Permissions</PermissionsHeading>
              {!loading
                ? role?.scopes.map((permission, index) => {
                    return (
                      !permission.hidden && (
                        <PermissionListItem
                          key={index}
                          permission={permission}
                          onChange={onChange}
                          showValue={true}
                          viewOnly={false}
                        />
                      )
                    );
                  })
                : [0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => {
                    return (
                      <PermissionListItem
                        key={index}
                        isLoading={true}
                        showValue={true}
                      />
                    );
                  })}
            </PermissionsContainer>
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={'Create'}
                  onClick={onSave}
                  showLoading={buttonLoading}
                  loadingText="Creating"
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {error && error !== '' && <Error errorMessage={error} />}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const PermissionsContainer = styled.div`
  margin: 15px 0 15px 0;
  text-align: left;
`;

const PermissionsHeading = styled.p`
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
  padding-bottom: 3px;
`;

CreateRoleModal.propTypes = {
  hide: PropTypes.func,
  role: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  buttonLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default CreateRoleModal;
