/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Button, TextInput, Error, DetectModalKeys } from '../components';
import { resetUserPassword } from '../store/actions';
import { AuthText } from '../styles/library/fontStyles';
import { TextInputContainer, InputLabel } from '../styles/library/inputStyles';
import { backgroundAuth } from '../assets';
import {
  BackgroundPage,
  CenteredContainer,
} from '../styles/library/layoutStyles';
import {
  CenterModal,
  ModalHeader,
  ModalHeadingConfig,
  ModalContent,
} from '../styles/library/modalStyles';
import { ButtonThemes } from '../styles/themes';
import { DetectEnterKeyPress, UpdatePageTitle } from '../utils';

const ResetPassword = () => {
  UpdatePageTitle('Reset Password');

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { passwordResetError, passwordUpdated } = useSelector((state) => ({
    passwordResetError: state.user.passwordResetError,
    passwordUpdated: state.user.passwordUpdated,
  }));
  const [showModalError, setShowModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [passwords, setPasswords] = useState({
    password: { value: '', hasError: false },
    password2: { value: '', hasError: false },
  });
  const [token, setToken] = useState();
  const search = location.search;

  const createErrorMessage = (errors) => {
    return Object.entries(errors).map((entry) => {
      if (entry[0] === 'new_password') {
        const missingErrors = entry[1].reduce((acc, curr, index) => {
          if (index !== entry[1].length - 1) {
            curr = curr + ', ';
          } else {
            curr = curr + '.';
          }
          return acc + curr;
        }, '');
        return `Password is missing ${missingErrors}`;
      } else {
        return entry[1].map((err) => {
          return err;
        });
      }
    });
  };

  useEffect(() => {
    if (search) {
      let tempToken;
      tempToken = new URLSearchParams(location.search).get('token');
      if (tempToken === undefined) {
        tempToken = search.split('?token=');
      }
      setToken(tempToken);
    }
  }, [location.search, search]);

  useEffect(() => {
    if (passwordResetError) {
      const { password, password2 } = passwords;
      const passwordError = passwordResetError.hasOwnProperty('new_password');
      setPasswords({
        password: { value: password.value, hasError: passwordError },
        password2: { value: password2.value, hasError: passwordError },
      });
      let message = createErrorMessage(passwordResetError);
      setModalErrorMessage(message[0]);
      setShowModalError(true);
    }
  }, [passwordResetError]);

  useEffect(() => {
    if (passwordUpdated) {
      return navigate('/auth');
    }
  }, [passwordUpdated]);

  const onSubmitForm = () => {
    let hasError;
    const { password, password2 } = passwords;
    if (password?.value === '') {
      setPasswords({
        ...passwords,
        password: { value: password.value, hasError: true },
      });
      setModalErrorMessage('Missing required field');
      hasError = true;
    } else if (password2?.value === '') {
      setPasswords({
        ...passwords,
        password2: { value: password2.value, hasError: true },
      });
      hasError = true;
      setModalErrorMessage('Missing required field');
    } else if (password?.value !== password2?.value) {
      setPasswords({
        password: { value: password.value, hasError: true },
        password2: { value: password2.value, hasError: true },
      });
      setModalErrorMessage('Passwords must match');
      hasError = true;
    } else if (token === undefined) {
      setModalErrorMessage(
        'Invalid URL. Check URL is the same as sent in email.'
      );
      hasError = true;
    }

    if (hasError) {
      setShowModalError(true);
    } else {
      const obj = {
        new_password: password?.value,
        password_reset_token: token,
      };
      dispatch(resetUserPassword(obj));
    }
  };

  const updatePassword = (e) => {
    setPasswords({
      ...passwords,
      [e.currentTarget.name]: { value: e.currentTarget.value, hasError: false },
    });
    setShowModalError(false);
    setModalErrorMessage('');
  };

  const backgroundTheme = {
    backgroundImage: backgroundAuth,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  };

  return (
    <ThemeProvider theme={backgroundTheme}>
      <DetectModalKeys onEnter={onSubmitForm} />
      <BackgroundPage>
        <CenteredContainer>
          <CenterModal>
            <ModalHeader>
              <ModalHeadingConfig>Reset Password</ModalHeadingConfig>
            </ModalHeader>
            <ModalContent>
              <AuthText style={{ paddingBottom: '0' }}>
                Your new password must contain at least 8 characters, one
                uppercase letter and one number.
              </AuthText>
              <TextInputContainer autoWidth={true} margin={'15px 0'}>
                <InputLabel>Password</InputLabel>
                <TextInput
                  type="password"
                  name="password"
                  value={passwords?.password?.value}
                  placeholder="New Password"
                  onChange={updatePassword}
                  hasError={passwords?.password?.hasError}
                  onKeyPress={(e) => DetectEnterKeyPress(e, onSubmitForm)}
                />
              </TextInputContainer>
              <TextInputContainer autoWidth={true} margin={'15px 0'}>
                <InputLabel>Retype Password</InputLabel>
                <TextInput
                  type="password"
                  name="password2"
                  value={passwords?.password2?.value}
                  placeholder="Retype Password"
                  onChange={updatePassword}
                  hasError={passwords?.password2?.hasError}
                  onKeyPress={(e) => DetectEnterKeyPress(e, onSubmitForm)}
                />
              </TextInputContainer>
              <Button
                onClick={onSubmitForm}
                text={'Update Password'}
                theme={ButtonThemes.primaryFull}
              />
            </ModalContent>
            {showModalError && <Error errorMessage={modalErrorMessage} />}
          </CenterModal>
        </CenteredContainer>
      </BackgroundPage>
    </ThemeProvider>
  );
};

export default ResetPassword;
