/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { lighten } from 'polished';
import { Error } from '../';
import { colors, fonts, messageColors } from '../../styles/variables';
import {
  getSurveyInstance,
  updateHouseholdStoreValue,
} from '../../store/actions';
import { timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  CloseButton,
} from '../../styles/library/modalStyles';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { DateFormatUTC, DateFromNowUTC } from '../../utils';

const SurveyResponsesModal = ({ hide, survey, errors }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { surveyInstance } = useSelector((state) => ({
    surveyInstance: state.households.surveyInstance,
  }));
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('surveyInstance', null));
    };
  }, []);

  useEffect(() => {
    if (survey?.id) {
      setLoading(true);
      dispatch(getSurveyInstance(survey?.id));
    }
  }, [survey?.id]);

  useEffect(() => {
    if (surveyInstance) {
      setLoading(false);
      setQuestions(surveyInstance.state.flat());
    }
  }, [surveyInstance?.state]);

  const generateResponse = (question, index) => {
    let hasNoResponse =
      question.value === '' && question?.type !== 'no-response';
    if (question?.type === 'checkbox') {
      hasNoResponse = question.value === [];
    }
    return (
      <ResponseContainer key={index} last={index === questions?.length - 1}>
        <QuestionContainer hasNoResponse={hasNoResponse}>
          {/* <QuestionNumber>{index + 1}. </QuestionNumber> */}
          <QuestionText dangerouslySetInnerHTML={{ __html: question?.text }} />
        </QuestionContainer>
        {question?.type === 'no-response' ? null : question?.type === 'text' ||
          question?.type === 'short-text' ? (
          question.value ? (
            <ResponseValue>{question.value}</ResponseValue>
          ) : (
            <NoResponseValue>No Response</NoResponseValue>
          )
        ) : (
          <QuestionOptions>
            {question.options.map((option, index) => {
              const selected = question.value.includes(option.id);
              return (
                <QuestionOption
                  key={index}
                  selected={selected}
                  hasNoResponse={hasNoResponse}
                >
                  {selected && (
                    <CheckIcon>
                      <svg
                        width="33"
                        height="26"
                        viewBox="0 0 33 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        data-image={`check-${index}`}
                      >
                        <path
                          opacity="0.6"
                          d="M32.3897 5.81601L16.9595 21.2617L12.2773 16.5904L27.7307 1.14989C27.8825 0.997539 28.0629 0.87645 28.2617 0.793542C28.4605 0.710634 28.6737 0.667532 28.8893 0.666699C29.1048 0.665866 29.3184 0.707319 29.5178 0.788688C29.7172 0.870057 29.8986 0.989747 30.0515 1.14092L30.0605 1.14989L32.3897 3.48263C32.6989 3.79294 32.8723 4.21228 32.8723 4.64932C32.8723 5.08636 32.6989 5.5057 32.3897 5.81601Z"
                          fill={colors.green}
                        />
                        <path
                          d="M17.0305 21.252L13.5191 24.7427C13.2126 25.0481 12.796 25.2206 12.3608 25.2222C11.9257 25.2239 11.5077 25.0546 11.1988 24.7516L11.1898 24.7427L0.482718 14.0955C0.173518 13.7875 0 13.3712 0 12.9373C0 12.5034 0.173518 12.0871 0.482718 11.7791L2.81203 9.46277C3.11804 9.15815 3.53375 8.98595 3.96799 8.98392C4.40223 8.98189 4.81956 9.15021 5.12847 9.45195L5.13876 9.46277L17.0305 21.252Z"
                          fill={colors.green}
                        />
                      </svg>
                    </CheckIcon>
                  )}
                  <span dangerouslySetInnerHTML={{ __html: option?.text }} />
                </QuestionOption>
              );
            })}
          </QuestionOptions>
        )}
      </ResponseContainer>
    );
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer
          role="dialog"
          ref={ref}
          style={{ minWidth: '525px', maxWidth: '525px' }}
        >
          <ModalHeadingContainer>
            <ModalHeading>{survey?.name} Responses</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            {loading ? (
              <ModalText style={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingPlaceholder width="60%" />
              </ModalText>
            ) : (
              <ModalText>
                <strong>Last Modified:</strong>{' '}
                {DateFormatUTC(surveyInstance?.modified_utc, 'lll', true)} (
                {DateFromNowUTC(surveyInstance?.modified_utc, true)})
              </ModalText>
            )}
            {!loading && surveyInstance?.status !== 'closed' && (
              <ModalWarningText>
                Warning: The most recent version of this questionnaire has not
                been completed yet. Unanswered questions are displayed with
                italics.
              </ModalWarningText>
            )}
            <ResponsesContainer>
              {loading
                ? [0, 1, 2, 3, 4, 5].map((index) => {
                    return (
                      <ResponseContainer key={index} last={index === 5}>
                        <QuestionContainer>
                          <LoadingPlaceholder width="100%" />
                          <LoadingPlaceholder width="100%" />
                          <LoadingPlaceholder width="60%" />
                        </QuestionContainer>
                        <ResponseValue>
                          <LoadingPlaceholder width="40%" />
                        </ResponseValue>
                      </ResponseContainer>
                    );
                  })
                : questions.map((question, index) => {
                    return generateResponse(question, index);
                  })}
            </ResponsesContainer>
          </ModalContentContainer>
          {errors && errors !== '' && <Error errorMessage={errors} />}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const CheckIcon = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: ${lighten(0.5, colors.green)};
  width: 27px;
  height: 27px;
  flex: 0 0 auto;
  border-radius: 100%;
  margin-right: 10px;
  svg {
    width: 14px;
  }
`;

const QuestionContainer = styled.div`
  margin-bottom: 15px;
  font-weight: ${(props) =>
    props.hasNoResponse ? fonts.normal : fonts.semiBold};
  font-style: ${(props) => props.hasNoResponse && 'italic'};
  color: ${(props) =>
    props.hasNoResponse ? colors.paleGrey : colors.darkGrey};
`;

// const QuestionNumber = styled.span``;
const QuestionText = styled.span``;

const ResponseValue = styled.div`
  margin-left: 20px;
`;

const NoResponseValue = styled(ResponseValue)`
  color: ${colors.paleGrey};
  font-style: italic;
`;

const QuestionOptions = styled.div`
  /* margin-left: 5px; */
  font-size: 13px;
`;

const QuestionOption = styled.div`
  font-weight: ${(props) => props.selected && fonts.semiBold};
  color: ${(props) =>
    props.hasNoResponse
      ? colors.paleGrey
      : props.selected
        ? colors.green
        : colors.darkGrey};
  font-style: ${(props) => props.hasNoResponse && 'italic'};
  margin-bottom: 10px;
  margin-left: ${(props) => props.selected || '37px'};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const ResponsesContainer = styled.div`
  text-align: left;
`;

const ResponseContainer = styled.div`
  margin-bottom: 20px;
  padding-bottom: ${(props) => (props.last ? 0 : '10px')};
  border-bottom: ${(props) =>
    props.last ? '1px solid transparent' : `1px solid ${colors.lightGrey}`};
`;

const ModalHeadingContainer = styled.div`
  padding: 12px 22px;
  background: ${colors.darkGrey};
  border-radius: 6px 6px 0 0;
  position: relative;
`;

const ModalHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  background: ${colors.darkGrey};
  color: ${colors.white};
  font-size: 15px;
`;

const ModalContentContainer = styled.div`
  padding: 20px 30px;
  max-height: 500px;
  overflow-y: auto;
`;

const ModalText = styled.p`
  font-size: 13px;
  margin-bottom: 15px;
  color: ${colors.paleGrey};
`;

const ModalWarningText = styled(ModalText)`
  margin-bottom: 20px;
  font-weight: ${fonts.bold};
  color: ${messageColors.error};
`;

SurveyResponsesModal.propTypes = {
  hide: PropTypes.func,
  survey: PropTypes.object,
  errors: PropTypes.string,
};

export default SurveyResponsesModal;
