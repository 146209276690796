import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, maxDevice, fonts } from '../styles/variables';

const TabMenu = ({ tabs, activeTab, onClick, noBottomMargin = false }) => {
  return (
    <ThemeProvider theme={{ noBottomMargin: noBottomMargin }}>
      <TabsContainer>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={index}
              style={
                activeTab === tab.value
                  ? { fontWeight: fonts.semiBold, color: colors.darkGrey }
                  : null
              }
              onClick={() => onClick(tab.value)}
            >
              {tab.text}
            </Tab>
          );
        })}
      </TabsContainer>
    </ThemeProvider>
  );
};

TabMenu.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  noBottomMargin: PropTypes.bool,
};

export const TabsContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin: ${(props) =>
      props.theme.noBottomMargin
        ? '-25px 20px 0 20px'
        : '-25px 20px 25px 20px'};
    padding: 15px 0;
  }
  @media ${maxDevice.mobileL} {
    padding: 15px 0;
  }
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  margin: ${(props) =>
    props.theme.noBottomMargin ? '-25px 40px 0 40px' : '-25px 40px 25px 40px'};
  border-bottom: 1px solid ${colors.lightGrey};
`;

const Tab = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 13px;
    margin-right: 15px;
  }
  @media ${maxDevice.sideNav} {
    font-size: 11px;
    margin-right: 10px;
  }
  margin-right: 20px;
  color: ${colors.paleGrey};
  cursor: pointer;
  &:hover {
    color: ${colors.darkGrey};
  }
`;

export default TabMenu;
