import styled from 'styled-components';
import { analyticsIllustration } from '../../assets';
import { UpdatePageTitle, headerContent } from '../../utils';
import { PageHeader } from '../../components';
import { PageContent } from '../../styles/library/layoutStyles';
import { colors, fonts } from '../../styles/variables';

const HouseholdAnalytics = () => {
  UpdatePageTitle('Analytics');

  return (
    <PageContainer>
      <PageHeader content={headerContent.analytics} />
      <PageContent>
        <NotFoundImage src={analyticsIllustration} alt="Coming Soon" />
        <NotFoundText>Coming Soon</NotFoundText>
      </PageContent>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const NotFoundImage = styled.img`
  max-height: 430px;
  margin: 10px;
  width: 100%;
`;

const NotFoundText = styled.p`
  margin: 20px 40px 20px 40px;
  font-size: 28px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

export default HouseholdAnalytics;
