/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SmallTable,
  SimpleModal,
  Pagination,
  SubTabMenu,
  BoBTransferModal,
} from '../../components';
import { undo, user as userIcon, share, cancel } from '../../assets';
import {
  getLicensesCount,
  getCustomerMembersList,
  deleteCustomerMember,
  deactivateCustomerMemberMFA,
  // resetCustomerMemberPassword,
  sendResetPassword,
  updateUserStoreValue,
  getCustomerDelegateList,
  getCustomerPendingList,
  deletePendingInvite,
  updateMemberRole,
  updateCustomerDelegate,
  sendInvite,
  resendInvite,
  toggleShowInviteMemberModal,
  toggleShowDeleteMemberModal,
  toggleShowEditDelegateModal,
  updateSeatConfiguration,
  toggleShowAddLicensesModal,
  toggleShowConfirmLicensesModal,
  updateBoBTransfer,
  getBoBTransfers,
  toggleShowBoBTransferModal,
  getAdvisorDetails,
  getBoBTransfer,
  toggleShowReceiveTransferModal,
} from '../../store/actions';
import { ErrorNotification, SendTrackingEvent } from '../../utils';
import { ButtonThemes } from '../../styles/themes';
import { colors, maxDevice, fonts } from '../../styles/variables';

const ManagerMembershipContainer = ({ customerId, trackSubTab, code, pin }) => {
  const dispatch = useDispatch();
  const {
    user,
    showTransferBoB,
    teamSettings,
    membersList,
    delegateList,
    pendingList,
    transfersList,
    licensesCount,
    updateSeatsError,
    resetMemberError,
    passwordResetSent,
    showingInviteMember,
    showingDeleteMember,
    showingEditDelegate,
    showingAddLicenses,
    showingConfirmLicenses,
    justSentInvite,
    inviteErrorServer,
    justDeletedInvite,
    justUpdatedDelegate,
    justUpdatedMember,
    updateMemberError,
    showingBoBTransfer,
    bobTransferScreen,
    updateTransferError,
    transferUpdated,
    transferDetails,
    showingCancelBoBModal,
    createdExternalTransfer,
    extendedExternalTransfer,
    showingReceiveBoBTransfer,
    authenticatedBoBTransfer,
    managerMembershipSubTab,
    limit,
    invocationId,
    sendInviteSuccess,
  } = useSelector((state) => ({
    user: state.user.user,
    showTransferBoB: state.user.showTransferBoB,
    teamSettings: state.user.teamSettings,
    membersList: state.user.membersList,
    delegateList: state.user.delegateList,
    pendingList: state.user.pendingList,
    transfersList: state.user.transfersList,
    licensesCount: state.user.licensesCount,
    updateSeatsError: state.user.updateSeatsError,
    resetMemberError: state.user.resetMemberError,
    passwordResetSent: state.user.passwordResetSent,
    showingInviteMember: state.user.showingInviteMember,
    showingDeleteMember: state.user.showingDeleteMember,
    showingEditDelegate: state.user.showingEditDelegate,
    showingAddLicenses: state.user.showingAddLicenses,
    showingConfirmLicenses: state.user.showingConfirmLicenses,
    justSentInvite: state.user.justSentInvite,
    inviteErrorServer: state.user.inviteError,
    justDeletedInvite: state.user.justDeletedInvite,
    justUpdatedDelegate: state.user.justUpdatedDelegate,
    justUpdatedMember: state.user.justUpdatedMember,
    updateMemberError: state.user.updateMemberError,
    showingBoBTransfer: state.user.showingBoBTransfer,
    bobTransferScreen: state.user.bobTransferScreen,
    updateTransferError: state.user.updateTransferError,
    transferUpdated: state.user.transferUpdated,
    transferDetails: state.user.transferDetails,
    showingCancelBoBModal: state.user.showingCancelBoBModal,
    createdExternalTransfer: state.user.createdExternalTransfer,
    extendedExternalTransfer: state.user.extendedExternalTransfer,
    showingReceiveBoBTransfer: state.user.showingReceiveBoBTransfer,
    authenticatedBoBTransfer: state.user.authenticatedBoBTransfer,
    managerMembershipSubTab: state.user.managerMembershipSubTab,
    limit: state.configs?.pagination?.default_limit || 25,
    invocationId: state.configs.invocationId,
    sendInviteSuccess: state.user.sendInviteSuccess,
  }));
  const [activeSubTab, setActiveSubTab] = useState('members');
  const [subTabs, setSubTabs] = useState([
    {
      text: 'Members',
      value: 'members',
    },
    {
      text: 'Delegation',
      value: 'delegation',
    },
    {
      text: 'Pending Invites',
      value: 'pending',
    },
  ]);
  const [customerMembers, setCustomerMembers] = useState([]);
  const [loadedCustomerMembers, setLoadedCustomerMembers] = useState(false);
  const [delegateMembers, setDelegateMembers] = useState([]);
  const [loadedDelegates, setLoadedDelegates] = useState(false);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [loadedPendingMembers, setLoadedPendingMembers] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [loadedTransfers, setLoadedTransfers] = useState(false);
  const [deleteMember, setDeleteMember] = useState();
  const [advisorLicenseCount, setAdvisorLicenseCount] = useState(0);
  const [delegateLicenseCount, setDelegateLicenseCount] = useState(0);
  const [emailAddress, setEmailAddress] = useState('');
  const [hasEmailError, setHasEmailError] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState();
  const [selectedAdvisors, setSelectedAdvisors] = useState();
  const [selectedDelegate, setSelectedDelegate] = useState();
  const [inviteError, setInviteError] = useState();
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSavingDelegate, setIsSavingDelegate] = useState(false);
  const [memberSortValue, setMemberSortValue] = useState('last_name');
  const [delegateSortValue, setDelegateSortValue] = useState('last_name');
  const [pendingSortValue, setPendingSortValue] = useState('-modified_utc');
  const [offset, setOffset] = useState(0);
  const [licenseErrorMessage, setLicenseErrorMessage] = useState('');
  const [isAddingLicenses, setIsAddingLicenses] = useState(false);
  const [noOptionMessage, setNoOptionMessage] = useState(
    'Start typing to begin search'
  );
  const [updateDelegateAdvisorList, setUpdateDelegateAdvisorList] = useState(
    []
  );
  const [licenseCounts, setLicenseCounts] = useState({
    essentials: '',
    // professional: '',
    delegate: '',
  });
  const [licenseCountErrors, setLicenseCountErrors] = useState({
    essentials: false,
    // professional: false,
    delegate: false,
  });
  const [resetModalContent, setResetModalContent] = useState({
    showing: false,
    type: '',
    item: null,
    error: false,
    isResetting: false,
  });
  const [transferModalContent, setTransferModalContent] = useState({
    showing: false,
    type: '',
    item: null,
    error: '',
    isLoading: false,
  });
  const [transferScreenValue, setTransferScreenValue] = useState('select');

  const headingOnClick = (value) => {
    if (value.table === 'members') {
      setMembersHeadings(
        membersHeadings.map((heading) => {
          if (heading.value === value.value) {
            heading.ascending = !heading.ascending;
            heading.active = true;
          } else {
            heading.active = false;
          }
          return heading;
        })
      );
    }
    if (value.table === 'delegates') {
      setDelegateHeadings(
        delegateHeadings.map((heading) => {
          if (heading.value === value.value) {
            heading.ascending = !heading.ascending;
            heading.active = true;
          } else {
            heading.active = false;
          }
          return heading;
        })
      );
    }
    if (value.table === 'pending') {
      setPendingHeadings(
        pendingHeadings.map((heading) => {
          if (heading.value === value.value) {
            heading.ascending = !heading.ascending;
            heading.active = true;
          } else {
            heading.active = false;
          }
          return heading;
        })
      );
    }
  };

  const [membersHeadings, setMembersHeadings] = useState([
    {
      text: 'Email',
      value: 'email',
      table: 'members',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: false,
    },
    {
      text: 'First Name',
      value: 'first_name',
      table: 'members',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: false,
    },
    {
      text: 'Last Name',
      value: 'last_name',
      table: 'members',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
      active: true,
    },
    { text: 'Advisor', display: true, align: 'center' },
    { text: 'Delegate', display: true, align: 'center' },
    { text: 'Manager', display: true, align: 'center' },
    { text: 'Billing', display: true, align: 'center' },
    {
      text: 'Customize',
      display: true,
      align: 'center',
      isHidden: true,
    },
    {
      text: 'MFA Status',
      display: true,
      align: 'center',
    },
    { text: 'Actions', display: false },
  ]);
  const [delegateHeadings, setDelegateHeadings] = useState([
    {
      text: 'Email',
      value: 'email',
      table: 'delegates',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: false,
    },
    {
      text: 'First Name',
      value: 'first_name',
      table: 'delegates',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: false,
    },
    {
      text: 'Last Name',
      value: 'last_name',
      table: 'delegates',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
      active: true,
    },
    { text: 'Advisors', display: true },
    { text: 'Actions', display: false },
  ]);
  const [pendingHeadings, setPendingHeadings] = useState([
    { text: 'Type', display: true },
    {
      text: 'Email',
      value: 'email',
      table: 'pending',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: false,
    },
    {
      text: 'Status',
      value: 'status',
      table: 'pending',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: false,
    },
    {
      text: 'Created',
      value: 'modified_utc',
      align: 'center',
      table: 'pending',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      active: true,
      ascending: false,
    },
    { text: 'Actions', display: false },
  ]);
  const transferHeadings = [
    { text: 'Type', display: true },
    {
      text: 'Source Advisor',
      value: 'source',
      display: true,
    },
    {
      text: 'Target Advisor',
      value: 'target',
      display: true,
    },
    {
      text: 'Customer',
      value: 'customer',
      display: true,
    },
    {
      text: 'Households',
      value: 'households',
      align: 'center',
      display: true,
    },
    {
      text: 'Created',
      value: 'created_utc',
      align: 'center',
      display: true,
    },
    {
      text: 'Last Modified',
      value: 'modified_utc',
      align: 'center',
      display: true,
    },
    {
      text: 'Status',
      value: 'status',
      align: 'center',
      display: true,
    },
    { text: 'Actions', display: false },
  ];
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (showTransferBoB) {
      setSubTabs([
        ...subTabs,
        {
          text: 'Transfers',
          hovertext:
            'Transfers allow Team Managers to move books of business (All Households) between their Members.',
          value: 'transfers',
        },
      ]);
    }
  }, [showTransferBoB]);

  useEffect(() => {
    if (code && pin) {
      dispatch(toggleShowReceiveTransferModal(true, code, pin));
    }
  }, [code, pin]);

  useEffect(() => {
    if (managerMembershipSubTab) {
      setActiveSubTab(managerMembershipSubTab);
      dispatch(updateUserStoreValue('managerMembershipSubTab', null));
    }
  }, [managerMembershipSubTab]);

  useEffect(() => {
    if (inviteErrorServer) {
      setIsSendingInvite(false);
      if (inviteErrorServer?.data) {
        if (inviteErrorServer?.data?.email) {
          setInviteError(inviteErrorServer?.data?.email[0]);
          setHasEmailError(true);
        }
      } else {
        setInviteError('Unknown Error');
      }
    }
  }, [inviteErrorServer]);

  useEffect(() => {
    if (membersHeadings) {
      const activeHeading = membersHeadings.find((heading) => heading.active);
      let sortValue = activeHeading.value;
      if (!activeHeading.ascending) {
        sortValue = `-${activeHeading.value}`;
      }
      if (sortValue !== memberSortValue) {
        dispatch(getCustomerMembersList(customerId, sortValue));
        setMemberSortValue(sortValue);
      }
    }
  }, [membersHeadings]);

  useEffect(() => {
    if (delegateHeadings) {
      const activeHeading = delegateHeadings.find((heading) => heading.active);
      let sortValue = activeHeading.value;
      if (!activeHeading.ascending) {
        sortValue = `-${activeHeading.value}`;
      }
      //Check that sortValue !== delegateSortValue if you want to stop this from dispatching onload
      if (checkIsLoaded()) {
        dispatch(getCustomerDelegateList(customerId, sortValue));
        setDelegateSortValue(sortValue);
      }
    }
  }, [delegateHeadings]);

  useEffect(() => {
    if (pendingHeadings) {
      const activeHeading = pendingHeadings.find((heading) => heading.active);
      let sortValue = activeHeading.value;
      if (!activeHeading.ascending) {
        sortValue = `-${activeHeading.value}`;
      }
      //Check that sortValue !== pendingSortValue if you want to stop this from dispatching onload
      if (checkIsLoaded()) {
        dispatch(getCustomerPendingList(customerId, sortValue));
        setPendingSortValue(sortValue);
      }
    }
  }, [pendingHeadings]);

  useEffect(() => {
    setOffset(0);
    trackSubTab(activeSubTab);
    if (activeSubTab === 'members') {
      dispatch(getCustomerMembersList(customerId));
    }
    if (activeSubTab === 'delegation') {
      dispatch(getCustomerDelegateList(customerId));
    }
    if (activeSubTab === 'pending') {
      dispatch(getCustomerPendingList(customerId));
    }
    if (activeSubTab === 'transfers') {
      dispatch(getBoBTransfers());
    }
  }, [activeSubTab]);

  useEffect(() => {
    if (!showingAddLicenses && !showingConfirmLicenses) {
      setLicenseErrorMessage('');
      setIsAddingLicenses(false);
      setLicenseCounts({
        essentials: '',
        // professional: '',
        delegate: '',
      });
      setLicenseCountErrors({
        essentials: false,
        // professional: false,
        delegate: false,
      });
    }
  }, [showingAddLicenses, showingConfirmLicenses]);

  useEffect(() => {
    if (bobTransferScreen) {
      setTransferScreenValue(bobTransferScreen);
    }
  }, [bobTransferScreen]);

  useEffect(() => {
    if (updateSeatsError) {
      setIsAddingLicenses(false);
      let errors = [];
      if (updateSeatsError.hasOwnProperty('data')) {
        for (const [key, value] of Object.entries(updateSeatsError.data)) {
          errors.push({
            field: key,
            message: value,
          });
        }
      }
      setLicenseErrorMessage('Error adding licenses');
      console.log('errors are', errors);
    }
  }, [updateSeatsError]);

  useEffect(() => {
    if (resetMemberError) {
      let errorMessage = 'Unknown Error';
      if (resetMemberError?.statusText) {
        errorMessage = resetMemberError?.statusText;
      }
      setResetModalContent({
        ...resetModalContent,
        isResetting: false,
        error: errorMessage,
      });
      dispatch(updateUserStoreValue('resetMemberError', false));
    }
  }, [resetMemberError]);

  useEffect(() => {
    if (updateTransferError) {
      let errorMessage = 'Unknown Error';
      if (updateTransferError?.statusText) {
        errorMessage = updateTransferError?.statusText;
      }
      setTransferModalContent({
        ...transferModalContent,
        isLoading: false,
        error: errorMessage,
      });
      dispatch(updateUserStoreValue('updateTransferError', false));
    }
  }, [updateTransferError]);

  useEffect(() => {
    if (transferUpdated) {
      setTransferModalContent({
        showing: false,
        type: '',
        item: null,
        error: '',
        isLoading: false,
      });
      dispatch(toggleShowBoBTransferModal(false));
    }
  }, [transferUpdated]);

  useEffect(() => {
    if (createdExternalTransfer) {
      setTransferScreenValue('share_external');
      dispatch(getAdvisorDetails(transferDetails?.source_member, 'source'));
      dispatch(updateUserStoreValue('createdExternalTransfer', false));
      dispatch(getBoBTransfers());
    }
  }, [createdExternalTransfer]);

  useEffect(() => {
    if (extendedExternalTransfer) {
      setTransferScreenValue('share_external');
      dispatch(updateUserStoreValue('extendedExternalTransfer', false));
      dispatch(getBoBTransfers());
    }
  }, [extendedExternalTransfer]);

  useEffect(() => {
    if (authenticatedBoBTransfer) {
      setTransferScreenValue('target_search_external');
      dispatch(getAdvisorDetails(transferDetails?.source_member, 'source'));
      dispatch(updateUserStoreValue('authenticatedBoBTransfer', false));
    }
  }, [authenticatedBoBTransfer]);

  useEffect(() => {
    if (showingCancelBoBModal) {
      setTransferModalContent({
        showing: true,
        type: 'cancel',
        item: transferDetails,
        error: '',
        isLoading: false,
      });
      dispatch(updateUserStoreValue('showingCancelBoBModal', false));
    }
  }, [showingCancelBoBModal]);

  useEffect(() => {
    if (showingReceiveBoBTransfer) {
      setTransferScreenValue('auth_external');
      dispatch(toggleShowBoBTransferModal(true));
      dispatch(updateUserStoreValue('showingReceiveBoBTransfer', false));
    }
  }, [showingReceiveBoBTransfer]);

  useEffect(() => {
    if (passwordResetSent) {
      setResetModalContent({
        ...resetModalContent,
        showing: false,
        isResetting: false,
      });
      dispatch(updateUserStoreValue('passwordResetSent', false));
    }
  }, [passwordResetSent]);

  useEffect(() => {
    if (updateMemberError) {
      if (updateMemberError?.data?.non_field_errors) {
        ErrorNotification(updateMemberError.data.non_field_errors[0]);
      }
      setCustomerMembers(
        customerMembers.map((customer) => {
          customer.is_advisor_loading = false;
          customer.is_delegate_loading = false;
          customer.is_manager_loading = false;
          customer.is_billing_manager_loading = false;
          customer.is_ldd_enabled_loading = false;
          return customer;
        })
      );
    }
  }, [updateMemberError]);

  useEffect(() => {
    if (membersList) {
      const transformedMembers = membersList.results.map((member) => {
        member.is_advisor = member?.is_advisor || false;
        member.is_delegate = member?.is_delegate || false;
        member.is_manager = member?.is_manager || false;
        member.is_billing_manager = member?.is_billing_manager || false;
        member.is_ldd_enabled = member?.is_custom_ldd;
        return member;
      });
      setCustomerMembers(transformedMembers);
      setLoadedCustomerMembers(true);
      setResetModalContent({
        showing: false,
        type: '',
        item: null,
        error: false,
        isResetting: false,
      });
    }
  }, [membersList]);

  useEffect(() => {
    if (pendingList) {
      setPendingMembers(pendingList?.results);
      setLoadedPendingMembers(true);
    }
  }, [pendingList]);

  useEffect(() => {
    if (delegateList) {
      setDelegateMembers(delegateList?.results);
      setLoadedDelegates(true);
    }
  }, [delegateList]);

  useEffect(() => {
    if (transfersList) {
      setLoadedTransfers(true);
      setTransfers(transfersList?.results);
    }
  }, [transfersList]);

  useEffect(() => {
    if (licensesCount) {
      setIsAddingLicenses(false);
      setAdvisorLicenseCount(licensesCount?.available_advisors);
      setDelegateLicenseCount(licensesCount?.available_delegates);
    }
  }, [licensesCount]);

  useEffect(() => {
    if (!showingInviteMember) {
      setEmailAddress('');
      setHasEmailError(false);
      setInviteError('');
      setSelectedUserType();
    }
  }, [showingInviteMember]);

  useEffect(() => {
    if (justSentInvite) {
      setIsSendingInvite(false);
      setActiveSubTab('pending');
    }
  }, [justSentInvite]);

  useEffect(() => {
    if (justDeletedInvite) {
      setIsDeleting(false);
      dispatch(toggleShowDeleteMemberModal(false));
      dispatch(getLicensesCount(customerId));
    }
  }, [justDeletedInvite]);

  useEffect(() => {
    if (justUpdatedMember) {
      dispatch(getLicensesCount(customerId));
    }
  }, [justUpdatedMember]);

  useEffect(() => {
    if (justUpdatedDelegate) {
      setIsSavingDelegate(false);
      dispatch(toggleShowEditDelegateModal(false));
    }
  }, [justUpdatedDelegate]);

  useEffect(() => {
    if (teamSettings) {
      generateColumnHeadings();
    }
  }, [teamSettings]);

  useEffect(() => {
    if (activeSubTab === 'members' && checkIsLoaded()) {
      dispatch(getCustomerMembersList(customerId, memberSortValue, offset));
    }
    if (activeSubTab === 'delegation' && checkIsLoaded()) {
      dispatch(getCustomerDelegateList(customerId, delegateSortValue, offset));
    }
    if (activeSubTab === 'pending' && checkIsLoaded()) {
      dispatch(getCustomerPendingList(customerId, pendingSortValue, offset));
    }
  }, [offset]);

  useEffect(() => {
    if (sendInviteSuccess === true) {
      setIsSendingInvite(false);
      dispatch(getCustomerPendingList(customerId, pendingSortValue, offset));
    }
  }, [sendInviteSuccess]);

  const getCount = () => {
    if (activeSubTab === 'members') {
      return membersList?.count;
    }
    if (activeSubTab === 'delegation') {
      return delegateList?.count;
    }
    if (activeSubTab === 'pending') {
      return pendingList?.count;
    }
    if (activeSubTab === 'transfers') {
      return 0;
    }
  };

  const addPagination = () => getCount() > limit;

  const determineDisplayBilling = () => {
    if (teamSettings && teamSettings?.customer_type) {
      if (teamSettings.customer_type === 'subscription') {
        return true;
      }
    }
    return false;
  };

  const determineDisplayCustomize = () => {
    if (teamSettings && teamSettings?.custom_ldd_setting) {
      if (teamSettings.custom_ldd_setting === 'member') {
        return true;
      }
    }
    return false;
  };

  const generateColumnHeadings = () => {
    if (activeSubTab === 'members') {
      return membersHeadings.reduce((acc, heading) => {
        if (heading.text === 'Customize' && !determineDisplayCustomize()) {
          return acc;
        } else if (heading.text === 'Billing' && !determineDisplayBilling()) {
          return acc;
        }
        return [...acc, heading];
      }, []);
    }
    if (activeSubTab === 'delegation') {
      return delegateHeadings;
    }
    if (activeSubTab === 'pending') {
      return pendingHeadings;
    }
    if (activeSubTab === 'transfers') {
      return transferHeadings;
    }
    return [];
  };

  const generateTableData = () => {
    if (activeSubTab === 'members') {
      return customerMembers;
    }
    if (activeSubTab === 'delegation') {
      return delegateMembers;
    }
    if (activeSubTab === 'pending') {
      return pendingMembers;
    }
    if (activeSubTab === 'transfers') {
      return transfers;
    }
    return [];
  };

  const checkIsLoaded = () => {
    if (activeSubTab === 'members') {
      return loadedCustomerMembers;
    }
    if (activeSubTab === 'delegation') {
      return loadedDelegates;
    }
    if (activeSubTab === 'pending') {
      return loadedPendingMembers;
    }
    if (activeSubTab === 'transfers') {
      return loadedTransfers;
    }
    return false;
  };

  const generateTableLabel = () => {
    if (activeSubTab === 'members') {
      return 'customer_members';
    }
    if (activeSubTab === 'delegation') {
      return 'customer_delegation';
    }
    if (activeSubTab === 'pending') {
      return 'customer_pending';
    }
    if (activeSubTab === 'transfers') {
      return 'customer_transfers';
    }
    return [];
  };

  const deleteFunction = (member) => {
    setDeleteMember(member);
    dispatch(toggleShowDeleteMemberModal(true));
  };

  const editFunction = (item) => {
    if (activeSubTab === 'pending') {
      dispatch(resendInvite(customerId, item.id));
      setPendingMembers(
        pendingMembers.map((member) => {
          if (member.id === item.id) {
            member.loading = true;
          }
          return member;
        })
      );
    }
    if (activeSubTab === 'delegation') {
      dispatch(toggleShowEditDelegateModal(true));
      setSelectedDelegate(item);
      // const advisorList = item?.potential_advisors.map((advisor) => {
      //   if (item?.delegators) {
      //     if (item.delegators.find((del) => del.hash === advisor.value)) {
      //       advisor.selected = true;
      //     } else {
      //       advisor.selected = false;
      //     }
      //   } else {
      //     advisor.selected = false;
      //   }
      //   return advisor;
      // });
      // const selectedAdvisorsNames = advisorList.reduce((acc, advisor) => {
      //   if (advisor.selected) {
      //     return [...acc, advisor];
      //   }
      //   return acc;
      // }, []);
      const delegators = item?.delegators.map((del) => {
        del.label = del.name;
        del.value = del.hash;
        return del;
      });

      setSelectedAdvisors(delegators);
    }
  };

  const dispatchDeleteCustomerMember = () => {
    setIsDeleting(true);
    dispatch(deleteCustomerMember(customerId, deleteMember.id));
  };

  const dispatchDeletePendingInvite = () => {
    setIsDeleting(true);
    dispatch(
      deletePendingInvite(customerId, deleteMember.id, pendingSortValue)
    );
  };

  const generateDeleteModalContent = () => {
    if (showingDeleteMember) {
      let heading = 'Delete Member?';
      let text = `Are you sure you want to delete<br/><strong>${deleteMember.first_name} ${deleteMember.last_name}</strong>?<br/>.`;
      if (deleteMember?.delegate_data != null) {
        text = `Are you sure you want to delete<br/><strong>${deleteMember.first_name} ${deleteMember.last_name}</strong>?<br/>. <br> Doing so will unlink this advisor from <b>${deleteMember?.delegate_data?.count}</b> Delegates.`;
      }
      if (activeSubTab === 'pending') {
        heading = 'Delete Invite?';
        text = `Are you sure you want to delete the invite sent to <strong>${deleteMember.email}</strong>?`;
      }
      return {
        heading: heading,
        text: text,
        buttons: [
          {
            text: 'Cancel',
            function: () => dispatch(toggleShowDeleteMemberModal(false)),
            theme: ButtonThemes.cancel,
          },
          {
            text: 'Yes',
            function: () =>
              activeSubTab === 'pending'
                ? dispatchDeletePendingInvite()
                : dispatchDeleteCustomerMember(),
            theme: ButtonThemes.primary,
            showLoading: isDeleting,
            loadingText: 'Deleting',
          },
        ],
      };
    }
  };

  const checkSendInvite = () => {
    if (emailAddress === '') {
      setHasEmailError(true);
      return setInviteError('Please provide a valid e-mail address');
    }
    if (selectedUserType === undefined) {
      return setInviteError('Please select a user type');
    }
    if (selectedUserType === 'delegate' && delegateLicenseCount === 0) {
      return setInviteError(
        'No delegate licenses available. Remove an existing delegate or contact support to add additional licenses.'
      );
    }
    if (selectedUserType === 'advisor' && advisorLicenseCount === 0) {
      return setInviteError(
        'No advisor licenses available. Please remove an existing advisor or contact support to add additional licenses.'
      );
    }
    const inviteData = {
      email: emailAddress,
      type: selectedUserType,
    };
    if (selectedUserType === 'delegate') {
      const advisorList = updateDelegateAdvisorList.map(
        (advisor) => advisor.value
      );
      inviteData.advisors = advisorList;
    }
    setIsSendingInvite(true);
    dispatch(sendInvite(customerId, inviteData));
  };

  const selectMemberOnChange = (members) => {
    if (members !== null) {
      setUpdateDelegateAdvisorList(members);
    } else {
      setUpdateDelegateAdvisorList([]);
    }
  };

  const delegateMembersOnChange = (members) => {
    if (members !== null) {
      setUpdateDelegateAdvisorList(members);
    } else {
      setUpdateDelegateAdvisorList([]);
    }
  };

  const sendUpdateDelegate = () => {
    const advisorsList = updateDelegateAdvisorList.map(
      (advisor) => advisor.value
    );
    setIsSavingDelegate(true);
    dispatch(
      updateCustomerDelegate(
        customerId,
        selectedDelegate.id,
        advisorsList,
        delegateSortValue
      )
    );
  };

  const generateDelegateModalContent = () => {
    if (showingEditDelegate) {
      return {
        heading: 'Edit Delegate',
        infoText: `Select which advisors this user is a delegate for`,
        minWidth: '420px',
        inputs: [
          {
            type: 'typeahead',
            name: 'delegate',
            label: 'Search Advisors',
            loadOptions: loadOptions,
            showSearchIcon: true,
            isMulti: true,
            placeholder: 'Search for advisor by name or email ',
            onInputChange: (value) => changeQuery(value),
            defaultValue: selectedAdvisors,
            noOptionMessage: noOptionMessage,
            onChange: (e) => delegateMembersOnChange(e),
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            function: () => dispatch(toggleShowEditDelegateModal(false)),
            theme: ButtonThemes.cancel,
          },
          {
            text: 'Save',
            function: () => sendUpdateDelegate(),
            theme: ButtonThemes.primary,
            showLoading: isSavingDelegate,
            loadingText: 'Saving',
          },
        ],
      };
    }
  };

  const updatedEmailAddress = (e) => {
    setEmailAddress(e.currentTarget.value);
    setHasEmailError(false);
    setInviteError('');
  };

  const selectUserType = (value) => {
    setSelectedUserType(value);
    setInviteError('');
  };

  const generateInviteModalContent = () => {
    if (showingInviteMember) {
      return {
        heading: 'Invite Member',
        infoText: `Enter the new user's email address and select user type.`,
        marginTop: '10vh',
        minWidth: '350px',
        inputs: [
          {
            type: 'text',
            maxLength: '150',
            name: 'email',
            required: true,
            placeholder: 'Email Address',
            label: 'Email Address',
            value: emailAddress,
            onChange: (e) => updatedEmailAddress(e),
            hasError: hasEmailError,
          },
        ],
        verticalHeader: 'Select Type of User',
        verticalContent: [
          {
            type: 'invite',
            text: 'Advisor',
            name: 'advisor',
            subtext: 'Create Households and Reports',
            image: userIcon,
            selected: selectedUserType === 'advisor',
            onClick: () => selectUserType('advisor'),
          },
          {
            type: 'invite',
            text: 'Delegate',
            name: 'delegate',
            subtext: 'Account access for support staff',
            image: userIcon,
            selected: selectedUserType === 'delegate',
            onClick: () => selectUserType('delegate'),
          },
          {
            type: 'invite',
            text: 'Team Manager',
            name: 'manager',
            subtext: 'Manages team membership and configuration',
            image: userIcon,
            selected: selectedUserType === 'team',
            onClick: () => selectUserType('team'),
          },
          {
            type: 'invite',
            text: 'Billing Manager',
            name: 'billing',
            subtext: 'Controls account payment information',
            image: userIcon,
            selected: selectedUserType === 'billing',
            onClick: () => selectUserType('billing'),
          },
        ],
        afterInputs: [
          {
            isHidden: selectedUserType !== 'delegate',
            type: 'typeahead',
            name: 'delegate',
            label: 'Search Advisors',
            loadOptions: loadOptions,
            showSearchIcon: true,
            isMulti: true,
            placeholder: 'Search for advisor by name or email ',
            onInputChange: (value) => changeQuery(value),
            defaultValue: [],
            onChange: (e) => selectMemberOnChange(e),
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            function: () => dispatch(toggleShowInviteMemberModal(false)),
            theme: ButtonThemes.cancel,
          },
          {
            text: 'Send Invite',
            function: () => checkSendInvite(),
            theme: ButtonThemes.primary,
            showLoading: isSendingInvite,
            loadingText: 'Sending',
          },
        ],
      };
    }
  };

  const toggleRoleOptions = (item, type) => {
    const matchedMember = customerMembers.find(
      (member) => member.id === item.id
    );
    if (matchedMember && user) {
      if (matchedMember.id === user.id && type === 'is_manager') {
        return ErrorNotification('You cannot remove yourself as manager');
      }
    }

    if (matchedMember) {
      const data = {
        key: type,
        value: !matchedMember[type],
      };
      dispatch(
        updateMemberRole(customerId, matchedMember.id, data, memberSortValue)
      );
    }
    setCustomerMembers(
      customerMembers.map((customer) => {
        if (customer.id === item.id) {
          customer[`${type}_loading`] = true;
        }
        return customer;
      })
    );
  };

  const updateLicenseValues = (e) => {
    setLicenseErrorMessage('');
    const { name, value } = e.currentTarget;
    setLicenseCounts({
      ...licenseCounts,
      [name]: value,
    });
  };

  const validateLicenseAmount = () => {
    const hasError = Object.values(licenseCountErrors).some(
      (val) => val === true
    );
    if (hasError) {
      return setLicenseErrorMessage(
        'Please resolve errors before adding licenses.'
      );
    }
    const total = Object.values(licenseCounts).reduce((acc, curr) => {
      let convertedNum = parseInt(curr);
      if (isNaN(convertedNum)) {
        convertedNum = 0;
      }
      return acc + convertedNum;
    }, 0);
    if (total <= 0) {
      return setLicenseErrorMessage('Must select at least one license.');
    }
    if (total > 10) {
      return setLicenseErrorMessage(
        'Please contact support if you would like to add more than 10 total licenses.'
      );
    }
    dispatch(toggleShowAddLicensesModal(false));
    dispatch(toggleShowConfirmLicensesModal(true));
  };

  const requestLicenseAmount = () => {
    const countData = {
      advisors: licenseCounts.essentials || 0,
      delegates: licenseCounts.delegate || 0,
      // pro: licenseCounts.professional || 0,
    };
    setIsAddingLicenses(true);
    return dispatch(updateSeatConfiguration(customerId, countData));
  };

  const validateOnBlur = (e) => {
    const { name, value } = e.currentTarget;
    let convertedValue = parseInt(value);
    if (isNaN(convertedValue) && value !== '') {
      setLicenseCountErrors({ ...licenseCountErrors, [name]: true });
      return setLicenseErrorMessage('Please enter a valid number.');
    }
    if (value < 0) {
      setLicenseCounts({
        ...licenseCounts,
        [name]: '0',
      });
    } else {
      setLicenseCountErrors({ ...licenseCountErrors, [name]: false });
    }
    // if (value > 10) {
    //   setLicenseCounts({
    //     ...licenseCounts,
    //     [name]: '10',
    //   });
    // setLicenseCountErrors({ ...licenseCountErrors, [name]: true });
    // setLicenseErrorMessage(
    //   'Please contact support if you would like to add more than 10 licenses.'
    // );
    // }
  };

  const updateCountClick = (type, increase) => {
    let matchingValue = licenseCounts[type];
    matchingValue = parseInt(matchingValue) || 0;
    setLicenseCountErrors({ ...licenseCountErrors, [type]: false });
    setLicenseErrorMessage('');
    if (increase) {
      if (matchingValue >= 10) {
        setLicenseErrorMessage(
          'Please contact support if you would like to add more than 10 licenses.'
        );
      } else {
        setLicenseCounts({
          ...licenseCounts,
          [type]: matchingValue + 1,
        });
      }
    } else {
      if (matchingValue > 0) {
        setLicenseCounts({
          ...licenseCounts,
          [type]: matchingValue - 1,
        });
      } else {
        setLicenseCounts({
          ...licenseCounts,
          [type]: '0',
        });
      }
    }
  };

  const changeQuery = (newQuery) => {
    if (newQuery === null || newQuery === '' || newQuery === ' ') {
      setQuery('');
      setNoOptionMessage('Start typing to begin search');
    } else {
      setQuery(newQuery);
      setNoOptionMessage('No results');
    }
  };

  const loadOptions = () => {
    if (query) {
      return fetch(
        `/api/v3/customer/${customerId}/advisor_search/${query}`
      ).then((res) => res.json());
    }
  };

  const goBackButton = () => {
    dispatch(toggleShowConfirmLicensesModal(false));
    dispatch(toggleShowAddLicensesModal(true));
  };

  const generateAddLicenseContent = () => {
    if (showingAddLicenses) {
      return {
        heading: 'Purchase Licenses',
        infoTextAlt: `Select the amount of each license you would like to add.`,
        marginTop: '10vh',
        minWidth: '350px',
        // infoBoxText:
        //   'Billing amount will be updated according to your contract terms.',
        verticalContent: [
          {
            type: 'license',
            text: 'Advisor',
            subtext: `Account access for advisors.`,
            color: colors.darkGrey,
            name: 'essentials',
            value: licenseCounts.essentials,
            hasError: licenseCountErrors.essentials,
            onChange: (e) => updateLicenseValues(e),
            onBlur: (e) => validateOnBlur(e),
            onClick: updateCountClick,
          },
          // {
          //   type: 'license',
          //   text: 'Professional Advisor',
          //   subtext: 'Includes all of Essentials plus Target-Maps and more.',
          //   color: colors.blue,
          //   name: 'professional',
          //   value: licenseCounts.professional,
          //   hasError: licenseCountErrors.professional,
          //   onChange: (e) => updateLicenseValues(e),
          //   onBlur: (e) => validateOnBlur(e),
          //   onClick: updateCountClick,
          // },
          {
            type: 'license',
            text: 'Delegate',
            subtext: 'Account access for support staff.',
            color: colors.darkGrey,
            name: 'delegate',
            value: licenseCounts.delegate,
            hasError: licenseCountErrors.delegate,
            onChange: (e) => updateLicenseValues(e),
            onBlur: (e) => validateOnBlur(e),
            onClick: updateCountClick,
            last: true,
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            function: () => dispatch(toggleShowAddLicensesModal(false)),
            theme: ButtonThemes.cancel,
          },
          {
            text: 'Purchase Licenses',
            function: () => validateLicenseAmount(),
            theme: ButtonThemes.primary,
          },
        ],
      };
    }
  };

  const generateConfirmLicenseContent = () => {
    if (showingConfirmLicenses) {
      return {
        heading: 'Confirm Purchase',
        infoTextAlt: `You are about to purchase the following:`,
        marginTop: '10vh',
        minWidth: '380px',
        verticalContent: [
          {
            type: 'confirm_license',
            text: 'Advisor',
            subtext: `Account access for advisors.`,
            color: colors.darkGrey,
            name: 'essentials',
            value: licenseCounts.essentials || 0,
          },
          {
            type: 'confirm_license',
            text: 'Delegate',
            subtext: 'Account access for support staff.',
            color: colors.darkGrey,
            name: 'delegate',
            value: licenseCounts.delegate || 0,
            last: true,
          },
        ],
        infoBoxEnd: {
          message: `Billing amount will be updated and each additional license will be subject to the terms of any agreements you have entered into with us for your existing licenses.`,
          fontSize: '11px',
        },
        buttons: [
          {
            text: 'Back',
            function: () => goBackButton(),
            theme: ButtonThemes.cancel,
          },
          {
            text: 'Confirm Purchase',
            function: () => requestLicenseAmount(),
            theme: ButtonThemes.primary,
            showLoading: isAddingLicenses,
            loadingText: 'Purchasing',
          },
        ],
      };
    }
  };

  const showAddLicensesButton = () => {
    if (
      teamSettings?.customer_type === 'subscription' ||
      teamSettings?.customer_type === 'manual'
    ) {
      return true;
    }
    return false;
  };

  const licenseButtonOnClick = () => {
    dispatch(toggleShowAddLicensesModal(true));
    SendTrackingEvent(
      invocationId,
      'click',
      'view_seats_modal',
      'manager_membership'
    );
  };

  const resetFunction = () => {
    if (resetModalContent.type === 'reset_mfa') {
      dispatch(
        deactivateCustomerMemberMFA(
          teamSettings.uuid,
          resetModalContent?.item?.uuid,
          customerId
        )
      );
    } else if (resetModalContent.type === 'reset_password') {
      dispatch(sendResetPassword(resetModalContent?.item?.email, true));
      // dispatch(
      //   resetCustomerMemberPassword(
      //     teamSettings.uuid,
      //     resetModalContent?.item?.uuid,
      //     customerId
      //   )
      // );
    }
    setResetModalContent({
      ...resetModalContent,
      isResetting: true,
    });
  };

  const generateResetModalContent = () => {
    let displayType = '';
    let text = '';
    switch (resetModalContent.type) {
      case 'reset_mfa':
        displayType = 'MFA';
        text = `<br/>This will clear the user's current MFA settings and prompt the user to set up MFA again.<br/>`;
        break;
      case 'reset_password':
        displayType = 'password';
        text = `<br/>This will send an email to this user to change their password.<br/>`;
        break;
      default:
        text = `Are you sure you want to reset?<br/>`;
    }
    return {
      heading: `Reset ${displayType}?`,
      text: `Would you like to reset ${displayType} for<br/><strong>${resetModalContent?.item?.first_name} ${resetModalContent?.item?.last_name}</strong>?<br/>${text}`,
      buttons: [
        {
          text: 'Cancel',
          function: () =>
            setResetModalContent({
              ...resetModalContent,
              showing: !resetModalContent.showing,
            }),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes, Reset',
          function: () => resetFunction(),
          theme: ButtonThemes.primary,
          showLoading: resetModalContent.isResetting,
          loadingText: 'Resetting',
        },
      ],
    };
  };

  const moreActionFunction = (type, item) => {
    setResetModalContent({ showing: true, type: type, item: item });
  };

  const rightTabContent = () => {
    if (activeSubTab === 'transfers') {
      return (
        <SecondaryButton
          onClick={() => dispatch(toggleShowReceiveTransferModal(true))}
        >
          Receive Transfer
        </SecondaryButton>
      );
    } else {
      return (
        <>
          {showAddLicensesButton() && (
            <AddLicensesContainer onClick={() => licenseButtonOnClick()}>
              Purchase Licenses
            </AddLicensesContainer>
          )}
          <LicensesContainer>
            <strong>Licenses Available: </strong>
            <span>{advisorLicenseCount} Advisor</span>
            <span>, {delegateLicenseCount} Delegate</span>
          </LicensesContainer>
        </>
      );
    }
  };

  const generateSimpleTransferModalContent = () => {
    let displayText = '';
    let loadingText = '';
    let text = '';
    const { source_member_name, household_count, target_member_name, id } =
      transferModalContent.item;
    const isPlural = household_count !== 1;
    switch (transferModalContent.type) {
      case 'cancel':
        displayText = 'Cancel';
        loadingText = 'Cancelling';
        text = `Are you sure you want to cancel the transfer of <strong>${source_member_name}'s ${household_count} Household${
          isPlural ? 's' : ''
        }</strong>?`;
        break;
      case 'undo':
        displayText = 'Undo';
        loadingText = 'Undoing';
        text = `Are you sure you want to return<br/><strong>${household_count} Household${
          isPlural ? 's' : ''
        }</strong><br/>to <strong>${source_member_name}</strong> from <strong>${target_member_name}</strong>?`;
        break;
      default:
        text = `Unknown Error`;
    }
    return {
      heading: `${displayText} Transfer?`,
      text: text,
      buttons: [
        {
          text: 'Close',
          function: () =>
            setTransferModalContent({
              ...transferModalContent,
              showing: !transferModalContent.showing,
            }),
          theme: ButtonThemes.cancel,
        },
        {
          text: `Yes, ${displayText}`,
          function: () => {
            dispatch(updateBoBTransfer(id, transferModalContent.type));
            setTransferModalContent({
              ...transferModalContent,
              isLoading: true,
            });
          },
          theme: ButtonThemes.primary,
          showLoading: transferModalContent.isLoading,
          loadingText: loadingText,
        },
      ],
    };
  };

  const shareTransfer = (transfer) => {
    dispatch(getAdvisorDetails(transfer?.source_member, 'source'));
    dispatch(getBoBTransfer(transfer?.id));
    setTransferScreenValue('share_external');
    dispatch(toggleShowBoBTransferModal(true));
  };

  const cancelTransfer = (transfer) => {
    setTransferModalContent({
      showing: true,
      type: 'cancel',
      item: transfer,
      error: '',
      isLoading: false,
    });
  };

  const undoTransfer = (transfer) => {
    setTransferModalContent({
      showing: true,
      type: 'undo',
      item: transfer,
      error: '',
      isLoading: false,
    });
  };

  const transferActions = [
    {
      icon: share,
      label: 'Share',
      value: 'share',
      onClick: shareTransfer,
    },
    { icon: undo, label: 'Undo', value: 'undo', onClick: undoTransfer },
    {
      icon: cancel,
      label: 'Cancel',
      value: 'cancel',
      onClick: cancelTransfer,
    },
  ];

  return (
    <>
      {showingInviteMember && (
        <SimpleModal
          hide={() =>
            dispatch(toggleShowInviteMemberModal(!showingInviteMember))
          }
          content={generateInviteModalContent()}
          error={inviteError}
          disableClickOutside={true}
        />
      )}
      {showingEditDelegate && (
        <SimpleModal
          hide={() =>
            dispatch(toggleShowEditDelegateModal(!showingEditDelegate))
          }
          content={generateDelegateModalContent()}
          error={inviteError}
          disableClickOutside={true}
        />
      )}
      {showingDeleteMember && (
        <SimpleModal
          hide={() =>
            dispatch(toggleShowDeleteMemberModal(!showingDeleteMember))
          }
          content={generateDeleteModalContent()}
        />
      )}
      {resetModalContent?.showing && (
        <SimpleModal
          error={resetModalContent.error}
          hide={() =>
            setResetModalContent({
              ...resetModalContent,
              showing: !resetModalContent.showing,
            })
          }
          content={generateResetModalContent()}
        />
      )}
      {showingAddLicenses && (
        <SimpleModal
          hide={() => dispatch(toggleShowAddLicensesModal(!showingAddLicenses))}
          content={generateAddLicenseContent()}
          error={licenseErrorMessage}
          disableClickOutside={true}
        />
      )}
      {showingConfirmLicenses && (
        <SimpleModal
          hide={() =>
            dispatch(toggleShowConfirmLicensesModal(!showingConfirmLicenses))
          }
          content={generateConfirmLicenseContent()}
          error={licenseErrorMessage}
          disableClickOutside={true}
        />
      )}
      {transferModalContent?.showing && (
        <SimpleModal
          error={transferModalContent.error}
          hide={() =>
            setTransferModalContent({
              ...transferModalContent,
              showing: !transferModalContent.showing,
            })
          }
          content={generateSimpleTransferModalContent()}
        />
      )}
      {showingBoBTransfer && (
        <BoBTransferModal
          hide={() => {
            dispatch(toggleShowBoBTransferModal(false));
            setTransferScreenValue('select');
          }}
          customerId={customerId}
          screen={transferScreenValue}
          setScreenValue={setTransferScreenValue}
        />
      )}
      <BackgroundPageContent>
        <SubTabMenu
          tabs={subTabs}
          activeTab={activeSubTab}
          onClick={setActiveSubTab}
          rightTabContent={rightTabContent()}
        />
        <MembershipContent>
          <SmallTable
            columnHeadings={generateColumnHeadings()}
            customEmptyResults={activeSubTab === 'transfers'}
            type={generateTableLabel()}
            items={generateTableData()}
            loaded={checkIsLoaded()}
            deleteFunction={deleteFunction}
            editFunction={editFunction}
            toggleFunction={toggleRoleOptions}
            moreActionFunction={moreActionFunction}
            isHousehold={false}
            actionsList={transferActions}
          />
          {addPagination() && (
            <Pagination
              clickNext={() => setOffset(offset + limit)}
              clickPrev={() => setOffset(offset - limit)}
              goToPage={(page) => setOffset(limit * (page - 1))}
              offset={offset}
              count={getCount()}
              limit={limit}
              paddingBottom={'30px'}
            />
          )}
        </MembershipContent>
      </BackgroundPageContent>
    </>
  );
};

const MembershipContent = styled.div`
  background: white;
  margin: 0 -40px;
  padding: 40px 40px 0 40px;
  /* height: 100%; */
`;

const BackgroundPageContent = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 30px 20px 100px 20px;
  }
  padding: 30px 40px 100px 40px;
  background: ${colors.lighterGrey};
  /* height: 100%; */
`;

const LicensesContainer = styled.div`
  font-size: 11px;
`;

const AddLicensesContainer = styled.button`
  /* border: 1px solid #666666;
  background: ${colors.lighterGrey};
  color: #666666; */
  /* border: 1px solid ${colors.green};
  background: ${colors.green};
  color: white; */
  /* border: 1px solid ${colors.green};
  color: ${colors.green}; */
  border: 1px solid ${colors.lightBlue};
  background: ${colors.lightBlue};
  color: ${colors.white};
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  padding: 5px 15px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: -26px;
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
    /* background: ${colors.green}; */
    color: ${colors.white};
  }
`;

const SecondaryButton = styled.button`
  border: 1px solid transparent;
  background: ${colors.lightBlue};
  color: ${colors.white};
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  padding: 8px 15px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: -30px;
  letter-spacing: 0.3px;
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
    color: ${colors.white};
  }
`;

ManagerMembershipContainer.propTypes = {
  customerId: PropTypes.string,
  trackSubTab: PropTypes.func,
  code: PropTypes.string,
  pin: PropTypes.string,
};

export default ManagerMembershipContainer;
