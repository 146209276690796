import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, fonts } from '../../styles/variables';

const CheckBox = ({
  styling,
  label,
  labelStyling,
  checked,
  disabled = false,
  onChange,
  onClick = null,
  isCustom = false,
}) => {
  return (
    <CheckBoxContainer style={styling}>
      {label ? (
        <CheckBoxLabel>
          <CheckBoxInput
            type="checkbox"
            onChange={onChange}
            onClick={onClick}
            checked={checked}
            disabled={disabled}
          />
          {isCustom ? (
            <CustomLabel
              style={labelStyling}
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          ) : (
            <span style={labelStyling}>{label}</span>
          )}
        </CheckBoxLabel>
      ) : (
        <CheckBoxInput
          type="checkbox"
          onChange={onChange}
          onClick={onClick}
          checked={checked}
          disabled={disabled}
        />
      )}
    </CheckBoxContainer>
  );
};
//Styling for Checkbox container
export const CheckBoxContainer = styled.div`
  display: inline-block;
`;

//Styling for Checkbox component
export const CheckBoxInput = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 10px;
  flex: 0 0 auto;
`;

//Styling for Checkbox label
export const CheckBoxLabel = styled.label`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  span {
    flex: 0 1 auto;
    color: ${colors.darkGrey};
  }
`;

const CustomLabel = styled.p`
  a {
    color: ${colors.lightBlue};
    font-weight: ${fonts.semiBold};
    cursor: pointer;
  }
`;

CheckBox.propTypes = {
  label: PropTypes.string,
  styling: PropTypes.object,
  labelStyling: PropTypes.object,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isCustom: PropTypes.bool,
};

export default CheckBox;
