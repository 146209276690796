import dayjs from 'dayjs';

//Day.js plugins for additional functionality
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const localizedFormat = require('dayjs/plugin/localizedFormat');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const quarterOfYear = require('dayjs/plugin/quarterOfYear');
const relativeTime = require('dayjs/plugin/relativeTime');

//Extend these plugins to dayjs
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);

// moment().format(format);
// moment(date).format(format);
// moment(date, format).format('YYYY-MM-DD');
export const DateFormat = (date, format, convert = false) => {
  if (convert) {
    return dayjs(date, format).format('YYYY-MM-DD');
  }
  const dt = dayjs(date);
  if (!isNaN(dt)) {
    return dt.format(format);
  }
  return ''
};

// moment.utc(date).format()
// moment.utc(date).format(format);
export const DateFormatUTC = (date, format, isLocal = false) => {
  if (isLocal) {
    return dayjs.utc(date).local().format(format);
  }
  return dayjs.utc(date).format(format);
};

// moment(date, formats, true);
export const DateFormatFromList = (date, formats, isStrict = true) => {
  return dayjs(date, formats, isStrict);
};

// moment()
export const DateNow = () => {
  return dayjs();
};

// moment(date).fromNow(true);
export const DateFromNow = (date, showSuffix = true) => {
  return dayjs(date).fromNow(showSuffix);
};

// moment.utc(date).local().fromNow();
// moment.utc(date).fromNow();
export const DateFromNowUTC = (date, isLocal = false) => {
  if (isLocal) {
    return dayjs.utc(date).local().fromNow();
  }
  return dayjs.utc(date).fromNow();
};

// moment(new Date()).month();
export const DateMonth = () => {
  return dayjs().month();
};

// moment(date).add(duration, key);
export const DateAdd = (date, duration, key = 'year') => {
  return dayjs(date).add(duration, key);
};

// moment(date).subtract(duration, key)
export const DateSubtract = (date, duration, key = 'year') => {
  return dayjs(date).subtract(duration, key);
};

// moment(date).diff(duration, key)
export const DateDiff = (date, duration, key = 'year') => {
  return dayjs(date).diff(duration, key);
};

// moment().startOf(key)
// moment(key).subtract(duration, secondKey);
// moment(key).subtract(duration, secondKey).startOf(thirdKey);
export const DateStartOf = (
  key = 'year',
  duration = null,
  secondKey = 'day',
  thirdKey = null
) => {
  if (duration) {
    if (thirdKey) {
      return dayjs()
        .startOf(key)
        .subtract(duration, secondKey)
        .startOf(thirdKey);
    }
    return dayjs().startOf(key).subtract(duration, secondKey);
  }
  return dayjs().startOf(key);
};

// moment(date).isValid();
// moment(textValue, dateFormat, true).isValid();
export const DateIsValid = (date, dateFormat = false, useStrict = true) => {
  if (dateFormat) {
    return dayjs(date, dateFormat, useStrict).isValid();
  }
  return dayjs(date).isValid();
};

export const GuessTimeZone = () => {
  return dayjs.tz.guess();
};
