/* eslint-disable array-callback-return */
import { SortInstrumentPriority } from '../transformers';
import { CalculateNorthValues } from './calculate';
import {
  DetermineOwnedByAnother,
  FilterMemberInstruments,
  FilterSplitMemberInstruments,
  // GetEntityInstruments,
  // GetMemberEntities,
  HasNoOwnersCategory,
} from './filters';
// import { GenerateRollupItems } from './rollup';

export const GenerateNorthItems = (
  membersList,
  entityList,
  insurances,
  selectedMembers,
  amrShowEntity,
  showEntityRollup,
  allInstrumentCategories,
  setNorthJointItems,
  setNorthLeftItems,
  setNorthRightItems
) => {
  if (selectedMembers.length === 2) {
    // const firstMemEntities = GetMemberEntities(
    //   entityList,
    //   selectedMembers[0].id
    // );
    // const secondMemEntities = GetMemberEntities(
    //   entityList,
    //   selectedMembers[1].id
    // );
    // const jointEntities = firstMemEntities.filter((value) =>
    //   secondMemEntities.includes(value)
    // );
    const matchingFirstMemberInsurances = FilterSplitMemberInstruments(
      [...insurances],
      selectedMembers[0].id,
      'insured'
    );
    const matchingSecondMemberInsurances = FilterSplitMemberInstruments(
      [...insurances],
      selectedMembers[1].id,
      'insured'
    );
    let jointInsurances = matchingFirstMemberInsurances.filter((item) =>
      matchingSecondMemberInsurances.includes(item)
    );
    jointInsurances = DetermineOwnedByAnother(selectedMembers, jointInsurances);
    let filteredFirstMemberInsurances = matchingFirstMemberInsurances.filter(
      (item) => !jointInsurances.includes(item)
    );
    filteredFirstMemberInsurances = DetermineOwnedByAnother(
      selectedMembers,
      filteredFirstMemberInsurances,
      selectedMembers[0].id
    );
    filteredFirstMemberInsurances = CalculateNorthValues(
      filteredFirstMemberInsurances,
      false,
      true
    );
    filteredFirstMemberInsurances = filteredFirstMemberInsurances.filter(
      (insurance) => {
        if (insurance.category === 'propcas' && insurance.assets.length > 0) {
          return;
        }
        return insurance;
      }
    );

    let filteredSecondMemberInsurances = matchingSecondMemberInsurances.filter(
      (item) => !jointInsurances.includes(item)
    );
    filteredSecondMemberInsurances = DetermineOwnedByAnother(
      selectedMembers,
      filteredSecondMemberInsurances,
      selectedMembers[1].id
    );
    filteredSecondMemberInsurances = CalculateNorthValues(
      filteredSecondMemberInsurances,
      false,
      true
    );
    filteredSecondMemberInsurances = filteredSecondMemberInsurances.filter(
      (insurance) => {
        if (insurance.category === 'propcas' && insurance.assets.length > 0) {
          return;
        }
        return insurance;
      }
    );
    jointInsurances = CalculateNorthValues(jointInsurances, false, true);
    jointInsurances = jointInsurances.filter((insurance) => {
      if (insurance.category === 'propcas' && insurance.assets.length > 0) {
        return;
      }
      return insurance;
    });

    // let jointEntityInsurances = [];
    // // let jointEntityRollupInsurances = [];
    // if (amrShowEntity) {
    //   jointEntityInsurances = GetEntityInstruments(
    //     [...insurances],
    //     jointEntities,
    //     'insured'
    //   );
    //   const memberInsuranceIds = jointInsurances.map((i) => i.id);
    //   jointEntityInsurances = jointEntityInsurances.filter((insurance) => {
    //     if (insurance.category === 'propcas' && insurance.assets.length > 0) {
    //       return;
    //     }
    //     if (!memberInsuranceIds.includes(insurance.id)) {
    //       //LINKED LIABILITY CHANGES
    //       insurance.show_cash_value = false;
    //       insurance.ignore_cash_value = false;
    //       //LINKED LIABILITY CHANGES
    //       return insurance;
    //     }
    //   });
    //LINKED LIABILITY CHANGES
    // jointEntityInsurances = CalculateNorthValues(jointEntityInsurances);
    //LINKED LIABILITY CHANGES
    // if (showEntityRollup) {
    //   //1. Find all rollup entities
    //   const jointRollupEntities = jointEntities.filter(
    //     (entity) => entity.entity_rollup
    //   );
    //   //2. Group instruments by entity then category
    //   jointEntityRollupInsurances = GenerateRollupItems(
    //     [selectedMembers[0].id, selectedMembers[1].id],
    //     jointRollupEntities,
    //     jointEntityInsurances,
    //     allInstrumentCategories,
    //     'insurance',
    //     'northJoint'
    //   );
    //   //3. Find all rollup entities' insurances
    //   const rollupInstruments = GetEntityInstruments(
    //     jointEntityInsurances,
    //     jointRollupEntities
    //   );
    //   //4. Filter out rollup insurances from regular display
    //   jointEntityInsurances = jointEntityInsurances.filter((ins) => {
    //     if (!rollupInstruments.some((i) => i.id === ins.id)) {
    //       return ins;
    //     }
    //   });
    // }
    // }
    // const jointEntityInsuranceIds = jointEntityInsurances.map((i) => i.id);
    // const jointEntityRollupInsuranceIds = jointEntityRollupInsurances.map(
    //   (i) => i.id
    // );

    // let firstEntityInsurances = [];
    // let firstEntityRollupInsurances = [];
    // if (amrShowEntity) {
    //   firstEntityInsurances = GetEntityInstruments(
    //     [...insurances],
    //     firstMemEntities,
    //     'insured'
    //   );
    //   firstEntityInsurances = firstEntityInsurances.filter((insurance) => {
    //     if (insurance.category === 'propcas' && insurance.assets.length > 0) {
    //       return;
    //     }
    //     if (!jointEntityInsuranceIds.includes(insurance.id)) {
    //       return insurance;
    //     }
    //   });
    //   firstEntityInsurances = CalculateNorthValues(firstEntityInsurances);
    // if (showEntityRollup) {
    //   //1. Find all rollup entities
    //   const firstRollupEntities = firstMemEntities.filter(
    //     (entity) => entity.entity_rollup
    //   );
    //   //2. Group instruments by entity then category
    //   firstEntityRollupInsurances = GenerateRollupItems(
    //     [selectedMembers[0].id],
    //     firstRollupEntities,
    //     firstEntityInsurances,
    //     allInstrumentCategories,
    //     'insurance',
    //     'northLeft'
    //   );

    //   firstEntityRollupInsurances = firstEntityRollupInsurances.filter(
    //     (insurance) => {
    //       if (!jointEntityRollupInsuranceIds.includes(insurance.id)) {
    //         return insurance;
    //       }
    //     }
    //   );

    //   //3. Find all rollup entities' insurances
    //   const rollupInstruments = GetEntityInstruments(
    //     firstEntityInsurances,
    //     firstRollupEntities
    //   );
    //   //Need to remove any insurance that is part of joint rollup!
    //   //4. Filter out rollup insurances from regular display
    //   firstEntityInsurances = firstEntityInsurances.filter((ins) => {
    //     if (!rollupInstruments.some((i) => i.id === ins.id)) {
    //       return ins;
    //     }
    //   });
    // }
    // }
    // let secondEntityInsurances = [];
    // let secondEntityRollupInsurances = [];
    // if (amrShowEntity) {
    //   secondEntityInsurances = GetEntityInstruments(
    //     [...insurances],
    //     secondMemEntities,
    //     'insured'
    //   );
    //   secondEntityInsurances = secondEntityInsurances.filter((insurance) => {
    //     if (insurance.category === 'propcas' && insurance.assets.length > 0) {
    //       return;
    //     }
    //     if (!jointEntityInsuranceIds.includes(insurance.id)) {
    //       return insurance;
    //     }
    //   });
    //LINKED LIABILITY CHANGES
    // secondEntityInsurances = CalculateNorthValues(secondEntityInsurances);
    //LINKED LIABILITY CHANGES
    //   if (showEntityRollup) {
    //     //1. Find all rollup entities
    //     const secondRollupEntities = secondMemEntities.filter(
    //       (entity) => entity.entity_rollup
    //     );
    //     //2. Group instruments by entity then category
    //     secondEntityRollupInsurances = GenerateRollupItems(
    //       [selectedMembers[1].id],
    //       secondRollupEntities,
    //       secondEntityInsurances,
    //       allInstrumentCategories,
    //       'insurance',
    //       'northRight'
    //     );
    //     secondEntityRollupInsurances = secondEntityRollupInsurances.filter(
    //       (insurance) => {
    //         if (!jointEntityRollupInsuranceIds.includes(insurance.id)) {
    //           return insurance;
    //         }
    //       }
    //     );
    //     //3. Find all rollup entities' insurances
    //     const rollupInstruments = GetEntityInstruments(
    //       secondEntityInsurances,
    //       secondRollupEntities
    //     );
    //     //4. Filter out rollup insurances from regular display
    //     secondEntityInsurances = secondEntityInsurances.filter((ins) => {
    //       if (!rollupInstruments.some((i) => i.id === ins.id)) {
    //         return ins;
    //       }
    //     });
    //   }
    // }

    setNorthLeftItems(
      SortInstrumentPriority([
        ...filteredFirstMemberInsurances,
        // ...firstEntityInsurances,
        // ...firstEntityRollupInsurances,
      ])
    );
    setNorthRightItems(
      SortInstrumentPriority([
        ...filteredSecondMemberInsurances,
        // ...secondEntityInsurances,
        // ...secondEntityRollupInsurances,
      ])
    );
    setNorthJointItems(
      SortInstrumentPriority([
        ...jointInsurances,
        // ...jointEntityInsurances,
        // ...jointEntityRollupInsurances,
      ])
    );
    return [];
  } else if (
    selectedMembers.length === membersList.length &&
    selectedMembers.length !== 1
  ) {
    let calculatedValues = CalculateNorthValues([...insurances], true);
    calculatedValues = calculatedValues.map((insurance) => {
      insurance.owned_by_entity = false;
      return insurance;
    });
    calculatedValues = calculatedValues.filter((insurance) => {
      if (insurance.category === 'propcas' && insurance.assets.length > 0) {
        return;
      }
      return insurance;
    });
    //LINKED LIABILITY CHANGES
    setNorthLeftItems([]);
    setNorthRightItems([]);
    setNorthJointItems([]);
    //LINKED LIABILITY CHANGES
    return SortInstrumentPriority(calculatedValues);
  } else if (selectedMembers.length === 1) {
    //LIST OF ALL THE INSURANCES A MEMBER IS INSURED UNDER
    let filteredInsurances = FilterMemberInstruments(
      selectedMembers,
      [...insurances],
      'insured'
    );
    //CHECKS IF MEMBER IS ALSO OWNER OF INSTRUMENT THEY ARE INSURED UNDER
    //IF THEY ARE NOT IT IS MARKED AS OWNED_BY_ANOTHER AND YELLOW
    filteredInsurances = DetermineOwnedByAnother(
      selectedMembers,
      filteredInsurances
    );
    //GENERATES DISPLAY AMOUNT FOR INSURANCES & REMOVES OWNED_BY_ENTITY
    filteredInsurances = CalculateNorthValues(filteredInsurances, false, true);
    // filteredInsurances = filteredInsurances.map((insurance) => {
    //   insurance.owned_by_entity = false;
    //   return insurance;
    // });
    //REMOVES PROPCAS INSURANCE WITH LINKED ASSET
    filteredInsurances = filteredInsurances.filter((insurance) => {
      if (insurance.category === 'propcas' && insurance.assets.length > 0) {
        return;
      }
      return insurance;
    });

    // let rollupInsurances = [];
    // let entityInsurances = [];
    // if (amrShowEntity) {
    //   const entities = GetMemberEntities(entityList, null, selectedMembers);
    //   entityInsurances = GetEntityInstruments(insurances, entities, 'insured');
    //   entityInsurances = entityInsurances.filter((insurance) => {
    //     if (insurance.category === 'propcas' && insurance.assets.length > 0) {
    //       return;
    //     }
    //     return insurance;
    //   });
    //LINKED LIABILITY CHANGES
    // entityInsurances = CalculateNorthValues(entityInsurances);
    //LINKED LIABILITY CHANGES
    // if (showEntityRollup) {
    //   //1. Find all rollup entities
    //   const rollupEntities = entities.filter(
    //     (entity) => entity.entity_rollup
    //   );
    //   //2. Group instruments by entity then category
    //   rollupInsurances = GenerateRollupItems(
    //     [selectedMembers[0].id],
    //     rollupEntities,
    //     entityInsurances,
    //     allInstrumentCategories,
    //     'insurance',
    //     'north'
    //   );
    //   //3. Find all rollup entities' insurances
    //   const rollupInstruments = GetEntityInstruments(
    //     entityInsurances,
    //     rollupEntities
    //   );
    //   //4. Filter out rollup insurances from regular display
    //   entityInsurances = entityInsurances.filter((ins) => {
    //     if (!rollupInstruments.some((i) => i.id === ins.id)) {
    //       return ins;
    //     }
    //   });
    // }
    // }
    //LINKED LIABILITY CHANGES
    setNorthJointItems([]);
    setNorthLeftItems([]);
    setNorthRightItems([]);
    //LINKED LIABILITY CHANGES
    return SortInstrumentPriority([
      ...filteredInsurances,
      // ...entityInsurances,
      // ...rollupInsurances,
    ]);
  } else if (selectedMembers.length === 0) {
    const noInsured = HasNoOwnersCategory(insurances, 'insured');
    //LINKED LIABILITY CHANGES
    setNorthLeftItems([]);
    setNorthRightItems([]);
    setNorthJointItems([]);
    //LINKED LIABILITY CHANGES
    return CalculateNorthValues(noInsured, true);
  } else {
    let filteredInsurances = FilterMemberInstruments(
      selectedMembers,
      [...insurances],
      'insured'
    );
    filteredInsurances = DetermineOwnedByAnother(
      selectedMembers,
      filteredInsurances
    );
    filteredInsurances = filteredInsurances.filter((insurance) => {
      if (insurance.category === 'propcas' && insurance.assets.length > 0) {
        return;
      }
      return insurance;
    });
    const finalInsurances = filteredInsurances.map((item) => {
      let updated = { ...item };
      updated.calculated = item.amount;
      updated.ignore_cash_value = true;
      updated.owned_by_entity = false;
      return updated;
    });

    // const memberEntities = GetMemberEntities(
    //   entityList,
    //   false,
    //   selectedMembers
    // );
    // const selectedEntities = selectedMembers.filter(
    //   (mem) => mem.member_type === 'entity'
    // );
    // const finalEntities = memberEntities.filter((entity) => {
    //   return !selectedEntities.includes(entity);
    // });

    // let rollupInsurances = [];
    // let entityInsurances = [];
    // if (amrShowEntity) {
    //   entityInsurances = GetEntityInstruments(
    //     insurances,
    //     finalEntities,
    //     'insured'
    //   );
    //   entityInsurances = entityInsurances.filter((insurance) => {
    //     if (insurance.category === 'propcas' && insurance.assets.length > 0) {
    //       return;
    //     }
    //     return insurance;
    //   });
    //LINKED LIABILITY CHANGES
    // entityInsurances = CalculateNorthValues(entityInsurances);
    //LINKED LIABILITY CHANGES
    // if (showEntityRollup) {
    //   //1. Find all rollup entities
    //   const rollupEntities = finalEntities.filter(
    //     (entity) => entity.entity_rollup
    //   );
    //   const selectedMemIds = selectedMembers.map((mem) => mem.id);
    //   //2. Group instruments by entity then category
    //   rollupInsurances = GenerateRollupItems(
    //     selectedMemIds,
    //     rollupEntities,
    //     entityInsurances,
    //     allInstrumentCategories,
    //     'insurance',
    //     'north'
    //   );
    //   //3. Find all rollup entities' insurances
    //   const rollupInstruments = GetEntityInstruments(
    //     entityInsurances,
    //     rollupEntities
    //   );
    //   //4. Filter out rollup insurances from regular display
    //   entityInsurances = entityInsurances.filter((ins) => {
    //     if (!rollupInstruments.some((i) => i.id === ins.id)) {
    //       return ins;
    //     }
    //   });
    // }
    // }
    //LINKED LIABILITY CHANGES
    setNorthLeftItems([]);
    setNorthRightItems([]);
    setNorthJointItems([]);
    //LINKED LIABILITY CHANGES
    return SortInstrumentPriority([
      ...finalInsurances,
      // ...entityInsurances,
      // ...rollupInsurances,
    ]);
  }
};
