/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  CreateRoleModal,
  Error,
  GenerateInput,
  PermissionListItem,
  SimpleModal,
} from '../../components';
import {
  getUserRoles,
  cloneUserRole,
  deleteUserRole,
  updateUserStoreValue,
  getUserRolePermissions,
  getDefaultUserRolePermissions,
  createUserRole,
  updateUserRole,
} from '../../store/actions';
import { colors, maxDevice, fonts } from '../../styles/variables';
import { ButtonThemes, ErrorThemes } from '../../styles/themes';
import { CapitalizeFirstLetter, UserRoleOption } from '../../utils';
import { TextInputRow } from '../../styles/library/inputStyles';

const ConnectRolesSettingsContainer = () => {
  const dispatch = useDispatch();
  const {
    loadedUser,
    user,
    roles,
    rolePermissions,
    defaultRolePermissions,
    clonedRole,
    deletedUserRole,
    updatedUserRole,
    updateUserRoleError,
    createRoleError,
  } = useSelector((state) => ({
    loadedUser: state.user.loadedUser,
    user: state.user.user,
    roles: state.user.roles,
    rolePermissions: state.user.rolePermissions,
    defaultRolePermissions: state.user.defaultRolePermissions,
    clonedRole: state.user.clonedRole,
    deletedUserRole: state.user.deletedUserRole,
    updatedUserRole: state.user.updatedUserRole,
    updateUserRoleError: state.user.updateUserRoleError,
    createRoleError: state.user.createRoleError,
  }));
  const [loadedRoles, setLoadedRoles] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [showRoleDetails, setShowRoleDetails] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [hasNameError, setHasNameError] = useState(false);
  const [nameError, setNameError] = useState('Name is required');
  const [showPermissions, setShowPermissions] = useState(false);
  const [permissionsList, setPermissionsList] = useState([]);
  const [loadedPermissions, setLoadedPermissions] = useState(false);
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [newRole, setNewRole] = useState({ name: '', scopes: [] });
  const [newRoleError, setNewRoleError] = useState();
  const [loadedDefaultPermissions, setLoadedDefaultPermissions] =
    useState(false);
  const [updatingRole, setUpdatingRole] = useState(false);
  const [creatingRole, setCreatingRole] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    // isShowing: false,
    isDelete: false,
    loadingText: 'Cloning',
    actionValue: 'clone',
    actionDisplay: 'Clone',
    error: '',
  });
  const [errorMessage, setErrorMessage] = useState(false);

  //FETCH LIST OF USER ROLES
  useEffect(() => {
    if (loadedUser) {
      dispatch(getUserRoles(user.uuid));
    }
  }, [loadedUser]);

  //SET USER ROLES IN LOCAL STATE
  useEffect(() => {
    if (roles) {
      const transformedRoles = roles.map((role) => {
        role.value = role.role;
        role.label = role.name;
        return role;
      });
      setRoleOptions(transformedRoles);
      setSelectedRole(transformedRoles[0]);
      setLoadedRoles(true);
    }
  }, [roles]);

  useEffect(() => {
    if (selectedRole?.value && loadedRoles) {
      setIsClone(false);
      setShowPermissions(true);
      setLoadedPermissions(false);
      setShowRoleDetails(true);
      setIsEditable(selectedRole?.editable);
      dispatch(getUserRolePermissions(user?.uuid, selectedRole?.value));
    }
  }, [selectedRole?.value]);

  useEffect(() => {
    if (defaultRolePermissions) {
      setLoadedDefaultPermissions(true);
      setNewRole({ ...newRole, scopes: defaultRolePermissions?.scopes });
      dispatch(updateUserStoreValue('defaultRolePermissions', null));
    }
  }, [defaultRolePermissions]);

  useEffect(() => {
    if (rolePermissions && !clonedRole) {
      setIsEditable(rolePermissions?.role?.editable);
      setIsClone(false);
      setTimeout(() => {
        setPermissionsList(rolePermissions?.scopes);
        setLoadedPermissions(true);
      }, 200);
    }
  }, [rolePermissions]);

  useEffect(() => {
    if (clonedRole) {
      setIsClone(true);
      setIsEditable(clonedRole?.role?.editable);
      setSelectedRole({ ...selectedRole, name: clonedRole?.role?.name });
      dispatch(updateUserStoreValue('clonedRole', null));
      setTimeout(() => {
        setPermissionsList(clonedRole?.scopes);
        setLoadedPermissions(true);
      }, 200);
    }
  }, [clonedRole]);

  //SET USER ROLES IN LOCAL STATE
  useEffect(() => {
    if (confirmModal?.isShowing === false) {
      setUpdatingRole(false);
      setConfirmModal({ ...confirmModal, error: '' });
    }
  }, [confirmModal?.isShowing]);

  //SET USER ROLES IN LOCAL STATE
  useEffect(() => {
    if (showCreateRoleModal === false) {
      setNewRole({ name: '', scopes: [] });
      setLoadedDefaultPermissions(false);
      setCreatingRole(false);
      setIsClone(false);
      setNewRoleError();
    }
  }, [showCreateRoleModal]);

  //CLOSE MODAL AND CLEAR STORE AFTER UPDATE
  useEffect(() => {
    if (updatedUserRole) {
      setConfirmModal({ ...confirmModal, isShowing: false });
      setShowCreateRoleModal(false);
      setUpdatingRole(false);
      setErrorMessage();
      if (!clonedRole) {
        dispatch(getUserRolePermissions(user?.uuid, selectedRole?.value));
      }
      dispatch(updateUserStoreValue('updatedUserRole', false));
    }
  }, [updatedUserRole]);

  //CLOSE MODAL AND CLEAR STORE AFTER UPDATE
  useEffect(() => {
    if (deletedUserRole) {
      setConfirmModal({ ...confirmModal, isShowing: false });
      setShowCreateRoleModal(false);
      setUpdatingRole(false);
      setErrorMessage();
      dispatch(updateUserStoreValue('deletedUserRole', false));
    }
  }, [deletedUserRole]);

  //CLOSE MODAL AND CLEAR STORE AFTER UPDATE
  useEffect(() => {
    if (updateUserRoleError) {
      setUpdatingRole(false);
      const errorData = updateUserRoleError?.data;
      let errorMsg = 'Error updating permission set';
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          if (key === 'name') {
            let message = value;
            if (Array.isArray(value)) {
              message = value[0];
            }
            setHasNameError(true);
            setNameError(CapitalizeFirstLetter(message));
          }
          errors.push({ field: key, message: value });
        }
        errorMsg = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      if (confirmModal?.isShowing) {
        setConfirmModal({ ...confirmModal, error: errorMsg });
      } else {
        setErrorMessage(errorMsg);
      }
      dispatch(updateUserStoreValue('updateUserRoleError', null));
    }
  }, [updateUserRoleError]);

  //CLOSE MODAL AND CLEAR STORE AFTER UPDATE
  useEffect(() => {
    if (createRoleError) {
      setCreatingRole(false);
      const errorData = createRoleError?.data;
      let errorMsg = 'Error creating permission set';
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          if (key === 'name') {
            let message = value;
            if (Array.isArray(value)) {
              message = value[0];
            }
            setNewRole({
              ...newRole,
              nameError: true,
              nameErrorMessage: CapitalizeFirstLetter(message),
            });
          }
          errors.push({ field: key, message: value });
        }
        errorMsg = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      setNewRoleError(errorMsg);
      dispatch(updateUserStoreValue('createRoleError', null));
    }
  }, [createRoleError]);

  const generateConfirmContent = () => {
    const { name, role } = selectedRole;
    const { loadingText, isDelete, actionValue, actionDisplay } = confirmModal;
    return {
      heading: `${actionDisplay} Permission Set?`,
      text: `<p>Are you sure you want to ${actionValue} <strong>${name}</strong>?</p></br/>${
        isDelete
          ? 'This action cannot be undone.'
          : 'This will create a copy of this permission set you can edit.'
      } </p>`,
      buttons: [
        {
          text: 'Cancel',
          function: () =>
            setConfirmModal({ ...confirmModal, isShowing: false }),
          theme: ButtonThemes.cancel,
        },
        {
          text: `Yes, ${actionDisplay}`,
          function: () => {
            setUpdatingRole(true);
            setConfirmModal({ ...confirmModal, error: '' });
            isDelete
              ? dispatch(deleteUserRole(user?.uuid, role))
              : dispatch(cloneUserRole(user?.uuid, role));
          },
          showLoading: updatingRole,
          loadingText: loadingText,
          theme: isDelete ? ButtonThemes.error : ButtonThemes.primary,
        },
      ],
    };
  };

  const resetErrorDisplay = () => {
    setHasNameError(false);
    setNameError('');
    setConfirmModal({ ...confirmModal, error: '' });
  };

  // TODO: Turn these back on when restoring delete button
  // const onCancel = () => {
  //   setSelectedRole({ ...selectedRole, name: selectedRole?.label });
  //   dispatch(getUserRolePermissions(user?.uuid, selectedRole?.value));
  // };

  // const onDelete = () => {
  //   setConfirmModal({
  //     isShowing: true,
  //     isDelete: true,
  //     loadingText: 'Deleting',
  //     actionValue: 'delete',
  //     actionDisplay: 'Delete',
  //     error: '',
  //   });
  // };

  const onClone = () => {
    setConfirmModal({
      isShowing: true,
      isDelete: false,
      loadingText: 'Cloning',
      actionValue: 'clone',
      actionDisplay: 'Clone',
      error: '',
    });
  };

  const permissionOnChange = (e) => {
    const updatedScopes = permissionsList.map((scope) => {
      if (e.name === scope.name) {
        scope.selected = !scope.selected;
      }
      return scope;
    });
    setPermissionsList(updatedScopes);
  };

  const newRoleOnChange = (e, isText = false) => {
    if (isText) {
      setNewRole({ ...newRole, name: e.currentTarget.value });
    } else {
      const updatedScopes = newRole.scopes.map((scope) => {
        if (e.name === scope.name) {
          scope.selected = !scope.selected;
        }
        return scope;
      });
      setNewRole({ ...newRole, scopes: updatedScopes });
    }
  };

  const newRoleSave = () => {
    setCreatingRole(true);
    dispatch(createUserRole(user?.uuid, newRole));
  };

  const onSave = () => {
    setLoadedPermissions(false);
    setUpdatingRole(true);
    const roleDetails = {
      name: selectedRole.name,
      scopes: permissionsList,
    };
    dispatch(createUserRole(user?.uuid, roleDetails));
  };

  const onUpdate = () => {
    //Send name value and updates scopes
    //Need error validation
    const roleDetails = {
      value: selectedRole.value,
      name: selectedRole.name,
      scopes: permissionsList,
    };
    setLoadedPermissions(false);
    setUpdatingRole(true);
    dispatch(updateUserRole(user?.uuid, roleDetails));
  };

  return (
    <>
      {confirmModal?.isShowing && (
        <SimpleModal
          hide={() => setConfirmModal({ ...confirmModal, isShowing: false })}
          content={generateConfirmContent()}
          error={confirmModal.error}
        />
      )}
      {showCreateRoleModal && (
        <CreateRoleModal
          role={newRole}
          hide={() => setShowCreateRoleModal(false)}
          error={newRoleError}
          loading={!loadedDefaultPermissions}
          buttonLoading={creatingRole}
          onChange={newRoleOnChange}
          onSave={newRoleSave}
        />
      )}
      <ContainerLayout>
        <LeftContent>
          <HeadingContent>
            <FormHeader>Permission Sets</FormHeader>
            <Button
              text="Create New"
              onClick={() => {
                dispatch(getDefaultUserRolePermissions(user?.uuid));
                setShowCreateRoleModal(true);
              }}
            />
          </HeadingContent>
          <RoleContainer>
            {GenerateInput({
              type: 'select',
              width: '100%',
              isVisible: true,
              label: 'Permission Set',
              name: 'role',
              placeholder: 'Select Permission Set',
              customComponent: UserRoleOption,
              value: selectedRole,
              options: roleOptions,
              isLoading: !loadedRoles,
              onChange: (e) => {
                resetErrorDisplay();
                setSelectedRole(e);
              },
            })}
          </RoleContainer>
          {showRoleDetails && (
            <div>
              <TextInputRow>
                {GenerateInput({
                  isVisible: true,
                  width: '100%',
                  required: true,
                  label: 'Name',
                  type: 'text',
                  mobileWidth: '100%',
                  disabled: !isEditable,
                  value: selectedRole?.name,
                  placeholder: `Permission Set Name`,
                  hasError: hasNameError,
                  errorMessage: nameError,
                  onChange: (e) => {
                    resetErrorDisplay();
                    setSelectedRole({
                      ...selectedRole,
                      name: e.currentTarget.value,
                    });
                  },
                })}
              </TextInputRow>
              {showPermissions && (
                <PermissionsContainer>
                  <PermissionsHeading>Permissions</PermissionsHeading>
                  {loadedPermissions ? (
                    permissionsList.length ? (
                      permissionsList.map((permission, index) => {
                        return (
                          !permission.hidden && (
                            <PermissionListItem
                              key={index}
                              permission={permission}
                              onChange={permissionOnChange}
                              showValue={true}
                              viewOnly={!isEditable}
                            />
                          )
                        );
                      })
                    ) : (
                      <EmptyDisplay>No permissions available</EmptyDisplay>
                    )
                  ) : (
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => {
                      return (
                        <PermissionListItem
                          key={index}
                          isLoading={true}
                          showValue={true}
                        />
                      );
                    })
                  )}
                </PermissionsContainer>
              )}
              <ButtonBar>
                {/* <LeftButtons>
                  {isEditable ? (
                    isClone ? (
                      <Button
                        text="Cancel"
                        theme={ButtonThemes.cancel}
                        onClick={onCancel}
                      />
                    ) : (
                      <Button
                        text="Delete"
                        theme={ButtonThemes.error}
                        onClick={onDelete}
                      />
                    )
                  ) : null}
                </LeftButtons> */}
                <RightButtons>
                  {!isClone && <Button text="Clone" onClick={onClone} />}
                  {isEditable && (
                    <Button
                      text={isClone ? 'Save' : 'Update'}
                      onClick={isClone ? onSave : onUpdate}
                      loadingText={isClone ? 'Saving' : 'Updating'}
                      showLoading={updatingRole}
                    />
                  )}
                </RightButtons>
              </ButtonBar>
              {errorMessage && (
                <Error
                  errorMessage={errorMessage}
                  theme={ErrorThemes.inverted}
                />
              )}
            </div>
          )}
        </LeftContent>
        <RightContent />
      </ContainerLayout>
    </>
  );
};

const RoleContainer = styled.div``;

// TODO: Turn these back on when restoring delete button
// const LeftButtons = styled.div`
//   flex: 0 0 auto;
// `;

const EmptyDisplay = styled.p`
  padding: 20px 0;
  color: ${colors.paleGrey};
`;

const RightButtons = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 1 auto;
  button {
    margin-left: 15px;
  }
`;

const ButtonBar = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
  justify-content: space-between;
`;

const ContainerLayout = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  margin: -25px 0px 0px 0px;
  background: ${colors.lighterGrey};
`;

const LeftContent = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
    flex: 1 1 auto;
  }
  @media ${maxDevice.sideNav} {
    padding: 20px 20px 100px 20px;
    overflow-x: hidden;
    min-height: 500px;
  }
  flex: 1 1 40%;
  min-height: 800px;
  /* max-width: 50%;
  min-width: 50%; */
  background: white;
  padding: 30px 40px 100px 40px;
`;

const RightContent = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
    flex: 1 1 auto;
  }
  flex: 1 1 45%;
`;

const PermissionsContainer = styled.div`
  margin: 15px 0 15px 0;
  text-align: left;
`;

const PermissionsHeading = styled.p`
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
  padding-bottom: 3px;
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  span {
    flex: 1 1 auto;
  }
  p {
    flex: 0 0 auto;
    font-size: 11px;
    color: ${colors.paleGrey};
    cursor: pointer;
  }
`;

const HeadingContent = styled.div`
  border-bottom: 1px solid ${colors.darkGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0 15px 0;
  margin-bottom: 15px;
`;

export default ConnectRolesSettingsContainer;
