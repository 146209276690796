import { useDispatch, useSelector } from 'react-redux';
import { showLoginScreen } from '../../store/actions';
import { checkCircle } from '../../assets';
import {
  LineBreak,
  CenterFlexContainer,
} from '../../styles/library/layoutStyles';
import {
  AuthText,
  Text,
  EmailLink,
  LinkText,
  LinkStyle,
} from '../../styles/library/fontStyles';
import { ModalCheck } from '../../styles/library/imageStyles';
import { fonts } from '../../styles/variables';

const RecoverySentModal = () => {
  const dispatch = useDispatch();
  const { resetEmail } = useSelector((state) => ({
    resetEmail: state.user.resetEmail,
  }));

  return (
    <>
      <ModalCheck src={checkCircle} alt="check" data-image="check-circle" />
      <AuthText style={{ paddingBottom: '0' }}>
        A password recovery email was sent to:
      </AuthText>
      <Text
        style={{
          textAlign: 'center',
          padding: '10px 0 15px 0',
          fontWeight: fonts.semiBold,
        }}
      >
        {resetEmail}
      </Text>
      <LineBreak />
      <AuthText style={{ paddingBottom: '0' }}>
        For any questions, please contact our Asset-Map support at: (888)
        664-8850 or email{' '}
        <EmailLink href="mailto:support@asset-map.com">
          support@asset-map.com
        </EmailLink>
      </AuthText>
      <AuthText style={{ padding: '15px 0 5px 0' }}>
        For more information, visit us at{' '}
        <LinkStyle href="https://www.asset-map.com/" target="_blank">
          asset-map.com
        </LinkStyle>
      </AuthText>
      <CenterFlexContainer>
        <LinkText
          onClick={() => {
            dispatch(showLoginScreen());
          }}
        >
          Back
        </LinkText>
      </CenterFlexContainer>
    </>
  );
};

export default RecoverySentModal;
