/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormChunk,
  SimpleModal,
  AuthFactorModal,
  AuthFactorOption,
  AuthDevice,
} from '../../components';
import {
  updateExistingUserPassword,
  resetPasswordFields,
  disableAuthFactor,
  resetAuthFactors,
  getAuthDevices,
  removeAuthDevice,
  getUserInfo,
} from '../../store/actions';
import {
  chrome,
  firefox,
  safari,
  edge,
  passwordProtected,
  internetExplorer,
  opera,
  globe,
} from '../../assets';
import { CheckHasValue, authFactorOptions } from '../../utils';
import { ButtonThemes } from '../../styles/themes';
import { colors, maxDevice, fonts } from '../../styles/variables';
import { SecondaryHeading } from '../../styles/library/fontStyles';

const SecurityPreferencesContainer = () => {
  const dispatch = useDispatch();
  const {
    isSSO,
    showMFA,
    passwordResetError,
    passwordUpdated,
    customSettingsError,
    authFactors,
    authFactorAdded,
    authFactorReturned,
    authCodeEnabled,
    showCodeSentScreen,
    authCodeDisabled,
    authDevices,
    showSetupMFA,
  } = useSelector((state) => ({
    isSSO: state.user.isSSO,
    showMFA: state.user.showMFA,
    passwordResetError: state.user.passwordResetError,
    passwordUpdated: state.user.passwordUpdated,
    authFactors: state.user.authFactors,
    authFactorAdded: state.user.authFactorAdded,
    authFactorReturned: state.user.authFactorReturned,
    authCodeEnabled: state.user.authCodeEnabled,
    showCodeSentScreen: state.user.showCodeSentScreen,
    authCodeDisabled: state.user.authCodeDisabled,
    authDevices: state.user.authDevices,
    showSetupMFA: state.user.showSetupMFA,
  }));
  const [password, setPassword] = useState({
    password: '',
    password1: '',
    password2: '',
  });
  const [errorFields, setErrorFields] = useState([]);
  const [passwordErrorMessages, setPasswordErrorMessages] = useState([]);
  const [showClearDeviceModal, setShowClearDeviceModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const [showAuthFactorModal, setShowAuthFactorModal] = useState(false);
  const [authScreenValue, setAuthScreenValue] = useState('add');
  const [showDisableAuthMethodModal, setShowDisableAuthMethodModal] =
    useState(false);
  const [isDeleteMethod, setIsDeleteMethod] = useState(false);
  const [selectedAuthMethod, setSelectedAuthMethod] = useState();
  const [authMethodOptions, setAuthMethodOptions] = useState(authFactorOptions);
  const [authDeviceList, setAuthDeviceList] = useState([]);

  const createErrorMessage = (errors) => {
    if (errors['old_password']) {
      setErrorFields([{ field: 'password' }]);
      return setPasswordErrorMessages(['Incorrect Password']);
    }
    if (errors['new_password']) {
      let errorsArray = [];
      setErrorFields([{ field: 'password1' }, { field: 'password2' }]);
      let newErr = '';
      if (Array.isArray(errors)) {
        errors.forEach((error, index) => {
          newErr += `${error}${index !== errors.length - 1 ? ', ' : ''}`;
        });
        errorsArray.push(newErr);
        setPasswordErrorMessages(errorsArray);
      }
      if (typeof errors === 'object') {
        newErr = 'Passwords must adhere to the following rules: ';
        if (Object.keys(errors).includes('new_password')) {
          const objLength = Object.keys(errors['new_password']).length - 1;
          Object.values(errors['new_password']).forEach((e, index) => {
            newErr += `${e}${index !== objLength ? ', ' : ''}`;
          });
          errorsArray.push(newErr);
          setPasswordErrorMessages(errorsArray);
        }
      }
    }
  };

  useEffect(() => {
    if (authFactors) {
      setShowDisableAuthMethodModal(false);
      const emailAuthFactor = authFactors.find(
        (factor) => factor.factor_type === 'email'
      );
      const emptyEmail = authFactorOptions.find(
        (factor) => factor.type === 'email'
      );
      const textAuthFactor = authFactors.find(
        (factor) => factor.factor_type === 'sms'
      );
      const emptyText = authFactorOptions.find(
        (factor) => factor.type === 'phone'
      );

      setAuthMethodOptions(
        authMethodOptions.map((method) => {
          if (method.type === 'email') {
            if (emailAuthFactor) {
              method = { ...method, ...emailAuthFactor, is_enabled: true };
            } else {
              method = emptyEmail;
            }
          }
          if (method.type === 'phone') {
            if (textAuthFactor) {
              method = { ...method, ...textAuthFactor, is_enabled: true };
            } else {
              method = emptyText;
            }
          }
          return method;
        })
      );
      if (!emailAuthFactor && !textAuthFactor) {
        dispatch(getAuthDevices());
      }
    }
  }, [authFactors]);

  const determineIcon = (label) => {
    if (label) {
      label = label.toLowerCase();
    } else {
      label = '';
    }
    if (label.includes('chrome')) {
      return chrome;
    }
    if (label.includes('safari')) {
      return safari;
    }
    if (label.includes('firefox')) {
      return firefox;
    }
    if (label.includes('edge')) {
      return edge;
    }
    if (label.includes('explorer')) {
      return internetExplorer;
    }
    if (label.includes('opera')) {
      return opera;
    }
    return globe;
  };

  useEffect(() => {
    if (authDevices) {
      setShowClearDeviceModal(false);
      setAuthDeviceList(
        authDevices.map((item) => {
          const { device, os, user_agent } = item?.device;
          let browserLabel = 'Unknown Browser';
          let deviceLabel = '';
          let deviceName = 'Unknown Device';
          let deviceLocation = 'Unknown Location';
          let operatingSystem = 'Unknown OS';
          let version = '';
          if (user_agent) {
            item.browser = user_agent?.family;
            item.icon = determineIcon(item.browser);
            let version = '';
            if (user_agent?.major) {
              if (user_agent?.minor) {
                version = `${user_agent?.major}.${user_agent?.minor}`;
              } else {
                version = user_agent?.major;
              }
            }
            browserLabel = `${user_agent?.family} ${version}`;
          }
          if (os) {
            if (os?.major) {
              if (os?.minor) {
                version = `${os?.major}.${os?.minor}`;
              } else {
                version = os?.major;
              }
            }
            if (os?.family) {
              operatingSystem = `${os.family} ${version}`;
            }
          }
          if (device) {
            if (device?.family) {
              deviceName = device.family;
            }
          }
          deviceLabel = `${operatingSystem}/${deviceName}`;
          if (item?.location) {
            const { ipaddress } = item.location;
            const hasIp = ipaddress !== 'None';
            if (hasIp) {
              deviceLocation = `${ipaddress}`;
            } else {
              deviceLocation = 'Unknown';
            }
          }
          item.browserLabel = browserLabel;
          item.deviceLabel = deviceLabel;
          item.location = deviceLocation;
          return item;
        })
      );
    }
  }, [authDevices]);

  useEffect(() => {
    if (authFactorAdded && !showSetupMFA) {
      setShowAuthFactorModal(true);
      setAuthScreenValue('code');
      dispatch(resetAuthFactors());
    }
  }, [authFactorAdded]);

  useEffect(() => {
    if (authFactorReturned && !showSetupMFA) {
      let matched = {};
      if (authFactorReturned?.factor_type === 'email') {
        matched = authMethodOptions.find((method) => method.type === 'email');
      }
      if (authFactorReturned?.factor_type === 'sms') {
        matched = authMethodOptions.find((method) => method.type === 'phone');
      }
      setSelectedAuthMethod({
        ...authFactorReturned,
        ...matched,
      });
      dispatch(resetAuthFactors());
    }
  }, [authFactorReturned]);

  useEffect(() => {
    if (authCodeEnabled && !showSetupMFA) {
      setShowAuthFactorModal(false);
      dispatch(resetAuthFactors());
      dispatch(getUserInfo());
    }
  }, [authCodeEnabled, authCodeDisabled]);

  useEffect(() => {
    if (authCodeDisabled && !showSetupMFA) {
      setShowAuthFactorModal(false);
    }
  }, [authCodeDisabled]);

  useEffect(() => {
    if (showCodeSentScreen && !showSetupMFA) {
      setAuthScreenValue('resent');
      dispatch(resetAuthFactors());
    }
  }, [showCodeSentScreen]);

  useEffect(() => {
    if (passwordResetError) {
      return createErrorMessage(passwordResetError);
    }
  }, [passwordResetError]);

  useEffect(() => {
    if (customSettingsError && customSettingsError.hasOwnProperty('data')) {
      let errors = [];
      for (const [key, value] of Object.entries(customSettingsError.data)) {
        errors.push({
          field: key,
          message: value,
        });
      }
      setErrorFields(errors);
    }
  }, [customSettingsError]);

  useEffect(() => {
    if (passwordUpdated) {
      setPassword({
        password: '',
        password1: '',
        password2: '',
      });
      dispatch(resetPasswordFields());
    }
  }, [passwordUpdated]);

  const hasError = (field) => {
    if (errorFields && errorFields.length >= 1) {
      return errorFields.includes(field);
    } else {
      return false;
    }
  };

  const passwordInputs = [
    {
      name: 'password',
      id: 'password',
      placeholder: 'Current Password',
      type: 'password',
      width: '100%',
      // withIcon: true,
      // iconSrc: eye,
      hasError: hasError('password'),
    },
    {
      name: 'password1',
      placeholder: 'New Password',
      id: 'new_password_1',
      type: 'password',
      width: '50%',
      // withIcon: true,
      // iconSrc: eye,
      hasError: hasError('password1'),
      // lastpassIgnore: true,
    },
    {
      name: 'password2',
      placeholder: 'Retype Password',
      type: 'password',
      id: 'new_password_2',
      width: '50%',
      // withIcon: true,
      // iconSrc: eye,
      hasError: hasError('password2'),
      // lastpassIgnore: true,
    },
  ];

  const updatePassword = (e) => {
    setPassword({
      ...password,
      [e.currentTarget.name]: e.currentTarget.value,
    });
    setErrorFields([]);
    setPasswordErrorMessages([]);
  };

  const validateUserPassword = () => {
    let passwordErrors;
    let fields = [
      {
        field: 'password',
        value: password.password,
      },
      {
        field: 'password1',
        value: password.password1,
      },
      {
        field: 'password2',
        value: password.password2,
      },
    ];

    let checkFields = fields.map((field) => {
      return CheckHasValue(field);
    });
    passwordErrors = checkFields.filter((field) => {
      return field.error;
    });
    // 1. Check that all fields are filled out
    setErrorFields(passwordErrors);
    if (passwordErrors && passwordErrors.length >= 1) {
      return setPasswordErrorMessages(['Missing Required Fields']);
    }

    // 2. Check that all fields meet password requirements
    // 3. Check that new password fields match
    if (password.password1 !== password.password2) {
      passwordErrors = [{ field: 'password1' }, { field: 'password2' }];
      //Need to set error text to passwords don't match
      setErrorFields(passwordErrors);
      return setPasswordErrorMessages(['Password fields must match']);
    }

    if (password.password === password.password1) {
      passwordErrors = [
        { field: 'password' },
        { field: 'password1' },
        { field: 'password2' },
      ];
      setErrorFields(passwordErrors);
      return setPasswordErrorMessages([
        'New password cannot be same as current',
      ]);
      //Need to set error text to passwords don't match
    }

    if (passwordErrors.length === 0) {
      let obj = {
        old_password: password.password,
        new_password: password.password1,
        new_password2: password.password2,
      };
      dispatch(updateExistingUserPassword(obj));
    }
  };

  const linkClick = (option) => {
    setSelectedAuthMethod(option);
    if (option.is_enabled && option.is_confirmed) {
      setShowAuthFactorModal(true);
      setAuthScreenValue('edit');
    } else if (option.is_enabled) {
      setShowAuthFactorModal(true);
      setAuthScreenValue('resend');
    } else {
      return window.open(
        'https://usersupport.asset-map.com/en/articles/5174071-multi-factor-authentication'
      );
    }
  };

  const removeAuthMethod = (option, isWrong = false) => {
    setSelectedAuthMethod(option);
    setShowDisableAuthMethodModal(true);
    setIsDeleteMethod(isWrong);
  };

  const updateAuthModal = (option) => {
    setSelectedAuthMethod(option);
    if (option.is_enabled && option.is_confirmed) {
      setShowDisableAuthMethodModal(true);
      setIsDeleteMethod(false);
    }
    if (option.is_enabled && !option.is_confirmed) {
      setShowAuthFactorModal(true);
      setAuthScreenValue('code');
    }
    if (!option.is_enabled && !option.is_confirmed) {
      setShowAuthFactorModal(true);
      setAuthScreenValue('add');
    }
    // setSelectedDevice(device);
  };

  const clearDeviceModal = (device) => {
    setSelectedDevice(device);
    setShowClearDeviceModal(true);
  };

  // const clearAllDevicesModal = () => {
  //   setSelectedDevice('all');
  //   setShowClearDeviceModal(true);
  // };

  const clearDevice = () => {
    if (selectedDevice === 'all') {
      setAuthDeviceList([]);
    } else {
      dispatch(removeAuthDevice(selectedDevice.id));
    }
  };

  const generateDisableAuthMethodContent = () => {
    if (showDisableAuthMethodModal) {
      if (isDeleteMethod) {
        return {
          heading: `Wrong ${selectedAuthMethod.deleteLabel}?`,
          text: `<span style="font-size:14px;">If you are unable to receive an authentication code at the ${selectedAuthMethod.deleteLabel} provided, you must delete this entry and start again.</span><br/><br/><span style="font-size:14px;font-weight:600;">Do you want to delete this ${selectedAuthMethod.deleteLabel}?</span>`,
          // text: `<span style="font-size:14px;font-weight:600;">Are you sure you want to disable ${selectedAuthMethod.displayValue} multi-factor authentication?</span><span style="font-size:12px;display:block;margin:15px 0 20px 0">You will no longer be asked to verify your account when you login. This will make you account less secure.<span>`,
          buttons: [
            {
              text: 'Cancel',
              function: () => setShowDisableAuthMethodModal(false),
              theme: ButtonThemes.cancel,
            },
            {
              text: 'Yes, Delete',
              function: () =>
                dispatch(
                  disableAuthFactor(
                    selectedAuthMethod.id,
                    selectedAuthMethod.label
                  )
                ),
              theme: ButtonThemes.primary,
            },
          ],
        };
      } else {
        return {
          heading: `Disable Authentication Factor`,
          text: `<span style="font-size:14px;font-weight:600;">Are you sure you want to disable ${selectedAuthMethod.displayValue} multi-factor authentication?</span>`,
          // text: `<span style="font-size:14px;font-weight:600;">Are you sure you want to disable ${selectedAuthMethod.displayValue} multi-factor authentication?</span><span style="font-size:12px;display:block;margin:15px 0 20px 0">You will no longer be asked to verify your account when you login. This will make you account less secure.<span>`,
          buttons: [
            {
              text: 'Cancel',
              function: () => setShowDisableAuthMethodModal(false),
              theme: ButtonThemes.cancel,
            },
            {
              text: 'Yes, Disable',
              function: () =>
                dispatch(
                  disableAuthFactor(
                    selectedAuthMethod.id,
                    selectedAuthMethod.label
                  )
                ),
              theme: ButtonThemes.primary,
              // showLoading: isDeleting,
              // loadingText: 'Deleting',
            },
          ],
        };
      }
    }
  };

  const generateClearDeviceModalContent = () => {
    if (showClearDeviceModal) {
      let heading = 'Clear Device';
      let text = `You will be asked prompted to enter a new authentication code the next time you login using this device`;
      if (selectedDevice === 'all') {
        heading = 'Clear All Device';
        text =
          'You will be asked prompted to enter a new authentication code the next time you login to any device.';
      }
      return {
        heading: heading,
        text: text,
        buttons: [
          {
            text: 'Cancel',
            function: () => setShowClearDeviceModal(false),
            theme: ButtonThemes.cancel,
          },
          {
            text: 'Yes, Clear',
            function: () => clearDevice(),
            theme: ButtonThemes.primary,
            // showLoading: isDeleting,
            // loadingText: 'Deleting',
          },
        ],
      };
    }
  };

  const closeAuthFactorModal = () => {
    setShowAuthFactorModal(false);
    dispatch(resetAuthFactors());
  };

  return (
    <>
      {showClearDeviceModal && (
        <SimpleModal
          hide={() => setShowClearDeviceModal(false)}
          content={generateClearDeviceModalContent()}
        />
      )}
      {showDisableAuthMethodModal && (
        <SimpleModal
          hide={() => setShowDisableAuthMethodModal(false)}
          content={generateDisableAuthMethodContent()}
        />
      )}
      {showAuthFactorModal && (
        <AuthFactorModal
          hide={() => closeAuthFactorModal()}
          screen={authScreenValue}
          type={selectedAuthMethod?.type}
          selectedOption={selectedAuthMethod}
          setScreenValue={setAuthScreenValue}
          removeAuthMethod={removeAuthMethod}
        />
      )}
      <ContainerLayout>
        <LeftContent>
          {showMFA ? (
            <>
              <SecondaryHeading margin="0 0 30px 0">
                Multi-factor Authentication
              </SecondaryHeading>
              <FormHeader>Factors</FormHeader>
              <AuthMethodList>
                {authMethodOptions.map((option, index) => {
                  return (
                    <AuthFactorOption
                      key={index}
                      option={option}
                      updateAuthModal={updateAuthModal}
                      linkClick={linkClick}
                    />
                  );
                })}
              </AuthMethodList>
              <FormHeader>
                Trusted Devices
                {/* <p onClick={() => clearAllDevicesModal()}>Clear All</p> */}
              </FormHeader>
              <AuthDeviceList>
                {authDeviceList.length !== 0 ? (
                  authDeviceList.map((device, index) => {
                    return (
                      <AuthDevice
                        key={index}
                        device={device}
                        clearDevice={clearDeviceModal}
                      />
                    );
                  })
                ) : (
                  <NoDevicesContainer>
                    <img
                      src={passwordProtected}
                      alt="No Devices"
                      data-image="no-devices"
                    />
                    <p>No Devices have been authorized.</p>
                  </NoDevicesContainer>
                )}
              </AuthDeviceList>
            </>
          ) : !isSSO ? (
            <>
              <SecondaryHeading margin="0 0 30px 0">Password</SecondaryHeading>
              <FormChunk
                inputs={passwordInputs}
                value={password}
                onChange={updatePassword}
                button={{
                  text: 'Update',
                  onClick: validateUserPassword,
                  value: 'password',
                }}
                borderBottom={false}
                errorFields={errorFields}
                hasErrorDisplay={true}
                errorMessages={passwordErrorMessages}
              />
            </>
          ) : null}
        </LeftContent>
        <RightContent>
          {!isSSO && showMFA && (
            <>
              <SecondaryHeading margin="0 0 30px 0">Password</SecondaryHeading>
              <FormChunk
                inputs={passwordInputs}
                value={password}
                onChange={updatePassword}
                button={{
                  text: 'Update',
                  onClick: validateUserPassword,
                  value: 'password',
                }}
                borderBottom={false}
                errorFields={errorFields}
                hasErrorDisplay={true}
                errorMessages={passwordErrorMessages}
              />
            </>
          )}
        </RightContent>
      </ContainerLayout>
    </>
  );
};

const NoDevicesContainer = styled.div`
  background: white;
  height: 300px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${colors.hoverLightGrey};
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 250px;
  }
  p {
    margin-top: 15px;
    text-align: center;
    color: ${colors.paleGrey};
    font-weight: ${fonts.semiBold};
  }
`;

const AuthMethodList = styled.div`
  margin-bottom: 50px;
`;

const AuthDeviceList = styled.div``;

const ContainerLayout = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  margin: -25px 0px 0px 0px;
  background: ${colors.lighterGrey};
`;

const LeftContent = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
    flex: 1 1 auto;
  }
  /* @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }*/
  @media ${maxDevice.sideNav} {
    padding: 20px 20px 100px 20px;
    overflow-x: hidden;
    min-height: 500px;
  }
  flex: 1 1 40%;
  min-height: 800px;
  /* max-width: 50%;
  min-width: 50%; */
  background: white;
  padding: 30px 40px 100px 40px;
`;

const FormContainer = styled.div`
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  span {
    flex: 1 1 auto;
  }
  p {
    flex: 0 0 auto;
    font-size: 11px;
    color: ${colors.paleGrey};
    cursor: pointer;
  }
`;

const RightContent = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
    flex: 1 1 auto;
  }
  /* @media ${maxDevice.laptop} {
    max-width: 50%;
  } */
  /* @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  } */
  @media ${maxDevice.sideNav} {
    padding: 20px;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  flex: 1 1 30%;
  /* max-width: 50%; */
  padding: 30px 40px 20px 40px;
  /* position: relative; */
`;

export default SecurityPreferencesContainer;
