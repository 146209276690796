import styled from 'styled-components';
import PropTypes from 'prop-types';
import { maxDevice } from '../styles/variables';
import { FooterContainer } from '../styles/library/layoutStyles';

const PageFooter = ({ footerValue, isAMR = false }) => {
  return isAMR ? (
    <AMRFooterContainer
      dangerouslySetInnerHTML={{
        __html: footerValue,
      }}
    />
  ) : (
    <FooterContainer
      dangerouslySetInnerHTML={{
        __html: footerValue,
      }}
    />
  );
};

const AMRFooterContainer = styled(FooterContainer)`
  @media ${maxDevice.laptopAlt} {
    margin-bottom: 120px;
  }
  @media ${maxDevice.tablet} {
    margin-bottom: 130px;
  }
  @media ${maxDevice.mobileL} {
    margin-bottom: 180px;
  }
  margin-bottom: 80px;
`;

PageFooter.propTypes = {
  footerValue: PropTypes.string,
  isAMR: PropTypes.bool,
};

export default PageFooter;
