/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import {
  Button,
  LoadingAnimation,
  Select,
  TargetMapCompare,
} from '../../components';
import {
  getTargetMaps,
  toggleShowNewTargetMapModal,
  updateTargetMapStoreValue,
} from '../../store/actions';
import {
  arrowRightDark,
  pdf,
  // plus
} from '../../assets';
import {
  assetMapColors,
  // boxShadows,
  colors,
  fonts,
  maxDevice,
} from '../../styles/variables';
import { SecondaryHeading } from '../../styles/library/fontStyles';
import { FlexCenterStart } from '../../styles/library/layoutStyles';
import { IconContainer } from '../../styles/library/imageStyles';
import { SelectContainer } from '../../styles/library/inputStyles';
import {
  UpdatePageTitle,
  HtmlToString,
  DetermineTargetMapColor,
  TargetMapOption,
  SendTrackingEvent,
  DownloadReport,
  ErrorNotification,
} from '../../utils';

const HouseholdTargetMapsCompare = () => {
  UpdatePageTitle('Target-Maps Comparison');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const householdId = params.id;
  const {
    currentHousehold,
    targetMaps,
    editedTargetMap,
    clonedTargetMap,
    clonedTargetMapId,
    createdTargetMap,
    createdTargetMapId,
    loadedTargetMaps,
    targetMapEditError,
    targetMapEditErrorId,
    invocationId,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    targetMaps: state.targetMaps.targetMaps,
    editedTargetMap: state.targetMaps.editedTargetMap,
    clonedTargetMap: state.targetMaps.clonedTargetMap,
    clonedTargetMapId: state.targetMaps.clonedTargetMapId,
    createdTargetMap: state.targetMaps.createdTargetMap,
    createdTargetMapId: state.targetMaps.createdTargetMapId,
    loadedTargetMaps: state.targetMaps.loadedTargetMaps,
    targetMapEditError: state.targetMaps.targetMapEditError,
    targetMapEditErrorId: state.targetMaps.targetMapEditErrorId,
    invocationId: state.configs.invocationId,
  }));
  const [paramIds, setParamIds] = useState([]);
  const [selectedTargetMaps, setSelectedTargetMaps] = useState([]);
  const [selectedTargetMap, setSelectedTargetMap] = useState();
  const [targetMapList, setTargetMapList] = useState([]);
  const defaultError = {
    name: false,
    duration: false,
    return_rate: false,
    inflation_rate: false,
  };
  const [targetMapError, setTargetMapError] = useState(defaultError);

  useEffect(() => {
    if (editedTargetMap) {
      dispatch(getTargetMaps(householdId));
    }
  }, [editedTargetMap]);

  useEffect(() => {
    if (clonedTargetMap && clonedTargetMapId) {
      const targetMapParams = [...paramIds, clonedTargetMapId].reduce(
        (acc, curr) => {
          return acc + `/${curr}`;
        },
        ''
      );
      SendTrackingEvent(invocationId, 'action', 'targetmap_clone', null, {
        targetmap: clonedTargetMapId,
        in_compare: true,
      });
      return navigate(
        `/household/${householdId}/targetmap/compare${targetMapParams}`
      );
    }
  }, [clonedTargetMap, targetMaps, clonedTargetMapId]);

  useEffect(() => {
    if (createdTargetMap && createdTargetMapId) {
      const targetMapParams = [...paramIds, createdTargetMapId].reduce(
        (acc, curr) => {
          return acc + `/${curr}`;
        },
        ''
      );
      SendTrackingEvent(invocationId, 'action', 'targetmap_create', null, {
        targetmap: createdTargetMapId,
        in_compare: true,
      });
      return navigate(
        `/household/${householdId}/targetmap/compare${targetMapParams}`
      );
    }
  }, [createdTargetMap, targetMaps, createdTargetMapId]);

  useEffect(() => {
    if (
      params &&
      targetMaps &&
      !clonedTargetMap &&
      !editedTargetMap &&
      !createdTargetMap
    ) {
      const paramIds = [params?.tm1, params?.tm2, params?.tm3].filter(
        (id) => id
      );
      setParamIds(paramIds);

      const updatedTargetMapsList = targetMaps.map((targetmap) => {
        const tm = { ...targetmap };
        targetmap.name = HtmlToString(targetmap.name);
        targetmap.color =
          targetmap.color || DetermineTargetMapColor(targetmap.template);
        tm.label = targetmap.name;
        tm.value = targetmap.id;
        return tm;
      });
      const availableTargetMaps = updatedTargetMapsList.filter((tm) => {
        return !paramIds.includes(tm.id);
      });
      setTargetMapList(availableTargetMaps);

      const paramTargetMaps = paramIds.map((param) => {
        const targetmap = updatedTargetMapsList.find((tm) => tm.id === param);
        return targetmap;
      });
      setSelectedTargetMaps(paramTargetMaps);
    }
  }, [targetMaps, loadedTargetMaps, params]);

  useEffect(() => {
    if (targetMapEditError) {
      setTargetMapError(targetMapEditError.data);
      dispatch(updateTargetMapStoreValue('targetMapEditError', null));
    }
  }, [targetMapEditError]);

  const removeTargetMap = (tm) => {
    const targetMapParams = paramIds.reduce((acc, curr) => {
      if (curr !== tm) {
        return acc + `/${curr}`;
      }
      return acc;
    }, '');
    SendTrackingEvent(invocationId, 'action', 'targetmap_remove', null, {
      targetmap: tm,
      in_compare: true,
    });
    return navigate(
      `/household/${householdId}/targetmap/compare${targetMapParams}`
    );
  };

  const onSelectTargetMap = (e) => {
    setSelectedTargetMap(null);
    const targetMapParams = [...paramIds, e.id].reduce((acc, curr) => {
      return acc + `/${curr}`;
    }, '');
    SendTrackingEvent(invocationId, 'action', 'targetmap_add', null, {
      targetmap: e.id,
      in_compare: true,
    });
    return navigate(
      `/household/${householdId}/targetmap/compare${targetMapParams}`
    );
  };

  const generateCompareReport = () => {
    if (selectedTargetMaps.length >= 1) {
      const children = selectedTargetMaps.map((tm) => {
        return {
          selected: true,
          id: tm.id,
        };
      });
      const summaryMap = {
        label: 'Target-Map Summary',
        value: 'summary',
        selected: true,
        pages: 1,
        noCashflow: true,
      };
      DownloadReport({
        value: 'targetmap',
        type: 'targetmap_compare',
        childValue: 'targetmaps',
        hasOptions: true,
        children: [summaryMap, ...children],
      });
    } else {
      ErrorNotification('No Target-Maps Selected');
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const updatedList = reorder(
      selectedTargetMaps,
      result.source.index,
      result.destination.index
    );
    setSelectedTargetMaps(updatedList);
    const targetMapParams = updatedList.reduce((acc, curr) => {
      return acc + `/${curr.id}`;
    }, '');
    return navigate(
      `/household/${householdId}/targetmap/compare${targetMapParams}`
    );
  };

  return (
    <PageContainer>
      <HeaderContent>
        <LeftContent>
          <BackArrowLink to={`/household/${householdId}/targetmap`}>
            <BackArrow
              src={arrowRightDark}
              alt="back"
              data-image="back-arrow"
            />
          </BackArrowLink>
          <SecondaryHeading margin="0">
            Target-Map<sup>™</sup> Comparison
          </SecondaryHeading>
        </LeftContent>
        <RightContent>
          {selectedTargetMaps.length >= 1 && (
            <IconContainer onClick={generateCompareReport}>
              <img src={pdf} alt="PDF" data-image="heading-pdf" />
            </IconContainer>
          )}
        </RightContent>
      </HeaderContent>
      {!loadedTargetMaps ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <PageContent>
          <TargetMapCompareContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list" direction="horizontal">
                {(provided) => (
                  <DragContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {selectedTargetMaps.map((targetMap, index) => (
                      <TargetMapCompare
                        key={targetMap.id}
                        index={index}
                        tm={targetMap}
                        currentHousehold={currentHousehold}
                        householdId={householdId}
                        removeTargetMap={removeTargetMap}
                        showClone={selectedTargetMaps.length < 3}
                        error={
                          targetMapEditErrorId === targetMap.id
                            ? targetMapError
                            : defaultError
                        }
                      />
                    ))}
                    {provided.placeholder}
                    {selectedTargetMaps.length < 3 && (
                      <AddTargetMapContainer key="add">
                        <SelectContainer margin="0" width="70%">
                          <Select
                            options={targetMapList}
                            components={{ Option: TargetMapOption }}
                            selectedOption={selectedTargetMap}
                            onChange={onSelectTargetMap}
                            isSearchable={true}
                            placeholder={'Select Target-Map'}
                            autoFocus={false}
                            isSmaller={true}
                          />
                        </SelectContainer>
                        <MiddleText>OR</MiddleText>
                        <Button
                          text="New Target-Map"
                          // theme={ButtonThemes.inactiveBorder}
                          onClick={() =>
                            dispatch(
                              toggleShowNewTargetMapModal(true, null, false)
                            )
                          }
                        />
                        {/* <PlusIconContainer>
                  <PlusIcon src={plus} alt="add" />
                  <MenuContainer>
                    <p>Create Target-Map</p>
                    <p>Select Existing Target-Map</p>
                  </MenuContainer>
                </PlusIconContainer> */}
                      </AddTargetMapContainer>
                    )}
                  </DragContainer>
                )}
              </Droppable>
            </DragDropContext>
          </TargetMapCompareContainer>
        </PageContent>
      )}
    </PageContainer>
  );
};

const MiddleText = styled.p`
  color: ${colors.paleGrey};
  font-size: 15px;
  margin: 20px 0;
  font-weight: ${fonts.semiBold};
`;

// const PlusIconContainer = styled.div`
//   ${FlexCenterAll};
//   width: 100px;
//   height: 100px;
//   border: 1px solid ${colors.paleGrey};
//   border-radius: 100%;
//   background: ${colors.lightGrey};
//   cursor: pointer;
//   position: relative;
//   &:hover {
//     opacity: 0.9;
//     transform: scale(1.01);
//   }
// `;

// const PlusIcon = styled.img`
//   width: 50px;
//   height: 50px;
//   opacity: 0.5;
// `;

// const MenuContainer = styled.div`
//   width: 180px;
//   height: 100px;
//   position: absolute;
//   top: 110px;
//   background: ${colors.white};
//   z-index: 99;
//   border-radius: 5px 5px;
//   font-size: 11px;
//   box-shadow: ${boxShadows.boxShadow};
//   padding: 10px;
//   p {
//     font-size: 14px;
//   }
// `;

const DragContainer = styled.div`
  width: 100%;
  ${FlexCenterStart};
  gap: 2%;
  align-items: stretch;
  padding-bottom: 20px;
`;

const TargetMapCompareContainer = styled.div`
  /* ${FlexCenterStart};
  gap: 2%;
  align-items: stretch;
  overflow-y: scroll;
  padding-bottom: 20px;
  flex-wrap: wrap; */
`;

const AddTargetMapContainer = styled.div`
  /* border: 1px solid ${colors.paleGrey}; */
  border: 1px solid ${assetMapColors.stencil};
  /* background: ${colors.white}; */
  background: ${colors.lighterGrey};
  border-radius: 6px;
  max-width: 34%;
  min-width: 300px;
  flex: 1 1 33%;
  display: flex;
  min-height: 538.5px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 20px 20px;
  }
  margin: 25px 40px;
`;

const HeaderContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 20px 20px;
  }
  margin: 25px 40px;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
`;

const LeftContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  ${SecondaryHeading} {
    @media ${maxDevice.mobileL} {
      font-size: 13px;
    }
    svg {
      margin-right: 10px;
    }
  }
`;

const RightContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
`;

const BackArrowLink = styled(Link)`
  @media ${maxDevice.sideNav} {
    margin-left: 0px;
  }
  margin-right: 20px;
  margin-left: -15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    img {
      opacity: 0.7;
    }
  }
`;

const BackArrow = styled.img`
  height: 15px;
  width: 15px;
  transform: rotate(180deg);
`;

const LoadingContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export default HouseholdTargetMapsCompare;
