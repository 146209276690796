import { apiAction } from './';

export const ConfigTypes = {
  GET_CONFIGS: 'GET_CONFIGS',
  GET_SYSTEM_FIELDS: 'GET_SYSTEM_FIELDS',
  GET_USER_FIELDS: 'GET_USER_FIELDS',
  GET_HOUSEHOLD_FIELDS: 'GET_HOUSEHOLD_FIELDS',
  GET_GLOBAL_CONFIGS: 'GET_GLOBAL_CONFIGS',
  GET_SYSTEM_VERSION: 'GET_SYSTEM_VERSION',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_INVOCATION_ID: 'SET_INVOCATION_ID',
};

//ACTION TO SET CONFIG VALUES IN STORE <object>
export const setConfigs = (configs) => ({
  type: ConfigTypes.GET_CONFIGS,
  payload: configs,
});

//API CALL TO GET SYSTEM CONFIG VALUES
export const getConfigs = () => {
  return apiAction({
    path: `/system/configs`,
    method: 'GET',
    onSuccess: setConfigs,
    label: ConfigTypes.GET_CONFIGS,
  });
};

//ACTION TO SET SYSTEM FIELDS IN STORE <object>
export const setSystemFields = (fields) => ({
  type: ConfigTypes.GET_SYSTEM_FIELDS,
  payload: fields,
});

//API CALL TO GET SYSTEM FIELDS BY LOCALE
export const getSystemFields = (locale) => {
  return apiAction({
    path: `/system/fields?locale=${locale}`,
    method: 'GET',
    onSuccess: setSystemFields,
    label: ConfigTypes.GET_SYSTEM_FIELDS,
  });
};

//ACTION TO SET USER FIELDS IN STORE <object>
export const setUserFields = (fields) => ({
  type: ConfigTypes.GET_USER_FIELDS,
  payload: fields,
});

//API CALL TO GET USER FIELDS BY LOCALE
export const getUserFields = (locale) => {
  return apiAction({
    path: `/system/fields?locale=${locale}`,
    method: 'GET',
    onSuccess: setUserFields,
    label: ConfigTypes.GET_USER_FIELDS,
  });
};

//ACTION TO SET HOUSEHOLD FIELDS IN STORE <object>
export const setHouseholdFields = (fields) => ({
  type: ConfigTypes.GET_HOUSEHOLD_FIELDS,
  payload: fields,
});

//API CALL TO GET HOUSEHOLD FIELDS BY HOUSEHOLD ID
export const getHouseholdFieldsOld = (householdId) => {
  return apiAction({
    path: `/system/fields?household=${householdId}`,
    method: 'GET',
    onSuccess: setHouseholdFields,
    label: ConfigTypes.GET_HOUSEHOLD_FIELDS,
  });
};

//API CALL TO GET HOUSEHOLD FIELDS BY HOUSEHOLD ID
export const getHouseholdFields = (householdId) => {
  return apiAction({
    path: `/household/${householdId}/configs`,
    method: 'GET',
    onSuccess: setHouseholdFields,
    label: ConfigTypes.GET_HOUSEHOLD_FIELDS,
  });
};

//ACTION TO SET GLOBAL CONFIG VALUES IN STORE
export const setGlobalConfigs = (configs) => ({
  type: ConfigTypes.GET_GLOBAL_CONFIGS,
  payload: configs,
});

/*
  API CALL TO GET GLOBAL CONFIGS - DON'T NEED TO BE LOGGED IN
  - path <string>: url pathname to let BE know where user is making request from (consumer/ampux)
*/
export const getGlobalConfigs = (path = '') => {
  return apiAction({
    path: `/system/global?path=${path}`,
    method: 'GET',
    onSuccess: setGlobalConfigs,
    label: ConfigTypes.GET_GLOBAL_CONFIGS,
  });
};

//ACTION TO SET SYSTEM VERSION IN STORE <object>
export const setSystemVersion = (version) => ({
  type: ConfigTypes.GET_SYSTEM_VERSION,
  payload: version,
});

//API CALL TO GET SYSTEM VERSION
export const getSystemVersion = () => {
  return apiAction({
    path: `/system/version`,
    method: 'GET',
    onSuccess: setSystemVersion,
    label: ConfigTypes.GET_SYSTEM_VERSION,
  });
};

//ACTION TO SET CURRENCY OBJECT IN STORE <object>
export const setCurrencyObj = (currency) => ({
  type: ConfigTypes.SET_CURRENCY,
  payload: currency,
});

//ACTION TO SET INVOCATION ID IN STORE <string>
export const setInvocationId = (invocationId) => ({
  type: ConfigTypes.SET_INVOCATION_ID,
  payload: invocationId,
});
