/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getQuicksightUrl } from '../../store/actions';
import styled from 'styled-components';
import { FlexCenterAll } from '../../styles/library/layoutStyles';
import { heights } from '../../styles/variables';

const QuicksightEmbedding = require('amazon-quicksight-embedding-sdk');

const ManagerAtlasContainer = () => {
  const dispatch = useDispatch();
  const { quicksightUrl } = useSelector((state) => ({
    quicksightUrl: state.user.quicksightUrl,
  }));
  const [qsUrlObj, setQsUrlObj] = useState({});
  const [qsUrl, setQsUrl] = useState('');
  const [options, setOptions] = useState({});
  const [optionsCheck, setOptionsCheck] = useState(false);
  const [dashboard, setDashboard] = useState({});

  useEffect(() => {
    dispatch(getQuicksightUrl());
  }, []);

  useEffect(() => {
    if (quicksightUrl) {
      setQsUrlObj(quicksightUrl);
    }
  }, [quicksightUrl]);

  useEffect(() => {
    if (qsUrl) {
      let div = document.getElementById('embeddingContainer');
      setOptions({
        url: qsUrl,
        container: div,
        scrolling: 'yes',
        height: '100%',
        iframeResizeOnSheetChange: false,
        width: '100%',
        footerPaddingEnabled: true,
        sheetId: 'YOUR_SHEETID',
        sheetTabsDisabled: false,
        undoRedoDisabled: false,
        resetDisabled: false,
      });
    }
  }, [qsUrl]);

  useEffect(() => {
    if (qsUrlObj) {
      const url = qsUrlObj?.body?.EmbedUrl;
      setQsUrl(url);
    }
  }, [qsUrlObj]);

  useEffect(() => {
    if (optionsCheck) {
      if (options?.url.startsWith('http')) {
        const dashboard = QuicksightEmbedding.embedDashboard(options);
        setDashboard(dashboard);
      }
    }
  }, [optionsCheck]);

  useEffect(() => {
    if (options) {
      if (options?.url) {
        setOptionsCheck(true);
      }
    }
  }, [options]);

  useEffect(() => {
    if (dashboard) {
      console.log(dashboard);
    }
  }, [dashboard]);

  return <AtlasContainer id="embeddingContainer" />;
};

const AtlasContainer = styled.div`
  width: calc(100% - 50px);
  height: calc(100vh - ${heights.navBar} - 185px);
  margin: 0 25px;
  ${FlexCenterAll};
  overflow: hidden;
`;

export default ManagerAtlasContainer;
