/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import Snowfall from 'react-snowfall';
import { Button, FlexItem, TypeaheadSearch } from '../components';
import { getHouseholdIndex } from '../store/actions';
import {
  arrowRight,
  plusCircle,
  hintOrange,
  map,
  integrate,
  orangeChevron,
  greenChevron,
  blueChevron,
  trafficLightGreen,
} from '../assets';
import {
  BackgroundPage,
  // Page,
  CenteredContainer,
  CenterContent,
  BottomContent,
} from '../styles/library/layoutStyles';
// import { boxShadows } from '../styles/variables';
import { ButtonThemes, selectThemes } from '../styles/themes';
import {
  SUPPORT_LINK as supportLink,
  SIGNALS_LINK as signalsLink,
} from '../utils/constants';
import { HouseholdSearch, SendTrackingEvent } from '../utils';

const DashboardContainer = ({ content }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    dashboardBackground,
    invocationId,
    loadedUser,
    showDiscovery,
    showSnow,
    householdStartPage,
  } = useSelector((state) => ({
    dashboardBackground: state.configs.dashboardBackground,
    invocationId: state.configs.invocationId,
    loadedUser: state.user.loadedUser,
    showDiscovery: state.user.showDiscovery,
    showSnow: state.user.showSnow,
    householdStartPage: state.user.householdStartPage,
  }));
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState();
  const [hasBackground, setHasBackground] = useState(false);
  const [backgroundTheme, setBackgroundTheme] = useState({
    backgroundImage: null,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundAttachment: 'fixed',
    opacity: 0.9,
  });
  const [actionContainers, setActionContainers] = useState([
    {
      id: 'support',
      iconSrc: hintOrange,
      iconAlt: 'info',
      linkSrc: supportLink,
      chevronSrc: orangeChevron,
    },
    {
      id: 'signals',
      iconSrc: trafficLightGreen,
      iconAlt: 'traffic',
      linkSrc: signalsLink,
      chevronSrc: greenChevron,
    },
    {
      id: 'integrations',
      iconSrc: integrate,
      iconAlt: 'integrate',
      localLink: '/integration',
      chevronSrc: blueChevron,
    },
  ]);

  useEffect(() => {
    if (dashboardBackground && !showSnow) {
      setBackgroundTheme({
        ...backgroundTheme,
        backgroundImage: `url(${dashboardBackground})`,
      });
      setHasBackground(true);
    }
  }, [dashboardBackground]);

  useEffect(() => {
    if (showSnow) {
      setBackgroundTheme({
        ...backgroundTheme,
        backgroundImage: 'linear-gradient(#123, #111)',
      });
      setHasBackground(true);
    }
  }, [showSnow]);

  const handleChange = (value, { action }) => {
    setInputValue(value);
    if (action === 'input-change') {
      setSelectedOption(value);
      if (value.length > 1) {
        SendTrackingEvent(
          invocationId,
          'action',
          'search',
          'member_dashboard',
          {
            query: value,
          }
        );
      }
    }
    if (action === 'set-value') {
      dispatch(getHouseholdIndex('me', 2000, 0, selectedOption));
    }
  };
  const loadOptions = (input) => {
    if (input.length >= 2) {
      return HouseholdSearch(input, redirectSearch, householdStartPage);
    }
  };

  useEffect(() => {
    if (loadedUser) {
      if (showDiscovery) {
        setActionContainers([
          {
            id: 'support',
            iconSrc: hintOrange,
            iconAlt: 'info',
            linkSrc: supportLink,
            chevronSrc: orangeChevron,
          },
          {
            id: 'discovery',
            iconSrc: map,
            iconAlt: 'map',
            localLink: '/discovery',
            chevronSrc: greenChevron,
          },
          {
            id: 'integrations',
            iconSrc: integrate,
            iconAlt: 'integrate',
            localLink: '/integration',
            chevronSrc: blueChevron,
          },
        ]);
      }
    }
  }, [loadedUser, showDiscovery]);

  const generatePanels = () => {
    return actionContainers.map((container, index) => {
      let itemContent = content.lowerItems[container.id];
      return (
        <FlexItem
          content={itemContent}
          key={index}
          object={container}
          hasBackground={hasBackground}
          onClick={() => onLinkClick(container.id)}
          loading={!loadedUser}
        />
      );
    });
  };

  const redirectSearch = () => {
    if (selectedOption && selectedOption.hasOwnProperty('value')) {
      setSelectedOption();
      return navigate(
        `/household/${selectedOption.value}/${householdStartPage}`
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
      redirectSearch();
    }
  };

  const handleSelectChange = (value) => {
    if (value && value.hasOwnProperty('value')) {
      setSelectedOption(value);
      SendTrackingEvent(
        invocationId,
        'action',
        'household_select',
        'member_dashboard',
        { household: value.value }
      );
    }
  };

  const onLinkClick = (keyword) => {
    SendTrackingEvent(invocationId, 'click', keyword, 'member_dashboard');
  };

  return (
    <ThemeProvider theme={backgroundTheme}>
      {showSnow && <Snowfall />}
      <BackgroundPage>
        {/* <Page
      style={{
        backgroundImage:
          'linear-gradient(170deg, rgba(62, 177, 200, 0.1) 0%, rgba(0,76,151,.1) 100%)',
      }}
        >*/}
        <CenteredContainer>
          <CenterContent>
            <TypeaheadSearch
              showSearchIcon={true}
              cacheOptions={false}
              largeIcon={true}
              inputValue={inputValue}
              placeholder={content.actionMenu.searchBar}
              loadOptions={loadOptions}
              onSelectChange={(e) => handleSelectChange(e)}
              onChange={(e, action) => handleChange(e, action)}
              onKeyDown={(e) => onKeyDown(e)}
              styles={selectThemes.dashboard}
            />
            <Link
              to="/member/households"
              onClick={() => onLinkClick('household_index')}
            >
              <Button
                text={content.actionMenu.householdIndex}
                imageSrc={arrowRight}
                imageName="arrow"
                bigButton={true}
                theme={ButtonThemes.bigButtonSecondary}
              />
            </Link>
            <Link
              to="/household/new"
              onClick={() => onLinkClick('add_household')}
            >
              <Button
                text={content.actionMenu.addHousehold}
                imageSrc={plusCircle}
                imageName="add"
                bigButton={true}
                theme={ButtonThemes.bigButtonPrimary}
              />
            </Link>
          </CenterContent>
          <BottomContent>{generatePanels()}</BottomContent>
        </CenteredContainer>
        {/* </Page> */}
      </BackgroundPage>
    </ThemeProvider>
  );
};

DashboardContainer.propTypes = {
  content: PropTypes.object,
};

export default DashboardContainer;
