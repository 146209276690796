import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fonts } from '../styles/variables';

const AvailableSeats = ({
  available_advisors,
  available_delegates,
  subscribed_advisor_seats,
  subscribed_delegate_seats,
}) => {
  return (
    <>
      <AccountConfigContainer>
        <p>Your account has the following configuration:</p>
        <table>
          <thead>
            <tr>
              <th>Seat Type</th>
              <th>Licensed</th>
              <th>Available</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Advisor</td>
              <td>{subscribed_advisor_seats}</td>
              <td>{available_advisors}</td>
            </tr>
            <tr>
              <td>Delegate</td>
              <td>{subscribed_delegate_seats}</td>
              <td>{available_delegates}</td>
            </tr>
          </tbody>
        </table>
      </AccountConfigContainer>
      <AddSeatsText>
        Need to <strong>Add Seats</strong> or make other changes? Please contact
        support.
      </AddSeatsText>
    </>
  );
};

AvailableSeats.propTypes = {
  subscribed_advisor_seats: PropTypes.number,
  available_advisors: PropTypes.number,
  subscribed_delegate_seats: PropTypes.number,
  available_delegates: PropTypes.number,
};

const AddSeatsText = styled.p`
  margin-top: 10px;
`;

const AccountConfigContainer = styled.div`
  margin-top: 10px;
  table {
    margin: 15px auto;
    thead {
      tr {
        th {
          font-weight: ${fonts.semiBold};
          padding: 5px 10px;
        }
      }
      tbody {
        tr {
          td {
            padding: 10px;
          }
        }
      }
    }
  }
`;

export default AvailableSeats;
