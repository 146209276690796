import { DiscoveryContainer } from '../containers';
import { UpdatePageTitle, CloseDropdowns } from '../utils';

const Discovery = () => {
  UpdatePageTitle('Discovery');
  CloseDropdowns();

  return <DiscoveryContainer />;
};

export default Discovery;
