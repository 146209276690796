import { StyleContainer } from '../containers';
import { UpdatePageTitle, CloseDropdowns } from './../utils';

const StyleGuide = () => {
  UpdatePageTitle('Style Guide');
  CloseDropdowns();

  return <StyleContainer />;
};

export default StyleGuide;
