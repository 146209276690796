import { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { ChevronImg, MediumImage } from '../styles/library/imageStyles';
import {
  ActionContainer,
  LoadingContainer,
  ClickContainer,
  LeftIcon,
  RightContent,
} from '../styles/library/layoutStyles';
import PropTypes from 'prop-types';
import { LoadingPlaceholder } from '../styles/library/fontStyles';

const FlexItem = ({
  object,
  content,
  hasBackground,
  onClick,
  loading = false,
}) => {
  return (
    <ThemeProvider theme={{ hasBackground: hasBackground }}>
      <ActionContainer>
        {loading ? (
          <LoadingContainer>
            <LeftIcon>
              <LoadingPlaceholder
                display="flex"
                margin="15px 0"
                width="50px"
                height="50px"
                round="true"
              />
            </LeftIcon>
            <RightContent>
              <LoadingPlaceholder height="21.5px" width="100px" margin="0" />
              <LoadingPlaceholder
                height="38px"
                width="100%"
                margin="5px 0 10px 0"
              />
              <LoadingPlaceholder height="15px" width="125px" margin="0" />
            </RightContent>
          </LoadingContainer>
        ) : (
          <ClickContainer
            as={object.linkSrc ? 'a' : Link}
            to={object.localLink}
            href={object.linkSrc}
            target={object.linkSrc ? '_blank' : null}
            rel={object.linkSrc ? 'noopener noreferrer' : null}
            onClick={onClick}
          >
            <LeftIcon>
              {loading ? (
                <LoadingPlaceholder
                  display="flex"
                  margin="15px 0"
                  width="50px"
                  height="50px"
                  round="true"
                />
              ) : (
                <MediumImage
                  src={object.iconSrc}
                  alt={object.iconAlt}
                  data-image={object.iconAlt}
                />
              )}
            </LeftIcon>
            <RightContent>
              <h3>{content.label}</h3>
              <p style={{ marginBottom: '10px' }}>{content.text}</p>
              <span to={object.localLink} onClick={onClick}>
                {content.cta}
                <ChevronImg
                  src={object.chevronSrc}
                  alt="arrow"
                  data-image={`arrow-${object.iconAlt}`}
                />
              </span>
            </RightContent>
          </ClickContainer>
        )}
      </ActionContainer>
    </ThemeProvider>
  );
};

FlexItem.propTypes = {
  object: PropTypes.object,
  content: PropTypes.object,
  hasBackground: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default FlexItem;
