import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, inputColors, fonts } from '../../styles/variables';
import { selectThemes } from '../../styles/themes';
import { times, search } from '../../assets';
import { Select, DatePickerInput, TypeaheadSearch } from '../../components';

const Filter = ({ data, type }) => {
  // TODO figure out what is happening here and why it returns no props every once and a while.
  // if (!data) {
  //   return null;
  // }
  // filtering components
  const selectFilter = (filter) => {
    return (
      <Select
        onChange={(e) => filter.onChangeCallback(e, filter.key)}
        options={filter.options}
        components={filter.components}
        isSearchable={filter.isSearchable}
        placeholder={filter.placeholder}
        autoFocus={false}
        selectedOption={filter.selectedOption}
        closeMenuOnSelect={filter.closeMenuOnSelect}
        styles={selectThemes.inputSearch}
      />
    );
  };

  const datePickerFilter = (filter) => {
    return (
      <DatePickerInput
        value={filter.value}
        onChange={(e) => filter.onChangeCallback(e, filter.key)}
        placeholder={filter.placeholder}
        maxDate={filter.maxDate}
        afterYearRange={filter.afterYearRange}
        beforeYearRange={filter.beforeYearRange}
        hasError={filter.hasError}
        justUpdated={filter.justUpdated}
        dateFormat={filter.dateFormat}
      />
    );
  };

  const searchFilter = (filter) => {
    return (
      <SearchContainer>
        <SearchBoxContainer>
          <SearchBox
            type={filter.type}
            placeholder={filter.placeholder}
            value={filter.value}
            onChange={(e) => filter.onChangeCallback(e)}
            onKeyDown={(e) => filter.onKeyDownCallback(e)}
          />
          {filter.selectedOption && (
            <ClearIcon
              src={times}
              alt="clear"
              onClick={filter.onClearCallback}
              data-image={`filter-clear`}
            />
          )}
        </SearchBoxContainer>
        <SearchButton onClick={filter.onSearchCallback}>
          <img src={search} alt="search" data-image={`filter-search`} />
        </SearchButton>
      </SearchContainer>
    );
  };

  const typeaheadSearch = (filter) => {
    return (
      <TypeaheadSearch
        options={filter.options}
        loadOptions={filter.loadOptions}
        cacheOptions={filter.cacheOptions ? filter.cacheOptions : false}
        placeholder={filter.placeholder ? filter.placeholder : ''}
        isMulti={filter.isMulti ? filter.isMulti : false}
        showSearchIcon={true}
        onSelectChange={(e) => filter.handleSelectChange(e)}
        styles={selectThemes.filterSearch}
        value={filter.value}
      />
    );
  };

  return (
    <FilterContainer>
      <InputLabel>{data?.label}</InputLabel>
      <FilterWrapper style={data?.style ? data.style : null}>
        {type === 'select' && selectFilter(data)}
        {type === 'datepicker' && datePickerFilter(data)}
        {type === 'search' && searchFilter(data)}
        {type === 'typeaheadSearch' && typeaheadSearch(data)}
      </FilterWrapper>
    </FilterContainer>
  );
};

Filter.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
};

export const FilterContainer = styled.div`
  border-top: 0;
  justify-content: flex-start;
  font-size: 12px;
  flex-direction: column;
  display: flex;
  align-content: center;
  align-items: flex-start;
  flex: 0 0 auto;
  margin-right: 15px;
`;

const FilterWrapper = styled.div`
  position: relative;
`;

const InputLabel = styled.label`
  text-align: left;
  margin: 0 0 3px 3px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const SearchContainer = styled.div`
  width: 260px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  input {
    height: 28px;
    border-radius: 6px;
    color: ${colors.darkGrey};
    font-size: 13px;
  }
`;

const SearchBoxContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
`;

const SearchBox = styled.input`
  display: inline-block;
  padding: 4px 18px 4px 10px;
  font-size: 12px;
  flex: 1 1 auto;
  height: 20px;
  border-radius: 4px;
  border: 1px solid ${inputColors.border};
`;

const SearchButton = styled.button`
  background: none;
  margin-left: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
  img {
    width: 15px;
    height: 15px;
    flex: 0 0 auto;
  }
`;

const ClearIcon = styled.img`
  position: absolute;
  height: 8px;
  width: 8px;
  right: 10px;
  cursor: pointer;
  display: block;
  &:hover {
    transform: scale(1.05);
  }
`;

export default Filter;
