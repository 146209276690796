import styled from 'styled-components';
import BaseTable, { AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css';
import PropTypes from 'prop-types';

const Table = ({
  columns,
  data,
  disabled,
  rowHeight = 35,
  headerHeight = 40,
  sortState = {},
  emptyRenderer,
  onEndReachedThreshold,
  onEndReached,
  columnSort,
  overlayRenderer,
  components,
  transformCells,
  containerStyle,
}) => {
  return (
    <TableContainer style={containerStyle}>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            width={width}
            height={height}
            disabled={disabled}
            columns={columns}
            data={data}
            onEndReachedThreshold={onEndReachedThreshold}
            onEndReached={onEndReached}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            overlayRenderer={overlayRenderer}
            sortState={sortState}
            components={components}
            emptyRenderer={emptyRenderer}
            onColumnSort={({ column, key, order }) => {
              return columnSort(column, key, order);
            }}
            // cellProps={({
            //   columns,
            //   column,
            //   columnIndex,
            //   rowData,
            //   rowIndex,
            // }) => {
            //   return transformCells(
            //     columns,
            //     column,
            //     columnIndex,
            //     rowData,
            //     rowIndex
            //   );
            // }}
          />
        )}
      </AutoResizer>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
`;

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  sortState: PropTypes.object,
  onEndReachedThreshold: PropTypes.number,
  onEndReached: PropTypes.func,
  emptyRenderer: PropTypes.func,
  overlayRenderer: PropTypes.func,
  components: PropTypes.object,
  columnSort: PropTypes.func,
  transformCells: PropTypes.func,
  containerStyle: PropTypes.object,
};

export default Table;
