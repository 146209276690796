/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import {
  noSurveys,
  share,
  clipboardCheck,
  pdf,
  documentDownload,
  // ellipsisH,
  ballotCheck,
  link,
  launch,
} from '../../assets';
import {
  createSurveyInstance,
  getSurveyHistory,
  getLatestSurveyInstance,
  toggleShowSurveyResponsesModal,
} from '../../store/actions';
import { EmptyImage, SurveyListItem, SimpleModal } from '../../components';
import { colors, maxDevice, fonts } from '../../styles/variables';
import { ErrorNotification, SuccessNotification } from '../../utils';

const HouseholdSurveysContainer = ({ householdId }) => {
  const { surveys, surveyInstance } = useSelector((state) => ({
    surveys: state.households.surveys,
    surveyInstance: state.households.surveyInstance,
  }));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [surveyList, setSurveyList] = useState([]);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [actionState, setActionState] = useState(false);
  const [showShareSurvey, setShowShareSurvey] = useState(false);
  const [shareSurveyContent, setShareSurveyContent] = useState();
  const surveyHeadings = [
    {
      label: 'Name',
      value: 'name',
      width: '30%',
      placeholderWidth: '250px',
    },
    // {
    //   label: 'Version',
    //   value: 'version',
    //   width: '10%',
    //   placeholderWidth: '50px',
    // },
    {
      label: 'Last Sent',
      value: 'sent_utc',
      width: '13%',
      placeholderWidth: '100px',
    },
    {
      label: 'Last Completed',
      value: 'completed_utc',
      width: '13%',
      placeholderWidth: '100px',
    },
    {
      label: 'Description',
      value: 'completed_description',
      width: '24%',
      placeholderWidth: '100px',
    },
    {
      label: 'Actions',
      value: 'actions',
      align: 'right',
      width: '20%',
      placeholderWidth: '100px',
    },
  ];

  const actionOnClick = (type, survey) => {
    setActionState(type);
    if (type === 'more') {
      if (showMoreMenu === survey.id) {
        setShowMoreMenu(false);
      } else {
        setShowMoreMenu(survey.id);
      }
    } else if (type === 'share') {
      openShareSurveyModal(survey);
    } else if (type === 'complete') {
      if (survey.sent) {
        dispatch(getLatestSurveyInstance(householdId, survey.id));
      }
    } else if (type === 'review') {
      if (survey.completed_id) {
        dispatch(
          toggleShowSurveyResponsesModal(true, {
            name: survey.name,
            id: survey.completed_id,
          })
        );
      }
      // if (survey.sent) {
      //   const matchingResponses = surveyHistory.results.filter(
      //     (res) => res.survey_id === survey.id
      //   );
      //   const mostRecentSurvey = matchingResponses[0];
      //   dispatch(toggleShowSurveyResponsesModal(true, mostRecentSurvey));
      // }
    } else if (type === 'blank') {
      if (survey.pdf_available) {
        const link = `/api/v3/household/${householdId}/survey/${survey.id}/pdf?blank=1`;
        window.open(link, '_blank');
      } else {
        ErrorNotification(`Blank PDF not available`, 5);
      }
    } else if (type === 'pdf') {
      if (survey.pdf_available && survey.completed) {
        const link = `/api/v3/household/${householdId}/survey/${survey.id}/pdf`;
        window.open(link, '_blank');
      } else {
        ErrorNotification(`Completed PDF not available`, 5);
      }
    }
  };

  const actionsList = [
    {
      label: 'Share Questionnaire',
      value: 'share',
      disabledLabel: 'Cannot share Questionnaire',
      icon: share,
    },
    {
      label: 'Complete Now',
      value: 'complete',
      disabledLabel: 'Questionnaire is already complete',
      // isMoreMenu: true,
      icon: ballotCheck,
    },
    {
      label: 'View Responses',
      value: 'review',
      disabledLabel: 'Cannot view responses',
      icon: clipboardCheck,
    },
    {
      label: 'Download Responses as PDF',
      disabledLabel: 'PDF is not available',
      value: 'pdf',
      icon: pdf,
    },
    // {
    //   label: 'More Actions',
    //   value: 'more',
    //   icon: ellipsisH,
    // },
    {
      label: 'Download Blank PDF',
      value: 'blank',
      disabledLabel: 'Blank PDF is not available',
      //isMoreMenu: true,
      isStartIcon: true,
      icon: documentDownload,
    },
  ];

  useEffect(() => {
    if (surveys) {
      setLoading(false);
      setSurveyList(surveys?.results);
    }
  }, [surveys]);

  useEffect(() => {
    if (surveyInstance) {
      const link = `${window.location.origin}/survey/${surveyInstance.id}`;
      if (actionState === 'share') {
        copy(link);
        SuccessNotification('Link Copied');
      }
      if (actionState === 'complete') {
        window.open(link, '_blank');
      }
      setShowShareSurvey(false);
      dispatch(getSurveyHistory(householdId)); // update responses list
    }
  }, [surveyInstance]);

  // DISPATCHES CALL TO CREATE SURVEY INSTANCE
  const shareSurveyInstance = (surveyId, action) => {
    setActionState(action);
    return dispatch(createSurveyInstance(householdId, surveyId));
  };

  // FUNCTION TO OPEN SURVEY SHARE MODAL & SET CONTENT
  const openShareSurveyModal = (survey) => {
    setShowShareSurvey(true);
    const surveyLink = `${window.location.origin}/survey/${survey?.sent_id}`;
    setShareSurveyContent({
      heading: 'Share Questionnaire',
      text: `How would you like to share <strong>${survey.name}</strong>?`,
      minWidth: '350px',
      infoBox: survey?.unfinished
        ? {
            message: `A new copy of this questionnaire has already been created but not completed. To complete it click <a href="${surveyLink}" target="blank">here</a>. Sharing this questionnaire again will create a new questionnaire instance.`,
            margin: '-5px 15px 15px 15px',
            fontSize: '11px',
          }
        : null,
      horizontalContent: [
        {
          type: 'buttonRow',
          text: 'Copy Link',
          name: 'copy',
          image: link,
          onClick: () => shareSurveyInstance(survey.id, 'share'),
        },
        {
          type: 'buttonRow',
          text: 'Launch Now',
          name: 'launch',
          image: launch,
          onClick: () => shareSurveyInstance(survey.id, 'complete'),
        },
      ],
    });
  };

  return (
    <>
      {showShareSurvey && (
        <SimpleModal
          hide={() => setShowShareSurvey(!showShareSurvey)}
          content={shareSurveyContent}
        />
      )}
      <PageContent>
        <SurveyListContainer>
          <SurveyListHeadings>
            {surveyHeadings.map((heading, index) => {
              return (
                <HeadingContainer
                  key={index}
                  $width={heading.width}
                  $align={heading.align}
                >
                  {heading.label}
                </HeadingContainer>
              );
            })}
          </SurveyListHeadings>
          {loading ? (
            <SurveyListItem
              key={0}
              isLoading={true}
              survey={null}
              actions={actionsList}
            />
          ) : (
            <SurveyList>
              {surveyList && surveyList.length !== 0 ? (
                surveyList.map((survey, index) => {
                  return (
                    <SurveyListItem
                      key={index}
                      surveyNumber={index + 1}
                      survey={survey}
                      actions={actionsList}
                      actionOnClick={actionOnClick}
                      showMoreMenu={showMoreMenu}
                      setShowMoreMenu={setShowMoreMenu}
                    />
                  );
                })
              ) : (
                <EmptyImage
                  imageSrc={noSurveys}
                  imageName="No Questionnaires"
                  text="No Available Questionnaires"
                />
              )}
            </SurveyList>
          )}
        </SurveyListContainer>
      </PageContent>
    </>
  );
};

const HeadingContainer = styled.div`
  flex: 1 1 auto;
  width: ${(props) => (props.$width ? props.$width : '100%')};
  text-align: ${(props) => (props.$align ? props.$align : 'left')};
  padding: 0 5px;
`;

const SurveyListHeadings = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
`;

const SurveyList = styled.div`
  min-height: 180px;
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -20px 20px 50px 20px;
  }
  margin: -25px 40px 30px 40px;
`;

const SurveyListContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 60px;
`;

HouseholdSurveysContainer.propTypes = {
  householdId: PropTypes.string,
};

export default HouseholdSurveysContainer;
