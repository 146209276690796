import { IntegrationsContainer } from '../containers';
import { UpdatePageTitle, CloseDropdowns } from './../utils';

const Integration = () => {
  UpdatePageTitle('Integrations');
  CloseDropdowns();

  return <IntegrationsContainer />;
};

export default Integration;
