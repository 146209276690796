import { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, TextInput, Select, Error } from '../';
import { colors, inputColors, fonts } from '../../styles/variables';
import { timesWhite, trashDark } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  CloseButton,
} from '../../styles/library/modalStyles';

const FilterModal = ({
  hide,
  content,
  inputs,
  inputOnChange,
  selectOnChange,
  responseSelectOnChange,
  deleteFunction,
  // conjunctionOnChange,
  errors,
}) => {
  const ref = useRef();

  const generateSelectContainer = (key, input) => {
    return (
      <SelectContainer key={key}>
        {input.label && <InputLabel>{input.placeholder}</InputLabel>}
        <Select
          selectedOption={input.selectedOption}
          onChange={input.onChange}
          options={input.options}
          isSearchable={true}
          placeholder={input.placeholder}
          autoFocus={false}
          isDisabled={input.isDisabled}
        />
      </SelectContainer>
    );
  };

  const generateFilterRow = (key, input) => {
    return (
      <FilterRowContainer key={key}>
        <SelectContainer>
          <InputLabel>{input.columnName}</InputLabel>
          <Select
            selectedOption={input.selectedOption}
            onChange={(e) => selectOnChange(e, input.name)}
            options={input.options}
            isSearchable={true}
            placeholder={input.placeholder}
            autoFocus={false}
            isDisabled={input.isDisabled}
          />
        </SelectContainer>
        {input.useSelect ? (
          <ResponseSelectContainer style={{ width: input.width }}>
            <Select
              selectedOption={input.responseSelectedOption}
              onChange={(e) => responseSelectOnChange(e, input.name)}
              options={input.responseOptions}
              isSearchable={true}
              placeholder={input.placeholder}
              autoFocus={false}
              isDisabled={input.isDisabled}
            />
          </ResponseSelectContainer>
        ) : (
          <TextInputContainer style={{ width: input.width }}>
            <TextInput
              type={input.type}
              name={input.name}
              maxLength={input.maxLength}
              // id={input.id}
              hasError={input.hasError}
              required={input.required}
              disabled={input.disabled}
              placeholder={input.placeholder}
              value={input.value}
              onChange={(e) => inputOnChange(e, input.name)}
            />
          </TextInputContainer>
        )}

        {/* <ConjunctionContainer>
          <input
            type="radio"
            id="and"
            name={`conjunction${key}`}
            value="and"
            checked={input.conjunction === 'and'}
            onChange={() => conjunctionOnChange('and', input.name)}
          />
          <label htmlFor="and" style={{ marginRight: '5px' }}>
            And
          </label>
          <input
            type="radio"
            id="or"
            name={`conjunction${key}`}
            value="or"
            checked={
              input.conjunction === 'or' || input.conjunction === undefined
            }
            onChange={() => conjunctionOnChange('or', input.name)}
          />
          <label htmlFor="or">Or</label>
        </ConjunctionContainer> */}
        <TrashIconContainer
          onClick={() => {
            deleteFunction(input.id);
          }}
        >
          <TrashIcon src={trashDark} alt="trash" data-image={`trash-${key}`} />
        </TrashIconContainer>
      </FilterRowContainer>
    );
  };

  const generateInput = (input, index) => {
    if (input.type === 'select') {
      return generateSelectContainer(index, input);
    } else if (input.type === 'filter') {
      return generateFilterRow(index, input);
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>{content.heading}</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            {content.infoText ? (
              <ModalInfoText>{content.infoText}</ModalInfoText>
            ) : content.text ? (
              <ModalText>{content.text}</ModalText>
            ) : null}
            {inputs &&
              inputs.length !== 0 &&
              inputs.map((input, index) => {
                return generateInput(input, index);
              })}
            {content.buttons && content.buttons.length !== 0 && (
              <ButtonsRowContainer>
                {content.buttons.map((button, index) => {
                  return (
                    <div
                      key={index}
                      style={index === 1 ? { marginLeft: '10px' } : null}
                    >
                      <Button
                        text={button.text}
                        onClick={button.function}
                        theme={button.theme}
                      />
                    </div>
                  );
                })}
              </ButtonsRowContainer>
            )}
          </ModalContentContainer>
          {errors && errors !== '' && <Error errorMessage={errors} />}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const ModalHeadingContainer = styled.div`
  padding: 12px 22px;
  background: ${colors.darkGrey};
  border-radius: 6px 6px 0 0;
  position: relative;
`;

const ModalHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  background: ${colors.darkGrey};
  color: ${colors.white};
  font-size: 15px;
`;

const ModalContentContainer = styled.div`
  padding: 15px 15px;
  /* max-height: 500px;
  overflow-y: auto; */
`;

const ModalInfoText = styled.p`
  font-size: 11px;
  margin-bottom: 15px;
`;

const ModalText = styled.p`
  font-size: 13px;
  margin-bottom: 15px;
`;

const InputLabel = styled.label`
  text-align: left;
  margin: 0 0 3px 3px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  input {
    flex: 1 1 auto;
    padding: 9.5px 10px;
    font-size: 12px;
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
`;

const ResponseSelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const ButtonsRowContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  div {
    flex: 1 1 50%;
    button {
      width: 100%;
    }
  }
`;

const FilterRowContainer = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 15px;
  margin-bottom: 15px;
  ${SelectContainer} {
    width: 120px;
    margin: 0;
    flex: 0 0 auto;
  }
  ${InputLabel} {
    margin: 0 0 2px 3px;
  }
  ${TextInputContainer} {
    margin: 0 10px 0 10px;
    flex: 1 1 auto;
  }
  ${ResponseSelectContainer} {
    margin: 0 10px 0 10px;
    flex: 1 1 auto;
  }
`;

// const ConjunctionContainer = styled.div`
//   display: flex;
//   align-content: center;
//   justify-content: flex-start;
//   align-items: center;
//   margin-left: -4px;
//   margin-bottom: 7px;
//   font-size: 12px;
// `;

const TrashIconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 3px;
  background: ${colors.lightGrey};
`;

const TrashIcon = styled.img`
  width: 15px;
  height: 15px;
`;

FilterModal.propTypes = {
  hide: PropTypes.func,
  content: PropTypes.object,
  inputs: PropTypes.array,
  inputOnChange: PropTypes.func,
  selectOnChange: PropTypes.func,
  responseSelectOnChange: PropTypes.func,
  deleteFunction: PropTypes.func,
  errors: PropTypes.string,
};

export default FilterModal;
