import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styles/variables';

const SortButtons = ({ name, clickAscend, clickDescend, direction }) => {
  return (
    <SortButtonsContainer>
      <span>{name}</span>
      {clickAscend && clickDescend && (
        <ButtonsContainer>
          <Ascend
            className="arrow up"
            onClick={clickAscend}
            direction={direction}
          />
          <Descend
            className="arrow down"
            onClick={clickDescend}
            direction={direction}
          />
        </ButtonsContainer>
      )}
    </SortButtonsContainer>
  );
};

const SortButtonsContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 10px;
  width: 5px;
  transition: border 150ms;
  .arrow {
    display: block;
    padding: 2px;
    &:hover {
      border: solid ${colors.darkGrey};
      border-width: 0 1px 1px 0;
    }
  }
`;

const Ascend = styled.button`
  border: solid
    ${(props) =>
      props.direction === 'ascend' ? colors.darkGrey : colors.paleGrey};
  border-width: 0 1px 1px 0;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

const Descend = styled.button`
  border: solid
    ${(props) =>
      props.direction === 'descend' ? colors.darkGrey : colors.paleGrey};
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

SortButtons.propTypes = {
  name: PropTypes.string,
  clickAscend: PropTypes.func,
  clickDescend: PropTypes.func,
  direction: PropTypes.string,
};

export default SortButtons;
