/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { checkCircle } from '../../assets';
import {
  colors,
  maxDevice,
  fonts,
  boxShadows,
  inputColors,
} from '../../styles/variables';
import { LinkStyle } from '../../styles/library/fontStyles';
import { Button, GenerateInput, Error, Toggle } from '../../components';
import { ButtonThemes } from '../../styles/themes';

const IntegrationCard = ({
  index,
  integration,
  toggleIntegration,
  visitIntegration,
  sendDisableIntegration,
  integrationLogin,
  loginInputs,
  buttonLoading,
  loginError,
  toggleFinancials,
}) => {
  const generateActivateMethod = () => {
    if (integration.is_manager_disabled) {
      return (
        <ManagerDisabledText>
          Team Manager has disabled this integration
        </ManagerDisabledText>
      );
    }
    if (integration.isPartner) {
      return (
        <>
          <Button
            text="Learn More"
            bigButton={false}
            theme={{ ...ButtonThemes.primary, color: integration.color }}
            onClick={() => {
              visitIntegration(integration);
            }}
          />
          {/* <CardSmallText>
            {integration.name} is an Asset-Map partner. Visit their website to
            see more details.
          </CardSmallText> */}
        </>
      );
    }
    if (
      (integration.enable_method === 'inbound' ||
        integration.enable_method === 'external') &&
      !integration.disable
    ) {
      return (
        <>
          {!integration.disable_deactivate && (
            <Button
              text="Learn More"
              target="_blank"
              rel="noopener noreferrer"
              url={integration.enable_url}
              onClick={() => {
                visitIntegration(integration);
              }}
            ></Button>
          )}
          <CardSmallText>
            {integration.name} is an inbound integration.
          </CardSmallText>
          <CardSmallText>
            You must set up the integration using their website/application.
          </CardSmallText>
        </>
      );
    }
    if (!integration.disable) {
      return (
        <>
          <Button
            text="Activate"
            bigButton={false}
            onClick={() => {
              toggleIntegration(integration);
            }}
          />
          {integration.warning && <Warning>{integration.warning}</Warning>}
          {!integration.small_text && (
            <>
              <CardSmallText>
                Activating will take you to {integration.name}.
              </CardSmallText>
              <CardSmallText>
                You&apos;ll be asked to sign in and confirm that you want to
                import:
              </CardSmallText>
            </>
          )}
          {integration?.error_message ? (
            <ErrorContainer>
              <Error errorMessage={integration?.error_message} />
            </ErrorContainer>
          ) : (
            <>
              {integration.small_text && (
                <CardSmallText>{integration.small_text}</CardSmallText>
              )}
              <CardPermissionsText>{integration.access}</CardPermissionsText>
            </>
          )}
        </>
      );
    }
    return null;
  };

  const generateToggleFinancials = () => {
    if ('is_enabled_financials' in integration) {
      return (
        <ToggleContainer>
          <Toggle
            props={{
              name: 'Enable Financials',
              value: integration.is_enabled_financials,
              text: 'Include Financials',
            }}
            onClick={() =>
              toggleFinancials(
                integration.key,
                !integration.is_enabled_financials
              )
            }
          />
          {integration.key === 'redtail' && (
            <CardSmallText>
              {
                'Including financials will result in existing financials in Redtail CRM being imported to a household in Asset-Map when a household is synced with Redtail CRM. If Redtail financial instruments have already been manually added to your Asset-Map households, including financials could cause data duplication.'
              }
            </CardSmallText>
          )}
          {integration.key === 'blackdiamond' && (
            <CardSmallText>
              {
                'Including financials will result in existing financials in Black Diamond CRM being imported to a household in Asset-Map when a household is synced with Black Diamond CRM. If Black Diamond financial instruments have already been manually added to your Asset-Map households, including financials could cause data duplication.'
              }
            </CardSmallText>
          )}
        </ToggleContainer>
      );
    }
    return <></>;
  };

  return integration.is_enabled ? (
    <CardActive key={index} color={integration.color}>
      <CardImageContainer>
        <CardImage
          src={integration.image}
          alt={integration.name}
          data-image={`integration-${integration.name}`}
          style={{
            height: integration?.height || null,
            padding: integration?.padding || null,
            maxWidth: integration?.tile_maxWidth || null,
          }}
        />
      </CardImageContainer>
      {integration.activeInfo ? (
        <CardInfo>
          <p>
            We have created a folder named{' '}
            {
              <a
                href={`/api/v3/member/integration/${integration.key}/go/top`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {'"Asset-Map" '}
              </a>
            }
            in your {integration.name} account.
          </p>{' '}
          <p>
            To begin using your {integration.name} integration, navigate to a
            Household and click &quot;Enable&quot; to start uploading files.
          </p>
        </CardInfo>
      ) : (
        <CardInfo>{integration.info}</CardInfo>
      )}
      {generateToggleFinancials()}
      <CardStatusRow>
        <StatusLeft>
          <img src={checkCircle} alt="check" data-image="active-check" />
          <span>ACTIVE</span>
        </StatusLeft>
        <StatusRight>
          {!integration.disable_deactivate && (
            <LinkStyle
              href={integration.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                sendDisableIntegration(integration);
              }}
            >
              Deactivate
            </LinkStyle>
          )}
          {integration.info_url && (
            <LinkStyle href={integration.info_url} target="_blank">
              More Info
            </LinkStyle>
          )}
          {integration.is_sso && (
            <LinkStyle href={integration.sso_url} target="_blank">
              {integration.name} Login
            </LinkStyle>
          )}
        </StatusRight>
      </CardStatusRow>
    </CardActive>
  ) : integration.showLogin ? (
    <Card key={index}>
      <CardContent>
        <CardImageContainerLogin
          style={{
            height: integration?.height ? integration?.height : null,
            padding: integration?.padding ? integration?.padding : null,
          }}
        >
          <CardImage src={integration.image} alt={integration.name} />
        </CardImageContainerLogin>
        <LoginDetails
          dangerouslySetInnerHTML={{
            __html: integration.login_details,
          }}
        />
        <InputsContainer>
          {loginInputs.map((input, index) => {
            return GenerateInput(input, index);
          })}
        </InputsContainer>
        <div>
          <Button
            text={`Link ${integration.name} Account`}
            showLoading={buttonLoading}
            loadingText={`Connecting to ${integration.name}`}
            onClick={() => {
              integrationLogin(integration);
            }}
          />
        </div>
        {loginError !== '' && (
          <ErrorContainer>
            <Error errorMessage={loginError} />
          </ErrorContainer>
        )}
      </CardContent>
    </Card>
  ) : (
    <Card key={index} height={integration?.isPartner ? '300px' : null}>
      {integration.isNew && (
        <CornerRibbon color={integration?.isPartner ? integration.color : null}>
          New
        </CornerRibbon>
      )}
      <CardImageContainer
        style={{
          height: integration?.height || null,
          padding: integration?.padding || null,
        }}
      >
        <CardImage
          src={integration.image}
          alt={integration.name}
          height={integration.imageHeight}
          disabled={integration.is_manager_disabled}
          style={{
            height: integration?.height || null,
            padding: integration?.padding || null,
            maxWidth: integration?.tile_maxWidth || null,
          }}
        />
      </CardImageContainer>
      <CardInfo disabled={integration.is_manager_disabled}>
        {integration.info}
      </CardInfo>
      {generateActivateMethod()}
    </Card>
  );
};

const CornerRibbon = styled.div`
  position: absolute;
  border: 1px solid ${colors.lightGrey};
  border: ${(props) =>
    props.color
      ? `1px solid ${props.color}`
      : `1px solid ${inputColors.primary25}`};
  width: 200px;
  background: ${colors.lightGrey};
  background: ${(props) => (props.color ? props.color : inputColors.primary25)};
  top: 16px;
  right: -74px;
  padding: 3px 0;
  text-align: center;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: ${fonts.bold};
  text-transform: uppercase;
  color: ${colors.paleGrey};
  color: ${(props) => (props.color ? 'white' : colors.blue)};
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  z-index: 1;
`;

const Warning = styled.div`
  font-size: 10px;
  font-weight: 600;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 10px;
`;

const Card = styled.div`
  @media (max-width: 1360px) {
    flex: 1 1 40%;
    max-width: 40%;
    min-width: 40%;
  }
  @media (max-width: 950px) {
    height: 330px;
  }
  @media ${maxDevice.tablet} {
    flex: 1 1 85%;
    max-width: 85%;
    min-width: 85%;
  }
  @media ${maxDevice.mobileL} {
    /* min-width: 250px; */
    margin: 0 0px 20px 0px;
    height: 320px;
  }
  @media ${maxDevice.mobileS} {
    padding: 15px 13px;
    margin: 0 0px 10px 0px;
  }
  border: 1px solid ${colors.lightGrey};
  border-radius: 0 0 5px 5px;
  background: ${colors.lighterGrey};
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 360px;
  max-width: 360px;
  min-width: 300px;
  height: 280px;
  height: ${(props) => props.height};
  padding: 20px 25px;
  margin: 0 10px 20px 10px;
  position: relative;
  overflow: hidden;
  button {
    margin: 0px 0 25px 0;
    width: 150px;
  }
  /* &:hover {
    transform: scale(1.01);
  } */
`;

const CardActive = styled(Card)`
  box-shadow: ${boxShadows.boxShadowSoft};
  border-top: ${(props) => `8px solid ${props.color}`};
  background: white;
  justify-content: stretch;
  button {
    margin: 20px 0;
    width: 150px;
  }
`;

const CardImageContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 45px;
`;

const CardImageContainerLogin = styled(CardImageContainer)`
  height: 35px;
`;

const LoginDetails = styled.p`
  font-size: 12px;
  margin: 10px 0;
  a {
    color: ${colors.lightBlue};
    font-weight: ${fonts.semiBold};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

const ErrorContainer = styled.div`
  div {
    border-radius: 3px;
    padding: 8px 15px;
    font-size: 11px;
  }
`;

const CardImage = styled.img`
  height: ${(props) => (props.height ? props.height : '100%')};
  width: 100%;
  max-height: 65px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '200px')};
  filter: ${(props) => (props.disabled ? 'grayscale(100%)' : null)};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const CardInfo = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 11px;
  }
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  height: 50px;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 auto;
  color: ${(props) => (props.disabled ? colors.paleGrey : null)};
  p {
    display: inline;
    max-width: 300px;
    &:first-child {
      margin-bottom: 8px;
    }
  }
  a {
    color: ${colors.lightBlue};
  }
  /* border-bottom: 1px solid ${colors.lightGrey}; */
`;

const InputsContainer = styled.div`
  width: 100%;
  margin: 10px 0 20px 0;
`;

// const CardExternalLink = styled.a`
//   font-size: 13px;
//   margin-bottom: 20px;
//   color: ${colors.lightBlue};
//   &:hover {
//     color: ${colors.blue};
//   }
// `;

const CardStatusRow = styled.div`
  /* border-top: 1px solid ${colors.lightGrey}; */
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-end;
  flex: 0 1 auto;
`;

const StatusLeft = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  text-align: left;
  font-size: 15px;
  font-weight: ${fonts.semiBold};
  color: ${colors.green};
  img {
    width: 20px;
    margin-right: 10px;
  }
`;

const StatusRight = styled.div`
  flex: 1 1 auto;
  text-align: right;
  font-size: 12px;
  ${LinkStyle} {
    margin-left: 15px;
  }
`;

const CardContent = styled.div`
  width: 100%;
  button {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const CardSmallText = styled.p`
  @media ${maxDevice.mobileL} {
    font-size: 8px;
  }
  font-size: 10px;
  color: ${colors.paleGrey};
`;

const CardPermissionsText = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
  margin-top: 12px;
`;

const ToggleContainer = styled.div`
  margin: 10px;
  text-align: center;
`;

const ManagerDisabledText = styled.div`
  margin: 0px 0 40px 0;
  font-size: 13px;
  font-weight: ${fonts.bold};
  color: ${colors.paleGrey};
`;

IntegrationCard.propTypes = {
  index: PropTypes.number,
  cardId: PropTypes.number,
  integration: PropTypes.object,
  toggleIntegration: PropTypes.func,
  visitIntegration: PropTypes.func,
  sendDisableIntegration: PropTypes.func,
  integrationLogin: PropTypes.func,
  loginInputs: PropTypes.array,
  buttonLoading: PropTypes.bool,
  loginError: PropTypes.string,
  toggleFinancials: PropTypes.func,
};

export default IntegrationCard;
