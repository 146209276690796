import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexCenterAll } from '../styles/library/layoutStyles';
import {
  maxDevice,
  heights,
  colors,
  fonts,
  boxShadows,
} from '../styles/variables';
import PropTypes from 'prop-types';
import { AvatarPlaceholder, Initials } from '../styles/library/imageStyles';
import { LoadingPlaceholder } from '../styles/library/fontStyles';
import { logo as amLogo, chevronDown, userShield } from '../assets';

const NavBar = ({ loggedIn, navBarContent, unread }) => {
  const leftContent = navBarContent.leftContent;
  const centerContent = navBarContent.centerContent;
  const rightContent = navBarContent.rightContent;
  const transformNotificationCount = (unread) => {
    if (unread >= 1 && unread < 100) {
      return <NotificationCount>{unread}</NotificationCount>;
    } else if (unread >= 100) {
      return <NotificationCount>99+</NotificationCount>;
    } else {
      return <NotificationCount />;
    }
  };

  return (
    <>
      <Navbar>
        <LeftContent>
          {loggedIn && (
            <>
              {leftContent.map((item, index) => {
                if (item.kind === 'image') {
                  return (
                    <IconImage
                      key={index}
                      src={item.src}
                      alt={item.alt}
                      title={item.title}
                      data-image={`nav-${item.alt}`}
                      onClick={item.function}
                    />
                  );
                } else if (item.kind === 'link') {
                  return (
                    item.value && (
                      <TextContainer key={index}>
                        <LinkContainer key={index}>
                          <Link to={`/household/${item.id}/summary`}>
                            {item.value}
                          </Link>
                        </LinkContainer>
                      </TextContainer>
                    )
                  );
                } else {
                  return (
                    item.value && (
                      <TextContainer
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item.value }}
                      />
                    )
                  );
                }
              })}
            </>
          )}
        </LeftContent>
        <CenterContent>
          <Link to={centerContent.link} onClick={centerContent.onClick}>
            {centerContent.appLogo ? (
              <AppLogo
                src={centerContent.appLogo}
                alt={centerContent.alt}
                isCustom={centerContent.appLogo !== amLogo}
                data-image="nav-logo"
              />
            ) : (
              <LoadingPlaceholder width="175px" height="35px" margin="0" />
            )}
            {/* {(() => {
              if (centerContent.appLogo) {
                return (() => {
                  if (centerContent.appLogo === amLogo) {
                    return (
                      <AppLogo
                        src={centerContent.appLogo}
                        alt={centerContent.alt}
                        data-image="nav-logo"
                      />
                    );
                  } else {
                    return (
                      <BrandLogo
                        src={centerContent.appLogo}
                        alt={centerContent.alt}
                        data-image="nav-logo"
                      />
                    );
                  }
                })();
              } else {
                return (
                  <LoadingPlaceholder width="175px" height="35px" margin="0" />
                );
              }
            })()} */}
            <PlusLogo
              src={centerContent.plusLogo}
              alt={centerContent.alt}
              data-image="nav-logo"
              isCustom={centerContent.appLogo !== amLogo}
            />
          </Link>
        </CenterContent>
        <RightContent>
          {loggedIn && (
            <>
              {rightContent.map((item, index) => {
                return item.isAvatar ? (
                  item.src ? (
                    <Avatar
                      key={index}
                      src={item.src}
                      alt={item.alt}
                      data-image="nav-user-avatar"
                      onClick={item.function}
                    />
                  ) : (
                    <AvatarPlaceholder
                      key={index}
                      onClick={item.function}
                      background={colors.lightGrey}
                      data-image="nav-user-avatar"
                      clickable={true}
                    >
                      <Initials>{item.initials}</Initials>
                    </AvatarPlaceholder>
                  )
                ) : item.isIntercom ? (
                  <ExternalLink
                    key={index}
                    href={item.link}
                    onClick={item.onClick}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="launch-intercom"
                  >
                    <IconImageHide
                      src={item.src}
                      alt={item.alt}
                      title={item.title}
                      data-image={`nav-${item.alt}`}
                    />
                  </ExternalLink>
                ) : item.isExternal ? (
                  <ExternalLink
                    key={index}
                    onClick={item.onClick}
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconImageHide
                      src={item.src}
                      alt={item.alt}
                      title={item.title}
                      data-image={`nav-${item.alt}`}
                    />
                  </ExternalLink>
                ) : item.hideSmall ? (
                  <IconImageHide
                    key={index}
                    src={item.src}
                    alt={item.alt}
                    title={item.title}
                    onClick={item.function}
                    data-image={`nav-${item.alt}`}
                    style={
                      item.isActive ? { background: colors.lightGrey } : null
                    }
                  />
                ) : item.kind === 'action' ? (
                  <TextContainer key={index}>
                    <ActionContainer onClick={item.onClick}>
                      {item.value}
                    </ActionContainer>
                  </TextContainer>
                ) : item.kind === 'advisor' ? (
                  item.value && (
                    <AdvisorContainer key={index} onClick={item.function}>
                      <AdvisorIcon
                        src={userShield}
                        alt="advisor"
                        data-image="advisor-nav-icon"
                      />
                      <AdvisorDetails>
                        <AdvisorLabel>Advisor</AdvisorLabel>
                        <LinkContainer $margin="0">
                          <span>{item.value}</span>
                        </LinkContainer>
                      </AdvisorDetails>
                      <DropdownIcon
                        src={chevronDown}
                        alt="chevron down"
                        data-image="chevron-down-nav-icon"
                      />
                    </AdvisorContainer>
                  )
                ) : (
                  <React.Fragment key={index}>
                    <IconImage
                      src={item.src}
                      alt={item.alt}
                      title={item.title}
                      onClick={item.function}
                      data-image={`nav-${item.alt}`}
                    />
                    {transformNotificationCount(unread)}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </RightContent>
      </Navbar>
    </>
  );
};

NavBar.propTypes = {
  loggedIn: PropTypes.bool,
  navBarContent: PropTypes.object,
  unread: PropTypes.number,
};

const Navbar = styled.nav`
  position: fixed;
  height: ${heights.navBar};
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: white;
  box-shadow: ${boxShadows.boxShadowNavbarAlt};
  z-index: 500;
`;

const LeftContent = styled.div`
  @media ${maxDevice.tablet} {
    padding-left: 20px;
    a {
      margin: 0 3px !important;
    }
  }
  flex: 1 1 44%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding-left: 25px;
  a {
    display: flex;
    margin: 0 5px;
  }
  img {
    &:first-child {
      margin-left: -10px;
    }
  }
`;

const TextContainer = styled.div`
  ${FlexCenterAll}
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  span {
    color: ${colors.paleGrey};
    font-weight: ${fonts.regular};
    margin-right: 5px;
  }
`;

const LinkContainer = styled.div`
  font-size: 14px;
  flex: 1 1 auto;
  font-weight: ${fonts.semiBold};
  margin: ${(props) => (props.$margin ? props.$margin : '0 0 0 10px')};
  a,
  span {
    @media ${maxDevice.laptop} {
      max-width: 200px;
    }
    @media ${maxDevice.tablet} {
      max-width: 160px;
      font-size: 13px;
    }
    @media ${maxDevice.sideNav} {
      max-width: 100px;
      font-size: 12px;
    }
    @media ${maxDevice.mobileL} {
      display: none;
    }
    color: ${colors.lightBlue};
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    &:hover {
      transform: scale(1.01);
    }
  }
`;

const AdvisorDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  margin-left: 10px;
  a {
    margin: 0 !important;
  }
  @media ${maxDevice.sideNav} {
    display: none;
  }
`;

const AdvisorLabel = styled.p`
  font-size: 9px;
  font-weight: ${fonts.regular};
  color: ${colors.paleGrey};
  margin-bottom: -3px;
`;

const AdvisorIcon = styled.img`
  width: 23px;
  height: 23px;
`;

const DropdownIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 3px;
`;

const AdvisorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  margin-right: 10px;
  cursor: pointer;
  @media ${maxDevice.tablet} {
    margin-right: 5px;
  }
  @media ${maxDevice.sideNav} {
    margin-right: 0px;
  }
`;

const ActionContainer = styled.div`
  font-size: 14px;
  flex: 1 1 auto;
  font-weight: ${fonts.semiBold};
  margin-right: 10px;
  color: ${colors.lightBlue};
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
`;

const CenterContent = styled.div`
  flex: 0 0 12%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  a {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`;

const RightContent = styled.div`
  @media ${maxDevice.tablet} {
    padding-right: 20px;
  }
  flex: 1 1 44%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  padding-right: 25px;
  a {
    display: flex;
    margin: 0 5px;
  }
`;

const AppLogo = styled.img`
  @media ${maxDevice.tablet} {
    display: ${(props) => (props.isCustom ? 'inherit' : 'none')};
  }
  width: 100%;
  position: relative;
  top: ${(props) => (props.isCustom ? 0 : '2px')};
  max-width: 180px;
  /* min-width: 150px; */
  height: 100%;
  max-height: 50px;
  &:hover {
    opacity: 0.92;
  }
`;

// const BrandLogo = styled.img`
//   @media ${maxDevice.tablet} {
//     display: inherit;
//   }
//   position: relative;
//   object-fit: cover;
//   height: 35px;
//   &:hover {
//     opacity: 0.92;
//   }
// `;

const PlusLogo = styled.img`
  @media ${maxDevice.tablet} {
    display: ${(props) => (props.isCustom ? 'none' : 'inherit')};
  }
  display: none;
  width: 100%;
  position: relative;
  width: 40px;
`;

const IconImage = styled.img`
  @media ${maxDevice.tablet} {
    padding: 8px;
  }
  width: 23px;
  height: 23px;
  transition: all 0.1s ease;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  flex: 0 0 auto;
  &:hover {
    background: ${colors.hoverLighterGrey};
  }
`;

// const NotificationIconContainer = styled.div`
//   display: flex;
//   border-radius: 10px;
//   align-content: center;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;

//   &:hover {
//     background: #f5f5f5;
//   }
// `;

const ExternalLink = styled.a`
  margin: 0;
`;

const IconImageHide = styled(IconImage)`
  @media ${maxDevice.mobileL} {
    display: none;
  }
`;

// Changed to be part of navbar width - can change to make fixed again
// const NotificationCount = styled.span`
//   position: relative;
//   flex: 0 0 auto;
//   font-size: 11px;
//   font-weight: ${fonts.bold};
//   top: -10px;
//   /* left: -12px; */
//   color: ${colors.pink};
//   /* width: 20px; */
//   margin-left: -10px;
//   padding-right: 8px;
//   text-align: left;
// `;

const NotificationCount = styled.span`
  position: relative;
  font-size: 11px;
  font-weight: ${fonts.bold};
  top: -10px;
  left: -13px;
  color: ${colors.pink};
  width: 20px;
  margin-right: -15px;
  text-align: left;
`;

const Avatar = styled.img`
  @media ${maxDevice.tablet} {
    margin-left: 5px;
  }
  border-radius: 50px;
  background: ${colors.lightGrey};
  width: 40px;
  height: 40px;
  margin: 0 0 0 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export default NavBar;
