import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, TargetMapPercentCircle } from '../';
import { toggleShowNewTargetMapModal } from '../../store/actions';
import { pdf } from '../../assets';
import { colors, maxDevice } from '../../styles/variables';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { DownloadReport } from '../../utils';

const TargetMapListItem = ({
  item,
  index,
  showNew = false,
  loading = false,
}) => {
  const dispatch = useDispatch();

  // const generateLabel = (type) => {
  //   if (type === 'ltc') {
  //     return 'LTC';
  //   } else if (type === 'blank' || type === '') {
  //     return 'Custom';
  //   } else {
  //     return type;
  //   }
  // };

  return (
    <>
      {showNew ? (
        <AddTargetMapContainer>
          <p>There are no Target-Maps for this household.</p>
          <div>
            <Button
              text={'Add Target-Map'}
              onClick={() => dispatch(toggleShowNewTargetMapModal(true))}
            />
          </div>
        </AddTargetMapContainer>
      ) : item && !loading ? (
        <TargetMapListItemContainer key={index}>
          <PercentageContainer>
            <TargetMapPercentCircle
              targetMap={item}
              width={'70px'}
              isList={true}
            />
          </PercentageContainer>
          <TextContainer>
            <Text>{item.label}</Text>
            <PercentageBar>
              <CashFlowPercentage style={{ width: `${item.income_funded}%` }} />
              <AssetPercentage style={{ width: `${item.asset_funded}%` }} />
              <InsurancePercentage
                style={{ width: `${item.insurance_funded}%` }}
              />
              {/* <span
                style={{
                  background: item?.color || DetermineTargetMapColor(item.type),
                  width: `${item.funded}%`,
                  height: '15px',
                  borderRadius: '1px',
                }}
              ></span> */}
            </PercentageBar>
            {/* <SubText>{generateLabel(item.type)}</SubText> */}
          </TextContainer>
          <IconContainer
            onClick={() =>
              DownloadReport({
                value: 'targetmap',
                childValue: 'targetmaps',
                hasOptions: true,
                children: [{ selected: true, id: item.id }],
              })
            }
          >
            <img src={pdf} alt="PDF" data-image={`tm-pdf-${item.id}`} />
          </IconContainer>
        </TargetMapListItemContainer>
      ) : (
        <TargetMapListItemContainer key={index}>
          <PercentageContainer $width="55px">
            <CirclePlaceholder />
          </PercentageContainer>
          <TextContainer>
            <LoadingPlaceholder width="180px" />
            <PercentageBar />
          </TextContainer>
          <IconContainer />
        </TargetMapListItemContainer>
      )}
    </>
  );
};

const TargetMapListItemContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  border: 1px solid ${colors.lightGrey};
  padding: 12px 15px;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
`;

const AddTargetMapContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  border: 1px solid ${colors.lightGrey};
  padding: 12px 15px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
  p {
    margin-bottom: 15px;
  }
`;

const PercentageContainer = styled.div`
  margin-right: 15px;
  flex: 0 0 auto;
  width: ${(props) => (props.$width ? props.$width : '65px')};
  height: 60px;
  /* width: 55px;
  height: 55px; */
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const CirclePlaceholder = styled.div`
  margin: 0 0 0 -10px;
  width: 40px;
  height: 40px;
  border: 10px solid ${colors.lightGrey};
  border-radius: 100%;
`;

const PercentageBar = styled.div`
  width: 100%;
  height: 15px;
  border-radius: 3px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  /* border: 1px solid ${colors.lightGrey}; */
  background: ${colors.lightGrey};
  overflow: hidden;
  /* box-shadow: inset 0 0px 2px rgba(0, 0, 0, 0.1); */
`;

const CashFlowPercentage = styled.div`
  background: ${colors.lightBlue};
  flex: 0 1 auto;
  height: 100%;
  /* display: none; */
  &:hover {
    opacity: 0.9;
  }
`;

const AssetPercentage = styled.span`
  background: ${colors.green};
  flex: 0 1 auto;
  height: 100%;
  /* display: none; */
  &:hover {
    opacity: 0.9;
  }
`;

const InsurancePercentage = styled.span`
  background: ${colors.blue};
  flex: 0 1 auto;
  height: 100%;
  /* display: none; */
  &:hover {
    opacity: 0.9;
  }
`;

const TextContainer = styled.div`
  flex: 1 1 auto;
  text-align: left;
  margin-right: 10px;
`;

const Text = styled.p`
  @media ${maxDevice.tablet} {
    max-width: 200px;
  }
  @media ${maxDevice.mobileL} {
    max-width: 150px;
  }
  @media ${maxDevice.mobileM} {
    max-width: 120px;
  }
  @media ${maxDevice.mobileS} {
    max-width: 90px;
  }
  flex: 1 1 auto;
  max-width: 280px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  a {
    color: ${colors.lightBlue};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

// const SubText = styled.p`
//   @media ${maxDevice.tablet} {
//     max-width: 200px;
//   }
//   @media ${maxDevice.mobileL} {
//     max-width: 150px;
//   }
//   @media ${maxDevice.mobileM} {
//     max-width: 120px;
//   }
//   @media ${maxDevice.mobileS} {
//     max-width: 90px;
//   }
//   flex: 1 1 auto;
//   max-width: 250px;
//   text-align: left;
//   white-space: nowrap;
//   overflow: hidden;
//   height: 20px;
//   text-overflow: ellipsis;
//   font-size: 12px;
//   text-transform: capitalize;
// `;

const IconContainer = styled.span`
  flex: 0 0 auto;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGrey};
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
  }
  &:hover {
    transform: scale(1.01);
    background: ${colors.hoverLightGrey};
  }
`;

TargetMapListItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  loading: PropTypes.bool,
  showNew: PropTypes.bool,
};

export default TargetMapListItem;
