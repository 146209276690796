import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { search } from '../../assets';
import { selectThemes } from '../../styles/themes';
import { colors, inputColors } from '../../styles/variables';

//Can add custom loading element
const LoadingIndicator = () => {
  return <span></span>;
};

const TypeaheadSearch = ({
  showSearchIcon = true,
  largeIcon = false,
  cacheOptions,
  loadOptions,
  inputValue,
  value,
  defaultValue,
  onChange,
  onSelectChange,
  options = [],
  isSearchable = true,
  placeholder = 'Search',
  autoFocus = true,
  onKeyDown,
  isMulti = false,
  noOptionMessage = 'No Results',
  styles,
}) => {
  const DropdownIndicator = (props) => {
    return (
      //This is a custom component to change the dropdown to a search icon
      <components.DropdownIndicator {...props}>
        <img
          src={search}
          alt="search"
          data-image="typeahead-search"
          style={
            largeIcon
              ? { width: '25px', height: '25px' }
              : { width: '15px', height: '15px' }
          }
        />
      </components.DropdownIndicator>
    );
  };
  const ClearIndicator = () => {
    return null;
  };
  return (
    <AsyncSelect
      components={
        showSearchIcon
          ? { DropdownIndicator, LoadingIndicator, ClearIndicator }
          : {}
      }
      cacheOptions={cacheOptions}
      loadOptions={loadOptions}
      defaultOptions
      inputValue={inputValue}
      value={value}
      defaultValue={defaultValue}
      onInputChange={onChange}
      onChange={onSelectChange}
      isMulti={isMulti}
      options={options}
      isSearchable={isSearchable}
      placeholder={placeholder}
      autoFocus={autoFocus}
      styles={styles ? styles : selectThemes.default}
      onKeyDown={onKeyDown}
      noOptionsMessage={() => {
        return noOptionMessage;
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.blue,
          primary25: inputColors.primary25,
          primary50: inputColors.primary50,
          primary75: inputColors.primary75,
          neutral80: colors.darkGrey,
        },
      })}
    />
  );
};

TypeaheadSearch.propTypes = {
  showSearchIcon: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  largeIcon: PropTypes.bool,
  loadOptions: PropTypes.func,
  inputValue: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  styles: PropTypes.object,
  onKeyDown: PropTypes.func,
  noOptionMessage: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default TypeaheadSearch;
