import styled, { createGlobalStyle } from 'styled-components';
import * as variables from './variables';

export const AppContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  padding: 0;
  margin: 0;
  /* width: 100%;
  height: 100vh;
  position: absolute;
  top: 0; */
  color: ${variables.colors.darkGrey};
`;

//HAVING A SET HEIGHT IS WHAT IS BREAKING THE MARKER DISPLAY
//REMOVING THAT HEIGHT MESSES UP THE DISPLAY ELSEWHERE
export const RouterOutlet = styled.div`
  position: absolute;
  top: ${variables.heights.navBar};
  /* Added to remove horizontal scroll on windows */
  width: 100%;
  /* height: auto; */
  /* height: calc(100vh - ${variables.heights.navBar}); */
  /* overflow: auto; */
`;

export const Banner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${(props) =>
    props.color ? props.color : variables.messageColors.errorHalfOpacity};
  padding: 5px 10px;
  font-size: 14px;
  opacity: 0.8;
  color: ${variables.colors.white};
  text-align: center;
  z-index: 99;
  a {
    color: ${variables.colors.white};
    font-weight: ${variables.fonts.semiBold};
  }
`;
export const IntercomSpacer = styled.div`
  height: 70px;
  background: transparent;
`;

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  textarea,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'Open Sans', sans-serif;
    vertical-align: baseline;
  }
  sup {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
  }
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
    font-family: 'Open Sans', sans-serif;
  }
  ol,
  ul {
    list-style: none;
    font-family: 'Open Sans', sans-serif;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote {
    &:before,
    &:after {
      content: '';
      content: none;
    }
  }
  q {
    &:before,
    &:after {
      content: '';
      content: none;
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  strong {
    font-weight: ${variables.fonts.semiBold};
  }
  em, i {
    font-style: italic;
  }
  strong, b {
    font-weight: ${variables.fonts.bold};
  }
  img {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:focus {
      outline: none;
    }
  }
  button {
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    outline: none;
    border: 0;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    &:focus {
      outline: none;
    }
  }
  a {
    text-decoration: none;
  }
  &:focus {
      outline: none;
  }
  input {
    font-family: 'Open Sans', sans-serif;
    &:focus {
      outline: none;
    }
  }

  .tippy-tooltip.light-blue-theme {
    background-color: ${variables.colors.lightBlue};
    color: white;
    .arrow-small {
      border-top-color: ${variables.colors.lightBlue} !important;
    }
  }
  .tippy-tooltip.blue-theme {
    background-color: ${variables.colors.blue};
    color: white;
    .arrow-small {
      border-top-color: ${variables.colors.blue} !important;
    }
  }
  .tippy-tooltip.pink-theme {
    background-color: ${variables.colors.pink};
    color: white;
    .arrow-small {
      border-top-color: ${variables.colors.pink} !important;
    }
  }
  .tippy-tooltip.green-theme {
    background-color: ${variables.colors.green};
    color: white;
    .arrow-small {
      border-top-color: ${variables.colors.green} !important;
    }
  }
  .tippy-tooltip.orange-theme {
    background-color: ${variables.colors.orange};
    color: white;
    .arrow-small {
      border-top-color: ${variables.colors.orange} !important;
    }
  }

  .marker-container {
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 490;
    width: 100%;
    .canvas-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 491;
      opacity: 1.0;
    canvas:last-child {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      }
    }
  }
`;
