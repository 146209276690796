/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  SmallTable,
  SimpleModal,
  Pagination,
  SubTabMenu,
  ImagePreviewModal,
} from '../../components';
import { cancel, plusCircleDark } from '../../assets';
import {
  getStencilAssetMapImage,
  getCustomerStencilLibrary,
  updateHouseholdStoreValue,
  cloneStencil,
  toggleShowCloneStencilModal,
  moveCustomerStencil,
  updateUserStoreValue,
  toggleShowStencilImagePreviewModal,
} from '../../store/actions';
import { colors, maxDevice } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import { SendTrackingEvent } from '../../utils';

const ManagerStencilContainer = () => {
  const dispatch = useDispatch();
  const {
    user,
    customerStencilLibrary,
    showStencilLibrary,
    hasAssetmapPlusStencilLibrary,
    hasCustomerStencilLibrary,
    showingStencilImagePreview,
    stencilAmrImage,
    cloneStencilModal,
    cloneStencilError,
    cloningStencil,
    clonedStencil,
    moveStencilError,
    movingStencil,
    movedStencil,
    limit,
    invocationId,
  } = useSelector((state) => ({
    user: state.user.user,
    customerStencilLibrary: state.user.customerStencilLibrary,
    showStencilLibrary: state.user.showStencilLibrary,
    hasAssetmapPlusStencilLibrary: state.user.hasAssetmapPlusStencilLibrary,
    hasCustomerStencilLibrary: state.user.hasCustomerStencilLibrary,
    showingStencilImagePreview: state.households.showingStencilImagePreview,
    stencilAmrImage: state.households.stencilAmrImage,
    cloneStencilModal: state.households.cloneStencilModal,
    cloneStencilError: state.households.cloneStencilError,
    cloningStencil: state.households.cloningStencil,
    clonedStencil: state.households.clonedStencil,
    moveStencilError: state.user.moveStencilError,
    movingStencil: state.user.movingStencil,
    movedStencil: state.user.movedStencil,
    limit: state.configs?.pagination?.default_limit || 25,
    invocationId: state.configs.invocationId,
  }));
  const [activeSubTab, setActiveSubTab] = useState('personal');
  const [subTabs, setSubTabs] = useState([
    {
      text: 'My Stencils',
      value: 'personal',
      visible: true,
    },
    {
      text: 'Public Library',
      value: 'assetmap',
      visible: true,
    },
    {
      text: 'Asset-Map+',
      value: 'assetmap_plus',
      visible: false,
    },
    {
      text: 'Shared',
      value: 'shared',
      visible: false,
    },
  ]);
  const [sortValue, setSortValue] = useState('-last_modified_by_utc');
  const [stencilList, setStencilList] = useState([]);
  const [viewStencil, setViewStencil] = useState();
  const [loadedStencils, setLoadedStencils] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [currentStencil, setCurrentStencil] = useState();
  const [offset, setOffset] = useState(0);

  const headingOnClick = (value) => {
    setStencilColumnHeadings(
      stencilColumnHeadings.map((heading) => {
        if (heading.value === value.value) {
          heading.ascending = !heading.ascending;
          heading.active = true;
        } else {
          heading.active = false;
        }
        return heading;
      })
    );
  };

  const [stencilColumnHeadings, setStencilColumnHeadings] = useState([
    {
      text: 'Name',
      value: 'name',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      active: false,
      ascending: false,
    },
    {
      text: 'Description',
      value: 'description',
      display: true,
    },
    {
      text: '# Members',
      value: 'members_count',
      align: 'center',
      display: true,
    },
    {
      text: '# Financials',
      value: 'stencil_financials_count',
      align: 'center',
      display: true,
    },
    {
      text: 'Last Modified',
      value: 'last_modified_by_utc',
      display: true,
      align: 'center',
      ascending: false,
      sortable: true,
      onClick: headingOnClick,
      active: true,
    },
    { text: 'Actions', display: false },
  ]);

  useEffect(() => {
    if (user) {
      if (showStencilLibrary) {
        const updatedTabs = subTabs.map((tab) => {
          if (tab.value === 'assetmap_plus') {
            tab.visible = hasAssetmapPlusStencilLibrary;
          } else if (tab.value === 'shared') {
            tab.visible = hasCustomerStencilLibrary;
          }
          return tab;
        });
        setSubTabs(updatedTabs);
      }
    }
  }, [user, showStencilLibrary]);

  useEffect(() => {
    if (stencilAmrImage?.url) {
      viewStencil.image = {
        url: stencilAmrImage.url,
        fresh: stencilAmrImage.fresh,
        is_error: stencilAmrImage.is_error,
        refresh: stencilAmrImage.refresh,
        reason: stencilAmrImage.reason,
        generated_utc: stencilAmrImage.generated_utc,
        is_default: stencilAmrImage?.default || false,
      };
      dispatch(
        toggleShowStencilImagePreviewModal(true, {
          ...viewStencil,
        })
      );
    }
  }, [stencilAmrImage?.url]);

  useEffect(() => {
    if (clonedStencil) {
      setActiveSubTab('personal');
      dispatch(updateHouseholdStoreValue('clonedStencil', false));
    }
  }, [clonedStencil]);

  useEffect(() => {
    if (movedStencil) {
      setShowMoveModal(false);
      dispatch(updateUserStoreValue('movedStencil', false));
    }
  }, [movedStencil]);

  useEffect(() => {
    if (customerStencilLibrary) {
      let updatedStencils = [...customerStencilLibrary?.results].map(
        (stencil) => {
          if (activeSubTab === 'assetmap') {
            stencil.link = false;
            stencil.action = {
              value: 'clone',
              icon: plusCircleDark,
              tooltip: 'Add to Personal Library',
              onClick: cloneStencilOnClick,
            };
          } else if (activeSubTab === 'assetmap_plus') {
            stencil.link = false;
            stencil.action = {
              value: 'clone',
              icon: plusCircleDark,
              tooltip: 'Add to Personal Library',
              onClick: cloneStencilOnClick,
            };
          } else if (activeSubTab === 'shared') {
            stencil.link = `${window.location.origin}/ampux/household/${stencil.id}/assetmap`;
            stencil.action = {
              value: 'remove',
              icon: cancel,
              tooltip: 'Remove from Shared and move to Personal Library',
              onClick: moveStencilOnClick,
            };
          } else if (activeSubTab === 'personal' && hasCustomerStencilLibrary) {
            stencil.link = `${window.location.origin}/ampux/household/${stencil.id}/assetmap`;
            stencil.action = {
              value: 'move',
              icon: plusCircleDark,
              tooltip: 'Move to Shared Library',
              onClick: moveStencilOnClick,
            };
          }
          stencil.imageOnClick = viewStencilImage;
          return stencil;
        }
      );
      setStencilList(updatedStencils);
      setLoadedStencils(true);
    }
  }, [customerStencilLibrary]);

  useEffect(() => {
    if (stencilColumnHeadings) {
      const activeHeading = stencilColumnHeadings.find(
        (heading) => heading.active
      );
      let currentSortValue = activeHeading.value;
      if (!activeHeading.ascending) {
        currentSortValue = `-${activeHeading.value}`;
      }
      if (currentSortValue !== sortValue) {
        dispatch(getCustomerStencilLibrary(activeSubTab, currentSortValue));
        setSortValue(currentSortValue);
      }
    }
  }, [stencilColumnHeadings]);

  useEffect(() => {
    setOffset(0);
    if (activeSubTab) {
      setLoadedStencils(false);
      dispatch(getCustomerStencilLibrary(activeSubTab));
      SendTrackingEvent(
        invocationId,
        'click',
        'stencil_library_tab',
        'manager_stencils',
        {
          stencil_library_tab: activeSubTab,
        }
      );
    }
  }, [activeSubTab]);

  useEffect(() => {
    if (showMoveModal) {
      dispatch(updateHouseholdStoreValue('moveStencilError', false));
    }
  }, [showMoveModal]);

  useEffect(() => {
    if (loadedStencils) {
      dispatch(getCustomerStencilLibrary(activeSubTab, sortValue, offset));
    }
  }, [offset]);

  const viewStencilImage = (stencil) => {
    if (stencil?.image?.fresh || stencil?.image?.reason === 'disabled') {
      dispatch(
        toggleShowStencilImagePreviewModal(true, {
          ...stencil,
        })
      );
    } else {
      dispatch(
        toggleShowStencilImagePreviewModal(true, {
          ...stencil,
          imageLoading: true,
        })
      );
      // refresh the image
      dispatch(getStencilAssetMapImage(stencil?.id, true));
    }
    setViewStencil(stencil);
  };

  const cloneStencilOnClick = (stencil) => {
    dispatch(toggleShowCloneStencilModal(true, stencil));
  };

  const moveStencilOnClick = (stencil) => {
    setCurrentStencil(stencil);
    setShowMoveModal(true);
  };

  const getCount = () => {
    return customerStencilLibrary?.count;
  };

  const addPagination = () => getCount() > limit;

  const generateCloneStencilModalContent = () => {
    const stencilName = cloneStencilModal?.data?.name;
    const stencilId = cloneStencilModal?.data?.id;
    return {
      heading: `Add Stencil?`,
      text: `Are you sure you want to add<br/><strong>${stencilName}</strong> to your Personal Library?<br/>Adding this stencil will create a copy of this household and add it to your Personal Library.`,
      buttons: [
        {
          text: 'Cancel',
          function: () => dispatch(toggleShowCloneStencilModal(false)),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Add',
          theme: ButtonThemes.primary,
          loadingText: 'Adding',
          showLoading: cloningStencil,
          function: () => dispatch(cloneStencil(stencilId, true)),
        },
      ],
    };
  };

  //Need dispatch action, loading, error handling and success
  const generateMoveStencilModalContent = () => {
    const isRemove = currentStencil?.action?.value === 'remove';
    const stencilName = currentStencil?.name;
    const stencilId = currentStencil?.id;
    return {
      heading: isRemove ? 'Remove from Shared?' : 'Move to Shared?',
      text: isRemove
        ? `Are you sure you want to remove<br/><strong>${stencilName}</strong> from shared?<br/>This stencil household will no longer be visible to all your users and moved to your personal library.`
        : `Are you sure you want to move<br/><strong>${stencilName}</strong> to shared?<br/>This will allow this stencil household to be visible to all your users.`,
      buttons: [
        {
          text: 'Cancel',
          function: () => setShowMoveModal(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: isRemove ? 'Remove' : 'Move',
          theme: ButtonThemes.primary,
          loadingText: isRemove ? 'Removing' : 'Moving',
          showLoading: movingStencil,
          function: () =>
            dispatch(moveCustomerStencil(stencilId, isRemove, activeSubTab)),
        },
      ],
    };
  };

  return (
    <>
      {cloneStencilModal?.showModal && (
        <SimpleModal
          hide={() =>
            dispatch(toggleShowCloneStencilModal(!cloneStencilModal.showModal))
          }
          content={generateCloneStencilModalContent()}
          error={cloneStencilError}
        />
      )}
      {showMoveModal && (
        <SimpleModal
          hide={() => setShowMoveModal(false)}
          content={generateMoveStencilModalContent()}
          error={moveStencilError}
        />
      )}
      {showingStencilImagePreview && <ImagePreviewModal />}
      <BackgroundPageContent>
        <SubTabMenu
          tabs={subTabs.filter((tab) => tab.visible)}
          activeTab={activeSubTab}
          onClick={setActiveSubTab}
        />
        <StencilContent>
          <SmallTable
            columnHeadings={stencilColumnHeadings}
            type={'customer_stencil'}
            items={stencilList}
            loaded={loadedStencils}
            isHousehold={false}
          />
          {addPagination() && (
            <Pagination
              clickNext={() => setOffset(offset + limit)}
              clickPrev={() => setOffset(offset - limit)}
              goToPage={(page) => setOffset(limit * (page - 1))}
              offset={offset}
              count={getCount()}
              limit={limit}
              paddingBottom={'30px'}
            />
          )}
        </StencilContent>
      </BackgroundPageContent>
    </>
  );
};

const StencilContent = styled.div`
  background: white;
  margin: 0 -40px;
  padding: 40px 40px 0 40px;
`;

const BackgroundPageContent = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 30px 20px 100px 20px;
  }
  padding: 30px 40px 100px 40px;
  margin-top: -25px;
  background: ${colors.lighterGrey};
  /* height: 100%; */
`;

export default ManagerStencilContainer;
