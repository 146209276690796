import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BillingContainer } from '../../containers';
import { getTeamSettings } from '../../store/actions';
import { UpdatePageTitle, CloseDropdowns } from '../../utils';

const Billing = () => {
  const dispatch = useDispatch();

  UpdatePageTitle('Billing');
  CloseDropdowns();

  const { customer } = useSelector((state) => ({
    customer: state.user.user.customer,
  }));

  useEffect(() => {
    if (customer && customer.id) {
      dispatch(getTeamSettings(customer.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return <BillingContainer customerId={customer.id} />;
};

export default Billing;
