import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, maxDevice, fonts } from '../../styles/variables';
import {
  members,
  targetMaps as tmIcon,
  policy,
  balance,
  financials,
  asterisk,
  fire,
  glasses,
  signed,
  notes,
  plusLogoOutline,
  documentDownload,
  reports as comprep,
  trafficLight,
  unknownFile,
  beneficiaries,
  info,
  share,
  milestone,
} from '../../assets';
import { DateFormat } from '../../utils';

export const GenerateReportIcon = (reportType) => {
  switch (reportType) {
    case 'comprep':
      return comprep;
    case 'targetmap':
      return tmIcon;
    case 'assetmap':
      return plusLogoOutline;
    case 'members':
      return members;
    case 'financials':
      return financials;
    case 'beneficiary':
      return beneficiaries;
    case 'notes':
      return notes;
    case 'cover':
      return signed;
    case 'disclosure':
      return asterisk;
    case 'policysummary':
      return policy;
    case 'balancesheet':
      return balance;
    case 'factfinder':
      return glasses;
    case 'financialdrill':
      return fire;
    case 'signals':
      return trafficLight;
    case 'milestones':
      return milestone;
    case 'unknown':
      return unknownFile;
    default:
      return unknownFile;
  }
};

const ReportListItem = ({
  report,
  panel,
  borderBottom = true,
  onClickDetails,
  onClickShare,
  downloadFunc,
  isConsumer = false,
}) => {
  const generateReportKb = () => {
    return report?.bytes && !panel ? Math.round(report?.bytes / 1000) : null;
  };

  return (
    <ThemeProvider theme={{ panel, borderBottom, isConsumer }}>
      <ReportContainer>
        <ReportType>
          <ReportIcon
            src={GenerateReportIcon(report?.report_type)}
            alt={report.report_type}
            data-image={report.report_type}
          />
          <span>{report?.report_title || 'Unknown Report'}</span>
          {!panel && report.report_type === 'comprep' && !isConsumer && (
            <ReportDetails
              onClick={() => onClickDetails(report)}
              src={info}
              alt="info"
              data-image={`${report.report_type}-info`}
            />
          )}
        </ReportType>
        {!panel && !isConsumer && (
          <ReportUser>{report?.member?.name}</ReportUser>
        )}
        <ReportDate>
          {DateFormat(
            report?.created_utc,
            panel ? 'MMM D, YYYY' : 'dddd, MMM D YYYY'
          )}
        </ReportDate>
        <ActionsContainer>
          {generateReportKb() && (
            <KBContainer>
              <KBValue>{generateReportKb()} kB</KBValue>
            </KBContainer>
          )}
          {report.shareable && (
            <ShareIcon
              onClick={() => onClickShare(report)}
              src={share}
              data-image={`${report.report_type}-share`}
            />
          )}
          {downloadFunc ? (
            <DownloadIcon
              src={documentDownload}
              alt="download"
              data-image={`${report.report_type}-download`}
              onClick={() => downloadFunc(report)}
            />
          ) : (
            <a href={`/generate/comprehensive/get/${report.id}/`}>
              <DownloadIcon
                src={documentDownload}
                alt="download"
                data-image={`${report.report_type}-download`}
              />
            </a>
          )}
        </ActionsContainer>
      </ReportContainer>
    </ThemeProvider>
  );
};

const ReportContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  padding: ${(props) => (props.theme.panel ? '12px 0' : '15px 0')};
  height: ${(props) => (props.theme.panel ? '20px' : '25px')};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${(props) =>
    props.theme.borderBottom ? `1px solid ${colors.lightGrey}` : 'none'};
  font-size: 14px;
  color: ${colors.darkGrey};
  &:hover {
    background: ${colors.lighterGrey};
  }
`;

const ReportType = styled.div`
  width: ${(props) => (props.theme.panel ? '60%' : '40%')};
  padding: ${(props) => (props.theme.panel ? '0 10px 0 0' : '0 10px')};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-right: 10px;
  }
`;

const ReportIcon = styled.img`
  height: ${(props) => (props.theme.panel ? '18px' : '20px')};
  width: ${(props) => (props.theme.panel ? '18px' : '20px')};
  margin-right: 15px;
`;

const ReportUser = styled.div`
  width: 40%;
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const ReportDetails = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.5;
`;

const ReportDate = styled.div`
  width: ${(props) => (props.theme.panel ? '35%' : '40%')};
  padding-right: 10px;
  color: ${(props) => (props.theme.panel ? colors.paleGrey : colors.darkGrey)};
  text-align: ${(props) => (props.theme.panel ? 'right' : 'left')};
`;

const KBContainer = styled.div`
  width: 100px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const KBValue = styled.span`
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  background: ${colors.lighterGrey};
  color: ${colors.paleGrey};
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
`;

const ActionsContainer = styled.div`
  width: ${(props) => (props.theme.isConsumer ? '20%' : '12%')};
  padding: 0 10px;
  flex: 0 0 auto;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const DownloadIcon = styled.img`
  @media ${maxDevice.mobileL} {
    width: 12px;
    height: 12px;
  }
  width: 17px;
  height: 17px;
  margin-top: 4px;
  margin-left: 10px;
  opacity: 0.8;
  cursor: pointer;
`;

const ShareIcon = styled.img`
  @media ${maxDevice.mobileL} {
    width: 12px;
    height: 12px;
  }
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
`;

ReportListItem.propTypes = {
  report: PropTypes.object,
  panel: PropTypes.bool,
  borderBottom: PropTypes.bool,
  onClickDetails: PropTypes.func,
  onClickShare: PropTypes.func,
  downloadFunc: PropTypes.func,
  isConsumer: PropTypes.bool,
};

export default ReportListItem;
