import styled, { ThemeProvider } from 'styled-components';
import { lighten, darken } from 'polished';
import { colors, fonts, boxShadows } from '../styles/variables';
import { ButtonThemes } from '../styles/themes';
import { ImageIcon, ImageIconBig } from '../styles/library/imageStyles';
import PropTypes from 'prop-types';
import LoadingAnimation from './LoadingAnimation';

const Button = ({
  theme = ButtonThemes.primary,
  bigButton = false,
  imageSrc,
  onClick,
  text,
  imageName,
  showLoading = false,
  loadingText = 'Creating',
  qaAttribute,
}) => {
  const buttonTheme = {
    bg: theme.color,
    fontColor: theme.fontColor,
    width: theme.width,
    alignment: theme.alignment,
    border: theme.border,
    cursor: theme.cursor,
    transform: theme.transform,
    loadingColor: theme.loadingColor,
  };
  return (
    <ThemeProvider theme={buttonTheme}>
      <>
        {bigButton ? (
          imageSrc ? (
            <BigButtonIcon onClick={onClick}>
              <TextBigButton data-button={qaAttribute}>{text}</TextBigButton>
              <IconBigButton>
                <ImageIconBig
                  src={imageSrc}
                  alt={imageName}
                  data-image={imageName}
                />
              </IconBigButton>
            </BigButtonIcon>
          ) : (
            <BigButtonRegular>
              <TextBigButton data-button={qaAttribute}>{text}</TextBigButton>
            </BigButtonRegular>
          )
        ) : imageSrc ? (
          <ButtonIcon onClick={onClick}>
            <TextButton data-button={qaAttribute}>{text}</TextButton>
            <IconButtonContainer>
              <ImageIcon
                src={imageSrc}
                alt={imageName}
                data-image={imageName}
              />
            </IconButtonContainer>
          </ButtonIcon>
        ) : showLoading ? (
          <LoadingButton onClick={showLoading ? null : onClick}>
            <>
              <LoadingAnimation
                dots={true}
                smaller={true}
                button={true}
                color={theme?.loadingColor || 'white'}
              />
              <span>{loadingText}</span>
            </>
          </LoadingButton>
        ) : (
          <ButtonStyle onClick={onClick} data-button={qaAttribute}>
            {text}
          </ButtonStyle>
        )}
      </>
    </ThemeProvider>
  );
};

Button.propTypes = {
  bigButton: PropTypes.bool,
  text: PropTypes.string,
  theme: PropTypes.object,
  onClick: PropTypes.func,
  imageSrc: PropTypes.string,
  imageName: PropTypes.string,
  showLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  qaAttribute: PropTypes.string,
};

//Main Button Styled Component - Pass themes as props to style when using
const ButtonStyle = styled.button`
  background: ${(props) => props.theme.bg};
  width: ${(props) => props.theme.width};
  text-align: ${(props) => props.theme.alignment};
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid transparent;
  border: ${(props) => props.theme.border};
  color: ${colors.white};
  color: ${(props) => props.theme.fontColor};
  cursor: ${(props) => props.theme.cursor};
  font-size: ${fonts.buttonText};
  line-height: ${fonts.buttonText};
  font-weight: ${fonts.semiBold};
  transition: all 0.1s ease;
  letter-spacing: 0.3px;
  &:hover {
    transform: scale(1.01);
    transform: ${(props) => props.theme.transform};
    background-color: ${(props) =>
      props.theme.bg && lighten(0.05, props.theme.bg)};
  }
`;

//Main Button Styled Component - Pass themes as props to style when using
const LoadingButton = styled.button`
  background: ${(props) => props.theme.bg};
  width: ${(props) => props.theme.width};
  text-align: ${(props) => props.theme.alignment};
  padding: 10px 15px;
  border-radius: 3px;
  border: 1px solid transparent;
  border: ${(props) => props.theme.border};
  color: ${colors.white};
  color: ${(props) => props.theme.fontColor};
  cursor: ${(props) => props.theme.cursor};
  font-size: ${fonts.buttonText};
  line-height: ${fonts.buttonText};
  font-weight: ${fonts.semiBold};
  transition: all 0.1s ease;
  letter-spacing: 0.3px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.01);
    transform: ${(props) => props.theme.transform};
    background-color: ${(props) =>
      props.theme.bg && lighten(0.05, props.theme.bg)};
  }
`;

//Text for Button
const TextButton = styled.span`
  flex: 1 1 auto;
  padding: 10px 15px;
  text-align: left;
  font-size: ${fonts.buttonText};
  font-weight: ${fonts.semiBold};
`;

//Icon Container for Button
const IconButtonContainer = styled.div`
  flex: 0 1 auto;
  width: 34px;
  height: 34px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 0 3px 3px 0;
`;

//Clone of Button Style but allows for right Icon
const ButtonIcon = styled(ButtonStyle)`
  background: ${(props) => props.theme.bg};
  padding: 0;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  border: none;
  &:hover {
    background: ${(props) => props.theme.bg};
  }
  ${IconButtonContainer} {
    background-color: ${(props) =>
      props.theme.bg && darken(0.05, props.theme.bg)};
  }
`;

//Larger Button Styled Component - Pass themes as props to style when using
const BigButtonRegular = styled(ButtonStyle)`
  background: ${(props) => props.theme.bg};
  border: 1px solid transparent;
  color: ${colors.white};
  font-size: ${fonts.primaryText};
  margin: 10px 0;
  padding: 20px;
  text-align: left;
  box-shadow: ${boxShadows.boxShadowDropdown};
  &:hover {
    background-color: ${(props) =>
      props.theme.bg && lighten(0.05, props.theme.bg)};
  }
`;

//Text for Big Button
const TextBigButton = styled.span`
  flex: 1 1 auto;
  font-weight: ${fonts.semiBold};
  padding: 20px;
`;

//Icon Container for Big Button
const IconBigButton = styled.div`
  flex: 0 1 auto;
  width: 70px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 0 3px 3px 0;
`;

//Clone of Big Button but allows for right Icon
const BigButtonIcon = styled(BigButtonRegular)`
  background: ${(props) => props.theme.bg};
  padding: 0;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  border: none;
  &:hover {
    background: ${(props) => props.theme.bg};
  }
  ${IconBigButton} {
    background-color: ${(props) =>
      props.theme.bg && darken(0.05, props.theme.bg)};
  }
`;

export default Button;
