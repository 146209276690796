/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { noReports } from '../../assets';
import {
  EmptyImage,
  SortButtons,
  Pagination,
  LoadingAnimation,
  ReportListItem,
  Filter,
} from '../../components';
import {
  getHouseholdReports,
  toggleShowReportDetailsModal,
  toggleShowReportShareModal,
  getReportShareTypes,
  updateHouseholdStoreValue,
} from '../../store/actions';
import { colors, maxDevice, buttonColors, fonts } from '../../styles/variables';
import { FilterContainer } from '../../components/Table/Filter';
import { DateFormat } from '../../utils';

// local filters state
const filtersReducer = (filters, action) => {
  if (action.type === 'update_report_types') {
    for (const [value, label] of Object.entries(action.payload)) {
      filters[0].options.push({ label: label, value: value });
    }
    return filters;
  }

  const updatedFilters = filters.map((f) => {
    if (f.key === action.type) {
      f.selectedOption = action.payload;
      if (action.type === 'report_type') {
        f.param = action.payload.value;
      }
      if (action.type.includes('created_utc')) {
        f.param = action.payload
          ? DateFormat(action.payload, 'YYYY-MM-DD')
          : '';
      }
      if (action.type === 'search') {
        f.param = action.payload;
        f.value = action.payload;
      }
    }
    return f;
  });
  return updatedFilters;
};

const ordersReducer = (orders, action) => {
  const [field, direction] = action.type.split('-');
  const updatedOrders = orders.map((o) => {
    if (o.field === field) {
      o.direction = direction;
      o.param = field;
      if (direction === 'descend') {
        o.param = '-' + field;
      }
    } else {
      o.direction = '';
      o.param = '';
    }
    return o;
  });
  return updatedOrders;
};

const pagesReducer = (pages, action) => {
  if (action.type === 'update_count') {
    pages.count = action.payload;
  }
  if (action.type === 'update_limit') {
    pages.limit = action.payload;
  }
  if (action.type === 'update_offset') {
    pages.offset = action.payload;
  }
  return pages;
};

const HouseholdReportsArchive = ({ householdId }) => {
  /**
   * Global state
   */
  const dispatch = useDispatch();
  const { showUnlimitedReportHistory, householdReports, reportTypes, limit } =
    useSelector((state) => ({
      showUnlimitedReportHistory: state.user.showUnlimitedReportHistory,
      householdReports: state.households.householdReports,
      reportTypes: state.configs.reports?.available,
      limit: state.configs?.pagination?.default_limit || 25,
    }));

  // sortable components
  const reportType = (order) => {
    return (
      <ReportType key={order.field}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'report_type-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'report_type-descend' })}
          direction={order.direction}
          data-image={`arrow-${order.direction}`}
        />
      </ReportType>
    );
  };

  const generatedBy = (order) => {
    return (
      <ReportUser key={order.field}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'member__name-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'member__name-descend' })}
          direction={order.direction}
          data-image={`arrow-${order.direction}`}
        />
      </ReportUser>
    );
  };

  const createdDate = (order) => {
    return (
      <ReportDate key={order.field}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'created_utc-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'created_utc-descend' })}
          direction={order.direction}
          data-image={`arrow-${order.direction}`}
        />
      </ReportDate>
    );
  };

  /**
   * Local state
   */
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [queryParams, setQueryParams] = useState();

  const selectFilterChange = (e, key) => {
    // set local state of filters
    (async () => {
      dispatchPages({ type: 'update_offset', payload: 0 });
      dispatchFilters({ type: key, payload: e });
      // set local state of parameters, which will trigger the reload to get new hhs
    })().then(() => updateParams());
  };

  const clearSearchValue = () => {
    (async () => {
      dispatchFilters({ type: 'search', payload: '' });
    })().then(() => updateParams());
  };

  const initFiltersState = [
    {
      filterType: 'select',
      key: 'report_type',
      label: 'Report Type',
      placeholder: 'All Reports',
      options: [
        {
          label: 'All Reports',
          value: '',
        },
      ],
      style: { width: '215px' },
      isVisible: true,
      selectedOption: { label: 'All Reports', value: '' },
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'datepicker',
      key: 'created_utc_after',
      placeholder: 'Start Date',
      label: 'Start Date',
      isVisible: false,
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'datepicker',
      key: 'created_utc_before',
      placeholder: 'End Date',
      label: 'End Date',
      isVisible: false,
      onChangeCallback: selectFilterChange,
    },
    {
      filterType: 'search',
      key: 'search',
      type: 'text',
      placeholder: 'Search Reports by Advisor',
      label: 'Search by Advisor',
      isVisible: true,
      value: '',
      onKeyDownCallback: (e) => {
        if (
          e.code === 'Enter' ||
          e.code === 'NumpadEnter' ||
          e.keyCode === 13
        ) {
          updateParams();
        }
      },
      onClearCallback: clearSearchValue,
      onChangeCallback: (e) =>
        dispatchFilters({ payload: e.target.value, type: 'search' }),
      onSearchCallback: () => updateParams(),
    },
  ];
  const [filtersState, dispatchFilters] = useReducer(
    filtersReducer,
    initFiltersState
  );

  const initOrdersState = [
    {
      field: 'report_type',
      direction: '',
      label: 'Report Type',
      component: reportType,
      param: '',
    },
    {
      field: 'member__name',
      direction: '',
      label: 'Generated By',
      component: generatedBy,
      param: '',
    },
    {
      field: 'created_utc',
      direction: 'descend',
      label: 'Date',
      component: createdDate,
      param: '-created_utc',
    },
  ];
  const [ordersState, dispatchOrders] = useReducer(
    ordersReducer,
    initOrdersState
  );

  const initPagesState = {
    limit: limit,
    offset: 0,
    count: 0,
  };
  const [pagesState, dispatchPages] = useReducer(pagesReducer, initPagesState);

  /**
   * Side Effects
   */
  // update hh reports in ui if there are any changes
  useEffect(() => {
    if (householdReports) {
      if (householdReports?.results) {
        setLoading(false);
        setReports(householdReports.results);
      }
      if (householdReports.count !== pagesState.count) {
        dispatchPages({
          type: 'update_count',
          payload: householdReports.count,
        });
      }

      filtersState.forEach((filterState) => {
        if (filterState.filterType === 'datepicker') {
          filterState.isVisible = showUnlimitedReportHistory;
        }
        return filterState;
      });
    }
  }, [householdReports]);

  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('householdReports', false));
    };
  }, []);

  useEffect(() => {
    if (reportTypes) {
      dispatchFilters({
        type: 'update_report_types',
        payload: reportTypes,
      });
    }
  }, [reportTypes]);

  useEffect(() => {
    if (!loading) {
      updateParams();
    }
  }, [pagesState]);

  useEffect(() => {
    if (!loading) {
      dispatchPages({
        type: 'update_offset',
        payload: 0,
      });
      updateParams(0);
    }
  }, [ordersState]);

  // every time the filters or ordering changes update the reports.
  useEffect(() => {
    dispatch(getHouseholdReports(householdId, queryParams));
  }, [queryParams]);

  /**
   * Handling Events
   */
  // this triggers the query params state change which triggers api call to get reports based on params
  const updateParams = (offset) => {
    const params = filtersState.reduce(
      (o, key) => ({ ...o, [key.key]: key.param }),
      {}
    );
    if (offset !== undefined) {
      params.offset = offset;
    } else {
      params.offset = pagesState.offset;
    }
    params.ordering = ordersState.reduce((o, key) => o + key.param, '');
    setQueryParams(params);
  };

  const nextPage = () => {
    const offset = pagesState.offset + pagesState.limit;
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const prevPage = () => {
    const offset = pagesState.offset - pagesState.limit;
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const goToPage = (page) => {
    const offset = pagesState.limit * (page - 1);
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const clickMore = (report) => {
    dispatch(toggleShowReportDetailsModal(true, report));
  };

  const clickShare = (report) => {
    dispatch(getReportShareTypes(householdId, report));
    dispatch(toggleShowReportShareModal(true, report));
  };

  return (
    <PageContent>
      <ActionRow>
        <FiltersContainer>
          {filtersState.map((filterState, ind) => {
            return (
              filterState?.isVisible && (
                <Filter
                  data={filterState}
                  type={filterState.filterType}
                  key={ind}
                />
              )
            );
          })}
          {showUnlimitedReportHistory === false && (
            <Unlimited>
              Results limited to the last 30 days. Want more?{' '}
              <a
                href="https://www.asset-map.com/upgrade"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>
            </Unlimited>
          )}
        </FiltersContainer>
      </ActionRow>
      <ReportsListContainer>
        <ReportsListHeader>
          {ordersState.map((orderState) => {
            return orderState.component(orderState);
          })}
          <DownloadContainer></DownloadContainer>
        </ReportsListHeader>
        {loading ? (
          <Empty>
            <LoadingAnimation />
          </Empty>
        ) : (
          <ReportsListOptions>
            {reports && reports.length !== 0 ? (
              reports.map((report, index) => {
                return (
                  <div key={index}>
                    <ReportListItem
                      report={report}
                      onClickDetails={clickMore}
                      onClickShare={clickShare}
                    />
                  </div>
                );
              })
            ) : (
              <EmptyImage
                imageSrc={noReports}
                imageName="No Reports"
                text="No Reports Found"
              />
            )}
            {pagesState?.count !== 0 && pagesState?.count > limit && (
              <Pagination
                clickPrev={prevPage}
                clickNext={nextPage}
                goToPage={goToPage}
                limit={pagesState.limit}
                offset={pagesState.offset}
                count={pagesState.count}
              />
            )}
          </ReportsListOptions>
        )}
      </ReportsListContainer>
    </PageContent>
  );
};

const ReportType = styled.div`
  width: 40%;
  padding: 0 10px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  span {
    margin-right: 10px;
  }
`;

const ReportUser = styled.div`
  width: 40%;
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const ReportDate = styled.div`
  width: 40%;
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const DownloadContainer = styled.div`
  width: 12%;
  flex: 0 0 auto;
  padding: 0 10px;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
  .kb {
    font-size: 11px;
    font-weight: ${fonts.semiBold};
    background: ${colors.lighterGrey};
    color: ${colors.paleGrey};
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
  }
`;

const ReportsListHeader = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
  span {
    cursor: pointer;
  }
`;

const ReportsListOptions = styled.div`
  min-height: 180px;
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -20px 20px 50px 20px;
  }
  margin: -25px 40px 30px 40px;
`;

const FiltersContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${FilterContainer} {
    @media (max-width: 800px) {
      margin-bottom: 8px;
    }
  }
`;

const Unlimited = styled.div`
  font-size: 13px;
  padding: 5px 0;
  font-style: italic;
  color: ${colors.paleGrey};
  a {
    color: ${buttonColors.secondary};
  }
`;

const ActionRow = styled.div`
  @media ${maxDevice.sideNav} {
    button {
      font-size: 10px;
    }
  }
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 12px 0 14px 0;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const ReportsListContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 60px;
`;

const Empty = styled.div`
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

HouseholdReportsArchive.propTypes = {
  householdId: PropTypes.string,
};

export default HouseholdReportsArchive;
