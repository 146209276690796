import { DashboardContainer } from '../containers';
import { UpdatePageTitle, CloseDropdowns } from '../utils';
import { en_us } from '../utils/locales';

const Dashboard = () => {
  UpdatePageTitle('Dashboard');
  CloseDropdowns();

  return <DashboardContainer content={en_us.dashboard.content} />;
};

export default Dashboard;
