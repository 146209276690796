/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PageHeader, EmptyImage, PageFooter } from '../../components';
import { noReports } from '../../assets';
import { PageContent } from '../../styles/library/layoutStyles';
import {
  maxDevice,
  colors,
  messageColors,
  fonts,
} from '../../styles/variables';
import {
  UpdatePageTitle,
  kffPanelOptions,
  FormatCurrency,
  headerContent,
  IsNegative,
} from '../../utils';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';

const HouseholdKeyFinancials = () => {
  UpdatePageTitle('KFFs');

  const { householdKffs, currency, customLDD, isStencil } = useSelector(
    (state) => ({
      householdKffs: state.households.householdKffs,
      currency: state.households.householdCurrency,
      customLDD: state.user.customLDD,
      isStencil: state.households.isStencil,
    })
  );
  const [loadedKffs, setLoadedKffs] = useState(false);

  useEffect(() => {
    if (householdKffs) {
      setLoadedKffs(true);
    }
  }, [householdKffs]);

  const generateValue = (item) => {
    let value = householdKffs[item.key];
    if (item.type === 'text') {
      return 'None';
    }
    if (item.type === 'currency') {
      return FormatCurrency(value, currency);
    }
    if (item.type === 'percent') {
      return `${value} %`;
    }
    return value;
  };

  const generateWidth = (item) => {
    if (item.type === 'currency') {
      return '70px';
    }
    if (item.type === 'percent') {
      return '40px';
    }
    if (item.type === 'number') {
      return '30px';
    }
    return '60px';
  };

  const generatePanelItem = (panel, index) => {
    return (
      <PanelContainer key={index}>
        <PanelHeader>
          <Heading>{panel.heading}</Heading>
        </PanelHeader>
        <PanelContent>
          {panel.options.map((option, index) => {
            return (
              <OptionRow key={index}>
                <OptionLabel>{option.label}</OptionLabel>
                <OptionValue
                  style={
                    loadedKffs
                      ? {
                          color: IsNegative(householdKffs[option.key])
                            ? messageColors.error
                            : null,
                        }
                      : null
                  }
                >
                  {loadedKffs ? (
                    generateValue(option)
                  ) : (
                    <LoadingPlaceholder
                      margin="0"
                      height="16px"
                      width={generateWidth(option)}
                    />
                  )}
                </OptionValue>
              </OptionRow>
            );
          })}
        </PanelContent>
      </PanelContainer>
    );
  };

  return (
    <PageContainer>
      <PageHeader content={{ ...headerContent.kffs }} />
      {isStencil ? (
        <>
          <EmptyImage
            imageSrc={noReports}
            imageName="No Reports"
            text="Reports are not available for Stencil Households"
          />
        </>
      ) : (
        <PageContent>
          <PanelsContainer>
            <LeftContainer>
              {kffPanelOptions.map((panel, index) => {
                if (panel.side === 'left') {
                  return generatePanelItem(panel, index);
                }
              })}
            </LeftContainer>
            <RightContainer>
              {kffPanelOptions.map((panel, index) => {
                if (panel.side === 'right') {
                  return generatePanelItem(panel, index);
                }
              })}
            </RightContainer>
          </PanelsContainer>
          {customLDD?.disclaimer && (
            <PageFooter footerValue={customLDD?.disclaimer} />
          )}
        </PageContent>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const LeftContainer = styled.div`
  @media ${maxDevice.laptop} {
    flex: 1 1 auto;
    margin-right: 0px;
  }
  flex: 1 1 50%;
  margin-right: 15px;
`;

const RightContainer = styled.div`
  @media ${maxDevice.laptop} {
    flex: 1 1 auto;
    margin-left: 0px;
    width: 100%;
  }
  flex: 1 1 50%;
  margin-left: 15px;
`;

const PanelContainer = styled.div`
  border: 1px solid ${colors.paleGrey};
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  /* flex: 1 1 600px; */
`;

const PanelHeader = styled.div`
  @media ${maxDevice.mobileL} {
    font-size: 12px;
  }
  background: ${colors.lightGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  position: relative;
  text-align: left;
  font-size: 14px;
`;

const Heading = styled.p`
  flex: 1 1 auto;
  font-weight: ${fonts.semiBold};
`;

const PanelContent = styled.div`
  background: white;
  padding: 5px 15px;
`;

const OptionRow = styled.div`
  @media ${maxDevice.mobileL} {
    font-size: 11px;
  }
  background: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  font-size: 13px;
  border-bottom: 1px solid ${colors.lightGrey};
  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

const OptionLabel = styled.span`
  flex: 1 1 auto;
`;

const OptionValue = styled.span`
  flex: 0 0 auto;
  margin: 0 10px;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  &:last-child {
    margin-right: 0;
  }
`;

const PanelsContainer = styled.div`
  @media ${maxDevice.laptop} {
    flex-wrap: wrap;
  }
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
`;

export default HouseholdKeyFinancials;
