import { MarkerTypes as MARKER } from '../actions';
import { GetCustomPenColor, UpdateCustomPenColor } from '../../utils';

const initialState = {
  showingCanvas: false,
  usingBlackMarker: false,
  usingCustomMarker: false,
  usingRedMarker: false,
  usingSelect: false,
  showingPalette: false,
  customPenColor: GetCustomPenColor(),
};

const marker = (state = initialState, action) => {
  switch (action.type) {
    case MARKER.TOGGLE_BLACK_MARKER:
      return {
        ...state,
        usingBlackMarker: action.payload,
        showingCanvas: action.payload,
        usingRedMarker: false,
        usingSelect: false,
        usingCustomMarker: false,
        showingPalette: false,
      };
    case MARKER.TOGGLE_RED_MARKER:
      return {
        ...state,
        usingRedMarker: action.payload,
        showingCanvas: action.payload,
        usingBlackMarker: false,
        usingSelect: false,
        usingCustomMarker: false,
        showingPalette: false,
      };
    case MARKER.TOGGLE_CUSTOM_MARKER:
      return {
        ...state,
        usingCustomMarker: action.payload,
        showingCanvas: action.payload,
        usingRedMarker: false,
        usingBlackMarker: false,
        usingSelect: false,
        showingPalette: false,
      };
    case MARKER.TOGGLE_SELECT:
      return {
        ...state,
        usingSelect: action.payload,
        showingCanvas: action.payload,
        usingBlackMarker: false,
        usingRedMarker: false,
        usingCustomMarker: false,
        showingPalette: false,
      };
    case MARKER.TOGGLE_PALETTE:
      return {
        ...state,
        showingPalette: action.payload,
        showingCanvas: true,
        usingBlackMarker: false,
        usingRedMarker: false,
        usingCustomMarker: true,
        usingSelect: false,
      };
    case MARKER.DELETE_CANVAS:
      return {
        ...state,
        showingCanvas: false,
        usingBlackMarker: false,
        usingRedMarker: false,
        usingSelect: false,
        usingCustomMarker: false,
        showingPalette: false,
      };
    case MARKER.CUSTOM_PEN_COLOR:
      return {
        ...state,
        customPenColor: UpdateCustomPenColor(action.payload),
      };
    default:
      return state;
  }
};

export default marker;
