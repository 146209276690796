import styled from 'styled-components';
import { colors, fonts } from '../variables';
import { FlexCenterAll, FlexCenterStart } from './layoutStyles';

export const LoadingContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const ExpandableItem = styled.div`
  /* border: 1px solid green; */
  filter: ${(props) =>
    props.$inFocusMode ? (props.$isFocused ? null : `grayscale(1)`) : null};
  opacity: ${(props) =>
    props.$inFocusMode ? (props.$isFocused ? null : `.5`) : null};
`;

export const ExpandHeadingContainer = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};

  padding: 15px 18px;
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  ${FlexCenterStart};
  position: relative;
  z-index: 1;
  box-shadow: ${(props) =>
    props.$isExpanded ? `0px 4px 10px -4px rgba(0, 0, 0, 0.1) ` : null};
`;

export const ExpandHeadingLabel = styled.div`
  ${FlexCenterStart};
  flex: 1 1 auto;
  cursor: pointer;
`;

export const LabelText = styled.p`
  ${FlexCenterStart};
  flex: 1 1 auto;
`;

export const CountLabel = styled.span`
  background: ${colors.lightGrey};
  color: ${colors.darkGrey};
  margin: 0 5px;
  padding: 5px;
  border-radius: 20px;
  min-width: 18px;
  height: 18px;
  opacity: 0.7;
  font-size: 13px;
  ${FlexCenterAll};
`;

export const ExpandIcon = styled.button`
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-left: 5px;
  margin: 0 -5px 0 5px;
  ${FlexCenterAll};
  &:hover {
    background: ${colors.lighterGrey};
  }
  img {
    width: 15px;
    height: 15px;
    transform: ${(props) => (props.$isExpanded ? 'rotate(180deg)' : null)};
  }
`;

export const ExpandedDetails = styled.div`
  background: ${colors.lighterGrey};
  padding: ${(props) => (props.$padding ? props.$padding : '20px')};
  font-size: 12px;
  position: relative;
`;

export const EmptyContainer = styled.div`
  ${FlexCenterAll};
  flex-direction: column;
`;

export const NotFoundImage = styled.img`
  max-height: 430px;
  max-width: 280px;
  margin: 40px 20px 20px 20px;
`;

export const NotFoundText = styled.p`
  margin: ${(props) => (props.$margin ? props.$margin : '5px 20px 15px 20px')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '20px')};
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;
