/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { GenerateInput, Button, Error, DetectModalKeys } from '..';
import {
  createTargetMapInstrument,
  updateTargetMapInstrument,
  toggleShowDeleteModal,
} from '../../store/actions';
import { timesWhite, trashDark } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
  ModalIcon,
} from '../../styles/library/modalStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  MatchSelectItem,
  CheckHasError,
  GenerateError,
  UseHandleInstrumentError,
  CreateAmountPlaceholder,
  CheckOverwritten,
  HtmlToString,
  DetectEnterKeyPress,
  DateFormat,
} from '../../utils';

const CustomAssetModal = ({
  hide,
  isNew,
  householdId,
  instrumentInfo,
  targetMapId,
  currency,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    currentHousehold,
    targetMapInstrumentError,
    instrumentDateReference,
    preferences,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    targetMapInstrumentError: state.targetMaps.targetMapInstrumentError,
    instrumentDateReference: state.configs.instrumentDateReference,
    preferences: state.user.user.preferences,
  }));
  const [selectedMember, setSelectedMember] = useState();
  const [dateType, setDateType] = useState({ label: 'Age', value: 'age' });
  const [currentAsset, setCurrentAsset] = useState({
    name: '',
    description: '',
    amount: '',
    start_age: '',
    start_date: '',
    tax_rate: '',
    is_future_value: false,
    is_active: true,
  });
  const [membersList, setMembersList] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();
  const [errorTitle, setErrorTitle] = useState();

  UseHandleInstrumentError(
    targetMapInstrumentError,
    setButtonLoading,
    setModalError,
    setModalErrors,
    setErrorTitle
  );

  useEffect(() => {
    if (instrumentInfo) {
      setDateType(
        MatchSelectItem(instrumentDateReference, instrumentInfo.date_reference)
      );
      setSelectedMember(instrumentInfo.memberObj);
      setCurrentAsset(instrumentInfo);
    }
  }, [instrumentInfo, currentHousehold]);

  useEffect(() => {
    if (isNew) {
      setSelectedMember(membersList[0]);
    }
  }, [isNew, membersList]);

  useEffect(() => {
    if (
      currentHousehold &&
      currentHousehold.hasOwnProperty('householdmembers')
    ) {
      const householdMembers = [...currentHousehold.householdmembers];
      setMembersList(
        householdMembers.filter((mem) => {
          if (mem.member_type === 'member') {
            const updatedMemberName = HtmlToString(mem.name);
            mem.label = `${updatedMemberName} (${mem.age})`;
            mem.value = mem.id;
            return mem;
          }
        })
      );
    }
  }, [currentHousehold]);

  const checkHasValue = (value) => {
    if (value === '' || value === null || value === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const saveOnEnter = () => {
    if (!buttonLoading) {
      return saveAsset();
    }
  };

  const saveAsset = () => {
    setErrorTitle();

    // if (!checkHasValue(currentAsset.amount)) {
    //   setModalErrors({
    //     ...modalErrors,
    //     amount: ['A valid integer is required.'],
    //   });
    //   return setModalError('A valid integer is required.');
    // }
    let amountValue = parseInt(currentAsset.amount) || 0;

    let taxRate = 0;
    if (currentAsset.tax_rate !== null && currentAsset.tax_rate !== '') {
      taxRate = parseInt(currentAsset.tax_rate);
    }

    let instrumentObject = {
      name: currentAsset.name,
      description: currentAsset.description,
      member: selectedMember.value,
      amount: amountValue,
      is_future_value: currentAsset.is_future_value,
      date_reference: dateType.value,
      tax_rate: taxRate,
      is_active: currentAsset.is_active,
    };

    if (dateType.value === 'age') {
      if (checkHasValue(currentAsset.start_age)) {
        instrumentObject.start_age = currentAsset.start_age;
      }
    }

    if (dateType.value === 'date') {
      if (checkHasValue(currentAsset.start_date)) {
        instrumentObject.start_date = DateFormat(
          currentAsset.start_date,
          'YYYY-MM-DD'
        );
      }
    }

    setButtonLoading(true);
    if (isNew) {
      dispatch(
        createTargetMapInstrument(
          householdId,
          targetMapId,
          'asset',
          instrumentObject
        )
      );
    } else {
      dispatch(
        updateTargetMapInstrument(
          householdId,
          targetMapId,
          'asset',
          currentAsset.id,
          instrumentObject
        )
      );
    }
  };

  const updateCurrentAsset = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors();
    if (isSelect) {
      setCurrentAsset({
        ...currentAsset,
        [fieldName]: e,
      });
    } else {
      setCurrentAsset({
        ...currentAsset,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const checkDisabled = () => {
    if (instrumentInfo) {
      if (instrumentInfo.hasOwnProperty('instrument')) {
        if (
          instrumentInfo.instrument === null ||
          instrumentInfo.instrument === undefined ||
          instrumentInfo.instrument === ''
        ) {
          return false;
        }
        return true;
      }
    } else {
      return false;
    }
  };

  const formatFunction = (values, input) => {
    setModalError('');
    setModalErrors();
    let setValue;
    if (values.hasOwnProperty('floatValue')) {
      setValue = values.floatValue;
    } else if (values.currentTarget?.value) {
      setValue = values.currentTarget.value;
    }

    if (setValue === 'NaN') {
      setValue = '';
    }

    setCurrentAsset({
      ...currentAsset,
      [input.name]: setValue,
    });
  };

  const essentialInputs = [
    {
      type: 'text',
      label: 'Name',
      name: 'name',
      required: true,
      placeholder: 'Name',
      value: currentAsset.name,
      width: '48%',
      isVisible: true,
      disabled: checkDisabled(),
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'name'),
      errorMessage: GenerateError(modalErrors, 'name'),
    },
    {
      type: 'text',
      label: 'Description',
      maxLength: '120',
      name: 'description',
      width: '48%',
      required: false,
      placeholder: 'Description',
      isVisible: true,
      disabled: checkDisabled(),
      value: currentAsset.description,
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'description'),
      errorMessage: GenerateError(modalErrors, 'description'),
    },
    {
      type: 'select',
      label: 'Link to Member',
      name: 'member',
      required: false,
      placeholder: 'Link to Member',
      value: selectedMember,
      options: membersList,
      width: '100%',
      isVisible: true,
      onChange: (e) => setSelectedMember(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'member'),
      errorMessage: GenerateError(modalErrors, 'member'),
    },
    {
      type: 'calculatorCurrency',
      label: CheckOverwritten(isNew, instrumentInfo)
        ? 'Amount Available (Overwritten)'
        : 'Amount Available',
      maxLength: '80',
      name: 'amount',
      width: !checkDisabled() ? '68%' : '100%',
      required: false,
      placeholder: isNew
        ? 'Amount Available'
        : CreateAmountPlaceholder(instrumentInfo, currency, 'Amount Available'),
      isVisible: true,
      value: currentAsset.amount,
      onChange: (e, input) => formatFunction(e, input),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'amount'),
      errorMessage: GenerateError(modalErrors, 'amount'),
    },
    {
      type: 'checkbox',
      label: 'Future Value',
      name: 'is_future_value',
      required: false,
      width: '28%',
      value: currentAsset.is_future_value,
      isVisible: !checkDisabled(),
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_future_value: !currentAsset.is_future_value,
        }),
    },
    {
      type: 'select',
      label: 'Date Reference',
      name: 'date_reference',
      width: '48%',
      required: false,
      placeholder: 'Date Reference',
      value: dateType,
      options: instrumentDateReference,
      isVisible: true,
      onChange: (e) => setDateType(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'date_reference'),
      errorMessage: GenerateError(modalErrors, 'date_reference'),
    },
    {
      type: 'number',
      label: 'Start Age',
      maxLength: '80',
      name: 'start_age',
      width: '48%',
      required: false,
      placeholder: 'Start Age',
      value: currentAsset.start_age,
      isVisible: dateType.value === 'age',
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'start_age'),
      errorMessage: GenerateError(modalErrors, 'start_age'),
    },
    {
      type: 'date',
      label: 'Start Date',
      name: 'start_date',
      width: '48%',
      required: false,
      placeholder: preferences?.date_format.toLowerCase(),
      dateFormat: preferences?.date_format,
      value: currentAsset.start_date,
      isVisible: dateType.value === 'date',
      onChange: (e) => updateCurrentAsset(e, true, 'start_date'),
      hasError: CheckHasError(modalErrors, 'start_date'),
      errorMessage: GenerateError(modalErrors, 'start_date'),
    },
    {
      type: 'number',
      label: 'Tax Rate',
      maxLength: '2',
      name: 'tax_rate',
      width: '48%',
      required: false,
      hasSuffix: true,
      suffixValue: '%',
      placeholder: 'Tax Rate',
      tooltip: `Percentage lost to taxes.`,
      value: currentAsset.tax_rate,
      isVisible: true,
      onChange: (e) => updateCurrentAsset(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'tax_rate'),
      errorMessage: GenerateError(modalErrors, 'tax_rate'),
    },
    {
      type: 'checkbox',
      label: 'Included in this Target-Map',
      name: 'is_active',
      required: false,
      width: '48%',
      value: currentAsset.is_active,
      isVisible: true,
      onChange: () =>
        setCurrentAsset({
          ...currentAsset,
          is_active: !currentAsset.is_active,
        }),
    },
  ];

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={saveOnEnter} onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {isNew
                ? 'Add Target-Map Custom Asset'
                : 'Edit Target-Map Custom Asset'}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <TabContent>
              {essentialInputs.map((input, index) => {
                return GenerateInput(input, index, currency);
              })}
            </TabContent>
            <ButtonsRowContainer>
              {isNew || currentAsset.instrument !== null ? null : (
                <ModalIcon
                  src={trashDark}
                  alt="delete"
                  data-image="delete"
                  onClick={() =>
                    dispatch(
                      toggleShowDeleteModal(
                        true,
                        'targetmapAsset',
                        currentAsset.name,
                        targetMapId,
                        currentAsset.id
                      )
                    )
                  }
                />
              )}
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={isNew ? 'Create' : 'Update'}
                  onClick={() => saveAsset()}
                  showLoading={buttonLoading}
                  loadingText={isNew ? 'Creating' : 'Saving'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} title={errorTitle} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

CustomAssetModal.propTypes = {
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  householdId: PropTypes.string,
  instrumentInfo: PropTypes.object,
  targetMapId: PropTypes.string,
  currency: PropTypes.object,
};

export default CustomAssetModal;
