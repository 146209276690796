import { KeyFinancialFiguresContainer } from '../../containers';
import { UpdatePageTitle, CloseDropdowns } from '../../utils';

const KeyFinancialFigures = () => {
  UpdatePageTitle('Key Financial Figures');
  CloseDropdowns();

  return <KeyFinancialFiguresContainer />;
};

export default KeyFinancialFigures;
