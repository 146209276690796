import { assetMapColors } from '../../styles/variables';

export const CreateWestLines = (setNorthWestWide, setSouthWestWide) => {
  const northWestContainer = document.getElementById('north-west-container');
  const northWestLineContainer = document.getElementById(
    'north-west-line-container'
  );
  const northWestLine = document.getElementById('north-west-line');
  const southWestContainer = document.getElementById('south-west-container');
  const southWestLineContainer = document.getElementById(
    'south-west-line-container'
  );
  const southWestLine = document.getElementById('south-west-line');
  let northTop = 0;
  let southBottom = 0;
  if (northWestContainer && northWestLine) {
    const rects = northWestContainer?.getClientRects();
    if (rects) {
      const top = rects[0].height / 2;
      northWestLineContainer.style.height = `${rects[0].height}px`;
      northWestLine.style.top = `${top}px`;
      northTop = top;
    }
  }
  if (southWestContainer && southWestLine) {
    const rects = southWestContainer?.getClientRects();
    if (rects) {
      const bottom = rects[0].height / 2;
      southWestLineContainer.style.height = `${rects[0].height}px`;
      southWestLine.style.bottom = `${bottom}px`;
      southBottom = bottom;
    }
  }
  createWestVerticalLines(
    northTop,
    southBottom,
    setNorthWestWide,
    setSouthWestWide
  );
};

const createWestVerticalLines = (
  top,
  bottom,
  setNorthWestWide,
  setSouthWestWide
) => {
  const northWestLine = document.getElementById('north-west-line');
  const northWestTotalLine = document.getElementById('north-west-total-line');
  const southWestLine = document.getElementById('south-west-line');
  const southWestTotalLine = document.getElementById('south-west-total-line');
  const southWestContainer = document.getElementById('south-west-container');
  let northPosition = 'above';
  let southPosition = 'below';
  if (northWestLine && northWestTotalLine) {
    const northLineRects = northWestLine?.getClientRects();
    const northTotalRecs = northWestTotalLine?.getClientRects();
    if (northLineRects && northTotalRecs) {
      const height = northTotalRecs[0].y - northLineRects[0].y;
      if (height < 0) {
        northWestLine.style.height = `${Math.abs(height)}px`;
        northWestLine.style.borderBottom = `thin solid ${assetMapColors.connector}`;
        northWestLine.style.borderTop = `transparent`;
        northWestLine.style.borderTopColor = `transparent`;
        northWestLine.style.bottom = `${top - 1}px`;
        northWestLine.style.top = ``;
        northPosition = 'below';
      } else {
        northWestLine.style.height = `${height - 1}px`;
        northWestLine.style.transform = '';
        northWestLine.style.borderBottom = `transparent`;
        northWestLine.style.borderTop = `thin solid ${assetMapColors.connector}`;
        northWestLine.style.top = `${top}px`;
        northWestLine.style.bottom = ``;
        northPosition = 'above';
      }
    }
  }
  if (southWestLine && southWestTotalLine) {
    const totalRects = southWestTotalLine?.getClientRects();
    const containerRects = southWestContainer?.getClientRects();
    if (containerRects && totalRects) {
      const containerLineY = containerRects[0].y + containerRects[0].height / 2;
      if (containerLineY < totalRects[0].y) {
        const height = totalRects[0].y - containerLineY;
        southWestLine.style.height = `${height}px`;
        southWestLine.style.borderTop = `thin solid ${assetMapColors.connector}`;
        southWestLine.style.borderBottom = `transparent`;
        southWestLine.style.top = `${bottom}px`;
        southWestLine.style.bottom = ``;
        southWestLine.style.width = '30px';
        southPosition = 'above';
      } else {
        const height = containerLineY - totalRects[0].y;
        southWestLine.style.height = `${height - 1}px`;
        southWestLine.style.transform = '';
        southWestLine.style.borderTop = `transparent`;
        southWestLine.style.borderBottom = `thin solid ${assetMapColors.connector}`;
        southWestLine.style.bottom = `${bottom - 1}px`;
        southWestLine.style.top = ``;
        southPosition = 'below';
      }
    }
  }
  if (northWestLine) {
    if (northPosition === 'above' && southPosition === 'above') {
      setNorthWestWide(true);
    } else {
      setNorthWestWide(false);
    }
    northWestLine.style.width =
      northPosition === 'above' && southPosition === 'above' ? '60px' : '30px';
  }
  if (southWestLine) {
    if (northPosition === 'below' && southPosition === 'below') {
      setSouthWestWide(true);
    } else {
      setSouthWestWide(false);
    }
    southWestLine.style.width =
      northPosition === 'below' && southPosition === 'below' ? '60px' : '30px';
  }
};

export const CreateEastLines = (setNorthEastWide, setSouthEastWide) => {
  const northEastContainer = document.getElementById('north-east-container');
  const northEastLineContainer = document.getElementById(
    'north-east-line-container'
  );
  const northEastLine = document.getElementById('north-east-line');
  const southEastContainer = document.getElementById('south-east-container');
  const southEastLineContainer = document.getElementById(
    'south-east-line-container'
  );
  const southEastLine = document.getElementById('south-east-line');
  let northTop = 0;
  let southBottom = 0;
  if (northEastContainer && northEastLine) {
    const rects = northEastContainer?.getClientRects();
    if (rects) {
      const top = rects[0].height / 2;
      northEastLineContainer.style.height = `${rects[0].height}px`;
      northEastLine.style.top = `${top}px`;
      northTop = top;
    }
  }
  if (southEastContainer && southEastLine) {
    const rects = southEastContainer?.getClientRects();
    if (rects) {
      const bottom = rects[0].height / 2;
      southEastLineContainer.style.height = `${rects[0].height}px`;
      southEastLine.style.bottom = `${bottom}px`;
      southBottom = bottom;
    }
  }
  createEastVerticalLines(
    northTop,
    southBottom,
    setNorthEastWide,
    setSouthEastWide
  );
};

const createEastVerticalLines = (
  top,
  bottom,
  setNorthEastWide,
  setSouthEastWide
) => {
  const northEastLine = document.getElementById('north-east-line');
  const northEastLineTotal = document.getElementById('north-east-total-line');
  const southEastLine = document.getElementById('south-east-line');
  const southEastTotalLine = document.getElementById('south-east-total-line');
  const southEastContainer = document.getElementById('south-east-container');
  let northPosition = 'above';
  let southPosition = 'below';

  if (northEastLine && northEastLineTotal) {
    const lineRects = northEastLine?.getClientRects();
    const totalRects = northEastLineTotal?.getClientRects();
    if (lineRects && totalRects) {
      const height = totalRects[0].y - lineRects[0].y;
      if (height < 0) {
        northEastLine.style.height = `${Math.abs(height)}px`;
        northEastLine.style.borderBottom = `thin solid ${assetMapColors.connector}`;
        northEastLine.style.borderTop = `transparent`;
        northEastLine.style.borderTopColor = `transparent`;
        northEastLine.style.bottom = `${top - 1}px`;
        northEastLine.style.top = ``;
        northPosition = 'below';
      } else {
        northEastLine.style.height = `${height - 1}px`;
        northEastLine.style.transform = '';
        northEastLine.style.borderBottom = `transparent`;
        northEastLine.style.borderTop = `thin solid ${assetMapColors.connector}`;
        northEastLine.style.top = `${top}px`;
        northEastLine.style.bottom = ``;
        northPosition = 'above';
      }
    }
  }
  if (southEastLine && southEastTotalLine) {
    const totalRects = southEastTotalLine?.getClientRects();
    const containerRects = southEastContainer?.getClientRects();
    if (containerRects && totalRects) {
      const containerLineY = containerRects[0].y + containerRects[0].height / 2;
      if (containerLineY < totalRects[0].y) {
        const height = totalRects[0].y - containerLineY;
        southEastLine.style.height = `${height}px`;
        southEastLine.style.borderTop = `thin solid ${assetMapColors.connector}`;
        southEastLine.style.borderBottom = `transparent`;
        southEastLine.style.top = `${bottom}px`;
        southEastLine.style.bottom = ``;
        southPosition = 'above';
      } else {
        const height = containerLineY - totalRects[0].y;
        southEastLine.style.height = `${height - 1}px`;
        southEastLine.style.transform = '';
        southEastLine.style.borderTop = `transparent`;
        southEastLine.style.borderBottom = `thin solid ${assetMapColors.connector}`;
        southEastLine.style.bottom = `${bottom - 1}px`;
        southEastLine.style.top = ``;
        southPosition = 'below';
      }
    }
  }
  if (northEastLine) {
    if (northPosition === 'above' && southPosition === 'above') {
      setNorthEastWide(true);
    } else {
      setNorthEastWide(false);
    }
    northEastLine.style.width =
      northPosition === 'above' && southPosition === 'above' ? '60px' : '30px';
  }
  if (southEastLine) {
    if (northPosition === 'below' && southPosition === 'below') {
      setSouthEastWide(true);
    } else {
      setSouthEastWide(false);
    }
    southEastLine.style.width =
      northPosition === 'below' && southPosition === 'below' ? '60px' : '30px';
  }
};
