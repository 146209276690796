/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  ListItem,
  LoadingAnimation,
  Panel,
  ReportListItem,
} from '../../components';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { colors, maxDevice, fonts } from '../../styles/variables';
import {
  ErrorNotification,
  FormatCurrency,
  GenerateAge,
  HtmlToString,
  signalLabels,
} from '../../utils';
import { Tooltip } from 'react-tippy';
import { emailWhite } from '../../assets';
import {
  getHouseholdReports,
  getMemberInfo,
  toggleShowEntityModal,
  toggleShowMemberModal,
} from '../../store/actions';

const ConsumerHouseholdSummaryNew = ({
  canEdit,
  viewMembers,
  viewSignals,
  viewReports,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const householdId = params?.id;
  const {
    assetMapImage,
    currency,
    currentHousehold,
    members,
    kffs,
    householdSignals,
    reports,
    allMemberCategories,
    loadedHouseholdFields,
  } = useSelector((state) => ({
    assetMapImage: state.households.amrImage,
    currency: state.households.householdCurrency,
    currentHousehold: state.households.currentHousehold,
    members: state.households.currentHousehold.householdmembers,
    reports: state.households.householdReports,
    kffs: state.households.householdKffs,
    householdSignals: state.households.householdSignals,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
  }));
  const [loadedKffs, setLoadedKffs] = useState(false);
  const [loadedHousehold, setLoadedHousehold] = useState(false);
  const [advisor, setAdvisor] = useState({
    name: '',
    email: '',
    loaded: false,
  });
  const [membersList, setMembersList] = useState([]);
  const [signals, setSignals] = useState([]);
  const [panelOptions, setPanelOptions] = useState([
    {
      heading: 'Members',
      value: 'members',
      link: `../relationships`,
      showIcon: true,
      isVisible: false,
    },
    {
      heading: 'Signals',
      value: 'signals',
      link: `../signals`,
      showIcon: true,
      isVisible: false,
    },
    {
      heading: 'Reports',
      value: 'reports',
      link: '../reports',
      isVisible: false,
      showIcon: true,
    },
  ]);

  useEffect(() => {
    if (currentHousehold?.id) {
      setLoadedHousehold(true);
    }
    return () => {
      setLoadedHousehold(false);
    };
  }, [currentHousehold?.id]);

  useEffect(() => {
    if (assetMapImage) {
      if (assetMapImage.hasOwnProperty('is_error') && assetMapImage.is_error) {
        ErrorNotification('Error Generating AMR Image Preview', 5);
      }
    }
  }, [assetMapImage]);

  useEffect(() => {
    if (currentHousehold?.advisors && currentHousehold?.id) {
      let advisor = null;
      const advisors = currentHousehold?.advisors;
      if (advisors && Array.isArray(advisors)) {
        advisor = advisors[0];
      }
      setAdvisor({ name: advisor?.name, email: advisor?.email, loaded: true });
    }
  }, [currentHousehold?.advisors, currentHousehold?.id]);

  useEffect(() => {
    if (kffs && currentHousehold?.id) {
      setLoadedKffs(true);
    }
  }, [kffs, currentHousehold?.id]);

  useEffect(() => {
    if (members) {
      setMembersList(members);
    }
  }, [members]);

  useEffect(() => {
    if (loadedHousehold) {
      setPanelOptions(
        panelOptions.map((panel) => {
          if (panel.value === 'members') {
            panel.isVisible = viewMembers;
          } else if (panel.value === 'signals') {
            panel.isVisible = viewSignals;
          } else if (panel.value === 'reports') {
            panel.isVisible = viewReports;
          }
          return panel;
        })
      );
    }
  }, [loadedHousehold]);

  useEffect(() => {
    if (viewReports) {
      dispatch(getHouseholdReports(householdId, { limit: 10 }, true));
    }
  }, [viewReports, householdId]);

  useEffect(() => {
    if (householdSignals) {
      const signalOptions = householdSignals?.signals.map((signal) => {
        const matching = signalLabels.find((ind) => ind.key === signal.name);
        return { ...signal, ...matching };
      });
      signalOptions.sort((a, b) => a.order - b.order);
      setSignals(signalOptions);
    }
  }, [householdSignals]);

  const generateEmptyListItem = (item, index) => {
    return (
      <ListItem
        index={index || 0}
        key={index || 0}
        item={{}}
        loading={true}
        heading={item.heading}
        showIcon={item.showIcon}
        showImage={item.showImage}
      />
    );
  };

  const generateAssetMapPanel = (item) => {
    if (assetMapImage) {
      return (
        <ListItem
          key={0}
          index={0}
          item={{
            src: assetMapImage.url,
            link: `/household/${householdId}/assetmap`,
          }}
          value={item.value}
          heading={item.heading}
          showIcon={false}
          showImage={item.showImage}
        />
      );
    } else {
      return generateEmptyListItem(item);
    }
  };

  const formatKffValue = (number, decPlaces = 0) => {
    const abbrev = ['K', 'M', 'B', 'T'];
    //If its over a million make it show two decimal place
    const decimalGroups = ['M', 'B', 'T'];
    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
      if (decimalGroups.includes(abbrev[i])) {
        decPlaces = 2;
      } else {
        decPlaces = 1;
      }
      decPlaces = Math.pow(10, decPlaces);
      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10, (i + 1) * 3);
      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round((number * decPlaces) / size) / decPlaces;
        // Handle special case where we round up to the next abbreviation
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }
        // Add the letter for the abbreviation
        number += abbrev[i];
        break;
      }
    }
    if (currency.hasOwnProperty('symbol')) {
      if (currency?.symbol === '$' || currency?.code === 'USD') {
        return `${currency?.symbol}${number}`;
      }
    }
    // if (
    //   currency.hasOwnProperty('symbol') &&
    //   currency.hasOwnProperty('symbol_location')
    // ) {
    //   if (currency?.symbol_location === 'prefix') {
    //     return `${currency?.symbol}${number}`;
    //   } else {
    //     return `${number}${currency?.symbol}`;
    //   }
    // }
    return number;
  };

  const generateKffValue = (value, color, theme) => {
    return (
      <KffValue>
        {loadedKffs ? (
          <Tooltip
            title={FormatCurrency(value, currency)}
            position="top"
            trigger="mouseenter click"
            delay={100}
            arrow={true}
            arrowSize={'small'}
            animation={'fade'}
            animateFill={false}
            size={'small'}
            theme={theme}
          >
            {formatKffValue(value)}
          </Tooltip>
        ) : (
          <LoadingAnimation dots={true} color={color} margin="0 auto" />
        )}
      </KffValue>
    );
  };

  const generateKffPanel = () => {
    return (
      <KFFContainer>
        <ColorBlock $color={colors.blue} $bg="rgb(194,212,230)">
          <KffLabel>Income</KffLabel>
          {generateKffValue(kffs?.incomes_amount, colors.blue, 'blue')}
        </ColorBlock>
        <ColorBlock $color={colors.pink} $bg="rgb(249,218,231)">
          <KffLabel>Retirement Assets</KffLabel>
          {generateKffValue(
            kffs?.assets_retirement_amount,
            colors.pink,
            'pink'
          )}
        </ColorBlock>
        <ColorBlock $color={colors.green} $bg="rgb(235,245,222)">
          <KffLabel>Non-Retirement Assets</KffLabel>
          {generateKffValue(
            kffs?.assets_nonretirement_amount,
            colors.green,
            'green'
          )}
        </ColorBlock>
        <ColorBlock $color={colors.orange} $bg="rgb(248,229,204)">
          <KffLabel>Liabilities</KffLabel>
          {generateKffValue(kffs?.liabilities_amount, colors.orange, 'orange')}
        </ColorBlock>
        <ColorBlock $color={colors.lightBlue} $bg="rgb(223,242,246)">
          <KffLabel>Life Insurance</KffLabel>
          {generateKffValue(
            kffs?.insurances_life_amount,
            colors.lightBlue,
            'light-blue'
          )}
        </ColorBlock>
      </KFFContainer>
    );
  };

  const memberOnClick = (member) => {
    dispatch(getMemberInfo(currentHousehold.id, member.id, true));
    dispatch(toggleShowMemberModal(true, true));
  };

  const entityOnClick = (member) => {
    dispatch(getMemberInfo(currentHousehold.id, member.id, true));
    dispatch(toggleShowEntityModal(true, true));
  };

  const generateMembers = () => {
    if (membersList?.length) {
      return membersList.map((member) => {
        member.label = HtmlToString(member.name);
        let memCategory = member.category;
        const category = allMemberCategories.find(
          (cat) => cat.value === member.category
        );
        if (category?.label) {
          memCategory = category?.label;
        }
        if (member.member_type === 'member') {
          let age = GenerateAge(member.date_of_birth);
          member.value = `${memCategory} (${age})`;
          member.textClick = () => memberOnClick(member);
          return member;
        }
        if (member.member_type === 'entity') {
          member.value = memCategory;
          member.textClick = () => entityOnClick(member);
          return member;
        }
        return member;
      });
    } else {
      return [
        {
          label: 'No Members',
          value: `Member`,
          showIcon: false,
          showNew: true,
          onClick: () => dispatch(toggleShowMemberModal(true, false)),
        },
      ];
    }
  };

  const generateSignals = () => {
    if (signals) {
      return signals.reduce((acc, signal) => {
        if (signal.enabled) {
          signal.value = signal.calculated;
          signal.snoozed = signal?.snooze?.snoozed || signal?.snooze?.muted;
          return [...acc, signal];
        }
        return acc;
      }, []);
    } else {
      return [];
    }
  };

  const generateListItems = (item) => {
    if (item.value === 'members') {
      if (loadedHousehold) {
        const formattedMembers = generateMembers();
        if (formattedMembers.length >= 1 && loadedHouseholdFields) {
          return formattedMembers.map((content, index) => {
            return (
              <ListItem
                key={index}
                index={index}
                item={content}
                value={item.value}
                heading={item.heading}
                showNew={content.showNew}
                showIcon={item.showIcon}
                showImage={item.showImage}
              />
            );
          });
        }
      } else {
        return [0, 1, 2].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
    } else if (item.value === 'signals') {
      let generatedSignals = generateSignals();
      if (generatedSignals.length !== 0) {
        return generatedSignals.map((content, index) => {
          return (
            <ListItem
              key={index}
              index={index}
              item={content}
              value={item.value}
              heading={item.heading}
              showIcon={item.showIcon}
              showImage={item.showImage}
            />
          );
        });
      } else if (signals.length >= 1) {
        return (
          <ListItem
            key={0}
            index={0}
            item={{
              label:
                'All signal types have been disabled by your team manager.',
            }}
            value={item.value}
            heading={'Signals'}
            disabled={true}
            showIcon={false}
            showImage={false}
          />
        );
      } else {
        return [0, 1, 2, 3, 4].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
    } else if (item.value === 'reports') {
      if (reports.count === undefined) {
        return [0, 1, 2].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
      if (reports.count > 0) {
        const reportsList = reports.results.slice(0, 10);
        return reportsList.map((report, index) => {
          return (
            <React.Fragment key={index}>
              <ReportListItem
                report={report}
                panel={true}
                borderBottom={index !== reports.count - 1}
              />
            </React.Fragment>
          );
        });
      }
      return (
        <AddReportContainer key={1}>
          <p>There are no Reports for this household.</p>
        </AddReportContainer>
      );
    } else {
      return item.content.map((content, index) => {
        return (
          <ListItem
            key={index}
            index={index}
            item={content}
            value={item.value}
            heading={item.heading}
            showIcon={item.showIcon}
            showImage={item.showImage}
            disabled={true}
          />
        );
      });
    }
  };

  return (
    <PageContainer>
      <HeadingContainer>
        <HouseholdHeadingContainer>
          <Label>Household</Label>
          {currentHousehold?.name ? (
            <Name>{currentHousehold.name}</Name>
          ) : (
            <LoadingPlaceholder
              margin="2px 0 0 0"
              height="20px"
              width="200px"
            />
          )}
        </HouseholdHeadingContainer>
        <AdvisorHeadingContainer>
          <LeftContent>
            <Label>Advisor</Label>
            {advisor?.loaded ? (
              <Name>{advisor?.name || 'Unknown Name'}</Name>
            ) : (
              <LoadingPlaceholder
                margin="2px 0 0 0"
                height="20px"
                width="140px"
              />
            )}
          </LeftContent>
          <RightContent>
            <EmailIconContainer
              href={`mailto:${advisor?.email}`}
              title={`Email: ${advisor?.email}`}
            >
              <img src={emailWhite} alt="email" />
            </EmailIconContainer>
          </RightContent>
        </AdvisorHeadingContainer>
      </HeadingContainer>
      <ColumnsContainer>
        <ColumnContainer>
          <Panel
            item={{
              heading: 'Asset-Map',
              value: 'assetMap',
              link: `../assetmap`,
              isVisible: true,
              showImage: true,
            }}
            transform={generateAssetMapPanel}
            showingMenu={false}
            household={currentHousehold}
          />
          <Panel
            item={{
              heading: 'Key Financials',
              value: 'kffs',
              noLink: true,
              isVisible: true,
            }}
            transform={generateKffPanel}
            showingMenu={false}
            household={currentHousehold}
          />
          <ColumnsContainer>
            {panelOptions.map((item, index) => {
              return (
                item.isVisible && (
                  <ColumnContainer key={index}>
                    <Panel
                      key={index}
                      item={item}
                      transform={generateListItems}
                      household={currentHousehold}
                    />
                  </ColumnContainer>
                )
              );
            })}
          </ColumnsContainer>
        </ColumnContainer>
      </ColumnsContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 15px 12px;
  }
  padding: 30px 40px;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const HeadingContainer = styled.div`
  @media (max-width: 750px) {
    margin-bottom: 15px;
  }
  margin-top: -5px;
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const HouseholdHeadingContainer = styled.div`
  @media (max-width: 720px) {
    justify-content: flex-start;
    flex: 1 1 100%;
    margin-bottom: 10px;
  }
  flex: 0 0 auto;
  margin-right: 10px;
`;

const AdvisorHeadingContainer = styled.div`
  @media (max-width: 720px) {
    justify-content: flex-start;
    flex: 1 1 100%;
    text-align: left;
  }
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
`;

const LeftContent = styled.div`
  @media (max-width: 720px) {
    margin-right: 8px;
  }
  margin-right: 15px;
`;

const Label = styled.p`
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const Name = styled.p`
  font-size: 15px;
  color: ${colors.darkGrey};
`;

const RightContent = styled.div``;

const EmailIconContainer = styled.a`
  @media (max-width: 720px) {
    width: 30px;
    height: 30px;
  }
  width: 40px;
  height: 40px;
  display: block;
  background: ${colors.darkGrey};
  border-radius: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    @media (max-width: 720px) {
      width: 15px;
      height: 15px;
    }
    width: 20px;
    height: 20px;
  }
`;

const KFFContainer = styled.div`
  margin: 5px -5px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`;

const KffLabel = styled.p`
  @media (max-width: 1210px) {
    font-size: 10px;
  }
  @media ${maxDevice.mobileL} {
    font-size: 8px;
  }
  flex: 1 1 auto;
  text-align: center;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
  font-size: 12px;
`;

const KffValue = styled.div`
  @media (max-width: 1210px) {
    font-size: 25px;
  }
  @media ${maxDevice.mobileL} {
    font-size: 20px;
  }
  @media ${maxDevice.mobileM} {
    font-size: 18px;
  }
  flex: 0 0 auto;
  text-align: center;
  font-weight: ${fonts.semiBold};
  font-size: 32px;
`;

const ColorBlock = styled.div`
  @media (max-width: 1210px) {
    min-width: 100px;
    padding: 6px 10px;
  }
  @media ${maxDevice.mobileL} {
    min-width: 85px;
    padding: 4px;
  }
  @media ${maxDevice.mobileM} {
    min-width: 70px;
  }
  border-radius: 6px;
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  flex: 1 1 15%;
  /* width: 20%; */
  height: 100%;
  min-width: 110px;
  border: 1px solid ${(props) => props.$color};
  background-color: ${(props) => props.$bg};
  margin: 5px;
  ${KffValue} {
    color: ${(props) => props.$color};
  }
`;

const ColumnsContainer = styled.div`
  @media ${maxDevice.tablet} {
    gap: 0;
  }
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;

const ColumnContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin-right: 0px;
  }
  display: flex;
  flex-direction: column;
  flex: 1 1 270px;
`;

// const ColumnContainerSlim = styled(ColumnContainer)`
//   @media ${maxDevice.sideNav} {
//     margin-right: 0px;
//     max-width: auto;
//   }
//   display: flex;
//   flex-direction: column;
//   flex: 1 1 270px;
//   max-width: 50%;
// `;

const AddReportContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  border: 1px solid ${colors.lightGrey};
  padding: 12px 15px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
  p {
    margin-bottom: 15px;
  }
`;

ConsumerHouseholdSummaryNew.propTypes = {
  canEdit: PropTypes.bool,
  viewMembers: PropTypes.bool,
  viewSignals: PropTypes.bool,
  viewReports: PropTypes.bool,
};

export default ConsumerHouseholdSummaryNew;
