/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import {
  CheckBox,
  Button,
  TextInput,
  DetectModalKeys,
  InfoBox,
} from '../../components';
import {
  logUserIn,
  showLoginHelpScreen,
  showPasswordRecoveryScreen,
  toggleRememberMe,
  updateUserStoreValue,
  // removeLoginError,
} from '../../store/actions';
import { eye, eyeClosed } from '../../assets';
import { CenterFlexContainer } from '../../styles/library/layoutStyles';
import {
  ModalDetailText,
  ModalMainText,
  SSOContainer,
} from '../../styles/library/modalStyles';
import {
  LinkText,
  CapsLockWarning,
  ButtonLinkExternalPrimary,
  EmailLink,
} from '../../styles/library/fontStyles';
import {
  InputLabel,
  TextInputContainer,
} from '../../styles/library/inputStyles';
import { ButtonThemes } from '../../styles/themes';
import { CapsLockActive, DetectEnterKeyPress } from '../../utils';

const LoginModal = ({
  url,
  isShared = false,
  householdName = 'Loading',
  isTimeout = false,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { rememberMe, showError, createdAccountDetails } = useSelector(
    (state) => ({
      rememberMe: state.user.rememberMe,
      showError: state.user.loginError,
      createdAccountDetails: state.user.createdAccountDetails,
    })
  );
  const [showingPassword, setShowingPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [hasSSOScreen, setHasSSOScreen] = useState(false);
  const [showSSO, setShowSSO] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginContent, setLoginContent] = useState({
    email: '',
    password: '',
  });
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (url && url !== null && url.trim() !== '') {
      setShowLoading(false);
      setHasSSOScreen(true);
    }
  }, [url]);

  useEffect(() => {
    if (hasSSOScreen) {
      setShowSSO(true);
    }
  }, [hasSSOScreen]);

  //IF USER WAS REDIRECTED TO LOGIN AFTER CREATING ACCOUNT - PREFILL EMAIL ADDRESS
  useEffect(() => {
    if (createdAccountDetails) {
      setLoginContent({ ...loginContent, email: createdAccountDetails?.email });
      dispatch(updateUserStoreValue(createdAccountDetails, null));
    }
  }, [createdAccountDetails]);

  useEffect(() => {
    const usernameCookie = Cookies.get('username');
    if (usernameCookie !== undefined) {
      setLoginContent({
        ...loginContent,
        email: usernameCookie,
      });
    }
  }, []);

  useEffect(() => {
    if (showError) {
      setEmailError(true);
      setPasswordError(true);
      setShowLoading(false);
      dispatch(updateUserStoreValue('loginError', null));
    }
  }, [showError]);

  const updateLoginInfo = (e) => {
    setLoginContent({
      ...loginContent,
      [e.currentTarget.name]: e.currentTarget.value,
    });
    if (e.currentTarget.name === 'email') {
      setEmailError(false);
    }
    if (e.currentTarget.name === 'password') {
      setPasswordError(false);
    }
    // dispatch(removeLoginError());
  };

  const onSubmitForm = () => {
    let hasError = false;
    if (loginContent.email === '') {
      setEmailError(true);
      hasError = true;
    }
    if (loginContent.password === '') {
      setPasswordError(true);
      hasError = true;
    }
    if (!hasError) {
      setShowLoading(true);
      dispatch(logUserIn(loginContent));
    }
  };

  useEffect(() => {
    if (location) {
      const queryParams = new URLSearchParams(window.location.search);
      const next = queryParams.get('next');
      if (next) {
        dispatch(updateUserStoreValue('redirect', next));
      }
    }
  }, [location]);

  return showSSO ? (
    <>
      <SSOContainer>
        <ModalMainText>
          To login to Asset-Map, you must first log into your Equitable account.
        </ModalMainText>
        <ModalDetailText>
          For any questions, contact{' '}
          <EmailLink href="mailto:support@asset-map.com">
            support@asset-map.com
          </EmailLink>
        </ModalDetailText>
        <ModalDetailText>
          For more information, visit{' '}
          <EmailLink
            href="https://www.asset-map.com/"
            target="_blank"
            rel="noreferrer"
          >
            asset-map.com
          </EmailLink>
        </ModalDetailText>
      </SSOContainer>
      <ButtonLinkExternalPrimary href={url}>Sign In</ButtonLinkExternalPrimary>
      <CenterFlexContainer>
        <LinkText onClick={() => setShowSSO(false)}>Asset-Map Login</LinkText>
      </CenterFlexContainer>
    </>
  ) : (
    <>
      <DetectModalKeys onEnter={onSubmitForm} />
      {isShared && (
        <InfoBox
          message={`Sign in to accept the invitation to <strong>${householdName}<strong>.`}
          margin="15px 0 0 0"
          fontSize="11px"
        />
      )}
      {isTimeout && (
        <InfoBox
          message={`Due to <strong>inactivity</strong> for security reasons you have been logged out.`}
          margin="15px 0 0 0"
          fontSize="12px"
        />
      )}
      <TextInputContainer autoWidth={true} margin={'15px 0 10px 0'}>
        <InputLabel>Email</InputLabel>
        <TextInput
          type="email"
          name="email"
          value={loginContent.email}
          placeholder="Email Address"
          onChange={updateLoginInfo}
          hasError={emailError}
          onKeyPress={(e) => DetectEnterKeyPress(e, onSubmitForm)}
        />
      </TextInputContainer>
      <TextInputContainer>
        <InputLabel>Password</InputLabel>
        <TextInput
          type={showingPassword ? 'text' : 'password'}
          name="password"
          value={loginContent.password}
          placeholder="Password"
          onChange={updateLoginInfo}
          hasError={passwordError}
          withIcon={true}
          onKeyPress={(e) => DetectEnterKeyPress(e, onSubmitForm)}
          iconName={showingPassword ? 'closed eye' : 'eye'}
          iconSrc={showingPassword ? eyeClosed : eye}
          onClick={() => setShowingPassword(!showingPassword)}
        />
        <CapsLockActive style={{ display: 'block' }}>
          {(active) =>
            active && <CapsLockWarning>Caps Lock is on</CapsLockWarning>
          }
        </CapsLockActive>
      </TextInputContainer>
      <CheckBox
        label={'Remember Me'}
        styling={{ fontSize: '12px', marginBottom: '10px' }}
        onChange={() => dispatch(toggleRememberMe(!rememberMe))}
        checked={rememberMe}
      />
      <Button
        onClick={onSubmitForm}
        type="submit"
        text={'Sign In'}
        showLoading={showLoading}
        loadingText="Signing In"
        theme={ButtonThemes.primaryFull}
      />
      <CenterFlexContainer>
        {hasSSOScreen ? (
          <LinkText onClick={() => setShowSSO(true)}>SSO Login</LinkText>
        ) : (
          <LinkText
            onClick={() => {
              dispatch(showPasswordRecoveryScreen());
            }}
          >
            Forgot Your Password?
          </LinkText>
        )}
        <LinkText
          onClick={() => {
            dispatch(showLoginHelpScreen());
          }}
        >
          Trouble Signing In?
        </LinkText>
      </CenterFlexContainer>
    </>
  );
};

LoginModal.propTypes = {
  url: PropTypes.string,
  isShared: PropTypes.bool,
  householdName: PropTypes.string,
  isTimeout: PropTypes.bool,
};

export default LoginModal;
