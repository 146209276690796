import axios from 'axios';
import {
  ApiTypes as API,
  unauthorized,
  accessDenied,
  apiError,
  apiStart,
  apiEnd,
  apiSuccess,
} from '../store/actions';
import { CheckRememberMe, SuccessNotification, ErrorNotification } from '.';
import { CONSUMER_API as consumerAPI } from './constants';
import Cookies from 'js-cookie';

//MIDDLEWARE USED TO HANDLE ALL API CALLS
const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type !== API.API) return;

    const accessToken = Cookies.get('token');
    const CSRFToken = Cookies.get('csrftoken');
    const {
      REACT_APP_ENVIRONMENT,
      REACT_APP_LOCAL_API_URL,
      REACT_APP_DEV_API_URL,
      REACT_APP_INT_API_URL,
      REACT_APP_GLOBAL_API_URL,
      REACT_APP_PROD_API_URL,
    } = process.env;

    const isDev =
      process.env.NODE_ENV === 'development' &&
      window.location.origin === 'http://localhost:3000';

    let url;
    switch (REACT_APP_ENVIRONMENT) {
      case 'dev':
        url = REACT_APP_DEV_API_URL;
        break;
      case 'int':
        url = REACT_APP_INT_API_URL;
        break;
      case 'global':
        url = REACT_APP_GLOBAL_API_URL;
        break;
      case 'prod':
        url = REACT_APP_PROD_API_URL;
        break;
      default:
        window.location.origin === 'http://localhost:3000'
          ? (url = REACT_APP_LOCAL_API_URL)
          : (url = window.location.origin + '/api/v3');
    }

    //EVERYTHING THAT IS PASSED IN FROM REDUX ACTIONS
    const {
      path,
      method,
      absolute,
      isConsumer,
      data,
      onSuccess,
      passData,
      passId,
      passSubId,
      passThirdParam,
      sendData,
      passValue,
      ignoreResponse,
      showStart,
      successMessage,
      errorMessage,
      onFailure,
      label,
      headers,
    } = action.payload;

    const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    //IF CONSUMER PREPEND CONSUMER TO URL PATH
    let relativePath = path;
    isConsumer
      ? (relativePath = `/${consumerAPI}${path}`)
      : (relativePath = path);

    //IF ABSOLUTE PATH IS DESIRED NEED TO USE PASSED IN PATH INSTEAD OF STANDARD
    absolute ? (url = relativePath) : (url = url + relativePath);

    //AXIOS DEFAULT CONFIGS
    axios.defaults.baseURL = url || '';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    if (REACT_APP_ENVIRONMENT || isDev) {
      axios.defaults.headers.common['Authorization'] = `Token ${accessToken}`;
    }
    if (CSRFToken && !/^(GET|HEAD|OPTIONS|TRACE)$/.test(method)) {
      axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
    }

    let username;
    let copiedData = data;
    if (label === 'LOG_IN') {
      username = data.username;
    }

    //IF YOU PASS SHOW START AN ACTION FOR API_START WILL BE ACCESSIBLE IN THE REDUCER
    if (showStart && label) {
      dispatch(apiStart(label));
    }
    // THE ACTUAL API REQUEST
    axios
      .request({
        url,
        method,
        headers,
        [dataOrParams]: data,
      })
      .then(({ data, status }) => {
        if (label === 'LOG_IN') {
          let rememberMe = CheckRememberMe();
          if (rememberMe) {
            Cookies.set('username', username, {
              expires: 30,
            });
          } else {
            Cookies.remove('username');
          }
        }
        //IF SUCCESS MESSAGE IS PASSED THEN DISPLAY TOAST
        if (successMessage) {
          SuccessNotification(successMessage);
        }
        //PASS DATA - PASS ALL DATA SENT WITH API TO NEXT ACTION
        //PASS ID - PASSES ID SENT WITH API CALL TO THE NEXT API CALL
        //PASS THIRD PARAM - IF YOU WANT TO PASS THREE VALUES TO NEXT CALL
        //PASS SUB ID - PASS TWO ID VALUES TO NEXT API CALL
        if (passData) {
          dispatch(onSuccess(copiedData));
        } else if (passId) {
          if (passThirdParam) {
            dispatch(onSuccess(passId, passSubId, passThirdParam));
          } else if (passSubId) {
            dispatch(onSuccess(passId, passSubId));
          } else {
            dispatch(onSuccess(passId));
          }
        } else {
          if (label === 'LOG_IN') {
            dispatch(onSuccess({ ...data, status }));
          } else {
            if (passValue) {
              dispatch(onSuccess(data, passValue));
            } else if (ignoreResponse) {
              dispatch(onSuccess());
            } else {
              dispatch(onSuccess(data));
            }
          }
        }
        //IF API CALL IS SUCCESS A API_SUCCESS ACTION WILL BE DISPATCHED
        if (label) {
          dispatch(
            apiSuccess(label, {
              ...data,
              passId,
              passSubId,
              ...sendData,
            })
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          let errorAction = action.payload.label;
          //CHECK IF ERROR VALUE IS 500 THEN SHOW ERROR TOAST
          if (error.response.status === 500) {
            ErrorNotification('Server Error');
          }
          //CHECK IF ERROR VALUE IS 404 THEN SHOW ERROR TOAST
          if (error.response.status === 404) {
            ErrorNotification('Server Error: Not Found');
          }
          if (error.response.status === 401) {
            if (error.response?.data?.code === 'multisession') {
              return dispatch(
                unauthorized(error.response, 'USER_MULTISESSION_LOGOUT')
              );
            }
            //CHECK IF ERROR VALUE IS 401 THEN DISPATCH UNAUTHORIZED ACTION
            //Check if multi session ais in the body and change the action values here
            //Then can use that separate label and value in the reducer to redirect and display error message

            dispatch(unauthorized(error.response, errorAction));
          } else {
            if (errorMessage) {
              ErrorNotification(
                errorMessage || error.response.data.errorMessage
              );
            }
            //OTHER ERRORS WILL DISPATCH API_ERROR ACTION THAT CAN BE USED IN STORE
            dispatch(apiError(error.response, errorAction));
            if (action.payload && action.payload.onFailure !== null) {
              if (passId) {
                if (passThirdParam) {
                  dispatch(
                    onFailure(passId, passSubId, passThirdParam, error.response)
                  );
                } else if (passSubId) {
                  dispatch(onFailure(passId, passSubId, error.response));
                } else {
                  dispatch(onFailure(passId, error.response));
                }
              } else {
                dispatch(onFailure(error.response));
              }
            }
          }
          if (error.response && error.response.status === 403) {
            dispatch(
              accessDenied(
                error.response,
                errorAction,
                window.location.pathname
              )
            );
          }
        }
      })
      .finally(() => {
        //AFTER EVERYTHING IS DONE WHETHER SUCCESS OR ERROR AN API_END ACTION WILL BE DISPATCHED
        if (label) {
          dispatch(apiEnd(label));
        }
      });
  };

export default apiMiddleware;
