export const MarkerTypes = {
  TOGGLE_BLACK_MARKER: 'TOGGLE_BLACK_MARKER',
  TOGGLE_RED_MARKER: 'TOGGLE_RED_MARKER',
  TOGGLE_CUSTOM_MARKER: 'TOGGLE_CUSTOM_MARKER',
  TOGGLE_SELECT: 'TOGGLE_SELECT',
  TOGGLE_PALETTE: 'TOGGLE_PALETTE',
  CUSTOM_PEN_COLOR: 'CUSTOM_PEN_COLOR',
  DELETE_CANVAS: 'DELETE_CANVAS',
};

//ACTION TO TOGGLE BLACK MARKER SELECTED <boolean>
export const toggleBlackMarker = (isUsing) => ({
  type: MarkerTypes.TOGGLE_BLACK_MARKER,
  payload: isUsing,
});

//ACTION TO TOGGLE RED MARKER SELECTED <boolean>
export const toggleRedMarker = (isUsing) => ({
  type: MarkerTypes.TOGGLE_RED_MARKER,
  payload: isUsing,
});

//ACTION TO TOGGLE CUSTOM COLOR MARKER SELECTED <boolean>
export const toggleCustomMarker = (isUsing) => ({
  type: MarkerTypes.TOGGLE_CUSTOM_MARKER,
  payload: isUsing,
});

//ACTION TO TOGGLE SELECT TOOL SELECTED <boolean>
export const toggleSelectTool = (isUsing) => ({
  type: MarkerTypes.TOGGLE_SELECT,
  payload: isUsing,
});

//ACTION TO TOGGLE DISPLAY OF COLOR PICKER MODAL <boolean>
export const togglePalette = (isShowing) => ({
  type: MarkerTypes.TOGGLE_PALETTE,
  payload: isShowing,
});

//ACTION TO SET CUSTOM MARKER HEX COLOR VALUE <string>
export const setCustomPenColor = (penColor) => ({
  type: MarkerTypes.CUSTOM_PEN_COLOR,
  payload: penColor,
});

//ACTION TO REMOVE DRAWING CANVAS OVERLAY
export const deleteCanvas = () => ({
  type: MarkerTypes.DELETE_CANVAS,
});
