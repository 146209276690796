/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Tooltip } from 'react-tippy';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthText } from '../../styles/library/fontStyles';
import { Button, TextInput, Error } from '../../components';
import { ButtonThemes } from '../../styles/themes';
import {
  updateExistingUserPassword,
  skipPasswordReset,
} from '../../store/actions';
import { eye, eyeClosed, errorCircleRed } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
} from '../../styles/library/modalStyles';
import { CenteredContainer } from '../../styles/library/layoutStyles';
import {
  TextInputContainer,
  InputLabel,
  TooltipIconError,
} from '../../styles/library/inputStyles';
import { LinkButton } from '../../styles/library/fontStyles';
import {
  CenterModal,
  ModalHeader,
  ModalHeadingConfig,
  ModalContent,
  ButtonsColumnContainer,
} from '../../styles/library/modalStyles';

const PasswordRequestedResetModal = () => {
  const dispatch = useDispatch();
  const {
    showError,
    errorMessage,
    passwordResetError,
    passwordUpdated,
    skips,
    user,
  } = useSelector((state) => ({
    showError: state.user.responseError.showError,
    errorMessage: state.user.responseError.message,
    passwordResetError: state.user.passwordResetError,
    passwordUpdated: state.user.passwordUpdated,
    skips: state.user?.user?.password_change_skips_available,
    user: state.user,
  }));
  const [showingPassword, setShowingPassword] = useState(false);
  const [showingNewPassword, setShowingNewPassword] = useState(false);
  const [showingRetypedPassword, setShowingRetypedPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [retypedPassword, setRetypedPassword] = useState('');
  const [originalPassword, setOriginalPassword] = useState('');
  const [passwordError, setPasswordError] = useState({
    currentPassword: '',
    newPassword: '',
    retypedPassword: '',
  });
  const [showLocalError, setShowLocalError] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const navigate = useNavigate();
  const submitButtonText = 'Update Password';
  let skipStr = !skips ? 'Skip' : 'Skips (' + skips + ' remain)';

  const createErrorMessage = (errors) => {
    let newErr = 'Passwords must follow these rules: ';
    if (Array.isArray(errors)) {
      errors.forEach((error, index) => {
        newErr += `${error}${index !== errors.length - 1 ? ', ' : ''}`;
      });
      return newErr;
    }
    if (typeof errors === 'object') {
      const objLength = Object.keys(errors).length - 1;
      Object.values(errors).forEach((e, index) => {
        newErr += `${e}${index !== objLength ? ', ' : ''}`;
      });
      return newErr;
    }
    return null;
  };

  useEffect(() => {
    if (passwordResetError) {
      const currentPasswordMessage = createErrorMessage(
        passwordResetError?.old_password
      );
      const newPasswordMessage = createErrorMessage(
        passwordResetError?.new_password
      );
      setPasswordError({
        currentPassword: currentPasswordMessage,
        newPassword: newPasswordMessage,
        retypedPassword: '',
      });
      if (currentPasswordMessage && newPasswordMessage) {
        setLocalErrorMessage('Please fix errors and try again');
      } else if (currentPasswordMessage) {
        setLocalErrorMessage(currentPasswordMessage);
      } else if (newPasswordMessage) {
        setLocalErrorMessage(newPasswordMessage);
      } else {
        setLocalErrorMessage('Please fix errors and try again');
      }
      setShowLocalError(true);
    }
  }, [passwordResetError]);

  useEffect(() => {
    if (passwordUpdated) {
      navigate('/ampux/auth');
    }
  }, [passwordUpdated]);

  const onSubmitForm = () => {
    let currentPasswordError = '';
    let newPasswordError = '';
    let retypePasswordError = '';
    let errorCount = 0;

    if (originalPassword === '') {
      currentPasswordError = 'Please enter your current password';
      setLocalErrorMessage(currentPasswordError);
      errorCount++;
    }
    if (newPassword === '') {
      newPasswordError = 'Please enter your new password';
      setLocalErrorMessage(newPasswordError);
      errorCount++;
    }
    if (retypedPassword === '') {
      retypePasswordError = 'Please retype your new password';
      setLocalErrorMessage(retypePasswordError);
      errorCount++;
    }
    if (newPassword !== retypedPassword) {
      retypePasswordError = 'Your passwords must match';
      newPasswordError = 'Your passwords must match';
      setLocalErrorMessage(newPasswordError);
      errorCount++;
    }
    if (originalPassword === newPassword && originalPassword !== '') {
      currentPasswordError = 'New password must be different than old one';
      newPasswordError = 'New password must be different than old one';
      setLocalErrorMessage(newPasswordError);
      errorCount++;
    }
    if (currentPasswordError || newPasswordError || retypePasswordError) {
      setShowLocalError(true);
      if (errorCount > 1) {
        setLocalErrorMessage('Please fix errors and try again');
      }
      return setPasswordError({
        currentPassword: currentPasswordError,
        newPassword: newPasswordError,
        retypedPassword: retypePasswordError,
      });
    } else {
      //Dispatch API call to reset password
      let obj = {
        old_password: originalPassword,
        new_password: newPassword,
      };
      dispatch(updateExistingUserPassword(obj));
    }
  };

  const useSkip = () => {
    dispatch(skipPasswordReset(user));
  };

  const clearAllErrors = () => {
    setPasswordError({
      currentPassword: '',
      newPassword: '',
      retypedPassword: '',
    });
    setShowLocalError(false);
    setLocalErrorMessage('');
  };

  const updatePassword = (e) => {
    setNewPassword(e.currentTarget.value);
    clearAllErrors();
  };

  const updateRetypedPassword = (e) => {
    setRetypedPassword(e.currentTarget.value);
    clearAllErrors();
  };

  const updateOriginalPassword = (e) => {
    setOriginalPassword(e.currentTarget.value);
    clearAllErrors();
  };

  const canSkip = () => {
    if (skips > 0 || skips === null) {
      return true;
    } else {
      return false;
    }
  };

  const generateLabel = (label, fieldName) => {
    return (
      <InputLabel>
        {label}{' '}
        {passwordError[fieldName] && (
          <Tooltip
            title={passwordError[fieldName]}
            position="top"
            trigger="mouseenter click"
            followCursor="true"
            delay={0}
            arrow={true}
            arrowSize={'small'}
            animation={'fade'}
            animateFill={false}
            transitionFlip={false}
            size={'small'}
          >
            <TooltipIconError
              src={errorCircleRed}
              alt="error"
              data-image={`tooltip-error-${fieldName}`}
            />
          </Tooltip>
        )}
      </InputLabel>
    );
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground style={{ background: 'rgba(0,0,0,.7)' }} />
      <ModalOverlay>
        <CenteredContainer>
          <CenterModal>
            <ModalHeader>
              <ModalHeadingConfig>Reset Password</ModalHeadingConfig>
            </ModalHeader>
            <ModalContent>
              <AuthText>
                Your new password must contain one uppercase letter, one number,
                and meet a minimum character length (generally 8).
              </AuthText>
              <TextInputContainer>
                {generateLabel('Current Password', 'currentPassword')}
                <TextInput
                  type={showingPassword ? 'text' : 'password'}
                  label="Current Password"
                  name="originalPassword"
                  value={originalPassword}
                  placeholder="Current Password"
                  onChange={updateOriginalPassword}
                  errors={passwordError.currentPassword}
                  withIcon={true}
                  iconName={showingRetypedPassword ? 'closed eye' : 'eye'}
                  iconSrc={showingPassword ? eyeClosed : eye}
                  onClick={() => setShowingPassword(!showingPassword)}
                />
              </TextInputContainer>
              <TextInputContainer>
                {generateLabel('New Password', 'newPassword')}
                <TextInput
                  type={showingNewPassword ? 'text' : 'password'}
                  name="newPassword"
                  value={newPassword}
                  placeholder="New Password"
                  onChange={updatePassword}
                  errors={passwordError.newPassword}
                  withIcon={true}
                  iconName={showingRetypedPassword ? 'closed eye' : 'eye'}
                  iconSrc={showingNewPassword ? eyeClosed : eye}
                  onClick={() => setShowingNewPassword(!showingNewPassword)}
                />
              </TextInputContainer>
              <TextInputContainer>
                {generateLabel('Retype New Password', 'retypedPassword')}
                <TextInput
                  type={showingRetypedPassword ? 'text' : 'password'}
                  name="retypedPassword"
                  value={retypedPassword}
                  placeholder="Retype New Password"
                  onChange={updateRetypedPassword}
                  errors={passwordError.retypedPassword}
                  withIcon={true}
                  iconName={showingRetypedPassword ? 'closed eye' : 'eye'}
                  iconSrc={showingRetypedPassword ? eyeClosed : eye}
                  onClick={() =>
                    setShowingRetypedPassword(!showingRetypedPassword)
                  }
                />
              </TextInputContainer>
              <ButtonsColumnContainer>
                <Button
                  onClick={onSubmitForm}
                  text={submitButtonText}
                  theme={ButtonThemes.primaryFull}
                ></Button>
                <LinkButton
                  onClick={canSkip() ? useSkip : undefined}
                  disabled={!canSkip()}
                >
                  {skipStr}
                </LinkButton>
              </ButtonsColumnContainer>
            </ModalContent>
            {showLocalError && <Error errorMessage={localErrorMessage} />}
            {showError && <Error errorMessage={errorMessage} />}
          </CenterModal>
        </CenteredContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

export default PasswordRequestedResetModal;
