/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  PageHeader,
  EmptyImage,
  SortButtons,
  Pagination,
  LoadingAnimation,
  ReportListItem,
} from '../../components';
import { noReports } from '../../assets';
import {
  getHouseholdReports,
  updateHouseholdStoreValue,
} from '../../store/actions';
import { colors, maxDevice, fonts } from '../../styles/variables';
import { CONSUMER_API as consumerAPI } from '../../utils/constants';
import { SendTrackingEvent, UpdatePageTitle, headerContent } from '../../utils';

const ordersReducer = (orders, action) => {
  const [field, direction] = action.type.split('-');
  const updatedOrders = orders.map((o) => {
    if (o.field === field) {
      o.direction = direction;
      o.param = field;
      if (direction === 'descend') {
        o.param = '-' + field;
      }
    } else {
      o.direction = '';
      o.param = '';
    }
    return o;
  });
  return updatedOrders;
};

const pagesReducer = (pages, action) => {
  if (action.type === 'update_count') {
    pages.count = action.payload;
  }
  if (action.type === 'update_limit') {
    pages.limit = action.payload;
  }
  if (action.type === 'update_offset') {
    pages.offset = action.payload;
  }
  return pages;
};

const ConsumerHouseholdReports = ({ showPDF }) => {
  UpdatePageTitle('Shared Reports');
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  const { householdReports, householdReportsError, limit, invocationId } =
    useSelector((state) => ({
      householdReports: state.households.householdReports,
      householdReportsError: state.households.householdReportsError,
      limit: state.configs?.pagination?.default_limit || 25,
      invocationId: state.configs.invocationId,
    }));

  // sortable components
  const reportType = (order) => {
    return (
      <ReportType key={order.field}>
        <SortButtons
          name={order.label}
          data-image={`arrow-${order.direction}`}
        />
      </ReportType>
    );
  };

  // const generatedBy = (order) => {
  //   return (
  //     <ReportUser key={order.field}>
  //       <SortButtons
  //         name={order.label}
  //         data-image={`arrow-${order.direction}`}
  //       />
  //     </ReportUser>
  //   );
  // };

  const createdDate = (order) => {
    return (
      <ReportDate key={order.field}>
        <SortButtons
          name={order.label}
          clickAscend={() => dispatchOrders({ type: 'created_utc-ascend' })}
          clickDescend={() => dispatchOrders({ type: 'created_utc-descend' })}
          direction={order.direction}
          data-image={`arrow-${order.direction}`}
        />
      </ReportDate>
    );
  };

  /**
   * Local state
   */
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [queryParams, setQueryParams] = useState();
  const [reportsError, setReportsError] = useState();

  const initOrdersState = [
    {
      field: 'report_type',
      direction: '',
      label: 'Report Type',
      component: reportType,
      param: '',
    },
    // {
    //   field: 'member__name',
    //   direction: '',
    //   label: 'Shared By',
    //   component: generatedBy,
    //   param: '',
    // },
    {
      field: 'created_utc',
      direction: 'descend',
      label: 'Shared Date',
      component: createdDate,
      param: '-created_utc',
    },
  ];
  const [ordersState, dispatchOrders] = useReducer(
    ordersReducer,
    initOrdersState
  );

  const initPagesState = {
    limit: limit,
    offset: 0,
    count: 0,
  };
  const [pagesState, dispatchPages] = useReducer(pagesReducer, initPagesState);

  /**
   * Side Effects
   */
  // update hh reports in ui if there are any changes
  useEffect(() => {
    if (householdReports) {
      if (householdReports?.results) {
        setLoading(false);
        const transformedReports = householdReports?.results.map((report) => {
          return { ...report, ...report?.report, shareable: false };
        });
        setReports(transformedReports);
      }
      if (householdReports.count !== pagesState.count) {
        dispatchPages({
          type: 'update_count',
          payload: householdReports.count,
        });
      }
    }
  }, [householdReports]);

  useEffect(() => {
    if (householdReportsError) {
      setLoading(false);
      if (householdReportsError?.data) {
        if (householdReportsError.data?.detail) {
          return setReportsError(householdReportsError.data?.detail);
        }
      }
      setReportsError('Error Loading Reports.');
      dispatch(updateHouseholdStoreValue('householdReportsError', null));
    } else {
      setReportsError();
    }
  }, [householdReportsError]);

  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('householdReports', false));
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      updateParams();
    }
  }, [pagesState]);

  useEffect(() => {
    if (!loading) {
      dispatchPages({
        type: 'update_offset',
        payload: 0,
      });
      updateParams(0);
    }
  }, [ordersState]);

  // every time the filters or ordering changes update the reports.
  useEffect(() => {
    dispatch(getHouseholdReports(householdId, queryParams, true));
  }, [queryParams]);

  /**
   * Handling Events
   */
  // this triggers the query params state change which triggers api call to get reports based on params
  const updateParams = (offset) => {
    const params = {};
    if (offset !== undefined) {
      params.offset = offset;
    } else {
      params.offset = pagesState.offset;
    }
    params.ordering = ordersState.reduce((o, key) => o + key.param, '');
    setQueryParams(params);
  };

  const nextPage = () => {
    const offset = pagesState.offset + pagesState.limit;
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const prevPage = () => {
    const offset = pagesState.offset - pagesState.limit;
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const goToPage = (page) => {
    const offset = pagesState.limit * (page - 1);
    dispatchPages({
      type: 'update_offset',
      payload: offset,
    });
    updateParams(offset);
  };

  const downloadOnClick = (report) => {
    SendTrackingEvent(
      invocationId,
      'click',
      'report_pdf_download',
      'household_reports',
      {
        isConsumer: true,
        report: report.id,
      }
    );
    window.open(
      `${window.location.origin}/api/v3/${consumerAPI}/household/${householdId}/shared/reports/${report.id}/download`,
      '_self'
    );
  };

  return (
    <PageContainer>
      <PageHeader content={{ ...headerContent.consumerReports }} />
      <PageContent>
        <ReportsListContainer>
          <ReportsListHeader>
            {ordersState.map((orderState) => {
              return orderState.component(orderState);
            })}
            <DownloadContainer />
          </ReportsListHeader>
          {loading ? (
            <Empty>
              <LoadingAnimation />
            </Empty>
          ) : (
            <ReportsListOptions>
              {reportsError ? (
                <EmptyImage
                  imageSrc={noReports}
                  imageName="No Shared Reports"
                  text={reportsError}
                />
              ) : reports && reports.length !== 0 ? (
                reports.map((report, index) => {
                  return (
                    <div key={index}>
                      <ReportListItem
                        report={report}
                        isConsumer={true}
                        downloadFunc={downloadOnClick}
                      />
                    </div>
                  );
                })
              ) : (
                <EmptyImage
                  imageSrc={noReports}
                  imageName="No Shared Reports"
                  text="No Shared Reports"
                />
              )}
              {pagesState?.count !== 0 && pagesState?.count > limit && (
                <Pagination
                  clickPrev={prevPage}
                  clickNext={nextPage}
                  goToPage={goToPage}
                  limit={pagesState.limit}
                  offset={pagesState.offset}
                  count={pagesState.count}
                />
              )}
            </ReportsListOptions>
          )}
        </ReportsListContainer>
      </PageContent>
    </PageContainer>
  );
};

const ReportType = styled.div`
  width: 40%;
  padding: 0 10px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  span {
    margin-right: 10px;
  }
`;

// const ReportUser = styled.div`
//   width: 40%;
//   padding-right: 10px;
//   span {
//     margin-right: 10px;
//   }
// `;

const ReportDate = styled.div`
  width: 40%;
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const DownloadContainer = styled.div`
  width: 12%;
  flex: 0 0 auto;
  padding: 0 10px;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
  .kb {
    font-size: 11px;
    font-weight: ${fonts.semiBold};
    background: ${colors.lighterGrey};
    color: ${colors.paleGrey};
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
  }
`;

const ReportsListHeader = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
  span {
    cursor: pointer;
  }
`;

const ReportsListOptions = styled.div`
  min-height: 180px;
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -20px 20px 50px 20px;
  }
  margin: -25px 40px 30px 40px;
`;

const ReportsListContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 60px;
`;

const Empty = styled.div`
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

ConsumerHouseholdReports.propTypes = {
  showPDF: PropTypes.bool,
};

export default ConsumerHouseholdReports;
