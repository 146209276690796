/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import {
  showAuthCodeScreen,
  showAuthCodeSentScreen,
  resendAuthCode,
} from '../../store/actions';
import { Button } from '../../components';
import { StartFlexContainer } from '../../styles/library/layoutStyles';
import { LinkText } from '../../styles/library/fontStyles';
import { ButtonThemes } from '../../styles/themes';
import { colors, fonts } from '../../styles/variables';
import PropTypes from 'prop-types';

const ResendCodeModal = ({
  isAdd,
  showResendScreen,
  showCodeSentScreen,
  authValue,
  authId,
  hide,
  noHide,
  setScreenValue,
}) => {
  const dispatch = useDispatch();
  const { mfa_uuid, codeResent } = useSelector((state) => ({
    mfa_uuid: state.user.mfa_uuid,
    codeResent: state.user.codeResent,
  }));

  const resendCode = () => {
    if (isAdd) {
      dispatch(resendAuthCode(authId, isAdd));
    } else {
      dispatch(resendAuthCode(mfa_uuid));
    }
  };

  const submitCode = () => {
    if (isAdd) {
      setScreenValue('code');
    } else {
      dispatch(showAuthCodeScreen());
    }
  };

  useEffect(() => {
    if (codeResent) {
      dispatch(showAuthCodeSentScreen());
    }
  }, [codeResent]);

  return (
    <ThemeProvider theme={{ sent: showCodeSentScreen }}>
      <IconContainer>
        {showResendScreen ? (
          <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M42.5941 0.397134L1.46751 24.1163C-0.643903 25.3372 -0.432762 28.4489 1.81635 29.376L14.6868 34.7V44.0628C14.6868 46.835 18.1568 48.0374 19.8919 45.9262L25.4641 39.1519L37.0677 43.9435C38.8211 44.6687 40.804 43.558 41.0886 41.6762L46.9638 3.38957C47.3402 0.938709 44.7055 -0.823707 42.5941 0.397134ZM17.6244 44.0628V35.9117L22.6275 37.977L17.6244 44.0628ZM38.1877 41.2264L19.2584 33.4057L37.5726 11.761C38.0133 11.247 37.3064 10.5494 36.7923 10.99L13.3557 30.964L2.93631 26.6681L44.0629 2.93979L38.1877 41.2264Z"
              fill={colors.lightBlue}
            />
          </svg>
        ) : (
          <svg
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.5 0C10.969 0 0 10.969 0 24.5C0 38.031 10.969 49 24.5 49C38.031 49 49 38.031 49 24.5C49 10.969 38.031 0 24.5 0ZM24.5 45.8387C12.7771 45.8387 3.16129 36.3494 3.16129 24.5C3.16129 12.7772 12.6506 3.16129 24.5 3.16129C36.2229 3.16129 45.8387 12.6506 45.8387 24.5C45.8387 36.2228 36.3494 45.8387 24.5 45.8387ZM38.4917 18.6752L20.662 36.3619C20.1972 36.8229 19.4466 36.8199 18.9855 36.3551L10.5016 27.8027C10.0406 27.3379 10.0435 26.5872 10.5083 26.1262L11.35 25.2913C11.8148 24.8303 12.5654 24.8332 13.0265 25.298L19.8407 32.1672L35.987 16.1503C36.4519 15.6893 37.2025 15.6923 37.6635 16.1571L38.4984 16.9988C38.9595 17.4636 38.9565 18.2142 38.4917 18.6752Z"
              fill={colors.green}
            />
          </svg>
        )}
      </IconContainer>
      {isAdd ? (
        showResendScreen ? (
          <AuthInfoText>
            Would you like to resend your code to
            <span>{authValue}</span>
          </AuthInfoText>
        ) : (
          <AuthInfoText>
            A 6-digit authentication code was sent to
            <span>{authValue}</span>
          </AuthInfoText>
        )
      ) : (
        <InfoText>
          {showResendScreen
            ? `Would you like to resend your authentication code?`
            : `A 6-digit authentication code was sent to your device.`}
        </InfoText>
      )}
      <Button
        onClick={showResendScreen ? () => resendCode() : () => submitCode()}
        text={showResendScreen ? 'Resend Code' : 'Enter Code'}
        theme={ButtonThemes.primaryFull}
      />
      <StartFlexContainer>
        <LinkText
          style={{ flex: '0 0 auto', textAlign: 'left' }}
          onClick={() => {
            isAdd && !noHide ? hide() : dispatch(showAuthCodeScreen());
          }}
        >
          {isAdd ? 'Cancel' : 'Back'}
        </LinkText>
      </StartFlexContainer>
    </ThemeProvider>
  );
};

const IconContainer = styled.div`
  margin: 15px auto;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 100px;
  background: ${(props) =>
    props.theme.sent ? `rgba(120, 190, 32, .15)` : `rgba(62, 177, 200, 0.15)`};
`;

const InfoText = styled.p`
  text-align: center;
  margin: 20px auto;
  font-size: 15px;
  max-width: 320px;
  color: ${colors.darkGrey};
`;

const AuthInfoText = styled(InfoText)`
  font-weight: ${fonts.semiBold};
  span {
    font-weight: ${fonts.light};
    display: block;
    margin-top: 3px;
  }
`;

ResendCodeModal.propTypes = {
  isAdd: PropTypes.bool,
  showResendScreen: PropTypes.bool,
  showCodeSentScreen: PropTypes.bool,
  authValue: PropTypes.string,
  authId: PropTypes.string,
  hide: PropTypes.func,
  noHide: PropTypes.bool,
  setScreenValue: PropTypes.func,
};

export default ResendCodeModal;
