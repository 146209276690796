import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { TargetMapPercentCircle } from '../';
import { chevronRight, errorCircleRed, undo } from '../../assets';
import * as styles from '../../styles/variables';
import { FormatCurrency, IsNegative } from '../../utils';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { TooltipIconError } from '../../styles/library/inputStyles';

const TargetMapPercentBar = ({
  isList = false,
  targetMap,
  currency,
  returnRate,
  setReturnRate,
  inflationRate,
  setInflationRate,
  duration,
  setDuration,
  setPercentageBlur,
  updatePercentageValue,
  setYearBlur,
  resetDuration,
  error,
}) => {
  const generateErrorIcon = (input) => {
    return (
      <ToolTipContainer>
        <Tooltip
          title={input.errorMessage}
          position="top"
          trigger="mouseenter click"
          followCursor="true"
          delay={0}
          arrow={true}
          arrowSize={'small'}
          animation={'fade'}
          animateFill={false}
          transitionFlip={false}
          size={'small'}
        >
          <TooltipIconError
            src={errorCircleRed}
            alt="error"
            data-image={`tooltip-error-${input.name}`}
          />
        </Tooltip>
      </ToolTipContainer>
    );
  };

  return (
    <ThemeProvider theme={{ isList: isList }}>
      <TargetMapContentContainer>
        <TargetMapPercentContainer>
          <TargetMapPercentCircle targetMap={targetMap} />
        </TargetMapPercentContainer>
        <TargetMapInfoContainer>
          <TargetMapBarContainer>
            <OverallPercentageBar
            // style={{
            //   width: `${targetMap.funded}%`,
            //   background:
            //     targetMap.color ||
            //     DetermineTargetMapColor(targetMap.template),
            // }}
            ></OverallPercentageBar>
            <CashFlowPercentage
              style={{ width: `${targetMap.income_funded}%` }}
            >
              <Tooltip
                title={`${targetMap.income_funded}% Cash Flow`}
                position="top"
                trigger="mouseenter click"
                followCursor="true"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
              >
                <TooltipPlaceholder></TooltipPlaceholder>
              </Tooltip>
            </CashFlowPercentage>
            <AssetPercentage style={{ width: `${targetMap.asset_funded}%` }}>
              <Tooltip
                title={`${targetMap.asset_funded}% Asset`}
                position="top"
                trigger="mouseenter click"
                followCursor="true"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
              >
                <TooltipPlaceholder></TooltipPlaceholder>
              </Tooltip>
            </AssetPercentage>
            <InsurancePercentage
              style={{ width: `${targetMap.insurance_funded}%` }}
            >
              <Tooltip
                title={`${targetMap.insurance_funded}% Insurance`}
                position="top"
                trigger="mouseenter click"
                followCursor="true"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
              >
                <TooltipPlaceholder></TooltipPlaceholder>
              </Tooltip>
            </InsurancePercentage>
          </TargetMapBarContainer>
          <TargetMapExtraInfoRow>
            <TargetMapExtraInfoChunk>
              {isList ? (
                <ChunkValue>{targetMap.return_rate}%</ChunkValue>
              ) : (
                <ChunkValue>
                  {error.return_rate &&
                    generateErrorIcon({
                      name: 'return_rate',
                      errorMessage: error.return_rate,
                    })}
                  <PercentageInput
                    type="number"
                    $hasError={error.return_rate}
                    value={returnRate}
                    onChange={(e) => setReturnRate(e.currentTarget.value)}
                    onBlur={(e) =>
                      setPercentageBlur(e, setReturnRate, 'return_rate')
                    }
                  />
                  <span>%</span>
                  <ChevronContainer>
                    <ChevronUp
                      src={chevronRight}
                      alt="up"
                      data-image={`arrow-up-return`}
                      onClick={() => {
                        updatePercentageValue(
                          true,
                          setReturnRate,
                          'return_rate',
                          returnRate
                        );
                      }}
                    />
                    <ChevronDown
                      src={chevronRight}
                      alt="down"
                      data-image={`arrow-down-return`}
                      onClick={() => {
                        updatePercentageValue(
                          false,
                          setReturnRate,
                          'return_rate',
                          returnRate
                        );
                      }}
                    />
                  </ChevronContainer>
                </ChunkValue>
              )}
              <ChunkLabel>Expected Net Return on Capital</ChunkLabel>
            </TargetMapExtraInfoChunk>
            {!isList && <InfoSeparator>:</InfoSeparator>}
            <TargetMapExtraInfoChunk>
              <ChunkValue
                style={
                  IsNegative(targetMap.capital_net)
                    ? { color: styles.messageColors.error }
                    : null
                }
              >
                {targetMap?.capital_net || targetMap?.capital_net === 0 ? (
                  FormatCurrency(Math.round(targetMap.capital_net), currency)
                ) : (
                  <LoadingPlaceholder width="120px" />
                )}
              </ChunkValue>
              {/* <ChunkValue>{targetMap.capital_net}</ChunkValue> */}
              <ChunkLabel>
                Present Capital{' '}
                {IsNegative(targetMap.capital_net) ? (
                  <NegativeText>Deficit</NegativeText>
                ) : (
                  <PositiveText>Surplus</PositiveText>
                )}
              </ChunkLabel>
            </TargetMapExtraInfoChunk>
            {!isList && <InfoSeparator>or</InfoSeparator>}
            <TargetMapExtraInfoChunk>
              <ChunkValue>
                {Math.abs(Math.round(targetMap.contribution_needed)) ===
                Math.abs(Math.round(targetMap.capital_net)) ? (
                  'N/A'
                ) : targetMap?.contribution_needed ||
                  targetMap?.contribution_needed === 0 ? (
                  <span>
                    {FormatCurrency(
                      Math.abs(targetMap.contribution_needed),
                      currency
                    )}
                    /mo
                  </span>
                ) : (
                  <LoadingPlaceholder width="120px" />
                )}
              </ChunkValue>
              <ChunkLabel>
                {IsNegative(targetMap.capital_net) ? (
                  <>
                    <NegativeText>Additional</NegativeText> Contributions to
                    Fund
                  </>
                ) : (
                  <>
                    <PositiveText>Surplus</PositiveText> Contributions
                  </>
                )}
              </ChunkLabel>
            </TargetMapExtraInfoChunk>
            {!isList && <InfoSeparator>at</InfoSeparator>}
            <TargetMapExtraInfoChunk>
              {isList ? (
                <ChunkValue>{targetMap.inflation_rate}%</ChunkValue>
              ) : (
                <ChunkValue>
                  {error.inflation_rate &&
                    generateErrorIcon({
                      name: 'inflation_rate',
                      errorMessage: error.inflation_rate,
                    })}
                  <PercentageInput
                    type="number"
                    $hasError={error.inflation_rate}
                    value={inflationRate}
                    onChange={(e) => setInflationRate(e.currentTarget.value)}
                    onBlur={(e) =>
                      setPercentageBlur(e, setInflationRate, 'inflation_rate')
                    }
                  />
                  <span>%</span>
                  <ChevronContainer>
                    <ChevronUp
                      src={chevronRight}
                      alt="up"
                      data-image={`arrow-up-inflation`}
                      onClick={() => {
                        updatePercentageValue(
                          true,
                          setInflationRate,
                          'inflation_rate',
                          inflationRate
                        );
                      }}
                    />
                    <ChevronDown
                      src={chevronRight}
                      alt="down"
                      data-image={`arrow-down-inflation`}
                      onClick={() => {
                        updatePercentageValue(
                          false,
                          setInflationRate,
                          'inflation_rate',
                          inflationRate
                        );
                      }}
                    />
                  </ChevronContainer>
                </ChunkValue>
              )}
              <ChunkLabel>Annual Increase of Contributions</ChunkLabel>
            </TargetMapExtraInfoChunk>
            {!isList && <InfoSeparator>for</InfoSeparator>}
            <TargetMapExtraInfoChunk>
              {isList ? (
                <ChunkValue>{targetMap.duration} yrs</ChunkValue>
              ) : (
                <ChunkValue>
                  {error.duration &&
                    generateErrorIcon({
                      name: 'duration',
                      errorMessage: error.duration,
                    })}
                  <DurationInput
                    type="number"
                    min="0"
                    max="75"
                    $hasError={error.duration}
                    value={duration}
                    onChange={(e) => setDuration(e.currentTarget.value)}
                    onBlur={(e) => setYearBlur(e, setDuration, 'duration')}
                  />
                  <span>yrs</span>
                  <ResetIcon
                    src={undo}
                    alt="reset"
                    onClick={() => resetDuration()}
                    data-image={`reset-duration`}
                  />
                </ChunkValue>
              )}
              <ChunkLabel>Duration of Contributions</ChunkLabel>
            </TargetMapExtraInfoChunk>
          </TargetMapExtraInfoRow>
        </TargetMapInfoContainer>
      </TargetMapContentContainer>
    </ThemeProvider>
  );
};

const TargetMapContentContainer = styled.div`
  @media ${styles.maxDevice.tablet} {
    flex-wrap: wrap;
    justify-content: center;
  }
  padding: 15px 20px;
  display: flex;
  align-content: center;
  align-items: ${(props) => (props.theme.isList ? 'center' : 'flex-start')};
  justify-content: flex-start;
`;

const TargetMapPercentContainer = styled.div`
  /* @media ${styles.maxDevice.mobileL} {
    display: none;
  } */
  @media (max-width: 675px) {
    margin: 0 20px 15px 20px;
  }
  flex: 0 0 95px;
  margin-right: 25px;
  margin-left: ${(props) => (props.theme.isList ? 0 : '20px')};
`;

// const PercentageContainer = styled.div`
//   width: 95px;
//   height: 95px;
// `;

// const PercentageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
// `;

// const PercentageText = styled.p`
//   font-weight: ${styles.fonts.semiBold};
//   font-size: 24px;
//   margin-bottom: 5px;
// `;

// const PercentageFunded = styled.p`
//   font-size: 13px;
//   color: ${styles.colors.paleGrey};
// `;

const TargetMapInfoContainer = styled.div`
  @media (max-width: 670px) {
    margin-right: 0;
  }
  flex: 1 1 auto;
  margin-right: ${(props) => (props.theme.isList ? '5px' : '20px')};
`;

const TooltipPlaceholder = styled.div`
  height: 100%;
  width: 100%;
`;

const CashFlowPercentage = styled.div`
  background: ${styles.colors.lightBlue};
  flex: 0 1 auto;
  height: 100%;
  /* display: none; */
  &:hover {
    opacity: 0.9;
  }
`;

const AssetPercentage = styled.span`
  background: ${styles.colors.green};
  flex: 0 1 auto;
  height: 100%;
  /* display: none; */
  &:hover {
    opacity: 0.9;
  }
`;

const InsurancePercentage = styled.span`
  background: ${styles.colors.blue};
  flex: 0 1 auto;
  height: 100%;
  /* display: none; */
  &:hover {
    opacity: 0.9;
  }
`;

const OverallPercentageBar = styled.div`
  flex: 0 1 auto;
  height: 100%;
  &:hover {
    opacity: 0.9;
  }
`;

const TargetMapBarContainer = styled.div`
  overflow: hidden;
  height: ${(props) => (props.theme.isList ? '25px' : '35px')};
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  background: ${styles.colors.lightGrey};
  cursor: default;
  /* &:hover {
    ${OverallPercentageBar} {
      display: none;
    }
    ${CashFlowPercentage} {
      display: block;
    }
    ${AssetPercentage} {
      display: block;
    }
    ${InsurancePercentage} {
      display: block;
    }
  }*/
`;

const TargetMapExtraInfoRow = styled.div`
  margin-top: 20px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const TargetMapExtraInfoChunk = styled.div`
  @media ${styles.maxDevice.tablet} {
    text-align: center;
    width: 60px;
  }
  flex: 1 1 auto;
  text-align: left;
  margin: 5px 10px;
  width: 80px;
  &:first-child {
    margin-left: 0;
    @media ${styles.maxDevice.tablet} {
      margin-left: 10px;
    }
  }
  &:last-child {
    margin-right: 0;
    @media ${styles.maxDevice.tablet} {
      margin-right: 10px;
    }
  }
`;

const InfoSeparator = styled.div`
  @media ${styles.maxDevice.tablet} {
    display: none;
  }
  margin-top: 10px;
  font-size: 12px;
  flex: 0 0 auto;
  color: ${styles.colors.paleGrey};
`;

const ChunkValue = styled.div`
  @media ${styles.maxDevice.tablet} {
    font-size: 14px;
    font-size: ${(props) => (props.theme.isList ? '12px' : '14px')};
    justify-content: center;
  }
  font-size: ${(props) => (props.theme.isList ? '15px' : '18px')};
  font-weight: ${styles.fonts.semiBold};
  margin-bottom: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: ${(props) => (props.theme.isList ? 'flex-start' : 'center')};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const ChunkLabel = styled.div`
  @media ${styles.maxDevice.laptop} {
    font-size: 11px;
    font-size: ${(props) => (props.theme.isList ? '10px' : '11px')};
  }
  @media ${styles.maxDevice.tablet} {
    font-size: 9.5px;
    text-align: center;
  }
  font-size: ${(props) => (props.theme.isList ? '11px' : '12px')};
  text-align: ${(props) => (props.theme.isList ? 'left' : 'center')};
  opacity: 0.7;
`;

const NegativeText = styled.span`
  opacity: 1;
  color: ${(props) =>
    props.theme.isList ? 'default' : styles.messageColors.error};
`;

const PositiveText = styled.span`
  color: ${(props) =>
    props.theme.isList ? 'default' : styles.messageColors.success};
`;

const PercentageInput = styled.input`
  border: 1px solid ${styles.inputColors.border};
  color: ${styles.colors.darkGrey};
  width: 35px;
  font-size: 18px;
  font-weight: ${styles.fonts.semiBold};
  text-align: center;
  border-radius: 3px;
  flex: 0 0 auto;
  margin-right: 3px;
  ${({ $hasError }) =>
    $hasError &&
    `
    border: 1px solid ${styles.messageColors.error}
  `}
`;

const ChevronContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const ChevronUp = styled.img`
  width: 11px;
  height: 11px;
  transform: rotate(-90deg);
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const ChevronDown = styled(ChevronUp)`
  transform: rotate(90deg);
`;

const ResetIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-left: 5px;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const DurationInput = styled.input`
  border: 1px solid ${styles.inputColors.border};
  color: ${styles.colors.darkGrey};
  width: 45px;
  font-size: 18px;
  font-weight: ${styles.fonts.semiBold};
  text-align: center;
  border-radius: 3px;
  flex: 0 0 auto;
  margin-right: 3px;
`;

const ToolTipContainer = styled.div`
  position: relative;
  ${TooltipIconError} {
    width: 13px;
    height: 13px;
    position: absolute;
    right: 5px;
    top: -5px;
  }
`;

TargetMapPercentBar.propTypes = {
  isList: PropTypes.bool,
  targetMap: PropTypes.object,
  currency: PropTypes.object,
  returnRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setReturnRate: PropTypes.func,
  inflationRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setInflationRate: PropTypes.func,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setDuration: PropTypes.func,
  setPercentageBlur: PropTypes.func,
  updatePercentageValue: PropTypes.func,
  setYearBlur: PropTypes.func,
  resetDuration: PropTypes.func,
  error: PropTypes.object,
};

export default TargetMapPercentBar;
