import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { fonts, maxDevice } from '../styles/variables';
import { SuccessThemes } from '../styles/themes';
import { FlexCenterAll } from '../styles/library/layoutStyles';

const Success = ({
  successMessage,
  title,
  theme = SuccessThemes.modalDefault,
  maxWidth,
}) => {
  return (
    <ThemeProvider theme={{ ...theme, maxWidth }}>
      <SuccessContainer>
        <SuccessImg
          src={theme.imgSrc}
          alt="success"
          title={title ? title : 'Success'}
          data-image="success"
        />
        <SuccessText dangerouslySetInnerHTML={{ __html: successMessage }} />
      </SuccessContainer>
    </ThemeProvider>
  );
};

const SuccessContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-radius: 2px;
  }
  background: ${(props) => props.theme.bg};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.padding};
  max-width: ${(props) => (props.theme.maxWidth ? props.theme.maxWidth : '')};
  margin-bottom: ${(props) =>
    props.theme.marginBottom ? props.theme.marginBottom : ''};
  border: ${(props) => (props.theme.border ? props.theme.border : '')};
  ${FlexCenterAll}
`;

const SuccessText = styled.p`
  @media ${maxDevice.tablet} {
    font-size: ${fonts.success};
  }
  color: ${(props) => props.theme.txtColor};
  font-weight: ${(props) => props.theme.fontWeight};
  flex: 1 1 auto;
  text-align: left;
  font-size: ${fonts.success};
  margin-right: 10px;
  a {
    color: ${(props) => props.theme.txtColor};
    text-decoration: underline;
  }
`;

export const SuccessImg = styled.img`
  flex: 0 0 auto;
  width: 15px;
  margin-right: 15px;
`;

Success.propTypes = {
  successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string,
  theme: PropTypes.object,
  maxWidth: PropTypes.string,
};

export default Success;
