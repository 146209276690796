/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { PermissionContainer } from '../';
import {
  ListItem,
  TargetMapListItem,
  Panel,
  ReportListItem,
  Button,
  FileStorageExplorer,
  MilestoneListItem,
} from '../../components';
import {
  toggleShowMemberModal,
  toggleShowEntityModal,
  toggleShowAdvisorModal,
  toggleShowNewTargetMapModal,
  toggleShowMilestoneModal,
  toggleShowUploadFileModal,
  getMemberInfo,
  fetchUpdatedFileStorage,
  syncHouseholdSummary,
  getHouseholdReports,
  getHouseholdIntegrationFolder,
  toggleShowSignalsPanel,
  toggleShowReportShareModal,
  getReportShareTypes,
  getHouseholdMilestones,
  setHouseholdMilestones,
  setBulkSync,
  getHouseholdInfo,
  updateHouseholdStoreValue,
} from '../../store/actions';
import {
  userAdd,
  addMilestone,
  addEntity,
  pencil,
  plusCircleDark,
  link,
  upload,
  // download,
  sync,
  settingsDark,
  boxIcon,
  dropboxIcon,
  googleDriveIcon,
  hide,
  historyIcon,
} from '../../assets';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { colors, maxDevice, fonts } from '../../styles/variables';
import {
  GenerateAge,
  integrationOptions,
  ErrorNotification,
  signalLabels,
  HtmlToString,
  SendTrackingEvent,
} from '../../utils';

const HouseholdSummary = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const householdId = params?.id;
  const {
    advisors,
    assetMapImage,
    fetchRefreshImage,
    currency,
    currentHousehold,
    householdPermission,
    getHouseholdError,
    householdSharingList,
    householdMilestones,
    integrations,
    kffs,
    members,
    showHouseholdSignals,
    showSignalsPanel,
    householdSignals,
    allMemberCategories,
    loadedHouseholdFields,
    showDataLoader,
    showMilestones,
    showTargetMaps,
    targetMaps,
    // targetMapSummary,
    tempHousehold,
    isStencil,
    showReportHistory,
    reports,
    refreshFileStorage,
    googleFolder,
    googleFolderError,
    boxFolder,
    boxFolderError,
    dropboxFolder,
    dropboxFolderError,
    loadFolderError,
    justUpdatedMilestone,
    invocationId,
    showingMilestoneModal,
    userOpenedMilestoneModal,
    justSynced,
  } = useSelector((state) => ({
    advisors: state.households.currentHousehold.advisors,
    assetMapImage: state.households.amrImage,
    fetchRefreshImage: state.households.fetchRefreshImage,
    currency: state.households.householdCurrency,
    currentHousehold: state.households.currentHousehold,
    householdPermission: state.households.householdPermission,
    getHouseholdError: state.households.householdInfoError,
    householdSharingList: state.households.householdSharingList,
    householdMilestones: state.households.householdMilestones,
    integrations: state.households.currentHousehold.integrations,
    kffs: state.households.householdKffPanel,
    members: state.households.currentHousehold.householdmembers,
    showHouseholdSignals: state.user.showHouseholdSignals,
    showSignalsPanel: state.user.showSignalsPanel,
    householdSignals: state.households.householdSignals,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    showDataLoader: state.user.showDataLoader,
    showMilestones: state.user.showMilestones,
    showTargetMaps: state.user.showTargetMaps,
    targetMaps: state.targetMaps.targetMaps,
    tempHousehold: state.households.tempHousehold,
    isStencil: state.households.isStencil,
    showReportHistory: state.user.showReportHistory,
    reports: state.households.householdReports,
    refreshFileStorage: state.households.refreshFileStorage,
    googleFolder: state.households.googleFolder,
    googleFolderError: state.households.googleFolderError,
    boxFolder: state.households.boxFolder,
    boxFolderError: state.households.boxFolderError,
    dropboxFolder: state.households.dropboxFolder,
    dropboxFolderError: state.households.dropboxFolderError,
    loadFolderError: state.households.loadFolderError,
    justUpdatedMilestone: state.households.justUpdatedMilestone,
    invocationId: state.configs.invocationId,
    showingMilestoneModal: state.households.showingMilestoneModal,
    userOpenedMilestoneModal: state.households.userOpenedMilestoneModal,
    justSynced: state.households.justSynced,
  }));
  const [showingMenus, setShowingMenus] = useState({
    milestones: false,
    members: false,
    advisors: false,
    integrations: false,
    box: false,
    dropbox: false,
    googledrive: false,
    assetMap: false,
    kffs: false,
    signals: false,
    dataLoader: false,
    targetMaps: false,
  });
  const [googleFiles, setGoogleFiles] = useState();
  const [dropboxFiles, setDropboxFiles] = useState();
  const [boxFiles, setBoxFiles] = useState();
  const [summaryList, setSummaryList] = useState([
    {
      column: 1,
      heading: 'Milestones',
      value: 'milestones',
      link: '../milestones',
      showIcon: true,
      isVisible: false,
      clickMenu: () => toggleShowMoreMenu('milestones'),
      menuItems: [
        {
          label: 'Assign Milestone',
          name: 'add_milestone',
          image: addMilestone,
          onClick: () => closeMenu(toggleShowMilestoneModal, true),
        },
        {
          label: 'History',
          name: 'milestone_history',
          image: historyIcon,
          link: `../milestones`,
          // onClick: () =>
          //   history.push(`/household/${householdId}/milestones`),
        },
      ],
    },
    {
      column: 1,
      heading: 'Members',
      value: 'members',
      link: `../relationships`,
      showIcon: true,
      isVisible: true,
      clickMenu: () => toggleShowMoreMenu('members'),
      menuItems: [
        {
          label: 'Add Member',
          name: 'add_member',
          image: userAdd,
          onClick: () => closeMenu(toggleShowMemberModal),
        },
        {
          label: 'Add Entity',
          name: 'add_entity',
          image: addEntity,
          onClick: () => closeMenu(toggleShowEntityModal),
        },
      ],
    },
    {
      column: 1,
      heading: 'Advisors',
      value: 'advisors',
      noLink: true,
      showIcon: true,
      isVisible: true,
      clickMenu: () => toggleShowMoreMenu('advisors'),
      menuItems: [
        {
          label: 'Add Advisor',
          name: 'add_advisor',
          image: userAdd,
          onClick: () => closeMenu(toggleShowAdvisorModal),
        },
      ],
    },
    {
      column: 1,
      heading: 'Integrations',
      link: `../info`,
      value: 'integrations',
      showIcon: false,
      isVisible: true,
      clickMenu: () => toggleShowMoreMenu('integrations'),
      menuItems: [
        {
          label: 'Edit Integrations',
          name: 'edit_integrations',
          image: pencil,
          link: '/integration',
        },
      ],
      footer: {
        isVisible: false,
        text: 'Auto Sync is Enabled',
        image: sync,
      },
    },
    {
      column: 1,
      heading: 'Box',
      fileStorage: true,
      icon: boxIcon,
      value: 'box',
      showIcon: false,
      isVisible: false,
      clickMenu: () => toggleShowMoreMenu('box'),
      menuItems: [
        {
          label: 'Refresh',
          name: 'refresh_box',
          image: sync,
          onClick: () => closeMenu(fetchUpdatedFileStorage, 'box'),
        },
      ],
    },
    {
      column: 1,
      heading: 'Dropbox',
      fileStorage: true,
      icon: dropboxIcon,
      value: 'dropbox',
      showIcon: false,
      isVisible: false,
      clickMenu: () => toggleShowMoreMenu('dropbox'),
      menuItems: [
        {
          label: 'Refresh',
          name: 'refresh_dropbox',
          image: sync,
          onClick: () => closeMenu(fetchUpdatedFileStorage, 'dropbox'),
        },
      ],
    },
    {
      column: 1,
      heading: 'Google Drive',
      fileStorage: true,
      icon: googleDriveIcon,
      value: 'googledrive',
      showIcon: false,
      isVisible: false,
      clickMenu: () => toggleShowMoreMenu('googledrive'),
      menuItems: [
        {
          label: 'Refresh',
          name: 'refresh_google_drive',
          image: sync,
          onClick: () => closeMenu(fetchUpdatedFileStorage, 'googledrive'),
        },
      ],
    },
    {
      column: 2,
      heading: 'Asset-Map',
      value: 'assetMap',
      link: `../assetmap`,
      isVisible: true,
      showImage: true,
      // clickMenu: () => toggleShowMoreMenu('assetMap'),
      // menuItems: [
      //   {
      //     label: 'Refresh Preview',
      //     image: sync,
      //     onClick: () => fetchUpdatedImage(),
      //   },
      // ],
    },
    {
      column: 2,
      heading: 'Key Financials',
      value: 'kffs',
      link: `../kffs`,
      clickMenu: () => toggleShowMoreMenu('kffs'),
      isVisible: true,
      menuItems: [
        {
          label: 'Edit KFF Settings',
          image: pencil,
          link: '/member/kff',
          name: 'edit_kffs',
        },
      ],
    },
    {
      column: 2,
      heading: 'Signals',
      value: 'signals',
      link: `../signals`,
      showIcon: true,
      clickMenu: () => toggleShowMoreMenu('signals'),
      isVisible: true,
      menuItems: [
        {
          label: 'Hide Panel',
          name: 'hide',
          image: hide,
          onClick: () => hideSignalsPanel(),
        },
      ],
    },
    {
      column: 2,
      heading: 'Data Loader',
      value: 'dataLoader',
      link: `../loader`,
      isVisible: false,
      clickMenu: () => toggleShowMoreMenu('dataLoader'),
      menuItems: [
        {
          label: 'Upload Data',
          image: upload,
          link: `../loader`,
          name: 'upload',
        },
        // {
        //   label: 'Export Financials',
        //   image: download,
        //   downloadLink: CSVLink,
        // },
      ],
    },
    {
      column: 3,
      heading: 'Target-Maps',
      value: 'targetMaps',
      link: `../targetmap`,
      isVisible: true,
      clickMenu: () => toggleShowMoreMenu('targetMaps'),
      menuItems: [
        {
          label: 'Add Target-Map',
          image: plusCircleDark,
          name: 'add_tm',
          onClick: () => closeMenu(toggleShowNewTargetMapModal),
        },
        {
          label: 'Preferences',
          image: settingsDark,
          link: '/member/tmpref',
        },
      ],
    },
    {
      column: 3,
      heading: 'Reports',
      value: 'reports',
      link: '../reports?panel=history',
      isVisible: true,
      showIcon: true,
      menuItems: [
        {
          label: 'Refresh',
          name: 'refresh_reports',
          image: sync,
          onClick: () => fetchReports(),
        },
      ],
    },
  ]);
  const [membersList, setMembersList] = useState([]);
  const [leftHouseholdData, setLeftHouseholdData] = useState([]);
  const [centerHouseholdData, setCenterHouseholdData] = useState([]);
  const [rightHouseholdData, setRightHouseholdData] = useState([]);
  const [showPermissionDisplay, setShowPermissionDisplay] = useState(false);
  const [loadedHousehold, setLoadedHousehold] = useState(false);
  const [permissionType, setPermissionType] = useState();
  const [canEditHousehold, setCanEditHousehold] = useState(false);
  const [refreshingAmr, setRefreshingAmr] = useState(false);
  const [signals, setSignals] = useState([]);
  const [reportsOffset, setReportsOffset] = useState(0);
  const [milestonesOffset, setMilestonesOffset] = useState(0);
  const [enabledFileStorage, setEnabledFileStorage] = useState([]);
  const [loadingFolderContent, setLoadingFolderContent] = useState({
    box: true,
    dropbox: true,
    googledrive: true,
  });
  const [refreshingContent, setRefreshingContent] = useState({
    box: false,
    dropbox: false,
    googledrive: false,
  });
  const [loadingError, setLoadingError] = useState({
    box: false,
    dropbox: false,
    googledrive: false,
  });
  const [folderErrorMessage, setFolderErrorMessage] = useState(
    'Error loading folder. Please check that it has not been deleted.'
  );

  useEffect(() => {
    if (loadFolderError) {
      setFolderErrorMessage(loadFolderError);
    }
  }, [loadFolderError]);

  useEffect(() => {
    if (currentHousehold?.id) {
      setLoadedHousehold(true);
    }
    return () => {
      setLoadedHousehold(false);
    };
  }, [currentHousehold?.id]);

  useEffect(() => {
    if (members) {
      setMembersList(members);
    }
  }, [members]);

  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('householdReports', false));
      dispatch(setHouseholdMilestones(false));
    };
  }, []);

  useEffect(() => {
    if (householdPermission) {
      if (householdPermission.hasOwnProperty('status')) {
        setShowPermissionDisplay(true);
        if (householdPermission?.status) {
          setPermissionType(householdPermission.status.toString());
          setCanEditHousehold(false);
        }
      } else {
        if (householdPermission === 'read' || householdPermission === 'write') {
          setShowPermissionDisplay(false);
          setCanEditHousehold(true);
        } else {
          setShowPermissionDisplay(true);
          setCanEditHousehold(false);
        }
        setPermissionType(householdPermission);
      }
    }
  }, [householdPermission]);

  useEffect(() => {
    setLeftHouseholdData(filterData(summaryList, 1));
    setCenterHouseholdData(filterData(summaryList, 2));
    setRightHouseholdData(filterData(summaryList, 3));
  }, [summaryList]);

  //HOOK TO GET CONNECT INVITE STATUS AND ADD IT TO HOUSEHOLD MEMBER
  useEffect(() => {
    if (householdSharingList && members) {
      const inviteList = householdSharingList.reduce((acc, item) => {
        if (item?.consumerinvite?.id) {
          const inviteDetails = {
            ...item.householdmember,
            status: item?.consumerinvite?.status || 'inactive',
          };
          return [...acc, inviteDetails];
        }
        return acc;
      }, []);
      const transformedMembers = [...members].map((mem) => {
        const member = { ...mem };
        const invite = inviteList.find((invite) => invite.uuid === mem.uuid);
        member.status = invite?.status;
        return member;
      });
      setMembersList(transformedMembers);
    }
  }, [householdSharingList, members]);

  useEffect(() => {
    if (assetMapImage) {
      if (assetMapImage.hasOwnProperty('is_error') && assetMapImage.is_error) {
        // setRefreshingAmr(true);
        ErrorNotification('Error Generating AMR Image Preview', 5);
        // } else if (
        //   assetMapImage.hasOwnProperty('fresh') &&
        //   !assetMapImage.fresh
        // ) {
        //   setRefreshingAmr(true);
        // } else {
        //   setRefreshingAmr(false);
        // }
      }
    }
  }, [assetMapImage]);

  useEffect(() => {
    if (fetchRefreshImage !== null) {
      setRefreshingAmr(fetchRefreshImage);
    }
  }, [fetchRefreshImage]);

  useEffect(() => {
    if (showMilestones) {
      dispatch(getHouseholdMilestones(householdId, { limit: 5 }));
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'milestones') {
            panel.isVisible = true;
          }
          return panel;
        })
      );
    }
  }, [showMilestones, householdId]);

  useEffect(() => {
    if (showReportHistory) {
      dispatch(getHouseholdReports(householdId, { limit: 10 }));
    }
  }, [showReportHistory, householdId]);

  useEffect(() => {
    if (showTargetMaps !== undefined) {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'targetMaps') {
            panel.isVisible = showTargetMaps && !isStencil;
          } else if (panel.value === 'kffs') {
            panel.isVisible = !isStencil;
            panel.column = showTargetMaps ? 2 : 3;
          } else if (panel.value === 'integrations') {
            panel.column = showTargetMaps && !isStencil ? 1 : 3;
          } else if (panel.value === 'dataLoader') {
            panel.column = isStencil ? 3 : 2;
          }
          return panel;
        })
      );
    }
  }, [showTargetMaps, isStencil]);

  useEffect(() => {
    if (showDataLoader !== undefined) {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'dataLoader') {
            panel.isVisible = showDataLoader;
          }
          return panel;
        })
      );
    }
  }, [showDataLoader]);

  useEffect(() => {
    if (showReportHistory !== undefined) {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'reports') {
            panel.isVisible = showReportHistory;
          }
          return panel;
        })
      );
    }
  }, [showReportHistory]);

  useEffect(() => {
    if (showHouseholdSignals !== undefined && showSignalsPanel !== undefined) {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'signals') {
            panel.isVisible =
              showHouseholdSignals && showSignalsPanel && !isStencil;
          }
          return panel;
        })
      );
    }
  }, [showHouseholdSignals, showSignalsPanel, isStencil]);

  useEffect(() => {
    if (householdSignals) {
      const signalOptions = householdSignals?.signals.map((signal) => {
        const matching = signalLabels.find((ind) => ind.key === signal.name);
        return { ...signal, ...matching };
      });
      signalOptions.sort((a, b) => a.order - b.order);
      setSignals(signalOptions);
    } else {
      setSignals([]);
    }
  }, [householdSignals]);

  useEffect(() => {
    if (refreshingAmr) {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'assetMap') {
            panel.refreshing = true;
          }
          return panel;
        })
      );
    } else {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'assetMap') {
            panel.refreshing = false;
          }
          return panel;
        })
      );
    }
  }, [refreshingAmr]);

  useEffect(() => {
    if (integrations) {
      if (integrations?.available) {
        const fileStorageIntegrations = integrationOptions.reduce(
          (acc, curr) => {
            if (curr.file_storage) {
              return [...acc, curr.id];
            }
            return acc;
          },
          []
        );
        const enabledStorageIntegrations = fileStorageIntegrations.reduce(
          (acc, curr) => {
            if (integrations?.partners?.[curr]?.enabled) {
              return [...acc, curr];
            }
            return acc;
          },
          []
        );
        setEnabledFileStorage(enabledStorageIntegrations);

        let addSyncAllAction = false;
        for (let partner in integrations?.partners) {
          if (integrations?.partners[partner]?.sync?.available === true) {
            addSyncAllAction = true;
          }
        }

        setSummaryList(
          summaryList.map((panel) => {
            if (integrations?.partners.hasOwnProperty(panel.value)) {
              panel.isVisible = integrations?.partners[panel.value]?.enabled;
            }
            if (panel.value === 'integrations' && addSyncAllAction) {
              let add = true;
              panel.menuItems.forEach((menuItem) => {
                if (menuItem.label === 'Sync All') {
                  add = false;
                }
              });
              if (add) {
                panel.menuItems.push({
                  label: 'Sync All',
                  name: 'sync_integrations',
                  image: sync,
                  onClick: () => syncAll(),
                });
              }
            }
            return panel;
          })
        );
      }
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'integrations') {
            if (
              integrations.hasOwnProperty('available') &&
              integrations.hasOwnProperty('partners')
            ) {
              let hasIntegrations = Object.entries(
                integrations.partners
              ).length;
              panel.isVisible = integrations.available && hasIntegrations;
              panel.footer.isVisible = integrations?.auto_sync ? true : false;
            } else {
              panel.isVisible = false;
            }
          }
          return panel;
        })
      );
    }
    return () => {
      setEnabledFileStorage([]);
      setSummaryList(
        summaryList.map((panel) => {
          if (panel?.fileStorage) {
            panel.isVisible = false;
          }
          return panel;
        })
      );
    };
  }, [integrations]);

  useEffect(() => {
    if (
      enabledFileStorage &&
      enabledFileStorage.length !== 0 &&
      currentHousehold?.uuid
    ) {
      enabledFileStorage.forEach((item) => {
        return dispatch(
          getHouseholdIntegrationFolder(currentHousehold?.uuid, item)
        );
      });
    }
  }, [enabledFileStorage]);

  useEffect(() => {
    if (googleFolder) {
      setGoogleFiles(googleFolder);
      setLoadingError({
        ...loadingError,
        googledrive: false,
      });
      setRefreshingContent({
        ...refreshingContent,
        googledrive: false,
      });
      setLoadingFolderContent({
        ...loadingFolderContent,
        googledrive: false,
      });
    }
  }, [googleFolder]);

  useEffect(() => {
    if (googleFolderError) {
      setLoadingError({
        ...loadingError,
        googledrive: true,
      });
      setRefreshingContent({
        ...refreshingContent,
        googledrive: false,
      });
      setLoadingFolderContent({
        ...loadingFolderContent,
        googledrive: false,
      });
    }
  }, [googleFolderError]);

  useEffect(() => {
    if (dropboxFolder) {
      setDropboxFiles(dropboxFolder);
      setLoadingError({
        ...loadingError,
        dropbox: false,
      });
      setRefreshingContent({
        ...refreshingContent,
        dropbox: false,
      });
      setLoadingFolderContent({
        ...loadingFolderContent,
        dropbox: false,
      });
    }
  }, [dropboxFolder]);

  useEffect(() => {
    if (dropboxFolderError) {
      setLoadingError({
        ...loadingError,
        dropbox: true,
      });
      setRefreshingContent({
        ...refreshingContent,
        dropbox: false,
      });
      setLoadingFolderContent({
        ...loadingFolderContent,
        dropbox: false,
      });
    }
  }, [dropboxFolderError]);

  useEffect(() => {
    if (boxFolder) {
      setBoxFiles(boxFolder);
      setLoadingError({
        ...loadingError,
        box: false,
      });
      setRefreshingContent({
        ...refreshingContent,
        box: false,
      });
      setLoadingFolderContent({
        ...loadingFolderContent,
        box: false,
      });
    }
  }, [boxFolder]);

  useEffect(() => {
    if (boxFolderError) {
      setLoadingError({
        ...loadingError,
        box: true,
      });
      setRefreshingContent({
        ...refreshingContent,
        box: false,
      });
      setLoadingFolderContent({
        ...loadingFolderContent,
        box: false,
      });
    }
  }, [boxFolderError]);

  useEffect(() => {
    if (refreshFileStorage) {
      dispatch(
        getHouseholdIntegrationFolder(
          currentHousehold?.uuid,
          refreshFileStorage
        )
      );
      setRefreshingContent({
        ...refreshingContent,
        [refreshFileStorage]: true,
      });
      setLoadingFolderContent({
        ...loadingFolderContent,
        [refreshFileStorage]: true,
      });
    }
  }, [refreshFileStorage]);

  useEffect(() => {
    if (getHouseholdError && getHouseholdError.hasOwnProperty('status')) {
      if (getHouseholdError) {
        setShowPermissionDisplay(true);
      }
    }
  }, [getHouseholdError]);

  useEffect(() => {
    if (reports) {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'reports') {
            panel.isVisible = showReportHistory && !isStencil;
            if (reports?.count > 10) {
              panel.pagination = {
                prev: () => {
                  dispatch(
                    getHouseholdReports(householdId, {
                      limit: 10,
                      offset: reportsOffset - 10,
                    })
                  );
                  setReportsOffset(reportsOffset - 10);
                },
                next: () => {
                  dispatch(
                    getHouseholdReports(householdId, {
                      limit: 10,
                      offset: reportsOffset + 10,
                    })
                  );
                  setReportsOffset(reportsOffset + 10);
                },
                total: reports.count < 30 ? reports.count : 30,
                limit: 10,
                offset: reportsOffset,
                more: {
                  link: '../reports?panel=history',
                  text: 'Report History',
                },
              };
            } else {
              panel.pagination = false;
            }
          }
          return panel;
        })
      );
    }
  }, [reports, showReportHistory, isStencil]);

  useEffect(() => {
    if (justUpdatedMilestone) {
      setMilestonesOffset(0);
    }
  }, [justUpdatedMilestone]);

  useEffect(() => {
    if (householdMilestones) {
      setSummaryList(
        summaryList.map((panel) => {
          if (panel.value === 'milestones') {
            if (householdMilestones?.count > 5) {
              panel.pagination = {
                prev: () => {
                  dispatch(
                    getHouseholdMilestones(householdId, {
                      limit: 5,
                      offset: milestonesOffset - 5,
                    })
                  );
                  setMilestonesOffset(milestonesOffset - 5);
                },
                next: () => {
                  dispatch(
                    getHouseholdMilestones(householdId, {
                      limit: 5,
                      offset: milestonesOffset + 5,
                    })
                  );
                  setMilestonesOffset(milestonesOffset + 5);
                },
                total:
                  householdMilestones.count < 30
                    ? householdMilestones.count
                    : 30,
                limit: 5,
                offset: milestonesOffset,
                more: {
                  link: '../milestones',
                  text: 'Milestones History',
                },
              };
            } else {
              panel.pagination = false;
            }
          }
          return panel;
        })
      );
    }
  }, [householdMilestones]);

  useEffect(() => {
    if (showingMilestoneModal === true) {
      SendTrackingEvent(
        invocationId,
        'click',
        'milestone_assign',
        'household_summary',
        {
          household: householdId,
          user_opened: userOpenedMilestoneModal,
        }
      );
    }
  }, [showingMilestoneModal]);

  useEffect(() => {
    if (justSynced) {
      dispatch(getHouseholdInfo(householdId));
    }
  }, [justSynced]);

  const toggleShowMoreMenu = (panel) => {
    showingMenus[panel] = !showingMenus[panel];
    setShowingMenus(showingMenus);
  };

  const toggleMenus = (item) => {
    setShowingMenus({
      ...showingMenus,
      [item.value]: !showingMenus[item.value],
    });
  };

  const closeMenu = (toggleFunction, userOpened) => {
    const closeAllMenus = Object.entries(showingMenus).map((e) => {
      return { [e[0]]: false };
    });
    setShowingMenus(closeAllMenus);
    if (toggleFunction) {
      return dispatch(toggleFunction(true, userOpened));
    }
  };

  const fetchReports = () => {
    closeMenu();
    return dispatch(getHouseholdReports(householdId, { limit: 10 }));
  };

  const hideSignalsPanel = () => {
    closeMenu();
    return dispatch(toggleShowSignalsPanel(true));
  };

  const filterData = (data, column) => {
    return data.filter((panel) => {
      return panel.column === column && panel.isVisible;
    });
  };

  const memberOnClick = (member) => {
    dispatch(getMemberInfo(currentHousehold.id, member.id));
    dispatch(toggleShowMemberModal(true, true));
  };

  const entityOnClick = (member) => {
    dispatch(getMemberInfo(currentHousehold.id, member.id));
    dispatch(toggleShowEntityModal(true, true));
  };

  const generateMembers = () => {
    if (membersList?.length) {
      return membersList.map((member) => {
        member.label = HtmlToString(member.name);
        let memCategory = member.category;
        const category = allMemberCategories.find(
          (cat) => cat.value === member.category
        );
        if (category?.label) {
          memCategory = category?.label;
        }
        if (member.member_type === 'member') {
          let age = GenerateAge(member.date_of_birth);
          member.value = `${memCategory} (${age})`;
          member.textClick = () => memberOnClick(member);
          return member;
        }
        if (member.member_type === 'entity') {
          member.value = memCategory;
          member.textClick = () => entityOnClick(member);
          return member;
        }
        return member;
      });
    } else {
      return [
        {
          label: 'No Members',
          value: `Member`,
          showIcon: false,
          showNew: true,
          onClick: () => dispatch(toggleShowMemberModal(true, false)),
        },
      ];
    }
  };

  const generateAdvisors = () => {
    if (advisors) {
      return advisors.map((advisor) => {
        advisor.label = `${advisor.name}`;
        advisor.value = `<a href='mailto:${advisor.email}'>${advisor.email}</a>`;
        return advisor;
      });
    } else {
      return [];
    }
  };

  const fetchFolderContent = (integration, folderId) => {
    //Set a loading value and pass that in to Panel
    //Need to stop loading value when fetched
    setLoadingFolderContent({
      ...loadingFolderContent,
      [integration]: true,
    });
    dispatch(
      getHouseholdIntegrationFolder(
        currentHousehold?.uuid,
        integration,
        folderId
      )
    );
  };

  const generateStoragePanel = (integration) => {
    let parentFolder = [];
    switch (integration) {
      case 'googledrive':
        parentFolder = googleFiles;
        break;
      case 'dropbox':
        parentFolder = dropboxFiles;
        break;
      case 'box':
        parentFolder = boxFiles;
        break;
      default:
        parentFolder = [];
    }
    return (
      <FileStorageExplorer
        integration={integration}
        folder={parentFolder}
        getFolder={fetchFolderContent}
        refreshing={refreshingContent[integration]}
        loadingContent={loadingFolderContent[integration]}
        loadingError={loadingError[integration]}
        loadingErrorMessage={folderErrorMessage}
        toggleDisplayModal={() =>
          dispatch(
            toggleShowUploadFileModal(
              true,
              integration,
              parentFolder?.id,
              'household'
            )
          )
        }
      />
    );
  };

  const generateIntegrations = () => {
    if (integrations && integrations.available) {
      return integrationOptions.reduce((acc, integration) => {
        if (integrations.partners[integration.id]) {
          integration.label = integration.name;
          integration.value = integrations.partners[integration.id];
          integration.textClick = navigateIntegration;
          return [...acc, integration];
        }
        return acc;
      }, []);
    } else {
      return [];
    }
  };

  const generateKFFs = () => {
    if (kffs) {
      return kffs.map((kff) => {
        kff.label = `${kff.label}`;
        kff.currency = currency;
        return kff;
      });
    } else {
      return [];
    }
  };

  const generateSignals = () => {
    if (signals) {
      return signals.reduce((acc, signal) => {
        if (signal.enabled) {
          signal.value = signal.calculated;
          signal.snoozed = signal?.snooze?.snoozed || signal?.snooze?.muted;
          return [...acc, signal];
        }
        return acc;
      }, []);
    } else {
      return [];
    }
  };

  const generateTargetMaps = () => {
    if (targetMaps) {
      return targetMaps.map((targetMap) => {
        targetMap.label = (
          <Link to={`/household/${householdId}/targetmap/${targetMap.id}`}>
            {HtmlToString(targetMap.name)}
          </Link>
        );
        targetMap.value = `${targetMap.funded}%`;
        return targetMap;
      });
    }
  };

  const generateEmptyListItem = (item, index) => {
    return (
      <ListItem
        index={index || 0}
        key={index || 0}
        item={{}}
        loading={true}
        heading={item.heading}
        showIcon={item.showIcon}
        showImage={item.showImage}
      />
    );
  };

  const clickShare = (report) => {
    dispatch(getReportShareTypes(householdId, report));
    dispatch(toggleShowReportShareModal(true, report));
  };

  const generateListItems = (item) => {
    if (item.value === 'members') {
      if (loadedHousehold) {
        const formattedMembers = generateMembers();
        if (formattedMembers.length >= 1 && loadedHouseholdFields) {
          return formattedMembers.map((content, index) => {
            return (
              <ListItem
                key={index}
                index={index}
                item={content}
                value={item.value}
                heading={item.heading}
                showNew={content.showNew}
                showIcon={item.showIcon}
                showImage={item.showImage}
              />
            );
          });
        }
      } else {
        if (
          tempHousehold !== undefined &&
          tempHousehold.householdmember_count !== 0
        ) {
          let arr = [...Array(tempHousehold.householdmember_count).keys()];
          return arr.map((content, index) => {
            return (
              <ListItem
                index={index}
                key={index}
                item={{}}
                loading={true}
                value={item.value}
                heading={item.heading}
                showIcon={item.showIcon}
                showImage={item.showImage}
              />
            );
          });
        } else {
          return [0, 1, 2].map((index) => {
            return generateEmptyListItem(item, index);
          });
        }
      }
    } else if (item.value === 'milestones') {
      if (
        householdMilestones &&
        householdMilestones.hasOwnProperty('results')
      ) {
        if (
          householdMilestones.results &&
          !isEmpty(householdMilestones.results)
        ) {
          const milestonesList = householdMilestones.results.slice(0, 5);
          return milestonesList.map((content, index) => {
            return (
              <MilestoneListItem
                key={index}
                milestone={content}
                showComments={false}
                isPanel={true}
                noBorderBottom={
                  !item.pagination && index + 1 === milestonesList?.length
                }
              />
            );
          });
        } else {
          return (
            <ListItem
              key={0}
              index={0}
              item={{
                label: 'No Milestones',
                buttonText: 'Assign Milestone',
                onClick: () => dispatch(toggleShowMilestoneModal(true, true)),
              }}
              showNew={true}
            />
          );
        }
      } else {
        return [0, 1, 2, 3, 4].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
    } else if (item.value === 'advisors') {
      let advisors = generateAdvisors();
      if (advisors.length >= 1) {
        return advisors.map((content, index) => {
          return (
            <ListItem
              key={index}
              index={index}
              item={content}
              value={item.value}
              heading={item.heading}
              showIcon={item.showIcon}
              showImage={item.showImage}
            />
          );
        });
      } else {
        return generateEmptyListItem(item);
      }
    } else if (item.value === 'integrations') {
      let integrations = generateIntegrations();
      if (integrations.length >= 1) {
        return integrations.map((content, index) => {
          return (
            <ListItem
              key={index}
              index={index}
              item={content}
              value={item.value}
              heading={item.heading}
              showIcon={item.showIcon}
              showImage={item.showImage}
              onClick={navigateIntegration}
              onClickSync={updateIntegration}
              syncing={item?.syncingAll}
            />
          );
        });
      } else {
        return [0, 1].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
    } else if (item?.fileStorage) {
      return generateStoragePanel(item.value);
    } else if (item.value === 'kffs') {
      let kffs = generateKFFs();
      if (kffs.length >= 1) {
        return kffs.map((content, index) => {
          return (
            <ListItem
              key={index}
              index={index}
              item={content}
              value={item.value}
              heading={item.heading}
              showIcon={item.showIcon}
              showImage={item.showImage}
            />
          );
        });
      } else {
        return [0, 1, 2, 3, 4].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
    } else if (item.value === 'signals') {
      let generatedSignals = generateSignals();
      if (generatedSignals.length !== 0) {
        return generatedSignals.map((content, index) => {
          return (
            <ListItem
              key={index}
              index={index}
              item={content}
              value={item.value}
              heading={item.heading}
              showIcon={item.showIcon}
              showImage={item.showImage}
            />
          );
        });
      } else if (signals.length >= 1) {
        return (
          <ListItem
            key={0}
            index={0}
            item={{
              label:
                'All signal types have been disabled by your team manager.',
            }}
            value={item.value}
            heading={'Signals'}
            disabled={true}
            showIcon={false}
            showImage={false}
          />
        );
      } else {
        return [0, 1, 2, 3, 4].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
    } else if (item.value === 'dataLoader') {
      return (
        <ListItem
          key={0}
          index={0}
          item={{
            label:
              'Import and sync financial information from other data sources',
          }}
          value={item.value}
          heading={'Data Loader'}
          disabled={true}
          showIcon={false}
          showImage={false}
        />
      );
    } else if (item.value === 'targetMaps') {
      let targetMaps = generateTargetMaps();
      if (targetMaps) {
        if (targetMaps.length >= 1) {
          return targetMaps.map((content, index) => {
            return (
              <TargetMapListItem
                key={index}
                item={content}
                householdId={householdId}
                loading={false}
              />
            );
          });
        } else {
          return <TargetMapListItem key={0} item={{}} showNew={true} />;
        }
      } else {
        return (
          <TargetMapListItem key={0} item={{}} showNew={false} loading={true} />
        );
      }
    } else if (item.value === 'assetMap') {
      if (assetMapImage) {
        // let origin;
        // if (window.location.port === '3000') {
        //   origin = `http://localhost:8000`;
        // } else {
        //   origin = window.location.origin;
        // }
        return (
          <ListItem
            key={0}
            index={0}
            item={{
              src: assetMapImage.url,
              link: `../assetmap`,
            }}
            value={item.value}
            heading={item.heading}
            showIcon={false}
            showImage={item.showImage}
          />
        );
      } else {
        return generateEmptyListItem(item);
      }
    } else if (item.value === 'reports') {
      if (reports.count === undefined) {
        return [0, 1, 2].map((index) => {
          return generateEmptyListItem(item, index);
        });
      }
      if (reports.count > 0) {
        const reportsList = reports.results.slice(0, 10);
        return reportsList.map((report, index) => {
          return (
            <React.Fragment key={index}>
              <ReportListItem
                report={report}
                panel={true}
                borderBottom={index !== reports.count - 1}
                onClickShare={clickShare}
              />
            </React.Fragment>
          );
        });
      }
      // if no reports
      return (
        <AddReportContainer key={1}>
          <p>There are no Reports for this household.</p>
          <Link to={'../reports'}>
            <Button text={'Generate Report'} />
          </Link>
        </AddReportContainer>
      );
    } else {
      return item.content.map((content, index) => {
        return (
          <ListItem
            key={index}
            index={index}
            item={content}
            value={item.value}
            heading={item.heading}
            showIcon={item.showIcon}
            showImage={item.showImage}
            disabled={true}
          />
        );
      });
    }
  };

  const updateIntegration = (integration) => {
    SendTrackingEvent(
      invocationId,
      'action',
      'integration_sync',
      'household_summary',
      {
        household: householdId,
        integration: integration.id,
      }
    );
    return dispatch(syncHouseholdSummary(householdId, integration.id));
  };

  const navigateIntegration = (item) => {
    location.pathname = `../info`;
    location.search = `panel=${item.id}`;
    navigate(location, { state: { panel: item.id } });
  };

  const syncAll = () => {
    closeMenu();
    setSummaryList(
      summaryList.map((panel) => {
        if (panel.value === 'integrations') {
          panel.syncingAll = true;
        }
        return panel;
      })
    );
    return dispatch(setBulkSync('household_summary'));
  };

  //Need to check what type of permission
  return showPermissionDisplay ? (
    <PermissionContainer type={permissionType} />
  ) : (
    <PageContainer>
      {(currentHousehold && currentHousehold.hasOwnProperty('name')) ||
      (tempHousehold && tempHousehold.hasOwnProperty('name')) ? (
        <HouseholdHeadingContainer>
          <HouseholdHeading>
            <HouseholdLabel>Household:</HouseholdLabel>
            {currentHousehold.is_linked && (
              <HouseholdLinked
                src={link}
                alt="linked"
                data-image={`household-linked`}
              />
            )}
            <HouseholdName>
              {currentHousehold.name || tempHousehold.name}
            </HouseholdName>
          </HouseholdHeading>
          {canEditHousehold && <EditLink to="../info">Edit</EditLink>}
        </HouseholdHeadingContainer>
      ) : (
        <LoadingPlaceholder margin="0 0 20px 0" height="24px" width="300px" />
      )}

      <ColumnsContainer>
        <ColumnContainer>
          {leftHouseholdData.map((item, index) => {
            return (
              <Panel
                key={index}
                item={item}
                transform={generateListItems}
                showingMenu={showingMenus[item.value]}
                toggleMenu={toggleMenus}
                household={currentHousehold}
              />
            );
          })}
        </ColumnContainer>
        <ColumnContainer>
          {centerHouseholdData.map((item, index) => {
            return (
              <Panel
                key={index}
                item={item}
                transform={generateListItems}
                showingMenu={showingMenus[item.value]}
                toggleMenu={toggleMenus}
                household={currentHousehold}
              />
            );
          })}
        </ColumnContainer>
        <ColumnContainer>
          {rightHouseholdData.map((item, index) => {
            return (
              <Panel
                key={index}
                item={item}
                transform={generateListItems}
                showingMenu={showingMenus[item.value]}
                toggleMenu={toggleMenus}
                household={currentHousehold}
              />
            );
          })}
        </ColumnContainer>
      </ColumnsContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 15px 12px;
  }
  padding: 25px 5px 25px 20px;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const ColumnsContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ColumnContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin-right: 0px;
  }
  display: flex;
  flex-direction: column;
  flex: 1 1 270px;
  margin-right: 15px;
`;

const HouseholdHeadingContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin-bottom: 15px;
  }
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
`;

const HouseholdLabel = styled.span`
  margin-right: 4px;
`;

const HouseholdHeading = styled.div`
  font-weight: ${fonts.bold};
  font-size: 18px;
  margin-right: 15px;
`;

const HouseholdName = styled.span`
  margin-left: 4px;
  font-weight: ${fonts.light};
  flex: 0 0 auto;
`;

const HouseholdLinked = styled.img`
  width: 16px;
  max-height: 13px;
  margin-left: 2px;
`;

const EditLink = styled(Link)`
  font-weight: ${fonts.medium};
  font-size: 14px;
  color: ${colors.lightBlue};
  cursor: pointer;
  line-height: 21px;
`;

const AddReportContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  border: 1px solid ${colors.lightGrey};
  padding: 12px 15px;
  border-radius: 5px;
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
  p {
    margin-bottom: 15px;
  }
`;

export default HouseholdSummary;
