/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SmallTable, Pagination, Button, TypeaheadSearch } from '../components';
import {
  getDeletedHouseholds,
  restoreDeletedHouseholds,
} from '../store/actions';
import Axios from 'axios';
import Cookies from 'js-cookie';

const DeletedHouseholdsContainer = ({ accessor }) => {
  const dispatch = useDispatch();
  const { user, deletedHouseholds, limit, userId } = useSelector((state) => ({
    user: state.user.user,
    deletedHouseholds: state.households.deletedHouseholds,
    limit: state.configs?.pagination?.default_limit || 25,
    userId: state.user.user.id,
  }));
  const [households, setHouseholds] = useState([]);
  const [loadedHouseholds, setLoadedHouseholds] = useState(false);
  const [selectedHouseholds, setSelectedHouseholds] = useState([]);
  const [sortValue, setSortValue] = useState('modified_utc');
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [noOptionMessage, setNoOptionMessage] = useState(
    'Start typing to begin search'
  );
  const headingOnClick = (value) => {
    setTableHeadings(
      tableHeadings.map((heading) => {
        if (heading.value === value.value) {
          heading.ascending = !heading.ascending;
          heading.active = true;
        } else {
          heading.active = false;
        }
        return heading;
      })
    );
  };
  const [tableHeadings, setTableHeadings] = useState([
    {
      text: ' ',
      value: 'is_selected',
      table: 'deletedHouseholds',
      display: true,
      sortable: false,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Household',
      value: 'name',
      table: 'deletedHouseholds',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Advisor',
      value: 'member.name',
      table: 'deletedHouseholds',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Customer',
      value: 'customer.name',
      table: 'deletedHouseholds',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Last Modified',
      value: 'last_modified_by_utc',
      table: 'deletedHouseholds',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
      active: true,
    },
  ]);
  const addPagination = () => getCount() > limit;

  useEffect(() => {
    if (deletedHouseholds) {
      const transformedHouseholds = deletedHouseholds.map((household) => {
        household.label = household.name;
        household.value = household.ampid;
        household.id = household.ampid;
        household.is_selected = false;
        return household;
      });
      setHouseholds(transformedHouseholds);
      setLoadedHouseholds(true);
    }
  }, [deletedHouseholds]);

  useEffect(() => {
    if (userId) {
      if (accessor === 'customer') {
        dispatch(getDeletedHouseholds('customer', user.customer.id));
      } else {
        dispatch(getDeletedHouseholds('member', userId));
      }
    }
  }, [userId]);

  useEffect(() => {
    if (tableHeadings && loadedHouseholds) {
      const activeHeading = tableHeadings.find((heading) => heading.active);
      let newSortValue = activeHeading?.value;
      if (!activeHeading?.ascending) {
        newSortValue = `-${activeHeading?.value}`;
      }
      if (newSortValue !== sortValue) {
        if (accessor === 'customer') {
          dispatch(
            getDeletedHouseholds('customer', user.customer.id, newSortValue)
          );
        } else {
          dispatch(getDeletedHouseholds('member', userId, newSortValue));
        }
        setSortValue(newSortValue);
      }
    }
  }, [tableHeadings]);

  useEffect(() => {
    if (households && loadedHouseholds) {
      setSelectedHouseholds(households.filter((hh) => hh.is_selected));
    }
  }, [households]);

  const changeQuery = (newQuery) => {
    if (newQuery === null || newQuery === '' || newQuery === ' ') {
      setQuery('');
      setNoOptionMessage('Start typing to begin search');
    } else {
      setQuery(newQuery);
      setNoOptionMessage('No results');
    }
  };

  const loadOptions = () => {
    if (query) {
      const CSRFToken = Cookies.get('csrftoken');
      Axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
      Axios.defaults.baseURL = window.location.origin + '/api/v3';
      if (accessor === 'customer') {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: {
            accessor: accessor,
            accessor_id: user.customer.id,
            query: query,
          },
        };
        return Axios.post(
          '/household/deleted_household_search',
          requestOptions
        ).then((res) => res.data);
      } else {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: { accessor: accessor, accessor_id: user.id, query: query },
        };
        return Axios.post(
          '/household/deleted_household_search',
          requestOptions
        ).then((res) => res.data);
      }
    }
  };

  const getCount = () => {
    if (households) {
      return households.length;
    } else {
      return null;
    }
  };

  const toggleSelected = (item) => {
    setHouseholds(
      households.map((household) => {
        if (household.value === item.value) {
          household.is_selected = !household.is_selected;
        }
        return household;
      })
    );
  };

  const handleHouseholdChange = (selections) => {
    let selectedIds = [];
    if (selections) {
      selectedIds = selections?.map((sel) => sel?.value);
    }
    setHouseholds(
      households.map((hh) => {
        hh.is_selected = selectedIds.includes(hh.value);
        return hh;
      })
    );
  };

  const restoreSelectedHouseholds = () => {
    if (selectedHouseholds.length > 0) {
      const ids = selectedHouseholds.map((hh) => hh.value);
      if (accessor === 'customer') {
        dispatch(restoreDeletedHouseholds('customer', user.customer.id, ids));
      } else {
        dispatch(restoreDeletedHouseholds('member', userId, ids));
      }
      setSelectedHouseholds([]);
    }
  };

  return (
    <>
      <TopContent>
        <SelectContainer>
          <TypeaheadSearch
            onChange={(e) => changeQuery(e)}
            label={"Search by Household Name or Advisor's Name"}
            loadOptions={loadOptions}
            showSearchIcon={true}
            isMulti={true}
            value={selectedHouseholds}
            placeholder={'Search by Household or Advisor Name'}
            noOptionMessage={noOptionMessage}
            onSelectChange={(e) => handleHouseholdChange(e)}
          />
        </SelectContainer>
        {selectedHouseholds && selectedHouseholds.length !== 0 && (
          <ButtonContainer>
            <Button
              text="Restore Selected"
              onClick={() => restoreSelectedHouseholds()}
            />
          </ButtonContainer>
        )}
      </TopContent>
      <TableContent>
        <SmallTable
          columnHeadings={tableHeadings}
          type="deleted_households"
          customEmptyResults={true}
          items={households}
          loaded={loadedHouseholds}
          toggleFunction={toggleSelected}
        />
        {addPagination() && (
          <Pagination
            clickNext={() => setOffset(offset + limit)}
            clickPrev={() => setOffset(offset - limit)}
            goToPage={(page) => setOffset(limit * (page - 1))}
            offset={offset}
            count={getCount()}
            limit={limit}
            paddingBottom={'30px'}
          />
        )}
      </TableContent>
    </>
  );
};

const TopContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const SelectContainer = styled.div`
  width: 100%;
  max-width: 450px;
`;

const TableContent = styled.div`
  background: white;
  margin: 0 -40px;
  padding: 40px 40px 0 40px;
  /* height: 100%; */
`;

const ButtonContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

DeletedHouseholdsContainer.propTypes = {
  accessor: PropTypes.string,
};

export default DeletedHouseholdsContainer;
