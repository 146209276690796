/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { colors, fonts, messageColors } from '../../styles/variables';
import { launchLink, home } from '../../assets';

//COMPONENT THAT FORMATS DISPLAY OF SEARCH RESULTS RETURNED FROM INTEGRATIONS
const IntegrationSearchResult = ({ result, generateButton }) => {
  const determineColor = (status) => {
    switch (status) {
      case 'Active':
        return colors.green;
      case 'Inactive':
        return messageColors.warning;
      case 'Deceased':
        return messageColors.error;
      default:
        return messageColors.info;
    }
  };

  return (
    <ResultContainer>
      <RightIntegrationContent>
        <NameRow>
          <Name>{result.name}</Name>
          {result.external_url && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={result.external_url}
            >
              <LaunchImage
                src={launchLink}
                alt="launch"
                data-image="launch-result"
              />
            </a>
          )}
          {result.is_family_head && (
            <img
              src={home}
              alt="head of household"
              data-image="head-of-household"
            />
          )}
          {result.type && <ResultType>{result.type}</ResultType>}
          {result.status && (
            <ResultStatus>
              <Tooltip
                title={result.status}
                position="right"
                trigger="mouseenter click"
                followCursor="false"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
              >
                <svg height="10" width="10">
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    fill={determineColor(result.status)}
                  />
                </svg>
              </Tooltip>
            </ResultStatus>
          )}
          {result.date_of_birth && result.date_of_birth !== '' && (
            <ResultDOB>{result.date_of_birth}</ResultDOB>
          )}
          {result.display_address && result.display_address !== '' && (
            <ResultAddress>
              <Tooltip
                title={result.display_address}
                position="right"
                trigger="mouseenter click"
                followCursor="false"
                delay={0}
                arrow={true}
                arrowSize={'small'}
                animation={'fade'}
                animateFill={false}
                transitionFlip={false}
                size={'small'}
              >
                <img src={home} alt="address" data-image="home-address" />
              </Tooltip>
            </ResultAddress>
          )}
        </NameRow>
        {result.family_name && <SubLabel>{result.family_name}</SubLabel>}
      </RightIntegrationContent>
      <LeftIntegrationContent>{generateButton(result)}</LeftIntegrationContent>
    </ResultContainer>
  );
};

const StatusLabel = styled.span`
  border: 1px solid ${colors.paleGrey};
  font-size: 10px;
  margin-left: 5px;
  background: white;
  color: ${colors.paleGrey};
  border-radius: 2px;
  padding: 1px 4px;
`;

const ResultContainer = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
`;

const RightIntegrationContent = styled.div`
  flex: 1 1 auto;
`;

const LeftIntegrationContent = styled.div`
  flex: 0 0 auto;
`;

const NameRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  img {
    width: 10px;
    margin-left: 5px;
    display: block;
  }
`;

const Name = styled.p`
  font-size: 14px;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LaunchImage = styled.img`
  cursor: pointer;
  width: 11px;
  margin-left: 8px;
`;

const SubLabel = styled.p`
  font-size: 10px;
  color: ${colors.paleGrey};
`;

const ResultType = styled(StatusLabel)`
  text-transform: uppercase;
`;

const ResultStatus = styled.span`
  margin-left: 5px;
  width: 10px;
`;

const ResultDOB = styled.span`
  margin-left: 5px;
  color: ${colors.paleGrey};
  font-size: 10px;
  font-weight: ${fonts.light};
`;

const ResultAddress = styled.span`
  text-align: left;
  img {
    width: 11px;
  }
`;

IntegrationSearchResult.propTypes = {
  result: PropTypes.object,
  generateButton: PropTypes.func,
};

export default IntegrationSearchResult;
