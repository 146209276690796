/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PageHeader } from '../../components';
import {
  getTermsAndConditions,
  updateTermsAndConditions,
} from '../../store/actions';
import { PageContent } from '../../styles/library/layoutStyles';
import { SecondaryHeading } from '../../styles/library/fontStyles';
import { UpdatePageTitle, CloseDropdowns, DateFormatUTC } from '../../utils';
import { fonts } from '../../styles/variables';

const Terms = ({ isConsumer = false }) => {
  const dispatch = useDispatch();
  const { loggedIn, termsAndConditions, isSSO, userRequiresSubscription } =
    useSelector((state) => ({
      loggedIn: state.user.loggedIn,
      termsAndConditions: state.user.termsAndConditions,
      isSSO: state.user.isSSO,
      userRequiresSubscription: state.user.userRequiresSubscription,
    }));
  UpdatePageTitle('Terms of Service');
  CloseDropdowns();

  const [termsText, setTermsText] = useState('Loading');
  const [termsAcceptance, setTermsAcceptance] = useState({
    heading: 'Terms of Service',
    subcontent: ['Loading'],
  });

  useEffect(() => {
    dispatch(getTermsAndConditions(isConsumer));
  }, []);

  useEffect(() => {
    if (termsAndConditions) {
      if (termsAndConditions.terms) {
        setTermsText(termsAndConditions.terms);
      }
      if (loggedIn) {
        if (termsAndConditions.has_accepted_terms) {
          const date = DateFormatUTC(
            termsAndConditions?.terms_accepted_utc,
            'LLLL',
            true
          );
          termsAcceptance.subcontent = [
            `You accepted the following terms of service on ${date}.`,
          ];
          termsAcceptance.buttonText = null;
          termsAcceptance.showButton = false;
          setTermsAcceptance(termsAcceptance);
          if (isSSO && userRequiresSubscription) {
            window.location = `${window.location.origin}/`;
          }
        } else {
          termsAcceptance.subcontent = [
            'You must accept the following Terms of Service before continuing.',
          ];
          termsAcceptance.buttonText = 'Accept Terms';
          termsAcceptance.showButton = true;
          setTermsAcceptance(termsAcceptance);
        }
      } else {
        termsAcceptance.subcontent = [
          'Please review the following Terms of Service before creating an account.',
        ];
        termsAcceptance.showButton = false;
        setTermsAcceptance(termsAcceptance);
      }
    }
  }, [termsAndConditions, isSSO, userRequiresSubscription]);

  return (
    <PageContainer>
      <PageHeader
        content={termsAcceptance}
        buttonFunction={() => dispatch(updateTermsAndConditions(isConsumer))}
      />
      <PageContent>
        <SecondaryHeading margin="0 0 15px 0">
          Asset-Map<sup>®</sup> Terms of Service
        </SecondaryHeading>
        <TermsAndConditionsContainer
          dangerouslySetInnerHTML={{ __html: termsText }}
        />
      </PageContent>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const TermsAndConditionsContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  font-size: 14px;
  p {
    margin-bottom: 10px;
  }
  u {
    font-weight: ${fonts.semiBold};
    font-size: 15px;
    margin-bottom: 5px;
  }
`;

Terms.propTypes = {
  isConsumer: PropTypes.bool,
};

export default Terms;
