/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { isEqual } from 'lodash';
import {
  getInboundValue,
  // updateInboundValue,
  getDiscoveryList,
  createDiscovery,
  setCreatedDiscovery,
  closeDiscovery,
  createHouseholdFromDiscovery,
  deleteDiscovery,
  updateHouseholdAdvisor,
} from '../store/actions';
import {
  Button,
  PageHeader,
  Select,
  TextInput,
  // CheckBox,
  SimpleModal,
  LoadingAnimation,
  Pagination,
  Filter,
  DiscoveryShareModal,
  AdvisorAssignModal,
} from '../components';
import {
  // ellipsisH,
  cancel,
  trashDark,
  share,
  check,
  shareSolid as shareWhite,
  email,
  link,
  launch,
  copy as copyIcon,
  documentDownload,
  // newBadge,
  plusCircleDark,
  home,
  noDiscovery,
  info,
  QRCodeIcon,
  chevronDown,
  userShield,
} from '../assets';
import { ButtonLink, LoadingPlaceholder } from '../styles/library/fontStyles';
import {
  InputLabel,
  TextInputContainer,
  SelectContainer,
} from '../styles/library/inputStyles';
import {
  GetCurrencies,
  SetDefaultItem,
  SuccessNotification,
  headerContent,
  SendTrackingEvent,
  DateFromNowUTC,
} from '../utils';
import { Page } from '../styles/library/layoutStyles';
import { FilterContainer } from '../components/Table/Filter';
import { colors, buttonColors, maxDevice, fonts } from '../styles/variables';
import { ButtonThemes } from '../styles/themes';

const filtersReducer = (filters, action) => {
  let updateOptions = action.type === 'options';
  return filters.map((f) => {
    if (updateOptions) {
      if (f.key === action.payload.key) {
        if (f.key === 'currency') {
          f.options = action.payload.options.map((o) => {
            return {
              value: o.code,
              label: o.name,
            };
          });
        } else {
          f.options = action.payload.options;
        }
      }
    } else {
      // updating the selected value
      if (f.key === action.type) {
        f.selectedOption = action.payload;
        f.value = action.payload;
      }
    }
    return f;
  });
};

const DiscoveryContainer = () => {
  const dispatch = useDispatch();
  const {
    user,
    showDiscoveryCustom,
    currencies,
    preferences,
    discovery,
    createdDiscovery,
    invocationId,
    limit,
    showInboundDiscoveryLink,
    isDiscoveryManager,
    updateAdvisorError,
    justUpdatedAdvisor,
  } = useSelector((state) => ({
    user: state.user.user,
    showDiscoveryCustom: state.user.showDiscoveryCustom,
    currencies: state.configs.currencies,
    preferences: state.user.user.preferences,
    discovery: state.discovery.discovery,
    createdDiscovery: state.discovery.createdDiscovery,
    invocationId: state.configs.invocationId,
    limit: state.configs?.pagination?.default_limit || 25,
    showInboundDiscoveryLink: state.user.showInboundDiscoveryLink,
    isDiscoveryManager: state.user.isDiscoveryManager,
    updateAdvisorError: state.households.updateAdvisorError,
    justUpdatedAdvisor: state.households.justUpdatedAdvisor,
  }));
  const [name, setName] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [nameError, setNameError] = useState(false);
  const [showShareButton, setShowShareButton] = useState(false);
  const [inboundLink, setInboundLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [discoveryList, setDiscoveryList] = useState([]);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showSendDiscovery, setShowSendDiscovery] = useState(false);
  const [sendDiscoveryContent, setSendDiscoveryContent] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState({});
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [closeModalContent, setCloseModalContent] = useState({});
  const [showCreateHHModal, setShowCreateHHModal] = useState(false);
  const [createHHModalContent, setCreateHHModalContent] = useState({});
  const [showShareOptionsModal, setShowShareOptionsModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [params, setParams] = useState({
    offset: 0,
    is_lead: '',
    currency: '',
    status: '',
    lead_advisor: '',
  });
  const [showAssignAdvisorModal, setShowAssignAdvisorModal] = useState(false);
  const [advisorError, setAdvisorError] = useState();
  const [advisorSuccess, setAdvisorSuccess] = useState();
  const [assignDiscovery, setAssignDiscovery] = useState();

  //set options (some options) as the state
  const initFiltersState = [
    {
      filterType: 'select',
      key: 'type',
      label: 'Type',
      placeholder: 'Type',
      options: [
        {
          label: 'Personal',
          value: 'personal',
        },
        {
          label: 'Firm',
          value: 'firm',
        },
      ],
      style: { width: '215px' },
      selectedOption: { label: 'Personal', value: 'personal' },
      isVisible: true,
      onChangeCallback: (e) => dispatchFilters({ type: 'type', payload: e }),
    },
    {
      filterType: 'select',
      key: 'is_lead',
      label: 'Sources',
      placeholder: 'Sources',
      options: [
        { label: 'All', value: '' },
        {
          label: 'Outbound',
          value: false,
        },
      ],
      style: { width: '215px' },
      selectedOption: { label: 'All', value: '' },
      isVisible: true,
      onChangeCallback: (e) => dispatchFilters({ type: 'is_lead', payload: e }),
    },
    {
      filterType: 'select',
      key: 'currency',
      label: 'Currencies',
      placeholder: 'Currencies',
      options: [{ label: 'All', value: '' }],
      style: { width: '215px' },
      selectedOption: { label: 'All', value: '' },
      isVisible: true,
      onChangeCallback: (e) =>
        dispatchFilters({ type: 'currency', payload: e }),
    },
    {
      filterType: 'select',
      key: 'status',
      label: 'Status',
      options: [
        { label: 'All', value: '' },
        { label: 'Closed', value: 'closed' },
        { label: 'Complete', value: 'complete' },
        { label: 'Created', value: 'sent' },
        { label: 'In Progress', value: 'in_progress' },
        { label: 'Opened', value: 'opened' },
      ],
      style: { width: '215px' },
      selectedOption: { label: 'All', value: '' },
      isVisible: true,
      onChangeCallback: (e) => dispatchFilters({ type: 'status', payload: e }),
    },
    {
      filterType: 'typeaheadSearch',
      key: 'lead_advisor',
      label: 'Lead Advisors',
      isVisible: false,
      showSearchIcon: true,
      placeholder: 'Filter advisors by name or email',
      isMulti: true,
      value: null,
      loadOptions: (input) => {
        if (input) {
          return fetch(
            `/api/v3/customer/${user.customer.id}/advisor_search/${input}`
          ).then((res) => {
            return res.json();
          });
        }
      },
      handleSelectChange: (e) => {
        dispatchFilters({ type: 'lead_advisor', payload: e });
      },
    },
  ];

  const [filtersState, dispatchFilters] = useReducer(
    filtersReducer,
    initFiltersState
  );

  //He have filters state and action
  //Set with the reducer function and init state
  //Need to update the state

  // MAKES API CALL TO GET DISCOVERY LIST
  useEffect(() => {
    dispatch(getDiscoveryList());
    return () => {
      dispatch(setCreatedDiscovery(null));
    };
  }, []);

  // MAKES API CALL TO GET INBOUND VALUE IF ACTIVE
  useEffect(() => {
    if (showInboundDiscoveryLink) {
      dispatch(getInboundValue());
      dispatchFilters({
        type: 'options',
        payload: {
          key: 'is_lead',
          options: [
            { label: 'All', value: '' },
            {
              label: 'Inbound',
              value: true,
            },
            {
              label: 'Outbound',
              value: false,
            },
          ],
        },
      });
    }
  }, [showInboundDiscoveryLink]);

  // GETS CURRENCY OPTION FROM USER INFO AND MATCHES IT FROM LIST OF CURRENCIES
  useEffect(() => {
    if (currencies && preferences) {
      SetDefaultItem(currencies, preferences.currency, setSelectedCurrency);
    }
    if (currencies.length > 0) {
      dispatchFilters({
        type: 'options',
        payload: {
          key: 'currency',
          options: [{ name: 'All', code: '' }, ...currencies],
        },
      });
    }
  }, [currencies, preferences]);

  // SETS DISCOVERY LIST VARIABLE ONCE THERE IS RESPONSE FROM API CALL
  useEffect(() => {
    if (discovery && discovery.hasOwnProperty('results')) {
      setDiscoveryList(discovery.results);
      setLoading(false);
    }
  }, [discovery]);

  useEffect(() => {
    if (inboundLink && user?.id && showDiscoveryCustom) {
      setShowShareButton(true);
    }
  }, [inboundLink, user?.id, showDiscoveryCustom]);

  useEffect(() => {
    if (user?.inbound_discovery_link) {
      setInboundLink(`${window.location.origin}${user.inbound_discovery_link}`);
    } else if (user?.id) {
      setInboundLink(`${window.location.origin}/i/${user.id}`);
    }
  }, [user?.inbound_discovery_link]);

  let alreadyUpdatedParams = false;
  useEffect(() => {
    if (!alreadyUpdatedParams && params.offset !== offset) {
      let newParams = { ...params };
      newParams.offset = offset;
      setParams(newParams);
      alreadyUpdatedParams = false;
    }
  }, [offset]);

  useEffect(() => {
    let newParams = { ...params };
    newParams.offset = 0;
    alreadyUpdatedParams = true;
    setOffset(0);
    filtersState.forEach((filter) => {
      if (filter.filterType === 'select') {
        newParams[filter.key] = filter.selectedOption.value;
      }
      if (
        filter.filterType === 'typeaheadSearch' &&
        filter.hasOwnProperty('selectedOption')
      ) {
        newParams[filter.key] = filter.selectedOption.map((selected) => {
          return selected.value;
        });
      }
      if (isDiscoveryManager && filter.key === 'lead_advisor') {
        filter.isVisible = true;
      }
    });
    if (!isEqual(newParams, params)) {
      setParams(newParams);
    }
  }, [filtersState]);

  useEffect(() => {
    dispatch(
      getDiscoveryList(
        params.offset,
        params.is_lead,
        params.currency,
        params.status,
        params.lead_advisor
      )
    );
  }, [params, justUpdatedAdvisor]);

  useEffect(() => {
    if (createdDiscovery && createdDiscovery.hasOwnProperty('hash')) {
      openExistingDiscoveryModal(createdDiscovery);
      dispatch(getDiscoveryList());
      SendTrackingEvent(
        invocationId,
        'action',
        'discovery_create',
        'discovery',
        { discovery: createdDiscovery.hash }
      );
    }
  }, [createdDiscovery]);

  useEffect(() => {
    if (updateAdvisorError) {
      console.warn(updateAdvisorError);
      setAdvisorError('Error Assigning Household to Advisor');
    }
  }, [updateAdvisorError]);

  useEffect(() => {
    if (justUpdatedAdvisor) {
      setAdvisorSuccess('Lead Advisor Updated');
    }
  }, [justUpdatedAdvisor]);

  const startDiscovery = () => {
    if (name !== '') {
      let data = {
        name: name,
        currency: selectedCurrency.value,
      };
      dispatch(createDiscovery(data));
      setName('');
    } else {
      setNameError(true);
    }
  };

  const copySubject = () => {
    SuccessNotification('Copied', 2.5, 'bottom-left', {
      size: ' 5px',
      style: 'solid',
    });
    return copy(emailSubject);
  };
  const copyBody = () => {
    SuccessNotification('Copied', 2.5, 'bottom-left', {
      size: ' 5px',
      style: 'solid',
    });
    return copy(emailBody, { format: 'text/plain' });
  };

  const goBackToMainModal = () => {
    setShowEmailModal(false);
    setShowSendDiscovery(true);
  };

  const openEmailProvider = () => {
    let emailBodyContent = emailBody;
    var body = document.getElementById('textarea-body')?.value;
    if (body) {
      emailBodyContent = body;
    }
    emailBodyContent = encodeURIComponent(emailBodyContent);
    window.parent.location = `mailto:?subject=${emailSubject}&body=${emailBodyContent}`;
  };

  // COPIES DISCOVERY LINK TO CLIPBOARD
  const openEmailModal = (discovery) => {
    setShowSendDiscovery(false);
    setShowEmailModal(true);
    setEmailSubject('Help me understand your current financial situation');
    setEmailBody(
      discovery.name +
        ',\n\r' +
        'The following interview questionnaire will help me understand your current financial state. Please take a few minutes to complete this to the best of your ability while the link remains "live". My interview will ask high-level questions about the important people in your life, your current financial decisions, and any topics you would like to discuss with our team.\n\r' +
        discovery.link +
        '\n\r' +
        'Please note this link will expire in 14 days and you can share it selectively with others that need to contribute.\n\r'
    );
  };

  const emailContent = {
    heading: 'Send Discovery Email',
    text: `<strong>You can use the copy buttons to transfer the information to your email.<strong/>`,
    middleText:
      '<strong>or you can send the data directly from your email system:<strong/>',
    minWidth: '400px',
    inputs: [
      {
        type: 'text',
        name: 'subject',
        label: 'Subject',
        required: false,
        placeholder: 'Subject',
        inputImage: copyIcon,
        imageName: 'copy',
        imageClick: () => copySubject(),
        value: emailSubject,
        onChange: (e) => setEmailSubject(e.currentTarget.value),
      },
      {
        type: 'textarea',
        useEditor: false,
        name: 'body',
        label: 'Body',
        required: false,
        placeholder: 'Body',
        inputImage: copyIcon,
        imageName: 'copy',
        imageClick: () => copyBody(),
        value: emailBody,
        onChange: (e) => setEmailBody(e.currentTarget.value),
      },
    ],
    horizontalContent: [
      {
        type: 'buttonRow',
        text: 'Email',
        name: 'email',
        image: email,
        onClick: () => openEmailProvider(),
      },
    ],
    buttons: [
      {
        text: 'Go Back',
        function: () => goBackToMainModal(),
        theme: ButtonThemes.cancel,
      },
    ],
  };

  // COPIES DISCOVERY LINK TO CLIPBOARD
  const copyDiscoveryLink = (link) => {
    copy(link);
    setShowSendDiscovery(false);
    SuccessNotification('Link Copied', 2.5, 'bottom-left', {
      size: ' 5px',
      style: 'solid',
    });
  };

  // OPENS DISCOVERY INTERVIEW IN NEW TAB
  const openDiscoveryLink = (link) => {
    window.open(link, '_blank');
    setShowSendDiscovery(false);
  };

  const openInboundLink = (source) => {
    window.open(inboundLink, '_blank');
    SendTrackingEvent(invocationId, 'click', source, 'discovery');
  };

  // FUNCTION TO OPEN EXISTING DISCOVERY MODAL & SET CONTENT
  const openExistingDiscoveryModal = (discovery) => {
    setShowSendDiscovery(true);
    setSendDiscoveryContent({
      heading: 'Send Discovery',
      text: `How would you like to send the discovery questionnaire to <strong>${discovery.name}</strong>?`,
      minWidth: '400px',
      horizontalContent: [
        {
          type: 'buttonRow',
          text: 'Email',
          name: 'email',
          image: email,
          onClick: () => openEmailModal(discovery),
        },
        {
          type: 'buttonRow',
          text: 'Copy Link',
          name: 'copy',
          image: link,
          onClick: () => copyDiscoveryLink(discovery.link),
        },
        {
          type: 'buttonRow',
          text: 'Launch Now',
          name: 'launch',
          image: launch,
          onClick: () => openDiscoveryLink(discovery.link),
        },
      ],
    });
  };

  const sendCloseDiscovery = (id) => {
    dispatch(closeDiscovery(id));
    setShowCloseModal(false);
  };

  const openCloseModal = (discovery) => {
    setShowCloseModal(true);
    setCloseModalContent({
      heading: 'Close Discovery?',
      text: `Are you sure you want to close <strong>${discovery.name}</strong>? This cannot be undone.`,
      buttons: [
        {
          text: 'No',
          function: () => setShowCloseModal(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes',
          function: () => sendCloseDiscovery(discovery.hash),
          theme: ButtonThemes.primary,
        },
      ],
    });
  };

  const sendCreateHHDiscovery = (id) => {
    dispatch(createHouseholdFromDiscovery(id));
    setShowCreateHHModal(false);
  };

  const openCreateHHModal = (discovery) => {
    setShowCreateHHModal(true);
    setCreateHHModalContent({
      heading: 'Create Household',
      text: `Are you sure you want to Create a Household from <strong>${discovery.name}</strong>? This can only be done once.`,
      buttons: [
        {
          text: 'No',
          function: () => setShowCreateHHModal(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes',
          function: () => sendCreateHHDiscovery(discovery.hash),
          theme: ButtonThemes.primary,
        },
      ],
    });
  };

  const sendDeleteDiscovery = (id) => {
    dispatch(deleteDiscovery(id));
    setShowDeleteModal(false);
  };

  const openDeleteModal = (discovery) => {
    setShowDeleteModal(true);
    setDeleteModalContent({
      heading: 'Delete Discovery?',
      text: `Are you sure you want to delete <strong>${discovery.name}</strong>? This cannot be undone.`,
      buttons: [
        {
          text: 'No',
          function: () => setShowDeleteModal(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes',
          function: () => sendDeleteDiscovery(discovery.hash),
          theme: ButtonThemes.primary,
        },
      ],
    });
  };

  const setNameValue = (e) => {
    setName(e.target.value);
    setNameError(false);
  };

  const copyLink = () => {
    copy(inboundLink);
    setCopied(true);
    SendTrackingEvent(invocationId, 'click', 'inbound_copy', 'discovery');
    SuccessNotification('Copied', 2.5, 'bottom-left', {
      size: ' 5px',
      style: 'solid',
    });
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  const openShareModal = () => {
    setShowShareOptionsModal(true);
    SendTrackingEvent(invocationId, 'click', 'inbound_qr', 'discovery');
  };

  const updateLeadAdvisor = (advisor, discovery) => {
    setAdvisorError();
    dispatch(updateHouseholdAdvisor(discovery.household.hash, advisor.value));
  };

  const openAssignAdvisorModal = (discovery) => {
    setShowAssignAdvisorModal(true);
    setAssignDiscovery(discovery);
  };

  const closeAssignAdvisorModal = () => {
    setShowAssignAdvisorModal(!showAssignAdvisorModal);
    setAdvisorError();
    setAdvisorSuccess();
  };

  const generateFilter = (filter) => {
    if (filter?.selectedOption || filter.filterType === 'typeaheadSearch') {
      if (!Array.isArray(filter.selectedOption)) {
        return (
          <span>
            :{' '}
            {filter?.selectedOption?.label
              ? filter?.selectedOption?.label
              : 'All'}
          </span>
        );
      } else {
        let lead_advisors = filter.selectedOption.reduce((acc, cur) => {
          acc.push(cur.label);
          return acc;
        }, []);
        return (
          <span>
            : {lead_advisors.length === 0 ? 'All' : lead_advisors.join(', ')}
          </span>
        );
      }
    }
  };

  return (
    <>
      {showShareOptionsModal && (
        <DiscoveryShareModal
          hide={() => setShowShareOptionsModal(!showShareOptionsModal)}
          inboundLink={inboundLink}
          showCustom={showDiscoveryCustom}
          invocationId={invocationId}
        />
      )}
      {showSendDiscovery && (
        <SimpleModal
          hide={() => setShowSendDiscovery(!showSendDiscovery)}
          content={sendDiscoveryContent}
        />
      )}
      {showEmailModal && (
        <SimpleModal
          hide={() => setShowEmailModal(!showEmailModal)}
          content={emailContent}
        />
      )}
      {showCloseModal && (
        <SimpleModal
          hide={() => setShowCloseModal(!showCloseModal)}
          content={closeModalContent}
        />
      )}
      {showCreateHHModal && (
        <SimpleModal
          hide={() => setShowCreateHHModal(!showCreateHHModal)}
          content={createHHModalContent}
        />
      )}
      {showDeleteModal && (
        <SimpleModal
          hide={() => setShowDeleteModal(!showDeleteModal)}
          content={deleteModalContent}
        />
      )}
      {showAssignAdvisorModal && (
        <AdvisorAssignModal
          hide={closeAssignAdvisorModal}
          update={(advisor, discovery) => updateLeadAdvisor(advisor, discovery)}
          discovery={assignDiscovery}
          customer={user.customer}
          updateError={advisorError}
          updateSuccess={advisorSuccess}
        />
      )}

      <Page>
        <ColumnContainerLayout>
          <PageHeader content={headerContent.discovery} margin="25px 0" />
          <DetailsContainerRow>
            {preferences.discovery?.personal && (
              <PersonalDiscovery>
                {preferences.discovery?.firm && isDiscoveryManager
                  ? 'Personal Discovery'
                  : null}
                <OutboundContainer
                  $custom={
                    showDiscoveryCustom ||
                    !showInboundDiscoveryLink ||
                    (preferences.discovery?.firm &&
                      preferences.discovery?.personal &&
                      isDiscoveryManager)
                  }
                >
                  <SectionLabel>
                    Outbound
                    <Tooltip
                      title={'Invite a client or prospect'}
                      position="right"
                      trigger="mouseenter click"
                      followCursor="true"
                      delay={0}
                      arrow={true}
                      arrowSize={'small'}
                      animation={'fade'}
                      animateFill={false}
                      transitionFlip={false}
                      size={'small'}
                      style={{
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginLeft: '7px',
                      }}
                    >
                      <TooltipInfoIcon
                        src={info}
                        alt="info"
                        data-image="tooltip-info"
                      />
                    </Tooltip>
                  </SectionLabel>
                  <OutboundRow>
                    <TextInputContainer>
                      <InputLabel>{`Recipient's Name`}</InputLabel>
                      <TextInput
                        type={'text'}
                        name={'name'}
                        placeholder={`Recipient's Name`}
                        value={name}
                        onChange={(e) => setNameValue(e)}
                        hasError={nameError}
                      />
                    </TextInputContainer>
                    <SelectContainer>
                      <InputLabel>Currency</InputLabel>
                      <Select
                        selectedOption={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e)}
                        options={GetCurrencies(currencies)}
                        isSearchable={true}
                        placeholder="Currency"
                        autoFocus={false}
                      />
                    </SelectContainer>
                    <ButtonRow>
                      <Button
                        text="Initiate Interview"
                        bigButton={false}
                        onClick={() => startDiscovery()}
                      />
                    </ButtonRow>
                  </OutboundRow>
                </OutboundContainer>
                {showInboundDiscoveryLink && (
                  <InboundContainer>
                    <SectionLabel
                      style={{
                        justifyContent: showDiscoveryCustom
                          ? 'flex-end'
                          : 'flex-start',
                      }}
                    >
                      Inbound{' '}
                      <Tooltip
                        title={'Share your link for lead generation'}
                        position="right"
                        trigger="mouseenter click"
                        followCursor="true"
                        delay={0}
                        arrow={true}
                        arrowSize={'small'}
                        animation={'fade'}
                        animateFill={false}
                        transitionFlip={false}
                        size={'small'}
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginLeft: '7px',
                        }}
                      >
                        <TooltipInfoIcon
                          src={info}
                          alt="info"
                          data-image="tooltip-info"
                        />
                      </Tooltip>
                    </SectionLabel>
                    <InboundRow>
                      {showShareButton ? (
                        <ButtonRow>
                          <Button
                            text={'Share'}
                            bigButton={false}
                            imageSrc={shareWhite}
                            onClick={() => setShowShareOptionsModal(true)}
                            theme={ButtonThemes.secondary}
                          />
                        </ButtonRow>
                      ) : (
                        <>
                          <TextInputContainer>
                            <InputLabel>Personal Discovery Link</InputLabel>
                            {inboundLink ? (
                              <InboundLink
                                $active={true}
                                onClick={() => openInboundLink('inbound_open')}
                              >
                                {inboundLink}
                              </InboundLink>
                            ) : (
                              <LoadingPlaceholder
                                width="200px"
                                height="20px"
                                margin="3px 0 0 3px"
                              />
                            )}
                          </TextInputContainer>
                          <ActionIconsContainer $active={true}>
                            <LaunchIcon
                              src={launch}
                              alt="launch"
                              title="Open Link"
                              data-image="discovery-open-link"
                              onClick={() =>
                                openInboundLink('inbound_open_icon')
                              }
                            />
                            <CopyIcon
                              src={copied ? check : copyIcon}
                              alt={copied ? 'check' : 'copy'}
                              title="Copy Link"
                              data-image="discovery-copy-link"
                              onClick={copyLink}
                            />
                            <QRCodeIconContainer
                              src={QRCodeIcon}
                              alt="QR Code"
                              title="Generate QR Code"
                              data-image="discovery-share"
                              onClick={openShareModal}
                            />
                          </ActionIconsContainer>
                        </>
                      )}
                    </InboundRow>
                  </InboundContainer>
                )}
              </PersonalDiscovery>
            )}
            {preferences.discovery?.firm && isDiscoveryManager && (
              <FirmDiscovery>
                {preferences.discovery?.personal ? 'Firm Discovery' : ''}
                {isDiscoveryManager && (
                  <OutboundContainer
                    $custom={
                      showDiscoveryCustom ||
                      !showInboundDiscoveryLink ||
                      (preferences.discovery?.firm &&
                        preferences.discovery?.personal &&
                        isDiscoveryManager)
                    }
                  >
                    <SectionLabel>
                      Outbound
                      <Tooltip
                        title={'Invite a client or prospect'}
                        position="right"
                        trigger="mouseenter click"
                        followCursor="true"
                        delay={0}
                        arrow={true}
                        arrowSize={'small'}
                        animation={'fade'}
                        animateFill={false}
                        transitionFlip={false}
                        size={'small'}
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginLeft: '7px',
                        }}
                      >
                        <TooltipInfoIcon
                          src={info}
                          alt="info"
                          data-image="tooltip-info"
                        />
                      </Tooltip>
                    </SectionLabel>
                    <OutboundRow>
                      <TextInputContainer>
                        <InputLabel>{`Recipient's Name`}</InputLabel>
                        <TextInput
                          type={'text'}
                          name={'name'}
                          placeholder={`Recipient's Name`}
                          value={name}
                          onChange={(e) => setNameValue(e)}
                          hasError={nameError}
                        />
                      </TextInputContainer>
                      <SelectContainer>
                        <InputLabel>Currency</InputLabel>
                        <Select
                          selectedOption={selectedCurrency}
                          onChange={(e) => setSelectedCurrency(e)}
                          options={GetCurrencies(currencies)}
                          isSearchable={true}
                          placeholder="Currency"
                          autoFocus={false}
                        />
                      </SelectContainer>
                      <ButtonRow>
                        <Button
                          text="Initiate Interview"
                          bigButton={false}
                          onClick={() => startDiscovery()}
                        />
                      </ButtonRow>
                    </OutboundRow>
                  </OutboundContainer>
                )}
                {showInboundDiscoveryLink && (
                  <InboundContainer>
                    <SectionLabel
                      style={{
                        justifyContent: showDiscoveryCustom
                          ? 'flex-end'
                          : 'flex-start',
                      }}
                    >
                      Inbound{' '}
                      <Tooltip
                        title={'Share your link for lead generation'}
                        position="right"
                        trigger="mouseenter click"
                        followCursor="true"
                        delay={0}
                        arrow={true}
                        arrowSize={'small'}
                        animation={'fade'}
                        animateFill={false}
                        transitionFlip={false}
                        size={'small'}
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginLeft: '7px',
                        }}
                      >
                        <TooltipInfoIcon
                          src={info}
                          alt="info"
                          data-image="tooltip-info"
                        />
                      </Tooltip>
                    </SectionLabel>
                    <InboundRow>
                      {showShareButton ? (
                        <ButtonRow>
                          <Button
                            text={'Share'}
                            bigButton={false}
                            imageSrc={shareWhite}
                            onClick={() => setShowShareOptionsModal(true)}
                            theme={ButtonThemes.secondary}
                          />
                        </ButtonRow>
                      ) : (
                        <>
                          <TextInputContainer>
                            <InputLabel>Firm Discovery Link</InputLabel>
                            {inboundLink ? (
                              <InboundLink
                                $active={true}
                                onClick={() => openInboundLink('inbound_open')}
                              >
                                {inboundLink}
                              </InboundLink>
                            ) : (
                              <LoadingPlaceholder
                                width="200px"
                                height="20px"
                                margin="3px 0 0 3px"
                              />
                            )}
                          </TextInputContainer>
                          <ActionIconsContainer $active={true}>
                            <LaunchIcon
                              src={launch}
                              alt="launch"
                              title="Open Link"
                              data-image="discovery-open-link"
                              onClick={() =>
                                openInboundLink('inbound_open_icon')
                              }
                            />
                            <CopyIcon
                              src={copied ? check : copyIcon}
                              alt={copied ? 'check' : 'copy'}
                              title="Copy Link"
                              data-image="discovery-copy-link"
                              onClick={copyLink}
                            />
                            <QRCodeIconContainer
                              src={QRCodeIcon}
                              alt="QR Code"
                              title="Generate QR Code"
                              data-image="discovery-share"
                              onClick={openShareModal}
                            />
                          </ActionIconsContainer>
                        </>
                      )}
                    </InboundRow>
                  </InboundContainer>
                )}
              </FirmDiscovery>
            )}
          </DetailsContainerRow>
          <FiltersContainer>
            <FilterListRow>
              <SectionLabel
                onClick={() => setShowFilterRow(!showFilterRow)}
                style={{ cursor: 'pointer' }}
              >
                Filters{' '}
                <ChevronIcon
                  src={chevronDown}
                  alt="arrow"
                  data-image={`chevron-${showFilterRow ? 'down' : 'left'}`}
                  style={{
                    transform: !showFilterRow ? 'rotate(90deg)' : null,
                  }}
                />
              </SectionLabel>
              {!showFilterRow && (
                <FiltersList>
                  {filtersState.map((filter, index) => {
                    return (
                      filter.isVisible && (
                        <FilterChip
                          key={index}
                          onClick={() => setShowFilterRow(!showFilterRow)}
                        >
                          <FilterName>{filter?.label}</FilterName>
                          {generateFilter(filter)}
                        </FilterChip>
                      )
                    );
                  })}
                </FiltersList>
              )}
            </FilterListRow>
            {showFilterRow && (
              <FiltersRow>
                {filtersState.map((filterState, ind) => {
                  return (
                    filterState.isVisible && (
                      <Filter
                        data={filterState}
                        type={filterState.filterType}
                        key={ind}
                      />
                    )
                  );
                })}
              </FiltersRow>
            )}
          </FiltersContainer>
          <TableContainer>
            <TableHeader>
              <tr>
                {/* <Type>Type</Type> */}
                <Source>Source</Source>
                <Created>Created</Created>
                <Modified>Last Modified</Modified>
                <Name>Name</Name>
                {isDiscoveryManager && <Advisor>Lead Advisor</Advisor>}
                <Currency>Currency</Currency>
                <Status>Status</Status>
                <Percent>%</Percent>
                <Actions style={{ paddingRight: '10px' }}>Actions</Actions>
              </tr>
            </TableHeader>
            <tbody>
              {loading ? (
                <Empty>
                  <td colSpan={9}>
                    <LoadingAnimation margin="0 auto" />
                  </td>
                </Empty>
              ) : (
                discoveryList.map((discovery, index) => {
                  return (
                    <TableRow key={index}>
                      {/* <Type>
                        {discovery.lead_advisor.value === params.is_lead
                          ? 'Personal'
                          : 'Firm'}
                      </Type> */}
                      <Source>
                        {discovery.is_lead ? 'Inbound' : 'Outbound'}
                      </Source>
                      <Created>
                        {preferences !== undefined &&
                          DateFromNowUTC(discovery.created_utc)}
                      </Created>
                      <Modified>
                        {preferences !== undefined &&
                          DateFromNowUTC(discovery.modified_utc)}
                      </Modified>
                      <Name>
                        {discovery.email ? (
                          <a href={`mailto:${discovery.email}`}>
                            {discovery.name}
                          </a>
                        ) : (
                          discovery.name
                        )}
                      </Name>
                      {isDiscoveryManager && (
                        <Advisor>{discovery.lead_advisor.label || '-'}</Advisor>
                      )}
                      <Currency>{discovery.currency}</Currency>
                      <Status>{discovery.status_display}</Status>
                      <Percent>
                        {discovery.percent_complete !== null
                          ? `${discovery.percent_complete}%`
                          : '-'}
                      </Percent>
                      <Actions style={{ paddingRight: '40px' }}>
                        <ActionImage
                          src={share}
                          onClick={() => {
                            if (
                              discovery.actions.link &&
                              ((preferences.discovery?.firm &&
                                isDiscoveryManager) ||
                                preferences.discovery?.personal)
                            ) {
                              openExistingDiscoveryModal(discovery);
                            }
                          }}
                          alt="send"
                          title="Send Discovery"
                          data-image="discovery-send"
                          $disabled={
                            !discovery.actions.link ||
                            (preferences.discovery?.firm && !isDiscoveryManager)
                          }
                        />
                        <ActionImage
                          src={cancel}
                          alt="close"
                          title="Close Discovery"
                          data-image="discovery-close"
                          onClick={() => {
                            if (
                              discovery.actions.close &&
                              ((preferences.discovery?.firm &&
                                isDiscoveryManager) ||
                                preferences.discovery?.personal)
                            ) {
                              openCloseModal(discovery);
                            }
                          }}
                          $disabled={
                            !discovery.actions.close ||
                            (preferences.discovery?.firm && !isDiscoveryManager)
                          }
                        />
                        <ActionImage
                          src={plusCircleDark}
                          alt="create"
                          title="Create Household"
                          data-image="discovery-create-household"
                          onClick={() => {
                            if (
                              discovery.actions.create &&
                              ((preferences.discovery?.firm &&
                                isDiscoveryManager) ||
                                preferences.discovery?.personal)
                            ) {
                              openCreateHHModal(discovery);
                            }
                          }}
                          $disabled={!discovery.actions.create}
                        />
                        {discovery.actions.assign &&
                          ((preferences.discovery?.firm &&
                            isDiscoveryManager) ||
                            preferences.discovery?.personal) && (
                            <ActionImage
                              src={userShield}
                              alt="assign"
                              title="Assign Lead Advisor"
                              data-image="discovery-assign-advisor"
                              $disabled={!discovery.household}
                              onClick={() => {
                                if (
                                  discovery.actions.assign &&
                                  ((preferences.discovery?.firm &&
                                    isDiscoveryManager) ||
                                    preferences.discovery?.personal)
                                ) {
                                  return openAssignAdvisorModal(discovery);
                                }
                              }}
                            />
                          )}
                        {discovery?.household?.link &&
                        ((preferences.discovery?.firm && isDiscoveryManager) ||
                          preferences.discovery.personal) ? (
                          <Link to={discovery?.household?.link}>
                            <ActionImage
                              src={home}
                              alt="household"
                              title="Go to Household"
                              data-image="discovery-view-household"
                            />
                          </Link>
                        ) : (
                          <DisabledImage
                            src={home}
                            alt="household"
                            title="Need to Create Household First"
                            data-image="discovery-view-household-disabled"
                          />
                        )}
                        {discovery.actions.generate &&
                        ((preferences.discovery?.firm && isDiscoveryManager) ||
                          preferences.discovery?.personal) ? (
                          <a href={`/interview/form/${discovery.hash}/report`}>
                            <ActionImage
                              src={documentDownload}
                              alt="download"
                              title="Generate Report"
                              data-image="discovery-generate-pdf"
                            />
                          </a>
                        ) : (
                          <DisabledImage
                            src={documentDownload}
                            alt="download"
                            title="Generate Report"
                            data-image="discovery-generate-pdf-disabled"
                          />
                        )}
                        <ActionImage
                          src={trashDark}
                          alt="delete"
                          onClick={() => {
                            if (
                              discovery.actions.remove &&
                              ((preferences.discovery?.firm &&
                                isDiscoveryManager) ||
                                preferences.discovery?.personal)
                            ) {
                              openDeleteModal(discovery);
                            }
                          }}
                          title="Delete Discovery"
                          $disabled={
                            !discovery.actions.remove ||
                            (preferences.discovery?.firm && !isDiscoveryManager)
                          }
                          data-image="discovery-delete"
                        />
                      </Actions>
                    </TableRow>
                  );
                })
              )}
            </tbody>
          </TableContainer>
          {discovery && discovery.results.length === 0 && (
            <EmptyResults>
              <img
                src={noDiscovery}
                alt="No Discovery"
                data-image="no-discovery"
              />
              <p>No Discovery has been sent or started</p>
            </EmptyResults>
          )}
          {discovery?.count !== 0 && discovery?.count > limit && (
            <Pagination
              clickNext={() => setOffset(offset + limit)}
              clickPrev={() => setOffset(offset - limit)}
              goToPage={(page) => setOffset(limit * (page - 1))}
              limit={limit}
              offset={offset}
              count={discovery?.count}
            />
          )}
          <ButtonRowContainer>
            <ButtonLink to="/household/new">Cancel</ButtonLink>
          </ButtonRowContainer>
        </ColumnContainerLayout>
      </Page>
    </>
  );
};

const ActionIconsContainer = styled.div`
  /* border: 1px solid red; */
  margin-top: 7px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  img {
    cursor: pointer;
    cursor: ${(props) => (props.$active ? 'pointer' : 'not-allowed')};
    opacity: 1;
    opacity: ${(props) => (props.$active ? '1' : '.5')};
    &:hover {
      opacity: 0.85;
      opacity: ${(props) => (props.$active ? '.85' : '.5')};
      transform: scale(1.01);
    }
  }
`;

const LaunchIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;
// const LaunchIcon = styled.img`
//   cursor: pointer;
//   width: 12px;
//   height: 12px;
//   margin-right: 15px;
//   margin-bottom: -4px;
//   &:hover {
//     opacity: 0.85;
//     transform: scale(1.01);
//   }
// `;

const CopyIcon = styled.img`
  width: 19px;
  height: 19px;
  transition: all 0.5s ease;
`;

const FilterListRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const ColumnContainerLayout = styled.div`
  margin: 10px 40px;
`;

const DetailsContainerRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-top: -25px;
  width: 100%;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const SectionLabel = styled.div`
  font-weight: ${fonts.semiBold};
  margin: 0 0 8px 3px;
  color: ${colors.darkGrey};
  font-size: 13px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const TooltipInfoIcon = styled.img`
  width: 12px;
  height: 12px;
  /* margin-left: 6px; */
  opacity: 0.5;
  cursor: pointer;
`;

const ChevronIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 4px;
  opacity: 0.8;
`;

const PersonalDiscovery = styled.p`
  flex-direction: row;
  display: flex;
  margin-right: 30px;
  padding-top: 15px;
  margin-right: 30px;
`;
const FirmDiscovery = styled.p`
  flex-direction: row;
  display: flex;
  padding-top: 15px;
`;

const OutboundContainer = styled.div`
  @media (max-width: 1000px) {
    margin-right: 0px;
  }
  @media (max-width: 1150px) {
    border-right: none;
  }
  flex: 0 0 auto;
  flex: ${(props) => (props.$custom ? '1 1 auto' : '0 0 auto')};
  margin-right: 30px;
  padding-top: 15px;
  border-right: 1px solid ${colors.lightGrey};
  border-right: ${(props) => props.$custom && 'none'};
`;

const OutboundRow = styled.div`
  @media (max-width: 756px) {
    padding-bottom: 0px;
  }
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin-right: 30px;
  ${TextInputContainer} {
    margin-right: 40px;
    max-width: 300px;
    min-width: 250px;
  }
  ${SelectContainer} {
    margin-right: 10px;
    max-width: 200px;
    min-width: 175px;
    ${InputLabel} {
      margin: 0 0 3px 0;
    }
  }
`;

const InboundLink = styled.p`
  cursor: ${(props) => (props.$active ? 'pointer' : 'not-allowed')};
  color: ${(props) => (props.$active ? colors.lightBlue : colors.paleGrey)};
  margin: 3px 0 0 3px;
  font-size: 14px;
`;

const InboundContainer = styled.div`
  @media (max-width: 1150px) {
    padding-top: 0px;
    width: 100%;
  }
  flex: 0 0 auto;
  padding-top: 15px;
`;

const InboundRow = styled.div`
  @media (max-width: 1150px) {
    justify-content: flex-start;
  }
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-end;
  transition: all 0.1s ease;
  position: relative;
`;

const QRCodeIconContainer = styled.img`
  margin-left: 10px;
  width: 22px;
  height: 22px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  margin-top: 7px;
`;

const FiltersContainer = styled.div`
  flex: 1 1 auto;
  padding-top: 15px;
`;

const FiltersList = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0 0 8px 10px;
`;

const FilterChip = styled.div`
  cursor: pointer;
  background: ${colors.lightGrey};
  padding: 4px 8px;
  font-size: 10px;
  margin-right: 8px;
  border-radius: 3px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  span {
    flex: 0 0 auto;
  }
`;

const FilterName = styled.div`
  font-weight: ${fonts.semiBold};
`;

const FiltersRow = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
  ${FilterContainer} {
    @media (max-width: 800px) {
      margin-bottom: 8px;
    }
  }
`;

const TableContainer = styled.table`
  @media ${maxDevice.mobileL} {
    padding: 0px;
  }
  padding: 0px;
  font-size: 14px;
  border: 1px solid ${colors.lightGrey};
  margin: 5px auto 20px auto;
  width: 100%;
`;

const TableHeader = styled.thead`
  width: 100%;
  background: ${colors.lighterGrey};
  td {
    padding: 12px 12px;
    font-weight: ${fonts.semiBold};
  }
`;

// const Type = styled.td`
//   width: 10%;
//   border-right: 1px solid ${colors.lightGrey};
// `;
const Source = styled.td`
  width: 10%;
  border-right: 1px solid ${colors.lightGrey};
`;
const Created = styled.td`
  width: 12%;
  border-right: 1px solid ${colors.lightGrey};
`;
const Modified = styled.td`
  width: 10%;
  border-right: 1px solid ${colors.lightGrey};
`;
const Name = styled.td`
  width: 20%;
  border-right: 1px solid ${colors.lightGrey};
  a {
    color: ${colors.lightBlue};
  }
`;
const Advisor = styled.td`
  width: 20%;
  border-right: 1px solid ${colors.lightGrey};
  a {
    color: ${colors.lightBlue};
  }
`;
const Currency = styled.td`
  width: 5%;
  border-right: 1px solid ${colors.lightGrey};
`;
const Status = styled.td`
  width: 10%;
  border-right: 1px solid ${colors.lightGrey};
`;
const Percent = styled.td`
  width: 5%;
  border-right: 1px solid ${colors.lightGrey};
`;
const Actions = styled.td`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  a {
    width: 18px;
    height: 18px;
    margin-right: 15px;
  }
`;

const EmptyResults = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 40px;
  img {
    max-width: 340px;
    width: 100%;
  }
  p {
    margin-top: 30px;
    font-size: 18px;
    font-weight: ${fonts.semiBold};
    color: ${colors.paleGrey};
  }
`;

const Empty = styled.tr`
  height: 40vh;
  vertical-align: middle;
  td {
    vertical-align: middle;
  }
`;

const ActionImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 15px;
  opacity: ${(props) => (props.$disabled ? 0.3 : 1)};
  cursor: ${(props) => (props.$disabled ? 'disabled' : 'pointer')};
`;

const DisabledImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 15px;
  opacity: 0.3;
`;

const TableRow = styled.tr`
  width: 100%;
  border-bottom: 1px solid ${colors.lightGrey};
  td {
    padding: 10px 12px;
  }
`;

const ButtonRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  text-align: right;
  padding: 40px 0;
  ${ButtonLink} {
    background: ${buttonColors.inactive};
  }
`;

export default DiscoveryContainer;
