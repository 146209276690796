import { components } from 'react-select';
import {
  pencil,
  trashDark,
  link,
  imagePreview,
  plusCircleDark,
  // copy,
} from '../assets';
import {
  CustomSelectDropdownOption,
  DefaultOptionLabel,
  SelectInfoContainer,
  SelectName,
  SelectOrg,
  SelectDescription,
  StencilDescription,
  SelectDeleteContainer,
  StencilInfoContainer,
} from '../styles/library';
import { HtmlToString } from '../utils';

// CUSTOM SELECT OPTION COMPONENT FOR COLUMNS
export const ColumnOption = (option) => {
  let disabled = false;
  const isSelected = option.data.visible || false;
  if (option.data.key === 'name' || option.data.key === 'id') {
    disabled = true;
  }
  return (
    <components.Option {...option} isDisabled={disabled}>
      <CustomSelectDropdownOption>
        <input
          type="checkbox"
          disabled={disabled}
          checked={isSelected}
          onChange={() => null}
        />
        <label>{option.label}</label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR MEMBER DROPDOWN
export const MemberOption = (option) => {
  const isSelected = option.data.isSelected || option.data.selected || false;
  const transformName = HtmlToString(option.data.name);
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown">
        <input type="checkbox" checked={isSelected} onChange={() => null} />
        <label className="select-dropdown-label">
          <span className="select-dropdown-span">{transformName} </span>
          {option.data.age || option.data.age === 0 ? (
            <span className="select-dropdown-span">
              ({option.data.age}
              {option.data.date_of_birth_estimated && 'e'})
            </span>
          ) : null}
        </label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR BENEFICIARY DROPDOWN
export const BeneficiaryOption = (option) => {
  const isSelected = option.data.isBeneficiary || false;
  const transformName = HtmlToString(option.data.name);
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown">
        <input type="checkbox" checked={isSelected} onChange={() => null} />
        <label className="select-dropdown-label">
          <span className="select-dropdown-span">{transformName} </span>
          {option.data.age || option.data.age === 0 ? (
            <span className="select-dropdown-span">
              ({option.data.age}
              {option.data.date_of_birth_estimated && 'e'})
            </span>
          ) : null}
        </label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR CONTINGENT BENEFICIARY DROPDOWN
export const ContingentBeneficiaryOption = (option) => {
  const isSelected = option.data.isContingentBeneficiary || false;
  const transformName = HtmlToString(option.data.name);
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown">
        <input type="checkbox" checked={isSelected} onChange={() => null} />
        <label className="select-dropdown-label">
          <span className="select-dropdown-span">{transformName} </span>
          {option.data.age || option.data.age === 0 ? (
            <span className="select-dropdown-span">
              ({option.data.age}
              {option.data.date_of_birth_estimated && 'e'})
            </span>
          ) : null}
        </label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR INSURED DROPDOWN
export const InsuredOption = (option) => {
  const isSelected = option.data.isInsured || false;
  const transformName = HtmlToString(option.data.name);
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown">
        <input type="checkbox" checked={isSelected} onChange={() => null} />
        <label className="select-dropdown-label">
          <span className="select-dropdown-span">{transformName} </span>
          {option.data.age || option.data.age === 0 ? (
            <span className="select-dropdown-span">
              ({option.data.age}
              {option.data.date_of_birth_estimated && 'e'})
            </span>
          ) : null}
        </label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR ASSET DROPDOWN
export const AssetsOption = (option) => {
  const isSelected = option.data.isSelected || false;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown">
        <input type="checkbox" checked={isSelected} onChange={() => null} />
        <label className="select-dropdown-label">
          <span className="select-dropdown-span">{option.data.name} </span>
          <span className="select-dropdown-span">({option.data.source})</span>
          <span className="select-dropdown-span">
            {' '}
            - {option.data.formattedValue}
          </span>
        </label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

// CUSTOM SELECT OPTION COMPONENT FOR REPORT AND HHI LAYOUTS
export const LayoutOption = (option, editFunction) => {
  const { isSelected } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption selected={isSelected} iconWidth="10px">
        <label>{option.label}</label>
        {option.data.is_default ? (
          <DefaultOptionLabel>default</DefaultOptionLabel>
        ) : option.data.is_system ? (
          <DefaultOptionLabel>system</DefaultOptionLabel>
        ) : null}
        {!option.data.is_system && (
          <img
            src={pencil}
            alt="edit"
            onClick={() => editFunction(option)}
            data-image="edit-layout"
          />
        )}
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

// CUSTOM SELECT OPTION COMPONENT FOR AMR Layouts
export const AMRLayoutsOption = (option, deleteFunction) => {
  const { name, description, is_default } = option.data;
  const { isSelected } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption
        id="select-dropdown"
        selected={isSelected}
        maxWidth="500px"
        marginLeft="0px"
      >
        <SelectInfoContainer>
          <SelectName id="select-dropdown-name">{name}</SelectName>
          <SelectDescription
            id="select-dropdown-description"
            selected={isSelected}
          >
            ({description})
          </SelectDescription>
        </SelectInfoContainer>
        <SelectDeleteContainer>
          {is_default && (
            <DefaultOptionLabel margin="0 0 0 5px">Default</DefaultOptionLabel>
          )}
          {!is_default && (
            <img
              src={trashDark}
              alt="delete"
              data-image="delete-layout"
              onClick={() => deleteFunction(option.data)}
            />
          )}
        </SelectDeleteContainer>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

// CUSTOM SELECT OPTION COMPONENT FOR AMR Proposals
export const AMRProposalsOption = (option, deleteFunction) => {
  const { name } = option.data;
  const { isSelected } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption
        id="select-dropdown"
        selected={isSelected}
        maxWidth="500px"
        marginLeft="0px"
      >
        <SelectInfoContainer>
          <SelectName id="select-dropdown-name">{name}</SelectName>
        </SelectInfoContainer>
        <SelectDeleteContainer>
          <img
            src={trashDark}
            alt="delete"
            data-image="delete-proposal"
            onClick={() => deleteFunction(option.data)}
          />
        </SelectDeleteContainer>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR STENCIL DROPDOWN
export const StencilOption = (option) => {
  const { isSelected } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown" selected={isSelected}>
        <SelectName id="select-dropdown-name" selected={isSelected}>
          {option.data.name}
          {option.data.is_assetmap_stencil && '*'}
        </SelectName>
        {option.data.is_customer_stencil && (
          <SelectOrg>&#40;{option.data.org}&#41;</SelectOrg>
        )}
        <SelectDescription
          selected={isSelected}
          id="select-dropdown-description"
          dangerouslySetInnerHTML={{ __html: option.data.description }}
        />
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR STENCIL LIBRARY DROPDOWN
export const StencilLibraryOption = (
  option,
  viewStencil,
  showClone,
  cloneFunction
) => {
  const { isSelected } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption
        id="select-dropdown"
        selected={isSelected}
        maxWidth="430px"
        marginLeft="0"
        iconWidth="16px"
      >
        <img
          src={imagePreview}
          alt="preview stencil"
          title="View image preview of stencil"
          onClick={() => viewStencil(option.data)}
          style={{ marginRight: '8px' }}
          data-image="view-stencil"
        />
        <StencilInfoContainer>
          <SelectName id="select-dropdown-name" selected={isSelected}>
            {option.data.name}
          </SelectName>
          <StencilDescription
            selected={isSelected}
            id="select-dropdown-description"
            dangerouslySetInnerHTML={{ __html: option.data.description }}
          />
        </StencilInfoContainer>
        {showClone && (
          <SelectDeleteContainer iconWidth="14px">
            <img
              src={plusCircleDark}
              alt="add"
              title="Add to Personal Library"
              onClick={() => cloneFunction(option.data)}
              data-image="clone-stencil"
            />
          </SelectDeleteContainer>
        )}
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR FOLDER DROPDOWN
export const FolderOption = (option) => {
  const isLinked = option?.data?.linked;
  const transformLabel = HtmlToString(option.data.label);
  const { isSelected } = option;

  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption
        id="select-dropdown"
        selected={isSelected}
        iconWidth="10px"
      >
        <label className="select-dropdown-label">
          <span className="select-dropdown-span">{transformLabel}</span>
          {isLinked && (
            <img src={link} alt="linked" data-image="folder-linked" />
          )}
        </label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR DELEGATE DROPDOWN
export const ActivityOption = (option) => {
  const isSelected = option.data.selected || false;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown">
        <input type="checkbox" checked={isSelected} onChange={() => null} />
        {option.data.name ? (
          <label>
            {option.data.name}{' '}
            <span style={{ fontSize: '10px' }}>{option.data.email}</span>
          </label>
        ) : (
          <label>{option.data.label}</label>
        )}
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

// CUSTOM SELECT OPTION COMPONENT FOR TARGET MAP TEMPLATES
export const TargetMapTemplateOption = (
  option,
  editFunction,
  deleteFunction
) => {
  const { isSelected } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption selected={isSelected}>
        <label>{option.label}</label>
        <img
          src={pencil}
          alt="edit"
          onClick={() => editFunction(option.data)}
          style={{ marginRight: '8px' }}
          data-image="edit-tm-template"
        />
        <img
          src={trashDark}
          alt="delete"
          onClick={() => deleteFunction(option.data)}
          data-image="delete-tm-template"
        />
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

// CUSTOM SELECT OPTION COMPONENT FOR ROLE
export const UserRoleOption = (option, cloneFunction, deleteFunction) => {
  const { isSelected, data } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption
        selected={isSelected}
        iconWidth="10px"
        maxWidth="100%"
      >
        <label>{option.label}</label>
        {data.source === 'system' && (
          <DefaultOptionLabel>system</DefaultOptionLabel>
        )}
        {/* {!option.data.is_system && (
          <>
            <img
              src={copy}
              alt="copy"
              onClick={() => cloneFunction(option.data)}
              style={{ marginRight: '8px' }}
              data-image="clone-role"
            />

            <img
              src={trashDark}
              alt="delete"
              onClick={() => deleteFunction(option.data)}
              data-image="delete-role"
            />
          </>
        )} */}
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

// CUSTOM SELECT OPTION COMPONENT FOR ROLE
export const TargetMapOption = (option) => {
  const { isSelected, data } = option;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption selected={isSelected} maxWidth="100%">
        <svg height="8" width="8">
          <circle cx="4" cy="4" r="4" fill={data.color} />
        </svg>
        <label style={{ fontSize: '11px' }}>{option.label}</label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR MULTI SELECT TM CATEGORY DROPDOWN
export const TargetMapMultiOption = (option) => {
  const { data } = option;
  const isSelected = data.isSelected;
  return (
    <components.Option {...option}>
      <CustomSelectDropdownOption id="select-dropdown" maxWidth="100%">
        <input type="checkbox" checked={isSelected} onChange={() => null} />
        <label>
          <svg height="8" width="8">
            <circle cx="4" cy="4" r="4" fill={data.color} />
          </svg>
          {option.label}
        </label>
      </CustomSelectDropdownOption>
    </components.Option>
  );
};

//CREATES CUSTOM COMPONENT DISPLAY FOR MEMBER DROPDOWN
export const LegalApplicableMemberOption = (option) => {
    const isSelected = option.data.isApplicable || false;
    const transformName = HtmlToString(option.data.name);
    return (
      <components.Option {...option}>
        <CustomSelectDropdownOption id="select-dropdown">
          <input type="checkbox" checked={isSelected} onChange={() => null} />
          <label className="select-dropdown-label">
            <span className="select-dropdown-span">{transformName} </span>
            {option.data.age || option.data.age === 0 ? (
              <span className="select-dropdown-span">
                ({option.data.age}
                {option.data.date_of_birth_estimated && 'e'})
              </span>
            ) : null}
          </label>
        </CustomSelectDropdownOption>
      </components.Option>
    );
  };

  export const LegalEmpoweredMemberOption = (option) => {
    const isSelected = option.data.isEmpowered || false;
    const transformName = HtmlToString(option.data.name);
    return (
      <components.Option {...option}>
        <CustomSelectDropdownOption id="select-dropdown">
          <input type="checkbox" checked={isSelected} onChange={() => null} />
          <label className="select-dropdown-label">
            <span className="select-dropdown-span">{transformName} </span>
            {option.data.age || option.data.age === 0 ? (
              <span className="select-dropdown-span">
                ({option.data.age}
                {option.data.date_of_birth_estimated && 'e'})
              </span>
            ) : null}
          </label>
        </CustomSelectDropdownOption>
      </components.Option>
    );
  };