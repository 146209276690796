/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, GenerateInput } from '../../../components';
import {
  getHouseholdInvestorProfile,
  updateHouseholdInvestorProfile,
} from '../../../store/actions';
import { PageContent } from '../../../styles/library/layoutStyles';
import {
  ButtonLink,
  SubGroupHeading,
  TertiaryHeading,
} from '../../../styles/library/fontStyles';
import { buttonColors, maxDevice } from '../../../styles/variables';
import { MatchSelectItem, SendTrackingEvent } from '../../../utils';

const HouseholdInvestorProfile = ({ householdId }) => {
  const dispatch = useDispatch();
  const {
    investorProfile,
    investorProfileError,
    updatedInvestorProfile,
    invocationId,
  } = useSelector((state) => ({
    investorProfile: state.households.investorProfile,
    investorProfileError: state.households.investorProfileError,
    updatedInvestorProfile: state.households.updatedInvestorProfile,
    invocationId: state.configs.invocationId,
  }));
  const [savingProfile, setSavingProfile] = useState(false);
  const [profile, setProfile] = useState({
    charitable_programs: false,
    educational_fund: false,
    emergency_fund: false,
    estate_planning: false,
    family_business: false,
    final_expenses: false,
    future_tax_strategies: false,
    investment_experience_401k: '',
    investment_experience_annuities: '',
    investment_experience_banking: '',
    investment_experience_bonds: '',
    investment_experience_business_interests: '',
    investment_experience_irasep: '',
    investment_experience_mutual_funds: '',
    investment_experience_other: '',
    investment_experience_real_estate: '',
    investment_objective: '',
    investment_time_horizon: '',
    mortgage_fund: false,
    protect_assets: false,
    replacement_income: false,
    retirement_needs: false,
    risk_profile: '',
    small_business: false,
    systematic_savings: false,
    tax_defferal_programs: false,
  });
  //TEXT INPUTS FOR INVESTMENT EXPERIENCE
  const [investmentInputs, setInvestmentInputs] = useState([
    { label: 'Banking', name: 'investment_experience_banking' },
    { label: 'Annuities', name: 'investment_experience_annuities' },
    { label: 'Real Estate', name: 'investment_experience_real_estate' },
    { label: 'Bond/Bond Funds', name: 'investment_experience_bonds' },
    { label: 'IRAs/SEPs', name: 'investment_experience_irasep' },
    {
      label: 'Business Interests',
      name: 'investment_experience_business_interests',
    },
    {
      label: 'Mutual Funds/Stocks',
      name: 'investment_experience_mutual_funds',
    },
    { label: '401(k)', name: 'investment_experience_401k' },
    { label: 'Other', name: 'investment_experience_other' },
  ]);

  //CHECKBOX INPUTS FOR FINANCIAL PRIORITIES
  const financialInputs = [
    {
      category: 'Managing Risk',
      inputs: [
        { label: 'Replacement Income', name: 'replacement_income' },
        { label: 'Protect Assets', name: 'protect_assets' },
        { label: 'Final Expenses', name: 'final_expenses' },
      ],
    },
    {
      category: 'Accumulation for Future',
      inputs: [
        { label: 'Mortgage Fund', name: 'mortgage_fund' },
        { label: 'Educational Fund', name: 'educational_fund' },
        { label: 'Emergency Fund', name: 'emergency_fund' },
      ],
    },
    {
      category: 'Financial Independence',
      inputs: [
        { label: 'Retirement Needs', name: 'retirement_needs' },
        { label: 'Systematic Savings', name: 'systematic_savings' },
      ],
    },
    {
      category: 'Wealth Preservation',
      inputs: [
        { label: 'Estate Planning', name: 'estate_planning' },
        { label: 'Family Business', name: 'family_business' },
        { label: 'Charitable Programs', name: 'charitable_programs' },
      ],
    },
    {
      category: 'Addressing Taxes',
      inputs: [
        { label: 'Tax Deferral Programs', name: 'tax_defferal_programs' },
        { label: 'Small Business', name: 'small_business' },
        { label: 'Future Tax Strategies', name: 'future_tax_strategies' },
      ],
    },
  ];

  //HOUSEHOLD RISK PREFERENCE DROPDOWN OPTIONS
  const riskPreferenceOptions = [
    {
      label: 'None',
      value: null,
    },
    {
      label: 'Conservative',
      value: 'CONSERVATIVE',
    },
    {
      label: 'Conservative/Moderate',
      value: 'CONSERVATIVE_MODERATE',
    },
    {
      label: 'Moderate',
      value: 'MODERATE',
    },
    {
      label: 'Moderate/Aggressive',
      value: 'MODERATE_AGGRESSIVE',
    },
    {
      label: 'Aggressive',
      value: 'AGGRESSIVE',
    },
  ];

  //INVESTMENT OBJECTIVE DROPDOWN OPTIONS
  const investmentObjectiveOptions = [
    {
      label: 'None',
      value: null,
    },
    {
      label: 'Principal Preservation',
      value: 'PRINCIPAL_PRESERVATION',
    },
    {
      label: 'Income',
      value: 'INCOME',
    },
    {
      label: 'Income and Growth',
      value: 'INCOME_AND_GROWTH',
    },
    {
      label: 'Growth and Income',
      value: 'GROWTH_AND_INCOME',
    },
    {
      label: 'Growth',
      value: 'GROWTH',
    },
  ];

  //INVESTMENT HORIZON DROPDOWN OPTIONS
  const investmentHorizonOptions = [
    {
      label: 'None',
      value: null,
    },
    {
      label: 'Short (0-3 years)',
      value: 'SHORT',
    },
    {
      label: 'Medium (3-7 years)',
      value: 'MEDIUM',
    },
    {
      label: 'Long (8+ years)',
      value: 'LONG',
    },
  ];

  //OBJECT FOR ADDITIONAL PREFERENCES SELECT DROPDOWNS
  const selectInputs = [
    {
      label: 'Household Risk Preference',
      name: 'risk_profile',
      value: profile.risk_profile,
      options: riskPreferenceOptions,
    },
    {
      label: 'Investment Objective',
      name: 'investment_objective',
      value: profile.investment_objective,
      options: investmentObjectiveOptions,
    },
    {
      label: 'Investment Time Horizon',
      name: 'investment_time_horizon',
      value: profile.investment_time_horizon,
      options: investmentHorizonOptions,
    },
  ];

  //INVESTOR PROFILE DATA OBJECT KEYS TO IGNORE WHEN SETTING NULL VALUES
  const excludeKeys = [
    'risk_profile',
    'investment_objective',
    'investment_time_horizon',
  ];

  //HOOK TO MAKE API CALL TO GET INVESTOR PROFILE DATA, EVERY TIME TAB IS CLICKED
  useEffect(() => {
    if (householdId) {
      dispatch(getHouseholdInvestorProfile(householdId));
    }
  }, [householdId]);

  //HOOK TO MAP API INVESTOR PROFILE TO LOCAL OBJECT FOR INPUTS
  useEffect(() => {
    if (investorProfile) {
      //SETS NULL VALUES TO EMPTY STRINGS
      Object.keys(investorProfile).reduce((acc, key) => {
        if (investorProfile[key] === null && !excludeKeys.includes(key)) {
          investorProfile[key] = '';
        }
        return acc;
      }, {});
      //SETS LOCAL DATA OBJECT TO MATCHING VALUE FROM API
      setProfile({
        ...investorProfile,
        risk_profile: MatchSelectItem(
          riskPreferenceOptions,
          investorProfile.risk_profile
        ),
        investment_objective: MatchSelectItem(
          investmentObjectiveOptions,
          investorProfile.investment_objective
        ),
        investment_time_horizon: MatchSelectItem(
          investmentHorizonOptions,
          investorProfile.investment_time_horizon
        ),
      });
    }
  }, [investorProfile]);

  //HOOK TO SEND TRACKING EVENT WHEN INVESTOR PROFILE IS UPDATED
  useEffect(() => {
    if (updatedInvestorProfile) {
      setSavingProfile(false);
      SendTrackingEvent(
        invocationId,
        'action',
        'household_profile_edit',
        'household_profile',
        { household: householdId }
      );
    }
  }, [updatedInvestorProfile]);

  //HOOK TO DISPLAY INPUT ERROR BORDERS AND MESSAGES FROM API
  useEffect(() => {
    if (investorProfileError) {
      setSavingProfile(false);
      if (investorProfileError.hasOwnProperty('data')) {
        const errorData = investorProfileError.data;
        const errorKeys = Object.keys(errorData);
        setInvestmentInputs(
          investmentInputs.map((input) => {
            let errorMessage = '';
            const hasError = errorKeys.includes(input.name);
            if (hasError) {
              const errorObject = errorData[input.name];
              if (errorObject) {
                errorMessage = errorObject[0];
              } else {
                errorMessage = 'Values must be between 0-99';
              }
            }
            input.hasError = hasError;
            input.errorMessage = errorMessage;
            return input;
          })
        );
      }
    }
  }, [investorProfileError]);

  //FUNCTION TO UPDATE LOCAL DATA OBJECT WHEN USER MODIFIES VALUES
  const profileOnChange = (e, isSelect = false, fieldName = null) => {
    if (isSelect) {
      setProfile({ ...profile, [fieldName]: e });
    } else {
      setProfile({
        ...profile,
        [e.currentTarget.name]: e.currentTarget.value,
      });
      setInvestmentInputs(
        investmentInputs.map((input) => {
          if (input.name === e.currentTarget.name) {
            input.hasError = false;
          }
          return input;
        })
      );
    }
  };

  //FUNCTION TO MAKE API WITH UPDATE INVESTOR PROFILE INFO
  const saveInvestorProfile = () => {
    setSavingProfile(true);
    dispatch(
      updateHouseholdInvestorProfile(householdId, {
        ...profile,
        investment_objective: profile.investment_objective.value,
        risk_profile: profile.risk_profile.value,
        investment_time_horizon: profile.investment_time_horizon.value,
      })
    );
  };

  return (
    <PageContent margin="25px 40px 70px 40px">
      <ProfileChunkContainer>
        <TertiaryHeading>Investment Experience (years)</TertiaryHeading>
        <InputGroupContainer $margin="0 -10px">
          {investmentInputs.map((input, index) => {
            return GenerateInput(
              {
                ...input,
                type: 'number',
                value: profile[input.name],
                onChange: (e) => profileOnChange(e),
                placeholder: '0',
                hasError: input.hasError,
                isVisible: true,
                width: '132px',
                mobileWidth: '100%',
                margin: '0 10px 10px 10px',
              },
              index
            );
          })}
        </InputGroupContainer>
      </ProfileChunkContainer>
      <ProfileChunkContainer>
        <TertiaryHeading margin="0 0 15px -0">
          Financial Priorities
        </TertiaryHeading>
        <InputGroupContainer $margin="0 -10px -20px -10px">
          {financialInputs.map((group, index) => {
            return (
              <FinancialGroup key={index}>
                <SubGroupHeading margin="0 0 5px 0">
                  {group.category}
                </SubGroupHeading>
                {group.inputs.map((input, index) => {
                  return GenerateInput(
                    {
                      ...input,
                      type: 'checkbox',
                      value: profile[input.name],
                      isVisible: true,
                      onChange: () =>
                        setProfile({
                          ...profile,
                          [input.name]: !profile[input.name],
                        }),
                      margin: '2px 0 2px -3px',
                    },
                    index
                  );
                })}
              </FinancialGroup>
            );
          })}
        </InputGroupContainer>
      </ProfileChunkContainer>
      <ProfileChunkContainer>
        <TertiaryHeading>Additional Preferences</TertiaryHeading>
        <InputGroupContainer $margin="0 -10px -10px -10px">
          {selectInputs.map((input, index) => {
            return GenerateInput(
              {
                ...input,
                isVisible: true,
                type: 'select',
                value: input.value,
                onChange: (e) => profileOnChange(e, true, input.name),
                placeholder: `Select ${input.label}`,
                hasError: input.hasError,
                width: '100%',
                maxWidth: '300px',
                margin: '0 10px 10px 10px',
              },
              index
            );
          })}
        </InputGroupContainer>
      </ProfileChunkContainer>
      <ButtonsContainer>
        <ButtonLink to={`/household/${householdId}/summary`}>
          Back to Summary
        </ButtonLink>
        <Button
          text={'Save Profile'}
          onClick={() => saveInvestorProfile()}
          showLoading={savingProfile}
          loadingText={'Saving'}
        />
      </ButtonsContainer>
    </PageContent>
  );
};

const ProfileChunkContainer = styled.div`
  margin-bottom: 40px;
`;

const InputGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin: ${(props) => props.$margin};
`;

const FinancialGroup = styled.div`
  margin: 0px 10px 20px 10px;
  flex: 1 1 250px;
  max-width: 250px;
`;

const ButtonsContainer = styled.div`
  @media ${maxDevice.mobileL} {
    button {
      width: 100%;
    }
  }
  margin-top: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  ${ButtonLink} {
    @media ${maxDevice.mobileL} {
      margin: 10px 0 0 0px;
      width: 100%;
    }
    margin-right: 10px;
    background: ${buttonColors.inactive};
  }
`;

HouseholdInvestorProfile.propTypes = {
  householdId: PropTypes.string,
};

export default HouseholdInvestorProfile;
