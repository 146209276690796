/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader, Button } from '../components';
import { updateKFFSettings } from '../store/actions';
import { square, checkboxGreen } from '../assets';
import { Page, PageContent } from '../styles/library/layoutStyles';
import { ButtonThemes } from '../styles/themes';
import { maxDevice, colors, fonts } from '../styles/variables';
import { headerContent } from '../utils';

const KeyFinancialFiguresContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttonText, setButtonText] = useState('Go Back');
  const { user, showAmrKff, lastHousehold } = useSelector((state) => ({
    user: state.user.user,
    showAmrKff: state.user.showAmrKff,
    lastHousehold: state.households.lastHousehold,
  }));
  const [selectedSummary, setSelectedSummary] = useState([]);
  const [selectedAssetMap, setSelectedAssetMap] = useState([]);

  const panels = [
    {
      heading: 'Family Planning Ratios',
      value: 'family_planning',
      side: 'left',
      options: [
        // { label: 'Family Risk Preference', value: 'risk_preference' },
        // { label: 'Investment Objective', value: 'investment_objective' },
        { label: 'Debt/Equity Ratio', value: 'ratio_debt' },
        { label: 'Assets', value: 'assets_amount' },
        {
          label: 'Total Assets Under Management',
          value: 'managed_assets_amount',
        },
        { label: 'Liabilities', value: 'liabilities_amount' },
        { label: 'Net Worth', value: 'net_worth' },
        {
          label: 'Assets Real Estate Equity Amount',
          value: 'assets_residence_equity_amount',
        },
        {
          label: '% of Assets in Non-Retirement Funds',
          value: 'ratio_cash_position',
        },
        {
          label: '% of Assets in Retirement Plans',
          value: 'ratio_retirement_position',
        },
        { label: 'Earned Household Income', value: 'income_earned_amount' },
        { label: 'Wallet Share', value: 'wallet_share' },
        { label: 'Household Income', value: 'incomes_amount' },
      ],
    },
    {
      heading: 'Accounts',
      value: 'accounts',
      side: 'left',
      options: [
        { label: 'Accounts Under Management', value: 'managed_count' },
        {
          label: 'Retirement Assets Under Management',
          value: 'managed_assets_retirement_count',
        },
        {
          label: 'Non-retirement Assets Under Management',
          value: 'managed_assets_nonretirement_count',
        },
      ],
    },
    {
      heading: 'Assets',
      value: 'assets',
      side: 'right',
      options: [
        {
          label: 'Cash Value (Life Insurance)',
          value: 'insurances_cash_value_amount',
        },
        {
          label: 'Cash Value Under Management',
          value: 'managed_assets_cash_amount',
        },
        { label: 'Annuity Accounts', value: 'assets_annuity_amount' },
        {
          label: 'Annuity Accounts Under Management	',
          value: 'managed_assets_annuity_amount',
        },
        {
          label: 'Non-retirement Assets',
          value: 'assets_nonretirement_amount',
        },
        {
          label: 'Non-retirement Assets Under Management',
          value: 'managed_assets_nonretirement_amount',
        },
        { label: 'Retirement Accounts', value: 'assets_retirement_amount' },
        {
          label: 'Retirement Accounts Under Management',
          value: 'managed_assets_retirement_amount',
        },
      ],
    },
    {
      heading: 'Risk Management',
      value: 'risk_management',
      side: 'right',
      options: [
        { label: 'Life Insurance Coverage', value: 'insurances_life_amount' },
        { label: 'Term Coverage', value: 'insurances_term_amount' },
        { label: 'Permanent Coverage', value: 'insurances_permanent_amount' },
        {
          label: 'Monthly Disability Benefit',
          value: 'insurances_disability_amount',
        },
        {
          label: 'Monthly Disability Replacement Ratio',
          value: 'ratio_disability_coverage',
        },
        {
          label: 'Aggregate Disability Coverage',
          value: 'insurances_disability_contract_amount',
        },
        {
          label: 'Daily Long-Term Care Benefit',
          value: 'insurances_ltc_amount',
        },
        {
          label: 'Aggregate Long-Term Care Coverage',
          value: 'insurances_ltc_contract_amount',
        },
      ],
    },
  ];

  useEffect(() => {
    if (user && user.hasOwnProperty('kff')) {
      setSelectedSummary(user.kff.household);
      setSelectedAssetMap(user.kff.amr);
    }
  }, [user]);

  useEffect(() => {
    if (lastHousehold) {
      setButtonText('Back to Household');
    }
  }, [lastHousehold]);

  const toggleSelected = (item, type) => {
    if (type === 'summary') {
      if (selectedSummary.includes(item.value)) {
        setSelectedSummary(selectedSummary.filter((sel) => sel !== item.value));
      } else {
        if (selectedSummary.length === 5) {
          selectedSummary.shift();
        }
        setSelectedSummary([...selectedSummary, item.value]);
      }
    }
    if (type === 'asset-map') {
      if (selectedAssetMap.includes(item.value)) {
        setSelectedAssetMap(
          selectedAssetMap.filter((sel) => sel !== item.value)
        );
      } else {
        if (selectedAssetMap.length === 5) {
          selectedAssetMap.shift();
        }
        setSelectedAssetMap([...selectedAssetMap, item.value]);
      }
    }
  };

  const saveSettings = () => {
    const object = {
      kff_config: selectedSummary,
      amr_kff_config: selectedAssetMap,
    };
    dispatch(updateKFFSettings(object));
  };

  const goBackClick = () => {
    if (lastHousehold) {
      return navigate(`/household/${lastHousehold}/summary`);
    } else {
      return navigate(-1);
    }
  };

  const generatePanelItem = (panel, index) => {
    return (
      <PanelContainer key={index}>
        <PanelHeader>
          <Heading>{panel.heading}</Heading>
          <SubHeading>Summary</SubHeading>
          {showAmrKff && <SubHeading>Asset-Map</SubHeading>}
        </PanelHeader>
        <PanelContent>
          {panel.options.map((option, index) => {
            return (
              <OptionRow key={index}>
                <OptionLabel>{option.label}</OptionLabel>
                <OptionValue>
                  <CheckboxItem
                    src={
                      selectedSummary.includes(option.value)
                        ? checkboxGreen
                        : square
                    }
                    alt={
                      selectedSummary.includes(option.value)
                        ? 'checked box'
                        : 'box'
                    }
                    data-span={option.value}
                    data-image={`summary_checkbox_${
                      selectedSummary.includes(option.value)
                        ? 'checked'
                        : 'unchecked'
                    }`}
                    onClick={() => {
                      toggleSelected(option, 'summary');
                    }}
                    style={
                      selectedSummary.includes(option.value)
                        ? { opacity: 1 }
                        : { opacity: 0.4 }
                    }
                  />
                </OptionValue>
                {showAmrKff && (
                  <OptionValue>
                    <CheckboxItem
                      data-span={option.value}
                      data-image={`assetmap_checkbox_${
                        selectedSummary.includes(option.value)
                          ? 'checked'
                          : 'unchecked'
                      }`}
                      src={
                        selectedAssetMap.includes(option.value)
                          ? checkboxGreen
                          : square
                      }
                      alt={
                        selectedAssetMap.includes(option.value)
                          ? 'checked box'
                          : 'box'
                      }
                      onClick={() => {
                        toggleSelected(option, 'asset-map');
                      }}
                      style={
                        selectedAssetMap.includes(option.value)
                          ? { opacity: 1 }
                          : { opacity: 0.4 }
                      }
                    />
                  </OptionValue>
                )}
              </OptionRow>
            );
          })}
        </PanelContent>
      </PanelContainer>
    );
  };

  return (
    <Page>
      <PageHeader
        content={headerContent.kffPref}
        buttonFunction={saveSettings}
      />
      <PageContent>
        <PanelsContainer>
          <LeftContainer>
            {panels.map((panel, index) => {
              if (panel.side === 'left') {
                return generatePanelItem(panel, index);
              }
            })}
          </LeftContainer>
          <RightContainer>
            {panels.map((panel, index) => {
              if (panel.side === 'right') {
                return generatePanelItem(panel, index);
              }
            })}
          </RightContainer>
        </PanelsContainer>
        <ButtonContainer>
          <Button
            text={buttonText}
            onClick={() => goBackClick()}
            theme={ButtonThemes.cancel}
          />
        </ButtonContainer>
      </PageContent>
    </Page>
  );
};

const CheckboxItem = styled.img`
  @media ${maxDevice.mobileL} {
    width: 12px;
    height: 12px;
  }
  cursor: pointer;
  width: 15px;
  height: 15px;
  flex: 0 0 auto;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const LeftContainer = styled.div`
  @media ${maxDevice.laptop} {
    flex: 1 1 auto;
    margin-right: 0px;
  }
  flex: 1 1 50%;
  margin-right: 15px;
`;

const RightContainer = styled.div`
  @media ${maxDevice.laptop} {
    flex: 1 1 auto;
    margin-left: 0px;
  }
  flex: 1 1 50%;
  margin-left: 15px;
`;

const PanelContainer = styled.div`
  border: 1px solid ${colors.paleGrey};
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  /* flex: 1 1 600px; */
`;

const PanelHeader = styled.div`
  @media ${maxDevice.mobileL} {
    font-size: 12px;
  }
  background: ${colors.lightGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 15px;
  position: relative;
  text-align: left;
  font-size: 14px;
`;

const Heading = styled.p`
  flex: 1 1 auto;
  font-weight: ${fonts.semiBold};
`;

const SubHeading = styled.p`
  @media ${maxDevice.mobileL} {
    font-size: 11px;
    width: 55px;
  }
  font-size: 13px;
  margin: 0 10px;
  width: 65px;
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
`;

const PanelContent = styled.div`
  background: white;
  padding: 5px 15px;
`;

const OptionRow = styled.div`
  @media ${maxDevice.mobileL} {
    font-size: 11px;
  }
  background: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0;
  font-size: 13px;
  border-bottom: 1px solid ${colors.lightGrey};
  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

const OptionLabel = styled.span`
  flex: 1 1 auto;
`;

const OptionValue = styled.span`
  @media ${maxDevice.mobileL} {
    width: 55px;
  }
  flex: 0 0 auto;
  width: 65px;
  margin: 0 10px;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  &:last-child {
    margin-right: 0;
  }
`;

const PanelsContainer = styled.div`
  @media ${maxDevice.laptop} {
    flex-wrap: wrap;
  }
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

export default KeyFinancialFiguresContainer;
