/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  maxDevice,
  inputColors,
  messageColors,
  fonts,
  colors,
} from '../../styles/variables';
import { search, times } from '../../assets';

//COMPONENT FOR CREATING SEARCH BAR INPUT(S) FOR INTEGRATIONS
const IntegrationSearchBar = ({
  selectedIntegration,
  searchTypes = [],
  selectSearchType,
  showSeparateSearchInputs = false,
  searchInputs = [],
  searchValue,
  updateSearchValue,
  missingSearchValue,
  onKeyDown,
  searchIntegrationOnClick,
}) => {
  const generatePlaceholder = () => {
    if (selectedIntegration?.search_placeholder) {
      return selectedIntegration.search_placeholder;
    } else if (selectedIntegration.key === 'morningstar') {
      return `Search by ${selectedIntegration.name} Client Name`;
    } else if (selectedIntegration.key === 'axasf') {
      return `Search ${selectedIntegration.name} Entities by Name`;
    } else {
      return `Search ${selectedIntegration.name} Contacts by Name`;
    }
  };

  return (
    <>
      {searchTypes && (
        <SearchOptionsContainer>
          {searchTypes.map((option, index) => {
            return (
              <SearchOption
                key={index}
                onClick={() => selectSearchType(option)}
                selected={option.selected}
              >
                {option.label}
              </SearchOption>
            );
          })}
        </SearchOptionsContainer>
      )}
      <SearchContainer>
        <SearchBoxContainer>
          {showSeparateSearchInputs ? (
            searchInputs.map((input, index) => {
              return (
                <SearchBox
                  key={index}
                  type={input.type}
                  placeholder={input.placeholder}
                  value={input.value}
                  onChange={(e) => input.onChange(e.target.value, input.key)}
                  onKeyDown={(e) => {
                    input.onKeyDown(e);
                  }}
                  style={{
                    marginRight: '10px',
                    border: input.hasError
                      ? `1px solid ${messageColors.error}`
                      : null,
                  }}
                />
              );
            })
          ) : (
            <>
              <SearchBox
                type="text"
                placeholder={generatePlaceholder()}
                value={searchValue}
                onChange={(e) => updateSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  onKeyDown(e);
                }}
                hasError={missingSearchValue}
              />
              <ClearIcon
                src={times}
                alt="clear"
                onClick={() => updateSearchValue('')}
                data-image="integration-clear"
                style={
                  searchValue !== ''
                    ? {
                        display: 'inline-block',
                      }
                    : null
                }
              />
            </>
          )}
        </SearchBoxContainer>
        <SearchIconContainer onClick={searchIntegrationOnClick}>
          <img src={search} alt="search" data-image="integration-search" />
        </SearchIconContainer>
      </SearchContainer>
    </>
  );
};

const SearchOptionsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  flex: 0 0 auto;
`;

const SearchOption = styled.div`
  flex: 0 0 auto;
  text-transform: capitalize;
  padding: 8px 15px;
  background: white;
  font-size: 13px;
  color: ${(props) => (props.selected ? colors.white : colors.darkGrey)};
  background: ${(props) => (props.selected ? colors.blue : colors.white)};
  border: ${(props) =>
    props.selected
      ? `1px solid ${colors.blue}`
      : `1px solid ${inputColors.border}`};
  border-radius: 3px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: ${fonts.semiBold};
  &:hover {
    transform: scale(1.01);
  }
`;

const SearchContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const SearchBoxContainer = styled.div`
  @media ${maxDevice.tablet} {
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
  }
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  max-width: 500px;
`;

const SearchBox = styled.input`
  display: inline-block;
  padding: 8px 18px 8px 10px;
  font-size: 13px;
  flex: 1 1 auto;
  height: 20px;
  border-radius: 4px;
  border: ${(props) =>
    props.hasError
      ? `1px solid ${messageColors.error}`
      : `1px solid ${inputColors.border}`};
`;

const ClearIcon = styled.img`
  position: absolute;
  height: 8px;
  width: 8px;
  right: 10px;
  cursor: pointer;
  display: none;
  &:hover {
    transform: scale(1.05);
  }
`;

const SearchIconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 18px;
  }
`;

IntegrationSearchBar.propTypes = {
  selectedIntegration: PropTypes.object,
  searchTypes: PropTypes.array,
  searchValue: PropTypes.string,
  selectSearchType: PropTypes.func,
  showSeparateSearchInputs: PropTypes.bool,
  searchInputs: PropTypes.array,
  updateSearchValue: PropTypes.func,
  missingSearchValue: PropTypes.bool,
  onKeyDown: PropTypes.func,
  searchIntegrationOnClick: PropTypes.func,
};

export default IntegrationSearchBar;
