import {
  TargetMapTypes as TARGET_MAP,
  ApiTypes as API,
  HouseholdTypes as HOUSEHOLD,
} from '../actions';

const initialState = {
  targetMaps: false,
  loadedTargetMaps: null,
  showingEditTargetMapModal: false,
  showingNewTargetMapModal: false,
  newTargetMapType: null,
};

const targetMaps = (state = initialState, action) => {
  switch (action.type) {
    case TARGET_MAP.UPDATE_TARGET_MAP_STORE_VALUE:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    case TARGET_MAP.GET_TARGET_MAPS:
      return {
        ...state,
        targetMaps: action.payload,
        loadedTargetMaps: true,
        clonedTargetMap: false,
        clonedTargetMapId: null,
        createdTargetMap: false,
        createdTargetMapId: null,
        deletedTargetMap: false,
        editedTargetMap: false,
        showingEditTargetMapModal: false,
        showingNewTargetMapModal: false,
        newTargetMapType: null,
        requestedTargetMap: null,
        targetMapSummaryError: null,
        updatedActiveTargetMap: false,
      };
    case HOUSEHOLD.REMOVE_HOUSEHOLD_INFO:
      return {
        ...state,
        targetMaps: false,
        loadedTargetMaps: false,
        clonedTargetMap: false,
        clonedTargetMapId: null,
        createdTargetMap: false,
        createdTargetMapId: null,
        deletedTargetMap: false,
        editedTargetMap: false,
        showingEditTargetMapModal: false,
        showingNewTargetMapModal: false,
        newTargetMapType: null,
        showingDesiredCashFlowModal: false,
        showingCustomCashFlowModal: false,
        showingCustomAssetModal: false,
        showingCustomInsuranceModal: false,
        targetMapNotesError: null,
        targetMapEditError: null,
        targetMapEditErrorId: null,
        targetMapInstrumentError: null,
        changedTargetMap: false,
        requestedTargetMap: null,
        targetMapSummaryError: null,
      };
    case TARGET_MAP.GET_TARGET_MAP_INFO:
      return {
        ...state,
        currentTargetMap: action.payload,
        createdTargetMap: false,
        createdTargetMapId: null,
        showingDesiredCashFlowModal: false,
        showingCustomCashFlowModal: false,
        showingCustomAssetModal: false,
        showingCustomInsuranceModal: false,
        targetMapNotesError: null,
        targetMapEditError: null,
        targetMapEditErrorId: null,
        requestedTargetMap: null,
        targetMapSummaryError: null,
        targetMapInstrumentError: null,
        changedTargetMap: false,
      };
    case TARGET_MAP.UPDATE_TARGET_MAP_ERROR:
      return {
        ...state,
        targetMapEditError: action.payload.error,
        targetMapEditErrorId: action.payload.targetmap,
      };
    case TARGET_MAP.UPDATE_TARGET_MAP_SUMMARY_ERROR:
      return {
        ...state,
        requestedTargetMap: action.payload.targetmap,
        targetMapSummaryError: action.payload.error,
      };
    case TARGET_MAP.REMOVE_TARGET_MAP_INFO:
      return {
        ...state,
        currentTargetMap: {},
      };
    case TARGET_MAP.GET_TARGET_MAP_CASH_FLOW:
      return {
        ...state,
        cashFlow: action.payload,
        changedTargetMap: false,
      };
    case TARGET_MAP.TOGGLE_SHOW_NEW_TARGET_MAP_MODAL:
      return {
        ...state,
        showingNewTargetMapModal: action.payload?.showModal,
        newTargetMapType: action.payload?.targetMapType,
        isRedirect: action.payload?.isRedirect,
      };
    case TARGET_MAP.TOGGLE_SHOW_EDIT_TARGET_MAP_MODAL:
      return {
        ...state,
        showingEditTargetMapModal: action.payload,
      };
    case TARGET_MAP.TOGGLE_SHOW_TARGET_MAP_NOTES_MODAL:
      return action.payload?.isNotesPage
        ? {
            ...state,
            showingTargetMapNotesModal: action.payload?.showModal,
            isNotesPage: true,
            currentTargetMap: { notes: action.payload?.notes },
          }
        : {
            ...state,
            showingTargetMapNotesModal: action.payload?.showModal,
            isNotesPage: false,
          };
    case TARGET_MAP.TOGGLE_SHOW_TARGET_MAP_TEMPLATE_MODAL:
      return {
        ...state,
        showingTargetMapTemplateModal: action.payload?.showModal,
        isNewTargetMapTemplate: action.payload?.isNew,
        targetMapTemplateRules: action.payload?.templateRules,
        existingTemplate: action.payload?.existingTemplate,
      };
    case TARGET_MAP.TOGGLE_SHOW_DESIRED_CASH_FLOW_MODAL:
      return {
        ...state,
        showingDesiredCashFlowModal: action.payload.showModal,
        isNewDesiredCashFlow: action.payload.newInstrument,
      };
    case TARGET_MAP.TOGGLE_SHOW_CUSTOM_CASH_FLOW_MODAL:
      return {
        ...state,
        showingCustomCashFlowModal: action.payload.showModal,
        isNewCustomCashFlow: action.payload.newInstrument,
      };
    case TARGET_MAP.TOGGLE_SHOW_CUSTOM_ASSET_MODAL:
      return {
        ...state,
        showingCustomAssetModal: action.payload.showModal,
        isNewCustomAsset: action.payload.newInstrument,
      };
    case TARGET_MAP.TOGGLE_SHOW_CUSTOM_INSURANCE_MODAL:
      return {
        ...state,
        showingCustomInsuranceModal: action.payload.showModal,
      };
    case TARGET_MAP.GET_TARGET_MAP_TEMPLATES:
      return {
        ...state,
        targetMapTemplates: action.payload,
        targetMapTemplateError: false,
      };
    case TARGET_MAP.SUBMIT_TARGET_MAP_ALT_DATA:
      return {
        ...state,
        targetMapAltValue: action.payload,
        targetMapAltError: null,
      };
    case API.API_SUCCESS:
      switch (action.payload.label) {
        case TARGET_MAP.CLONE_TARGET_MAP:
          return {
            ...state,
            clonedTargetMap: true,
            clonedTargetMapId: action.payload?.data?.targetmap_id,
          };
        case TARGET_MAP.UPDATE_TARGET_MAP:
          return {
            ...state,
            editedTargetMap: true,
          };
        case TARGET_MAP.CREATE_TARGET_MAP:
          return {
            ...state,
            createdTargetMap: true,
            createdTargetMapId: action.payload?.data?.targetmap_id,
            showingNewTargetMapModal: false,
          };
        case TARGET_MAP.UPDATE_TARGET_MAP_ACTIVE:
          return {
            ...state,
            updatedActiveTargetMap: true,
            requestedTargetMap: null,
            targetMapSummaryError: null,
          };
        case TARGET_MAP.UPDATE_TARGET_MAP_NOTES:
          return {
            ...state,
            showingTargetMapNotesModal: false,
          };
        case TARGET_MAP.CREATE_TARGET_MAP_INSTRUMENT:
        case TARGET_MAP.UPDATE_TARGET_MAP_INSTRUMENT:
        case TARGET_MAP.TOGGLE_IS_SELECTED_TARGET_MAP:
        case TARGET_MAP.DELETE_TARGET_MAP_INSTRUMENT:
          return {
            ...state,
            changedTargetMap: true,
          };
        case TARGET_MAP.CREATE_TARGET_MAP_TEMPLATE:
        case TARGET_MAP.UPDATE_TARGET_MAP_TEMPLATE:
        case TARGET_MAP.DELETE_TARGET_MAP_TEMPLATE:
          return {
            ...state,
            showingTargetMapTemplateModal: false,
            isNewTargetMapTemplate: true,
            targetMapTemplateRules: [],
            existingTemplate: null,
            targetMapTemplateError: false,
          };
        default:
          return {
            ...state,
          };
      }
    case API.API_END:
      switch (action.payload) {
        case TARGET_MAP.CREATE_TARGET_MAP:
          return {
            ...state,
            createTargetMapError: false,
          };
        case TARGET_MAP.DELETE_TARGET_MAP:
          return {
            ...state,
            deletedTargetMap: true,
          };
        default:
          return {
            ...state,
          };
      }
    case API.API_ERROR:
      switch (action.payload.label) {
        case TARGET_MAP.GET_TARGET_MAPS:
          return {
            ...state,
            targetMaps: false,
            loadedTargetMaps: false,
          };
        case TARGET_MAP.CREATE_TARGET_MAP:
          return {
            ...state,
            createTargetMapError: action.payload.error,
          };
        case TARGET_MAP.UPDATE_TARGET_MAP:
          return {
            ...state,
            targetMapEditError: action.payload.error,
          };
        case TARGET_MAP.UPDATE_TARGET_MAP_NOTES:
          return {
            ...state,
            targetMapNotesError: action.payload.error,
          };
        case TARGET_MAP.CREATE_TARGET_MAP_TEMPLATE:
        case TARGET_MAP.UPDATE_TARGET_MAP_TEMPLATE:
          return {
            ...state,
            targetMapTemplateError: action.payload.error,
          };
        case TARGET_MAP.CREATE_TARGET_MAP_INSTRUMENT:
          return {
            ...state,
            targetMapInstrumentError: action.payload.error,
          };
        case TARGET_MAP.UPDATE_TARGET_MAP_INSTRUMENT:
          return {
            ...state,
            targetMapInstrumentError: action.payload.error,
          };
        case TARGET_MAP.SUBMIT_TARGET_MAP_ALT_DATA:
          return {
            ...state,
            targetMapAltError: action.payload.error,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default targetMaps;
