/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { LoadingAnimation, InfoBox, Error } from '..';
import {
  toggleShowStencilImagePreviewModal,
  updateHouseholdStoreValue,
} from '../../store/actions';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ModalLoadingOverlayContainerAbsolute,
  LoadingOverlayContainer,
  LoadingOverlay,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import { SendTrackingEvent, DateFormat } from '../../utils';
import { fonts } from '../../styles/variables';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';

const ImagePreviewModal = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { stencilPreviewDetails, invocationId } = useSelector((state) => ({
    stencilPreviewDetails: state.households.stencilPreviewDetails,
    invocationId: state.configs.invocationId,
  }));
  const [stencilImage, setStencilImage] = useState();
  const [stencilDetails, setStencilDetails] = useState();
  const [imageLoading, setImageLoading] = useState();
  const [imageError, setImageError] = useState();
  const [imageInfo, setImageInfo] = useState();

  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('stencilAmrImage', null));
      dispatch(updateHouseholdStoreValue('stencilPreviewDetails', null));
      setStencilImage();
    };
  }, []);

  useEffect(() => {
    if (stencilPreviewDetails) {
      setImageLoading(stencilPreviewDetails?.imageLoading);
      setStencilImage(stencilPreviewDetails?.image.url);
      setStencilDetails(stencilPreviewDetails);
      if (!stencilPreviewDetails?.imageLoading) {
        SendTrackingEvent(
          invocationId,
          'click',
          'stencil_image_preview',
          'household_amr',
          {
            stencil_amr_image: stencilPreviewDetails?.image.url,
            stencil: stencilPreviewDetails,
            is_team_manager: false,
          }
        );
      }
      if (stencilPreviewDetails?.image?.is_error) {
        if (stencilPreviewDetails?.image?.is_default) {
          setImageError('Unable to generate image.');
        } else {
          let message = 'Unable to refresh image';
          if (stencilPreviewDetails?.image?.generated_utc) {
            message += `, this image was generated on ${DateFormat(
              stencilPreviewDetails.image.generated_utc,
              'MM/DD/YYYY'
            )}`;
          }
          setImageInfo(message);
        }
      }
    }
  }, [stencilPreviewDetails]);

  const closePreviewModal = () => {
    dispatch(updateHouseholdStoreValue('stencilAmrImage', null));
    dispatch(updateHouseholdStoreValue('stencilPreviewDetails', null));
    dispatch(toggleShowStencilImagePreviewModal(false, null));
    setStencilImage();
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer
          role="dialog"
          ref={ref}
          width={'75vw'}
          style={{ minHeight: '580px' }}
        >
          <ModalHeadingContainer>
            <ModalHeading>
              Stencil<sup>™</sup> Image Preview
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => closePreviewModal()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainerAbsolute>
            {imageLoading && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
          </ModalLoadingOverlayContainerAbsolute>
          <ModalContentContainer>
            <StencilName>{stencilDetails?.name}</StencilName>
            {stencilImage ? (
              <ImagePreview src={stencilImage} alt="Stencil  Preview" />
            ) : (
              <LoadingPlaceholder width="100%" height="500px" />
            )}
          </ModalContentContainer>
          {imageError && imageError !== '' && (
            <Error errorMessage={imageError} />
          )}
          {imageInfo && imageInfo !== '' && (
            <InfoContainer>
              <InfoBox message={imageInfo} />
            </InfoContainer>
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const StencilName = styled.p`
  font-weight: ${fonts.semiBold};
  margin-bottom: 15px;
`;

const ImagePreview = styled.img`
  width: 70vw;
`;

// const ImagePreviewContainer = styled.div`
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   width: 80vw;
//   overflow: hidden;
//   background-size: contain;
//   background-position: center;
//   background-repeat: no-repeat;
//   img {
//     width: 100%;
//     /* height: 100%; */
//   }
// `;

const InfoContainer = styled.div`
  padding: 0px 10px;
`;

export default ImagePreviewModal;
