import styled from 'styled-components';
import { Button, Select, TextInput } from '../';
import { maxDevice, boxShadows } from '../../styles/variables';
import { selectThemes, ButtonThemes } from '../../styles/themes';
import PropTypes from 'prop-types';

const AddMemberCard = ({
  isMember = true,
  inputs,
  onAdd,
  onCancel,
  membersList,
}) => {
  return (
    <CardContainer>
      {isMember ? (
        <>
          {inputs.map((input, index) => {
            if (input.type === 'select') {
              return (
                <SelectContainer key={index} style={{ marginBottom: '10px' }}>
                  <Select
                    options={input.options}
                    selectedOption={input.value[input.name]}
                    onChange={(e) =>
                      input.onChange(
                        input.updater,
                        input.value,
                        input.type,
                        e,
                        input.name
                      )
                    }
                    isSearchable={true}
                    placeholder={input.placeholder}
                    autoFocus={false}
                    isDisabled={input.isDisabled}
                    hasError={input.hasError}
                    styles={selectThemes.smaller}
                    isSmaller={true}
                  />
                </SelectContainer>
              );
            } else if (input.type === 'date') {
              return (
                <InputContainer
                  key={index}
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  <TextInput
                    type={'text'}
                    name={input.name}
                    maxLength={input.maxLength}
                    hasError={input.hasError}
                    placeholder={input.placeholder}
                    value={input.value[input.name]}
                    ageDate={true}
                    onDateChange={input.onDateChange}
                    onBlur={input.onBlur}
                    dateValue={input.dateValue}
                    dateFormat={input.dateFormat}
                    maxDate={new Date()}
                    onChange={(e) =>
                      input.onChange(input.updater, input.value, input.type, e)
                    }
                  />
                </InputContainer>
              );
            } else {
              return (
                <InputContainer
                  key={index}
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  <TextInput
                    type={input.type}
                    name={input.name}
                    maxLength={input.maxLength}
                    hasError={input.hasError}
                    placeholder={input.placeholder}
                    value={input.value[input.name]}
                    onChange={(e) =>
                      input.onChange(input.updater, input.value, input.type, e)
                    }
                  />
                </InputContainer>
              );
            }
          })}
          {membersList.length !== 0 ? (
            <ButtonsContainer>
              <Button
                text={'Cancel'}
                theme={ButtonThemes.cancel}
                onClick={() => onCancel()}
              />
              <Button text={'Add Member'} onClick={() => onAdd()} />
            </ButtonsContainer>
          ) : (
            <ButtonContainer>
              <Button text={'Add Member'} onClick={() => onAdd()} />
            </ButtonContainer>
          )}
        </>
      ) : (
        <>
          {inputs.map((input, index) => {
            if (input.type === 'select') {
              return (
                <SelectContainer key={index} style={{ marginBottom: '10px' }}>
                  <Select
                    options={input.options}
                    selectedOption={input.value[input.name]}
                    onChange={(e) =>
                      input.onChange(
                        input.updater,
                        input.value,
                        input.type,
                        e,
                        input.name
                      )
                    }
                    isSearchable={true}
                    placeholder={input.placeholder}
                    autoFocus={false}
                    hasError={input.hasError}
                    isDisabled={input.isDisabled}
                    styles={selectThemes.smaller}
                    isSmaller={true}
                  />
                </SelectContainer>
              );
            } else {
              return (
                <InputContainer
                  key={index}
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  <TextInput
                    type={input.type}
                    name={input.name}
                    maxLength={input.maxLength}
                    hasError={input.hasError}
                    placeholder={input.placeholder}
                    value={input.value[input.name]}
                    onChange={(e) =>
                      input.onChange(input.updater, input.value, input.type, e)
                    }
                  />
                </InputContainer>
              );
            }
          })}
          {membersList.length !== 0 ? (
            <ButtonsContainer>
              <Button
                text={'Cancel'}
                theme={ButtonThemes.cancel}
                onClick={() => onCancel()}
              />
              <Button text={'Add Entity'} onClick={() => onAdd()} />
            </ButtonsContainer>
          ) : (
            <ButtonContainer>
              <Button text={'Add Entity'} onClick={() => onAdd()} />
            </ButtonContainer>
          )}
        </>
      )}
    </CardContainer>
  );
};

AddMemberCard.propTypes = {
  isMember: PropTypes.bool,
  inputs: PropTypes.array,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  membersList: PropTypes.array,
};

const InputContainer = styled.div`
  @media ${maxDevice.sideNav} {
    min-width: 100%;
    flex: 1 1 auto;
    margin-bottom: 15px;
    max-width: 100%;
  }
  margin: 0 15px 0 0;
  display: flex;
  max-width: 400px;
  flex: 1 1 auto;
  input {
    padding: 9.5px 10px;
    flex: 1 1 auto;
  }
`;

const SelectContainer = styled.div`
  @media ${maxDevice.sideNav} {
    min-width: 100%;
    flex: 1 1 auto;
  }
  flex: 1 1 auto;
  max-width: 225px;
  min-width: 150px;
`;

const CardContainer = styled.div`
  @media ${maxDevice.sideNav} {
    width: 100%;
    margin-right: 0;
  }
  box-shadow: ${boxShadows.boxShadow};
  border-radius: 2px;
  padding: 20px 20px 20px 20px;
  background: white;
  min-width: 170px;
  width: 170px;
  height: 220px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: center;
  ${InputContainer} {
    flex: 0 0 auto;
  }
  div {
    input {
      padding: 5px 10px;
      width: 100%;
      flex: 1 1 auto;
      border-radius: 5px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  button {
    flex: 1 1 auto;
    width: 100%;
    padding: 7px;
    font-size: 11px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  button {
    flex: 1 1 auto;
    width: 100%;
    padding: 7px;
    font-size: 11px;
    &:first-child {
      margin-right: 10px;
      width: 100px;
    }
  }
`;

export default AddMemberCard;
