import { DeletedHouseholdsContainer } from '../../containers';
import { PageHeader } from '../../components';
import { UpdatePageTitle, CloseDropdowns, headerContent } from '../../utils';
import { Page, PageContent } from '../../styles/library/layoutStyles';

const ManagerDeletedHouseholds = () => {
  UpdatePageTitle('Deleted Households');
  CloseDropdowns();

  return (
    <Page>
      <PageHeader content={headerContent.managerDeletedHouseholds} />
      <PageContent>
        <DeletedHouseholdsContainer accessor={'customer'} />
      </PageContent>
    </Page>
  );
};

export default ManagerDeletedHouseholds;
