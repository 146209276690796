/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  ReportGenerateItem,
  SimpleModal,
  Button,
  EmptyImage,
  Select,
  PageFooter,
} from '../../components';
import {
  getAssetMapLayouts,
  createReportLayout,
  updateReportLayout,
  deleteReportLayout,
  getHouseholdMilestones,
  toggleShowMilestoneModal,
} from '../../store/actions';
import { noReports, chevronDown } from '../../assets';
import {
  colors,
  maxDevice,
  boxShadows,
  fonts,
  inputColors,
} from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import { InputLabel } from '../../styles/library/inputStyles';
import {
  ClickContainer,
  ClickText,
  ClickImage,
  DropdownContainer,
  DropdownHeading,
  DropdownContent,
  DropdownItem,
} from '../../styles/library/dropdownStyles';
import {
  UseOutsideClick,
  defaultReportLayout,
  reportsList,
  ErrorNotification,
  DownloadReport,
  LayoutOption,
  SendTrackingEvent,
  HtmlToString,
  DetectEnterKeyPress,
  DownloadComprehensiveReport,
  amrEntityOwnershipPreferences,
  amrOrientationPreferences,
} from '../../utils';

const HouseholdReportsGenerate = ({ householdId }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const {
    showReportLayouts,
    reportLayouts,
    createReportError,
    assetMapLayouts,
    currentHousehold,
    targetMaps,
    loadedTargetMaps,
    customLDD,
    householdMilestones,
    reportSettings,
    preferences,
    showTargetMaps,
    showHouseholdSignals,
    isStencil,
    justSavedLayout,
    savedLayout,
    justCreatedLayout,
    createdLayout,
    loadedUser,
    hasEntityRollup,
    hasLegal,
    invocationId,
  } = useSelector((state) => ({
    showReportLayouts: state.user.showReportLayouts,
    reportLayouts: state.user.reportLayouts,
    createReportError: state.user.createReportError,
    assetMapLayouts: state.households.assetMapLayouts,
    currentHousehold: state.households.currentHousehold,
    targetMaps: state.targetMaps.targetMaps,
    loadedTargetMaps: state.targetMaps.loadedTargetMaps,
    customLDD: state.user.customLDD,
    householdMilestones: state.households.householdMilestones,
    reportSettings: state.configs.reports,
    preferences: state.user.user.preferences,
    showTargetMaps: state.user.showTargetMaps,
    showHouseholdSignals: state.user.showHouseholdSignals,
    isStencil: state.households.isStencil,
    justSavedLayout: state.user.justSavedLayout,
    savedLayout: state.user.savedLayout,
    justCreatedLayout: state.user.justCreatedLayout,
    createdLayout: state.user.createdLayout,
    loadedUser: state.user.loadedUser,
    hasEntityRollup: state.user.hasEntityRollup,
    hasLegal: state.user.hasLegal,
    invocationId: state.configs.invocationId,
  }));
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [loadedReports, setLoadedReports] = useState(false);
  const [targetMapsReady, setTargetMapsReady] = useState(false);
  const [showSaveMenu, setShowSaveMenu] = useState(false);
  const [reports, setReports] = useState(
    reportsList.map((report) => {
      return {
        ...report,
      };
    })
  );
  const [reportsOrder, setReportsOrder] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState(defaultReportLayout);
  const [reportLayoutsList, setReportLayoutsList] = useState([
    defaultReportLayout,
  ]);
  const [amrEntityOwnershipPref, setAmrEntityOwnershipPref] = useState(
    amrEntityOwnershipPreferences[0]
  );
  const [amrShowRollupPref, setAmrShowRollupPref] = useState(1);
  const [amrRollupExpandPref, setAmrRollupExpandPref] = useState();
  const [amrOrientationPref, setAmrOrientationPref] = useState();
  const [showNewLayoutModal, setShowNewLayoutModal] = useState(false);
  const [currentLayout, setCurrentLayout] = useState({
    name: '',
    is_default: false,
    data: [],
  });
  const [layoutNameFieldError, setLayoutNameFieldError] = useState(false);
  const [saveLayoutError, setSaveLayoutError] = useState('');
  const [editLayoutError, setEditLayoutError] = useState('');
  const [showEditLayoutModal, setShowEditLayoutModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const placeholderReportList = reportsList.filter((report) => !report.custom);
  const legalType = {
    label: 'Legal',
    value: 'legal',
    selected: true,
    pages: 0,
    isHidden: true,
  };

  UseOutsideClick(ref, () => {
    if (showSaveMenu) {
      setShowSaveMenu(!showSaveMenu);
    }
  });

  useEffect(() => {
    if (householdId) {
      dispatch(getAssetMapLayouts(householdId));
      dispatch(getHouseholdMilestones(householdId, { limit: 5 }));
    }
  }, [householdId]);

  useEffect(() => {
    return () => {
      setLoadedReports(false);
    };
  }, []);

  useEffect(() => {
    if (hasEntityRollup) {
      let matchingAmrEntityOwnershipPref = amrEntityOwnershipPreferences[0];
      setAmrEntityOwnershipPref(matchingAmrEntityOwnershipPref);
    }
  }, [hasEntityRollup]);

  useEffect(() => {
    if (loadedUser) {
      const updatedReports = reports.map((report) => {
        if (report.value === 'policysummary') {
          const existingType = report.types.find((t) => t.value === 'legal');
          if (!existingType && hasLegal) {
            report.types = [...report.types, legalType];
          }
        }
        return report;
      });
      setReports(updatedReports);
    }
  }, [loadedUser]);

  useEffect(() => {
    if (preferences) {
      let matchingAmrPref = amrOrientationPreferences[0];
      if (preferences.amr.orientation) {
        matchingAmrPref = amrOrientationPreferences.find(
          (option) => option.value === preferences.amr.orientation
        );
      }
      setAmrOrientationPref(matchingAmrPref);
    }
  }, [preferences]);

  useEffect(() => {
    if (!showEditLayoutModal && !showDeleteModal) {
      resetLayout();
      setEditLayoutError('');
    }
  }, [showEditLayoutModal, showDeleteModal]);

  useEffect(() => {
    if (createReportError) {
      if (createReportError?.data?.report_layout) {
        setSaveLayoutError(createReportError?.data?.report_layout);
      } else {
        setSaveLayoutError('Error saving layout');
      }
    } else {
      setSaveLayoutError('');
    }
  }, [createReportError]);

  useEffect(() => {
    if (justCreatedLayout && createdLayout?.id) {
      SendTrackingEvent(
        invocationId,
        'action',
        'report_layout_create',
        'household_reports',
        {
          household: currentHousehold.id,
          layout_id: createdLayout?.id,
          layout_name: createdLayout?.name,
        }
      );
    }
  }, [justCreatedLayout, createdLayout]);

  useEffect(() => {
    if (justSavedLayout && savedLayout?.id) {
      SendTrackingEvent(
        invocationId,
        'action',
        'report_layout_update',
        'household_reports',
        {
          household: currentHousehold.id,
          layout_id: savedLayout?.id,
          layout_name: savedLayout?.name,
        }
      );
    }
  }, [justSavedLayout, savedLayout]);

  useEffect(() => {
    if (reportLayouts) {
      setShowNewLayoutModal(false);
      resetLayout();
      let transformedReportLayouts = [];
      transformedReportLayouts = reportLayouts.map((layout) => {
        layout.value = layout?.id;
        layout.label = layout?.name;
        if (layout.hasOwnProperty('data')) {
          if (!Array.isArray(layout.data)) {
            layout.data = Object.entries(layout.data).map((e) => {
              return {
                value: e[0],
                ...e[1],
              };
            });
          }
        } else {
          layout.corrupt = true;
        }
        return layout;
      });
      transformedReportLayouts = transformedReportLayouts.filter(
        (report) => !report.corrupt
      );
      const defaultLayout = transformedReportLayouts.find(
        (layout) => layout.is_default
      );
      if (createdLayout?.id) {
        const matchingLayout = transformedReportLayouts.find(
          (layout) => layout.id === createdLayout.id
        );
        if (matchingLayout) {
          setReportLayoutsList([
            defaultReportLayout,
            ...transformedReportLayouts,
          ]);
          return setSelectedLayout(matchingLayout);
        }
      }
      if (!justSavedLayout) {
        if (defaultLayout) {
          setSelectedLayout(defaultLayout);
        } else {
          setSelectedLayout(defaultReportLayout);
        }
      } else {
        const matchingLayout = transformedReportLayouts.find(
          (layout) => layout.id === selectedLayout.id
        );
        if (matchingLayout) setSelectedLayout(matchingLayout);
      }
      setReportLayoutsList([defaultReportLayout, ...transformedReportLayouts]);
    }
  }, [reportLayouts, createdLayout]);

  useEffect(() => {
    if (showTargetMaps !== null) {
      const updatedReports = reports.map((report) => {
        if (report.value === 'targetmap') {
          report.selected = showTargetMaps;
          report.allSelected = showTargetMaps;
          report.available = showTargetMaps;
        }
        return report;
      });
      setReports(updatedReports);
    }
  }, [showTargetMaps]);

  useEffect(() => {
    if (showHouseholdSignals !== undefined) {
      const updatedReports = reports.map((report) => {
        if (report.value === 'signals') {
          report.available = showHouseholdSignals;
          //I'm setting it to false for [pt:#180950897]
          report.selected = false;
        }
        return report;
      });
      setReports(updatedReports);
    }
  }, [showHouseholdSignals]);

  // useEffect(() => {
  //   if (assetMapLayouts) {
  //     console.log('assetMapLayouts', assetMapLayouts);
  //     const updatedReports = reports.map((report) => {
  //       if (report.value === 'assetmap') {
  //         report.children = assetMapLayouts.map((report, index) => {
  //           return {
  //             label: report.name,
  //             id: report.id,
  //             value: report.id,
  //             selected: report.is_default,
  //             order: index,
  //             pages: 1,
  //             childValue: 'assetmap',
  //           };
  //         });
  //       }
  //       return report;
  //     });
  //     setReports(updatedReports);
  //   }
  // }, [assetMapLayouts]);

  //IF USER DOESN'T HAVE TARGET MAPS CAN CONTINUE TO LOAD REPORTS - IF THEY DO MUST WAIT UNTIL THEY ARE RETURNED
  useEffect(() => {
    if (showTargetMaps !== null) {
      if (showTargetMaps === false) {
        setTargetMapsReady(true);
      } else if (showTargetMaps && loadedTargetMaps) {
        setTargetMapsReady(true);
      }
    }
  }, [showTargetMaps, loadedTargetMaps]);

  useEffect(() => {
    if (
      currentHousehold &&
      currentHousehold.hasOwnProperty('name') &&
      reportSettings &&
      assetMapLayouts &&
      targetMapsReady
    ) {
      const updatedReports = reports.map((report) => {
        if (report.custom) {
          report.name = reportSettings.available[report.value];
        }
        if (report.value === 'targetmap') {
          report.available = reportSettings.available.targetmap;
          report.cashflowAllowed = reportSettings.available.cashflow;
          if (
            reportSettings.hasOwnProperty('options') &&
            reportSettings.options.hasOwnProperty('cashflows_checked')
          )
            report.cashFlowAllSelected =
              reportSettings.options.cashflows_checked;
        } else {
          report.available = reportSettings.available[report.value];
          if (reportSettings.hasOwnProperty('required')) {
            report.disabled = reportSettings.required[report.value];
          }
        }
        return {
          ...report,
        };
      });
      const transformedMemberNames = [...currentHousehold.householdmembers].map(
        (mem) => {
          mem.name = HtmlToString(mem.name);
          return mem;
        }
      );
      let beneficiary = [...transformedMemberNames];
      let balance = [...transformedMemberNames];
      let policy = [...transformedMemberNames];

      let beneficiaryMembers = beneficiary.map((member) => {
        let updatedMember = {
          ...member,
        };
        updatedMember.label = member.name;
        updatedMember.value = member.id;
        updatedMember.selected = true;
        updatedMember.pages = 1;
        if (member.member_type === 'member') {
          updatedMember.showAge = true;
        }
        return updatedMember;
      });

      let balanceMembers = balance.map((member) => {
        let updatedMember = {
          ...member,
        };
        updatedMember.label = member.name;
        updatedMember.value = member.id;
        updatedMember.selected = true;
        updatedMember.pages = 0;
        if (member.member_type === 'member') {
          updatedMember.showAge = true;
        }
        return updatedMember;
      });

      let policyMembers = policy.map((member) => {
        let updatedMember = {
          ...member,
        };
        updatedMember.label = member.name;
        updatedMember.value = member.id;
        updatedMember.selected = true;
        updatedMember.pages = 0;
        if (member.member_type === 'member') {
          updatedMember.showAge = true;
        }
        return updatedMember;
      });

      let policyFiltered = policyMembers.filter((member) => {
        return member.member_type === 'member';
      });
      let targetMapsList = [];
      if (targetMaps) {
        targetMapsList = targetMaps.map((map) => {
          map.label = HtmlToString(map.name);
          map.value = map.id;
          map.selected = true;
          map.pages = 1;
          map.cashFlow = false;
          map.noCashflow = !reportSettings.available.cashflow;
          return map;
        });
      }
      let assetMapsList = [];
      if (assetMapLayouts) {
        assetMapsList = assetMapLayouts.map((amr, index) => {
          return {
            label: amr.name,
            id: amr.id,
            value: amr.id,
            selected: amr.is_default,
            order: index,
            pages: 1,
            childValue: 'assetmap',
          };
        });
      }
      let summaryMap = {
        label: 'Target-Map Summary',
        value: 'summary',
        selected: true,
        pages: 1,
        noCashflow: true,
      };
      targetMapsList = [summaryMap, ...targetMapsList];
      setReports(
        updatedReports.map((report) => {
          if (report.hasOptions) {
            if (report.value === 'beneficiary') {
              report.children = beneficiaryMembers;
              return report;
            } else if (report.value === 'balancesheet') {
              report.children = balanceMembers;
              return report;
            } else if (report.value === 'policysummary') {
              report.children = policyFiltered;
              return report;
            } else if (report.value === 'targetmap') {
              report.children = targetMapsList;
              return report;
            } else if (report.value === 'assetmap') {
              report.children = assetMapsList;
              return report;
            }
          }
          return report;
        })
      );
      setLoadedReports(true);
    }
  }, [currentHousehold, reportSettings, assetMapLayouts, targetMapsReady]);

  useEffect(() => {
    if (selectedLayout && loadedReports) {
      let reportData = selectedLayout?.data;
      if (reportData) {
        reportData = reportData.map((report) => {
          const matchingReport = reports.find((r) => r.value === report.value);
          if (matchingReport) {
            matchingReport.selected = report.is_checked;
            matchingReport.expanded = report.is_checked;
            matchingReport.order = report.priority;
          }

          return {
            ...report,
            ...matchingReport,
          };
        });
        reportData = reportData.sort((a, b) => a.priority - b.priority);
        setReportsOrder(reportData);
      }
    }
  }, [selectedLayout, loadedReports]);

  useEffect(() => {
    const selected = reportsOrder.filter((report) => {
      if (Array.isArray(report.children) && report.children.length !== 0) {
        report.childrenSelected = report.children.some(
          (child) => child.selected
        );
        if (report.selected && report.childrenSelected === false) {
          report.children[0].selected = true;
        }
      }
      return report.selected && report.available;
    });
    setCount(selected.length);
    const pageCount = selected.reduce((acc, current) => acc + current.pages, 0);
    const childPages = selected.reduce((acc, curr) => {
      if (curr.children) {
        const childrenCount = curr.children.reduce((count, child) => {
          if (child.selected) {
            return count + child.pages;
          }
          return count;
        }, 0);
        return acc + childrenCount;
      }
      return acc;
    }, 0);
    setPages(pageCount + childPages);
  }, [reportsOrder]);

  const expandItem = (val) => {
    setReportsOrder(
      reportsOrder.map((report) => {
        if (report.value === val) {
          if (report.hasOptions) {
            report.expanded = !report.expanded;
          }
        }
        return report;
      })
    );
  };

  const determineSelectAllValue = (report, cashFlow, types) => {
    let matchedReport = reportsOrder.find((rep) => rep.value === report);
    if (cashFlow) {
      let hasUnselectedCF = matchedReport.children.some((child) => {
        return !child.cashFlow;
      });
      hasUnselectedCF
        ? (matchedReport.cashFlowAllSelected = false)
        : (matchedReport.cashFlowAllSelected = true);
    } else if (types) {
      let hasUnselectedType = matchedReport.types.some((child) => {
        return !child.selected;
      });
      hasUnselectedType
        ? (matchedReport.allTypesSelected = false)
        : (matchedReport.allTypesSelected = true);
    } else {
      let hasUnselected = matchedReport.children.some((child) => {
        return !child.selected;
      });
      hasUnselected
        ? (matchedReport.allSelected = false)
        : (matchedReport.allSelected = true);
    }
  };

  const selectReport = (
    val,
    report = false,
    cashFlow = false,
    types = false
  ) => {
    // its a sub menu item being selected
    if (report) {
      setReportsOrder(
        reportsOrder.map((rep) => {
          if (rep.value === report) {
            if (types) {
              rep.types.map((type) => {
                if (type.value === val) {
                  type.selected = !type.selected;
                }
                return type;
              });
            } else {
              let childSelectedValue = null;
              rep.children.map((child) => {
                if (child.value === val) {
                  if (cashFlow) {
                    child.cashFlow = !child.cashFlow;
                    if (child.cashFlow && !child.selected) {
                      child.selected = !child.selected;
                    }
                  } else {
                    childSelectedValue = !child.selected;
                    child.selected = childSelectedValue;
                    if (!child.selected && child.cashFlow) {
                      child.cashFlow = !child.cashFlow;
                    }
                  }
                }
                return child;
              });
              rep.childrenSelected = rep.children.some(
                (child) => child.selected
              );
              if (rep.selected) {
                rep.selected = rep.childrenSelected;
              } else {
                rep.selected =
                  rep.childrenSelected && childSelectedValue !== false;
              }
            }
          }
          return rep;
        })
      );
      determineSelectAllValue(report, cashFlow, types);
    } else if (val === 'includeKffs') {
      setReportsOrder(
        reportsOrder.map((report) => {
          if (report.value === 'assetmap') {
            report.includeKffs = !report.includeKffs;
          }
          return report;
        })
      );
    } else {
      setReportsOrder(
        reportsOrder.map((report) => {
          if (report.value === val) {
            if (!report.selected) {
              report.expanded = true;
            } else {
              report.expanded = false;
            }
            report.selected = !report.selected;
          }
          return report;
        })
      );
    }
  };

  const selectAllChildren = (
    val,
    allSelected,
    cashFlow = false,
    types = false
  ) => {
    setReportsOrder(
      reportsOrder.map((report) => {
        if (report.value === val) {
          report.selected = !allSelected;
          if (cashFlow) {
            report.cashFlowAllSelected = !allSelected;
          } else if (types) {
            report.allTypesSelected = !allSelected;
          } else {
            report.allSelected = !allSelected;
          }

          if (types) {
            report.types.map((type) => {
              type.selected = !allSelected;
              return type;
            });
          } else {
            report.children.map((child) => {
              if (cashFlow) {
                child.cashFlow = !allSelected;
                if (child.cashFlow && !child.selected) {
                  child.selected = !child.selected;
                }
              } else {
                child.selected = !allSelected;
                if (!child.selected && child.cashFlow) {
                  child.cashFlow = !child.cashFlow;
                }
              }
              return child;
            });
            if (cashFlow && !allSelected) {
              report.allSelected = true;
            }
            if (allSelected && !cashFlow) {
              report.cashFlowAllSelected = false;
            }
          }
          report.childrenSelected = report.children.some(
            (child) => child.selected
          );
        }
        return report;
      })
    );
  };

  const checkShowMilestoneModal = () => {
    if (householdMilestones) {
      if (
        householdMilestones.hasOwnProperty('show_comprehensive_report_modal')
      ) {
        return householdMilestones.show_comprehensive_report_modal;
      }
    }
    return false;
  };

  const generateComprehensiveReport = () => {
    if (checkShowMilestoneModal()) {
      return dispatch(toggleShowMilestoneModal(true, false));
    }
    if (!loadedReports) {
      return;
    }
    const selectedReports = reportsOrder.filter((report) => {
      return report.selected && report.available;
    });
    let orderedReportsList = selectedReports.sort((a, b) => {
      return a.priority - b.priority;
    });
    orderedReportsList = orderedReportsList.reduce((acc, curr) => {
      const copied = {
        ...curr,
      };
      return [...acc, copied.value];
    }, []);

    if (selectedReports.length === 0) {
      return ErrorNotification('Must select at least one report');
    }
    return DownloadComprehensiveReport(
      selectedReports,
      orderedReportsList,
      amrEntityOwnershipPref?.value,
      amrOrientationPref?.value
    );
  };

  const resetLayout = () => {
    setCurrentLayout({
      name: '',
      is_default: false,
      data: [],
    });
  };

  const saveLayout = (saveAs = false) => {
    if (!showReportLayouts) {
      return;
    }
    setShowSaveMenu(false);
    if (saveAs) {
      setSaveLayoutError('');
      setShowNewLayoutModal(true);
    } else {
      if (selectedLayout.value === 'amr') {
        ErrorNotification('Cannot update. Create new layout first.', 5);
      } else {
        const reportData = reportsOrder.reduce((acc, curr) => {
          acc[curr.value] = {
            priority: curr.priority,
            is_checked: curr.selected,
          };
          return acc;
        }, {});
        const updatedLayout = {
          name: selectedLayout?.name,
          is_default: selectedLayout?.is_default,
          data: reportData,
          id: selectedLayout?.id,
        };
        dispatch(updateReportLayout(selectedLayout?.id, updatedLayout));
        resetLayout();
      }
    }
  };

  const createNewLayout = () => {
    if (currentLayout?.name === '') {
      setLayoutNameFieldError(true);
      return setSaveLayoutError('Please provide a name.');
    } else {
      const reportData = reportsOrder.reduce((acc, curr) => {
        if (curr.available) {
          acc[curr.value] = {
            priority: curr.priority,
            is_checked: curr.selected,
          };
        }
        return acc;
      }, {});
      const formattedLayout = {
        name: currentLayout?.name,
        is_default: currentLayout?.is_default,
        data: reportData,
        id: currentLayout?.id,
      };
      dispatch(createReportLayout(formattedLayout));
    }
  };

  const cancelNewLayoutModal = () => {
    setShowNewLayoutModal(false);
    resetLayout();
    setLayoutNameFieldError(false);
  };

  const updateLayoutName = (e) => {
    setCurrentLayout({
      ...currentLayout,
      name: e.currentTarget.value,
    });
    setLayoutNameFieldError(false);
  };

  const editLayoutModal = (option) => {
    setShowEditLayoutModal(true);
    setCurrentLayout(option.data);
  };

  const createLayoutModal = {
    heading: 'Save Current Layout As...',
    infoText:
      'A new Report Layout will be created from your selected reports and order.',
    inputs: [
      {
        type: 'text',
        maxLength: '45',
        name: 'name',
        required: true,
        placeholder: 'Layout Name',
        value: currentLayout.name,
        onChange: (e) => updateLayoutName(e),
        hasError: layoutNameFieldError,
        onKeyPress: (e) => DetectEnterKeyPress(e, createNewLayout),
      },
      {
        type: 'checkbox',
        label: 'Set as Default',
        value: currentLayout.is_default,
        onChange: () =>
          setCurrentLayout({
            ...currentLayout,
            is_default: !currentLayout.is_default,
          }),
      },
    ],
    buttons: [
      {
        text: 'Cancel',
        function: () => cancelNewLayoutModal(),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Save',
        function: () => createNewLayout(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  const displayDeleteModal = () => {
    setShowEditLayoutModal(false);
    setShowDeleteModal(true);
  };

  const saveEditedLayout = () => {
    if (currentLayout?.name === '') {
      setLayoutNameFieldError(true);
      return setEditLayoutError('Please provide a name.');
    } else {
      dispatch(updateReportLayout(currentLayout.id, currentLayout));
      setShowEditLayoutModal(false);
    }
  };

  const editLayoutModalContent = {
    heading: 'Edit Report Layout',
    infoText:
      'You can change the name of your layout, set it as default or delete.',
    inputs: [
      {
        type: 'text',
        name: 'name',
        maxLength: '45',
        required: true,
        placeholder: 'Name',
        value: currentLayout.name,
        onChange: (e) => updateLayoutName(e),
        hasError: layoutNameFieldError,
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedLayout),
      },
      {
        type: 'checkbox',
        label: 'Make Default Layout',
        value: currentLayout.is_default,
        onChange: () =>
          setCurrentLayout({
            ...currentLayout,
            is_default: !currentLayout.is_default,
          }),
      },
    ],
    buttons: [
      {
        text: 'Delete',
        function: () => displayDeleteModal(),
        theme: ButtonThemes.error,
      },
      {
        text: 'Update',
        function: () => saveEditedLayout(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
    setShowEditLayoutModal(true);
    setLayoutNameFieldError(false);
  };

  const deleteLayout = () => {
    dispatch(deleteReportLayout(currentLayout.id));
    setShowEditLayoutModal(false);
    setShowDeleteModal(false);
  };

  const deleteLayoutModalContent = {
    heading: 'Delete Report Layout?',
    text: `Are you sure you want to delete <strong>${currentLayout?.name}</strong>?`,
    buttons: [
      {
        text: 'No',
        function: () => hideDeleteModal(),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Yes',
        function: () => deleteLayout(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((res, index) => {
      res.priority = index + 1;
      return res;
    });
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const updatedList = reorder(
      reportsOrder,
      result.source.index,
      result.destination.index
    );
    setReportsOrder(updatedList);
  }

  const onSelectReportLayout = (e) => {
    setSelectedLayout(e);
    SendTrackingEvent(
      invocationId,
      'action',
      'report_layout_select',
      'household_reports',
      {
        household: currentHousehold.id,
        layout_id: e.value,
        layout_name: e.label,
      }
    );
  };

  const availableCount = (reports) => {
    let available = reports.filter((report) => report.available);
    return available.length;
  };

  const clickDisabledLayouts = () => {
    SendTrackingEvent(invocationId, 'click', 'report_layouts_disabled', null);
  };

  return (
    <>
      {showNewLayoutModal && (
        <SimpleModal
          hide={() => cancelNewLayoutModal()}
          content={createLayoutModal}
          updateError={setLayoutNameFieldError}
          onEnter={createNewLayout}
          error={saveLayoutError}
        />
      )}
      {showEditLayoutModal && (
        <SimpleModal
          hide={() => setShowEditLayoutModal(!showEditLayoutModal)}
          content={editLayoutModalContent}
          updateError={setLayoutNameFieldError}
          error={editLayoutError}
        />
      )}
      {showDeleteModal && (
        <SimpleModal
          hide={() => setShowDeleteModal(!showDeleteModal)}
          content={deleteLayoutModalContent}
        />
      )}
      {isStencil ? (
        <>
          <EmptyImage
            imageSrc={noReports}
            imageName="No Reports"
            text="Reports are not available for Stencil Households"
          />
        </>
      ) : (
        <PageContent>
          <ActionRow>
            <SaveReportContainer>
              <SelectContainer>
                <InputLabel>
                  <span>Report Layouts</span>
                </InputLabel>
                {showReportLayouts ? (
                  <Select
                    selectedOption={selectedLayout}
                    onChange={onSelectReportLayout}
                    options={reportLayoutsList}
                    isSearchable={true}
                    isDisabled={!showReportLayouts}
                    placeholder={'Saved Report Templates'}
                    autoFocus={false}
                    components={{
                      Option: (e) => LayoutOption(e, editLayoutModal),
                    }}
                  />
                ) : (
                  <SelectPlaceholder onClick={() => clickDisabledLayouts()}>
                    <SelectText> {selectedLayout?.label}</SelectText>
                    <ClickImage
                      src={chevronDown}
                      alt="caret"
                      data-image="arrow-down"
                    />
                  </SelectPlaceholder>
                )}
              </SelectContainer>
              {showReportLayouts && (
                <SaveContainer>
                  <ClickContainer
                    onClick={() => setShowSaveMenu(!showSaveMenu)}
                  >
                    <ClickText>Save</ClickText>
                    <ClickImage
                      src={chevronDown}
                      alt="caret"
                      data-image="arrow-down"
                    />
                  </ClickContainer>
                  <DropdownContainer
                    ref={ref}
                    right={'-6px'}
                    display={showSaveMenu ? 'block' : 'none'}
                  >
                    <DropdownHeading>Save Layout</DropdownHeading>
                    <DropdownContent>
                      <DropdownItem onClick={() => saveLayout(false)}>
                        Save
                      </DropdownItem>
                      <DropdownItem onClick={() => saveLayout(true)}>
                        Save as...
                      </DropdownItem>
                    </DropdownContent>
                  </DropdownContainer>
                </SaveContainer>
              )}
            </SaveReportContainer>
            {/* <AMROrientationContainer>
              <SelectContainer>
                <InputLabel>Asset-Map Orientation</InputLabel>
                <Select
                  selectedOption={amrOrientationPref}
                  onChange={setAmrOrientationPref}
                  options={amrOrientationPreferences}
                  isSearchable={true}
                  placeholder={'Print Orientation'}
                  autoFocus={false}
                />
              </SelectContainer>
            </AMROrientationContainer> */}
            <SummaryContainer>
              <SelectedReports>
                Reports Selected: {count} of {availableCount(reports)}
              </SelectedReports>
              <PageCount>Estimated Page Count: {pages}</PageCount>
            </SummaryContainer>
            <OptionsContainer>
              <Button
                text={'Generate Report'}
                onClick={() => generateComprehensiveReport()}
              />
            </OptionsContainer>
          </ActionRow>
          {loadedReports ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="report-list" type="REPORT">
                {(provided) => (
                  <ReportsListContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {reportsOrder.map((report, index) => {
                      return (
                        <Draggable
                          draggableId={report.value}
                          index={index}
                          key={report.value}
                          isDragDisabled={!showReportLayouts}
                        >
                          {(provided, snapshot) => (
                            <ReportDragContainer
                              ref={provided.innerRef}
                              $isAssetMap={report.value === 'assetmap'}
                              $isDragging={snapshot.isDragging}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ReportGenerateItem
                                last={index === reportsOrder.length - 1}
                                report={report}
                                selectReport={selectReport}
                                expandItem={expandItem}
                                downloadReport={DownloadReport}
                                selectAllChildren={selectAllChildren}
                                hasEntityRollup={hasEntityRollup}
                                amrEntityOwnership={amrEntityOwnershipPref}
                                setAmrEntityOwnership={
                                  setAmrEntityOwnershipPref
                                }
                                amrShowRollup={amrShowRollupPref}
                                setAmrShowRollup={setAmrShowRollupPref}
                                amrRollupExpand={amrRollupExpandPref}
                                setAmrRollupExpand={setAmrRollupExpandPref}
                                amrOrientation={amrOrientationPref}
                                setAmrOrientation={setAmrOrientationPref}
                              />
                            </ReportDragContainer>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ReportsListContainer>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <ReportsListContainer>
              {placeholderReportList.map((report, index) => {
                return (
                  <ReportGenerateItem
                    loading={true}
                    key={index}
                    report={report}
                    selectReport={selectReport}
                    expandItem={expandItem}
                    downloadReport={DownloadReport}
                    selectAllChildren={selectAllChildren}
                    last={index === placeholderReportList.length - 1}
                  />
                );
              })}
            </ReportsListContainer>
          )}
          {customLDD?.disclaimer && (
            <PageFooter footerValue={customLDD?.disclaimer} />
          )}
          {/* <SummaryContainer>
            <SelectedReports>
              Reports Selected: {count} of {reports.length}
            </SelectedReports>
            <PageCount>Estimated Page Count: {pages}</PageCount>
          </SummaryContainer>
          <ButtonRow>
            <Button text={'Generate Report'} />
          </ButtonRow> */}
        </PageContent>
      )}
    </>
  );
};

const SelectPlaceholder = styled.div`
  height: 32px;
  width: 203px;
  font-size: 13px;
  padding: 2px 10px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${inputColors.border};
  border-radius: 6px;
  background: ${colors.lightGrey};
  color: hsl(0, 0%, 60%);
  ${ClickImage} {
    width: 20px;
    height: 20px;
  }
`;

const SelectText = styled.span`
  flex: 1 1 auto;
`;

const ReportDragContainer = styled.div`
  width: 100%;
  position: relative;
  background: white;
  z-index: ${(props) => (props.$isAssetMap ? (props.$isDragging ? 2 : 4) : 2)};
  box-shadow: ${(props) =>
    props.$isDragging ? boxShadows.boxShadowSoft : null};
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -20px 20px 50px 20px;
  }
  margin: -20px 40px 30px 40px;
`;

const SummaryContainer = styled.div`
  @media ${maxDevice.sideNav} {
    display: block;
    font-size: 11px;
    padding: 0;
  }
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  font-size: 13px;
  color: ${colors.paleGrey};
  font-weight: ${fonts.semiBold};
  padding: 10px 0;
  border-top: 1px solid ${colors.lightGrey};
  /* border-bottom: 1px solid ${colors.lightGrey}; */
`;

// const AMROrientationContainer = styled.div`
//   margin: 0 20px;
// `;

const ActionRow = styled.div`
  @media ${maxDevice.sideNav} {
    button {
      font-size: 10px;
    }
  }
  display: flex;
  align-content: center;
  justify-content: flex-start;
  /* justify-content: flex-end; */
  /* align-items: center; */
  align-items: center;
  flex-wrap: wrap;
  padding: 14px 0;
  border-bottom: 1px solid ${colors.lightGrey};
  ${SummaryContainer} {
    @media ${maxDevice.mobileL} {
      margin-right: 10px;
    }
    border-top: 0;
    flex: 0 1 auto;
    align-content: center;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 12px;
    margin-right: 20px;
  }
`;

const OptionsContainer = styled.div`
  @media ${maxDevice.tablet} {
    flex: 1 1 auto;
  }
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
`;

const SaveReportContainer = styled.div`
  @media ${maxDevice.tablet} {
    margin-bottom: 10px;
    margin-right: 0;
    width: 100%;
  }
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  margin-right: 20px;
`;

const SelectContainer = styled.div`
  flex: 1 1 auto;
  text-align: left;
  max-width: 225px;
  min-width: 180px;
  margin-top: -8px;
`;

const SaveContainer = styled.div`
  flex: 0 0 auto;
  text-align: right;
  margin: 10px 0 10px 20px;
  font-size: 13px;
  position: relative;
`;

const ReportsListContainer = styled.div`
  @media ${maxDevice.mobileL} {
    align-content: flex-start;
    justify-content: space-evenly;
  }
  padding: 10px 0 10px 0;
  margin-bottom: 60px;
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;

// const GripLines = styled.img`
//   width: 14px;
//   height: 18px;
//   margin-right: 5px;
//   opacity: 0.3;
//   cursor: grab;
// `;

const SelectedReports = styled.div`
  @media ${maxDevice.sideNav} {
    padding-right: 0px;
    margin-bottom: 5px;
    text-align: left;
    font-size: 10px;
  }
  /* @media ${maxDevice.mobileL} {
    padding-right: 0px;
    margin-bottom: 5px;
  } */
  padding-right: 15px;
  min-width: 130px;
  flex: 0 0 auto;
  text-align: right;
`;

const PageCount = styled.div`
  @media ${maxDevice.sideNav} {
    border-left: none;
    padding-left: 0px;
    font-size: 10px;
  }
  /* @media ${maxDevice.mobileL} {
    padding-left: 0px;
    border-left: none;
    text-align: right;
  } */
  border-left: 1px solid ${colors.lightGrey};
  padding-left: 15px;
  min-width: 130px;
  flex: 0 0 auto;
`;

HouseholdReportsGenerate.propTypes = {
  householdId: PropTypes.string,
};

export default HouseholdReportsGenerate;
