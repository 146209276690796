import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, messageColors, fonts } from '../../styles/variables';
import { FormatCurrency, IsNegative } from '../../utils';

const CashFlowTable = ({ cashFlow, currency, openModal }) => {
  const [expandedWants, setExpandedWants] = useState(false);
  const [expandedIncomes, setExpandedIncomes] = useState(false);
  const [expandedAssets, setExpandedAssets] = useState(false);
  const [expandedInsurances, setExpandedInsurances] = useState(false);
  const [insurances, setInsurances] = useState([]);

  useEffect(() => {
    if (
      cashFlow &&
      cashFlow?.insurances_asset &&
      cashFlow?.insurances_cashflow
    ) {
      setInsurances([
        ...cashFlow.insurances_asset,
        ...cashFlow.insurances_cashflow,
      ]);
    }
  }, [cashFlow]);

  const subHeadingStyle = {
    textAlign: 'center',
    cursor: 'pointer',
  };

  const noExpandStyle = {
    textAlign: 'center',
    cursor: 'default',
    color: colors.paleGrey,
  };

  const expandedHeading = {
    color: colors.lightBlue,
    paddingLeft: '8px',
    cursor: 'pointer',
  };

  const generateMiddleWidth = () => {
    let width = 5;
    if (expandedIncomes) {
      width += cashFlow?.incomes?.length;
    }
    if (expandedAssets) {
      width += cashFlow?.assets?.length;
    }
    if (expandedInsurances) {
      width += insurances?.length;
    }
    return width;
  };

  const getHeadingHeight = () => {
    const chartContainer = document.getElementById('chart-container');
    return chartContainer?.offsetHeight
      ? `${chartContainer?.offsetHeight - 1}px`
      : '250px';
  };

  const determineNegativeStyle = (item) => {
    if (item !== null && item !== 0 && IsNegative(item)) {
      return { color: messageColors.error };
    }
    return {};
  };

  return (
    <ThemeProvider theme={{ top: getHeadingHeight() }}>
      <CashFlowTableContainer>
        <thead>
          <tr style={{ fontWeight: fonts.semiBold }}>
            <SpaceHolder colSpan="3">&nbsp;</SpaceHolder>
            <UnderlinedHeader
              colSpan={expandedWants ? cashFlow?.wants?.length : 1}
              style={{ textAlign: 'center' }}
            >
              Capital Required
            </UnderlinedHeader>
            <SpaceHolder colSpan="1">&nbsp;</SpaceHolder>
            <UnderlinedHeader
              colSpan={generateMiddleWidth()}
              style={{ textAlign: 'center' }}
            >
              Capital Available
            </UnderlinedHeader>
            <SpaceHolder colSpan="1">&nbsp;</SpaceHolder>
            <UnderlinedHeader colSpan="3" style={{ textAlign: 'center' }}>
              Annual Funding Implication
            </UnderlinedHeader>
          </tr>
          <tr style={{ color: colors.lightBlue }}>
            <SpaceHolder colSpan="3">&nbsp;</SpaceHolder>
            {cashFlow && cashFlow?.wants?.length >= 1 ? (
              <th
                colSpan={expandedWants ? cashFlow?.wants?.length : 1}
                style={subHeadingStyle}
                onClick={() => setExpandedWants(!expandedWants)}
              >
                {expandedWants ? 'After-tax Cash Flow Desired' : 'Expand'}
              </th>
            ) : (
              <th colSpan="1" style={noExpandStyle}>
                -
              </th>
            )}
            <SpaceHolder colSpan="1">&nbsp;</SpaceHolder>
            {cashFlow && cashFlow?.incomes?.length >= 1 ? (
              <th
                colSpan={expandedIncomes ? cashFlow.incomes.length : 1}
                style={subHeadingStyle}
                onClick={() => setExpandedIncomes(!expandedIncomes)}
              >
                {expandedIncomes ? 'After-Tax Cash Flow Sources' : 'Expand'}
              </th>
            ) : (
              <th colSpan="1" style={noExpandStyle}>
                -
              </th>
            )}
            <SpaceHolder colSpan="1">&nbsp;</SpaceHolder>
            {cashFlow && cashFlow?.assets?.length >= 1 ? (
              <th
                colSpan={expandedAssets ? cashFlow.assets.length : 1}
                style={subHeadingStyle}
                onClick={() => setExpandedAssets(!expandedAssets)}
              >
                {expandedAssets ? 'Net After Tax Assets' : 'Expand'}
              </th>
            ) : (
              <th colSpan="1" style={noExpandStyle}>
                -
              </th>
            )}
            <SpaceHolder colSpan="1">&nbsp;</SpaceHolder>
            {cashFlow && insurances.length >= 1 ? (
              <th
                colSpan={expandedInsurances ? insurances.length : 1}
                style={subHeadingStyle}
                onClick={() => setExpandedInsurances(!expandedInsurances)}
              >
                {expandedInsurances ? 'Insurance Proceeds' : 'Expand'}
              </th>
            ) : (
              <th colSpan="1" style={noExpandStyle}>
                -
              </th>
            )}
            <SpaceHolder colSpan="1">&nbsp;</SpaceHolder>
          </tr>
          <tr>
            <UnderlinedSubHeader colSpan="1" style={{ textAlign: 'center' }}>
              Year
            </UnderlinedSubHeader>
            <UnderlinedSubHeader colSpan="1" style={{ textAlign: 'center' }}>
              Attained Age(s)
            </UnderlinedSubHeader>
            <SpaceHolder>&nbsp;</SpaceHolder>
            {!expandedWants && (
              <UnderlinedSubHeader>
                After-tax Cash Flow Desired
              </UnderlinedSubHeader>
            )}
            {expandedWants &&
              cashFlow.wants.map((want, index) => {
                return (
                  <UnderlinedSubHeader
                    key={index}
                    style={expandedHeading}
                    onClick={() => {
                      openModal('wants', want.hash);
                    }}
                  >
                    {want.title}
                  </UnderlinedSubHeader>
                );
              })}
            <SpaceHolder>&nbsp;</SpaceHolder>
            {!expandedIncomes && (
              <UnderlinedSubHeader>
                After-tax Cash Flow Sources
              </UnderlinedSubHeader>
            )}
            {expandedIncomes &&
              cashFlow.incomes.map((income, index) => {
                return (
                  <UnderlinedSubHeader
                    key={index}
                    style={expandedHeading}
                    onClick={() => {
                      openModal('income', income.hash);
                    }}
                  >
                    {income.title}
                  </UnderlinedSubHeader>
                );
              })}
            <SpaceHolder>&nbsp;</SpaceHolder>
            {!expandedAssets && (
              <UnderlinedSubHeader>Net After Tax Assets </UnderlinedSubHeader>
            )}
            {expandedAssets &&
              cashFlow.assets.map((asset, index) => {
                return (
                  <UnderlinedSubHeader
                    key={index}
                    style={expandedHeading}
                    onClick={() => {
                      openModal('asset', asset.hash);
                    }}
                  >
                    {asset.title}
                  </UnderlinedSubHeader>
                );
              })}
            <SpaceHolder>&nbsp;</SpaceHolder>
            {!expandedInsurances && (
              <UnderlinedSubHeader>Insurance Proceeds</UnderlinedSubHeader>
            )}
            {expandedInsurances &&
              insurances.map((insurance, index) => {
                return (
                  <UnderlinedSubHeader
                    key={index}
                    style={expandedHeading}
                    onClick={() => {
                      openModal('insurance', insurance.hash);
                    }}
                  >
                    {insurance.title}
                  </UnderlinedSubHeader>
                );
              })}
            <SpaceHolder>&nbsp;</SpaceHolder>
            <UnderlinedSubHeader>
              Net Savings/ (Withdrawals)
            </UnderlinedSubHeader>
            <SpaceHolder>&nbsp;</SpaceHolder>
            <UnderlinedSubHeader>EOY Net Capital Balance</UnderlinedSubHeader>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'center' }}>
          {cashFlow && cashFlow?.time?.year?.length !== 0
            ? cashFlow?.time?.year.map((year, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan="1" style={{ textAlign: 'center' }}>
                        {year}
                      </td>
                      <td colSpan="1" style={{ textAlign: 'center' }}>
                        {cashFlow.time.age[index]}
                      </td>
                      <td>&nbsp;</td>
                      {!expandedWants ? (
                        <td
                          colSpan="1"
                          style={{
                            color:
                              cashFlow.totals.wants[index] !== 0
                                ? messageColors.error
                                : null,
                          }}
                        >
                          {cashFlow.totals.wants[index] !== 0
                            ? `(${FormatCurrency(
                                cashFlow.totals.wants[index],
                                currency
                              )})`
                            : FormatCurrency(0, currency)}
                        </td>
                      ) : (
                        cashFlow.wants.map((want, i) => {
                          return (
                            <td
                              key={i}
                              style={{
                                color:
                                  want.cf[index] !== 0 &&
                                  want.cf[index] !== null
                                    ? messageColors.error
                                    : null,
                              }}
                            >
                              {want.cf[index] === null || want.cf[index] === 0
                                ? FormatCurrency(0, currency)
                                : `(${FormatCurrency(
                                    want.cf[index],
                                    currency
                                  )})`}
                            </td>
                          );
                        })
                      )}
                      <td>&nbsp;</td>
                      {!expandedIncomes ? (
                        <td
                          colSpan="1"
                          style={determineNegativeStyle(
                            cashFlow.totals.incomes[index]
                          )}
                        >
                          {FormatCurrency(
                            cashFlow.totals.incomes[index],
                            currency
                          )}
                        </td>
                      ) : (
                        cashFlow.incomes.map((income, i) => {
                          return (
                            <td
                              key={i}
                              style={determineNegativeStyle(income.cf[index])}
                            >
                              {FormatCurrency(
                                income.cf[index] === null
                                  ? 0
                                  : income.cf[index],
                                currency
                              )}
                            </td>
                          );
                        })
                      )}
                      <td>&nbsp;</td>
                      {!expandedAssets ? (
                        <td
                          colSpan="1"
                          style={determineNegativeStyle(
                            cashFlow.totals.assets[index]
                          )}
                        >
                          {FormatCurrency(
                            cashFlow.totals.assets[index],
                            currency
                          )}
                        </td>
                      ) : (
                        cashFlow.assets.map((asset, i) => {
                          return (
                            <td
                              key={i}
                              style={determineNegativeStyle(asset.cf[index])}
                            >
                              {FormatCurrency(
                                asset.cf[index] === null ? 0 : asset.cf[index],
                                currency
                              )}
                            </td>
                          );
                        })
                      )}
                      <td>&nbsp;</td>
                      {!expandedInsurances ? (
                        <td
                          colSpan="1"
                          style={determineNegativeStyle(
                            cashFlow.totals.insurances[index]
                          )}
                        >
                          {FormatCurrency(
                            cashFlow.totals.insurances[index],
                            currency
                          )}
                        </td>
                      ) : (
                        insurances.map((insurance, i) => {
                          return (
                            <td
                              key={i}
                              style={determineNegativeStyle(insurance)}
                            >
                              {FormatCurrency(
                                insurance.cf[index] === null
                                  ? 0
                                  : insurance.cf[index],
                                currency
                              )}
                            </td>
                          );
                        })
                      )}
                      <td>&nbsp;</td>
                      <td
                        colSpan="1"
                        style={{
                          color: IsNegative(cashFlow.totals.net[index])
                            ? messageColors.error
                            : null,
                          fontWeight: fonts.semiBold,
                        }}
                      >
                        {FormatCurrency(cashFlow.totals.net[index], currency)}
                      </td>
                      <td>&nbsp;</td>
                      <td
                        colSpan="1"
                        style={{
                          color: IsNegative(cashFlow.totals.pool[index])
                            ? messageColors.error
                            : null,
                          fontWeight: fonts.semiBold,
                        }}
                      >
                        {FormatCurrency(
                          Math.round(cashFlow.totals.pool[index]),
                          currency
                        )}
                      </td>
                    </tr>
                    {(index + 1) % 5 === 0 && (
                      <EmptyRow>
                        <td style={{ textAlign: 'center' }}>-</td>
                      </EmptyRow>
                    )}
                  </React.Fragment>
                );
              })
            : null}
        </tbody>
      </CashFlowTableContainer>
    </ThemeProvider>
  );
};

const CashFlowTableContainer = styled.table`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  font-size: 13px;
  position: relative;
  top: 0;
  thead {
    tr {
      th {
        vertical-align: bottom;
        max-width: 60px;
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background: ${colors.lightGrey};
      }
      td {
        padding: 3px 0;
        text-align: right;
      }
    }
  }
`;

const SpaceHolder = styled.th`
  min-width: 20px;
  position: sticky;
  background: white;
  top: ${(props) => props.theme.top};
`;

const EmptyRow = styled.tr`
  color: ${colors.paleGrey};
`;

const UnderlinedHeader = styled.th`
  border-bottom: 1px solid ${colors.darkGrey};
  padding-bottom: 3px;
  position: sticky;
  background: white;
  top: ${(props) => props.theme.top};
  z-index: 2;
`;

const UnderlinedSubHeader = styled.th`
  padding-top: 20px;
  padding-bottom: 3px;
  border-bottom: 1px solid ${colors.darkGrey};
  position: sticky;
  background: white;
  top: ${(props) => props.theme.top};
`;

CashFlowTable.propTypes = {
  cashFlow: PropTypes.object,
  currency: PropTypes.object,
  openModal: PropTypes.func,
};

export default CashFlowTable;
