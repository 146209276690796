/* eslint-disable array-callback-return */
import { MergeArrays, SortInstrumentPriority } from '../transformers';
import {
  CalculateMultipleCustodianValues,
  CalculateMultipleOwnerValues,
  CalculateValues,
} from './calculate';
import {
  FilterMemberInstruments,
  FilterSplitMemberInstruments,
  GetEntityInstruments,
  GetMemberEntities,
  HasNoOwners,
  FilterJointInstruments,
  FilterOutJointInstruments,
  FilterOutJointInsuranceInstruments,
  FindCustodianAssets,
  FindCustodianAssetsMultiple,
  FindIntersect,
  HasAssociatedAsset,
  HasNoOwnersCategory,
  RemoveDuplicates,
} from './filters';
import { GenerateRollupItems } from './rollup';

export const GenerateNorthWestItems = (
  membersList,
  entityList,
  incomes,
  selectedMembers,
  amrShowEntity,
  showEntityRollup,
  incomeCategories
) => {
  if (selectedMembers.length === 2) {
    const filteredInstruments = FilterSplitMemberInstruments(
      incomes,
      selectedMembers[0].id
    );
    let memberIncomes = filteredInstruments.map((income) => {
      income.owned_by_entity = false;
      return income;
    });
    memberIncomes = CalculateValues(memberIncomes, selectedMembers[0].id);

    let entityIncomes = [];
    let rollupIncomes = [];
    if (amrShowEntity) {
      const firstMemEntities = GetMemberEntities(
        entityList,
        selectedMembers[0].id
      );
      const secondMemEntities = GetMemberEntities(
        entityList,
        selectedMembers[1].id
      );
      const firstMemEntityIncomes = GetEntityInstruments(
        incomes,
        firstMemEntities
      );
      const secondMemEntityIncomes = GetEntityInstruments(
        incomes,
        secondMemEntities
      );

      if (showEntityRollup) {
        entityIncomes = firstMemEntityIncomes;
        //1. Find all rollup entities
        const rollupEntities = firstMemEntities.filter(
          (entity) => entity.entity_rollup
        );
        //2. Group instruments by entity then category
        rollupIncomes = GenerateRollupItems(
          [selectedMembers[0].id],
          rollupEntities,
          entityIncomes,
          incomeCategories,
          'income',
          'northWest'
        );
        //3. Find all rollup entities' incomes
        let rollupInstruments = GetEntityInstruments(
          entityIncomes,
          rollupEntities
        );
        //4. Filter out rollup incomes from regular display
        entityIncomes = entityIncomes.filter((income) => {
          if (!rollupInstruments.some((i) => i.id === income.id)) {
            return income;
          }
        });
      } else {
        entityIncomes = firstMemEntityIncomes.filter((income) => {
          if (!secondMemEntityIncomes.some((cf) => cf.id === income.id)) {
            return income;
          }
        });
      }
    }
    // return SortInstrumentPriority([...memberIncomes, ...entityIncomes]);
    return SortInstrumentPriority([
      ...memberIncomes,
      ...entityIncomes,
      ...rollupIncomes,
    ]);
  } else if (
    selectedMembers.length === membersList.length &&
    membersList.length !== 1
  ) {
    const calculatedIncomes = CalculateMultipleOwnerValues(
      selectedMembers,
      incomes
    );
    const finalIncomes = calculatedIncomes.map((income) => {
      income.owned_by_entity = false;
      return income;
    });
    return finalIncomes;
  } else if (selectedMembers.length === 1) {
    const filteredInstruments = FilterMemberInstruments(
      selectedMembers,
      incomes
    );
    let memberIncomes = filteredInstruments.map((income) => {
      income.owned_by_entity = false;
      return income;
    });
    memberIncomes = CalculateValues(memberIncomes, selectedMembers[0].id);
    let entityIncomes = [];
    let rollupIncomes = [];
    if (amrShowEntity) {
      const entities = GetMemberEntities(entityList, selectedMembers[0].id);
      entityIncomes = GetEntityInstruments(incomes, entities);
      if (showEntityRollup) {
        //1. Find all rollup entities
        const rollupEntities = entities.filter(
          (entity) => entity.entity_rollup
        );
        //2. Group instruments by entity then category
        rollupIncomes = GenerateRollupItems(
          [selectedMembers[0].id],
          rollupEntities,
          entityIncomes,
          incomeCategories,
          'income',
          'northWest'
        );
        //3. Find all rollup entities' incomes
        let rollupInstruments = GetEntityInstruments(
          entityIncomes,
          rollupEntities
        );
        //4. Filter out rollup incomes from regular display
        entityIncomes = entityIncomes.filter((income) => {
          if (!rollupInstruments.some((i) => i.id === income.id)) {
            return income;
          }
        });
      }
    }
    return SortInstrumentPriority([
      ...memberIncomes,
      ...entityIncomes,
      ...rollupIncomes,
    ]);
  } else if (selectedMembers.length === 0) {
    return HasNoOwners(incomes);
  } else {
    const memberEntities = GetMemberEntities(
      entityList,
      false,
      selectedMembers
    );
    const selectedEntities = selectedMembers.filter(
      (mem) => mem.member_type === 'entity'
    );
    const finalEntities = memberEntities.filter((entity) => {
      return !selectedEntities.includes(entity);
    });
    const memberIncomes = FilterMemberInstruments(selectedMembers, incomes);
    let updatedIncomes = memberIncomes.map((income) => {
      income.owned_by_entity = false;
      return income;
    });
    updatedIncomes = CalculateMultipleOwnerValues(
      selectedMembers,
      memberIncomes
    );
    let rollupIncomes = [];
    let entityIncomes = [];
    if (amrShowEntity) {
      entityIncomes = GetEntityInstruments([...incomes], finalEntities);
      if (showEntityRollup) {
        //1. Find all rollup entities
        const rollupEntities = finalEntities.filter(
          (entity) => entity.entity_rollup
        );
        const selectedMemIds = selectedMembers.map((mem) => mem.id);
        //2. Group instruments by entity then category
        rollupIncomes = GenerateRollupItems(
          selectedMemIds,
          rollupEntities,
          entityIncomes,
          incomeCategories,
          'income',
          'northWest'
        );
        //3. Find all rollup entities' incomes
        let rollupInstruments = GetEntityInstruments(
          entityIncomes,
          rollupEntities
        );
        //4. Filter out rollup incomes from regular display
        entityIncomes = entityIncomes.filter((income) => {
          if (!rollupInstruments.some((i) => i.id === income.id)) {
            return income;
          }
        });
      }
    }
    return SortInstrumentPriority([
      ...updatedIncomes,
      ...entityIncomes,
      ...rollupIncomes,
    ]);
  }
};

export const GenerateSouthWestItems = (
  membersList,
  entityList,
  assets,
  liabilities,
  insurances,
  selectedMembers,
  amrShowEntity,
  amrShowCustodian,
  showEntityRollup,
  allInstrumentCategories
) => {
  if (selectedMembers.length === 2) {
    let matchingAssets = [];
    matchingAssets = FilterSplitMemberInstruments(
      assets,
      selectedMembers[0].id
    );
    const jointAssets = FilterJointInstruments(
      matchingAssets,
      selectedMembers[1].id
    );
    const jointAssetIds = jointAssets.map((a) => a.id);
    matchingAssets = FilterOutJointInstruments(
      matchingAssets,
      selectedMembers[1].id
    );

    const memEntities = GetMemberEntities(entityList, selectedMembers[0].id);

    let custodianAssets = [];
    let custodianAssetsIds = [];
    if (amrShowCustodian) {
      custodianAssets = FindCustodianAssets([...assets], selectedMembers[0].id);
      custodianAssetsIds = custodianAssets.map((asset) => asset.id);
    }
    const assetIds = matchingAssets.map((asset) => asset.id);

    let matchingInsurances = [];
    matchingInsurances = FilterSplitMemberInstruments(
      insurances,
      selectedMembers[0].id
    );
    matchingInsurances = matchingInsurances.filter((insurance) => {
      if (
        insurance.cash_value !== null &&
        insurance.cash_value !== '' &&
        insurance.cash_value > 0
      ) {
        insurance.owned_by_entity = false;
        insurance.owned_by_another = false;
        return insurance;
      }
    });
    matchingInsurances = FilterOutJointInsuranceInstruments(
      matchingInsurances,
      selectedMembers[1].id,
      'owner',
      showEntityRollup
    );
    matchingInsurances = CalculateValues(
      matchingInsurances,
      selectedMembers[0].id
    );
    const insuranceIds = matchingInsurances.map((ins) => ins.id);

    let matchingLiabilities = [];
    matchingLiabilities = FilterSplitMemberInstruments(
      liabilities,
      selectedMembers[0].id
    );
    matchingLiabilities = FilterOutJointInstruments(
      matchingLiabilities,
      selectedMembers[1].id
    );

    matchingLiabilities = CalculateValues(
      matchingLiabilities,
      selectedMembers[0].id
    );
    matchingLiabilities = matchingLiabilities.map((liability) => {
      liability.owned_by_another = false;
      liability.owned_by_entity = false;
      return liability;
    });

    const mergedAssetIds = MergeArrays(
      assetIds,
      custodianAssetsIds,
      insuranceIds
    );

    let entityAssets = [];
    let entityAssetIds = [];
    let entityInsurances = [];
    //LINKED LIABILITY CHANGES
    let entityInsuranceIds = [];
    //LINKED LIABILITY CHANGES
    let entityLiabilities = [];
    let nonJointEntityLiabilities = [];
    if (amrShowEntity) {
      entityAssets = GetEntityInstruments([...assets], memEntities);
      entityAssetIds = entityAssets.map((a) => a.id);

      const firstMemEntityInsurances = GetEntityInstruments(
        insurances,
        memEntities
      );
      const firstMemEntityAssets = GetEntityInstruments(assets, memEntities);
      const firstMemEntityLiabilities = GetEntityInstruments(
        liabilities,
        memEntities
      );

      const secondMemEntities = GetMemberEntities(
        entityList,
        selectedMembers[1].id
      );
      const secondMemEntityInsurances = GetEntityInstruments(
        insurances,
        secondMemEntities
      );
      const secondMemEntityAssets = GetEntityInstruments(
        assets,
        secondMemEntities
      );
      const secondMemEntityLiabilities = GetEntityInstruments(
        liabilities,
        secondMemEntities
      );

      entityInsurances = firstMemEntityInsurances.filter((insurance) => {
        if (!secondMemEntityInsurances.some((cf) => cf.id === insurance.id)) {
          return insurance;
        }
      });

      entityInsurances = entityInsurances.filter((insurance) => {
        if (
          insurance.cash_value !== null &&
          insurance.cash_value !== '' &&
          insurance.cash_value > 0
        ) {
          insurance.owned_by_another = false;
          return insurance;
        }
      });
      //LINKED LIABILITY CHANGES
      entityInsuranceIds = entityInsurances.map((ins) => ins.id);
      nonJointEntityLiabilities = firstMemEntityLiabilities.filter(
        (liability) => {
          if (
            !secondMemEntityLiabilities.some((item) => item.id === liability.id)
          ) {
            return liability;
          }
        }
      );
      //LINKED LIABILITY CHANGES
      if (!showEntityRollup) {
        const jointEntityAssets = FindIntersect(
          firstMemEntityAssets,
          secondMemEntityAssets
        );
        entityAssets = entityAssets.filter((asset) => {
          if (!jointEntityAssets.some((item) => item.id === asset.id)) {
            return asset;
          }
        });
        entityAssets = FilterOutJointInstruments(
          entityAssets,
          selectedMembers[1].id
        );
        entityLiabilities = nonJointEntityLiabilities;
      } else {
        entityLiabilities = firstMemEntityLiabilities;
      }

      //Need to filter out liabilities that already show on south!! - this is if they are not owned by entity
    }

    entityLiabilities = entityLiabilities.map((liability) => {
      let updated = { ...liability };
      updated.owned_by_another = false;
      updated.owned_by_entity = true;
      updated.calculated = liability.amount;
      return updated;
    });

    let mergedLiabilities = MergeArrays(
      matchingLiabilities,
      nonJointEntityLiabilities
    );

    mergedLiabilities = RemoveDuplicates(mergedLiabilities);

    const filteredLiabilities = HasAssociatedAsset(
      mergedLiabilities,
      mergedAssetIds
    );

    let entityRollupLiabilities = [];
    let rollupInstrumentsLia = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      entityRollupLiabilities = GenerateRollupItems(
        [selectedMembers[0].id],
        rollupEntities,
        entityLiabilities,
        allInstrumentCategories,
        'liability',
        'southWest'
      );
      rollupInstrumentsLia = GetEntityInstruments(
        entityLiabilities,
        rollupEntities
      );
      entityLiabilities = entityLiabilities.filter((lia) => {
        if (!rollupInstrumentsLia.some((i) => i.id === lia.id)) {
          return lia;
        }
      });
    }

    const nonDuplicateLiabilities = filteredLiabilities.filter((lia) => {
      if (!rollupInstrumentsLia.some((l) => l.id === lia.id)) {
        return lia;
      }
    });

    //LINKED LIABILITY CHANGES
    matchingInsurances = matchingInsurances.map((insurance) => {
      const updated = { ...insurance };
      updated.owned_by_another = false;
      updated.owned_by_entity = false;
      const linked = nonDuplicateLiabilities.filter(
        (item) => item.associated === insurance.id
      );
      updated.linked_items = linked.map((item) => {
        return { ...item };
      });
      return updated;
    });
    //LINKED LIABILITY CHANGES

    entityInsurances = entityInsurances.map((insurance) => {
      let updated = { ...insurance };
      updated.owned_by_another = false;
      updated.owned_by_entity = true;
      updated.calculated = insurance.cash_value;
      updated.show_cash_value = true;
      //LINKED LIABILITY CHANGES
      const linked = mergedLiabilities.filter(
        (liability) => liability.associated === insurance.id
      );
      updated.linked_items = linked.map((item) => {
        return { ...item };
      });
      //LINKED LIABILITY CHANGES
      return updated;
    });
    let entityRollupInsurances = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      entityRollupInsurances = GenerateRollupItems(
        [selectedMembers[0].id],
        rollupEntities,
        entityInsurances,
        allInstrumentCategories,
        'insurance',
        'southWest'
      );
      let rollupInstruments = GetEntityInstruments(
        entityInsurances,
        rollupEntities
      );
      entityInsurances = entityInsurances.filter((ins) => {
        if (!rollupInstruments.some((i) => i.id === ins.id)) {
          return ins;
        }
      });
    }

    const allAssetIds = MergeArrays(
      mergedAssetIds,
      entityAssetIds,
      jointAssetIds,
      //LINKED LIABILITY CHANGES
      insuranceIds,
      entityInsuranceIds
      //LINKED LIABILITY CHANGES
    );

    custodianAssets = custodianAssets.map((asset) => {
      let updated = { ...asset };
      let matching = matchingLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      let propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_another = false;
        } else {
          copied.owned_by_another = true;
        }
        return copied;
      });
      updated.owned_by_another = true;
      updated.linked_items = matching;
      return updated;
    });

    entityAssets = entityAssets.map((asset) => {
      let updated = { ...asset };
      let matching = liabilities.filter(
        (liability) => liability.associated === asset.id
      );
      let propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      updated.linked_items = CalculateValues(matching, selectedMembers[0].id);
      updated.owned_by_another = false;
      updated.owned_by_entity = true;
      return updated;
    });
    let entityRollupAssets = [];
    if (showEntityRollup) {
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      entityRollupAssets = GenerateRollupItems(
        [selectedMembers[0].id],
        rollupEntities,
        entityAssets,
        allInstrumentCategories,
        'asset',
        'southWest'
      );
      let rollupInstruments = GetEntityInstruments(
        entityAssets,
        rollupEntities
      );
      entityAssets = entityAssets.filter((asset) => {
        if (!rollupInstruments.some((i) => i.id === asset.id)) {
          return asset;
        }
      });
    }

    custodianAssets = custodianAssets.filter((asset) => {
      if (!entityAssetIds.includes(asset.id)) {
        return asset;
      }
    });

    matchingAssets = matchingAssets.map((asset) => {
      let updated = { ...asset };
      let matching = nonDuplicateLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      let propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      if (!amrShowEntity) {
        matching = matching.filter((item) => !item.owned_by_entity);
      }
      updated.linked_items = matching;
      updated.owned_by_another = false;
      updated.owned_by_entity = false;
      return updated;
    });
    matchingAssets = MergeArrays(matchingAssets, custodianAssets);
    matchingAssets = CalculateValues(matchingAssets, selectedMembers[0].id);

    const finalLiabilities = matchingLiabilities.filter(
      (liability) => !allAssetIds.includes(liability.associated)
    );
    if (!showEntityRollup) {
      entityLiabilities = entityLiabilities.filter(
        (liability) => !allAssetIds.includes(liability.associated)
      );
    }

    const groupedAssets = SortInstrumentPriority([
      ...matchingAssets,
      ...entityAssets,
      ...entityRollupAssets,
    ]);
    const groupedInsurances = SortInstrumentPriority([
      ...matchingInsurances,
      ...entityInsurances,
      ...entityRollupInsurances,
    ]);
    const groupLiabilities = SortInstrumentPriority([
      ...finalLiabilities,
      ...entityLiabilities,
      ...entityRollupLiabilities,
    ]);
    return [...groupedAssets, ...groupedInsurances, ...groupLiabilities];
  } else if (selectedMembers.length === 0) {
    let matchedRetirementAssets = HasNoOwnersCategory(assets, 'retirement');
    const matchedLiabilities = HasNoOwners(liabilities);
    matchedRetirementAssets = matchedRetirementAssets.map((asset) => {
      const matching = matchedLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      asset.linked_items = [...matching, ...propCas].map((item) => {
        const copied = { ...item };
        copied.owned_by_another = false;
        copied.owned_by_entity = false;
        return copied;
      });
      asset.linked_items = matching;
      asset.owned_by_another = false;
      return asset;
    });
    return matchedRetirementAssets;
  } else if (
    selectedMembers.length === membersList.length &&
    membersList.length !== 1
  ) {
    let matchingRetirementAssets = assets.filter((asset) => {
      if (asset.category === 'retirement') {
        asset.owned_by_another = false;
        asset.owned_by_entity = false;
        return asset;
      }
    });
    const assetIds = matchingRetirementAssets.map((asset) => asset.id);

    const matchingLiabilities = HasAssociatedAsset(liabilities, assetIds);
    matchingRetirementAssets = matchingRetirementAssets.map((asset) => {
      let matching = matchingLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((l) => {
        l.owned_by_another = false;
        l.owned_by_entity = false;
        return l;
      });
      const calculated = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      asset.linked_items = calculated;
      asset.owned_by_another = false;
      asset.owned_by_entity = false;
      return asset;
    });
    const finalAssets = CalculateMultipleOwnerValues(
      selectedMembers,
      matchingRetirementAssets
    );
    return SortInstrumentPriority([...finalAssets]);
  } else if (selectedMembers.length === 1) {
    // let filteredLiabilities = [];
    const retirementAssets = assets.filter((asset) => {
      return asset.category === 'retirement';
    });
    const memEntities = GetMemberEntities(entityList, selectedMembers[0].id);

    let custodianAssets = [];
    let custodianAssetsIds = [];
    if (amrShowCustodian) {
      custodianAssets = FindCustodianAssets(
        retirementAssets,
        selectedMembers[0].id
      );
      custodianAssetsIds = custodianAssets.map((asset) => asset.id);
    }
    let entityAssets = [];
    if (amrShowEntity) {
      entityAssets = GetEntityInstruments(retirementAssets, memEntities);
    }
    let matchedAssets = FilterMemberInstruments(
      selectedMembers,
      retirementAssets
    );
    const matchedAssetIds = matchedAssets.map((asset) => asset.id);
    const mergedAssetIds = MergeArrays(matchedAssetIds, custodianAssetsIds);
    const matchingLiabilities = FilterMemberInstruments(
      selectedMembers,
      liabilities
    );
    const entityLiabilities = GetEntityInstruments(liabilities, memEntities);
    const mergedLiabilities = MergeArrays(
      matchingLiabilities,
      entityLiabilities
    );
    //Need to merge these two arrays an only keep the unique values?
    // Then I can just use this list for memberAssets

    const filteredLiabilities = HasAssociatedAsset(
      mergedLiabilities,
      mergedAssetIds
    );
    // filteredLiabilities = CalculateValues(
    //   filteredLiabilities,
    //   selectedMembers[0].id
    // );
    // const finalLiabilities = filteredLiabilities.filter(
    //   (liability) => !mergedAssetIds.includes(liability.associated)
    // );

    custodianAssets = custodianAssets.map((asset) => {
      let matching = matchingLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      asset.linked_items = CalculateValues(matching, selectedMembers[0].id);
      asset.owned_by_entity = false;
      asset.owned_by_another = true;
      return asset;
    });

    entityAssets = entityAssets.map((asset) => {
      const copiedAsset = { ...asset };
      let matching = liabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      copiedAsset.linked_items = CalculateValues(
        matching,
        selectedMembers[0].id
      );
      copiedAsset.owned_by_another = false;
      copiedAsset.owned_by_entity = true;
      return copiedAsset;
    });
    const entityAssetsIds = entityAssets.map((asset) => asset.id);

    let entityRollupAssets = [];
    if (showEntityRollup) {
      //1. Get all rollup entities
      const rollupEntities = memEntities.filter(
        (entity) => entity.entity_rollup
      );
      //2. Get all rollup instruments
      entityRollupAssets = GenerateRollupItems(
        [selectedMembers[0].id],
        rollupEntities,
        entityAssets,
        allInstrumentCategories,
        'asset',
        'southWest'
      );
      //3. Find all rollup entities' assets
      let rollupInstruments = GetEntityInstruments(
        entityAssets,
        rollupEntities
      );
      //4. Filter out rollup assets from regular display
      entityAssets = entityAssets.filter((asset) => {
        if (!rollupInstruments.some((i) => i.id === asset.id)) {
          return asset;
        }
      });
    }

    custodianAssets = custodianAssets.filter((asset) => {
      if (!entityAssetsIds.includes(asset.id)) {
        return asset;
      }
    });

    matchedAssets = matchedAssets.map((asset) => {
      // Liabilities list should includes liabilities member is an owner and liabilities entity they belong to is owner
      let matching = filteredLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.includes(selectedMembers[0].id)) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      if (!amrShowEntity) {
        matching = matching.filter((lia) => !lia.owned_by_entity);
      }
      const calculated = CalculateValues(matching, selectedMembers[0].id);
      asset.linked_items = calculated;
      asset.owned_by_another = false;
      asset.owned_by_entity = false;
      return asset;
    });
    // matchedAssets = MergeArrays(matchedAssets, custodianAssets);
    matchedAssets = CalculateValues(matchedAssets, selectedMembers[0].id);

    // return [...matchedAssets, ...finalLiabilities];
    return SortInstrumentPriority([
      ...matchedAssets,
      ...custodianAssets,
      ...entityAssets,
      ...entityRollupAssets,
    ]);
  } else {
    const retirementAssets = assets.filter((asset) => {
      return asset.category === 'retirement';
    });
    const memberEntities = GetMemberEntities(entityList, null, selectedMembers);
    const selectedEntities = selectedMembers.filter(
      (mem) => mem.member_type === 'entity'
    );
    const selectedMemIds = selectedMembers.map((mem) => mem.id);
    const finalEntities = memberEntities.filter((entity) => {
      return !selectedEntities.includes(entity);
    });

    let matchedAssets = FilterMemberInstruments(
      selectedMembers,
      retirementAssets
    );

    let custodianAssets = [];
    let custodianAssetsIds = [];
    if (amrShowCustodian) {
      custodianAssets = FindCustodianAssetsMultiple(
        retirementAssets,
        selectedMembers
      );
      custodianAssetsIds = custodianAssets.map((asset) => asset.id);
    }
    let entityAssets = [];
    if (amrShowEntity) {
      entityAssets = GetEntityInstruments(retirementAssets, finalEntities);
    }

    const matchedAssetIds = matchedAssets.map((asset) => asset.id);
    const mergedIds = MergeArrays(matchedAssetIds, custodianAssetsIds);
    const matchingLiabilities = FilterMemberInstruments(
      selectedMembers,
      liabilities
    );
    const entityLiabilities = GetEntityInstruments(liabilities, finalEntities);
    const mergedLiabilities = MergeArrays(
      matchingLiabilities,
      entityLiabilities
    );

    const filteredLiabilities = HasAssociatedAsset(
      mergedLiabilities,
      mergedIds
    );
    custodianAssets = custodianAssets.map((asset) => {
      let matching = filteredLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.some((o) => selectedMemIds.includes(o))) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });

      const calculated = CalculateMultipleCustodianValues(matching);
      asset.owned_by_another = true;
      asset.owned_by_entity = false;
      asset.linked_items = calculated;
      return asset;
    });

    entityAssets = entityAssets.map((asset) => {
      const copiedAsset = { ...asset };
      let matching = liabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.some((o) => selectedMemIds.includes(o))) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      copiedAsset.linked_items = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      copiedAsset.owned_by_another = false;
      copiedAsset.owned_by_entity = true;
      return copiedAsset;
    });
    const entityAssetsIds = entityAssets.map((asset) => asset.id);

    let entityRollupAssets = [];
    if (showEntityRollup) {
      //1. Get all rollup entities
      const rollupEntities = finalEntities.filter(
        (entity) => entity.entity_rollup
      );
      //2. Get all rollup instruments
      entityRollupAssets = GenerateRollupItems(
        selectedMemIds,
        rollupEntities,
        entityAssets,
        allInstrumentCategories,
        'asset',
        'southWest'
      );
      //3. Find all rollup entities' incomes
      let rollupInstruments = GetEntityInstruments(
        entityAssets,
        rollupEntities
      );
      //4. Filter out rollup incomes from regular display
      entityAssets = entityAssets.filter((asset) => {
        if (!rollupInstruments.some((i) => i.id === asset.id)) {
          return asset;
        }
      });
    }
    custodianAssets = custodianAssets.filter((asset) => {
      if (!entityAssetsIds.includes(asset.id)) {
        return asset;
      }
    });

    custodianAssets = CalculateMultipleCustodianValues(custodianAssets);

    matchedAssets = matchedAssets.map((asset) => {
      let matching = filteredLiabilities.filter(
        (liability) => liability.associated === asset.id
      );
      const propCas = insurances.filter((insurance) =>
        insurance.assets.includes(asset.id)
      );
      matching = [...matching, ...propCas];
      matching = matching.map((item) => {
        const copied = { ...item };
        const owners = item.members.reduce((acc, curr) => {
          if (curr.relation === 'owner') {
            return [...acc, curr.id];
          }
          return acc;
        }, []);
        if (owners.some((o) => selectedMemIds.includes(o))) {
          copied.owned_by_entity = false;
        } else {
          copied.owned_by_entity = true;
        }
        return copied;
      });
      if (!amrShowEntity) {
        matching = matching.filter((lia) => !lia.owned_by_entity);
      }
      const calculated = CalculateMultipleOwnerValues(
        selectedMembers,
        matching
      );
      asset.owned_by_another = false;
      asset.owned_by_entity = false;
      asset.linked_items = calculated;
      return asset;
    });
    matchedAssets = CalculateMultipleOwnerValues(
      selectedMembers,
      matchedAssets
    );

    return SortInstrumentPriority([
      ...matchedAssets,
      ...custodianAssets,
      ...entityAssets,
      ...entityRollupAssets,
    ]);
  }
};
