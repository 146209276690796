import { useState } from 'react';
import {
  Page,
  CenterMaxWidth,
  BlockContainer,
  ContentChunk,
  LineBreak,
  ColorTileContainer,
  ColorTile,
} from '../styles/library/layoutStyles';
import {
  PrimaryHeading,
  SecondaryHeading,
  ContentHeader,
  Text,
  AuthText,
  EmailLink,
  LinkStyle,
} from '../styles/library/fontStyles';
import {
  CenterModal,
  ModalHeader,
  ModalHeadingConfig,
  ModalContent,
} from '../styles/library/modalStyles';
import {
  CheckBox,
  Button,
  TextInput,
  Toggle,
  LoadingAnimation,
} from '../components';
import {
  SuccessNotification,
  InfoNotification,
  ErrorNotification,
  WarnNotification,
  LoadingNotification,
} from '../utils';
import * as variables from '../styles/variables';
import { ButtonThemes, InputThemes } from '../styles/themes';
import { plusCircle, arrowRight, search } from '../assets';

let colorArray = [];
const createColorsArray = (obj) => {
  for (const property in obj) {
    let colorObj = {};
    colorObj.name = property;
    colorObj.value = obj[property];
    colorArray.push(colorObj);
  }
};
createColorsArray(variables.colors);

const Styles = () => {
  const [toggleValue, setToggleValue] = useState(true);
  const [toggleValue2, setToggleValue2] = useState(true);
  const testFunction = () => {
    setToggleValue(!toggleValue);
  };
  const testFunction2 = () => {
    setToggleValue2(!toggleValue2);
  };

  const showToastNotification = (type) => {
    switch (type) {
      case 'success':
        return SuccessNotification('Success Notification');
      case 'error':
        return ErrorNotification('Error Notification');
      case 'info':
        return InfoNotification('Info Notification');
      case 'warning':
        return WarnNotification('Warning Notification');
      case 'loading':
        return LoadingNotification('Loading Notification');
      default:
        return null;
    }
  };

  return (
    <Page>
      <CenterMaxWidth>
        <PrimaryHeading style={{ textAlign: 'center', margin: '0 0 50px 0' }}>
          Style Guide
        </PrimaryHeading>
        <ContentChunk>
          <ContentHeader>Headings</ContentHeader>
          <PrimaryHeading>Primary Heading</PrimaryHeading>
          <SecondaryHeading>Secondary Heading</SecondaryHeading>
          <ContentHeader style={{ marginBottom: '5px' }}>
            Content Heading
          </ContentHeader>
          <Text>Paragraph</Text>
          <LinkStyle style={{ display: 'block', margin: '5px 0' }}>
            Link
          </LinkStyle>
          <EmailLink>Email Link</EmailLink>
        </ContentChunk>

        <ContentChunk>
          <ContentHeader>Buttons</ContentHeader>
          <Button text="Inactive Button" theme={ButtonThemes.inactive}></Button>
          <BlockContainer>
            <Button text="Green Button" theme={ButtonThemes.primary} />
          </BlockContainer>
          <BlockContainer style={{ maxWidth: '200px' }}>
            <Button
              text="Green Icon Button"
              imageSrc={plusCircle}
              imageText="add"
              theme={ButtonThemes.primary}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              text="Big Green Button"
              bigButton={true}
              theme={ButtonThemes.primary}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              bigButton={true}
              text="Big Green Icon Button"
              imageSrc={plusCircle}
              imageText="add"
              theme={ButtonThemes.bigButtonPrimary}
            />
          </BlockContainer>
          <BlockContainer>
            <Button text="Light Blue Button" theme={ButtonThemes.secondary} />
          </BlockContainer>
          <BlockContainer style={{ maxWidth: '200px' }}>
            <Button
              text="Light Blue Icon Button"
              imageSrc={arrowRight}
              imageText="add"
              theme={ButtonThemes.secondary}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              bigButton={true}
              text="Big Light Blue Button"
              theme={ButtonThemes.secondary}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              bigButton={true}
              text="Big Light Blue Icon Button"
              imageSrc={arrowRight}
              imageText="arrow"
              theme={ButtonThemes.bigButtonSecondary}
            />
          </BlockContainer>
          <BlockContainer>
            <Toggle
              props={{
                name: 'test',
                value: toggleValue,
              }}
              onClick={testFunction}
            />
          </BlockContainer>
          <BlockContainer>
            <Toggle
              props={{
                name: 'test',
                value: toggleValue2,
              }}
              onClick={testFunction2}
            />
          </BlockContainer>
        </ContentChunk>

        <ContentChunk>
          <ContentHeader>Inputs</ContentHeader>
          <TextInput
            placeholder="Input Outline"
            theme={InputThemes.outlineInput}
          />
          <TextInput
            placeholder="Input Background"
            theme={InputThemes.backgroundInput}
          />
          <TextInput
            placeholder="Input Background"
            theme={InputThemes.backgroundInputFull}
          />
          <TextInput
            bigInput={true}
            imageSrc={search}
            imageName="search"
            placeholder="Big Icon Input"
            theme={InputThemes.bigInputWhite}
          />
          <CheckBox label={'Checkbox'}></CheckBox>
        </ContentChunk>

        <ContentChunk>
          <ContentHeader>Modals</ContentHeader>
          <CenterModal style={{ margin: '20px 0 0 0' }}>
            <ModalHeader>
              <ModalHeadingConfig>Input Modal</ModalHeadingConfig>
            </ModalHeader>
            <ModalContent>
              <TextInput
                placeholder="Email"
                theme={InputThemes.backgroundInputFull}
              />
              <TextInput
                placeholder="Password"
                theme={InputThemes.backgroundInputFull}
              />
              <Button text="Submit" theme={ButtonThemes.primaryFull}></Button>
            </ModalContent>
          </CenterModal>
          <CenterModal style={{ margin: '20px 0 0 0' }}>
            <ModalHeader>
              <ModalHeadingConfig>Content Modal</ModalHeadingConfig>
            </ModalHeader>
            <ModalContent>
              <AuthText style={{ paddingBottom: '0' }}>
                A password recovery email was sent to:
              </AuthText>
              <Text
                style={{
                  textAlign: 'center',
                  padding: '15px 0 20px 0',
                  fontWeight: variables.fonts.semiBold,
                }}
              >
                john.smith@gmail.com
              </Text>
              <LineBreak />
              <AuthText style={{ paddingBottom: '0' }}>
                For any questions, please contact support at: (555) 5555-555 or
                email <EmailLink>jdoe@test.com</EmailLink>
              </AuthText>
              <AuthText style={{ padding: '15px 0 10px 0' }}>
                For more information, visit us at{' '}
                <LinkStyle>test.com</LinkStyle>
              </AuthText>
            </ModalContent>
          </CenterModal>
        </ContentChunk>

        <ContentChunk>
          <ContentHeader>Toast notifications</ContentHeader>
          <BlockContainer>
            <Button
              text="Success"
              theme={ButtonThemes.primary}
              onClick={() => showToastNotification('success')}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              text="Error"
              theme={ButtonThemes.error}
              onClick={() => showToastNotification('error')}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              text="Info"
              theme={ButtonThemes.info}
              onClick={() => showToastNotification('info')}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              text="Warning"
              theme={ButtonThemes.warning}
              onClick={() => showToastNotification('warning')}
            />
          </BlockContainer>
          <BlockContainer>
            <Button
              text="Loading"
              theme={ButtonThemes.secondary}
              onClick={() => showToastNotification('loading')}
            />
          </BlockContainer>
        </ContentChunk>

        <ContentChunk>
          <ContentHeader>Loaders</ContentHeader>
          <BlockContainer>
            <LoadingAnimation />
          </BlockContainer>
          <BlockContainer>
            <LoadingAnimation smaller={true} />
          </BlockContainer>
        </ContentChunk>

        <ContentChunk>
          <ContentHeader>Colors</ContentHeader>
          <ColorTileContainer>
            {colorArray.map((color, i) => {
              return (
                <ColorTile key={i} style={{ backgroundColor: color.value }}>
                  <p>{color.name}</p>
                  <p>{color.value}</p>
                </ColorTile>
              );
            })}
          </ColorTileContainer>
        </ContentChunk>
      </CenterMaxWidth>
    </Page>
  );
};

export default Styles;
