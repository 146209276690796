import { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ChromePicker } from 'react-color';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setCustomPenColor } from '../../store/actions';
import { timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
} from '../../styles/library/modalStyles';

const ColorPickerModal = ({ hide }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { customPenColor } = useSelector((state) => ({
    customPenColor: state.marker.customPenColor,
  }));

  const handleInputChange = (color) => {
    return dispatch(setCustomPenColor(color.hex));
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer
          role="dialog"
          ref={ref}
          style={{ minWidth: '280px', maxWidth: '280px' }}
        >
          <ModalHeadingContainer>
            <ModalHeading>Select Custom Pen Color</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          <ColorPickerContainer>
            <ChromePicker
              color={customPenColor}
              onChange={(color) => handleInputChange(color)}
              disableAlpha={true}
            />
          </ColorPickerContainer>
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const ColorPickerContainer = styled.div`
  .chrome-picker {
    width: auto !important;
    box-shadow: none !important;
    border-radius: 0 0 6px 6px !important;
  }
`;

ColorPickerModal.propTypes = {
  hide: PropTypes.func,
};

export default ColorPickerModal;
