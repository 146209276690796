import { HouseholdSummary } from '../../containers';
import { UpdatePageTitle, CloseDropdowns } from '../../utils';

const HouseholdHome = () => {
  UpdatePageTitle('Household');
  CloseDropdowns();

  return <HouseholdSummary />;
};

export default HouseholdHome;
