export const ApiTypes = {
  API: 'API',
  API_START: 'API_START',
  API_END: 'API_END',
  API_SUCCESS: 'API_SUCCESS',
  UNAUTHORIZED: 'UNAUTHORIZED',
  ACCESS_DENIED: 'ACCESS_DENIED',
  API_ERROR: 'API_ERROR',
};

//DISPATCHES WHEN API CALL STARTS (OPTIONAL)
export const apiStart = (label) => ({
  type: ApiTypes.API_START,
  payload: label,
});

//DISPATCHES WHEN API CALL IS DONE
export const apiEnd = (label) => ({
  type: ApiTypes.API_END,
  payload: label,
});

//DISPATCHES IF API CALL SUCCESSFUL
export const apiSuccess = (label, data) => ({
  type: ApiTypes.API_SUCCESS,
  payload: { label, data },
});

//DISPATCHES IF API CALL IS 401
export const unauthorized = (error, action) => ({
  type: ApiTypes.UNAUTHORIZED,
  payload: {
    label: action,
    error: error,
  },
});

//DISPATCHES IF API CALL IS 403
export const accessDenied = (error, action, path) => ({
  type: ApiTypes.ACCESS_DENIED,
  payload: {
    label: action,
    error: error,
    path: path,
  },
});

//DISPATCHES IF API IS ANY OTHER ERROR 400-500
export const apiError = (error, action) => ({
  type: ApiTypes.API_ERROR,
  payload: {
    label: action,
    error: error,
  },
});

/*
  API Action function takes in the following
  - path <string>: relative url path after /api/v3
  - method <string>: GET, POST, PUT or DELETE
  - absolute <boolean>: whether path should be relative
  - data <object>: data to pass to API endpoint
  - passData <boolean>: whether to pass data to onSuccess
  - passId <string>: id that can passed to onSuccess
  - passSubId <string>: sub id that can passed to onSuccess
  - passThirdParam <string>: third value that can passed to onSuccess
  - showStart <boolean>: whether an apiStart should be dispatched
  - sendData <object>: data to be passed from one action to another
  - onSuccess <function>: what function/action to run on success
  - successMessage <string>: if set will show success toast with this message
  - errorMessage <string>: if set will show error toast with this message
  - onFailure <function>: what function/action to run on error
  - label <string>: redux label to be used in store
*/
export function apiAction({
  path = '',
  method = '',
  absolute = false,
  isConsumer = false,
  data = null,
  passData = false,
  passId = null,
  passSubId = null,
  passThirdParam = null,
  showStart = false,
  sendData = null,
  passValue = false,
  ignoreResponse = false,
  onSuccess = () => {},
  successMessage = null,
  errorMessage = null,
  onFailure = null,
  label = '',
}) {
  return {
    type: ApiTypes.API,
    payload: {
      path,
      method,
      absolute,
      isConsumer,
      data,
      passData,
      passId,
      passSubId,
      passThirdParam,
      showStart,
      sendData,
      passValue,
      ignoreResponse,
      onSuccess,
      successMessage,
      errorMessage,
      onFailure,
      label,
    },
  };
}
