import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { ThemeProvider } from 'styled-components';
import {
  colors,
  boxShadows,
  inputColors,
  messageColors,
  fonts,
} from '../../styles/variables.js';
import { chevronRight, chevronDown } from '../../assets';
import PropTypes from 'prop-types';
import { DateFormat } from '../../utils/dates.js';

const DatePickerInput = ({
  value,
  onChange,
  dateFormat = 'MM/dd/yyyy',
  placeholder = 'Click to Select a Date',
  maxDate,
  beforeYearRange = 120,
  afterYearRange = 80,
  hasError,
  justUpdated,
  disabled = false,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [years, setYears] = useState([]);
  const currentYear = new Date().getFullYear();

  const updateDateFormat = () => {
    if (dateFormat === 'YYYY/MM/DD') {
      return 'yyyy/MM/dd';
    }
    if (dateFormat === 'MM/DD/YYYY') {
      return 'MM/dd/yyyy';
    }
    if (dateFormat === 'DD/MM/YYYY') {
      return 'dd/MM/yyyy';
    }
    return dateFormat;
  };

  useEffect(() => {
    if (value) {
      let transformed = DateFormat(value, 'YYYY-MM-DD');
      let parts = transformed.split('-');
      let stringDate = new Date(parts[0], parts[1] - 1, parts[2]);
      setStartDate(stringDate);
    } else {
      setStartDate();
    }
  }, [value]);

  useEffect(() => {
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    let years = range(
      currentYear + afterYearRange,
      currentYear - beforeYearRange,
      -1
    );
    setYears(years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateOnChange = (date) => {
    setStartDate(date);
    onChange(date);
  };

  return (
    <ThemeProvider
      theme={{
        border: hasError
          ? `1px solid ${messageColors.error}`
          : `1px solid ${inputColors.border}`,
        background: disabled
          ? colors.lightGrey
          : justUpdated
            ? inputColors.updated
            : colors.white,
        disabled: disabled,
      }}
    >
      <DatePickerContainer>
        <DatePicker
          showPopperArrow={false}
          placeholderText={placeholder}
          todayButton={'Today'}
          readOnly={disabled}
          renderCustomHeader={({
            date,
            changeYear,
            dateFormat,
            maxDate,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <MonthYearContainer>
              <ArrowButtonLeft
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <img src={chevronRight} alt="left" data-image="arrow-left" />
              </ArrowButtonLeft>
              <SelectContainer>
                <MonthSelect
                  value={months[date.getMonth()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </MonthSelect>
                <YearSelect
                  value={date.getFullYear()}
                  onChange={(e) => changeYear(e.currentTarget.value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </YearSelect>
              </SelectContainer>
              <ArrowButton
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <img src={chevronRight} alt="right" data-image="arrow-right" />
              </ArrowButton>
            </MonthYearContainer>
          )}
          selected={startDate}
          onChange={(date) => dateOnChange(date)}
          dateFormat={updateDateFormat()}
          maxDate={maxDate}
        />
      </DatePickerContainer>
    </ThemeProvider>
  );
};

const DatePickerContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  .react-datepicker {
    border: 1px solid ${colors.lightGrey};
    box-shadow: ${boxShadows.boxShadowDropdown};
    .react-datepicker__today-button {
      color: ${colors.darkGrey};
      font-weight: ${fonts.semiBold};
      border-top: 1px solid transparent;
    }
  }
  .react-datepicker__header {
    background: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.lightGrey};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: ${colors.darkGrey};
        font-weight: ${fonts.semiBold};
      }
    }
  }
  .react-datepicker__month {
    .react-datepicker__week {
      color: ${colors.darkGrey};
      .react-datepicker__day {
        color: ${colors.darkGrey};
      }
      .react-datepicker__day--outside-month {
        color: ${colors.paleGrey};
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected {
        background: ${colors.lightBlue};
        color: ${colors.white};
      }
    }
  }
  .react-datepicker-wrapper {
    flex: 1 1 auto;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    .react-datepicker__input-container {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      .react-datepicker-ignore-onclickoutside,
      input {
        /* border: 1px solid ${inputColors.border}; */
        border: ${(props) => props.theme.border};
        background: ${(props) => props.theme.background};
        transition: background-color 100ms linear;
        padding: 9px 12px;
        flex: 1 1 auto;
        text-align: left;
        width: 100%;
        border-radius: 6px;
        color: ${colors.darkGrey};
        &::placeholder {
          color: ${colors.paleGrey};
          opacity: 1;
        }
      }
    }
  }
`;

const MonthYearContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

const SelectArrow = styled.select`
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${chevronDown});
  background-color: transparent;
  background-size: 17px;
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  color: ${colors.darkGrey};
  font-family: 'Open Sans';
  option {
    text-align: center;
  }
`;

const MonthSelect = styled(SelectArrow)`
  padding: 0 15px 0 0;
  flex: 0 0 auto;
  margin-right: 15px;
`;

const YearSelect = styled(SelectArrow)`
  flex: 0 0 auto;
  padding: 0 20px 0 0;
`;

const SelectContainer = styled.div`
  flex: 1 1 auto;
`;

const ArrowButton = styled.button`
  flex: 0 0 auto;
  img {
    width: 10px;
    height: 10px;
  }
`;
const ArrowButtonLeft = styled(ArrowButton)`
  img {
    width: 10px;
    height: 10px;
    transform: rotate(180deg);
  }
`;

DatePickerInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  afterYearRange: PropTypes.number,
  beforeYearRange: PropTypes.number,
  hasError: PropTypes.bool,
  justUpdated: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DatePickerInput;
