/* eslint-disable react-hooks/exhaustive-deps */
import { ManagerAtlasContainer } from '../../containers';
import { PageHeader } from '../../components';
import { Page } from '../../styles/library/layoutStyles';
import { UpdatePageTitle, headerContent, CloseDropdowns } from '../../utils';

const Atlas = () => {
  UpdatePageTitle('Insights + Analytics');
  CloseDropdowns();

  return (
    <Page>
      <PageHeader content={headerContent.atlas} margin="25px 40px 0 40px" />
      <ManagerAtlasContainer />
    </Page>
  );
};

export default Atlas;
