/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { CardElement, useRecurly } from '@recurly/react-recurly';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { FormChunk, Button } from './';
import {
  GetCountries,
  GetStates,
  states,
  SetDefaultItem,
  SendTrackingEvent,
} from '../utils';
import {
  inputColors,
  messageColors,
  fonts,
  colors,
  boxShadows,
} from '../styles/variables';
import { updateBillingInfo } from '../store/actions';
import PropTypes from 'prop-types';
import { bankCheck, creditCard } from '../assets';

const RecurlyBilling = ({
  data,
  showACH,
  bankData,
  countries,
  defaultState,
  onChange,
  onBankChange,
  customerId,
  updateBillingError,
}) => {
  const formRef = useRef();
  const recurly = useRecurly();
  const dispatch = useDispatch();
  const { invocationId } = useSelector((state) => ({
    invocationId: state.configs.invocationId,
  }));
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [stateDisabled, setStateDisabled] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [hasCreditCardError, setHasCreditCardError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateBillingLink, setUpdateBillingLink] = useState();
  const [paymentOption, setPaymentOption] = useState('credit');

  useEffect(() => {
    if (data) {
      SetDefaultItem(states, data.state, setSelectedState);
      SetDefaultItem(countries, data.country, setSelectedCountry);
      setIsUpdating(false);
    }
  }, [data]);

  useEffect(() => {
    if (selectedCountry && selectedCountry.value !== 'US') {
      setSelectedState(null);
      setStateDisabled(true);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (defaultState) {
      SetDefaultItem(states, defaultState, setSelectedState);
      setStateDisabled(false);
    }
  }, [defaultState]);

  useEffect(() => {
    if (updateBillingError) {
      setIsUpdating(false);
      setHasErrors(true);
      if (updateBillingError?.data?.detail?.card) {
        setErrorMessage(updateBillingError?.data?.detail?.card);
      } else {
        setUpdateBillingLink(`${window.location.origin}${data.update_url}`);
        setErrorMessage(
          'Error updating info; please check that your billing information is correct.'
        );
      }
    } else {
      setHasErrors(false);
    }
  }, [updateBillingError]);

  const recurlyOnSuccess = (token) => {
    setHasErrors(false);
    setErrorMessage('');
    setErrorFields([]);
    setHasCreditCardError(false);
    dispatch(updateBillingInfo(customerId, token?.id));
    SendTrackingEvent(
      invocationId,
      'action',
      'update_billing_info',
      'billing_info',
      {
        customerId,
        tokenId: token?.id,
      }
    );
    setIsUpdating(true);
  };

  const recurlyOnError = (error) => {
    console.log('err is', error);
    setHasErrors(true);
    setErrorMessage(error.message);
    setErrorFields(error.details);
    if (error.fields.includes('number', 'month', 'year')) {
      setHasCreditCardError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUpdating) {
      return;
    }
    if (updateBillingLink) {
      setUpdateBillingLink();
    }
    if (paymentOption === 'credit') {
      recurly.token(formRef.current, (err, token) => {
        if (err) {
          recurlyOnError(err);
        } else {
          recurlyOnSuccess(token);
        }
      });
    } else {
      recurly.bankAccount.token(formRef.current, (err, token) => {
        if (err) {
          recurlyOnError(err);
        } else {
          recurlyOnSuccess(token);
        }
      });
    }
  };

  const bankAccountInputs = [
    {
      name: 'name_on_account',
      placeholder: 'Name on Account',
      type: 'text',
      width: '50%',
      required: true,
    },
    {
      name: 'routing_number',
      placeholder: 'Routing Number',
      type: 'text',
      width: '50%',
      required: true,
      tooltip: 'First number listed on a check (9 digits)',
    },
    {
      name: 'account_number',
      placeholder: 'Account Number',
      type: 'text',
      width: '50%',
      required: true,
      tooltip: 'Second number listed on a check (8-12 digits)',
    },
    {
      name: 'account_number_confirmation',
      placeholder: 'Confirm Account Number',
      type: 'text',
      width: '50%',
      required: true,
      tooltip: 'Second number listed on a check (8-12 digits)',
    },
    {
      name: 'account_type',
      placeholder: 'Bank Account Type',
      type: 'radio',
      width: '50%',
      required: true,
      value: bankData.account_type,
      onChange: (e, v) => onBankChange(e, v, 'account_type'),
      options: [
        { label: 'Checking', value: 'checking' },
        { label: 'Savings', value: 'savings' },
      ],
    },
  ];

  const billingInfoInputs = [
    {
      name: 'first_name',
      placeholder: 'First Name',
      type: 'text',
      width: '50%',
      required: true,
      isHidden: paymentOption === 'bank',
    },
    {
      name: 'last_name',
      placeholder: 'Last Name',
      type: 'text',
      width: '50%',
      required: true,
      isHidden: paymentOption === 'bank',
    },
    {
      name: 'address1',
      placeholder: 'Address',
      type: 'text',
      width: '50%',
      required: true,
      lastpassIgnore: true,
    },
    {
      name: 'address2',
      placeholder: 'Address Additional',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
    },
    {
      name: 'country',
      placeholder: 'Country',
      type: 'select',
      width: '100%',
      selectedOption: selectedCountry,
      onChange: (e) => onChange(e, true, 'country', setSelectedCountry),
      options: GetCountries(countries),
      required: true,
    },
    {
      name: 'country',
      placeholder: 'Country',
      type: 'text',
      width: '0%',
      hidden: true,
      required: false,
    },
    {
      name: 'city',
      placeholder: 'City',
      type: 'text',
      width: '50%',
      required: true,
      lastpassIgnore: true,
      margin: '0 8px 10px 0',
    },
    {
      name: 'state',
      placeholder: 'State',
      type: 'select',
      width: 'calc(30% - 8px)',
      selectedOption: selectedState,
      onChange: (e) => onChange(e, true, 'state', setSelectedState),
      options: GetStates(states),
      required: false,
      isDisabled: stateDisabled,
      margin: '0 8px 10px 0',
    },
    {
      name: 'state',
      placeholder: 'State',
      type: 'text',
      width: '0%',
      hidden: true,
      required: false,
    },
    {
      name: 'zip',
      recurly: 'postal_code',
      placeholder: 'Zip',
      type: 'text',
      width: 'calc(20% - 8px)',
      required: true,
      lastpassIgnore: true,
    },
    {
      name: 'phone',
      placeholder: 'Phone',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      recurlyIgnore: true,
    },
  ];

  return (
    <ThemeProvider theme={{ hasError: hasCreditCardError }}>
      {showACH && (
        <div>
          <FormHeader>Payment Options</FormHeader>
          <PaymentOptionsContainer>
            <PaymentOption
              isActive={paymentOption === 'credit'}
              onClick={() => setPaymentOption('credit')}
            >
              <img src={creditCard} alt="credit card" />
              Credit Card
            </PaymentOption>
            <PaymentOption
              isActive={paymentOption === 'bank'}
              onClick={() => setPaymentOption('bank')}
            >
              <img src={bankCheck} alt="bank account" />
              Bank Transfer (ACH)
            </PaymentOption>
          </PaymentOptionsContainer>
        </div>
      )}
      <form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
        {paymentOption === 'credit' ? (
          <>
            <FormHeader>Credit Card Info</FormHeader>
            <CreditCardContainer>
              <CardElement style={{ fontSize: '14px' }} />
            </CreditCardContainer>
          </>
        ) : paymentOption === 'bank' ? (
          <>
            <FormChunk
              header={'Bank Account Information'}
              inputs={bankAccountInputs}
              value={bankData}
              onChange={onBankChange}
              borderBottom={false}
              hasErrorDisplay={true}
              showRecurly={true}
              style={{ paddingBottom: '0' }}
              errorFields={errorFields}
            />
          </>
        ) : null}
        <FormChunk
          header={'Billing Information'}
          inputs={billingInfoInputs.filter((input) => !input.isHidden)}
          value={data}
          onChange={onChange}
          borderBottom={false}
          hasErrorDisplay={true}
          showRecurly={true}
          style={{ paddingBottom: '0' }}
          errorFields={errorFields}
        />
        <ButtonContainer>
          <Button
            text="Update"
            loadingText="Updating"
            showLoading={isUpdating}
          />
        </ButtonContainer>
        {hasErrors && (
          <ErrorContainer>
            <strong>Error: </strong>
            {errorMessage}
            {updateBillingLink && (
              <>
                <span> Or click </span>
                <a href={updateBillingLink} target="_blank" rel="noreferrer">
                  here
                </a>
                <span> to update your billing info.</span>
              </>
            )}
          </ErrorContainer>
        )}
      </form>
    </ThemeProvider>
  );
};

const PaymentOptionsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
`;

const PaymentOption = styled.button`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  padding: 20px 15px;
  width: 210px;
  border: ${(props) =>
    props.isActive
      ? `1px solid ${colors.darkGrey};`
      : `1px solid ${inputColors.border};`};
  font-weight: ${(props) => (props.isActive ? fonts.semiBold : fonts.regular)};
  color: ${(props) => (props.isActive ? colors.darkGrey : colors.paleGrey)};
  background: ${(props) =>
    props.isActive ? colors.white : colors.lighterGrey};
  box-shadow: ${(props) => (props.isActive ? boxShadows.boxShadow : null)};
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transform: scale(1.01);
  }
  img {
    width: 20px;
    margin-right: 10px;
    opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  }
`;

const ErrorContainer = styled.div`
  margin-top: 10px;
  color: ${messageColors.error};
  font-size: 13px;
  a {
    color: ${messageColors.error};
    font-weight: ${fonts.semiBold};
    text-decoration: underline;
  }
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  margin-bottom: 15px;
`;

const CreditCardContainer = styled.div`
  height: 40px;
  margin-bottom: 25px;
  .recurly-element-card {
    border: ${(props) =>
      props.theme.hasError
        ? `1px solid ${messageColors.error}`
        : `1px solid ${inputColors.border}`};
    height: 40px;
    border-radius: 6px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

RecurlyBilling.propTypes = {
  data: PropTypes.object,
  bankData: PropTypes.object,
  showACH: PropTypes.bool,
  countries: PropTypes.array,
  defaultState: PropTypes.string,
  onChange: PropTypes.func,
  onBankChange: PropTypes.func,
  customerId: PropTypes.string,
  updateBillingError: PropTypes.object,
};

export default RecurlyBilling;
