/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  PageHeader,
  TabMenu,
  SurveyResponsesModal,
  EmptyImage,
} from '../../components';
import {
  getSurveyList,
  getSurveyHistory,
  toggleShowSurveyResponsesModal,
} from '../../store/actions';
import {
  HouseholdSurveysContainer,
  HouseholdSurveysHistory,
} from '../../containers';

import { UpdatePageTitle, headerContent } from '../../utils';
import { noReports } from '../../assets';

const HouseholdSurveys = () => {
  UpdatePageTitle('Questionnaires');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  const {
    surveys,
    showSurveyHistory,
    showingSurveyResponsesModal,
    selectedSurvey,
    isStencil,
  } = useSelector((state) => ({
    surveys: state.households.surveys,
    showSurveyHistory: state.user.showSurveyHistory,
    showingSurveyResponsesModal: state.households.showingSurveyResponsesModal,
    selectedSurvey: state.households.selectedSurvey,
    isStencil: state.households.isStencil,
  }));
  const [activeTab, setActiveTab] = useState('questionnaires');
  const [hasSurveys, setHasSurveys] = useState(null);

  const tabs = [
    {
      text: 'Questionnaires',
      value: 'questionnaires',
    },
    {
      text: 'History',
      value: 'history',
    },
  ];

  useEffect(() => {
    dispatch(getSurveyList(householdId));
    dispatch(getSurveyHistory(householdId));
  }, []);

  useEffect(() => {
    if (surveys) {
      setHasSurveys(surveys?.count !== 0);
    }
  }, [surveys]);

  useEffect(() => {
    if (showSurveyHistory !== null) {
      if (location?.search && location?.search.includes('?panel=')) {
        const tabSplit = location?.search.split('?panel=');
        const tabValue = tabSplit[1];
        if (tabValue === 'history') {
          if (showSurveyHistory) {
            setActiveTab('history');
          } else {
            setActiveTab('questionnaires');
            navigate(location.pathname);
          }
        }
      }
    }
  }, [showSurveyHistory]);

  useEffect(() => {
    if (location) {
      if (location?.search && location?.search.includes('?panel=')) {
        const tabSplit = location?.search.split('?panel=');
        const tabValue = tabSplit[1];
        const tabOptions = tabs.map((tab) => tab.value);
        if (tabOptions.includes(tabValue)) {
          setActiveTab(tabValue);
        } else {
          setActiveTab('questionnaires');
          location.search = `panel=questionnaires`;
        }
      } else {
        setActiveTab('questionnaires');
        location.search = `panel=questionnaires`;
      }
    }
  }, [location]);

  const tabOnClick = (tab) => {
    location.search = `panel=${tab}`;
    navigate(location, { state: { panel: tab } });
  };

  return (
    <>
      {showingSurveyResponsesModal && (
        <SurveyResponsesModal
          hide={() =>
            dispatch(
              toggleShowSurveyResponsesModal(!showingSurveyResponsesModal, null)
            )
          }
          householdId={householdId}
          survey={selectedSurvey}
        />
      )}
      <PageContainer>
        <PageHeader
          content={{
            ...headerContent.surveys,
            subcontent:
              hasSurveys === null || hasSurveys
                ? []
                : headerContent.surveys?.subcontent,
          }}
        />
        {isStencil ? (
          <>
            <EmptyImage
              imageSrc={noReports}
              imageName="No Questionnaires"
              text="Questionnaires are not available for Stencil Households"
            />
          </>
        ) : (
          <>
            {showSurveyHistory && (
              <TabMenu tabs={tabs} activeTab={activeTab} onClick={tabOnClick} />
            )}
            {activeTab === 'questionnaires' ? (
              <HouseholdSurveysContainer householdId={householdId} />
            ) : activeTab === 'history' ? (
              <HouseholdSurveysHistory householdId={householdId} />
            ) : null}
          </>
        )}
      </PageContainer>
    </>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default HouseholdSurveys;
