import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as styles from '../variables';

export const ClickContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: flex-end;
  position: relative;
`;

export const ClickText = styled.p`
  margin-right: 5px;
`;

export const ClickImage = styled.img`
  width: 18px;
  height: 18px;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  z-index: 99;
  background: white;
  width: 60px;
  right: ${(props) => (props.right ? props.right : '4px')};
  top: 28px;
  margin-top: 5px;
  display: ${(props) => (props.display ? props.display : 'none')};
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  width: 110px;
  box-shadow: ${styles.boxShadows.boxShadow};
  &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    right: 8px;
    top: -6px;
    z-index: 9999 !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${styles.colors.darkGrey};
  }
`;

export const DropdownHeading = styled.h3`
  font-weight: ${styles.fonts.semiBold};
  padding: 8px 10px;
  background: ${styles.colors.darkGrey};
  color: ${styles.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
`;

export const DropdownContent = styled.div`
  padding: 10px 10px;
`;

export const DropdownItem = styled.div`
  margin: 8px 0;
  padding: 5px 10px;
  border-radius: 3px;
  background: ${styles.colors.lightGrey};
  text-align: center;
  display: block;
  cursor: pointer;
  &:hover {
    background: ${styles.colors.green};
    color: ${styles.colors.white};
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const DefaultOptionLabel = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: ${styles.fonts.semiBold};
  margin: ${(props) => (props.margin ? props.margin : '0 6px 0 5px')};
`;

export const SelectName = styled.span`
  font-weight: ${styles.fonts.semiBold};
`;

export const SelectOrg = styled.span`
  font-weight: ${styles.fonts.regular};
  flex: 0 0 auto;
  font-size: 11px;
  margin-left: 5px;
`;

export const SelectDescription = styled.span`
  font-weight: ${styles.fonts.regular};
  margin-left: 8px;
  font-size: 11px;
  flex: 0 0 auto;
  max-width: 200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectInfoContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

export const StencilInfoContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 5px;
`;

export const StencilDescription = styled.span`
  font-weight: ${styles.fonts.regular};
  font-size: 10px;
  /* flex: 0 0 auto;
  max-width: 200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const SelectDeleteContainer = styled.div`
  flex: 0 0 auto;
  margin-left: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  img {
    cursor: pointer;
    width: 12px;
    height: 12px;
    width: ${(props) => (props.iconWidth ? props.iconWidth : '12px')};
    height: ${(props) =>
      props.iconHeight ? props.iconHeight : props.iconWidth};
  }
`;

export const CustomSelectDropdownOption = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '-5px')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '400px')};
  ${DefaultOptionLabel} {
    color: ${(props) =>
      props.selected ? styles.colors.lightGrey : styles.colors.paleGrey};
  }
  ${SelectName} {
    color: ${(props) =>
      props.selected ? styles.colors.white : styles.colors.darkGrey};
  }
  ${SelectDescription} {
    color: ${(props) =>
      props.selected ? styles.colors.lightGrey : styles.colors.paleGrey};
  }
  ${StencilDescription} {
    color: ${(props) =>
      props.selected ? styles.colors.lightGrey : styles.colors.paleGrey};
  }
  input {
    flex: 0 0 auto;
  }
  label {
    flex: 1 1 auto;
    margin-left: 8px;
    width: 220px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    svg {
      margin-right: 5px;
    }
    img {
      margin-left: 6px;
    }
  }
  img {
    cursor: pointer;
    width: 12px;
    width: ${(props) => (props.iconWidth ? props.iconWidth : '12px')};
    height: 12px;
    height: ${(props) =>
      props.iconHeight ? props.iconHeight : props.iconWidth};
    opacity: 0.6;
    filter: ${(props) => (props.selected ? `brightness(0) invert(1)` : null)};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const HouseholdName = styled.span`
  color: ${styles.colors.lightBlue};
  display: block;
  padding: 8px 12px 0 12px;
`;

export const HouseholdContainer = styled(Link)`
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  &:hover {
    background: ${styles.inputColors.primary25};
    transform: scale(1.01);
    ${HouseholdName} {
      color: ${styles.colors.blue};
    }
  }
`;

export const HouseholdLocation = styled.span`
  color: ${styles.colors.paleGrey};
  font-size: 12px;
  display: block;
  padding: 0px 12px 8px 12px;
`;

export const City = styled.span`
  text-transform: capitalize;
`;

export const State = styled.span`
  text-transform: uppercase;
`;

export const AdvisorContainer = styled.div`
  text-align: left;
`;

export const AdvisorName = styled.p`
  span {
    margin-left: 5px;
    font-weight: ${styles.fonts.regular};
    color: ${styles.colors.paleGrey};
  }
`;

export const AdvisorEmail = styled.p`
  color: ${styles.colors.lightBlue};
`;

export const AdvisorContainerCustom = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  ${AdvisorEmail} {
    font-size: 11px;
    margin-left: 8px;
  }
`;
