/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import Chart from 'chart.js/auto';
import styled from 'styled-components';
import {
  SimpleModal,
  SmallTable,
  Button,
  TargetMapPercentBar,
  CashFlowTable,
  DesiredCashFlowModal,
  CustomCashFlowModal,
  CustomAssetModal,
  CustomInsuranceModal,
  LoadingAnimation,
  BulkActions,
  TargetMapAltModelInputs,
  TargetMapAltModelOutput,
} from '../../components';
import {
  toggleShowDeleteModal,
  toggleShowEditTargetMapModal,
  toggleShowTargetMapNotes,
  updateTargetMap,
  cloneTargetMap,
  cloneTargetMapWant,
  // getHouseholdInfo,
  getTargetMaps,
  getTargetMapInfo,
  removeTargetMapInfo,
  getTargetMapCashFlow,
  toggleIsSelectedTargetMap,
  toggleShowDesiredCashFlowModal,
  toggleShowCustomCashFlowModal,
  toggleShowCustomAssetModal,
  toggleShowCustomInsuranceModal,
  updateTargetInstrumentsOrder,
  toggleShowTargetMapTemplateModal,
  updateTargetMapStoreValue,
  updateUserStoreValue,
} from '../../store/actions';
import {
  // pencil,
  pdf,
  trashDark,
  copy,
  arrowRightDark,
  notePad,
  settingsDark,
  plusSquare,
  search,
  ballot,
  bullseyePointer,
} from '../../assets';
import {
  colors,
  messageColors,
  targetMapColors,
  boxShadows,
  fonts,
  maxDevice,
  heights,
} from '../../styles/variables';
import {
  LoadingPlaceholder,
  SecondaryHeading,
} from '../../styles/library/fontStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  FormatCurrency,
  DownloadReport,
  UpdatePageTitle,
  IsNegative,
  HtmlToString,
  DetermineTargetMapColor,
  DetectEnterKeyPress,
  UseOutsideClick,
  SetObjectFieldValue,
  SendTrackingEvent,
} from '../../utils';
import { useHotkeys } from 'react-hotkeys-hook';

const HouseholdTargetMap = ({ showPDF }) => {
  UpdatePageTitle('Target-Map');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chartRef = useRef();
  const pickerRef = useRef();
  const params = useParams();
  const householdId = params?.id;
  const targetMapId = params?.tm_id;
  const {
    currentHousehold,
    currency,
    changedTargetMap,
    currentTargetMap,
    loadedUser,
    targetMapDefaultTab,
    hasTargetMapTemplates,
    cashFlow,
    showingCashFlowDetails,
    showTargetMapAltModel,
    showingEditTargetMapModal,
    clonedTargetMap,
    deletedTargetMap,
    editedTargetMap,
    showingDesiredCashFlowModal,
    isNewDesiredCashFlow,
    showingCustomCashFlowModal,
    isNewCustomCashFlow,
    showingCustomAssetModal,
    isNewCustomAsset,
    showingCustomInsuranceModal,
    targetMapEditError,
    showingCanvas,
    hasTargetMapCompare,
    inCompareMode,
    invocationId,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    currency: state.households.householdCurrency,
    currentTargetMap: state.targetMaps.currentTargetMap,
    loadedUser: state.user.loadedUser,
    targetMapDefaultTab: state.user.targetMapDefaultTab,
    hasTargetMapTemplates: state.user.hasTargetMapTemplates,
    changedTargetMap: state.targetMaps.changedTargetMap,
    cashFlow: state.targetMaps.cashFlow,
    showingCashFlowDetails: state.user.showTargetMapCashflow,
    showTargetMapAltModel: state.user.showTargetMapAltModel,
    showingEditTargetMapModal: state.targetMaps.showingEditTargetMapModal,
    clonedTargetMap: state.targetMaps.clonedTargetMap,
    deletedTargetMap: state.targetMaps.deletedTargetMap,
    editedTargetMap: state.targetMaps.editedTargetMap,
    showingDesiredCashFlowModal: state.targetMaps.showingDesiredCashFlowModal,
    isNewDesiredCashFlow: state.targetMaps.isNewDesiredCashFlow,
    showingCustomCashFlowModal: state.targetMaps.showingCustomCashFlowModal,
    isNewCustomCashFlow: state.targetMaps.isNewCustomCashFlow,
    showingCustomAssetModal: state.targetMaps.showingCustomAssetModal,
    isNewCustomAsset: state.targetMaps.isNewCustomAsset,
    showingCustomInsuranceModal: state.targetMaps.showingCustomInsuranceModal,
    targetMapEditError: state.targetMaps.targetMapEditError,
    showingCanvas: state.marker.showingCanvas,
    inCompareMode: state.user.inCompareMode,
    hasTargetMapCompare: state.user.hasTargetMapCompare,
    invocationId: state.configs.invocationId,
  }));
  const [targetMap, setTargetMap] = useState({
    name: '',
    notes: '',
    income_funded: 0,
    duration: '0.0',
    growth: '',
    inflation_rate: 0,
    return_rate: 0,
    capital_net: 0,
    funded: 0,
  });
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [matchingSearchInstruments, setMatchingSearchInstruments] = useState(
    []
  );
  const [actions, setActions] = useState([
    {
      id: 'compare',
      label: 'Compare',
      icon: ballot,
      isHidden: true,
      width: '38px',
    },
    {
      id: 'search',
      label: 'Search',
      icon: search,
      isHidden: true,
      width: '38px',
    },
    {
      id: 'edit',
      label: 'Settings',
      icon: settingsDark,
      isHidden: false,
      width: '44.5px',
    },
    {
      id: 'notes',
      label: 'Notes',
      icon: notePad,
      isHidden: false,
      width: '33px',
    },
    { id: 'clone', label: 'Clone', icon: copy, isHidden: false, width: '32px' },
    {
      id: 'template',
      label: 'Template',
      icon: plusSquare,
      isHidden: true,
      width: '52.5px',
    },
    { id: 'pdf', label: 'PDF', icon: pdf, isHidden: !showPDF, width: '22.2px' },
    {
      id: 'delete',
      label: 'Delete',
      icon: trashDark,
      isHidden: false,
      width: '36.2px',
    },
  ]);
  const [selectedTab, setSelectedTab] = useState();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [customColor, setCustomColor] = useState(targetMapColors.custom);
  const [closedPicker, setClosedPicker] = useState(false);
  const [wantsList, setWantsList] = useState([]);
  const [cashFlowList, setCashFlowList] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [insuranceList, setInsuranceList] = useState([]);
  const [loadedInstruments, setLoadedInstruments] = useState(false);
  const [returnRate, setReturnRate] = useState('');
  const [inflationRate, setInflationRate] = useState('');
  const [duration, setDuration] = useState('');
  const [selectedInstrument, setSelectedInstrument] = useState();
  const [editModalError, setEditModalError] = useState('');
  const [chartValue, setChartValue] = useState();
  const [updatingTargetMap, setUpdatingTargetMap] = useState(false);
  const [isSavingTargetMap, setIsSavingTargetMap] = useState(false);
  const [editTargetMapError, setEditTargetMapError] = useState({
    name: false,
    duration: false,
    return_rate: false,
    inflation_rate: false,
  });
  const [copiedTargetMap, setCopiedTargetMap] = useState({
    name: '',
    notes: '',
    income_funded: 0,
    duration: '0.0',
    growth: '',
    inflation_rate: 0,
    return_rate: 0,
    capital_net: 0,
    funded: 0,
  });
  const [tabOptions, setTabOptions] = useState([
    { value: 'want', label: 'What you want' },
    { value: 'have', label: 'What you have' },
    { value: 'means', label: 'What it means' },
    { value: 'chart', label: 'Cash flow details', isHidden: true },
    { value: 'alt_model', label: 'Alt Model', isHidden: true },
  ]);
  const showLabel = true;

  useEffect(() => {
    if (loadedUser) {
      const updatedTabOptions = tabOptions.map((tabOption) => {
        if (tabOption.value === 'chart') {
          tabOption.isHidden = !showingCashFlowDetails;
        }
        if (tabOption.value === 'alt_model') {
          tabOption.isHidden = !showTargetMapAltModel;
        }
        return tabOption;
      });
      setTabOptions(updatedTabOptions);
    }
  }, [loadedUser]);

  UseOutsideClick(pickerRef, () => {
    if (showColorPicker) {
      setShowColorPicker(false);
      setClosedPicker(true);
    }
  });

  useEffect(() => {
    return () => {
      dispatch(removeTargetMapInfo());
      dispatch(updateUserStoreValue('inCompareMode', false));
    };
  }, []);

  //If user info is loaded but no default tab value fallback to tab 3
  useEffect(() => {
    if (loadedUser) {
      // setSelectedTab('alt_model');
      setSelectedTab(targetMapDefaultTab || 'means');
    }
  }, [loadedUser]);

  useEffect(() => {
    if (closedPicker) {
      if (customColor !== targetMap.color) {
        dispatch(
          updateTargetMap(householdId, targetMapId, {
            ...targetMap,
            color: customColor,
          })
        );
      }
    }
  }, [closedPicker]);

  useEffect(() => {
    if (loadedUser) {
      setActions(
        actions.map((action) => {
          if (action.id === 'template') {
            action.isHidden = !hasTargetMapTemplates;
          }
          if (action.id === 'compare') {
            action.isHidden = !hasTargetMapCompare;
          }
          return action;
        })
      );
    }
  }, [loadedUser]);

  useEffect(() => {
    if (currentHousehold && currentHousehold.hasOwnProperty('id')) {
      dispatch(getTargetMapInfo(householdId, targetMapId));
      dispatch(getTargetMapCashFlow(householdId, targetMapId));
    }
  }, [currentHousehold?.id]);

  useEffect(() => {
    if (clonedTargetMap) {
      return navigate(-1);
    }
  }, [clonedTargetMap]);

  useEffect(() => {
    if (!showingEditTargetMapModal) {
      setEditTargetMapError({
        name: false,
        duration: false,
        return_rate: false,
        inflation_rate: false,
      });
      setEditModalError('');
    }
  }, [showingEditTargetMapModal]);

  useEffect(() => {
    if (selectedTab === 'chart' && currentHousehold?.id) {
      dispatch(getTargetMapCashFlow(householdId, targetMapId));
    }
    if (loadedInstruments) {
      const canSearchTab = selectedTab === 'want' || selectedTab === 'have';
      setActions(
        actions.map((action) => {
          if (action.id === 'search') {
            action.isHidden = !canSearchTab;
          }
          return action;
        })
      );
    }
  }, [selectedTab, loadedInstruments]);

  const checkMatchingSearch = (searchList, searchQuery) => {
    return searchList.map((instrument) => {
      let memberName = '';
      let matchesName = false;
      if (instrument.memberObj) {
        memberName = instrument.memberObj?.name;
      } else if (instrument.members && instrument.members.length !== 0) {
        if (searchQuery !== '') {
          const memberNames = instrument.members.reduce((acc, mem) => {
            if (mem.relation === 'insured') {
              let name = mem?.memberObj?.name;
              if (name) {
                name = name.toLowerCase();
              }
              return [...acc, name];
            }
            return acc;
          }, []);
          matchesName = memberNames.some((name) => name.includes(searchQuery));
        }
      }
      const name = instrument?.name.toLowerCase();
      const description = instrument?.description.toLowerCase();
      const nameSearch = memberName.toLowerCase();
      const matchesSearch =
        (name.includes(searchQuery) ||
          description.includes(searchQuery) ||
          nameSearch.includes(searchQuery) ||
          matchesName) &&
        searchQuery !== '';
      instrument.matches_search = matchesSearch;
      return instrument;
    });
  };

  useEffect(() => {
    if (showSearchBar) {
      const searchQuery = searchValue.toLowerCase().trim();
      if (selectedTab === 'want') {
        const updatedList = wantsList.map((instrument) => {
          //Need to match member name
          let memberName = instrument.memberObj?.name;
          if (memberName) {
            memberName = memberName.toLowerCase();
          }
          const description = instrument?.category_description.toLowerCase();
          const matchesSearch =
            (description.includes(searchQuery) ||
              memberName.includes(searchQuery)) &&
            searchQuery !== '';
          instrument.matches_search = matchesSearch;
          return instrument;
        });
        const matchingSearch = updatedList.filter((ins) => ins.matches_search);
        setMatchingSearchInstruments(matchingSearch);
        setWantsList(updatedList);
      } else if (selectedTab === 'have') {
        const updatedCashFlow = checkMatchingSearch(cashFlowList, searchQuery);
        const updatedAssets = checkMatchingSearch(assetList, searchQuery);
        const updatedInsurance = checkMatchingSearch(
          insuranceList,
          searchQuery
        );
        const combinedList = [
          ...updatedCashFlow,
          ...updatedAssets,
          ...updatedInsurance,
        ];
        const matchingSearch = combinedList.filter((ins) => ins.matches_search);
        setMatchingSearchInstruments(matchingSearch);
        setCashFlowList(updatedCashFlow);
        setAssetList(updatedAssets);
        setInsuranceList(updatedInsurance);
      }
    }
  }, [showSearchBar, searchValue]);

  useEffect(() => {
    if (loadedInstruments) {
      if (showSearchBar === false) {
        clearMatchingInstruments();
      }
    }
  }, [loadedInstruments, showSearchBar]);

  useEffect(() => {
    if (targetMapEditError) {
      setIsSavingTargetMap(false);
      setUpdatingTargetMap(false);
      setEditTargetMapError(targetMapEditError.data);
      if (Object.keys(targetMapEditError.data).length > 1) {
        setEditModalError('Please fix errors and save again');
      } else {
        setEditModalError(
          targetMapEditError.data[Object.keys(targetMapEditError.data)[0]]
        );
      }
      dispatch(updateTargetMapStoreValue('targetMapEditError', null));
    }
  }, [targetMapEditError]);

  useEffect(() => {
    if (changedTargetMap) {
      dispatch(getTargetMaps(householdId));
      dispatch(getTargetMapCashFlow(householdId, targetMapId));
    }
  }, [changedTargetMap]);

  useEffect(() => {
    if (cashFlow && cashFlow.code === 200 && selectedTab === 'chart') {
      if (chartValue) {
        chartValue.destroy();
      }
      const sharedData = {
        borderWidth: 0,
        maxBarThickness: 125,
        barPercentage: 0.9,
        categoryPercentage: 0.9,
      };
      const chartOptions = {
        type: 'bar',
        data: {
          labels: cashFlow['time']['year'],
          datasets: [
            {
              label: 'Expected Cash Flow',
              backgroundColor: colors.blue,
              data: cashFlow['totals']['chart']['cashflow'],
              ...sharedData,
            },
            {
              label: 'Available Assets',
              backgroundColor: '#00b050',
              data: cashFlow['totals']['chart']['asset'],
              ...sharedData,
            },
            {
              label: 'Insurance Proceeds',
              backgroundColor: colors.lightBlue,
              data: cashFlow['totals']['chart']['insurance'],
              ...sharedData,
            },
            {
              label: 'Capital Withdrawals',
              backgroundColor: colors.green,
              data: cashFlow['totals']['chart']['capital'],
              ...sharedData,
            },
            {
              label: 'Funding Shortfall',
              backgroundColor: '#e6e6e6',
              data: cashFlow['totals']['chart']['shortfall'],
              ...sharedData,
            },
          ],
        },
        options: {
          animation: {
            duration: 0,
          },
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              labels: {
                boxWidth: 11,
                padding: 20,
              },
              onClick: (e) => {
                if (e && e.hasOwnProperty('native')) {
                  e.native.stopPropagation();
                }
                return;
              },
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              itemSort: function (a, b) {
                return b.datasetIndex - a.datasetIndex;
              },
              filter: function (tooltipItem) {
                return tooltipItem.raw !== null;
              },
              callbacks: {
                label: function (tooltipItem) {
                  var label = tooltipItem?.dataset?.label || '';
                  if (label) {
                    label += ': ';
                  }
                  let value = '';
                  if (value !== null) {
                    value = Math.round(tooltipItem.raw);
                    value = FormatCurrency(value, currency);
                  }
                  return label + value;
                },
              },
            },
          },
          scales: {
            x: {
              gridLines: {
                drawOnChartArea: false,
              },
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              gridLines: {
                drawOnChartArea: false,
              },
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                callback: function (value) {
                  return value.toLocaleString();
                },
              },
            },
          },
          title: {
            display: false,
            text: 'CashFlow',
          },
        },
      };
      const myChartRef = chartRef.current.getContext('2d');
      Chart.defaults.font.family =
        "'Open Sans','Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
      // eslint-disable-next-line no-unused-vars
      let myLineChart = new Chart(myChartRef, chartOptions);
      setChartValue(myLineChart);
    }
  }, [cashFlow, selectedTab]);

  useEffect(() => {
    if (deletedTargetMap) {
      dispatch(getTargetMaps(householdId));
      return navigate(`/household/${householdId}/targetmap`);
    }
  }, [deletedTargetMap]);

  useEffect(() => {
    if (editedTargetMap) {
      setUpdatingTargetMap(true);
      dispatch(getTargetMaps(householdId));
      dispatch(getTargetMapCashFlow(householdId, targetMapId));
      setEditTargetMapError({
        name: false,
        duration: false,
        return_rate: false,
        inflation_rate: false,
      });
      // dispatch(getHouseholdInfo(householdId));
    }
  }, [editedTargetMap]);

  useEffect(() => {
    if (currentTargetMap && currentTargetMap.hasOwnProperty('id')) {
      setTargetMap(currentTargetMap);
      setReturnRate(currentTargetMap.return_rate);
      setInflationRate(currentTargetMap.inflation_rate);
      setDuration(currentTargetMap.duration);
      setIsSavingTargetMap(false);
      setLoadedInstruments(true);
      setEditTargetMapError({
        name: false,
        duration: false,
        return_rate: false,
        inflation_rate: false,
      });
      if (currentTargetMap?.color) {
        setCustomColor(currentTargetMap?.color);
      }
    }
  }, [currentTargetMap]);

  useEffect(() => {
    if (
      currentHousehold &&
      currentHousehold.hasOwnProperty('householdmembers')
    ) {
      if (targetMap && targetMap.hasOwnProperty('wants')) {
        setWantsList(
          targetMap.wants.map((want) => {
            want.memberObj = transformMember(want.member);
            return want;
          })
        );
      }
      if (targetMap && targetMap.hasOwnProperty('incomes')) {
        setCashFlowList(
          targetMap.incomes.map((want) => {
            want.memberObj = transformMember(want.member);
            return want;
          })
        );
      }
      if (targetMap && targetMap.hasOwnProperty('assets')) {
        setAssetList(
          targetMap.assets.map((want) => {
            want.memberObj = transformMember(want.member);
            return want;
          })
        );
      }
      if (targetMap && targetMap.hasOwnProperty('insurances')) {
        const matchedMembers = targetMap.insurances.map((want) => {
          const matched = currentHousehold.instruments.find(
            (instrument) => instrument.id === want.instrument
          );
          if (matched) {
            want.members = matched.members.map((mem) => {
              mem.memberObj = transformMember(mem.id);
              return mem;
            });
          }
          return want;
        });
        setInsuranceList(matchedMembers);
      }
      setUpdatingTargetMap(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHousehold?.id, targetMap]);

  const transformMember = (memberId) => {
    const matchedMember = currentHousehold.householdmembers.find(
      (mem) => mem.id === memberId
    );
    if (matchedMember) {
      matchedMember.name = HtmlToString(matchedMember.name);
    }
    return matchedMember;
  };

  const actionOnClick = (targetMap, action) => {
    if (action === 'edit') {
      setCopiedTargetMap({ ...targetMap });
      return dispatch(toggleShowEditTargetMapModal(true));
    } else if (action === 'notes') {
      setCopiedTargetMap({ ...targetMap });
      return dispatch(toggleShowTargetMapNotes(true));
    } else if (action === 'clone') {
      return dispatch(cloneTargetMap(householdId, targetMapId));
    } else if (action === 'template' && loadedInstruments) {
      const transformedWants = wantsList.map((want) => {
        return {
          name: want.category_description,
          amount: want.amount,
          member: want.member,
          memberObj: want.memberObj,
        };
      });
      //Need to set modal to show and all have all the info needed to display and make API Call
      return dispatch(
        toggleShowTargetMapTemplateModal(true, true, transformedWants)
      );
    } else if (action === 'pdf') {
      return DownloadReport({
        value: 'targetmap',
        childValue: 'targetmaps',
        hasOptions: true,
        children: [{ selected: true, id: targetMapId }],
      });
    } else if (action === 'delete') {
      return dispatch(
        toggleShowDeleteModal(true, 'targetmap', targetMap.name, targetMapId)
      );
    } else if (action === 'search') {
      toggleShowSearchBar();
    } else if (action === 'compare') {
      return navigate(
        `/household/${householdId}/targetmap/compare/${targetMap.id}`
      );
    }
  };

  const updateSelectedTargetMap = (e, key) => {
    return setCopiedTargetMap({
      ...copiedTargetMap,
      [key ? key : e.currentTarget.name]: key ? e : e.currentTarget.value,
    });
  };

  const saveEditedTargetMap = () => {
    if (showingEditTargetMapModal && !isSavingTargetMap) {
      setIsSavingTargetMap(true);
      SendTrackingEvent(invocationId, 'action', 'targetmap_edit', null, {
        targetmap: targetMap.id,
        fields: copiedTargetMap,
        in_compare: inCompareMode,
      });
      dispatch(updateTargetMap(householdId, targetMapId, copiedTargetMap));
    }
  };

  const editTargetMapModalContent = {
    heading: 'Target-Map Settings',
    minWidth: '350px',
    inputs: [
      {
        type: 'text',
        name: 'name',
        maxLength: '100',
        required: true,
        label: 'Target-Map Name',
        placeholder: 'Name',
        value: copiedTargetMap.name,
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.name,
      },
      {
        type: 'number',
        name: 'duration',
        hasSuffix: true,
        suffixValue: 'yrs',
        maxLength: '5',
        required: false,
        label: 'Duration (yrs) before Event Start',
        placeholder: 'Duration (yrs)',
        value: copiedTargetMap.duration,
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.duration,
      },
      {
        type: 'number',
        name: 'return_rate',
        hasSuffix: true,
        maxLength: '5',
        required: true,
        label: 'Expected Net Return on Capital',
        placeholder: '0',
        value: copiedTargetMap.return_rate,
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.return_rate,
      },
      {
        type: 'number',
        hasSuffix: true,
        name: 'inflation_rate',
        maxLength: '5',
        required: true,
        placeholder: '0',
        label: 'Annual Increase of Contributions',
        value: copiedTargetMap.inflation_rate,
        onChange: (e) => updateSelectedTargetMap(e),
        onKeyPress: (e) => DetectEnterKeyPress(e, saveEditedTargetMap),
        hasError: editTargetMapError.inflation_rate,
      },
    ],
    buttons: [
      {
        text: 'Cancel',
        function: () => dispatch(toggleShowEditTargetMapModal(false)),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Update',
        showLoading: isSavingTargetMap,
        loadingText: 'Updating',
        function: () => saveEditedTargetMap(),
        theme: ButtonThemes.primary,
      },
    ],
  };

  const isActiveTab = (tab) => {
    return selectedTab === tab;
  };

  const activeTabStyle = {
    background: 'white',
    boxShadow: boxShadows.boxShadowTargetMapTab,
    fontWeight: fonts.semiBold,
    color: colors.darkGrey,
  };

  const columnHeadings = {
    cashFlowDesired: [
      { text: 'Check', display: false },
      { text: 'Description', display: true },
      { text: 'Member', display: true },
      { text: 'Annual Amount', display: true, align: 'right' },
      { text: 'Start Date', display: true, align: 'center' },
      { text: 'End Date', display: true, align: 'center' },
      { text: 'Start Age', display: true, align: 'center' },
      { text: 'End Age', display: true, align: 'center' },
      { text: 'COLA', display: true, align: 'center' },
      { text: 'Capital to Fund Today', display: true, align: 'right' },
      { text: 'Actions', display: false },
    ],
    cashFlowAvailable: [
      { text: 'Check', display: false },
      { text: 'Reference Name', display: true },
      { text: 'Interested Member', display: true },
      { text: 'Annual Amount', display: true, align: 'right' },
      { text: 'Start Date', display: true, align: 'center' },
      { text: 'End Date', display: true, align: 'center' },
      { text: 'Start Age', display: true, align: 'center' },
      { text: 'End Age', display: true, align: 'center' },
      { text: 'COLA', display: true, align: 'center' },
      { text: 'Lost to Taxes', display: true, align: 'center' },
      { text: 'Present Value Equivalent', display: true, align: 'right' },
      { text: 'Actions', display: false },
    ],
    assets: [
      { text: 'Check', display: false },
      { text: 'Reference Name', display: true },
      { text: 'Owner Member', display: true },
      { text: 'Asset Value', display: true, align: 'right' },
      { text: 'Value Date', display: true, align: 'center' },
      { text: 'Value Age', display: true, align: 'center' },
      { text: 'Lost to Taxes', display: true, align: 'center' },
      { text: 'Present Value Equivalent', display: true, align: 'right' },
      { text: 'Actions', display: false },
    ],
    insurance: [
      { text: 'Check', display: false },
      { text: 'Reference Name', display: true },
      { text: 'Insurance Provider', display: true },
      { text: 'Insured Member', display: true },
      { text: 'Benefit Amount', display: true, align: 'right' },
      { text: 'Start Date', display: true, align: 'center' },
      { text: 'End Date', display: true, align: 'center' },
      { text: 'COLA', display: true, align: 'center' },
      { text: 'Lost to Taxes', display: true, align: 'center' },
      { text: 'Present Value', display: true, align: 'right' },
      { text: 'Actions', display: false },
    ],
  };

  const cashFlowDesiredCloneFunction = (selected) => {
    dispatch(cloneTargetMapWant(householdId, targetMapId, selected.uuid));
  };

  const cashFlowDesiredDeleteFunction = (selected) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'targetmapWant',
        selected.category_description,
        targetMapId,
        selected.id
      )
    );
  };

  const cashFlowDesiredEditFunction = (selected) => {
    setSelectedInstrument(selected);
    dispatch(toggleShowDesiredCashFlowModal(true, true));
  };

  const cashFlowAvailableDeleteFunction = (selected) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'targetmapIncome',
        selected.name,
        targetMapId,
        selected.id
      )
    );
  };

  const cashFlowAvailableEditFunction = (selected) => {
    setSelectedInstrument(selected);
    dispatch(toggleShowCustomCashFlowModal(true, true));
  };

  const assetDeleteFunction = (selected) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'targetmapAsset',
        selected.name,
        targetMapId,
        selected.id
      )
    );
  };

  const assetEditFunction = (selected) => {
    setSelectedInstrument(selected);
    dispatch(toggleShowCustomAssetModal(true, true));
  };

  const insuranceDeleteFunction = (selected) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'targetmapInsurance',
        selected.name,
        targetMapId,
        selected.id
      )
    );
  };

  const insuranceEditFunction = (selected) => {
    setSelectedInstrument(selected);
    dispatch(toggleShowCustomInsuranceModal(true, true));
  };

  const cashFlowOpenModals = (type, selectedId) => {
    if (type === 'wants') {
      const matching = wantsList.find((wants) => wants.id === selectedId);
      if (matching) return cashFlowDesiredEditFunction(matching);
    }
    if (type === 'income') {
      const matching = cashFlowList.find(
        (cashflow) => cashflow.id === selectedId
      );
      if (matching) return cashFlowAvailableEditFunction(matching);
    }
    if (type === 'asset') {
      const matching = assetList.find((asset) => asset.id === selectedId);
      if (matching) return assetEditFunction(matching);
    }
    if (type === 'insurance') {
      const matching = insuranceList.find(
        (insurance) => insurance.id === selectedId
      );
      if (matching) return insuranceEditFunction(matching);
    }
  };

  const tableHeaders = {
    cashFlowDesiredTable: {
      cloneFunction: cashFlowDesiredCloneFunction,
      deleteFunction: cashFlowDesiredDeleteFunction,
      editFunction: cashFlowDesiredEditFunction,
    },
    cashFlowAvailableTable: {
      deleteFunction: cashFlowAvailableDeleteFunction,
      editFunction: cashFlowAvailableEditFunction,
    },
    assetsTable: {
      heading: 'Assets',
      deleteFunction: assetDeleteFunction,
      editFunction: assetEditFunction,
    },
    insuranceTable: {
      heading: 'Insurance',
      deleteFunction: insuranceDeleteFunction,
      editFunction: insuranceEditFunction,
    },
  };

  const addNewDesiredCashFlow = () => {
    setSelectedInstrument(null);
    dispatch(toggleShowDesiredCashFlowModal(true));
  };

  const addNewCustomCashFlow = () => {
    setSelectedInstrument(null);
    dispatch(toggleShowCustomCashFlowModal(true));
  };

  const addNewCustomAsset = () => {
    setSelectedInstrument(null);
    dispatch(toggleShowCustomAssetModal(true));
  };

  const toggleSelected = (item, type) => {
    setUpdatingTargetMap(true);
    SendTrackingEvent(invocationId, 'action', 'targetmap_toggle_select', null, {
      targetmap: targetMap.id,
      instrument_type: type,
      instrument: item.id,
      in_compare: inCompareMode,
    });
    dispatch(toggleIsSelectedTargetMap(householdId, targetMapId, type, item));
  };

  const handleUpdateTargetMap = (key, value) => {
    SendTrackingEvent(invocationId, 'action', 'targetmap_edit', null, {
      targetmap: targetMap.id,
      [key]: value,
      in_compare: inCompareMode,
    });
    dispatch(updateTargetMap(householdId, targetMapId, { [key]: value }));
  };

  const setPercentageBlur = (e, setFunction, key) => {
    if (!updatingTargetMap) {
      let parsed = parseInt(e.currentTarget.value);
      let isNumber = Number.isInteger(parsed);
      const changed = parsed !== currentTargetMap[key];
      if (isNumber && changed) {
        setFunction(e.currentTarget.value);
        setUpdatingTargetMap(true);
        handleUpdateTargetMap(key, parsed);
      } else {
        setFunction(currentTargetMap[key]);
      }
    }
  };

  const setYearBlur = (e, setFunction, key) => {
    if (!updatingTargetMap) {
      let parsed = parseFloat(e.currentTarget.value);
      let greaterThanZero = parsed > 0;
      let lessThanTwenty = parsed <= 75;
      const changed = parsed !== currentTargetMap[key];
      if (!isNaN(parsed) && greaterThanZero && lessThanTwenty && changed) {
        setFunction(e.currentTarget.value);
        setUpdatingTargetMap(true);
        parsed = parsed.toFixed(1);
        handleUpdateTargetMap(key, parsed);
      } else {
        setFunction(currentTargetMap[key]);
      }
    }
  };

  const updatePercentageValue = (isIncrease, setFunction, key, value) => {
    if (!updatingTargetMap) {
      let parsed = parseInt(value);
      let updatedValue;
      if (isIncrease) {
        updatedValue = parsed + 1;
      } else {
        updatedValue = parsed - 1;
      }
      setFunction(updatedValue);
      setUpdatingTargetMap(true);
      dispatch(
        updateTargetMap(householdId, targetMapId, {
          [key]: updatedValue,
        })
      );
    }
  };

  const resetDuration = () => {
    if (!updatingTargetMap) {
      setUpdatingTargetMap(true);
      handleUpdateTargetMap('duration', null);
    }
  };

  const generateSummaryLabelAfterTax = (want, index) => {
    return (
      <SummaryDataRow key={index}>
        <SummaryDataLabel>
          <strong>{want.category_description}:</strong>{' '}
          {want.end_date && 'Starting at '}
          {want.hasOwnProperty('memberObj') && (
            <span>{want?.memberObj?.name}</span>
          )}{' '}
          age {want.start_age} &#40;{FormatCurrency(want.amount, currency)}{' '}
          inflated at {want.inflation_rate}
          %/year{want.end_date && ` for ${want.duration} years`}&#41;
        </SummaryDataLabel>
        <SummaryDataValue
          style={{
            color: IsNegative(want.capital_value) && messageColors.error,
          }}
        >
          {FormatCurrency(Math.round(want.capital_value), currency)}
        </SummaryDataValue>
      </SummaryDataRow>
    );
  };

  const generateSummaryLabelCashFlow = (want, index) => {
    return (
      <SummaryDataRow key={index}>
        <SummaryDataLabel>
          <strong>
            {want.name}: {want.description}*
          </strong>{' '}
          {want.end_date && 'Starting at '}
          {want.hasOwnProperty('memberObj') && (
            <span>{want?.memberObj?.name}</span>
          )}{' '}
          age {want.start_age} &#40;{want.is_future_value && 'FV'}
          {FormatCurrency(want.amount, currency)}
          {want.end_date && ` for ${want.duration} years `} COLA{' '}
          {want.inflation_rate}% less {want.tax_rate}% Tax&#41;
        </SummaryDataLabel>
        <SummaryDataValue
          style={{
            color: IsNegative(want.capital_value) ? messageColors.error : null,
          }}
        >
          {FormatCurrency(Math.round(want.capital_value), currency)}
        </SummaryDataValue>
      </SummaryDataRow>
    );
  };

  const generateSummaryLabelAsset = (want, index) => {
    return (
      <SummaryDataRow key={index}>
        <SummaryDataLabel>
          <strong>
            {want.name}: {want.description}*
          </strong>{' '}
          &#40;{want.is_future_value && 'FV'}
          {FormatCurrency(want.amount, currency)} less {want.tax_rate}% Tax&#41;
        </SummaryDataLabel>
        <SummaryDataValue
          style={{
            color: IsNegative(want.capital_value) ? messageColors.error : null,
          }}
        >
          {FormatCurrency(Math.round(want.capital_value), currency)}
        </SummaryDataValue>
      </SummaryDataRow>
    );
  };

  const generateSummaryLabelInsurance = (want, index) => {
    return (
      <SummaryDataRow key={index}>
        <SummaryDataLabel>
          <strong>
            {want.name}: {want.description}*
          </strong>{' '}
          {want.end_age && 'Starting at '}
          {want.hasOwnProperty('memberObj') && (
            <span>{want?.memberObj?.name}</span>
          )}{' '}
          age {want.start_age} &#40;
          {FormatCurrency(want.amount, currency)}
          {want.end_age &&
            ` until age ${want.end_age} COLA ${want.inflation_rate}% `}{' '}
          less {want.tax_rate}% Tax&#41;
        </SummaryDataLabel>
        <SummaryDataValue
          style={{
            color: IsNegative(want.capital_value) ? messageColors.error : null,
          }}
        >
          {FormatCurrency(Math.round(want.capital_value), currency)}
        </SummaryDataValue>
      </SummaryDataRow>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((res, index) => {
      res.priority = index + 1;
      return res;
    });
    return result;
  };

  const onDragEnd = (result, type) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    let orderList = [];
    let orderFunc;
    switch (type) {
      case 'wants':
        orderList = wantsList;
        orderFunc = setWantsList;
        break;
      case 'haveCashFlow':
        orderList = cashFlowList;
        orderFunc = setCashFlowList;
        type = 'incomes';
        break;
      case 'haveAssets':
        orderList = assetList;
        orderFunc = setAssetList;
        type = 'assets';
        break;
      case 'haveInsurance':
        orderList = insuranceList;
        orderFunc = setInsuranceList;
        type = 'insurances';
        break;
      default:
        orderList = [];
        orderFunc = null;
    }
    const updatedList = reorder(
      orderList,
      result.source.index,
      result.destination.index
    );
    orderFunc(updatedList);
    const listIds = updatedList.map((want) => want.id);
    dispatch(
      updateTargetInstrumentsOrder(householdId, targetMapId, type, listIds)
    );
  };

  const toggleShowSearchBar = () => {
    if (loadedInstruments) {
      setSearchValue('');
      setShowSearchBar(!showSearchBar);
    }
  };

  const closeBulkActionsMenu = () => {
    if (loadedInstruments) {
      setSearchValue('');
      setShowSearchBar(false);
    }
  };

  const clearMatchingInstruments = () => {
    setMatchingSearchInstruments([]);
    setWantsList(SetObjectFieldValue(wantsList, 'matches_search'));
    setCashFlowList(SetObjectFieldValue(cashFlowList, 'matches_search'));
    setAssetList(SetObjectFieldValue(assetList, 'matches_search'));
    setInsuranceList(SetObjectFieldValue(insuranceList, 'matches_search'));
  };

  const BulkActionHotkeys = () => {
    useHotkeys('ctrl+shift+s', () => toggleShowSearchBar());
    useHotkeys('esc', () => closeBulkActionsMenu(true));
    return <></>;
  };

  return (
    <>
      {showingEditTargetMapModal && (
        <SimpleModal
          hide={() =>
            dispatch(toggleShowEditTargetMapModal(!showingEditTargetMapModal))
          }
          content={editTargetMapModalContent}
          error={editModalError}
          currency={currency}
          onEnter={saveEditedTargetMap}
        />
      )}
      {showingDesiredCashFlowModal && (
        <DesiredCashFlowModal
          isNew={isNewDesiredCashFlow}
          hide={() =>
            dispatch(
              toggleShowDesiredCashFlowModal(!showingDesiredCashFlowModal)
            )
          }
          householdId={householdId}
          instrumentInfo={selectedInstrument}
          targetMapId={targetMapId}
          currency={currency}
        />
      )}
      {showingCustomCashFlowModal && (
        <CustomCashFlowModal
          isNew={isNewCustomCashFlow}
          hide={() =>
            dispatch(toggleShowCustomCashFlowModal(!showingCustomCashFlowModal))
          }
          householdId={householdId}
          instrumentInfo={selectedInstrument}
          targetMapId={targetMapId}
          currency={currency}
        />
      )}
      {showingCustomAssetModal && (
        <CustomAssetModal
          isNew={isNewCustomAsset}
          hide={() =>
            dispatch(toggleShowCustomAssetModal(!showingCustomAssetModal))
          }
          householdId={householdId}
          instrumentInfo={selectedInstrument}
          targetMapId={targetMapId}
          currency={currency}
        />
      )}
      {showingCustomInsuranceModal && (
        <CustomInsuranceModal
          hide={() =>
            dispatch(
              toggleShowCustomInsuranceModal(!showingCustomInsuranceModal)
            )
          }
          householdId={householdId}
          instrumentInfo={selectedInstrument}
          targetMapId={targetMapId}
          currency={currency}
        />
      )}
      <PageContainer
        style={{
          height: showingCanvas ? '100%' : `calc(100vh - ${heights.navBar})`,
        }}
      >
        <BulkActions
          show={showSearchBar}
          count={matchingSearchInstruments.length}
          closeMenu={closeBulkActionsMenu}
          search={{
            showingSearch: true,
            searchValue: searchValue,
            searchOnChange: (e) => setSearchValue(e.currentTarget.value),
          }}
        />
        <PageContent>
          <HeaderContent>
            <LeftContent>
              <BackArrowLink
                to={inCompareMode ? '' : `/household/${householdId}/targetmap`}
                onClick={() => (inCompareMode ? navigate(-1) : null)}
              >
                <BackArrow
                  src={arrowRightDark}
                  alt="back"
                  data-image="back-arrow"
                />
              </BackArrowLink>
              {targetMap &&
              targetMap.hasOwnProperty('name') &&
              targetMap.name !== '' ? (
                <SecondaryHeading margin="0">
                  <CircleContainer
                    style={{
                      cursor:
                        targetMap.template === 'custom' ? 'pointer' : null,
                    }}
                  >
                    <svg
                      height="11"
                      width="10"
                      onClick={
                        targetMap.template === 'custom'
                          ? () => {
                              setShowColorPicker(!showColorPicker);
                              setClosedPicker(showColorPicker === true);
                            }
                          : null
                      }
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        fill={
                          targetMap.color ||
                          DetermineTargetMapColor(targetMap.template)
                        }
                      />
                    </svg>
                    {showColorPicker && (
                      <PickerContainer ref={pickerRef}>
                        <ChromePicker
                          color={customColor}
                          onChange={(e) => setCustomColor(e?.hex)}
                          disableAlpha={true}
                        />
                      </PickerContainer>
                    )}
                  </CircleContainer>
                  {HtmlToString(targetMap.name)}
                </SecondaryHeading>
              ) : (
                <LoadingPlaceholder width="300px" height="20px" margin="0" />
              )}
              {updatingTargetMap && (
                <LoadingAnimation
                  dots={true}
                  color={
                    targetMap.color ||
                    DetermineTargetMapColor(targetMap.template)
                  }
                />
              )}
            </LeftContent>
            <RightContent>
              <BulkActions
                show={showSearchBar}
                count={matchingSearchInstruments.length}
                closeMenu={toggleShowSearchBar}
                search={{
                  showingSearch: true,
                  searchValue: searchValue,
                  searchOnChange: (e) => setSearchValue(e.currentTarget.value),
                }}
              />
              {actions.map((action) => {
                return (
                  !action.isHidden &&
                  (!loadedInstruments ? (
                    <ActionItem key={action.id} $placeholder={true}>
                      <LoadingPlaceholder
                        round={true}
                        width="15px"
                        height="15px"
                        margin="0 5px 0 0"
                      />
                      <LoadingPlaceholder
                        width={action.width || '37px'}
                        height="16.5px"
                        margin="0"
                      />
                    </ActionItem>
                  ) : (
                    <ActionItem
                      key={action.id}
                      onClick={() => actionOnClick(targetMap, action.id)}
                    >
                      {showLabel ? (
                        <>
                          <ActionItemIcon
                            $showLabel={true}
                            src={action.icon}
                            alt={action.id}
                            data-image={action.id}
                          />
                          <ActionLabel>{action.label}</ActionLabel>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            title={action.label}
                            position="bottom"
                            trigger="mouseenter click"
                            arrowSize="small"
                            animation="fade"
                            size="small"
                            delay={0}
                            arrow={true}
                            animateFill={false}
                            transitionFlip={false}
                          >
                            <ActionItemIcon
                              $showLabel={false}
                              src={action.icon}
                              alt={action.id}
                            />
                          </Tooltip>
                        </>
                      )}
                    </ActionItem>
                  ))
                );
              })}
            </RightContent>
          </HeaderContent>
          <TargetMapContainer>
            <TopContentContainer id="chart-container">
              <TabGroupContainer>
                {tabOptions.map((tab, index) => {
                  return (
                    !tab.isHidden && (
                      <TabOption
                        key={index}
                        data-key={tab.value}
                        data-active={isActiveTab(tab.value)}
                        style={
                          isActiveTab(tab.value)
                            ? tab.image
                              ? { flex: '1 1 80px', ...activeTabStyle }
                              : { ...activeTabStyle }
                            : tab.image
                              ? { flex: '1 1 80px' }
                              : null
                        }
                        onClick={() => {
                          setShowSearchBar(false);
                          clearMatchingInstruments();
                          setSelectedTab(tab.value);
                        }}
                      >
                        {tab.image ? (
                          <img
                            src={tab.image}
                            alt={tab.value}
                            data-image={tab.value}
                            style={
                              isActiveTab('chart') ? { opacity: '1' } : null
                            }
                          />
                        ) : (
                          tab.label
                        )}
                      </TabOption>
                    )
                  );
                })}
              </TabGroupContainer>
              {selectedTab !== 'chart' ? (
                selectedTab === 'alt_model' ? (
                  <TabContent>
                    <TargetMapAltModelInputs
                      targetMap={currentTargetMap}
                      household={currentHousehold}
                    />
                  </TabContent>
                ) : (
                  <TargetMapPercentBar
                    targetMap={targetMap}
                    currency={currency}
                    returnRate={returnRate}
                    setReturnRate={setReturnRate}
                    inflationRate={inflationRate}
                    setInflationRate={setInflationRate}
                    duration={duration}
                    setDuration={setDuration}
                    setPercentageBlur={setPercentageBlur}
                    updatePercentageValue={updatePercentageValue}
                    setYearBlur={setYearBlur}
                    resetDuration={resetDuration}
                    error={editTargetMapError}
                  />
                )
              ) : (
                <ChartContainer>
                  <canvas id="cashflow-chart" ref={chartRef} />
                </ChartContainer>
              )}
            </TopContentContainer>
            <TabContentContainer>
              {isActiveTab('want') ? (
                <TabContent>
                  <BulkActionHotkeys />
                  <HeaderRow>
                    <HeaderLabel>After-tax Cash Flow Desired*</HeaderLabel>
                    <HeaderValue>
                      Total Capital Needed Today
                      {targetMap.capital_required ||
                      targetMap.capital_required === 0 ? (
                        <span>
                          {FormatCurrency(
                            Math.round(targetMap.capital_required),
                            currency
                          )}
                        </span>
                      ) : (
                        <LoadingPlaceholder width="80px" inline="true" />
                      )}
                    </HeaderValue>
                  </HeaderRow>
                  <TableContainer>
                    <SmallTable
                      columnHeadings={columnHeadings.cashFlowDesired}
                      type={'wants'}
                      items={wantsList}
                      loaded={loadedInstruments}
                      toggleFunction={toggleSelected}
                      cloneFunction={
                        tableHeaders.cashFlowDesiredTable.cloneFunction
                      }
                      deleteFunction={
                        tableHeaders.cashFlowDesiredTable.deleteFunction
                      }
                      editFunction={
                        tableHeaders.cashFlowDesiredTable.editFunction
                      }
                      activeColor={
                        targetMap.color ||
                        DetermineTargetMapColor(targetMap.template)
                      }
                      dragAndDrop={true}
                      onDragEnd={onDragEnd}
                    />
                  </TableContainer>
                  <ButtonRow>
                    <Button
                      text="Add Desired Cash Flow"
                      onClick={() => {
                        addNewDesiredCashFlow();
                      }}
                    />
                  </ButtonRow>
                </TabContent>
              ) : isActiveTab('have') ? (
                <TabContent>
                  <BulkActionHotkeys />
                  <HeaderRow>
                    <HeaderLabel>
                      <svg height="11" width="11">
                        <rect width="11" height="11" fill={colors.lightBlue} />
                      </svg>
                      Cash Flow Sources Available
                    </HeaderLabel>
                    <HeaderValue>
                      Total Capital Available Today &#40;Present Value
                      Equivalent&#41;
                      {targetMap?.capital_available ||
                      targetMap?.capital_available === 0 ? (
                        <span>
                          {FormatCurrency(
                            targetMap.capital_available,
                            currency
                          )}
                        </span>
                      ) : (
                        <LoadingPlaceholder width="80px" inline="true" />
                      )}
                    </HeaderValue>
                  </HeaderRow>
                  <TableContainer>
                    <SmallTable
                      columnHeadings={columnHeadings.cashFlowAvailable}
                      type={'haveCashFlow'}
                      items={cashFlowList}
                      loaded={loadedInstruments}
                      toggleFunction={toggleSelected}
                      deleteFunction={
                        tableHeaders.cashFlowAvailableTable.deleteFunction
                      }
                      editFunction={
                        tableHeaders.cashFlowAvailableTable.editFunction
                      }
                      activeColor={colors.lightBlue}
                      dragAndDrop={true}
                      onDragEnd={onDragEnd}
                    />
                  </TableContainer>
                  <ButtonRow>
                    <Button
                      text="Add Custom Cash Flow"
                      onClick={() => {
                        addNewCustomCashFlow();
                      }}
                    />
                  </ButtonRow>
                  <HeaderRow>
                    <HeaderLabel>
                      <svg height="11" width="11">
                        <rect width="11" height="11" fill={colors.green} />
                      </svg>
                      Assets Available
                    </HeaderLabel>
                  </HeaderRow>
                  <TableContainer>
                    <SmallTable
                      columnHeadings={columnHeadings.assets}
                      type={'haveAssets'}
                      items={assetList}
                      loaded={loadedInstruments}
                      toggleFunction={toggleSelected}
                      deleteFunction={tableHeaders.assetsTable.deleteFunction}
                      editFunction={tableHeaders.assetsTable.editFunction}
                      activeColor={colors.green}
                      dragAndDrop={true}
                      onDragEnd={onDragEnd}
                    />
                  </TableContainer>
                  <ButtonRow>
                    <Button
                      text="Add Custom Asset"
                      onClick={() => {
                        addNewCustomAsset();
                      }}
                    />
                  </ButtonRow>
                  <HeaderRow>
                    <HeaderLabel>
                      <svg height="11" width="11">
                        <rect width="11" height="11" fill={colors.blue} />
                      </svg>
                      Insurance Available
                    </HeaderLabel>
                  </HeaderRow>
                  <TableContainer>
                    <SmallTable
                      columnHeadings={columnHeadings.insurance}
                      type={'haveInsurance'}
                      items={insuranceList}
                      loaded={loadedInstruments}
                      toggleFunction={toggleSelected}
                      deleteFunction={
                        tableHeaders.insuranceTable.deleteFunction
                      }
                      editFunction={tableHeaders.insuranceTable.editFunction}
                      activeColor={colors.blue}
                      dragAndDrop={true}
                      onDragEnd={onDragEnd}
                    />
                  </TableContainer>
                </TabContent>
              ) : isActiveTab('means') ? (
                <TabContent>
                  <SummaryTextContainer>
                    <img
                      src={bullseyePointer}
                      alt="bullseye"
                      data-image="bullseye"
                    />
                    {targetMap.narrative ? (
                      <SummaryText
                        dangerouslySetInnerHTML={{
                          __html: targetMap.narrative,
                        }}
                      />
                    ) : (
                      <SummaryText>
                        Based on the calculation performed, this Target-Map&reg;
                        is
                        <strong> {targetMap.funded}% </strong>
                        funded to meet the capital required to fund loading
                        type. Assuming a hypothetical
                        <strong> {targetMap.return_rate}% </strong>
                        expected net return on capital, the amount of additional
                        capital required to fund this need today is
                        <strong>
                          {' '}
                          {FormatCurrency(
                            Math.abs(targetMap.capital_net || 0),
                            currency
                          )}{' '}
                        </strong>{' '}
                        or
                        <strong>
                          {' '}
                          {FormatCurrency(
                            Math.abs(targetMap.contribution_needed || 0),
                            currency
                          )}{' '}
                        </strong>
                        of monthly contributions inflated by
                        <strong> {targetMap.inflation_rate}% </strong>annually
                        for
                        <strong> {targetMap.duration} years</strong>.
                      </SummaryText>
                    )}
                  </SummaryTextContainer>
                  <MeaningContainer>
                    <LeftContainer>
                      <HeaderRow>
                        <HeaderLabel>
                          Capital Required{' '}
                          <HeaderSubLabel>(Present Value)</HeaderSubLabel>
                        </HeaderLabel>
                        <HeaderValue>
                          <span style={{ color: messageColors.error }}>
                            (
                            {FormatCurrency(
                              Math.round(targetMap.capital_required || 0),
                              currency
                            )}
                            )
                          </span>
                        </HeaderValue>
                      </HeaderRow>
                      <SummaryTableContainer>
                        <SummaryTableHeading>
                          After-tax Cash Flow Desired
                        </SummaryTableHeading>
                        <SummaryDataContainer>
                          {wantsList && loadedInstruments
                            ? wantsList.map((want, index) => {
                                if (want.is_active) {
                                  return generateSummaryLabelAfterTax(
                                    want,
                                    index
                                  );
                                }
                              })
                            : [1, 2, 3, 4, 5].map((item) => {
                                return (
                                  <SummaryDataRow key={item}>
                                    <SummaryDataLabel>
                                      <LoadingPlaceholder width="300px" />
                                    </SummaryDataLabel>
                                    <SummaryDataValue>
                                      <LoadingPlaceholder width="100px" />
                                    </SummaryDataValue>
                                  </SummaryDataRow>
                                );
                              })}
                        </SummaryDataContainer>
                      </SummaryTableContainer>
                    </LeftContainer>
                    <RightContainer>
                      <HeaderRow>
                        <HeaderLabel>
                          Capital Available{' '}
                          <HeaderSubLabel>
                            (Present Value Equivalent)
                          </HeaderSubLabel>
                        </HeaderLabel>
                        <HeaderValue>
                          <span
                            style={{
                              color: IsNegative(targetMap.capital_available)
                                ? messageColors.error
                                : null,
                            }}
                          >
                            {FormatCurrency(
                              Math.round(targetMap.capital_available || 0),
                              currency
                            )}
                          </span>
                        </HeaderValue>
                      </HeaderRow>
                      <SummaryTableContainer>
                        <SummaryTableHeading>
                          <svg height="11" width="11">
                            <rect
                              width="11"
                              height="11"
                              fill={colors.lightBlue}
                            />
                          </svg>
                          Cash Flow Sources Available{' '}
                          <SummaryPercent>
                            {targetMap.income_funded}%
                          </SummaryPercent>
                        </SummaryTableHeading>
                        <SummaryDataContainer>
                          {cashFlowList && loadedInstruments
                            ? cashFlowList.map((want, index) => {
                                if (want.is_active) {
                                  return generateSummaryLabelCashFlow(
                                    want,
                                    index
                                  );
                                }
                              })
                            : [1, 2, 3].map((item) => {
                                return (
                                  <SummaryDataRow key={item}>
                                    <SummaryDataLabel>
                                      <LoadingPlaceholder width="300px" />
                                    </SummaryDataLabel>
                                    <SummaryDataValue>
                                      <LoadingPlaceholder width="100px" />
                                    </SummaryDataValue>
                                  </SummaryDataRow>
                                );
                              })}
                        </SummaryDataContainer>
                      </SummaryTableContainer>
                      <SummaryTableContainer>
                        <SummaryTableHeading>
                          <svg height="11" width="11">
                            <rect width="11" height="11" fill={colors.green} />
                          </svg>
                          Assets Available{' '}
                          <SummaryPercent>
                            {targetMap.asset_funded || 0}%
                          </SummaryPercent>
                        </SummaryTableHeading>
                        <SummaryDataContainer>
                          {assetList && loadedInstruments
                            ? assetList.map((want, index) => {
                                if (want.is_active) {
                                  return generateSummaryLabelAsset(want, index);
                                }
                              })
                            : [1, 2, 3].map((item) => {
                                return (
                                  <SummaryDataRow key={item}>
                                    <SummaryDataLabel>
                                      <LoadingPlaceholder width="300px" />
                                    </SummaryDataLabel>
                                    <SummaryDataValue>
                                      <LoadingPlaceholder width="100px" />
                                    </SummaryDataValue>
                                  </SummaryDataRow>
                                );
                              })}
                        </SummaryDataContainer>
                      </SummaryTableContainer>
                      <SummaryTableContainer>
                        <SummaryTableHeading>
                          <svg height="11" width="11">
                            <rect width="11" height="11" fill={colors.blue} />
                          </svg>
                          Insurance Available{' '}
                          <SummaryPercent>
                            {targetMap.insurance_funded || 0}%
                          </SummaryPercent>
                        </SummaryTableHeading>
                        <SummaryDataContainer>
                          {insuranceList && loadedInstruments
                            ? insuranceList.map((want, index) => {
                                if (want.is_active) {
                                  return generateSummaryLabelInsurance(
                                    want,
                                    index
                                  );
                                }
                              })
                            : [1, 2, 3].map((item) => {
                                return (
                                  <SummaryDataRow key={item}>
                                    <SummaryDataLabel>
                                      <LoadingPlaceholder width="300px" />
                                    </SummaryDataLabel>
                                    <SummaryDataValue>
                                      <LoadingPlaceholder width="100px" />
                                    </SummaryDataValue>
                                  </SummaryDataRow>
                                );
                              })}
                        </SummaryDataContainer>
                      </SummaryTableContainer>
                    </RightContainer>
                  </MeaningContainer>
                </TabContent>
              ) : isActiveTab('chart') ? (
                <TabContent>
                  <CashFlowTable
                    cashFlow={cashFlow}
                    currency={currency}
                    openModal={cashFlowOpenModals}
                  />
                </TabContent>
              ) : isActiveTab('alt_model') ? (
                <TabContent>
                  <TargetMapAltModelOutput />
                </TabContent>
              ) : null}
            </TabContentContainer>
          </TargetMapContainer>
        </PageContent>
      </PageContainer>
    </>
  );
};

const CircleContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 5;
  top: 25px;
  left: 0;
`;

const ChartContainer = styled.div`
  margin: -45px 0 -20px 0;
  margin: 0 auto;
  position: relative;
  height: 22vh;
  width: 85vw;
  max-width: 1300px;
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 20px 0px;
  }
  margin: 25px 0px;
`;

const TopContentContainer = styled.div`
  ${'' /* padding: 20px 0 30px 0; */}
  height: 100%;
  min-height: 145px;
  width: 100%;
  position: sticky;
  top: 0;
  background: white;
  z-index: 3;
`;

const SummaryTableContainer = styled.div`
  margin-bottom: 40px;
`;

const SummaryTableHeading = styled.div`
  border-bottom: 1px solid rgb(225, 225, 225);
  padding-bottom: 8px;
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  svg {
    margin-right: 8px;
  }
`;

const SummaryPercent = styled.div`
  font-weight: ${fonts.semiBold};
  font-size: 15px;
  margin-left: 8px;
`;

const SummaryDataContainer = styled.div``;

const SummaryDataRow = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 8px 0 8px 10px;
  font-size: 12px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  ${LoadingPlaceholder} {
    margin: 0;
  }
`;

const MeaningContainer = styled.div`
  @media ${maxDevice.laptopL} {
    flex-wrap: wrap;
  }
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
`;

const LeftContainer = styled.div`
  @media ${maxDevice.laptopL} {
    flex: 1 1 auto;
    margin-right: 0;
  }
  flex: 1 1 50%;
  margin-right: 25px;
`;

const RightContainer = styled.div`
  @media ${maxDevice.laptopL} {
    flex: 1 1 auto;
    margin-left: 0;
  }
  flex: 1 1 50%;
  margin-left: 25px;
`;

const SummaryDataLabel = styled.div`
  flex: 1 1 auto;
`;

const SummaryDataValue = styled.div`
  flex: 0 0 auto;
  margin-left: 10px;
`;

const TabGroupContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: stretch;
  justify-content: flex-start;
  ${'' /* margin-top: 10px; */}
`;

const HeaderRow = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  display: flex;
  align-content: center;
  align-items: stretch;
  justify-content: flex-start;
  font-size: 15px;
  margin-bottom: 10px;
`;

const HeaderLabel = styled.div`
  flex: 1 1 auto;
  font-weight: ${fonts.semiBold};
  svg {
    margin-right: 8px;
    border-radius: 1px;
  }
`;

const HeaderSubLabel = styled.span`
  font-weight: ${fonts.normal};
  font-size: 12px;
`;

const HeaderValue = styled.div`
  flex: 0 0 auto;
  font-weight: ${fonts.light};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-left: 8px;
    font-weight: ${fonts.semiBold};
  }
`;

const TableContainer = styled.div`
  margin-top: 20px;
  margin-bottom: -30px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-content: center;
  align-items: stretch;
  justify-content: flex-end;
  margin-bottom: 25px;
`;

const TabOption = styled.div`
  @media ${maxDevice.laptop} {
    font-size: 13px;
  }
  @media ${maxDevice.sideNav} {
    font-size: 12px;
    img {
      width: 20px;
    }
  }
  border-right: 1px solid white;
  padding: 10px;
  background: ${colors.lightGrey};
  flex: 1 1 33%;
  font-size: 14.5px;
  cursor: pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: ${colors.paleGrey};
  img {
    max-width: 25px;
    height: 20px;
    opacity: 0.4;
  }
  &:last-child {
    border-right: none;
  }
`;

const TabContent = styled.div`
  @media ${maxDevice.laptop} {
    padding: 20px;
  }
  padding: 39px 50px;
`;

const SummaryTextContainer = styled.div`
  background: ${colors.lighterGrey};
  border: 1px solid ${colors.lightGrey};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  max-width: 1100px;
  padding: 15px 20px;
  border-radius: 3px;
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 30px;
  img {
    @media ${maxDevice.sideNav} {
      display: none;
    }
    width: 45px;
    height: 45px;
  }
`;

const SummaryText = styled.p`
  @media ${maxDevice.tablet} {
    line-height: 20px;
    font-size: 12px;
  }
  @media ${maxDevice.sideNav} {
    margin-left: 0;
  }
  line-height: 24px;
  font-size: 14px;
  margin-left: 25px;
  span,
  strong {
    @media ${maxDevice.tablet} {
      font-size: 13px;
    }

    font-size: 15px;
    font-weight: ${fonts.semiBold};
  }
`;

const TargetMapContainer = styled.div`
  /* height: 100%; */
  display: flex;
  /* position: relative; */
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: flex-start;
`;

const TabContentContainer = styled.div`
  flex: 1 1 auto;
  height: 100%;
  margin: 0px;
`;

const LeftContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  height: 20px;
  ${SecondaryHeading} {
    @media ${maxDevice.mobileL} {
      font-size: 13px;
    }
    svg {
      margin-right: 10px;
    }
  }
`;

const RightContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow: auto;
`;

const BackArrowLink = styled(Link)`
  @media ${maxDevice.sideNav} {
    margin-left: 0px;
  }
  margin-right: 10px;
  margin-left: -15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    img {
      opacity: 0.7;
    }
  }
`;

const BackArrow = styled.img`
  height: 15px;
  width: 15px;
  transform: rotate(180deg);
`;

const ActionItem = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
    margin-top: 10px;
  }
  flex: 0 0 auto;
  font-size: 14px;
  margin: ${(props) => (props.$placeholder ? '0 0 0 15px' : '0 0 0 18px')};
  cursor: ${(props) => (props.$placeholder ? null : 'pointer')};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    opacity: 0.8;
  }
`;

const ActionItemIcon = styled.img`
  flex: 0 0 auto;
  width: ${(props) => (props.$showLabel ? '12px' : '15px')};
  height: ${(props) => (props.$showLabel ? '12px' : '15px')};
  margin-right: ${(props) => (props.$showLabel ? '5px' : '0')};
`;

const ActionLabel = styled.span`
  font-size: 12px;
`;

const HeaderContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 20px 20px;
  }
  margin: 25px 40px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

HouseholdTargetMap.propTypes = {
  showPDF: PropTypes.bool,
};

export default HouseholdTargetMap;
