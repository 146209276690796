import { useState } from 'react';
import styled from 'styled-components';
import { PageHeader, Button, DropZone } from '../../components';
import { Page } from '../../styles/library/layoutStyles';
import { ButtonLink } from '../../styles/library/fontStyles';
import {
  colors,
  inputColors,
  maxDevice,
  buttonColors,
  messageColors,
  fonts,
} from '../../styles/variables';
import { UpdatePageTitle, CloseDropdowns, headerContent } from '../../utils';

const HouseholdImport = () => {
  UpdatePageTitle('Import Household');
  CloseDropdowns();

  const [content, setContent] = useState('');
  const [missingContent, setMissingContent] = useState(false);

  const updateContent = (e) => {
    setContent(e.currentTarget.value);
    setMissingContent(false);
  };

  const importHousehold = () => {
    if (content === '') {
      setMissingContent(true);
    }
  };
  return (
    <Page>
      <PageHeader content={headerContent.import} />
      <PageContent>
        <TextAreaLabel>Upload CSV File Here</TextAreaLabel>
        <UploadFileContainer>
          <DropZone
            type="file"
            accepted={{
              'text/csv': ['.csv'],
              'application/vnd.ms-excel': ['.xls'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
            }}
            // accepted="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            subtext="Max File Size: 2MB CSV or XLS"
          />
        </UploadFileContainer>
        <MiddleText>OR</MiddleText>
        <TextAreaLabel>Enter Data Here</TextAreaLabel>
        <TextInputContainer>
          <TextArea
            value={content}
            onChange={(e) => updateContent(e)}
            hasError={missingContent}
          />
        </TextInputContainer>
        <ButtonRowContainer>
          <Button text="Import Household" onClick={importHousehold} />
          <ButtonLink to="/household/new">Cancel</ButtonLink>
        </ButtonRowContainer>
      </PageContent>
    </Page>
  );
};

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 10px 20px;
  }
  margin: 25px 40px;
  max-width: 900px;
`;

const TextInputContainer = styled.div`
  @media ${maxDevice.sideNav} {
    input {
      padding: 10.5px 8px;
      font-size: 10px;
      margin: 0 5px;
    }
  }
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
  input {
    flex: 1 1 auto;
    margin: 0 8px;
    min-width: 30px;
  }
`;

const UploadFileContainer = styled.div`
  margin-bottom: 15px;
`;

const MiddleText = styled.p`
  color: ${colors.paleGrey};
  font-size: 16px;
  margin: 20px 0;
  font-weight: ${fonts.semiBold};
`;

const TextAreaLabel = styled.p`
  color: ${colors.darkGrey};
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: ${fonts.semiBold};
`;

const TextArea = styled.textarea`
  border: 1px solid ${inputColors.border};
  color: ${colors.darkGrey};
  resize: none;
  font-size: 14px;
  border-radius: 6px;
  height: 100%;
  max-height: 500px;
  min-height: 300px;
  padding: 15px 20px;
  ${({ hasError }) =>
    hasError &&
    `
    border: 1px solid ${messageColors.error}
  `}
`;

const ButtonRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  text-align: right;
  margin-top: 20px;
  button {
    margin-left: 15px;
  }
  ${ButtonLink} {
    margin-left: 10px;
    background: ${buttonColors.inactive};
  }
`;

export default HouseholdImport;
