import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button, TypeaheadSearch } from '..';
import { updateAdvisors } from '../../store/actions';
import { timesWhite, trashDark, checkCircle } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { ButtonThemes } from '../../styles/themes';
import { colors, fonts } from '../../styles/variables';
import {
  // UseOutsideClick,
  AdvisorSearch,
} from '../../utils';

const AdvisorModal = ({ hide, isNew }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { currentHousehold } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
  }));
  const [advisorList, setAdvisorList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (currentHousehold.advisors) {
      setAdvisorList(currentHousehold.advisors);
    }
  }, [currentHousehold.advisors]);

  const loadOptions = (input) => {
    if (input.length > 2) {
      return AdvisorSearch(input, currentHousehold.id);
    }
  };

  const handleChange = (value, { action }) => {
    setSearchValue(value);
    if (action === 'menu-close') {
      setSearchValue('');
    }
    // if (action === 'input-change') {
    //   dispatch(searchAdvisors(currentHousehold.id, value));
    // }
  };

  const handleSelectChange = (value) => {
    setSelectedValue('');
    if (advisorList.some((advisor) => advisor.id === value.value)) {
      return;
    }
    if (value && value.hasOwnProperty('value')) {
      const newAdvisor = {
        id: value.value,
        name: value.name,
        customer_name: value.customer_name,
        type: 'subadvisor',
      };
      setAdvisorList([...advisorList, newAdvisor]);
    }
  };

  const deleteAdvisor = (selectedAdvisor) => {
    return setAdvisorList(
      advisorList.filter((advisor) => advisor.id !== selectedAdvisor.id)
    );
  };

  const saveAdvisors = () => {
    const subadvisors = advisorList.filter(
      (advisor) => advisor.type === 'subadvisor'
    );
    setButtonLoading(true);
    return dispatch(
      updateAdvisors(
        currentHousehold.id,
        subadvisors.map((advisor) => advisor.id)
      )
    );
  };

  const generateAdvisorType = (type) => {
    switch (type) {
      case 'lead':
        return 'Lead Advisor';
      case 'subadvisor':
        return 'Subadvisor';
      default:
        return type;
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Add Advisor</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <ModalTextInfo>
              Search for the advisors associated with this household.
            </ModalTextInfo>
            <TypeaheadSearch
              showSearchIcon={true}
              cacheOptions={false}
              largeIcon={false}
              value={selectedValue}
              onSelectChange={(e) => handleSelectChange(e)}
              inputValue={searchValue}
              placeholder={'Search for advisors by last name or email'}
              loadOptions={loadOptions}
              onChange={(e, action) => handleChange(e, action)}
            />
            <SubHeader>Current Advisors</SubHeader>
            <AdvisorListContainer>
              {advisorList.map((advisor, index) => {
                return (
                  <AdvisorContainer
                    key={index}
                    style={
                      advisor.type === 'lead'
                        ? { fontWeight: fonts.semiBold }
                        : null
                    }
                  >
                    <AdvisorName>{advisor.name}</AdvisorName>
                    <AdvisorCustomer>{advisor.customer_name}</AdvisorCustomer>
                    <AdvisorType>
                      {generateAdvisorType(advisor.type)}
                    </AdvisorType>
                    {advisor.type !== 'lead' ? (
                      <DeleteIcon
                        src={trashDark}
                        alt="delete"
                        id="delete-icon"
                        data-image={`delete-${index}`}
                        onClick={() => deleteAdvisor(advisor)}
                      />
                    ) : (
                      <LeadIcon
                        src={checkCircle}
                        alt="lead"
                        data-image={`lead-advisor`}
                      />
                    )}
                  </AdvisorContainer>
                );
              })}
            </AdvisorListContainer>
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={isNew ? 'Save' : 'Update'}
                  onClick={() => saveAdvisors()}
                  showLoading={buttonLoading}
                  loadingText={isNew ? 'Saving' : 'Updating'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const ModalTextInfo = styled.p`
  font-size: 13px;
  margin-bottom: 15px;
`;

const SubHeader = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  margin: 15px 0 10px 0;
`;

const AdvisorListContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

const AdvisorContainer = styled.div`
  margin-bottom: 10px;
  flex: 1 1 auto;
  text-align: left;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  padding: 8px;
  background: ${colors.lighterGrey};
`;

const AdvisorName = styled.p`
  flex: 1 1 80px;
  padding-right: 10px;
`;

const AdvisorCustomer = styled.p`
  flex: 1 1 80px;
  padding-right: 10px;
`;

const AdvisorType = styled.p`
  flex: 1 1 80px;
  text-align: right;
  padding-right: 10px;
  width: 80px;
  max-width: 80px;
`;

const DeleteIcon = styled.img`
  width: 15px;
  height: 15px;
  flex: 0 0 auto;
  cursor: pointer;
`;

const LeadIcon = styled.img`
  width: 15px;
  height: 15px;
  flex: 0 0 auto;
`;

AdvisorModal.propTypes = {
  hide: PropTypes.func,
  isNew: PropTypes.bool,
};

export default AdvisorModal;
