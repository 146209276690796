import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Banner as BannerContainer } from '../styles/globalStyle';

const Banner = ({ isBilling = false, externalLink = '', link = '' }) => {
  return isBilling ? (
    <BannerContainer>
      Your subscription is expired • <Link to={link}>Resubscribe now</Link>
    </BannerContainer>
  ) : (
    <BannerContainer>
      Your subscription is expired. Ask an{' '}
      <Link to={link}>account manager</Link> to resubscribe • Questions?{' '}
      <a href={externalLink} rel="noopener noreferrer" target="_blank">
        Contact support
      </a>
    </BannerContainer>
  );
};

Banner.propTypes = {
  isBilling: PropTypes.bool,
  link: PropTypes.string,
  externalLink: PropTypes.string,
};

export default Banner;
