/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { BackgroundPage } from '../../../styles/library/layoutStyles';
import {
  mapWhite,
  addHouseWhite,
  importWhite,
  uploadWhite,
} from '../../../assets';
import {
  colors,
  fonts,
  boxShadows,
  maxDevice,
} from '../../../styles/variables';
import { SendTrackingEvent } from '../../../utils';
import { PrimaryHeading } from '../../../styles/library/fontStyles';

const HouseholdNewContainer = () => {
  const {
    showDiscovery,
    showIntegrations,
    showHouseholdImport,
    addHouseholdBackground,
    invocationId,
  } = useSelector((state) => ({
    showDiscovery: state.user.showDiscovery,
    showIntegrations: state.user.showIntegrations,
    showHouseholdImport: state.user.showHouseholdImport,
    addHouseholdBackground: state.configs.addHouseholdBackground,
    invocationId: state.configs.invocationId,
  }));
  const [cards, setCards] = useState([
    {
      name: 'Manually',
      color: colors.lightBlue,
      icon: addHouseWhite,
      keyword: 'manual',
      text: 'Select this option to add a new household by entering the required data yourself.',
      buttonText: 'Get Started',
      to: '/household/new/manual',
    },
  ]);
  const [hasBackground, setHasBackground] = useState(false);
  const [backgroundTheme, setBackgroundTheme] = useState({
    backgroundImage: null,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundAttachment: 'fixed',
    opacity: 0.9,
  });

  useEffect(() => {
    if (addHouseholdBackground) {
      setBackgroundTheme({
        ...backgroundTheme,
        backgroundImage: addHouseholdBackground,
      });
      setHasBackground(true);
    }
  }, [addHouseholdBackground]);

  const discoveryCard = {
    name: 'Discovery',
    color: colors.green,
    icon: mapWhite,
    keyword: 'discovery',

    text: 'Select this option to add a new household via the Discovery Interview process.',
    buttonText: 'Get Started',
    to: '/discovery',
  };

  const integrationsCard = {
    name: 'Integrations',
    color: colors.orange,
    icon: importWhite,
    keyword: 'integrations',
    // disabled: true,
    text: 'Select this option to add a new household from an available integration partner.',
    buttonText: 'Get Started',
    to: '/household/integrations',
  };

  const importCard = {
    name: 'Import',
    color: colors.pink,
    icon: uploadWhite,
    keyword: 'loader',
    disabled: true,
    text: 'Select this option to add a new household via csv import.',
    buttonText: 'Coming Soon',
    to: '/household/import',
  };

  useEffect(() => {
    if (showDiscovery !== undefined) {
      let updatedCards = cards;
      if (showDiscovery) {
        updatedCards.push(discoveryCard);
      }
      if (showIntegrations) {
        updatedCards.push(integrationsCard);
      }
      if (showHouseholdImport) {
        updatedCards.push(importCard);
      }
      setCards(updatedCards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDiscovery]);

  const determineCount = () => {
    if (cards.length !== 0) {
      return cards.length;
    } else {
      return '';
    }
  };
  const determinePlural = () => {
    if (cards.length !== 1) {
      return 's';
    } else {
      return '';
    }
  };

  const onLinkClick = (keyword) => {
    SendTrackingEvent(invocationId, 'click', keyword, 'new_household');
  };

  return (
    <ThemeProvider theme={{ ...backgroundTheme, hasBackground: hasBackground }}>
      <BackgroundPage>
        <ColumnContainerLayout>
          <FirstRowContainer>
            <PrimaryHeading>
              {determineCount()} Way{determinePlural()} to Add a New Household
            </PrimaryHeading>
            <SubContent>
              Select one of these methods to easily add a new household in
              Asset-Map.
            </SubContent>
          </FirstRowContainer>
          <SecondRowContainer>
            {cards.map((card, index) => {
              return (
                <CardContainer
                  key={index}
                  onClick={() => onLinkClick(card.keyword)}
                  to={card.disabled ? '/household/new' : card.to}
                  style={{
                    opacity: card.disabled ? 0.5 : null,
                    cursor: card.disabled ? 'not-allowed' : null,
                    backgroundImage: card.disabled
                      ? `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%238a888e' fill-opacity='0.3' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E")`
                      : null,
                  }}
                >
                  <CardContent>
                    <div style={{ background: card.color }}>
                      <img
                        src={card.icon}
                        alt={card.name}
                        data-image={card.name}
                      />
                    </div>
                    <h3>{card.name}</h3>
                    <p>{card.text}</p>
                  </CardContent>
                  <CardButton style={{ background: card.color }}>
                    {card.buttonText}
                  </CardButton>
                </CardContainer>
              );
            })}
          </SecondRowContainer>
        </ColumnContainerLayout>
      </BackgroundPage>
    </ThemeProvider>
  );
};

const ColumnContainerLayout = styled.div`
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FirstRowContainer = styled.div`
  @media ${maxDevice.mobileL} {
    margin-top: 10px;
  }
  flex: 0 1 auto;
  text-align: center;
  max-width: 500px;
  margin: 40px 30px 15px 30px;
`;

const SubContent = styled.p`
  @media ${maxDevice.mobileL} {
    font-size: 11px;
  }
  font-size: 12px;
  /* color: ${(props) =>
    props.theme.hasBackground ? 'white' : colors.paleGrey}; */
  color: ${colors.paleGrey};
  /* color: ${colors.white}; */
`;

const SecondRowContainer = styled.div`
  @media ${maxDevice.tablet} {
    margin-top: 20px;
  }
  @media ${maxDevice.mobileL} {
    padding: 0px;
  }
  flex: 1 1 auto;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  width: 100%;
`;

const CardContainer = styled(Link)`
  @media ${maxDevice.tablet} {
    margin: 10px 20px;
  }
  box-shadow: ${(props) =>
    props.theme.hasBackground
      ? boxShadows.boxShadowDropdown
      : boxShadows.boxShadowSoft};
  /* box-shadow: ${boxShadows.boxShadowSoft}; */
  margin: 10px 12px;
  border-radius: 0 0 6px 6px;
  max-width: 300px;
  min-width: 250px;
  flex: 1 1 auto;
  color: ${colors.darkGrey};
  background: white;
  &:hover {
    box-shadow: ${(props) =>
      props.theme.hasBackground
        ? boxShadows.boxShadowDropdown
        : boxShadows.boxShadow};
    /* box-shadow: ${boxShadows.boxShadow}; */
    transform: scale(1.01);
  }
`;

const CardContent = styled.div`
  @media ${maxDevice.mobileL} {
    padding: 10px 10px 15px 10px;
  }
  padding: 15px;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
  div {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }
  img {
    width: 40px;
    height: 40px;
    padding: 20px;
    flex: 1 1 auto;
  }
  h3 {
    font-weight: ${fonts.semiBold};
    font-size: 18px;
  }
  p {
    margin: 10px 0 10px 0;
    font-size: 12px;
    color: ${colors.paleGrey};
  }
`;

const CardButton = styled.div`
  padding: 15px 20px;
  text-align: center;
  color: ${colors.white};
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  border-radius: 0 0 6px 6px;
`;

export default HouseholdNewContainer;
