/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Routes,
  useNavigate,
  useLocation,
  Route,
  Navigate,
} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';
import { PrivateRoute, PermissionRoute, HouseholdPermissionRoute } from './';
import {
  Auth,
  ResetPassword,
  HouseholdImport,
  HouseholdNewManual,
  HouseholdNew,
  Household,
  Integration,
  Partners,
  Discovery,
  Notifications,
  Dashboard,
  HouseholdIndex,
  Preferences,
  KeyFinancialFigures,
  TargetMapPreferences,
  Terms,
  ManagerPreferences,
  ManagerAtlas,
  Billing,
  StyleGuide,
  MemberDeletedHouseholds,
  ManagerDeletedHouseholds,
  HouseholdHome,
  HouseholdMembers,
  HouseholdReports,
  HouseholdSurveys,
  HouseholdInfo,
} from '../pages';
import {
  NavbarContainer,
  PasswordRequestedResetModal,
  HouseholdNewIntegrations,
  HouseholdAssetMap,
  HouseholdFinancials,
  HouseholdTargetMap,
  HouseholdTargetMaps,
  HouseholdNotes,
  HouseholdBalanceSheet,
  HouseholdPolicySummary,
  HouseholdKeyFinancials,
  HouseholdBeneficiaries,
  HouseholdSignals,
  HouseholdAnalytics,
  HouseholdLoader,
  HouseholdMilestones,
  HouseholdTargetMapsCompare,
  HouseholdMembersList,
  HouseholdFamilyMap,
} from '../containers';
import {
  MarkerCanvas,
  ColorPickerModal,
  Banner,
  DelegateModal,
  AuthFactorModal,
} from '../components';
import {
  setInvocationId,
  deleteCanvas,
  getUnreadCount,
  togglePalette,
  toggleShowDelegateModal,
  getUserInfo,
  resetAuthFactors,
  disableAuthFactor,
  getTermsAndConditions,
} from '../store/actions';
import { RouterOutlet, IntercomSpacer } from '../styles/globalStyle';
import { GuessTimeZone, SendTrackingEvent, authFactorOptions } from '../utils';
import { CONSUMER_BASENAME as consumerBasename } from '../utils/constants';

/*
  Router used for all top level routes, rendered by App.jsx
  - Tracking, Intercom and Google Analytics initialized here
  - Marker canvas and palette modal are rendered here
  - Delegate modal is also shown is user is delegate
  - All routes go through here, household routes get sent to HouseholdRouter from Household.jsx
*/
const AppRouter = () => {
  const {
    loggedIn,
    isConsumer,
    isManager,
    isBilling,
    isDelegate,
    isImpersonating,
    isExpired,
    hasAtlas,
    googleAnalyticsId,
    environment,
    user,
    showSetupMFA,
    showAuthCode,
    showingPalette,
    showingCanvas,
    showDelegateModal,
    justChangedRole,
    invocationId,
    passwordChangeRequested,
    passwordChangeRequired,
    authFactorAdded,
    authFactorReturned,
    authCodeEnabled,
    authCodeDisabled,
    showCodeSentScreen,
    showDiscovery,
    userRequiresTerms,
    hasHouseholdPermission,
    showTargetMaps,
    hasTargetMapCompare,
    showSurveys,
    showHouseholdSignals,
    showMilestones,
    showTransferBoB,
    hasFamilyMap,
  } = useSelector((state) => ({
    loggedIn: state.user.loggedIn,
    isConsumer: state.user.isConsumer,
    isManager: state.user.isManager,
    isBilling: state.user.isBilling,
    isDelegate: state.user.isDelegate,
    isImpersonating: state.user.isImpersonating,
    isExpired: state.user.isExpired,
    hasAtlas: state.user.hasAtlas,
    googleAnalyticsId: state.configs.googleAnalyticsId,
    environment: state.configs.environment,
    user: state.user.user,
    showSetupMFA: state.user.showSetupMFA,
    showAuthCode: state.user.showAuthCode,
    showingPalette: state.marker.showingPalette,
    showingCanvas: state.marker.showingCanvas,
    showDelegateModal: state.user.showDelegateModal,
    justChangedRole: state.user.justChangedRole,
    invocationId: state.configs.invocationId,
    passwordChangeRequested: state?.user?.user?.password_change_requested,
    passwordChangeRequired: state?.user?.password_change_required,
    authFactorAdded: state.user.authFactorAdded,
    authFactorReturned: state.user.authFactorReturned,
    authCodeEnabled: state.user.authCodeEnabled,
    authCodeDisabled: state.user.authCodeDisabled,
    showCodeSentScreen: state.user.showCodeSentScreen,
    showDiscovery: state.user.showDiscovery,
    userRequiresTerms: state.user.userRequiresTerms,
    hasHouseholdPermission: state.households.hasHouseholdPermission,
    showTargetMaps: state.user.showTargetMaps,
    hasTargetMapCompare: state.user.hasTargetMapCompare,
    showSurveys: state.user.showSurveys,
    showHouseholdSignals: state.user.showHouseholdSignals,
    showMilestones: state.user.showMilestones,
    showTransferBoB: state.user.showTransferBoB,
    hasFamilyMap: state.user.hasFamilyMap,
  }));
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const [reactGALoaded, setReactGALoaded] = useState(false);
  const [authScreenValue, setAuthScreenValue] = useState('setup');
  const [selectedAuthMethod, setSelectedAuthMethod] = useState();

  //HOOK TO SET INVOCATION ID USED FOR ALL TRACKING EVENTS
  useEffect(() => {
    const ampux_invocation_id = uuidv4();
    dispatch(setInvocationId(ampux_invocation_id));
    dispatch(getTermsAndConditions());
  }, []);

  useEffect(() => {
    if (userRequiresTerms === true) {
      return navigate(`/member/terms`);
    }
  }, [userRequiresTerms, pathname]);

  //CHECKS IF USER IS ONLY CONSUMER TYPE - IF THEY ARE THEY GET TAKEN TO CONSUMER ROUTER
  useEffect(() => {
    if (loggedIn) {
      if (isConsumer) {
        window.location = `${window.location.origin}/${consumerBasename}/dashboard`;
      }
    }
  }, [isConsumer, loggedIn]);

  //ONCE INVOCATION ID IS SET A SESSION TRACKING CALL IS SENT
  useEffect(() => {
    if (invocationId) {
      const object = {
        user_agent: navigator.userAgent,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        screen_width_avail: window.screen.availWidth,
        screen_height_avail: window.screen.availHeight,
        screen_width_inner: window.innerWidth,
        screen_height_inner: window.innerHeight,
        screen_width_outer: window.outerWidth,
        screen_height_outer: window.outerHeight,
        tz_name:
          Intl.DateTimeFormat().resolvedOptions().timeZone || GuessTimeZone(),
        tz_offset: new Date().getTimezoneOffset(),
      };
      SendTrackingEvent(
        invocationId,
        'action',
        'invocation',
        'session',
        object
      );
    }
  }, [invocationId]);

  //CHECKS IF INTERCOM IS LOADED AND BLOCKS ON CERTAIN ROUTES
  //SENDS TRACKING EVENT FOR PAGE DURING ANY ROUTE CHANGE
  useEffect(() => {
    if (window.hasOwnProperty('Intercom')) {
      const splitPathName = pathname.split('/');
      const blockedRoutes = ['assetmap', 'targetmap', 'households', 'map'];
      const blockedRoute = splitPathName.some((r) => blockedRoutes.includes(r));
      window.Intercom('update', {
        hide_default_launcher: blockedRoute,
      });
    }
    if (invocationId && loggedIn) {
      try {
        SendTrackingEvent(invocationId, 'page');
      } catch (e) {
        console.log(e);
      }
    }
    if (reactGALoaded) {
      try {
        ReactGA.set({ page: location.pathname });
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
        // ReactGA.pageview(location.pathname);
      } catch (e) {
        console.log(e);
      }
    }
    if (showingCanvas) {
      dispatch(deleteCanvas());
    }
    if (loggedIn) {
      dispatch(getUnreadCount());
    }
  }, [location, loggedIn, reactGALoaded]);

  //IF USER CHANGES ROLE WE DEFAULT THEM TO DASHBOARD
  useEffect(() => {
    if (justChangedRole) {
      return navigate(`/member/dashboard`);
    }
  }, [justChangedRole]);

  //INIT GOOGLE ANALYTICS CALL
  useEffect(() => {
    if (googleAnalyticsId && user?.email && environment) {
      setReactGALoaded(true);
      ReactGA.initialize(googleAnalyticsId);
      // const ga = ReactGA.ga();
      // ga('require', 'displayFeatures');
      ReactGA.set({
        dimension1: environment,
        dimension2: 'true',
        '&uid': user.email,
        page: location.pathname,
      });
      ReactGA.send({ hitType: 'pageview' });
    }
  }, [googleAnalyticsId, user, environment]);

  //FUNCTION TO DETERMINE WHICH ROUTES DON'T NEED EXTRA PADDING TO ACCOMMODATE INTERCOM CHAT BUBBLE
  const hideIntercomSpacer = () => {
    const splitPathName = pathname.split('/');
    if (splitPathName.includes('targetmap')) {
      return true;
    } else {
      return [
        'assetmap',
        'household/new',
        'member/households',
        'manager/team',
        'member/preferences',
        '/summary',
        'dashboard',
        'auth',
        'manual',
        'analytics',
      ].some((path) => pathname.endsWith(path));
    }
  };

  //DETERMINES IF NON DISMISSIBLE DELEGATE MODAL SHOULD SHOW
  const determineShowDelegateModal = () => {
    if (!loggedIn) {
      return false;
    }
    if (isDelegate) {
      const pathNames = pathname.split('/');
      if (pathNames.includes('preferences') || pathNames.includes('terms')) {
        return false;
      } else {
        return true;
      }
    }
    return showDelegateModal;
  };

  //DETERMINES IF PASSWORD CHANGE MODAL SHOULD SHOW
  const determineShowPasswordChangeModal = () => {
    if (!loggedIn) {
      return false;
    }
    if (passwordChangeRequested) {
      return passwordChangeRequested;
    } else if (passwordChangeRequired) {
      return passwordChangeRequired;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!showSetupMFA) {
      setAuthScreenValue('setup');
    }
  }, [showSetupMFA]);

  //HOOK RUNS WHEN AN AUTH FACTOR (EMAIL/PHONE) HAS BEEN ADDED BUT NOT CONFIRMED
  useEffect(() => {
    if ((authFactorAdded || showAuthCode) && showSetupMFA) {
      setAuthScreenValue('code');
      dispatch(resetAuthFactors());
    }
  }, [authFactorAdded, showAuthCode]);

  //HOOK RUNS WHEN MFA HAS BEEN SET UP SUCCESSFULLY
  useEffect(() => {
    if (authCodeEnabled && showSetupMFA) {
      dispatch(getUserInfo());
      dispatch(resetAuthFactors());
    }
  }, [authCodeEnabled]);

  //HOOK RUNS WHEN AUTH FACTOR IS DISABLED/REMOVED
  useEffect(() => {
    if (authCodeDisabled) {
      dispatch(getUserInfo());
      dispatch(resetAuthFactors());
    }
  }, [authCodeDisabled]);

  //HOOK RUNS WHEN USER REQUESTS NEW CODE AND IT HAS BEEN SENT
  useEffect(() => {
    if (showCodeSentScreen && showSetupMFA) {
      setAuthScreenValue('resent');
      dispatch(resetAuthFactors());
    }
  }, [showCodeSentScreen]);

  //IF A NEW AUTH FACTOR HAS BEEN CREATED IT WILL UPDATE THE LOCAL OBJECT TO MATCH RETURNED DATA
  useEffect(() => {
    if (authFactorReturned) {
      let matched = {};
      if (authFactorReturned?.factor_type === 'email') {
        matched = authFactorOptions.find((method) => method.type === 'email');
      }
      if (authFactorReturned?.factor_type === 'sms') {
        matched = authFactorOptions.find((method) => method.type === 'phone');
      }
      setSelectedAuthMethod({
        ...authFactorReturned,
        ...matched,
      });
    }
  }, [authFactorReturned]);

  //FUNCTION WHEN USER CLICKS ON WHICH MFA METHOD TO SETUP
  const selectMFAMethod = (option) => {
    setSelectedAuthMethod(option);
    setAuthScreenValue('add');
  };

  //FUNCTION FOR WHEN USER CLICKS WRONG EMAIL/PHONE
  const removeAuthMethod = (option) => {
    setSelectedAuthMethod(option);
    dispatch(
      disableAuthFactor(selectedAuthMethod.id, selectedAuthMethod.label, false)
    );
  };

  const RedirectRoute = () => {
    return loggedIn ? (
      <Navigate to="member/dashboard" />
    ) : (
      <Navigate to="auth" />
    );
  };

  const RedirectHouseholdRoute = () => {
    return loggedIn ? <Navigate to="summary" /> : <Navigate to="/" />;
  };

  return (
    <>
      <NavbarContainer isConsumer={isConsumer} />
      <RouterOutlet id="outlet">
        <MarkerCanvas />
        {showingPalette && (
          <ColorPickerModal
            hide={() => dispatch(togglePalette(!showingPalette))}
          />
        )}
        {determineShowPasswordChangeModal() && <PasswordRequestedResetModal />}
        {loggedIn &&
          isExpired &&
          (isBilling ? (
            <Banner
              isBilling={true}
              link={'/manager/billing?panel=subscription'}
            />
          ) : (
            <Banner
              isBilling={false}
              link={'/member/preferences'}
              externalLink={'https://usersupport.asset-map.com/en/'}
            />
          ))}
        {determineShowDelegateModal() && (
          <DelegateModal
            canHide={(isImpersonating && !isDelegate) || userRequiresTerms}
            hide={() => dispatch(toggleShowDelegateModal(false))}
          />
        )}
        {showSetupMFA && !determineShowPasswordChangeModal() && (
          <AuthFactorModal
            noHide={true}
            screen={authScreenValue}
            setAuthScreenValue={setAuthScreenValue}
            authFactorOptions={authFactorOptions}
            type={selectedAuthMethod?.type}
            selectedOption={selectedAuthMethod}
            selectMFAMethod={selectMFAMethod}
            setScreenValue={setAuthScreenValue}
            removeAuthMethod={removeAuthMethod}
          />
        )}
        <Routes>
          <Route exact path="/" element={<RedirectRoute />} />
          {!loggedIn && <Route path="auth" element={<Auth />} />}
          <Route path="password-reset" element={<ResetPassword />} />
          <Route
            path="household/import"
            element={
              <PrivateRoute>
                <HouseholdImport />
              </PrivateRoute>
            }
          />
          <Route
            path="household/new/manual"
            element={
              <PrivateRoute>
                <HouseholdNewManual />
              </PrivateRoute>
            }
          />
          <Route
            path="household/integrations"
            element={
              <PrivateRoute>
                <HouseholdNewIntegrations />
              </PrivateRoute>
            }
          />
          <Route
            path="household/new"
            element={
              <PrivateRoute>
                <HouseholdNew />
              </PrivateRoute>
            }
          />
          <Route
            path="integration"
            element={
              <PrivateRoute>
                <Integration />
              </PrivateRoute>
            }
          />
          <Route
            path="partners"
            element={
              <PrivateRoute>
                <Partners />
              </PrivateRoute>
            }
          />
          <Route
            path="discovery"
            element={
              <PermissionRoute role={showDiscovery}>
                <Discovery />
              </PermissionRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />
          <Route
            path="member/dashboard"
            element={
              <PrivateRoute checkIsDelegate={false}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="member/households"
            element={
              <PrivateRoute>
                <HouseholdIndex />
              </PrivateRoute>
            }
          />
          <Route
            path="member/preferences"
            element={
              <PrivateRoute checkIsDelegate={false}>
                <Preferences />
              </PrivateRoute>
            }
          />
          <Route
            path="member/kff"
            element={
              <PrivateRoute>
                <KeyFinancialFigures />
              </PrivateRoute>
            }
          />
          <Route
            path="member/tmpref"
            element={
              <PrivateRoute>
                <TargetMapPreferences />
              </PrivateRoute>
            }
          />
          <Route
            path="member/terms"
            element={
              <PrivateRoute checkIsDelegate={false}>
                <Terms />
              </PrivateRoute>
            }
          />
          <Route
            path="member/restore_households"
            element={
              <PrivateRoute>
                <MemberDeletedHouseholds />
              </PrivateRoute>
            }
          />
          <Route
            path="member"
            element={
              <PrivateRoute>
                <Preferences />
              </PrivateRoute>
            }
          />
          <Route
            path="household/:id"
            element={
              <PrivateRoute>
                <Household />
              </PrivateRoute>
            }
          >
            <Route
              path="summary"
              element={
                <PrivateRoute>
                  <HouseholdHome />
                </PrivateRoute>
              }
            />
            <Route
              path="assetmap"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdAssetMap
                    isConsumer={false}
                    canEdit={true}
                    showPDF={false}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="relationships"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdMembers
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            >
              <Route
                path="map"
                element={
                  <HouseholdPermissionRoute
                    hasPermission={hasHouseholdPermission && hasFamilyMap}
                    redirectPath={'..'}
                  >
                    <HouseholdFamilyMap
                      isConsumer={false}
                      canEdit={true}
                      showPDF={true}
                    />
                  </HouseholdPermissionRoute>
                }
              />
              <Route
                index
                element={
                  <HouseholdPermissionRoute
                    hasPermission={hasHouseholdPermission}
                  >
                    <HouseholdMembersList
                      isConsumer={false}
                      canEdit={true}
                      showPDF={true}
                    />
                  </HouseholdPermissionRoute>
                }
              />
              <Route path="*" element={<Navigate to="" />} />
            </Route>
            <Route
              path="members"
              element={<Navigate to="../relationships" />}
            />
            <Route
              path="members/map"
              element={<Navigate to="../relationships/map" />}
            />
            <Route
              path="members/family_map"
              element={<Navigate to="../relationships/map" />}
            />
            <Route
              path="financials"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdFinancials
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="targetmap/compare/:tm1?/:tm2?/:tm3?"
              element={
                <HouseholdPermissionRoute
                  hasPermission={
                    hasHouseholdPermission &&
                    showTargetMaps &&
                    hasTargetMapCompare
                  }
                >
                  <HouseholdTargetMapsCompare
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="targetmap/:tm_id"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission && showTargetMaps}
                >
                  <HouseholdTargetMap
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="targetmap"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission && showTargetMaps}
                >
                  <HouseholdTargetMaps
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="notes"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdNotes isConsumer={false} canEdit={true} />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="balance_sheet"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdBalanceSheet
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="policy_summary"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdPolicySummary
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="kffs"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdKeyFinancials isConsumer={false} canEdit={true} />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="reports"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdReports isConsumer={false} canEdit={true} />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="beneficiaries"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdBeneficiaries
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="signals"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission && showHouseholdSignals}
                >
                  <HouseholdSignals
                    isConsumer={false}
                    canEdit={true}
                    showPDF={true}
                  />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="questionnaires"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission && showSurveys}
                >
                  <HouseholdSurveys isConsumer={false} canEdit={true} />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="surveys"
              element={<Navigate to="../questionnaires" />}
            />
            <Route
              path="analytics"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdAnalytics isConsumer={false} />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="info"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdInfo isConsumer={false} canEdit={true} />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="loader"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission}
                >
                  <HouseholdLoader isConsumer={false} />
                </HouseholdPermissionRoute>
              }
            />
            <Route
              path="milestones"
              element={
                <HouseholdPermissionRoute
                  hasPermission={hasHouseholdPermission && showMilestones}
                >
                  <HouseholdMilestones isConsumer={false} canEdit={true} />
                </HouseholdPermissionRoute>
              }
            />
            <Route index element={<RedirectHouseholdRoute />} />
            <Route path="*" element={<RedirectHouseholdRoute />} />
          </Route>
          <Route
            path="manager/team"
            element={
              <PermissionRoute role={isManager}>
                <ManagerPreferences />
              </PermissionRoute>
            }
          />
          <Route
            path="manager/analytics"
            element={
              <PermissionRoute role={hasAtlas}>
                <ManagerAtlas />
              </PermissionRoute>
            }
          />
          <Route
            path="manager/atlas"
            element={<Navigate to="/manager/analytics" />}
          />
          <Route
            path="manager/restore_households"
            element={
              <PermissionRoute role={isManager}>
                <ManagerDeletedHouseholds />
              </PermissionRoute>
            }
          />
          <Route
            path="manager/billing"
            element={
              <PermissionRoute role={isBilling}>
                <Billing />
              </PermissionRoute>
            }
          />
          <Route
            path="manager"
            element={
              <PermissionRoute role={isManager}>
                <ManagerPreferences />
              </PermissionRoute>
            }
          />
          <Route
            path="transfer"
            element={
              <PermissionRoute role={isManager && showTransferBoB}>
                <ManagerPreferences redirected={true} />
              </PermissionRoute>
            }
          />
          <Route
            path="style"
            element={
              <PrivateRoute>
                <StyleGuide />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<RedirectRoute />} />
        </Routes>
        {hideIntercomSpacer() || <IntercomSpacer />}
      </RouterOutlet>
    </>
  );
};

export default AppRouter;
