/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PageHeader, TabMenu } from '../../components';
import { getReportLayouts } from '../../store/actions';
import {
  HouseholdReportsGenerate,
  HouseholdReportsArchive,
} from '../../containers';

import { UpdatePageTitle, headerContent } from '../../utils';

const HouseholdReports = () => {
  UpdatePageTitle('Reports');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  const { isStencil, showReportLayouts, showReportHistory } = useSelector(
    (state) => ({
      isStencil: state.households.isStencil,
      showReportLayouts: state.user.showReportLayouts,
      showReportHistory: state.user.showReportHistory,
    })
  );
  const [activeTab, setActiveTab] = useState('generate');

  const tabs = [
    {
      text: 'Generate Report',
      value: 'generate',
    },
    {
      text: 'History',
      value: 'history',
    },
  ];

  useEffect(() => {
    if (showReportLayouts) {
      dispatch(getReportLayouts());
    }
  }, [showReportLayouts]);

  useEffect(() => {
    if (showReportHistory !== null) {
      if (location?.search && location?.search.includes('?panel=')) {
        const tabSplit = location?.search.split('?panel=');
        const tabValue = tabSplit[1];
        if (tabValue === 'history') {
          if (showReportHistory) {
            setActiveTab('history');
          } else {
            setActiveTab('generate');
            navigate(location.pathname);
          }
        }
      }
    }
  }, [showReportHistory]);

  useEffect(() => {
    if (location) {
      if (location?.search && location?.search.includes('?panel=')) {
        const tabSplit = location?.search.split('?panel=');
        const tabValue = tabSplit[1];
        const tabOptions = tabs.map((tab) => tab.value);
        if (tabOptions.includes(tabValue)) {
          setActiveTab(tabValue);
        } else {
          setActiveTab('generate');
          location.search = `panel=generate`;
        }
      } else {
        setActiveTab('generate');
        location.search = `panel=generate`;
      }
    }
  }, [location]);

  const tabOnClick = (tab) => {
    location.search = `panel=${tab}`;
    navigate(location, { state: { panel: tab } });
  };

  const renderTabMenu = () => {
    if (!isStencil && showReportHistory) {
      return <TabMenu tabs={tabs} activeTab={activeTab} onClick={tabOnClick} />;
    }
  };

  return (
    <PageContainer>
      <PageHeader content={{ ...headerContent.reports }} />
      {renderTabMenu()}
      {activeTab === 'generate' ? (
        <HouseholdReportsGenerate householdId={householdId} />
      ) : activeTab === 'history' ? (
        <HouseholdReportsArchive householdId={householdId} />
      ) : null}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

export default HouseholdReports;
