/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  UserPreferencesContainer,
  SecurityPreferencesContainer,
  CustomPreferencesContainer,
  ConnectRolesSettingsContainer,
} from '../../containers';
import { PageHeader, TabMenu } from '../../components';
import {
  getCustomUserPreferences,
  checkHasSupportAccess,
  getAuthFactors,
  getAuthDevices,
} from '../../store/actions';
import { UpdatePageTitle, CloseDropdowns, headerContent } from '../../utils';
import { Page } from '../../styles/library/layoutStyles';

const Preferences = () => {
  UpdatePageTitle('Preferences');
  CloseDropdowns();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loadedUser, isSSO, showCustom, isConsumer, showHouseholdSharing } =
    useSelector((state) => ({
      loadedUser: state.user.loadedUser,
      isSSO: state.user.isSSO,
      showCustom: state.user.showCustom,
      isConsumer: state.user.isConsumer,
      showHouseholdSharing: state.user.showHouseholdSharing,
    }));
  const [activeTab, setActiveTab] = useState('user');
  const [tabs, setTabs] = useState([
    {
      text: 'User Settings',
      value: 'user',
      visible: true,
    },
    {
      text: 'Security',
      value: 'security',
      visible: false,
    },
    {
      text: 'Customization',
      value: 'custom',
      visible: false,
    },
    {
      text: 'Client Portal',
      value: 'client_portal',
      visible: false,
    },
  ]);

  useEffect(() => {
    dispatch(checkHasSupportAccess());
    dispatch(getAuthFactors());
    dispatch(getAuthDevices());
  }, []);

  useEffect(() => {
    if (activeTab === 'security') {
      dispatch(getAuthFactors());
      dispatch(getAuthDevices());
    }
  }, [activeTab]);

  useEffect(() => {
    if (showCustom) {
      dispatch(getCustomUserPreferences());
    }
  }, [showCustom]);

  useEffect(() => {
    if (loadedUser) {
      setTabs(
        tabs.map((tab) => {
          if (tab.value === 'security') {
            tab.visible = !isSSO;
          }
          if (tab.value === 'custom') {
            tab.visible = showCustom;
          }
          if (tab.value === 'client_portal') {
            tab.visible = showHouseholdSharing;
          }
          return tab;
        })
      );
    }
  }, [loadedUser]);

  useEffect(() => {
    if (location) {
      if (location?.search && location?.search.includes('?panel=')) {
        const tabSplit = location?.search.split('?panel=');
        const tabValue = tabSplit[1];
        const tabOptions = tabs.map((tab) => tab.value);
        if (tabOptions.includes(tabValue)) {
          setActiveTab(tabValue);
        } else {
          setActiveTab('user');
          location.search = `panel=user`;
        }
      } else {
        setActiveTab('user');
        location.search = `panel=user`;
      }
    }
  }, [location]);

  const tabOnClick = (tab) => {
    location.search = `panel=${tab}`;
    navigate(location, { state: { panel: tab } });
  };

  return (
    <Page>
      <PageHeader
        content={
          isConsumer
            ? headerContent.consumerPreferences
            : headerContent.userPreferences
        }
      />
      <TabMenu
        tabs={tabs.filter((tab) => tab.visible)}
        activeTab={activeTab}
        onClick={tabOnClick}
      />
      {activeTab === 'user' ? (
        <UserPreferencesContainer isConsumer={isConsumer} />
      ) : activeTab === 'security' ? (
        <SecurityPreferencesContainer />
      ) : activeTab === 'custom' ? (
        <CustomPreferencesContainer />
      ) : activeTab === 'client_portal' ? (
        <ConnectRolesSettingsContainer />
      ) : null}
    </Page>
  );
};

export default Preferences;
