import styled, { ThemeProvider } from 'styled-components';
import { colors, maxDevice, fonts, inputColors } from '../../styles/variables';
import PropTypes from 'prop-types';

const Toggle = ({ props, onClick }) => {
  let { value, name, text, theme } = props;
  let toggleTheme = {};
  if (theme) {
    toggleTheme = {
      bg: theme?.bg,
      smaller: theme?.smaller,
      fontColor: theme?.fontColor,
      fontSize: theme?.fontSize,
    };
  }

  const handleChange = () => {
    // console.log('change');
  };

  return (
    <ThemeProvider theme={toggleTheme}>
      {text ? (
        <>
          <ToggleSwitch
            onClick={() => onClick(props)}
            data-id={name}
            data-enabled={value}
          >
            <ToggleSwitchCheckbox
              type="checkbox"
              checked={value}
              className="toggle-switch-checkbox"
              name={name}
              id={name}
              onChange={handleChange}
            />
            <ToggleSwitchLabel htmlFor="toggleSwitch">
              <ToggleSwitchInner />
              <ToggleSwitchSwitch />
            </ToggleSwitchLabel>
          </ToggleSwitch>
          <ToggleText>{text}</ToggleText>
        </>
      ) : (
        <>
          <ToggleSwitch
            onClick={() => onClick(props)}
            data-id={name}
            data-enabled={!value}
          >
            <ToggleSwitchCheckbox
              type="checkbox"
              checked={value}
              className="toggle-switch-checkbox"
              name={name}
              id={name}
              onChange={handleChange}
            />
            <ToggleSwitchLabel htmlFor="toggleSwitch">
              <ToggleSwitchInner />
              <ToggleSwitchSwitch />
            </ToggleSwitchLabel>
          </ToggleSwitch>
        </>
      )}
    </ThemeProvider>
  );
};

Toggle.propTypes = {
  props: PropTypes.object,
  value: PropTypes.bool,
  name: PropTypes.string,
  text: PropTypes.string,
  theme: PropTypes.object,
  onClick: PropTypes.func,
};

const ToggleSwitch = styled.div`
  @media screen and (max-width: 767px) {
    transform: scale(0.8);
  }
  width: 40px;
  transform: ${(props) => (props.theme.smaller ? 'scale(0.8)' : null)};
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
`;

const ToggleText = styled.span`
  @media ${maxDevice.sideNav} {
    font-size: 11px;
    margin-left: 10px;
  }
  margin-right: 10px;
  font-size: ${(props) =>
    props.theme.fontSize ? props.theme.fontSize : '13px'};
  margin-left: ${(props) => (props.theme.smaller ? '5px' : '10px')};
  font-weight: ${fonts.semiBold};
  /* color: ${colors.darkGrey}; */
  color: ${(props) =>
    props.theme.fontColor ? props.theme.fontColor : colors.paleGrey};
`;

const ToggleSwitchInner = styled.span`
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  &:before,
  &:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 14px;
    color: ${colors.white};
    font-weight: ${fonts.bold};
    box-sizing: border-box;
    content: '';
  }
  &:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: ${colors.lightBlue};
    background-color: ${(props) => props.theme.bg};
    color: ${colors.white};
  }
  &:after {
    padding-right: 10px;
    background-color: ${inputColors.border};
    color: ${colors.white};
    text-align: right;
  }
`;

const ToggleSwitchSwitch = styled.span`
  display: block;
  width: 16px;
  right: 20px;
  margin: 2px;
  background: ${colors.white};
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0 solid ${inputColors.border};
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
`;

const ToggleSwitchLabel = styled.label`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid transparent;
  border-radius: 20px;
  margin: 0;
`;
const ToggleSwitchCheckbox = styled.input`
  display: none;
  &:checked + ${ToggleSwitchLabel} ${ToggleSwitchInner} {
    margin-left: 0;
  }
  &:checked + ${ToggleSwitchLabel} ${ToggleSwitchSwitch} {
    right: 0;
  }
`;

export default Toggle;
