import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, maxDevice, fonts } from '../styles/variables';
import {
  notFound,
  passwordProtected,
  pipeline,
  payment,
  noHouseholds,
} from '../assets';

const PermissionContainer = ({ type = '404' }) => {
  const generatePermissionText = () => {
    switch (type) {
      case '404':
        return 'Not Found';
      case 'none':
        return 'You do not have permission to view household details.';
      case 'paused':
        return 'Subscription is paused. Please contact Asset-Map Support for more information or to update your subscription.';
      case 'past_due':
        return 'Account is past due. Please contact Asset-Map Support for more information or to update your account.';
      case 'private':
        return 'You do not have permission to view household details as it is marked private.';
      case 'search':
        return 'Household access limited to summary information in the Household Index.';
      case 'subscribe':
        return 'An active subscription is required to access household details.';
      case 'unknown':
        return 'An unknown error prevented access to view household details.';
      default:
        return 'Unknown Error';
    }
  };

  const determinePermissionImage = () => {
    switch (type) {
      case '404':
        return notFound;
      case 'none':
      case 'private':
        return passwordProtected;
      case 'paused':
      case 'past_due':
      case 'subscribe':
        return payment;
      case 'search':
        return noHouseholds;
      default:
        return pipeline;
    }
  };
  return (
    <PermissionDisplayContainer>
      <PermissionImage
        src={determinePermissionImage()}
        alt={type}
        title={type}
        data-image={type}
      />
      <PermissionText>{generatePermissionText()}</PermissionText>
    </PermissionDisplayContainer>
  );
};

const PermissionDisplayContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 8vh 20px 50px 20px;
`;

const PermissionImage = styled.img`
  max-width: 550px;
  max-height: 450px;
  width: 100%;
  margin: 0 40px 20px 40px;
`;

const PermissionText = styled.p`
  @media ${maxDevice.tablet} {
    font-size: 28px;
  }
  @media ${maxDevice.sideNav} {
    font-size: 22px;
  }
  @media ${maxDevice.mobileL} {
    font-size: 18px;
  }
  max-width: 900px;
  margin: 20px 40px 20px 40px;
  font-size: 32px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

PermissionContainer.propTypes = {
  type: PropTypes.string,
};

export default PermissionContainer;
