/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getConfigs,
  getGlobalConfigs,
  // getNotifications,
  // getUnreadCount,
  getUserInfo,
  getConsumerInfo,
  logUserOut,
  // markAllNotificationsRead,
  // toggleShowNotificationDropdown,
  toggleShowUserDropdown,
  getConsumerHouseholds,
  updateUserStoreValue,
  getAuthStatus,
  exitClientPreviewMode,
  closeAllDropdowns,
  toggleShowAdvisorDropdown,
  // toggleNotificationRead,
} from '../../store/actions';
import { NavBar, NavDropdown } from '../../components';
import {
  home,
  logo,
  plusLogo,
  //  bell, settingsIcon
} from '../../assets';
import {
  CONSUMER_SUPPORT_LINK as supportLink,
  // CONSUMER_FEEDBACK as feedback,
  BASENAME as basename,
} from '../../utils/constants';
import { ButtonThemes, DropdownThemes } from '../../styles/themes';
import {
  FormatPhoneNumber,
  SendTrackingEvent,
  ToggleCookie,
} from '../../utils';

const NavbarContainerConsumer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    // isShowingNotificationDropdown,
    // unread,
    isPreview,
    currentHousehold,
    leadAdvisor,
    isShowingUserDropdown,
    isShowingAdvisorDropdown,
    loggedIn,
    user,
    households,
    hasSingleHousehold,
    singleHousehold,
    justLoggedIn,
    invocationId,
    canAccessAMP,
    advisorLogo,
  } = useSelector((state) => ({
    // isShowingNotificationDropdown: state.dropdowns.showingNotificationDropdown,
    // unread: state.notifications.unread,
    isPreview: state.user.isPreview,
    currentHousehold: state.households.currentHousehold,
    leadAdvisor: state.households.leadAdvisor,
    isShowingUserDropdown: state.dropdowns.showingUserDropdown,
    isShowingAdvisorDropdown: state.dropdowns.showingAdvisorDropdown,
    loggedIn: state.user.loggedIn,
    user: state.user.user,
    households: state.households.households,
    hasSingleHousehold: state.households.hasSingleHousehold,
    singleHousehold: state.households.singleHousehold,
    justLoggedIn: state.user.justLoggedIn,
    invocationId: state.configs.invocationId,
    canAccessAMP: state.user.canAccessAMP,
    advisorLogo: state.households.advisorLogo,
  }));
  const [showAdvisorDropdown, setShowAdvisorDropdown] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getUserInfo());
      dispatch(getAuthStatus());
      // dispatch(getNotifications());
      dispatch(getConfigs());
      if (!isPreview) {
        dispatch(getConsumerInfo());
        dispatch(getConsumerHouseholds());
      }
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!loggedIn) {
      const { pathname } = window.location;
      dispatch(getGlobalConfigs(pathname));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (households && justLoggedIn) {
      if (hasSingleHousehold) {
        navigate(`/household/${singleHousehold}/assetmap`);
      } else {
        dispatch(updateUserStoreValue('justLoggedIn', false));
      }
    }
  }, [households, justLoggedIn]);

  useEffect(() => {
    setShowAdvisorDropdown(isShowingAdvisorDropdown);
  }, [isShowingAdvisorDropdown]);

  // useEffect(() => {
  //   if (loggedIn) {
  //     dispatch(getUnreadCount());
  //   }
  // }, [notifications]);

  // const goToNotificationSettings = () => {
  //   navigate('/member/preferences');
  //   //Need to scroll down page as well?
  // };

  const determineAvatar = (avatar) => {
    if (avatar === '/static/img/icon-user.png') {
      return null;
    } else {
      return avatar;
    }
  };

  const onIconClick = (dispatchFunc, keyword, type = 'click') => {
    dispatch(dispatchFunc);
    return SendTrackingEvent(invocationId, type, keyword, null);
  };

  const onLinkClick = (keyword) => {
    SendTrackingEvent(invocationId, 'click', keyword, null);
  };

  const redirectClick = () => {
    ToggleCookie('isConsumer', false);
    window.location = `${window.location.origin}/${basename}/member/dashboard`;
  };

  const exitPreviewOnClick = () => {
    const memberId = localStorage.getItem('member_id');
    SendTrackingEvent(invocationId, 'click', 'exit_client_preview', null);
    dispatch(closeAllDropdowns());
    return dispatch(exitClientPreviewMode(currentHousehold?.uuid, memberId));
  };

  const navBarContent = {
    leftContent: isPreview
      ? [
          // {
          //   kind: 'text',
          //   value: `<span>Previewing:</span> ${localStorage.getItem(
          //     'member_name'
          //   )}`,
          // },
        ]
      : [
          {
            kind: 'image',
            src: home,
            alt: 'house',
            title: 'Households',
            function: () =>
              isPreview
                ? null
                : hasSingleHousehold
                  ? navigate(`/household/${singleHousehold}`)
                  : navigate(`/dashboard`),
          },
          {
            kind: 'link',
            value: currentHousehold.name,
            id: currentHousehold.uuid,
          },
        ],
    centerContent: {
      link: isPreview
        ? null
        : hasSingleHousehold
          ? `/household/${singleHousehold}`
          : `/dashboard`,
      appLogo: advisorLogo ?? logo,
      plusLogo: plusLogo,
      alt: 'logo',
      onClick: () => onLinkClick('nav_top_logo'),
    },
    rightContent: [
      // {
      //   src: bell,
      //   alt: 'bell',
      //   title: 'Notifications',
      //   function: () => {
      //     if (!isShowingNotificationDropdown) {
      //       onLinkClick('nav_top_notification');
      //       dispatch(getNotifications());
      //     }
      //     dispatch(
      //       toggleShowNotificationDropdown(!isShowingNotificationDropdown)
      //     );
      //   },
      // },
      // isPreview
      //   ? {
      //       kind: 'action',
      //       value: 'Exit Preview',
      //       onClick: exitPreviewOnClick,
      //     }
      //   : {},
      {
        kind: 'advisor',
        value: leadAdvisor?.name,
        email: leadAdvisor?.email,
        showingDropdown: isShowingAdvisorDropdown,
        function: () =>
          dispatch(toggleShowAdvisorDropdown(!showAdvisorDropdown)),
      },
      {
        isAvatar: true,
        src: determineAvatar(user.avatar),
        initials: user.initials,
        alt: 'avatar',
        title: 'User',
        function: () =>
          dispatch(toggleShowUserDropdown(!isShowingUserDropdown)),
      },
    ],
  };

  // const notificationClick = (notification) => {
  //   dispatch(toggleNotificationRead(notification));
  //   return SendTrackingEvent(
  //     invocationId,
  //     'action',
  //     'nav_top_notification_mark_read',
  //     null
  //   );
  // };

  // const formatNotifications = () => {
  //   if (notifications) {
  //     return notifications.map((notification) => {
  //       notification.kind = 'notification';
  //       notification.onClick = notificationClick;
  //       return notification;
  //     });
  //   }
  // };

  // const notificationDropdown = {
  //   heading: 'Notifications',
  //   headingIcon: {
  //     source: settingsIcon,
  //     name: 'settings',
  //     function: goToNotificationSettings,
  //   },
  //   items: formatNotifications(),
  //   bottomContent: [
  //     {
  //       type: 'link',
  //       text: 'View All',
  //       to: '/notifications',
  //       onClick: () => onLinkClick('nav_top_notification_view'),
  //     },
  //     {
  //       type: 'function',
  //       text: 'Mark All Read',
  //       function: () =>
  //         onIconClick(
  //           markAllNotificationsRead(),
  //           'nav_top_notification_mark_all',
  //           'action'
  //         ),
  //     },
  //   ],
  // };

  const userDropdown = () => {
    let dropdown = {
      heading: user.name,
      items: [
        {
          order: 1,
          label: 'User Preferences',
          link: '/user/preferences',
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_prefs'),
        },
        {
          order: 2,
          label: 'Support Documentation',
          link: supportLink,
          outside: true,
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_support'),
        },
        // {
        //   order: 3,
        //   label: 'Feedback',
        //   link: feedback,
        //   outside: true,
        //   kind: 'text',
        //   onClick: () => onLinkClick('nav_top_user_feedback'),
        // },
        {
          order: 4,
          label: 'Terms of Service',
          link: '/user/terms',
          kind: 'text',
          onClick: () => onLinkClick('nav_top_user_terms'),
        },
      ],
      bottomButton: {
        label: isPreview ? 'Exit Preview' : 'Log Out',
        theme: ButtonThemes.primaryFull,
        function: () =>
          isPreview
            ? exitPreviewOnClick()
            : onIconClick(logUserOut(), 'nav_top_user_logout'),
        // onIconClick(
        //   isPreview ? exitPreviewOnClick() : logUserOut(),
        //   'nav_top_user_logout'
        // ),
      },
    };
    const redirectToAMP = {
      order: 16,
      label: 'Switch to Asset-Map Advisor',
      link: ``,
      endSection: true,
      kind: 'text',
      onClick: () => redirectClick(),
    };
    if (canAccessAMP) {
      dropdown.items.push(redirectToAMP);
    }
    if (isPreview) {
      dropdown.clientPreview = isPreview;
      dropdown.memberName = localStorage.getItem('member_name');
      dropdown.exitClientPreview = () =>
        onIconClick(logUserOut(), 'nav_top_user_logout');
      // dropdown.exitClientPreview = () => exitPreviewOnClick();
      dropdown.items = [];
    }
    dropdown.items.sort((a, b) => {
      return a.order - b.order;
    });
    return dropdown;
  };

  const advisorItems = [
    {
      label: 'Name',
      id: 'name',
      value: leadAdvisor?.name ? leadAdvisor?.name : 'Unknown',
      kind: 'textLabel',
    },
    leadAdvisor?.customer_name
      ? {
          label: 'Organization',
          value: leadAdvisor?.customer_name
            ? leadAdvisor?.customer_name
            : 'Unknown',
          kind: 'textLabel',
        }
      : null,
    leadAdvisor?.phone_number
      ? {
          label: 'Phone Number',
          value: FormatPhoneNumber(leadAdvisor?.phone_number),
          kind: 'textLabel',
        }
      : null,
    leadAdvisor?.email
      ? {
          label: 'Email',
          value: leadAdvisor?.email,
          link: `mailto:${leadAdvisor?.email}`,
          kind: 'textLabel',
        }
      : null,
  ];

  const advisorDropdown = {
    heading: 'Advisor Details',
    items: advisorItems.filter((advisor) => advisor !== null),
  };

  return (
    <>
      <NavBar
        loggedIn={loggedIn}
        navBarContent={navBarContent}
        // unread={unread}
      />
      {/* <NavDropdown
        content={notificationDropdown}
        theme={DropdownThemes.notifications}
        show={isShowingNotificationDropdown}
      /> */}
      <NavDropdown
        content={advisorDropdown}
        theme={DropdownThemes.advisor}
        show={isShowingAdvisorDropdown}
      />
      <NavDropdown
        content={userDropdown()}
        theme={DropdownThemes.user}
        show={isShowingUserDropdown}
      />
    </>
  );
};

export default NavbarContainerConsumer;
