/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorThemes } from '../../../styles/themes';
import {
  PageHeader,
  SimpleModal,
  LoadingAnimation,
  Error,
  IntegrationSearchBar,
  IntegrationSearchResult,
} from '../../../components';
import {
  getIntegrations,
  searchIntegration,
  setIntegrationSearchResults,
  getIntegrationMembers,
  setIntegrationMembers,
  setIntegrationMembersError,
  createHouseholdIntegration,
  linkHouseholdMemberToIntegration,
} from '../../../store/actions';
import { arrowRightDark, noIntegrations, check } from '../../../assets';
import { Page } from '../../../styles/library/layoutStyles';
import {
  boxShadows,
  colors,
  maxDevice,
  fonts,
} from '../../../styles/variables';
import {
  headerContent,
  integrationOptions,
  UpdatePageTitle,
  CloseDropdowns,
  SendTrackingEvent,
} from '../../../utils';
import {
  SecondaryHeading,
  TertiaryHeading,
} from '../../../styles/library/fontStyles';

const HouseholdNewIntegrations = () => {
  UpdatePageTitle('New Household - Integrations');
  CloseDropdowns();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    integrations,
    integrationResults,
    integrationMembers,
    integrationHousehold,
    integrationResultsError,
    invocationId,
    integrationMembersError,
    linkedMembers,
    createdHouseholdIntegrationError,
  } = useSelector((state) => ({
    integrations: state.user.integrations,
    integrationResults: state.user.integrationResults,
    integrationMembers: state.user.integrationMembers,
    integrationHousehold: state.user.integrationHousehold,
    integrationResultsError: state.user.integrationResultsError,
    invocationId: state.configs.invocationId,
    integrationMembersError: state.user.integrationMembersError,
    linkedMembers: state.households.linkedMembers,
    createdHouseholdIntegrationError:
      state.user.createdHouseholdIntegrationError,
  }));
  const [integrationsList, setIntegrationsList] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [hasSelectedIntegration, setHasSelectedIntegration] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [results, setResults] = useState([]);
  const [showMoreMessage, setShowMoreMessage] = useState(false);
  const [showHouseholdModal, setShowHouseholdModal] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [modalContent, setModalContent] = useState();
  const [currentImport, setCurrentImport] = useState();
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [membersListURL, setMembersListURL] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [pageWorkflow, setPageWorkflow] = useState('default');
  const [previousSearchResults, setPreviousSearchResults] = useState();
  const [createdHouseholdId, setCreatedHouseholdId] = useState();
  const [importingAll, setImportingAll] = useState();
  const [runSearch, setRunSearch] = useState();

  useEffect(() => {
    dispatch(getIntegrations());
    return () => {
      dispatch(setIntegrationSearchResults(false));
      dispatch(setIntegrationMembers(false));
      dispatch(setIntegrationMembersError(false));
    };
  }, []);

  useEffect(() => {
    if (integrationResultsError) {
      setIsLoadingResults(false);
      if (integrationResultsError?.error?.data?.detail?.error_message) {
        setErrorMessage(
          integrationResultsError?.error?.data?.detail?.error_message
        );
      } else {
        setErrorMessage('An error was encountered while searching');
      }
    }
  }, [integrationResultsError]);

  useEffect(() => {
    if (integrations) {
      let mappedIntegrations = integrations.map((integration) => {
        const integrationCopy = { ...integration };
        let details = integrationOptions.find(
          (int) => int.id === integration.key
        );
        integrationCopy.showLogin = false;
        return { ...integration, ...details };
      });
      let activeIntegrations = mappedIntegrations.filter((integration) => {
        return integration.is_enabled && !integration.exclude_household;
      });
      setIntegrationsList(activeIntegrations);
      setLoadingIntegrations(false);
    }
  }, [integrations]);

  //HOOK THAT UPDATES ACTIVE TAB AND MAKES API CALLS FOR INTEGRATIONS
  useEffect(() => {
    if (
      location &&
      location?.state?.panel &&
      integrationsList &&
      integrationsList.length !== 0
    ) {
      const matchedIntegration = integrationsList.find(
        (int) => int.key === location?.state?.panel
      );
      if (matchedIntegration) {
        setSelectedIntegration(matchedIntegration);
        setHasSelectedIntegration(true);
      }
    } else {
      setHasSelectedIntegration(false);
    }
  }, [integrationsList, location?.state?.panel]);

  //HOOK TO UPDATE LOCATION AND URL TO ACTIVE TAB VALUE
  useEffect(() => {
    if (hasSelectedIntegration) {
      if (selectedIntegration) {
        location.search = `panel=${selectedIntegration.key}`;
        navigate(location, { state: { panel: selectedIntegration.key } });
      } else {
        setSelectedIntegration();
        location.search = null;
        navigate(location, { state: null });
      }
    }
  }, [hasSelectedIntegration, selectedIntegration]);

  useEffect(() => {
    if (window?.location?.search) {
      const queryParams = new URLSearchParams(window.location.search);
      const integration = queryParams.get('panel');
      const input = queryParams.get('input');
      if (integration && input) {
        navigate(location, { state: { panel: integration } });
        setSearchValue(input);
        setRunSearch(true);
      }
    }
  }, [window?.location?.search]);

  useEffect(() => {
    if (runSearch && selectedIntegration) {
        searchIntegrationOnClick();
    }
  }, [runSearch, selectedIntegration])

  /*
   * There are 2 ways the create integration hh works, ie the workflow if the page, depending on how the integration's apis operate:
   *  default -> how most operate
   *    search request is on members or hh and members, results have "Import" button on list
   *    import button click opens modal where user can choose to import individual or entire hh.
   *  morningstar -> different than other integrations (potentially refactor):
   *    search request is on households, results have "View" button on list
   *    View button click hits ms api and gets members, results replace hh search list this time with an "Import" button
   *    import button click opens modal where user can choose to import individual or entire hh.
   */
  useEffect(() => {
    if (selectedIntegration)
      if (selectedIntegration.key === 'morningstar') {
        setPageWorkflow('morningstar');
      } else {
        setPageWorkflow('default');
      }
  }, [selectedIntegration]);

  useEffect(() => {
    if (integrationResults) {
      setResults(integrationResults.results);
      setIsLoadingResults(false);
      if (integrationResults.hasOwnProperty('more')) {
        setShowMoreMessage(integrationResults.more);
      }
      setShowResults(true);
    }
  }, [integrationResults]);

  useEffect(() => {
    if (integrationMembers) {
      if (integrationMembers.hasOwnProperty('results')) {
        if (pageWorkflow === 'morningstar') {
          setIsLoadingResults(false);
          setPreviousSearchResults(integrationResults);
          setResults(integrationMembers.results);
        } else {
          setMembersList(integrationMembers.results);
        }
        setMembersListURL(integrationMembers.link_url);
        setLoadingMembers(false);
      }
    }
  }, [integrationMembers]);

  useEffect(() => {
    if (integrationMembersError) {
      if (integrationMembersError.hasOwnProperty('detail')) {
        setErrorMessage(integrationMembersError.detail);
      } else {
        setErrorMessage('Error getting members');
      }
      setLoadingMembers(false);
      setIsLoadingResults(false);
    }
  }, [integrationMembersError]);

  const modalOnClick = (value, import_all) => {
    dispatch(createHouseholdIntegration(value));
    setShowHouseholdModal(false);
    setLoadingMembers(true);
    setImportingAll(import_all);
  };

  useEffect(() => {
    if (membersList && membersList.length !== 0) {
      if (membersList.length > 1) {
        setShowHouseholdModal(true);
        setLoadingMembers(false);
        setModalContent({
          heading: 'Import Contact',
          text: `A new Asset-Map Household will be created with:`,
          minWidth: '500px',
          horizontalContent: [
            {
              type: 'integration',
              text: `Entire ${selectedIntegration.name} Household`,
              members: membersList,
              buttonText: 'Import All',
              onClick: () => modalOnClick(membersListURL, true),
            },
            {
              type: 'integration',
              text: `Single ${selectedIntegration.name} Contact`,
              household_name: null,
              members: [{ name: currentImport.name }],
              buttonText: 'Import One',
              onClick: () => modalOnClick(currentImport.link_url, false),
            },
          ],
        });
      } else {
        dispatch(createHouseholdIntegration(membersListURL));
        setLoadingMembers(true);
      }
    }
  }, [membersList]);

  useEffect(() => {
    if (createdHouseholdIntegrationError) {
      setImportingAll(false);
      setLoadingMembers(false);
      setCurrentImport(false);
      if (createdHouseholdIntegrationError?.data?.detail) {
        setErrorMessage(createdHouseholdIntegrationError?.data?.detail);
      } else {
        setErrorMessage(
          'Unable to create household.  An error occurred while importing.'
        );
      }
    }
  }, [createdHouseholdIntegrationError]);

  useEffect(() => {
    if (integrationHousehold) {
      setLoadingMembers(false);
      if (integrationHousehold.hasOwnProperty('results')) {
        const updatedResults = results.map((result) => {
          const matched = integrationHousehold.results.find(
            (res) =>
              // eslint-disable-next-line eqeqeq
              res.contact_id == result.id ||
              res.contact_id === result.family_id ||
              res.contact_id === result.id + '.' + result.family_id ||
              (Array.isArray(res.contact_id) &&
                res.contact_id.includes(result.id))
          );
          if (matched) {
            result.just_created = true;
            result.households.push(matched.household);
            setCreatedHouseholdId(matched.household);
          } else {
            result.just_created = false;
          }
          return result;
        });
        setResults(updatedResults);
      }
    }
  }, [integrationHousehold]);

  useEffect(() => {
    if (linkedMembers && linkedMembers?.linked_members.length !== 0) {
      setLoadingMembers(false);
      setIsLoadingResults(true);
      const updatedResults = results.map((result) => {
        const matched = linkedMembers.linked_members.find(
          (res) => res.partner_data.memberId === result.id
        );
        if (matched) {
          result.just_created = true;
          result.households.push(createdHouseholdId);
        } else {
          result.just_created = false;
        }
        return result;
      });
      setResults(updatedResults);
      setIsLoadingResults(false);
    }
  }, [linkedMembers]);

  const integrationOnClick = (integration) => {
    setHasSelectedIntegration(true);
    setSelectedIntegration(integration);
    setShowResults(false);
    setResults([]);
    setErrorMessage('');
    setShowMoreMessage(false);
    SendTrackingEvent(
      invocationId,
      'action',
      'integration_select',
      'integration_households',
      { integration: integration.id }
    );
    location.search = `panel=${integration.key}`;
    navigate(location, { state: { panel: integration.key } });
  };

  const goBack = () => {
    setHasSelectedIntegration(false);
    setSelectedIntegration();
    setIsLoadingResults(false);
    setShowResults(false);
    setSearchValue('');
    setErrorMessage('');
    setShowMoreMessage(false);
    setSelectedIntegration();
    setPreviousSearchResults();
    setImportingAll();
    location.search = null;
    navigate(location, { state: null });
    setRunSearch(false);
  };

  const searchIntegrationOnClick = () => {
    if (hasSelectedIntegration) {
      setErrorMessage('');
      setCreatedHouseholdId(false);
      dispatch(searchIntegration(selectedIntegration.key, searchValue));
      setIsLoadingResults(true);
      setShowResults(true);
      setShowMoreMessage(false);
      setResults([]);
      setPreviousSearchResults(false);
      setImportingAll();
      SendTrackingEvent(
        invocationId,
        'action',
        'integration_search',
        'integration_households',
        { integration: selectedIntegration.id, query: searchValue }
      );
    }
  };

  const onKeyDown = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13) {
      searchIntegrationOnClick();
    }
  };

  const importOnClick = (result) => {
    if (loadingMembers) {
      return;
    }
    setCurrentImport(result);
    setLoadingMembers(true);
    setErrorMessage(false);
    if (pageWorkflow === 'morningstar' && !createdHouseholdId) {
      setMembersList([...integrationMembers.results]);
      return;
    }
    if (result.family_id && !createdHouseholdId) {
      dispatch(getIntegrationMembers(result.family_url));
    } else {
      if (pageWorkflow === 'morningstar' && createdHouseholdId) {
        dispatch(
          linkHouseholdMemberToIntegration(
            `/api/v3/household/${createdHouseholdId}/integration/${pageWorkflow}/link_householdmember/${result.id}.${result.family_id}`,
            'new',
            createdHouseholdId,
            pageWorkflow
          )
        );
      } else {
        dispatch(createHouseholdIntegration(result.link_url));
      }
    }
  };

  const selectOnClick = (result) => {
    setResults([]);
    setIsLoadingResults(true);
    dispatch(getIntegrationMembers(result.family_url));
  };

  const currentlyImportingMember = (result) => {
    return (
      (currentImport && currentImport.id === result.id && loadingMembers) ||
      (importingAll &&
        'family_id' in currentImport &&
        'family_id' in result &&
        currentImport.family_id === result.family_id)
    );
  };

  const generateButton = (result) => {
    let importButtonText = 'Import';
    let onClickHandler = () => importOnClick(result);
    if (pageWorkflow === 'morningstar' && !previousSearchResults) {
      importButtonText = 'Select';
      onClickHandler = () => selectOnClick(result);
    }
    if (result.households.length === 0) {
      return (
        <ImportLabel onClick={onClickHandler}>
          {currentlyImportingMember(result) && (
            <LoadingAnimation
              dots={true}
              color={colors.lightBlue}
              smaller={true}
            />
          )}
          {importButtonText}
        </ImportLabel>
      );
    }
    return (
      <ViewLink to={`/household/${result.households[0]}/summary`}>
        {result.just_created && (
          <img src={check} alt="check" data-image="created-check" />
        )}{' '}
        View
      </ViewLink>
    );
  };

  return (
    <>
      {showHouseholdModal && (
        <SimpleModal
          hide={() => setShowHouseholdModal(!showHouseholdModal)}
          content={modalContent}
        />
      )}
      <Page>
        <ColumnContainerLayout>
          <PageHeader content={headerContent.integrationsHousehold} />
          {!loadingIntegrations && integrationsList.length === 0 && (
            <EmptyContainer>
              <EmptyImage
                src={noIntegrations}
                alt="No Integrations"
                data-image="no-integrations"
              />
              <EmptyText>No Active Integrations</EmptyText>
              <EmptyLink to={'/integration'}>Activate Here</EmptyLink>
            </EmptyContainer>
          )}
          <CardsContainer>
            {loadingIntegrations &&
              [1, 2, 3].map((item) => {
                return (
                  <CardPlaceholder key={item}>
                    <CardImageContainer></CardImageContainer>
                  </CardPlaceholder>
                );
              })}
            {hasSelectedIntegration ? (
              <SelectedIntegrationContainer
                style={{ borderTop: `8px solid ${selectedIntegration.color}` }}
              >
                <LeftContainer>
                  <HeadingContainer>
                    <BackArrow
                      src={arrowRightDark}
                      alt="back"
                      data-image="back-arrow"
                      onClick={() => goBack()}
                    />
                    <SecondaryHeading margin="0">
                      Import from {selectedIntegration.name}
                    </SecondaryHeading>
                  </HeadingContainer>
                  <IntegrationSearchBar
                    selectedIntegration={selectedIntegration}
                    searchValue={searchValue}
                    updateSearchValue={setSearchValue}
                    onKeyDown={onKeyDown}
                    searchIntegrationOnClick={searchIntegrationOnClick}
                  />
                  {showResults && (
                    <ResultsContainer>
                      <TertiaryHeading>
                        {previousSearchResults && (
                          <BackToHouseholdsArrow
                            src={arrowRightDark}
                            alt="back"
                            data-image="back-arrow-results"
                            onClick={() => searchIntegrationOnClick()}
                          />
                        )}
                        Results
                      </TertiaryHeading>
                      <ResultsList>
                        {isLoadingResults ? (
                          <LoadingAnimation />
                        ) : errorMessage ? (
                          <ErrorContainer>
                            <Error
                              errorMessage={errorMessage}
                              theme={ErrorThemes.inverted}
                            />
                          </ErrorContainer>
                        ) : results && results.length !== 0 ? (
                          results.map((result, index) => {
                            return (
                              <IntegrationSearchResult
                                key={index}
                                result={result}
                                generateButton={generateButton}
                              />
                            );
                          })
                        ) : (
                          <ResultsMessage>No Results</ResultsMessage>
                        )}
                      </ResultsList>
                      {showMoreMessage && (
                        <MoreResultsMessage>
                          Additional results available, refine query.
                        </MoreResultsMessage>
                      )}
                    </ResultsContainer>
                  )}
                </LeftContainer>
                <RightContainer>
                  <CardImage
                    src={selectedIntegration.image}
                    alt={selectedIntegration.name}
                    $maxWidth={selectedIntegration?.maxWidth}
                    data-image={selectedIntegration.key}
                  />
                </RightContainer>
              </SelectedIntegrationContainer>
            ) : (
              integrationsList.map((integration, index) => {
                return (
                  <Card
                    key={index}
                    style={{ borderTop: `8px solid ${integration.color}` }}
                    onClick={() => integrationOnClick(integration)}
                  >
                    <CardImageContainer>
                      <CardImage
                        src={integration.image}
                        alt={integration.name}
                        data-image={integration.key}
                        $maxWidth={selectedIntegration?.maxWidth}
                      />
                    </CardImageContainer>
                  </Card>
                );
              })
            )}
          </CardsContainer>
        </ColumnContainerLayout>
      </Page>
    </>
  );
};

const ErrorContainer = styled.div`
  max-width: 400px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  div {
    flex: 0 0 auto;
  }
`;

const ViewLink = styled(Link)`
  color: ${colors.lightBlue};
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${colors.blue};
  }
  img {
    width: 12px;
    margin-right: 3px;
  }
`;

const ImportLabel = styled.span`
  color: ${colors.lightBlue};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  &:hover {
    color: ${colors.blue};
  }
`;

const SelectedIntegrationContainer = styled.div`
  @media ${maxDevice.sideNav} {
    margin: -25px 10px 0 10px;
  }

  padding-top: 25px;
  margin: -30px 10px 0 10px;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: flex-end;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
`;

const LeftContainer = styled.div`
  @media ${maxDevice.tablet} {
    margin-right: 0px;
    width: 100%;
  }
  flex: 1 1 auto;
  margin-bottom: 40px;
  margin-right: 20px;
`;

const ResultsContainer = styled.div`
  margin-top: 20px;
  max-width: 530px;
`;

const ResultsMessage = styled.p`
  font-size: 13px;
  color: ${colors.paleGrey};
`;

const ResultsList = styled.div`
  margin-top: 5px;
`;

const RightContainer = styled.div`
  flex: 0 0 auto;
  img {
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 80px;
    margin-bottom: 15px;
  }
`;

const BackArrow = styled.img`
  margin-right: 15px;
  height: 15px;
  width: 15px;
  transform: rotate(180deg);
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const BackToHouseholdsArrow = styled.img`
  margin-right: 15px;
  height: 15px;
  width: 15px;
  transform: rotate(180deg);
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const MoreResultsMessage = styled.p`
  text-align: center;
  color: ${colors.paleGrey};
  font-size: 14px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

const ColumnContainerLayout = styled.div`
  margin: 10px 0 40px 0;
`;

const CardsContainer = styled.div`
  @media ${maxDevice.laptop} {
    margin: 10px 30px 20px 30px;
  }
  @media ${maxDevice.mobileS} {
    margin: 10px 15px 15px 15px;
  }
  @media ${maxDevice.sideNav} {
    margin: 0 10px 15px 10px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 10px 30px 20px 30px;
`;

const Card = styled.div`
  @media ${maxDevice.mobileS} {
    padding: 15px 13px;
    margin: 0 0px 10px 0px;
  }
  @media ${maxDevice.sideNav} {
    min-width: 250px;
    margin: 0 0px 20px 0px;
  }
  @media ${maxDevice.laptop} {
    width: calc(50% - 30px);
    max-width: calc(50% - 30px);
    min-width: calc(50% - 20px);
    padding: 20px 0;
    margin: 0 10px 20px 10px;
  }
  @media ${maxDevice.tablet} {
    width: 100%;
    max-width: calc(100% - 30px);
    min-width: calc(100% - 20px);
  }
  box-shadow: ${boxShadows.boxShadowSoft};
  border-radius: 0 0 5px 5px;
  background: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex: 1 1 360px;
  max-width: 320px;
  min-width: 300px;
  padding: 20px 25px;
  margin: 0 10px 20px 10px;
  cursor: pointer;
  height: 85px;
  &:hover {
    transform: scale(1.01);
  }
`;

const CardPlaceholder = styled(Card)`
  border-top: 8px solid ${colors.lightGrey};
  box-shadow: none;
  background: ${colors.lighterGrey};
`;

const CardImageContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-height: 70px;
  width: 100%;
`;

const CardImage = styled.img`
  max-width: 240px;
  width: 100%;
  @media ${maxDevice.laptop} {
    max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : '250px')};
  }
  @media ${maxDevice.mobileL} {
    max-width: 200px;
  }
`;

const EmptyContainer = styled.div`
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 40px;
`;

const EmptyImage = styled.img`
  max-width: 450px;
  width: 100%;
`;

const EmptyText = styled.p`
  margin: 30px 0 10px 0;
  font-size: 24px;
  font-weight: ${fonts.semiBold};
  color: ${colors.paleGrey};
`;

const EmptyLink = styled(Link)`
  font-size: 16px;
  font-weight: ${fonts.semiBold};
  color: ${colors.lightBlue};
  &:hover {
    color: ${colors.blue};
  }
`;

export default HouseholdNewIntegrations;
