import { DiscoveryTypes as DISCOVERY } from '../actions';

const initialState = {
  inboundLink: false,
};

const discovery = (state = initialState, action) => {
  switch (action.type) {
    case DISCOVERY.GET_INBOUND_VALUE:
      return {
        ...state,
        inboundLink: action.payload.enabled,
      };
    case DISCOVERY.GET_DISCOVERY_LIST:
      return {
        ...state,
        discovery: action.payload,
        justUpdatedAdvisor: false,
      };
    case DISCOVERY.CREATE_DISCOVERY:
      return {
        ...state,
        createdDiscovery: action.payload,
      };
    default:
      return state;
  }
};

export default discovery;
