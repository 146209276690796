/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector, useDispatch } from 'react-redux';
import { SketchField, Tools } from 'react-sketch2';
import { DetectHotkeys } from '.';
import { toggleBlackMarker } from '../store/actions';
import { SendTrackingEvent } from '../utils';

const MarkerCanvas = () => {
  const dispatch = useDispatch();
  const markerTool = useRef();
  const {
    showingCanvas,
    usingBlackMarker,
    usingRedMarker,
    usingCustomMarker,
    usingSelect,
    customPenColor,
    invocationId,
  } = useSelector((state) => ({
    showingCanvas: state.marker.showingCanvas,
    usingBlackMarker: state.marker.usingBlackMarker,
    usingRedMarker: state.marker.usingRedMarker,
    usingCustomMarker: state.marker.usingCustomMarker,
    usingSelect: state.marker.usingSelect,
    customPenColor: state.marker.customPenColor,
    invocationId: state.configs.invocationId,
  }));

  const [canvasHeight, setCanvasHeight] = useState();
  const [usedHotkey, setUsedHotkey] = useState(false);

  useHotkeys('ctrl+m, ctrl+`', () => {
    setUsedHotkey(true);
    dispatch(toggleBlackMarker(!usingBlackMarker));
  });

  const determineTool = () => {
    if (usingBlackMarker || usingRedMarker || usingCustomMarker) {
      return Tools.Pencil;
    }
    if (usingSelect) {
      return Tools.Select;
    }
  };
  const determineColor = () => {
    if (usingBlackMarker) {
      return 'black';
    }
    if (usingRedMarker) {
      return 'red';
    }
    if (usingCustomMarker) {
      return customPenColor;
    }
  };

  useEffect(() => {
    if (showingCanvas) {
      setCanvasHeight(determineHeight());
      SendTrackingEvent(invocationId, 'action', 'marker_enable', null, {
        hotkey: usedHotkey,
      });
    }
  }, [showingCanvas]);

  const determineHeight = () => {
    let height = '2000px';
    if (document.getElementById('outlet')?.scrollHeight) {
      const contentHeight = document.getElementById('outlet')?.scrollHeight;
      const viewportHeight = window.innerHeight - 55;
      height = `${Math.max(contentHeight, viewportHeight)}px`;
    }
    return height;
  };

  const removeSelected = () => {
    if (showingCanvas) {
      markerTool.current.removeSelected();
      SendTrackingEvent(invocationId, 'action', 'marker_action', null, {
        action: 'erase',
        hotkey: true,
      });
    }
  };

  const undoDrawing = () => {
    if (markerTool.current.canUndo()) {
      markerTool.current.undo();
      SendTrackingEvent(invocationId, 'action', 'marker_action', null, {
        action: 'undo',
        hotkey: true,
      });
    }
  };

  const redoDrawing = () => {
    if (markerTool.current.canRedo()) {
      markerTool.current.redo();
      SendTrackingEvent(invocationId, 'action', 'marker_action', null, {
        action: 'redo',
        hotkey: true,
      });
    }
  };

  return (
    showingCanvas && (
      <>
        <DetectHotkeys
          undoFunction={undoDrawing}
          redoFunction={redoDrawing}
          deleteFunction={removeSelected}
        />
        <SketchField
          height={canvasHeight}
          tool={determineTool()}
          lineColor={determineColor()}
          value={null}
          className="marker-container"
          lineWidth={3}
          enableRemoveSelected={true}
          expandControls={true}
          ref={markerTool}
        />
      </>
    )
  );
};

export default MarkerCanvas;
