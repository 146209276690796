import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { colors, maxDevice } from '../../styles/variables';
import { milestone as milestoneIcon } from '../../assets';
import { DateFormat, DateFromNowUTC } from '../../utils';

const MilestoneListItem = ({
  milestone,
  showComments = false,
  isPanel = false,
  noBorderBottom = false,
}) => {
  return (
    <ThemeProvider theme={{ showComments, isPanel }}>
      {isPanel ? (
        <MilestoneContainer noBorderBottom={noBorderBottom}>
          <MilestoneIcon
            src={milestoneIcon}
            alt={milestone.name}
            data-image={milestone.name}
          />
          <MilestoneName>
            <span>{milestone?.name || 'Unknown Milestone'}</span>
          </MilestoneName>
          <MilestoneDate>{`Updated ${DateFromNowUTC(
            milestone.created_utc
          )}`}</MilestoneDate>
        </MilestoneContainer>
      ) : (
        <MilestoneContainer>
          <MilestoneName>
            <MilestoneIcon
              src={milestoneIcon}
              alt={milestone.name}
              data-image={milestone.name}
            />
            <span>{milestone?.name || 'Unknown Milestone'}</span>
          </MilestoneName>
          {isPanel || (
            <MilestoneAdvisor>{milestone?.advisor?.name}</MilestoneAdvisor>
          )}
          <MilestoneDate>
            {DateFormat(milestone?.created_utc, 'dddd, MMM D YYYY')}
          </MilestoneDate>
          {showComments && (
            <MilestoneComment>{milestone.comment}</MilestoneComment>
          )}
        </MilestoneContainer>
      )}
    </ThemeProvider>
  );
};

const MilestoneContainer = styled.div`
  @media ${maxDevice.tablet} {
    font-size: 12px;
  }
  padding: ${(props) => (props.theme.isPanel ? '12px 0' : '15px 0')};
  height: ${(props) => (props.theme.isPanel ? null : '25px')};
  min-height: ${(props) => (props.theme.isPanel ? '20px' : null)};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${(props) =>
    props.noBorderBottom ? null : `1px solid ${colors.lightGrey}`};

  font-size: 14px;
  color: ${colors.darkGrey};
  &:hover {
    background: ${(props) => (props.theme.isPanel ? null : colors.lighterGrey)};
  }
`;

const MilestoneName = styled.div`
  width: ${(props) =>
    props.theme.isPanel ? '100%' : props.theme.showComments ? '25%' : '33%'};
  padding: ${(props) => (props.theme.isPanel ? '0' : '0 10px')};
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-right: 10px;
  }
`;

const MilestoneIcon = styled.img`
  height: ${(props) => (props.theme.isPanel ? '18px' : '20px')};
  width: ${(props) => (props.theme.isPanel ? '18px' : '20px')};
  min-height: 18px;
  min-width: 18px;
  margin-right: 15px;
`;

const MilestoneAdvisor = styled.div`
  width: ${(props) => (props.theme.showComments ? '20%' : '33%')};
  padding-right: 10px;
  span {
    margin-right: 10px;
  }
`;

const MilestoneDate = styled.div`
  width: ${(props) =>
    props.theme.isPanel ? '100%' : props.theme.showComments ? '20%' : '33%'};
  text-align: ${(props) => (props.theme.isPanel ? 'right' : 'left')};
  color: ${(props) =>
    props.theme.isPanel ? colors.paleGrey : colors.darkGrey};
  padding-right: ${(props) => (props.theme.isPanel ? '0' : '10px')};
`;

const MilestoneComment = styled.div`
  width: 35%;
  padding-right: 10px;
  max-height: 40px;
  overflow-x: auto;
`;

MilestoneListItem.propTypes = {
  milestone: PropTypes.object,
  showComments: PropTypes.bool,
  isPanel: PropTypes.bool,
  noBorderBottom: PropTypes.bool,
};

export default MilestoneListItem;
