import { useHotkeys } from 'react-hotkeys-hook';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleBlackMarker,
  toggleRedMarker,
  deleteCanvas,
  toggleSelectTool,
  togglePalette,
  toggleCustomMarker,
} from '../store/actions';
import { SendTrackingEvent } from '../utils';

const DetectHotkeys = ({ undoFunction, redoFunction, deleteFunction }) => {
  const dispatch = useDispatch();
  const {
    usingBlackMarker,
    // usingRedMarker,
    // usingCustomMarker,
    // usingSelect,
    invocationId,
  } = useSelector((state) => ({
    usingBlackMarker: state.marker.usingBlackMarker,
    // usingRedMarker: state.marker.usingRedMarker,
    // usingCustomMarker: state.marker.usingCustomMarker,
    // usingSelect: state.marker.usingSelect,
    // customPenColor: state.marker.customPenColor,
    invocationId: state.configs.invocationId,
  }));

  const markerSendTracking = (dispatchFunc, name) => {
    dispatch(dispatchFunc);
    return SendTrackingEvent(invocationId, 'action', 'marker_action', null, {
      action: name,
      hotkey: true,
    });
  };

  useHotkeys('ctrl+m, ctrl+`', () =>
    dispatch(toggleBlackMarker(!usingBlackMarker))
  );
  useHotkeys('r', () =>
    markerSendTracking(toggleRedMarker(true), 'red_marker')
  );
  useHotkeys('b', () =>
    markerSendTracking(toggleBlackMarker(true), 'black_marker')
  );
  useHotkeys('c', () => markerSendTracking(toggleCustomMarker(true), 'custom'));
  useHotkeys('s, e', () =>
    markerSendTracking(toggleSelectTool(true), 'select')
  );

  // useHotkeys('r', () =>
  //   markerSendTracking(toggleRedMarker(!usingRedMarker), 'red_marker')
  // );
  // useHotkeys('b', () =>
  //   markerSendTracking(toggleBlackMarker(!usingBlackMarker), 'black_marker')
  // );
  // useHotkeys('s, e', () =>
  //   markerSendTracking(toggleSelectTool(!usingSelect), 'select')
  // );
  // useHotkeys('c', () =>
  //   markerSendTracking(toggleCustomMarker(!usingCustomMarker), 'custom')
  // );
  useHotkeys('p', () => markerSendTracking(togglePalette(true), 'color'));
  useHotkeys('u', () => undoFunction());
  useHotkeys('i, shift+u', () => redoFunction());
  useHotkeys('backspace, del, delete', () => deleteFunction());
  useHotkeys('d, t, x, esc, escape', () => {
    dispatch(deleteCanvas());
    SendTrackingEvent(invocationId, 'action', 'marker_disable', null, {
      hotkey: true,
    });
    SendTrackingEvent(invocationId, 'action', 'marker_action', null, {
      action: 'clear',
      hotkey: true,
    });
  });
  return <></>;
};

DetectHotkeys.propTypes = {
  undoFunction: PropTypes.func,
  redoFunction: PropTypes.func,
  deleteFunction: PropTypes.func,
};

export default DetectHotkeys;
