/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  LoginModal,
  AuthCodeModal,
  RecoveryModal,
  HelpModal,
  ResendCodeModal,
} from '../containers';
import { Error } from '../components';
import { getInviteDetails, getUserInfo } from '../store/actions';
import {
  BackgroundPage,
  CenteredContainer,
} from '../styles/library/layoutStyles';
import {
  CenterModal,
  ModalHeader,
  ModalHeadingConfig,
  ModalContent,
} from '../styles/library/modalStyles';
import { UpdatePageTitle, CloseDropdowns } from './../utils';

const Auth = ({ isConsumer, isShared = false }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    authBackground,
    modalTitle,
    showLogin,
    SSORedirectURL,
    showAuthCode,
    showRecovery,
    showHelp,
    showResendScreen,
    showCodeSentScreen,
    showError,
    errorMessage,
    inviteDetails,
    isTimeout,
  } = useSelector((state) => ({
    authBackground: state.configs.authBackground,
    modalTitle: state.user.modalTitle,
    showLogin: state.user.showLoginScreen,
    SSORedirectURL: state.configs.SSORedirectURL,
    showAuthCode: state.user.showAuthCode,
    showRecovery: state.user.showRecoveryScreen,
    showHelp: state.user.showHelpScreen,
    showResendScreen: state.user.showResendScreen,
    showCodeSentScreen: state.user.showCodeSentScreen,
    showError: state.user.responseError.showError,
    errorMessage: state.user.responseError.message,
    inviteDetails: state.user.inviteDetails,
    isTimeout: state.user.isTimeout,
  }));
  const [backgroundTheme, setBackgroundTheme] = useState({
    backgroundImage: null,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundAttachment: 'fixed',
    opacity: 0.9,
  });

  UpdatePageTitle('Login');
  CloseDropdowns();

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    if (isShared) {
      dispatch(getInviteDetails(params.id));
    }
  }, [isShared]);

  useEffect(() => {
    if (authBackground) {
      setBackgroundTheme({
        ...backgroundTheme,
        backgroundImage: authBackground,
      });
    }
  }, [authBackground]);

  return (
    <ThemeProvider theme={backgroundTheme}>
      <BackgroundPage>
        <CenteredContainer>
          <CenterModal>
            <ModalHeader>
              <ModalHeadingConfig>{modalTitle}</ModalHeadingConfig>
            </ModalHeader>
            <ModalContent>
              {showLogin && (
                <LoginModal
                  url={SSORedirectURL}
                  isShared={isShared}
                  householdName={inviteDetails?.household}
                  isTimeout={isTimeout}
                />
              )}
              {showAuthCode && <AuthCodeModal isLogin={true} />}
              {showRecovery && <RecoveryModal isConsumer={isConsumer} />}
              {showHelp && <HelpModal isConsumer={isConsumer} />}
              {(showResendScreen || showCodeSentScreen) && (
                <ResendCodeModal
                  showResendScreen={showResendScreen}
                  showCodeSentScreen={showCodeSentScreen}
                />
              )}
            </ModalContent>
            {showError && <Error errorMessage={errorMessage} />}
          </CenterModal>
        </CenteredContainer>
      </BackgroundPage>
    </ThemeProvider>
  );
};

Auth.propTypes = {
  isConsumer: PropTypes.bool,
  isShared: PropTypes.bool,
};

export default Auth;
