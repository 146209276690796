import { NotificationsContainer } from '../containers';
import { UpdatePageTitle, CloseDropdowns } from '../utils';

const Notifications = () => {
  UpdatePageTitle('Notifications');
  CloseDropdowns();

  return <NotificationsContainer />;
};

export default Notifications;
