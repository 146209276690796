import styled from 'styled-components';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import { checkboxGreen, chevronRightLightGrey, square } from '../assets';
import { colors, fonts, boxShadows } from '../styles/variables';
import { UseContextMenu } from '../utils';

const ContextMenu = ({
  menu,
  sideNavWidth,
  containerId,
  showMenu,
  setShowMenu,
  menuWidth = 150,
  subMenuWidth = 140,
}) => {
  const { xPos, yPos, invertSubMenuX, invertSubMenuY } = UseContextMenu(
    sideNavWidth,
    containerId,
    showMenu,
    setShowMenu,
    menuWidth,
    subMenuWidth,
    menu?.items
  );

  const visibleMenuItems = menu?.items.filter((item) => !item.isHidden);

  return (
    showMenu && (
      <ContextMenuContainer width={menuWidth} top={yPos} left={xPos}>
        <MenuHeading>{menu.heading}</MenuHeading>
        <MenuContent>
          {visibleMenuItems.map((menuItem, index) => {
            return (
              <MenuItem
                key={index}
                onClick={menuItem.onClick}
                data-text={menuItem.name}
              >
                {GenerateMenuItemContent(
                  menuItem,
                  index === visibleMenuItems?.length - 1
                )}
                {menuItem.children && (
                  <SubMenuContainer
                    invertX={invertSubMenuX}
                    invertY={invertSubMenuY}
                    width={subMenuWidth}
                  >
                    {menuItem.children.map((childItem, index) => {
                      return (
                        !childItem.isHidden && (
                          <MenuItem
                            key={index}
                            onClick={childItem.onClick}
                            data-text={childItem.name}
                          >
                            {childItem.tooltip && (
                              <Tooltip
                                title={childItem.tooltip}
                                position="right"
                                trigger="mouseenter"
                                followCursor="true"
                                delay={0}
                                arrow={true}
                                arrowSize={'small'}
                                animation={'fade'}
                                animateFill={false}
                                transitionFlip={false}
                                size={'small'}
                              >
                                {GenerateMenuItemContent(
                                  childItem,
                                  index === menuItem.children.length - 1
                                )}
                              </Tooltip>
                            )}
                            {!childItem.tooltip &&
                              GenerateMenuItemContent(
                                childItem,
                                index === menuItem.children.length - 1
                              )}
                          </MenuItem>
                        )
                      );
                    })}
                  </SubMenuContainer>
                )}
              </MenuItem>
            );
          })}
        </MenuContent>
      </ContextMenuContainer>
    )
  );
};

export const GenerateMenuItemContent = (item, isLast) => {
  return (
    <MenuItemContent isLast={isLast}>
      {item.image && (
        <>
          <MenuItemImage
            src={item.image}
            alt="img"
            data-image={item.name || item.label}
          />
          <MenuItemText>{item.label}</MenuItemText>
        </>
      )}
      {item.hasToggle && (
        <OptionValue>
          <CheckboxItem
            src={item.checked ? checkboxGreen : square}
            alt="box"
            data-image={`checkbox-${item.checked ? 'checked' : 'unchecked'}`}
            style={item.checked ? { opacity: 1 } : { opacity: 0.4 }}
          />
          <OptionLabel>{item.label}</OptionLabel>
        </OptionValue>
      )}
      {item.children && (
        <MoreChevron
          src={chevronRightLightGrey}
          alt="img"
          data-image={item.name || item.label}
        />
      )}
    </MenuItemContent>
  );
};

ContextMenu.propTypes = {
  menu: PropTypes.object.isRequired,
  sideNavWidth: PropTypes.string,
  containerId: PropTypes.string,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  menuWidth: PropTypes.number,
  subMenuWidth: PropTypes.number,
};

export const ContextMenuContainer = styled.div`
  position: absolute;
  z-index: 10;
  background: white;
  width: ${(props) => (props.menuWidth ? `${props.menuWidth}px` : '150px')};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  border-radius: 5px;
  box-shadow: ${boxShadows.boxShadow};
  font-size: 12px;
  z-index: 10;
  /* &:after {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 3px;
    top: -6px;
    z-index: 9999 !important;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${colors.darkGrey};
  } */
`;

//Need to make menu go to the left if too close to the right of click
export const SubMenuContainer = styled.div`
  position: absolute;
  z-index: 10;
  background: white;
  width: ${(props) => (props.width ? `${props.width}px` : '140px')};
  border-radius: 5px;
  box-shadow: ${boxShadows.boxShadow};
  font-size: 12px;
  z-index: 11;
  top: ${(props) => (props.invertY ? 'auto' : 0)};
  bottom: ${(props) => (props.invertY ? 0 : 'auto')};
  left: ${(props) => (props.invertX ? 'auto' : `${props.width + 5}px`)};
  right: ${(props) => (props.invertX ? `${props.width + 5}px` : 'auto')};
  /* display: none; */
  visibility: hidden;
`;

export const MenuHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  padding: 8px 12px;
  border-radius: 5px 5px 0px 0px;
  background: ${colors.darkGrey};
  color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuContent = styled.div`
  /* padding: 2px 12px; */
`;

export const MenuItem = styled.div`
  /* padding: 8px 12px; */
  cursor: pointer;
  display: flex;
  position: relative;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    background: ${colors.paleBlue};
    ${SubMenuContainer} {
      /* display: block; */
      visibility: visible;
    }
  }
`;

export const MenuItemContent = styled.div`
  padding: 8px 2px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${(props) =>
    props.isLast ? '1px solid transparent' : `1px solid ${colors.lightGrey}`};
  flex: 1 1 auto;
  margin: 0 10px;
`;

export const MenuItemImage = styled.img`
  width: 16px;
  height: 16px;
  max-height: 14px;
  margin-right: 7px;
  flex: 0 0 auto;
`;

export const MoreChevron = styled.img`
  width: auto;
  height: 9px;
  max-height: 9px;
  margin-left: 5px;
  flex: 0 0 auto;
`;

export const MenuItemText = styled.p`
  font-size: 12px;
  flex: 1 1 auto;
`;

export const OptionValue = styled.div`
  margin: 0;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const OptionLabel = styled.div`
  flex: 1 1 auto;
  margin-left: 7px;
`;

export const CheckboxItem = styled.img`
  width: 16px;
  height: 16px;
  max-height: 14px;
  flex: 0 0 auto;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

export default ContextMenu;
