/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Pluralize from 'react-pluralize';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import {
  AvailableSeats,
  Button,
  LoadingAnimation,
  PageHeader,
  RecurlyBilling,
  SimpleModal,
  SmallTable,
  TabMenu,
} from '../../components';
import {
  getBillingInfo,
  getBillingSubscription,
  getInvoices,
  getSubscriptionOptions,
  reenableSubscription,
  resubscribePlan,
} from '../../store/actions';
import {
  headerContent,
  FormatCurrency,
  ConvertObjectToArray,
  PruneObjectNulls,
  DateFormat,
} from '../../utils';
import { Page } from '../../styles/library/layoutStyles';
import { EmailLink } from '../../styles/library/fontStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  boxShadows,
  colors,
  maxDevice,
  inputColors,
  messageColors,
  fonts,
} from '../../styles/variables';

const BillingContainer = ({ customerId }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    billing,
    countries,
    invoices,
    isBillingInfoError,
    updateBillingError,
    isBillingInvoiceError,
    isBillingSubscriptionError,
    reenableSubscriptionError,
    subscription,
    subscriptionOptions,
    subscriptionOptionError,
    resubscribeError,
    teamSettings,
    user,
    showACH,
    recurlyKey,
  } = useSelector((state) => ({
    billing: state.user.billing,
    countries: state.configs.countries,
    invoices: state.user.invoices,
    isBillingInfoError: state.user.isBillingInfoError,
    updateBillingError: state.user.updateBillingError,
    isBillingInvoiceError: state.user.isBillingInvoiceError,
    isBillingSubscriptionError: state.user.isBillingSubscriptionError,
    reenableSubscriptionError: state.user.reenableSubscriptionError,
    subscription: state.user.subscription,
    subscriptionOptions: state.user.subscriptionOptions,
    subscriptionOptionError: state.user.subscriptionOptionError,
    resubscribeError: state.user.resubscribeError,
    teamSettings: state.user.teamSettings,
    user: state.user.user,
    showACH: state.user.showACH,
    recurlyKey: state.configs.recurlyKey,
  }));
  const [activeTab, setActiveTab] = useState('info');
  const [billingInfo, setBillingInfo] = useState({
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    phone: '',
    country: '',
    state: '',
    url: '',
  });
  const [bankAccountInfo, setBankAccountInfo] = useState({
    routing_number: '',
    account_number: '',
    account_number_confirmation: '',
    account_type: 'checking',
    name_on_account: '',
  });
  const [invoiceList, setInvoiceList] = useState([]);
  const [loadedInvoices, setLoadedInvoices] = useState(false);
  const [showReenableModal, setShowReenableModal] = useState(false);
  const [hasBillingError, setHasBillingError] = useState(false);
  const [loadedSubscription, setLoadedSubscription] = useState(false);
  const [hasSubscriptionPlans, setHasSubscriptionPlans] = useState(false);
  const [loadedSubscriptionPlans, setLoadedSubscriptionPlans] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [advisorCount, setAdvisorCount] = useState(0);
  const [advisorCountError, setAdvisorCountError] = useState(false);
  const [delegateCount, setDelegateCount] = useState(0);
  const [isUpdatingCount, setIsUpdatingCount] = useState(false);
  const [isResubscribing, setIsResubscribing] = useState(false);
  const [resubscribeErrorMessage, setResubscribeErrorMessage] = useState('');

  const headingOnClick = (value) => {
    setInvoiceHeadings(
      invoiceHeadings.map((heading) => {
        if (heading.value === value.value) {
          heading.ascending = !heading.ascending;
          heading.active = true;
        } else {
          heading.active = false;
        }
        return heading;
      })
    );
  };
  const [invoiceHeadings, setInvoiceHeadings] = useState([
    { text: 'Invoice', display: true },
    {
      text: 'Posted',
      value: 'invoice_created_at',
      display: true,
      sortable: true,
      ascending: false,
      active: true,
      onClick: headingOnClick,
    },
    {
      text: 'Due Date',
      value: 'due_on',
      display: true,
      sortable: true,
      ascending: false,
      onClick: headingOnClick,
    },
    { text: 'Status', display: true, align: 'center' },
    { text: 'Amount', display: true, align: 'center' },
    { text: 'Actions', display: false },
  ]);

  useEffect(() => {
    setHasBillingError(false);
    if (activeTab === 'invoices') {
      if (isBillingInvoiceError) {
        setHasBillingError(true);
      }
      return dispatch(getInvoices(customerId));
    } else if (activeTab === 'subscription') {
      if (isBillingSubscriptionError) {
        setHasBillingError(true);
      }
      dispatch(getSubscriptionOptions(customerId));
      return dispatch(getBillingSubscription(customerId));
    } else {
      if (isBillingInfoError) {
        setHasBillingError(true);
        dispatch(getBillingInfo(customerId));
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (location) {
      if (location?.search && location?.search.includes('?panel=')) {
        const tabSplit = location?.search.split('?panel=');
        const tabValue = tabSplit[1];
        const tabOptions = tabs.map((tab) => tab.value);
        if (tabOptions.includes(tabValue)) {
          setActiveTab(tabValue);
        } else {
          setActiveTab('info');
          location.search = `panel=info`;
        }
      } else {
        setActiveTab('info');
        location.search = `panel=info`;
      }
    }
  }, [location]);

  useEffect(() => {
    if (invoiceHeadings && activeTab === 'invoices') {
      const activeHeading = invoiceHeadings.find((heading) => heading.active);
      let isAscending = activeHeading.ascending;
      let sortValue = activeHeading.value;
      const sortedInvoices = [...invoiceList].sort((a, b) => {
        if (isAscending) {
          return new Date(a[sortValue]) - new Date(b[sortValue]);
        } else {
          return new Date(b[sortValue]) - new Date(a[sortValue]);
        }
      });
      setInvoiceList(sortedInvoices);
    }
  }, [invoiceHeadings]);

  useEffect(() => {
    if (subscriptionOptions) {
      if (subscriptionOptions.hasOwnProperty('plans')) {
        const plans = ConvertObjectToArray(subscriptionOptions?.plans, true);
        if (plans && plans.length !== 0) {
          setHasSubscriptionPlans(true);
        }
        setSubscriptionPlans(plans);
      }
      setLoadedSubscriptionPlans(true);
      if (subscriptionOptions.hasOwnProperty('advisors')) {
        setAdvisorCount(subscriptionOptions.advisors);
      }
      if (subscriptionOptions.hasOwnProperty('delegates')) {
        setDelegateCount(subscriptionOptions.delegates);
      }
      setLoadedSubscription(true);
      setIsUpdatingCount(false);
      setIsResubscribing(false);
    }
  }, [subscriptionOptions]);

  const tabOnClick = (tab) => {
    location.search = `panel=${tab}`;
    navigate(location, { state: { panel: tab } });
  };

  const generateBillingError = () => {
    if (reenableSubscriptionError && activeTab === 'subscription') {
      return <>{reenableSubscriptionError}</>;
    }
    return (
      <>
        If you would like to update your billing information, please contact our
        Account team at{' '}
        <EmailLink href="mailto:billing@asset-map.com">
          billing@asset-map.com
        </EmailLink>{' '}
        or call 888-664-8850.
      </>
    );
  };

  useEffect(() => {
    if (customerId) {
      dispatch(getBillingInfo(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    if (invoices) {
      setInvoiceList(invoices);
      setLoadedInvoices(true);
    }
  }, [invoices]);

  useEffect(() => {
    if (billing) {
      setBillingInfo(PruneObjectNulls(billing));
    }
  }, [billing]);

  useEffect(() => {
    if (subscription) {
      setLoadedSubscription(true);
      if (subscription.billing) {
        setBillingInfo(subscription.billing);
      }
    }
  }, [subscription]);

  useEffect(() => {
    if (reenableSubscriptionError) {
      setLoadedSubscription(true);
      setHasBillingError(true);
    }
  }, [reenableSubscriptionError]);

  useEffect(() => {
    if (isBillingInfoError) {
      setHasBillingError(true);
    }
  }, [isBillingInfoError]);

  useEffect(() => {
    if (isBillingInvoiceError) {
      setHasBillingError(true);
    }
  }, [isBillingInvoiceError]);

  useEffect(() => {
    if (isBillingSubscriptionError) {
      setHasBillingError(true);
    }
  }, [isBillingSubscriptionError]);

  useEffect(() => {
    if (resubscribeError) {
      if (resubscribeError?.data?.plan_code) {
        setResubscribeErrorMessage(
          `Error: ${resubscribeError?.data?.plan_code}`
        );
      } else if (resubscribeError?.data?.billing_info) {
        setResubscribeErrorMessage(
          `Error: ${resubscribeError?.data?.billing_info}`
        );
      } else if (resubscribeError?.data?.details) {
        setResubscribeErrorMessage(`Error: ${resubscribeError?.data?.details}`);
      } else if (resubscribeError?.data?.code) {
        setResubscribeErrorMessage(`Error: ${resubscribeError?.data?.code}`);
      } else {
        setResubscribeErrorMessage(`Unknown Error: Contact Support`);
      }
      setLoadedSubscription(true);
      setIsResubscribing(false);
    }
  }, [resubscribeError]);

  useEffect(() => {
    if (subscriptionOptionError) {
      console.log('subscriptionOptionError', subscriptionOptionError);
    }
  }, [subscriptionOptionError]);

  const tabs = [
    {
      text: 'Billing Info',
      value: 'info',
    },
    {
      text: 'Invoices',
      value: 'invoices',
    },
    {
      text: 'Subscription',
      value: 'subscription',
    },
  ];

  const billingInfoOnChange = (e, select = false, name, setter) => {
    if (select) {
      setBillingInfo({
        ...billingInfo,
        [name]: e.value,
      });
      setter(e);
    } else {
      setBillingInfo({
        ...billingInfo,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const bankInfoOnChange = (e, value, name) => {
    if (value) {
      setBankAccountInfo({
        ...bankAccountInfo,
        [name]: value,
      });
    } else {
      setBankAccountInfo({
        ...bankAccountInfo,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const reenableOnClick = () => {
    dispatch(
      reenableSubscription(subscription.subscriptions_reenable_url, customerId)
    );
    setShowReenableModal(false);
  };

  const openReenableModal = () => {
    setShowReenableModal(true);
  };

  const reenableContent = {
    heading: 'Re-enable Auto-Renewal',
    text: `Are you sure you want to <strong>re-enable</strong> auto-renewal on your account?`,
    minWidth: '400px',
    buttons: [
      {
        text: 'Enable',
        function: reenableOnClick,
        theme: ButtonThemes.primary,
      },
      {
        text: 'Cancel',
        function: () => setShowReenableModal(false),
        theme: ButtonThemes.cancel,
      },
    ],
  };

  const editFunction = (item) => {
    const formContent = `
      <input type="hidden" name="csrfmiddlewaretoken" value="${Cookies.get(
        'csrftoken'
      )}"/>
      <input
        type="hidden"
        id="invoice_id"
        name="invoice_id"
        value=${item.invoice_number}
      />`;
    document.getElementById('pdf_form').innerHTML = formContent;
    document.getElementById('pdf_form').submit();
  };

  const updateLicenseCount = () => {
    if (advisorCount >= 1) {
      dispatch(getSubscriptionOptions(customerId, advisorCount, delegateCount));
      setIsUpdatingCount(true);
    } else {
      setAdvisorCountError(true);
    }
  };

  const updateResubscribePlan = () => {
    if (selectedPlan !== null && !subscriptionOptions?.billing_info_update) {
      setIsResubscribing(true);
      dispatch(
        resubscribePlan(customerId, selectedPlan, advisorCount, delegateCount)
      );
    }
  };

  return (
    <Page>
      {showReenableModal && (
        <SimpleModal
          hide={() => setShowReenableModal(!showReenableModal)}
          content={reenableContent}
        />
      )}
      <PageHeader content={headerContent.billing} />
      <TabMenu tabs={tabs} activeTab={activeTab} onClick={tabOnClick} />
      {activeTab === 'info' && !isBillingInfoError ? (
        <PageContentMax>
          {teamSettings && teamSettings?.customer_type ? (
            teamSettings.customer_type === 'subscription' ? (
              <RecurlyProvider publicKey={recurlyKey}>
                <Elements>
                  <RecurlyBilling
                    data={billingInfo}
                    showACH={showACH}
                    bankData={bankAccountInfo}
                    countries={countries}
                    defaultState={user?.state}
                    onChange={billingInfoOnChange}
                    onBankChange={bankInfoOnChange}
                    customerId={customerId}
                    updateBillingError={updateBillingError}
                  />
                </Elements>
              </RecurlyProvider>
            ) : (
              <>
                <FormHeader>Billing Information</FormHeader>
                <BillingMessage>{generateBillingError()}</BillingMessage>
              </>
            )
          ) : (
            <LoadingAnimation />
          )}
        </PageContentMax>
      ) : activeTab === 'invoices' && !isBillingInvoiceError ? (
        <PageContent>
          <FormHeader>Billing Invoices</FormHeader>
          <FormText>Review and download past subscription invoices.</FormText>
          {loadedInvoices ? (
            <>
              <SmallTable
                columnHeadings={invoiceHeadings}
                type={'billing'}
                items={invoiceList}
                editFunction={editFunction}
                isHousehold={false}
              />
              <form
                method="post"
                action={`/api/v2/manager/billing/get_recurly_invoice_pdf/`}
                id="pdf_form"
                style={{ display: 'none' }}
              ></form>
            </>
          ) : (
            <LoadingAnimation />
          )}
        </PageContent>
      ) : activeTab === 'subscription' ? (
        loadedSubscription && subscription ? (
          <PageContentMax>
            {subscription.subscription ? (
              subscription.subscription_state === 'in_trial' ? (
                <>
                  <SubscriptionContent>
                    <SubscriptionDate>
                      Your trial ends on{' '}
                      {DateFormat(
                        subscription.subscription_end_date,
                        'MMM D, YYYY'
                      )}
                    </SubscriptionDate>
                    <SubText>
                      Submit your billing information to enable your
                      subscription to continue once the trial ends.
                    </SubText>
                  </SubscriptionContent>
                  <RecurlyProvider publicKey={recurlyKey}>
                    <Elements>
                      <RecurlyBilling
                        data={billingInfo}
                        countries={countries}
                        defaultState={user?.state}
                        onChange={billingInfoOnChange}
                        customerId={customerId}
                      />
                    </Elements>
                  </RecurlyProvider>
                </>
              ) : subscription.subscription_state === 'pending' ? (
                <SubscriptionContent>
                  <SubscriptionDataContainer>
                    <ConfiguredContainer>
                      <p>Your subscription is being configured.</p>
                    </ConfiguredContainer>
                  </SubscriptionDataContainer>
                  <AvailableSeats
                    available_advisors={subscription.available_advisors}
                    available_delegates={subscription.available_delegates}
                    subscribed_advisor_seats={
                      subscription.subscribed_advisor_seats
                    }
                    subscribed_delegate_seats={
                      subscription.subscribed_delegate_seats
                    }
                  />
                </SubscriptionContent>
              ) : subscription.subscription_state === 'active' ? (
                <SubscriptionContent>
                  <SubscriptionDataContainer>
                    <SubscriptionDate>
                      You are subscribed through{' '}
                      {DateFormat(
                        subscription.subscription_end_date,
                        'MMM D, YYYY'
                      )}
                    </SubscriptionDate>
                    {subscription.subscription_next_billing_date && (
                      <BillingDate>
                        Your next billing date is{' '}
                        {DateFormat(
                          subscription.subscription_next_billing_date,
                          'MMM D, YYYY'
                        )}{' '}
                        {subscription.subscription_remaining_billing_cycles && (
                          <>
                            (
                            <Pluralize
                              singular={'cycle'}
                              count={
                                subscription.subscription_remaining_billing_cycles
                              }
                            />
                            )
                          </>
                        )}
                      </BillingDate>
                    )}
                  </SubscriptionDataContainer>
                  <RenewContainer>
                    <p>Your subscription is set to renew automatically <br/>    
                      (Auto-renewal status may change by opt-out confirmation)
                    </p>
                  </RenewContainer>
                  <AvailableSeats
                    available_advisors={subscription.available_advisors}
                    available_delegates={subscription.available_delegates}
                    subscribed_advisor_seats={
                      subscription.subscribed_advisor_seats
                    }
                    subscribed_delegate_seats={
                      subscription.subscribed_delegate_seats
                    }
                  />
                </SubscriptionContent>
              ) : subscription.subscription_state === 'canceled' ? (
                <SubscriptionContent>
                  <SubscriptionDataContainer>
                    <SubscriptionDate>
                      Your subscription is scheduled to end on{' '}
                      {DateFormat(
                        subscription.subscription_expire_date,
                        'MMM D, YYYY'
                      )}
                    </SubscriptionDate>
                    {subscription.subscription_next_billing_date && (
                      <BillingDate>
                        Your next billing date is{' '}
                        {DateFormat(
                          subscription.subscription_next_billing_date,
                          'MMM D, YYYY'
                        )}{' '}
                        {subscription.subscription_remaining_billing_cycles && (
                          <>
                            (
                            <Pluralize
                              singular={'cycle'}
                              count={
                                subscription.subscription_remaining_billing_cycles
                              }
                            />
                            )
                          </>
                        )}
                      </BillingDate>
                    )}
                  </SubscriptionDataContainer>
                  <RenewContainer>
                    <p>
                      To re-enable auto-renewal for your account, click the
                      following button:
                    </p>
                    <Button text={'Enable'} onClick={openReenableModal} />
                    <ConfirmRenewContainer>
                      *You will be asked to confirm this action before it is
                      executed.
                    </ConfirmRenewContainer>
                  </RenewContainer>
                  <AvailableSeats
                    available_advisors={subscription.available_advisors}
                    available_delegates={subscription.available_delegates}
                    subscribed_advisor_seats={
                      subscription.subscribed_advisor_seats
                    }
                    subscribed_delegate_seats={
                      subscription.subscribed_delegate_seats
                    }
                  />
                </SubscriptionContent>
              ) : subscription.subscription_state === 'expired' ? (
                <ExpiredSubscriptionContent>
                  <SubscriptionDate>
                    Your subscription ended on{' '}
                    {DateFormat(
                      subscription.subscription_expire_date,
                      'MMM D, YYYY'
                    )}
                    .
                  </SubscriptionDate>
                  {loadedSubscriptionPlans ? (
                    <>
                      {hasSubscriptionPlans ? (
                        <div>
                          <LicenseCountContainer>
                            <h3>License Count</h3>
                            <LicenseInputsContainer>
                              <InputContainer>
                                <input
                                  style={
                                    advisorCountError
                                      ? {
                                          border: `1px solid ${messageColors.error}`,
                                        }
                                      : null
                                  }
                                  type="number"
                                  min="1"
                                  onFocus={(e) => e.target.select()}
                                  value={advisorCount}
                                  onChange={(e) => {
                                    setAdvisorCount(e.currentTarget.value);
                                    setAdvisorCountError(false);
                                  }}
                                />
                                <label>Advisors</label>
                              </InputContainer>
                              <InputContainer>
                                <input
                                  type="number"
                                  onFocus={(e) => e.target.select()}
                                  value={delegateCount}
                                  onChange={(e) =>
                                    setDelegateCount(e.currentTarget.value)
                                  }
                                />
                                <label>Delegates</label>
                              </InputContainer>
                              <Button
                                text="Update"
                                onClick={() => updateLicenseCount()}
                                loadingText="Updating"
                                showLoading={isUpdatingCount}
                              />
                            </LicenseInputsContainer>
                          </LicenseCountContainer>
                          <SubscriptionOptionsContainer>
                            <h3>Subscription Plans</h3>
                            <div>
                              {subscriptionPlans.map((plan, index) => {
                                return (
                                  <PlanContainer
                                    key={index}
                                    onClick={() =>
                                      setSelectedPlan(plan?.plan_code)
                                    }
                                    style={
                                      plan?.plan_code === selectedPlan
                                        ? {
                                            border: `1px solid ${colors.green}`,
                                            boxShadow: boxShadows.boxShadowSoft,
                                          }
                                        : null
                                    }
                                  >
                                    <PlanName>{plan.name}</PlanName>
                                    <PlanPrice>
                                      {plan.term} {plan.plan_interval_unit},{' '}
                                      {plan.payments} payment of{' '}
                                      {FormatCurrency(
                                        plan.total / 100,
                                        'USD',
                                        2
                                      )}
                                    </PlanPrice>
                                    {plan?.discount_percentage !== 0 && (
                                      <PlanDiscount>
                                        Price includes a{' '}
                                        {plan.discount_percentage}% discount
                                      </PlanDiscount>
                                    )}
                                  </PlanContainer>
                                );
                              })}
                            </div>
                          </SubscriptionOptionsContainer>
                          {subscriptionOptions?.billing_info_update && (
                            <UpdateBillingText>
                              Your billing information is expired. Please update
                              it{' '}
                              <ExpiredText onClick={() => setActiveTab('info')}>
                                here
                              </ExpiredText>{' '}
                              before resubscribing.
                            </UpdateBillingText>
                          )}
                          {resubscribeError && (
                            <SubscribeErrorMessage>
                              {resubscribeErrorMessage}
                            </SubscribeErrorMessage>
                          )}
                          <ButtonContainer>
                            <Button
                              text="Resubscribe"
                              onClick={() => updateResubscribePlan()}
                              loadingText="Resubscribing"
                              showLoading={isResubscribing}
                              theme={
                                selectedPlan !== null &&
                                !subscriptionOptions?.billing_info_update
                                  ? ButtonThemes.primary
                                  : ButtonThemes.inactive
                              }
                            />
                          </ButtonContainer>
                        </div>
                      ) : (
                        <SubText>
                          Contact Support to renew your subscription.
                        </SubText>
                      )}
                    </>
                  ) : (
                    <LoadingAnimation />
                  )}
                </ExpiredSubscriptionContent>
              ) : (
                <SubscriptionContent>
                  <p>Thanks for being a great customer!</p>
                </SubscriptionContent>
              )
            ) : (
              <SubscriptionContent>
                <p>{generateBillingError()}</p>
              </SubscriptionContent>
            )}
          </PageContentMax>
        ) : (
          <LoadingAnimation />
        )
      ) : null}
      {hasBillingError && (
        <PageContentMax>
          <BillingErrorMessage>{generateBillingError()}</BillingErrorMessage>
        </PageContentMax>
      )}
    </Page>
  );
};

const ButtonContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

const LicenseInputsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  margin-right: 20px;
  input {
    width: 60px;
    color: ${colors.darkGrey};
    border: 1px solid ${inputColors.border};
    border-radius: 6px;
    margin-right: 8px;
    padding: 8px 5px;
  }
`;

const ExpiredSubscriptionContent = styled.div``;

const UpdateBillingText = styled.p`
  font-size: 14px;
  color: ${messageColors.error};
`;

const ExpiredText = styled.span`
  /* color: ${messageColors.error}; */
  /* color: ${colors.lightBlue}; */
  font-weight: ${fonts.semiBold};
  text-decoration: underline;
  cursor: pointer;
`;

const SubscriptionOptionsContainer = styled.div`
  margin: 20px 0 20px 0;
  h3 {
    font-weight: ${fonts.semiBold};
    margin-bottom: 10px;
  }
`;

const LicenseCountContainer = styled(SubscriptionOptionsContainer)`
  margin: 40px 0 60px 0;
`;

const PlanContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    box-shadow: ${boxShadows.boxShadowSoft};
  }
`;

const PlanName = styled.p`
  text-transform: uppercase;
  color: ${colors.paleGrey};
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
`;

const PlanPrice = styled.p`
  color: ${colors.darkGrey};
  font-size: 18px;
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
`;

const PlanDiscount = styled.p`
  color: ${colors.paleGrey};
  font-size: 12px;
`;

const BillingErrorMessage = styled.p`
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  text-transform: none;
  color: ${messageColors.error};
`;

const SubscribeErrorMessage = styled.p`
  color: ${messageColors.error};
  font-size: 14px;
  margin: 10px 0 15px 0;
  text-align: right;
`;

const SubscriptionContent = styled.div`
  font-size: 14px;
  text-align: center;
`;

const SubscriptionDataContainer = styled.div`
  margin-bottom: 10px;
`;

const SubscriptionDate = styled.div`
  font-size: 18px;
`;

const SubText = styled.p`
  font-size: 13px;
  margin: 5px 0;
  color: ${colors.paleGrey};
`;

const BillingDate = styled.div`
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const RenewContainer = styled.div`
  margin: 13px 0;
  border-top: 1px solid ${colors.lightGrey};
  border-bottom: 1px solid ${colors.lightGrey};
  p {
    padding: 10px 0;
  }
`;

const ConfirmRenewContainer = styled.div`
  font-size: 10px;
  color: ${colors.paleGrey};
  margin: 10px 0;
`;

const ConfiguredContainer = styled.div`
  margin: 13px 0;
  border-top: 1px solid ${colors.lightGrey};
  border-bottom: 1px solid ${colors.lightGrey};
  p {
    padding: 10px 0;
  }
`;

const PageContent = styled.div`
  @media ${maxDevice.sideNav} {
    margin: 10px 20px;
  }
  margin: 25px 40px;
`;

const PageContentMax = styled(PageContent)`
  @media ${maxDevice.sideNav} {
    margin: 10px 20px;
  }
  margin: 25px 40px;
  max-width: 900px;
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  margin-bottom: 10px;
`;

const FormText = styled.p`
  @media ${maxDevice.mobileL} {
    font-size: 11px;
  }
  margin-bottom: 20px;
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const BillingMessage = styled.p`
  font-size: 13px;
  a {
    color: ${colors.lightBlue};
  }
`;

BillingContainer.propTypes = {
  customerId: PropTypes.string,
};

export default BillingContainer;
