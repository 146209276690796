/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Error, GenerateInput, InfoBox } from '..';
import { colors, fonts } from '../../styles/variables';
import { timesWhite, trash, noComments } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  SmallerModalContainer,
  ModalContentContainer,
} from '../../styles/library/modalStyles';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import {
  createSignalComment,
  toggleShowDeleteModal,
  toggleShowSignalCommentsModal,
  toggleSnoozeSignal,
  updateHouseholdStoreValue,
} from '../../store/actions';
import { useSelector } from 'react-redux';
import { DateFormatUTC } from '../../utils';
import { ButtonThemes } from '../../styles/themes';

const SignalCommentsModal = ({ hide, householdId }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    signalComments,
    preferences,
    signalCommentsError,
    selectedSignal,
    signalMuteError,
  } = useSelector((state) => ({
    signalComments: state.households.signalComments,
    preferences: state.user.user.preferences,
    signalCommentsError: state.households.signalCommentsError,
    selectedSignal: state.households.selectedSignal,
    signalMuteError: state.households.signalMuteError,
  }));

  const snoozeDurations = [
    { label: '1 Month', value: 30 },
    { label: '3 Months', value: 90 },
    { label: '1 Year', value: 365 },
    { label: 'Indefinitely (Until Wake)', value: 0 },
  ];
  const [currentSignal, setCurrentSignal] = useState();
  const [isSnooze, setIsSnooze] = useState(true);
  const [isLoadingComments, setIsLoadingComments] = useState(true);
  const [commentsPlaceholder, setCommentsPlaceholder] = useState([]);
  const [signalComment, setSignalComment] = useState('');
  const [snoozeDuration, setSnoozeDuration] = useState(snoozeDurations[3]);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [signalsError, setSignalsError] = useState('');

  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('selectedSignal', null));
      dispatch(updateHouseholdStoreValue('signalComments', null));
    };
  }, []);

  useEffect(() => {
    if (selectedSignal) {
      setCurrentSignal(selectedSignal);
      setIsSnooze(selectedSignal?.isSnooze);
      let placeholder = [...Array(selectedSignal?.comments)];
      placeholder = placeholder.map((x, i) => i);
      setCommentsPlaceholder(placeholder);
    }
  }, [selectedSignal]);

  useEffect(() => {
    if (signalComments) {
      setShowLoadingButton(false);
      setSignalComment('');
      setSignalsError('');
      const formattedComments = signalComments.map((comment) => {
        comment.value = comment?.comment;
        comment.date = DateFormatUTC(
          comment.created_utc,
          preferences.date_format
        );
        return comment;
      });
      setCurrentSignal({ ...selectedSignal, commentsList: formattedComments });
      setIsLoadingComments(false);
    }
  }, [signalComments]);

  useEffect(() => {
    if (signalCommentsError) {
      setShowLoadingButton(false);
      let errors = [];
      const errorData = signalCommentsError?.data;
      if (errorData) {
        for (const [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        const errorMessages = errors.map((error) => {
          const fieldName = error.field.replaceAll('_', ' ');
          return `${fieldName}: ${error.message}`;
        });
        return setSignalsError(errorMessages);
      }
      setSignalsError('Error processing request');
      setIsLoadingComments(false);
    }
  }, [signalCommentsError]);

  useEffect(() => {
    if (signalMuteError) {
      setShowLoadingButton(false);
      let errors = [];
      const errorData = signalMuteError?.data;
      if (errorData) {
        for (const [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        const errorMessages = errors.map((error) => {
          const fieldName = error.field.replaceAll('_', ' ');
          return `${fieldName}: ${error.message}`;
        });
        return setSignalsError(errorMessages);
      }
      setSignalsError('Error processing request');
    }
  }, [signalMuteError]);

  const validateSignalModal = () => {
    if (isSnooze) {
      const isMute = snoozeDuration.value === 0;
      let snoozeDate = null;
      if (!isMute) {
        const today = new Date();
        snoozeDate = new Date(
          new Date().setDate(today.getDate() + snoozeDuration.value)
        );
        snoozeDate = snoozeDate.toISOString();
      }
      const signalObj = {
        signal: currentSignal?.key,
        action: isMute ? 'mute' : 'snooze',
        [isMute ? 'duration' : 'snooze_utc']: snoozeDate,
        state_snoozed: currentSignal?.calculated,
        // comment: signalComment,
      };
      setShowLoadingButton(true);
      dispatch(toggleSnoozeSignal(householdId, signalObj, true));
    } else {
      if (signalComment !== '') {
        setShowLoadingButton(true);
        dispatch(
          createSignalComment(householdId, {
            signal_type: currentSignal?.key,
            comment: signalComment,
          })
        );
      } else {
        setSignalsError('Comment cannot be blank');
      }
    }
  };
  const inputOptions = [
    {
      type: 'select',
      label: 'Snooze Duration',
      name: 'duration',
      placeholder: 'Select Duration',
      value: snoozeDuration,
      options: snoozeDurations,
      width: '100%',
      isVisible: isSnooze,
      onChange: (e) => setSnoozeDuration(e),
    },
    {
      type: 'textarea',
      label: 'Comment',
      name: 'comment',
      showClear: true,
      id: 'signals',
      isVisible: !isSnooze,
      height: isSnooze ? '120px' : '100px',
      value: signalComment,
      onChange: (e) => setSignalComment(e),
    },
  ];
  const signalInputs = inputOptions.filter((input) => input.isVisible);

  const buttonOptions = [
    {
      text: 'Cancel',
      function: () => dispatch(toggleShowSignalCommentsModal(false)),
      theme: ButtonThemes.cancel,
    },
    {
      text: isSnooze ? 'Snooze' : 'Add Comment',
      loadingText: isSnooze ? 'Snoozing' : 'Adding',
      showLoading: showLoadingButton,
      function: () => validateSignalModal(),
    },
  ];

  const deleteComment = (comment) => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'signalComment',
        'this comment',
        comment.id,
        comment.signal_type
      )
    );
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <SmallerModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {isSnooze
                ? `Snooze ${currentSignal?.label}`
                : `${currentSignal?.label} Comments`}{' '}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            {isSnooze ? (
              <>
                <div>
                  {/* {GenerateSignalIcon(signal.key)} */}
                  <ModalText>
                    You are about to snooze a system generated Signal.
                  </ModalText>
                  {/* <ModalSubText>
                    Please leave a comment below to document why this signal was
                    snoozed.
                  </ModalSubText> */}
                </div>
              </>
            ) : isLoadingComments ? (
              <CommentsListContainer>
                {commentsPlaceholder.length !== 0 ? (
                  commentsPlaceholder.map((index) => {
                    return (
                      <CommentContainer key={index} last={true}>
                        <CommentDate>
                          <LoadingPlaceholder width="85px" />
                          {/* <LoadingPlaceholder width="18px" round={true} /> */}
                        </CommentDate>
                        <LoadingPlaceholder width="100%" />
                        <LoadingPlaceholder width="100%" />
                        <LoadingPlaceholder width="100%" />
                      </CommentContainer>
                    );
                  })
                ) : (
                  <NoCommentsContainer>
                    <img
                      src={noComments}
                      alt="comment"
                      data-image="no-comments"
                    />
                    <NoCommentsText>No Comments</NoCommentsText>
                  </NoCommentsContainer>
                )}
              </CommentsListContainer>
            ) : (
              <CommentsListContainer>
                {signalComments && signalComments.length !== 0 ? (
                  signalComments.map((comment, index) => {
                    return (
                      <CommentContainer
                        key={index}
                        last={index === signalComments.length - 1}
                      >
                        <CommentDate>
                          <span>
                            {comment.date} {comment.snoozed && '(Snoozed)'}
                          </span>
                          <DeleteIcon
                            src={trash}
                            alt="delete"
                            data-image={`trash-${index}`}
                            onClick={() => deleteComment(comment)}
                          />
                        </CommentDate>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: comment.value,
                          }}
                        />
                      </CommentContainer>
                    );
                  })
                ) : (
                  <NoCommentsContainer>
                    <img
                      src={noComments}
                      alt="comment"
                      data-image="no-comments"
                    />
                    <NoCommentsText>No Comments</NoCommentsText>
                  </NoCommentsContainer>
                )}
              </CommentsListContainer>
            )}
            {signalInputs &&
              signalInputs.length !== 0 &&
              signalInputs.map((input, index) => {
                return GenerateInput(input, index);
              })}
            {isSnooze && (
              <InfoBox
                margin="10px 0 10px 0"
                fontSize="11px"
                message="This signal will stay snoozed for the selected duration but
                    you can always wake the signal at any point."
              />
            )}
            {buttonOptions && buttonOptions !== 0 && (
              <ButtonsRowContainer>
                {buttonOptions.map((button, index) => {
                  return (
                    <ButtonContainer
                      key={index}
                      style={index === 1 ? { marginLeft: '10px' } : null}
                    >
                      <Button
                        text={button.text}
                        showLoading={button.showLoading}
                        loadingText={button.loadingText}
                        onClick={button.function}
                        theme={button.theme}
                      />
                    </ButtonContainer>
                  );
                })}
              </ButtonsRowContainer>
            )}
          </ModalContentContainer>
          {signalsError && signalsError !== '' && (
            <Error errorMessage={signalsError} />
          )}
        </SmallerModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const CommentsListContainer = styled.div`
  /* border: 1px solid ${colors.lightGrey}; */
  /* border-radius: 3px; */
  min-height: 150px;
  max-height: 250px;
  overflow-y: auto;
  text-align: left;
  margin-bottom: 15px;
`;

const DeleteIcon = styled.img`
  width: 11px;
  height: 11px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;

const CommentDate = styled.p`
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  margin-bottom: 3px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  span {
    margin-right: 5px;
  }
`;

const CommentContainer = styled.div`
  border-bottom: ${(props) =>
    props.last ? '1px solid transparent' : `1px solid ${colors.lightGrey}`};
  font-size: 12px;
  padding: 12px 8px;
  &:hover {
    ${DeleteIcon} {
      opacity: 1;
    }
  }
`;

const NoCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    max-width: 180px;
    max-height: 125px;
  }
`;

const NoCommentsText = styled.p`
  margin-top: 5px;
  font-weight: ${fonts.bold};
  font-size: 16px;
  color: ${colors.paleGrey};
`;

const ModalText = styled.p`
  font-size: 13px;
  margin: 5px 0 15px 0;
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
`;

// const ModalSubText = styled.p`
//   font-size: 10px;
//   margin-bottom: 15px;
//   color: ${colors.paleGrey};
// `;

const ButtonContainer = styled.div``;

const ButtonsRowContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  ${ButtonContainer} {
    flex: 1 1 50%;
    button {
      width: 100%;
    }
  }
`;

export default SignalCommentsModal;
