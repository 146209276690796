/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Button, Error, DetectModalKeys } from '../../components';
import { updateUserStoreValue, verifyEmailCode } from '../../store/actions';
import { redLock, greenUnlock, blueLock, checkCircle } from '../../assets';
import {
  CenterModal,
  ModalContentContainer,
  ModalHeader,
  ModalHeadingConfig,
  ModalOverlay,
} from '../../styles/library/modalStyles';
import { LightBackgroundIcon } from '../../styles/library/imageStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  colors,
  fonts,
  messageColors,
  inputColors,
  maxDevice,
} from '../../styles/variables';
import { DetectEnterKeyPress } from '../../utils';

const VerifyCodeModal = ({ code }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const {
    verifyEmailCodeError,
    createdConsumerAccount,
    createdAccountDetails,
  } = useSelector((state) => ({
    verifyEmailCodeError: state.user.verifyEmailCodeError,
    createdConsumerAccount: state.user.createdConsumerAccount,
    createdAccountDetails: state.user.createdAccountDetails,
  }));
  const [screen, setScreen] = useState('verify');
  const [verifyCode, setVerifyCode] = useState({
    verify1: '',
    verify2: '',
    verify3: '',
    verify4: '',
    verify5: '',
    verify6: '',
  });
  const [codeError, setCodeError] = useState(false);
  const [codeEntered, setCodeEntered] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [email, setEmail] = useState('Loading');

  //IF CODE IS PASSED IN PREFILL INPUTS
  useEffect(() => {
    if (code) {
      setVerifyCode({
        verify1: code[0],
        verify2: code[1],
        verify3: code[2],
        verify4: code[3],
        verify5: code[4],
        verify6: code[5],
      });
    }
  }, [code]);

  //ON EVERY CHANGE OF VERIFY CODE CHECK TO SEE IF 6 DIGITS ARE ENTERED
  useEffect(() => {
    if (verifyCode) {
      const hasEmptyValue = Object.values(verifyCode).some((x) => x === '');
      setCodeEntered(!hasEmptyValue);
    }
  }, [verifyCode]);

  //SET ERROR DISPLAY IF ERROR WITH VERIFY CODE
  useEffect(() => {
    if (verifyEmailCodeError) {
      setCreatingAccount(false);
      let errorMessage = 'Unknown Error';
      if (verifyEmailCodeError?.status === 'not_found') {
        errorMessage = 'Invite not found';
      } else {
        errorMessage = verifyEmailCodeError?.message;
      }
      setErrorMessage(errorMessage);
      if (verifyEmailCodeError?.status === 'incorrect_code') {
        setCodeError(true);
      }
    }
  }, [verifyEmailCodeError]);

  //UPDATE DISPLAY TO SEND ACCOUNT DETAILS DISPLAY AFTER ACCOUNT WAS VERIFIED
  useEffect(() => {
    if (createdConsumerAccount) {
      setScreen('sent');
      setEmail(createdAccountDetails?.email);
      setCreatingAccount(false);
      dispatch(updateUserStoreValue('createdConsumerAccount', false));
    }
  }, [createdConsumerAccount]);

  //FUNCTION TO RESET ERROR DISPLAY
  const resetError = () => {
    setErrorMessage('');
    setCodeError(false);
  };

  //CODE INPUT ON CHANGE HANDLER
  const handleChange = (e) => {
    resetError();
    const { maxLength, value, name } = e.target;
    // eslint-disable-next-line no-unused-vars
    const [fieldName, fieldIndex] = name.split('-');
    if (value.length >= maxLength) {
      e.target.select();
      if (parseInt(fieldIndex, 6) < 6) {
        const nextField = document.querySelector(
          `input[name=verify-${parseInt(fieldIndex, 6) + 1}]`
        );
        if (nextField !== null) {
          nextField.focus();
        }
      }
    }
    setVerifyCode({
      ...verifyCode,
      [`verify${fieldIndex}`]: value,
    });
  };

  //ON FOCUS OF INPUT SELECT CONTENT
  const handleFocus = (e) => e.target.select();

  //HANDLE PASTING OF CODE
  const onPaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text');
    if (!isNaN(parseInt(pasteData))) {
      resetError();
      setVerifyCode({
        verify1: pasteData[0],
        verify2: pasteData[1],
        verify3: pasteData[2],
        verify4: pasteData[3],
        verify5: pasteData[4],
        verify6: pasteData[5],
      });
    }
  };

  //MAKE API CALL TO VERIFY 6 DIGIT CODE
  const submitVerifyCode = () => {
    if (!codeError && codeEntered) {
      const values = Object.values(verifyCode);
      const code = values.join('');
      setCreatingAccount(true);
      return dispatch(verifyEmailCode(params.id, { code }));
    }
  };

  /*
    TWO MODAL UIS CAN BE DISPLAYED
    - Verify Code (verify) - inputs to enter 6 digit code
    - Account Created Email Sent (sent) - message that account was created and credentials were sent in email
  */
  return ReactDOM.createPortal(
    <>
      <ModalOverlay>
        <CenterModal width="375px">
          <ModalHeader>
            <ModalHeadingConfig>Create Asset-Map Account</ModalHeadingConfig>
          </ModalHeader>
          <ModalContentContainer>
            {screen === 'verify' && (
              <InfoContainer>
                <DetectModalKeys onEnter={submitVerifyCode} />
                <LightBackgroundIcon
                  width="70px"
                  iconWidth="35px"
                  backgroundColor={
                    codeError
                      ? 'rgba(235, 77, 75, .15)'
                      : codeEntered
                        ? 'rgba(120, 190, 32, .15)'
                        : 'rgba(62, 177, 200, 0.15)'
                  }
                >
                  <img
                    src={
                      codeError ? redLock : codeEntered ? greenUnlock : blueLock
                    }
                    alt="lock"
                  />
                </LightBackgroundIcon>
                <EnterCodeText>
                  Enter the 6-digit code that was sent to your email.
                </EnterCodeText>
                <CodeNumberContainer>
                  {[1, 2, 3, 4, 5, 6].map((index) => {
                    return (
                      <input
                        key={index}
                        type="text"
                        name={`verify-${index}`}
                        value={verifyCode[`verify${index}`]}
                        maxLength={1}
                        onChange={handleChange}
                        autoFocus={index === 1}
                        onFocus={handleFocus}
                        onKeyPress={(e) =>
                          DetectEnterKeyPress(e, submitVerifyCode)
                        }
                        onPaste={index === 1 ? onPaste : null}
                      />
                    );
                  })}
                </CodeNumberContainer>
                <Button
                  onClick={submitVerifyCode}
                  type="submit"
                  text={codeError ? 'Wrong Code' : 'Create Account'}
                  showLoading={creatingAccount}
                  loadingText={'Creating Account'}
                  theme={
                    codeError
                      ? ButtonThemes.errorFull
                      : codeEntered
                        ? ButtonThemes.primaryFull
                        : ButtonThemes.inactiveFull
                  }
                />
              </InfoContainer>
            )}
            {screen === 'sent' && (
              <InfoContainer>
                <LightBackgroundIcon
                  backgroundColor={messageColors.successLabelBg}
                  width="60px"
                  iconWidth="30px"
                >
                  <img src={checkCircle} alt="check" />
                </LightBackgroundIcon>
                <SentMessage>
                  <p>Account created successfully for</p>
                  <Email>{email}</Email>
                </SentMessage>
                <DetailsMessage>
                  A confirmation email has been sent. Please sign in now to
                  accept the Client Portal invite.
                </DetailsMessage>
                <Button
                  onClick={() => {
                    navigate(`/auth/i/${params.id}`);
                  }}
                  type="submit"
                  text={'Sign In'}
                  theme={ButtonThemes.primaryFull}
                />
              </InfoContainer>
            )}
          </ModalContentContainer>
          {errorMessage !== '' && <Error errorMessage={errorMessage} />}
        </CenterModal>
      </ModalOverlay>
    </>,
    document.body
  );
};

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 5px;
`;

const EnterCodeText = styled.p`
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  margin: 15px 0 0 0;
`;

const CodeNumberContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px -6px;
  input {
    @media ${maxDevice.tablet} {
      width: 30px;
      margin: 0 5px;
    }
    flex: 1 1 auto;
    width: 39px;
    margin: 0 6px;
    border-radius: 6px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    color: ${colors.darkGrey};
    border: ${(props) =>
      props.theme.error
        ? `1px solid ${messageColors.error}`
        : `1px solid ${inputColors.border}`};
  }
`;

const SentMessage = styled.div`
  font-size: 15px;
  margin: 8px 0 15px 0;
  text-align: center;
`;

const Email = styled.p`
  font-weight: ${fonts.bold};
`;

const DetailsMessage = styled.p`
  text-align: center;
  font-size: 11px;
  margin-bottom: 20px;
  padding: 0 30px;
`;

export default VerifyCodeModal;
