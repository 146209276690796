/* eslint-disable array-callback-return */
import {
  HouseholdTypes,
  ApiTypes as API,
  ConfigTypes as CONFIG,
  TargetMapTypes as TARGET_MAP,
  MemberTypes as MEMBER,
  InstrumentTypes as INSTRUMENT,
  TargetMapTypes,
} from '../actions';
import {
  UpdateCurrentHousehold,
  ConvertSignalsObjectToArray,
  DateNow,
} from '../../utils';

const initialState = {
  householdResults: [],
  householdIndex: {},
  currentHousehold: {},
  loadedHousehold: false,
  leadAdvisor: null,
  advisorLogo: null,
  hasHouseholdPermission: null,
  canEditHousehold: null,
  householdCurrency: {},
  applyingStencil: false,
  appliedStencil: false,
  householdIndexError: null,
  householdInfoError: {},
  loadedHouseholdNotes: false,
  loadedBeneficiaryReport: false,
  isStencil: false,
  justSynced: false,
  deleteModal: { showModal: false, type: 'Item' },
  bulkModal: { showModal: false },
  cloneStencilModal: { showModal: false },
  connectPreviewModal: { showModal: false },
  householdReports: false,
  sharingReport: false,
  sharedReport: false,
  fetchRefreshImage: null,
  householdMilestones: false,
  showProposalMode: false,
  syncAllResults: false,
  bulkSync: null,
  householdConfigs: null,
  householdKffs: null,
  familyMaps: null,
  modalState: 'default',
  showingLegalModal: null,
  isNewLegal: false,
  listLegals: false,
  currentBVExpandedState: null,
  inFocusMode: null,
  updatedFocusMode: false,
};

const getLeadAdvisor = (advisors) => {
  if (advisors && Array.isArray(advisors)) {
    let leadAdvisor = advisors.find((advisor) => advisor?.type === 'lead');
    if (leadAdvisor) {
      return leadAdvisor;
    } else {
      return advisors[0];
    }
  }
  return null;
};

const filterHouseholdMembers = (householdMembers, isActive = true) => {
  if (Array.isArray(householdMembers)) {
    return householdMembers.filter((mem) => mem.is_active === isActive);
  }
  return [];
};

const households = (state = initialState, action) => {
  switch (action.type) {
    case HouseholdTypes.UPDATE_HOUSEHOLD_STORE_VALUE:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    case HouseholdTypes.GET_CONSUMER_HOUSEHOLDS:
      return {
        ...state,
        households: action.payload,
        hasSingleHousehold: action.payload?.count === 1,
        singleHousehold: action.payload?.results[0]?.household?.id,
        householdsError: false,
      };
    case HouseholdTypes.GET_HOUSEHOLD_INDEX:
      return {
        ...state,
        householdIndex: action.payload,
        householdResults: [...action.payload.results],
        householdIndexError: null,
        loadedHouseholdLayouts: false,
        deletedHousehold: false,
        layoutId: null,
      };
    case HouseholdTypes.GET_HOUSEHOLD_INDEX_LAYOUTS:
      return {
        ...state,
        householdLayouts: action.payload,
        loadedHouseholdLayouts: true,
      };
    case HouseholdTypes.GET_HOUSEHOLD_INDEX_CSV:
      return {
        ...state,
        householdIndexCSV: action.payload.url,
      };
    case HouseholdTypes.CLEAR_HOUSEHOLD_INDEX_CSV:
      return {
        ...state,
        householdIndexCSV: null,
      };
    case HouseholdTypes.GET_HOUSEHOLD_PERMISSION:
      return {
        ...state,
        householdPermission: action.payload,
      };
    case HouseholdTypes.GET_HOUSEHOLD_CONFIGS:
      return {
        ...state,
        householdConfigs: action.payload,
      };
    case HouseholdTypes.GET_CONSUMER_HOUSEHOLD_ROLE:
      return {
        ...state,
        consumerHouseholdRole: action.payload,
      };
    case HouseholdTypes.GET_HOUSEHOLD_INFO:
      return {
        ...state,
        currentHousehold: {
          ...action.payload,
          allHouseholdMembers: action.payload.householdmembers,
          householdmembers: filterHouseholdMembers(
            action.payload.householdmembers,
            true
          ),
          excludedHouseholdMembers: filterHouseholdMembers(
            action.payload.householdmembers,
            false
          ),
        },
        householdprofessionals: action.payload.householdprofessionals,
        isStencil: action.payload?.status === 'stencil',
        leadAdvisor: getLeadAdvisor(action.payload?.advisors),
        advisorLogo: action.payload?.branding?.logo,
        householdInfoError: {},
        showingStencilModal: false,
        deleteModal: { showModal: false, type: 'Item' },
        justSynced: false,
        layoutMembers: null,
        refreshedImage: false,
        updatedHouseholdInfo: false,
        updatedHousehold: true,
        updateHouseholdError: null,
        justUnlinkedFileStorage: false,
        justLinkedFileStorage: false,
        justLinkedMember: false,
        justUnlinkedMember: false,
        justDeletedInstruments: false,
        justRestoredDeletedItems: false,
        applyingStencil: false,
        modifiedInstruments: false,
        currentProposal: null,
        householdlegals: action.payload.householdlegals,
      };
    case HouseholdTypes.GET_PROPOSAL_HOUSEHOLD_INFO:
      return {
        ...state,
        currentHousehold: {
          ...action.payload,
          allHouseholdMembers: action.payload.householdmembers,
          householdmembers: filterHouseholdMembers(
            action.payload.householdmembers,
            true
          ),
          excludedHouseholdMembers: filterHouseholdMembers(
            action.payload.householdmembers,
            false
          ),
        },
        proposalHousehold: action.payload,
        showProposalMode: true,
        showingProposalsModal: false,
        householdInfoError: {},
        deleteModal: { showModal: false, type: 'Item' },
        justSynced: false,
        layoutMembers: null,
        refreshedImage: false,
        updatedHouseholdInfo: false,
        assetMapProposalError: false,
        updateHouseholdError: null,
      };
    case HouseholdTypes.SET_HOUSEHOLD_PERMISSION:
      return {
        ...state,
        hasHouseholdPermission: action.payload,
      };
    case HouseholdTypes.SET_CAN_EDIT_HOUSEHOLD:
      return {
        ...state,
        canEditHousehold: action.payload,
      };
    case HouseholdTypes.SET_TEMP_HOUSEHOLD:
      return {
        ...state,
        tempHousehold: action.payload,
      };
    case HouseholdTypes.REMOVE_HOUSEHOLD_INFO:
      localStorage.removeItem('selectedMembers');
      localStorage.removeItem('beneficiaryView');
      localStorage.removeItem('relationshipView');
      return {
        ...state,
        currentHousehold: {},
        advisorLogo: null,
        leadAdvisor: null,
        hasHouseholdPermission: null,
        canEditHousehold: null,
        isStencil: false,
        householdPermission: null,
        householdReports: false,
        tempHousehold: undefined,
        lastHousehold: action.payload,
        amrImage: null,
        assetMapLayouts: false,
        assetMapProposals: false,
        fetchRefreshImage: false,
        refreshedImage: false,
        householdMilestones: null,
        householdConfigs: null,
        proposalHousehold: null,
        showProposalMode: false,
        showingProposalsModal: false,
        householdInfoError: {},
        deleteModal: { showModal: false, type: 'Item' },
        justSynced: false,
        layoutMembers: null,
        updatedHouseholdInfo: false,
        assetMapProposalError: false,
        updateHouseholdError: null,
        householdKffs: null,
        householdKffPanel: null,
        householdSignals: null,
        modalState: 'default',
        inFocusMode: null,
        updatedFocusMode: false,
        focusedItems: null,
        familyMaps: null,
        familyMap: null,
      };
    case HouseholdTypes.GET_DELETED_HOUSEHOLDS:
      return {
        ...state,
        deletedHouseholds: action.payload,
      };
    case HouseholdTypes.GET_DELETED_HOUSEHOLD_ITEMS:
      return {
        ...state,
        deletedHouseholdItems: action.payload,
      };
    case HouseholdTypes.CREATE_HOUSEHOLD_MANUAL:
      return {
        ...state,
        createdHousehold: action.payload,
        createHouseholdError: null,
      };
    case HouseholdTypes.REMOVE_CREATED_HOUSEHOLD:
      return {
        ...state,
        createdHousehold: {},
      };
    case HouseholdTypes.UPDATE_LOCAL_HOUSEHOLD:
      return {
        ...state,
        currentHousehold: UpdateCurrentHousehold(
          action.payload.household,
          state.currentHousehold,
          action.payload.categories
        ),
      };
    case HouseholdTypes.GET_INVESTOR_PROFILE:
      return {
        ...state,
        investorProfile: action.payload,
        updatedInvestorProfile: false,
      };
    case HouseholdTypes.DELETE_HOUSEHOLD:
      return {
        ...state,
        deletedHousehold: true,
      };
    case HouseholdTypes.SHOW_DELETE_MODAL:
      return {
        ...state,
        deleteModal: action.payload,
      };
    case HouseholdTypes.TOGGLE_SHOW_BULK_MODAL:
      return {
        ...state,
        bulkModal: action.payload,
      };
    case HouseholdTypes.TOGGLE_SHOW_CLONE_STENCIL_MODAL:
      return {
        ...state,
        cloneStencilModal: action.payload,
        cloningStencil: false,
        cloneStencilError: null,
      };
    case HouseholdTypes.GET_HOUSEHOLD_SHARING_LIST:
      return {
        ...state,
        householdSharingList: action.payload,
        inviteUpdated: false,
        createdSharingInvite: false,
        createInviteError: false,
      };
    case HouseholdTypes.SHOW_SHARE_HOUSEHOLD_MODAL:
      return {
        ...state,
        showingShareHousehold: action.payload?.showModal,
        shareHouseholdDetails: action.payload?.details,
        shareInviteDetails: null,
      };
    case HouseholdTypes.GET_HOUSEHOLD_SHARING_INVITE:
      return {
        ...state,
        shareInviteDetails: action.payload,
        updateInviteError: false,
      };
    case HouseholdTypes.SHOW_CANCEL_SHARING_MODAL:
      return {
        ...state,
        showingCancelSharingModal: action.payload?.showModal,
        inviteDetails: action.payload?.inviteDetails,
      };
    case HouseholdTypes.TOGGLE_SHOW_CONNECT_PREVIEW_MODAL:
      return {
        ...state,
        connectPreviewModal: action.payload,
      };
    case HouseholdTypes.GET_HOUSEHOLD_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case HouseholdTypes.GET_ROLE_PERMISSIONS:
      return {
        ...state,
        rolePermissions: action.payload,
      };
    case HouseholdTypes.GET_HOUSEHOLD_ASSET_MAP_IMAGE:
      return {
        ...state,
        amrImage: action.payload,
        fetchRefreshImage: false,
      };
    case HouseholdTypes.GET_STENCIL_ASSET_MAP_IMAGE:
      return {
        ...state,
        stencilAmrImage: action.payload,
      };
    case HouseholdTypes.GET_HOUSEHOLD_KFFS:
      return {
        ...state,
        householdKffs: action.payload,
        householdSignals: ConvertSignalsObjectToArray(
          action.payload?.indicators
        ),
      };
    case HouseholdTypes.SET_HOUSEHOLD_KFFS:
      return {
        ...state,
        householdKffPanel: action.payload,
      };
    case HouseholdTypes.GET_HOUSEHOLD_NOTES:
      return {
        ...state,
        householdNotes: action.payload,
        loadedHouseholdNotes: true,
      };
    case HouseholdTypes.GET_BENEFICIARY_REPORT:
      return {
        ...state,
        beneficiaryReport: action.payload,
        loadedBeneficiaryReport: true,
        beneficiaryReportError: null,
      };
    case HouseholdTypes.GET_HOUSEHOLD_MILESTONES:
      return {
        ...state,
        householdMilestones: action.payload,
        showingMilestoneModal: action.payload?.show_household_summary_modal,
        userOpenedMilestoneModal: false,
        justUpdatedMilestone: false,
      };
    case HouseholdTypes.TOGGLE_SHOW_MILESTONE_MODAL:
      return {
        ...state,
        showingMilestoneModal: action.payload.showModal,
        userOpenedMilestoneModal: action.payload.userOpened,
      };
    case HouseholdTypes.TOGGLE_SHOW_ASSET_MAP_LAYOUTS_MODAL:
      return {
        ...state,
        showingLayoutsModal: action.payload,
        assetmapLayoutError: false,
      };
    case HouseholdTypes.GET_ASSET_MAP_LAYOUTS:
      return {
        ...state,
        assetMapLayouts: action.payload,
        justCreatedLayout: false,
      };
    case HouseholdTypes.GET_ASSET_MAP_PROPOSALS:
      return {
        ...state,
        assetMapProposals: action.payload,
      };
    case HouseholdTypes.GET_ASSET_MAP_PROPOSAL:
      return {
        ...state,
        currentProposal: action.payload,
        assetMapProposalError: false,
        updatedProposal: false,
      };
    case HouseholdTypes.CREATED_ASSET_MAP_PROPOSAL:
      return {
        ...state,
        showingProposalsModal: false,
        showProposalMode: true,
        currentProposal: action.payload,
        assetMapProposalError: false,
      };
    case HouseholdTypes.TOGGLE_SHOW_PROPOSAL_MODE:
      return {
        ...state,
        showProposalMode: action.payload,
        assetMapProposalError: false,
      };
    case HouseholdTypes.UPDATE_LAYOUT_MEMBERS:
      return {
        ...state,
        layoutMembers: action.payload,
      };
    case HouseholdTypes.TOGGLE_SHOW_PROPOSALS_MODAL:
      return {
        ...state,
        showingProposalsModal: action.payload,
        assetMapProposalError: false,
        proposalCreateError: false,
      };
    case HouseholdTypes.TOGGLE_SHOW_STENCIL_INFO_MODAL:
      return {
        ...state,
        showingStencilInfoModal: action.payload,
      };
    case HouseholdTypes.TOGGLE_SHOW_STENCIL_MODAL:
      return {
        ...state,
        showingStencilModal: action.payload,
        stencilAmrImage: null,
        clonedStencil: false,
      };
    case HouseholdTypes.TOGGLE_SHOW_STENCIL_IMAGE_MODAL:
      return {
        ...state,
        showingStencilImagePreview: action.payload.showModal,
        stencilPreviewDetails: action.payload.stencil,
        // refreshedImage: false,
      };
    case HouseholdTypes.GET_STENCIL_OPTIONS:
      return {
        ...state,
        householdStencils: action.payload,
      };
    case HouseholdTypes.GET_STENCIL_MEMBERS:
      return {
        ...state,
        stencilMembers: action.payload,
      };
    case HouseholdTypes.GET_HOUSEHOLD_REPORTS:
      return {
        ...state,
        householdReports: action.payload,
        fetchHouseholdReports: true,
        householdReportsError: null,
      };
    case HouseholdTypes.TOGGLE_SHOW_REPORT_DETAILS_MODAL:
      return {
        ...state,
        showingReportDetailsModal: action.payload.showModal,
        selectedReport: action.payload.report,
      };
    case HouseholdTypes.TOGGLE_SHOW_REPORT_SHARE_MODAL:
      return {
        ...state,
        showingReportShareModal: action.payload.showModal,
        selectedReport: action.payload.report,
      };
    case HouseholdTypes.GET_REPORT_SHARE_TYPES:
      return {
        ...state,
        reportShareTypes: action.payload.reportShareTypes.share_types,
      };
    case HouseholdTypes.GET_REPORT_SHARE_TYPE_DETAILS:
      return {
        ...state,
        reportShareTypeDetails: action.payload,
      };
    case HouseholdTypes.SHARED_REPORT_DETAILS:
      return {
        ...state,
        sharedReportDetails: action.payload.response,
        reportShareFailed: false,
      };
    case HouseholdTypes.CREATE_ASSET_MAP_REPORT:
      return {
        ...state,
        generateAssetMapPDF: action.payload,
      };
    case HouseholdTypes.TOGGLE_SHOW_SIGNAL_COMMENTS_MODAL:
      return {
        ...state,
        showingSignalCommentsModal: action.payload?.showModal,
        selectedSignal: action.payload?.signal,
      };
    case HouseholdTypes.TOGGLE_SHOW_SIGNAL_DETAILS_MODAL:
      return {
        ...state,
        showingSignalDetailsModal: action.payload?.showModal,
        selectedSignal: action.payload?.signal,
      };
    case HouseholdTypes.GET_SIGNAL_INSTRUMENTS:
      return {
        ...state,
        focusedItems: action.payload?.instruments,
        inFocusMode: true,
        updatedFocusMode: true,
      };
    case TargetMapTypes.GET_TARGET_MAP_INSTRUMENTS:
      return {
        ...state,
        focusedItems: action.payload?.instruments,
        inFocusMode: true,
        updatedFocusMode: true,
      };
    case HouseholdTypes.GET_SIGNAL_COMMENTS:
      return {
        ...state,
        signalComments: action.payload,
        signalCommentsError: null,
        updatedComments: false,
      };
    case HouseholdTypes.TOGGLE_SHOW_SURVEY_RESPONSES_MODAL:
      return {
        ...state,
        showingSurveyResponsesModal: action.payload.showModal,
        selectedSurvey: action.payload.survey,
      };
    case HouseholdTypes.DOWNLOAD_CSV_FILE:
      return {
        ...state,
        downloadedCSV: action.payload,
      };
    case HouseholdTypes.SET_UPLOAD_FILE:
      return {
        ...state,
        uploadedFile: action.payload,
      };
    case HouseholdTypes.UPLOAD_CSV_FILE:
      return {
        ...state,
        uploadCSV: action.payload,
      };
    case HouseholdTypes.CLEAR_UPLOAD_CSV_RESULTS:
      return {
        ...state,
        uploadCSV: null,
      };
    case HouseholdTypes.GET_LINKED_INTEGRATION_MEMBERS:
      return {
        ...state,
        linkedMembers: action.payload,
        linkedMember: false,
        householdMember: null,
        justLinkedMember: false,
        justUnlinkedMember: false,
        linkIntegrationMemberError: null,
        linkedMembersError: false,
        justAlignedHousehold: false,
      };
    case HouseholdTypes.SEARCH_HOUSEHOLD_INTEGRATION:
      return {
        ...state,
        integrationResults: action.payload,
        linkIntegrationMemberError: null,
        justLinkedMember: false,
        justUnlinkedMember: false,
      };
    case HouseholdTypes.GET_HOUSEHOLD_FOLDER_OPTIONS:
      return {
        ...state,
        householdFolders: action.payload,
        householdFolderError: null,
      };
    case HouseholdTypes.GET_HOUSEHOLD_FOLDER_GOOGLE:
      return {
        ...state,
        googleFolder: action.payload,
        refreshFileStorage: null,
        uploadedFiles: false,
        googleFolderError: false,
      };
    case HouseholdTypes.SET_HOUSEHOLD_FOLDER_GOOGLE_FAIL:
      return {
        ...state,
        refreshFileStorage: null,
        uploadedFiles: false,
        googleFolderError: true,
      };
    case HouseholdTypes.GET_HOUSEHOLD_FOLDER_DROPBOX:
      return {
        ...state,
        dropboxFolder: action.payload,
        refreshFileStorage: null,
        uploadedFiles: false,
        dropboxFolderError: false,
      };
    case HouseholdTypes.SET_HOUSEHOLD_FOLDER_DROPBOX_FAIL:
      return {
        ...state,
        refreshFileStorage: null,
        uploadedFiles: false,
        dropboxFolderError: true,
      };
    case HouseholdTypes.GET_HOUSEHOLD_FOLDER_BOX:
      return {
        ...state,
        boxFolder: action.payload,
        refreshFileStorage: null,
        uploadedFiles: false,
        boxFolderError: false,
      };
    case HouseholdTypes.SET_HOUSEHOLD_FOLDER_BOX_FAIL:
      return {
        ...state,
        refreshFileStorage: null,
        uploadedFiles: false,
        boxFolderError: true,
        loadFolderError: action.payload?.data?.details || null,
      };
    case HouseholdTypes.REFRESH_FILE_STORAGE:
      return {
        ...state,
        refreshFileStorage: action.payload,
      };
    case HouseholdTypes.TOGGLE_SHOW_UPLOAD_FILE_MODAL:
      return {
        ...state,
        showingUploadFileModal: action.payload.isShowing,
        fileUploadIntegration: action.payload.integration,
        uploadFolderId: action.payload.folderId,
        uploadFileLocation: action.payload.type,
      };
    case HouseholdTypes.UPLOAD_FILE_STORAGE_FILE:
      return {
        ...state,
        uploadedFileId: action.payload,
        uploadedFiles: true,
      };
    case HouseholdTypes.SET_FILE_STORAGE_UPLOAD_FAILED:
      return {
        ...state,
        failedFileId: action.payload.fileId,
        failedFileMessage: action.payload.message,
      };
    case HouseholdTypes.GET_SURVEYS:
      return {
        ...state,
        surveys: action.payload,
      };
    case HouseholdTypes.GET_SURVEY_HISTORY:
      return {
        ...state,
        surveyHistory: action.payload,
      };
    case HouseholdTypes.CREATE_SURVEY_INSTANCE:
      return {
        ...state,
        surveyInstance: action.payload,
      };
    case HouseholdTypes.GET_SURVEY_INSTANCE:
      return {
        ...state,
        surveyInstance: action.payload,
      };
    case HouseholdTypes.GET_LATEST_SURVEY_INSTANCE:
      return {
        ...state,
        surveyInstance: action.payload,
      };
    case HouseholdTypes.UPDATE_HOUSEHOLD_ADVISOR:
      return {
        ...state,
        justUpdatedAdvisor: true,
      };
    case HouseholdTypes.BULK_SYNC_HOUSEHOLD_INTEGRATIONS:
      return {
        ...state,
        bulkSync: action.payload,
      };
    case HouseholdTypes.SET_MODAL_PAGE:
      return {
        ...state,
        modalPage: action.payload,
      };
    case HouseholdTypes.SET_MODAL_STATE:
      return {
        ...state,
        modalState: action.payload,
      };

    case HouseholdTypes.TOGGLE_SHOW_LEGAL_MODAL:
      return {
        ...state,
        showingLegalModal: action.payload.showModal,
        isNewLegal: action.payload.newLegal,
        listLegals: action.payload.listLegals,
        legalError: null,
      };
    case HouseholdTypes.CLEAR_LEGAL:
      return {
        ...state,
        legal: null,
        legalError: null,
        clearedLegal: true,
        legalAction: null,
      };
    case HouseholdTypes.GET_FAMILY_MAPS:
      return {
        ...state,
        familyMaps: action?.payload,
        familyMapError: false,
      };
    case HouseholdTypes.GET_FAMILY_MAP:
      return {
        ...state,
        familyMap: action?.payload,
        updatedFamilyMap: false,
        familyMapError: false,
      };
    case HouseholdTypes.SET_FOCUSED_ITEMS:
      return {
        ...state,
        focusedItems: action?.payload,
        inFocusMode: true,
        updatedFocusMode: true,
      };
    case HouseholdTypes.GET_LEGALS:
      return {
        ...state,
        legals: action?.payload,
        legalsError: action?.payload?.error,
      };
    case HouseholdTypes.GET_MEMBERS_LEGALS:
      return {
        ...state,
        membersLegals: action?.payload,
      };
    case HouseholdTypes.REFRESH_LEGAL_MODAL_LIST:
      return {
        ...state,
        refreshLegalModal: true,
      };
    case CONFIG.SET_CURRENCY:
      return {
        ...state,
        householdCurrency: action.payload,
      };
    case API.API_SUCCESS:
      switch (action.payload.label) {
        case HouseholdTypes.UPDATE_HOUSEHOLD_MILESTONE:
          return {
            ...state,
            justUpdatedMilestone: true,
          };
        case HouseholdTypes.CREATE_HOUSEHOLD_INDEX_LAYOUT:
          return {
            ...state,
            justCreatedLayout: true,
            layoutId: action?.payload?.data?.id,
          };
        case HouseholdTypes.UPDATE_HOUSEHOLD_INFO:
          return {
            ...state,
            updatedHouseholdInfo: true,
          };
        case HouseholdTypes.RESTORE_DELETED_HOUSEHOLD_ITEMS:
          return {
            ...state,
            justRestoredDeletedItems: true,
          };
        case HouseholdTypes.UPDATE_INVESTOR_PROFILE:
          return {
            ...state,
            updatedInvestorProfile: true,
          };
        case HouseholdTypes.DELETE_HOUSEHOLD_SHARING_INVITE:
          return {
            ...state,
            showingCancelSharingModal: false,
            inviteDetails: null,
            inviteUpdated: true,
          };
        case HouseholdTypes.CREATE_HOUSEHOLD_SHARING_INVITE:
          return {
            ...state,
            createdSharingInvite: true,
          };
        case HouseholdTypes.REFRESH_HOUSEHOLD_ASSET_MAP_IMAGE:
          return {
            ...state,
            refreshedImage: true,
          };
        case HouseholdTypes.UPDATE_ASSET_MAP_PROPOSAL:
          return {
            ...state,
            updatedProposal: true,
            assetMapProposalError: false,
          };
        case HouseholdTypes.CLONE_STENCIL:
          return {
            ...state,
            cloningStencil: false,
            clonedStencil: true,
            cloneStencilError: null,
            cloneStencilModal: { showModal: false },
          };
        case HouseholdTypes.CREATE_SIGNAL_COMMENT:
          return {
            ...state,
            updatedComments: true,
          };
        case HouseholdTypes.DELETE_SIGNAL_COMMENT:
          return {
            ...state,
            deleteModal: { showModal: false, type: 'Item' },
            updatedComments: true,
          };
        case HouseholdTypes.TOGGLE_SNOOZE_SIGNAL:
          return {
            ...state,
            showingSignalCommentsModal: false,
            signalMuteError: false,
          };
        case HouseholdTypes.LINK_HH_MEMBER_TO_INTEGRATION:
          return {
            ...state,
            linkedMember: true,
            householdMember: action?.payload?.data,
          };
        case HouseholdTypes.ALIGN_HOUSEHOLD_SALESFORCE:
        case HouseholdTypes.LINK_HOUSEHOLD_FILE_STORAGE:
          return {
            ...state,
            justLinkedFileStorage: true,
            justAlignedHousehold: true,
          };
        case HouseholdTypes.UNLINK_HOUSEHOLD_FILE_STORAGE:
          return {
            ...state,
            justUnlinkedFileStorage: true,
          };
        case HouseholdTypes.UPDATE_HOUSEHOLD_SHARING_INVITE:
          return {
            ...state,
            updatedInvite: true,
          };
        case MEMBER.DELETE_MEMBER:
        case MEMBER.DELETE_PROFESSIONAL:
        case INSTRUMENT.DELETE_INSTRUMENT:
        case INSTRUMENT.DELETE_PROPOSAL_INSTRUMENT:
          return {
            ...state,
            deleteModal: false,
          };
        case INSTRUMENT.TAKE_BULK_INSTRUMENT_ACTION:
          return {
            ...state,
            modifiedInstruments: true,
            bulkModal: { showModal: false, type: 'Item' },
          };
        case TARGET_MAP.DELETE_TARGET_MAP:
          return {
            ...state,
            deleteModal: { showModal: false, type: 'Item' },
          };
        case TARGET_MAP.GET_TARGET_MAP_TEMPLATES:
          return {
            ...state,
            deleteModal: { showModal: false, type: 'Item' },
          };
        case HouseholdTypes.SYNC_HOUSEHOLD_INTEGRATIONS:
          return {
            ...state,
            syncAllResults: action.payload?.data?.details,
            justSynced: true,
          };
        case HouseholdTypes.CREATE_LEGAL:
          return {
            ...state,
            legalAction: 'create',
            legalId: action?.payload?.data?.id,
          };
        case HouseholdTypes.CREATE_PROPOSAL_LEGAL:
          return {
            ...state,
            legalId: action?.payload?.data?.id,
            showingLegalModal: false,
          };
        case HouseholdTypes.GET_LEGAL:
          return {
            ...state,
            legalAction: 'read',
            legalId: action?.payload?.data?.id,
            showingLegalModal: true,
            legal: action?.payload?.data,
          };
        case HouseholdTypes.READ_PROPOSAL_LEGAL:
          return {
            ...state,
            proposalLegal: action.payload.data,
            legalProposalError: null,
          };
        case HouseholdTypes.UPDATE_LEGAL:
          return {
            ...state,
            legalAction: 'update',
            legalId: action?.payload?.data?.id,
            updatedLegal: action?.payload?.data,
          };
        case HouseholdTypes.UPDATE_PROPOSAL_LEGAL:
          return {
            ...state,
            updatedProposalLegal: true,
            legalId: action?.payload?.data?.id,
            showingLegalModal: false,
          };
        case HouseholdTypes.DELETE_LEGAL:
          return {
            ...state,
            legalAction: 'delete',
            legalId: action?.payload?.data?.passSubId,
            deleteModal: { showModal: false, type: 'Item' },
          };
        case HouseholdTypes.DELETE_PROPOSAL_LEGAL:
        case HouseholdTypes.TAKE_PROPOSAL_ACTION:
          return {
            ...state,
            showingLegalModal: false,
          };
        case HouseholdTypes.UPDATE_FAMILY_MAP:
        case HouseholdTypes.CREATE_FAMILY_MAP:
          return {
            ...state,
            updatedFamilyMap: true,
            familyMapError: false,
          };
        case HouseholdTypes.DELETE_FAMILY_MAP:
          return {
            ...state,
            justDeletedFamilyMap: action?.payload?.data?.passSubId
              ? action?.payload?.data?.passSubId
              : true,
            familyMapError: false,
            deleteModal: { showModal: false, type: 'Item' },
          };
        default:
          return { ...state };
      }
    case API.API_START:
      switch (action.payload) {
        case HouseholdTypes.APPLY_STENCIL:
          return {
            ...state,
            applyingStencil: true,
            appliedStencil: false,
            applyStencilError: null,
          };
        case HouseholdTypes.CLONE_STENCIL:
          return {
            ...state,
            cloningStencil: true,
            cloneStencilError: null,
          };
        case HouseholdTypes.REFRESH_HOUSEHOLD_ASSET_MAP_IMAGE:
          return {
            ...state,
            fetchRefreshImage: true,
          };
        case HouseholdTypes.SHARE_REPORT:
          return {
            ...state,
            sharingReport: true,
            sharedReport: false,
          };
        default:
          return { ...state };
      }
    case API.API_END:
      switch (action.payload) {
        case HouseholdTypes.SYNC_INTEGRATION_HOUSEHOLD_SUMMARY:
        case HouseholdTypes.SYNC_HOUSEHOLD_INTEGRATION:
          return {
            ...state,
            justSynced: true,
          };
        case HouseholdTypes.SYNC_HOUSEHOLD_MODAL:
          return {
            ...state,
            justSynced: true,
            lastHouseholdSync: DateNow(),
          };
        case HouseholdTypes.CREATE_ASSET_MAP_LAYOUT:
          return {
            ...state,
            showingLayoutsModal: false,
            assetmapLayoutError: false,
          };
        case HouseholdTypes.DELETE_ASSET_MAP_LAYOUT:
          return {
            ...state,
            assetmapLayoutError: false,
            deleteModal: { showModal: false, type: 'Item' },
          };
        case HouseholdTypes.DELETE_ASSET_MAP_PROPOSAL:
          return {
            ...state,
            justDeletedProposal: true,
            assetmapProposalError: false,
            deleteModal: { showModal: false, type: 'Item' },
          };
        case HouseholdTypes.APPLY_STENCIL:
          return {
            ...state,
            appliedStencil: true,
          };
        case HouseholdTypes.SHARE_REPORT:
          return {
            ...state,
            sharingReport: false,
            sharedReport: true,
          };
        case HouseholdTypes.LINK_HH_MEMBER_TO_INTEGRATION:
          return {
            ...state,
            justLinkedMember: true,
          };
        case HouseholdTypes.UNLINK_HH_MEMBER_FROM_INTEGRATION:
        case HouseholdTypes.UNLINK_SALESFORCE_MEMBER:
          return {
            ...state,
            justUnlinkedMember: true,
          };
        case TARGET_MAP.GET_TARGET_MAP_INFO:
          return {
            ...state,
            deleteModal: { showModal: false, type: 'Item' },
            showingSignalDetailsModal: false,
            selectedSignal: null,
          };
        default:
          return { ...state };
      }
    case API.API_ERROR:
      switch (action.payload.label) {
        case HouseholdTypes.DELETE_ASSET_MAP_LAYOUT:
          return {
            ...state,
            assetmapLayoutError: action.payload.error,
          };
        case HouseholdTypes.GET_ASSET_MAP_PROPOSAL:
        case HouseholdTypes.GET_PROPOSAL_HOUSEHOLD_INFO:
          return {
            ...state,
            assetMapProposalError: {
              ...action.payload?.error,
              action: 'fetching',
            },
          };
        case HouseholdTypes.CREATE_ASSET_MAP_PROPOSAL:
          return {
            ...state,
            justDeletedProposal: false,
            assetMapProposalError: {
              ...action.payload?.error,
              action: 'creating',
            },
          };
        case HouseholdTypes.DELETE_ASSET_MAP_PROPOSAL:
          return {
            ...state,
            justDeletedProposal: false,
            assetMapProposalError: {
              ...action.payload?.error,
              action: 'deleting',
            },
            deleteModal: { showModal: false, type: 'Item' },
          };
        case HouseholdTypes.UPDATE_ASSET_MAP_PROPOSAL:
          return {
            ...state,
            assetMapProposalError: {
              ...action.payload?.error,
              action: 'updating',
            },
          };
        case HouseholdTypes.GET_HOUSEHOLD_INDEX:
          return {
            ...state,
            householdIndexError: action.payload.error,
          };
        case HouseholdTypes.GET_CONSUMER_HOUSEHOLDS:
          return {
            ...state,
            householdsError: action.payload,
          };
        case HouseholdTypes.GET_HOUSEHOLD_PERMISSION:
          return {
            ...state,
            householdPermission: action.payload.error,
          };
        case HouseholdTypes.GET_HOUSEHOLD_INFO:
          return {
            ...state,
            householdInfoError: action.payload.error,
          };
        case HouseholdTypes.CREATE_HOUSEHOLD_MANUAL:
          return {
            ...state,
            createHouseholdError: action.payload.error,
          };
        case HouseholdTypes.UPDATE_HOUSEHOLD_INFO:
          return {
            ...state,
            updateHouseholdError: action.payload.error,
          };
        case HouseholdTypes.UPDATE_INVESTOR_PROFILE:
          return {
            ...state,
            investorProfileError: action.payload.error,
          };
        case HouseholdTypes.DELETE_HOUSEHOLD_SHARING_INVITE:
          return {
            ...state,
            updateInviteError: action.payload?.error,
          };
        case HouseholdTypes.CREATE_HOUSEHOLD_SHARING_INVITE:
          return {
            ...state,
            createInviteError: action.payload?.error,
          };
        case HouseholdTypes.UPDATE_HOUSEHOLD_SHARING_INVITE:
          return {
            ...state,
            updateInviteError: action.payload?.error,
          };
        case HouseholdTypes.GET_HOUSEHOLD_MILESTONES:
          return {
            ...state,
            householdMilestonesError: action.payload.error,
            householdMilestones: null,
            justUpdatedMilestone: false,
          };
        case HouseholdTypes.GET_LINKED_INTEGRATION_MEMBERS:
          return {
            ...state,
            linkedMembersError: action.payload.error,
          };
        case HouseholdTypes.SEARCH_HOUSEHOLD_INTEGRATION:
          return {
            ...state,
            integrationResultsError: action.payload,
          };
        case HouseholdTypes.LINK_HH_MEMBER_TO_INTEGRATION:
          return {
            ...state,
            justLinkedMember: false,
            linkIntegrationMemberError: action.payload.error.data,
          };
        case HouseholdTypes.APPLY_STENCIL:
          return {
            ...state,
            applyingStencil: false,
            applyStencilError:
              action.payload?.error?.data?.detail || 'Error applying stencil',
          };
        case HouseholdTypes.GET_STENCIL_MEMBERS:
          return {
            ...state,
            applyStencilError:
              action.payload?.error?.data?.detail ||
              'Error getting stencil members',
          };
        case HouseholdTypes.CLONE_STENCIL:
          return {
            ...state,
            cloningStencil: false,
            cloneStencilError:
              action.payload?.error?.data?.detail || 'Error cloning stencil',
          };
        case HouseholdTypes.GET_SIGNAL_COMMENTS:
          return {
            ...state,
            signalCommentsError: action.payload.error,
          };
        case HouseholdTypes.CREATE_SIGNAL_COMMENT:
          return {
            ...state,
            signalCommentsError: action.payload.error,
          };
        case HouseholdTypes.DELETE_SIGNAL_COMMENT:
          return {
            ...state,
            signalCommentsError: action.payload.error,
          };
        case HouseholdTypes.TOGGLE_SNOOZE_SIGNAL:
          return {
            ...state,
            signalMuteError: action.payload.error,
          };
        case HouseholdTypes.GET_HOUSEHOLD_FOLDER_OPTIONS:
          return {
            ...state,
            householdFolderError: action.payload,
          };
        case HouseholdTypes.LINK_HOUSEHOLD_FILE_STORAGE:
          return {
            ...state,
            linkFileStorageError: action.payload.error,
          };
        case HouseholdTypes.GET_HOUSEHOLD_REPORTS:
          return {
            ...state,
            householdReportsError: action.payload.error,
          };
        case HouseholdTypes.GET_BENEFICIARY_REPORT:
          return {
            ...state,
            beneficiaryReportError: action.payload.error,
          };
        case HouseholdTypes.SHARE_REPORT:
          return {
            ...state,
            sharingReport: false,
            sharedReport: false,
            reportShareFailed: action.payload.error,
          };
        case HouseholdTypes.UPDATE_HOUSEHOLD_ADVISOR:
          return {
            ...state,
            updateAdvisorError: action.payload.error,
          };
        case HouseholdTypes.GET_HOUSEHOLD_ROLES:
          return {
            ...state,
            rolesError: action.payload.error,
          };
        case HouseholdTypes.GET_ROLE_PERMISSIONS:
          return {
            ...state,
            rolePermissionsError: action.payload.error,
          };
        case HouseholdTypes.GET_FAMILY_MAPS:
        case HouseholdTypes.GET_FAMILY_MAP:
          return {
            ...state,
            familyMapError: {
              ...action.payload?.error,
              action: 'fetching',
            },
          };
        case HouseholdTypes.CREATE_FAMILY_MAP:
          return {
            ...state,
            justDeletedProposal: false,
            familyMapError: {
              ...action.payload?.error,
              action: 'creating',
            },
          };
        case HouseholdTypes.UPDATE_FAMILY_MAP:
          return {
            ...state,
            familyMapError: {
              ...action.payload?.error,
              action: 'updating',
            },
          };
        case HouseholdTypes.DELETE_FAMILY_MAP:
          return {
            ...state,
            justDeletedFamilyMap: false,
            familyMapError: {
              ...action.payload?.error,
              action: 'deleting',
            },
          };

        case HouseholdTypes.CREATE_LEGAL:
        case HouseholdTypes.GET_LEGAL:
        case HouseholdTypes.UPDATE_LEGAL:
        case HouseholdTypes.DELETE_LEGAL:
          return {
            ...state,
            legalError: action.payload.error,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default households;
