/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  colors,
  messageColors,
  maxDevice,
  fonts,
} from '../../styles/variables';
import {
  fileStorageHome,
  cloudUpload,
  launchLink,
  download,
  errorCircleRed,
} from '../../assets';
import { GenerateFileIcon, HtmlToString } from '../../utils';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';

const FileStorageExplorer = ({
  integration,
  folder,
  getFolder,
  refreshing,
  loadingContent,
  loadingError,
  loadingErrorMessage,
  toggleDisplayModal,
}) => {
  return (
    <FileStorageOptionContainer>
      <FSHeadingRow>
        {refreshing ? (
          <>
            <LoadingPlaceholder
              width="20px"
              height="20px"
              round="true"
              margin={'0 8px 0 0'}
            />
            <LoadingPlaceholder margin={'0 8px 0 0'} />
          </>
        ) : folder?.name ? (
          <TopRowContainer>
            <FSHomeIcon
              src={fileStorageHome}
              alt="home"
              data-image={`${integration}-home`}
              onClick={() => getFolder(integration, null)}
            />
            <FSHeadingNameContainer>
              {folder?.parent && (
                <>
                  <PreviousPath
                    onClick={() => getFolder(integration, folder?.parent)}
                  >
                    ...
                  </PreviousPath>
                  <PathSeparator>/</PathSeparator>
                </>
              )}
              {HtmlToString(folder?.name)}
            </FSHeadingNameContainer>
          </TopRowContainer>
        ) : loadingError ? (
          <ErrorParentDisplay>
            <img
              src={errorCircleRed}
              alt="Error"
              data-image={`${integration}-error`}
            />
            Error loading parent folder
          </ErrorParentDisplay>
        ) : (
          <>
            <LoadingPlaceholder
              width="20px"
              height="20px"
              round="true"
              margin={'0 8px 0 0'}
            />
            <LoadingPlaceholder margin={'0 8px 0 0'} />
          </>
        )}
        <FSUploadFileContainer>
          <FileUploadButton onClick={toggleDisplayModal}>
            <img
              src={cloudUpload}
              alt="Upload"
              data-image={`${integration}-upload`}
            />
            <span>Upload</span>
          </FileUploadButton>
        </FSUploadFileContainer>
      </FSHeadingRow>
      <FSItemsContainer>
        {loadingContent ? (
          <LoadingContainer>
            <LoadingPlaceholder
              width="20px"
              height="20px"
              round="true"
              margin={'0 8px 3px 0'}
            />
            <LoadingPlaceholder margin={'0 8px 3px 0'} />
          </LoadingContainer>
        ) : loadingError ? (
          <ErrorDisplay>{loadingErrorMessage}</ErrorDisplay>
        ) : folder?.items && folder?.items.length !== 0 ? (
          folder?.items.map((item, index) => {
            return (
              <FileStorageChild key={index}>
                <ClickableArea
                  onClick={
                    item.type === 'folder'
                      ? () => getFolder(integration, item.id)
                      : null
                  }
                  type={item.type}
                >
                  <StorageIcon
                    src={GenerateFileIcon(item, 'mime_type')}
                    alt={item.type === 'folder' ? 'folder' : 'file'}
                    data-image={`${integration}-${
                      item.type === 'folder' ? 'folder' : 'file'
                    }-${index}`}
                  />
                  <StorageName>{HtmlToString(item?.name)}</StorageName>
                </ClickableArea>
                <HoverActions>
                  <a
                    href={`/api/v3/member/integration/${integration}/go/object/${item.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={launchLink}
                      alt="Link"
                      data-image={`launch-${index}`}
                    />
                  </a>
                  {item.type !== 'folder' && (
                    <a
                      href={`/api/v3/member/integration/${integration}/download/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={download}
                        alt="Link"
                        data-image={`download-${index}`}
                      />
                    </a>
                  )}
                </HoverActions>
              </FileStorageChild>
            );
          })
        ) : folder ? (
          <EmptyDisplay>No Files</EmptyDisplay>
        ) : (
          <LoadingPlaceholder margin={'0 8px 0 0'} />
        )}
      </FSItemsContainer>
    </FileStorageOptionContainer>
  );
};

const TopRowContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`;

const PreviousPath = styled.span`
  color: ${colors.paleGrey};
  font-weight: ${fonts.light};
  cursor: pointer;
  font-size: 16px;
`;

const PathSeparator = styled.span`
  margin: 0 5px;
  color: ${colors.paleGrey};
  font-weight: ${fonts.light};
`;

const FSHomeIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transform: scale(1.01);
  }
`;

const StorageIcon = styled.img`
  width: 17px;
  height: 17px;
  margin-right: 8px;
  cursor: pointer;
`;

const HoverActions = styled.div`
  display: none;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  a {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
  }
  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      transform: scale(1.01);
    }
  }
`;

const FileStorageChild = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  &:hover {
    ${HoverActions} {
      display: flex;
    }
  }
`;

const ClickableArea = styled.div`
  margin-right: 8px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  cursor: ${(props) => (props.type === 'folder' ? 'pointer' : 'default')};
`;

const StorageName = styled.span`
  @media ${maxDevice.mobileL} {
    max-width: 200px;
  }
  font-size: 13px;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FileStorageOptionContainer = styled.div`
  padding: 8px 0;
  font-size: 14px;
`;

const FSItemsContainer = styled.div`
  margin: 5px 0;
  margin-left: 8px;
  text-align: left;
  max-height: 205px;
  overflow-y: auto;
`;

const FSHeadingRow = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const FSHeadingNameContainer = styled.p`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 1 auto;
  font-size: 14px;
  font-weight: ${fonts.semiBold};
`;

const FSUploadFileContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
`;

const FileUploadButton = styled.button`
  background: ${colors.lightGrey};
  font-size: 11px;
  padding: 5px 15px;
  border-radius: 50px;
  font-weight: ${fonts.semiBold};
  height: 30px;
  width: 30px;
  cursor: pointer;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  /* transition: all 0.2s ease-in-out; */
  img {
    width: 16px;
    height: 16px;
  }
  span {
    /* display: none;
      transition: opacity 1s ease-in-out; */
    max-width: 0;
    opacity: 0;
    white-space: nowrap;
    transition: 0.25s ease-in-out;
  }
  &:hover {
    transform: scale(1.01);
    background: ${colors.hoverLightGrey};
    width: 87px;
    justify-content: flex-start;

    span {
      /* display: inline-block; */
      opacity: 1;
      margin-left: 5px;
    }
  }
`;

const EmptyDisplay = styled.div`
  font-size: 11px;
  color: ${colors.paleGrey};
`;

const ErrorDisplay = styled.div`
  font-size: 12px;
  color: ${messageColors.error};
`;

const ErrorParentDisplay = styled(ErrorDisplay)`
  font-weight: ${fonts.semiBold};
  font-size: 13px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  img {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

FileStorageExplorer.propTypes = {
  integration: PropTypes.string,
  folder: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  getFolder: PropTypes.func,
  refreshing: PropTypes.bool,
  loadingContent: PropTypes.bool,
  loadingError: PropTypes.bool,
  loadingErrorMessage: PropTypes.string,
  toggleDisplayModal: PropTypes.func,
};

export default FileStorageExplorer;
