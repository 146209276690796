import React from 'react';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';
import { Tooltip } from 'react-tippy';
import { TextInput, Select, DatePickerInput, CheckBox, TextEditor } from '..';
import { lock, hint, errorCircleRed } from '../../assets';
import {
  colors,
  inputColors,
  messageColors,
  fonts,
} from '../../styles/variables';
import {
  TooltipIcon,
  TooltipIconError,
  InputLabel,
  TextInputContainer,
  TextInputRow,
  DatePickerContainer,
  CheckboxContainer,
  SelectContainer,
} from '../../styles/library/inputStyles';
const modules = {
  toolbar: [['bold', 'italic', 'underline', 'strike'], ['link']],
};

const generateInputLabel = (input, viewOnly = false) => {
  return (
    <InputLabel htmlFor={input.id}>
      <span>{input.label}</span>
      {input.disabled && !viewOnly ? (
        <LockImage
          src={lock}
          alt="lock"
          data-image="input-lock"
          title={input.title}
        />
      ) : input.required ? (
        <RequiredStar>*</RequiredStar>
      ) : null}
      {input.hasError && (
        <Tooltip
          title={input.errorMessage}
          position="top"
          trigger="mouseenter click"
          followCursor="true"
          delay={0}
          arrow={true}
          arrowSize={'small'}
          animation={'fade'}
          animateFill={false}
          transitionFlip={false}
          size={'small'}
        >
          <TooltipIconError
            src={errorCircleRed}
            alt="error"
            data-image={`tooltip-error-${input.id || input.name}`}
          />
        </Tooltip>
      )}
      {input.tooltip && !input.hasError && (
        <Tooltip
          title={input.tooltip}
          position="top"
          trigger="mouseenter click"
          followCursor="true"
          delay={0}
          arrow={true}
          arrowSize={'small'}
          animation={'fade'}
          animateFill={false}
          transitionFlip={false}
          size={'small'}
        >
          <TooltipIcon
            src={hint}
            alt="hint"
            data-image={`tooltip-hint-${input.id || input.name}`}
          />
        </Tooltip>
      )}
      {input.isExpired && <Expired>Expired</Expired>}
      {input.expiryDisplay && <ExpiryDate>{input.expiryDisplay}</ExpiryDate>}
    </InputLabel>
  );
};

const formatCalculator = (e, input) => {
  let s = e.target.value.replace('^', '**');
  try {
    // eslint-disable-next-line no-eval
    e.target.value = Math.round(eval(s.replace(/[^\d.+-/\\*()]/gi, '')));
  } catch (ex) {
    e.target.value = '';
  }
  return input.onChange(e, input);
};

const limitCalculatorInput = (e, input) => {
  if (e.key === 'Enter' || e.key === 'NumpadEnter') {
    formatCalculator(e, input);
    e.preventDefault();
  }

  const re = /[=0123456789.+-\\*/()]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

const generateCalculatorCurrencyInput = (key, input, currency, viewOnly) => {
  if (!input.isVisible) {
    return;
  }
  const prefixValue =
    currency?.symbol_location === 'prefix' ? currency.symbol : '';
  const suffixValue =
    currency?.symbol_location === 'suffix' ? currency.symbol : '';
  if (
    typeof input.value == 'undefined' ||
    input.value === '' ||
    input.value === null ||
    input.value.toString().startsWith('=')
  ) {
    return (
      <TextInputContainer
        key={key}
        width={input.width}
        data-disabled={input.disabled}
      >
        {input.label && generateInputLabel(input, viewOnly)}
        <TextInputRow>
          <TextInput
            type={input.type}
            name={input.name}
            id={input.name}
            maxLength={input.maxLength}
            disabled={input.disabled}
            // id={input.id}
            currency={currency}
            hasError={input.hasError}
            required={input.required}
            placeholder={input.placeholder}
            value={input.value}
            hasSuffix={input.hasSuffix}
            suffixValue={input.suffixValue}
            onChange={(values) => input.onChange(values, input)}
            onKeyPress={(e) => limitCalculatorInput(e, input)}
            onBlur={(e) => formatCalculator(e, input)}
            withIcon={input.withIcon}
            icons={input.icons}
            ageDate={input.ageDate}
            onDateChange={input.onDateChange}
            dateValue={input.dateValue}
            autoFocus={typeof input.value === 'undefined'}
            // iconSrc={input.iconSrc}
            // onClick={input.onClick}
          />
        </TextInputRow>
      </TextInputContainer>
    );
  } else {
    return (
      <TextInputContainer
        key={key}
        width={input.width}
        data-disabled={input.disabled}
      >
        {input.label && generateInputLabel(input, viewOnly)}
        <TextInputRow>
          <NumericFormat
            thousandSeparator={currency?.thousand_separator}
            decimalSeparator={currency?.decimal}
            prefix={prefixValue ? `${prefixValue} ` : ''}
            suffix={suffixValue ? ` ${suffixValue}` : ''}
            disabled={input.disabled}
            allowNegative={true}
            className="number-formatter"
            inputMode="numeric"
            id={input.name}
            //This will autoFocus if we just started typing,
            //but not if we calculated a larger number with the calculator
            autoFocus={input.value.length <= 1}
            value={input.value}
            type={'tel'}
            placeholder={input.placeholder}
            onBlur={input.onBlur}
            onValueChange={(values) => input.onChange(values, input)}
            onKeyPress={input.onKeyPress}
            style={{
              border: input.hasError
                ? `1px solid ${messageColors.error}`
                : null,
              background: input.disabled ? colors.lightGrey : null,
            }}
          />
        </TextInputRow>
      </TextInputContainer>
    );
  }
};

const generateCurrencyInput = (key, input, currency, viewOnly) => {
  const prefixValue =
    currency?.symbol_location === 'prefix' ? currency.symbol : '';
  const suffixValue =
    currency?.symbol_location === 'suffix' ? currency.symbol : '';
  return (
    input.isVisible && (
      <TextInputContainer
        key={key}
        width={input.width}
        data-disabled={input.disabled}
      >
        {input.label && generateInputLabel(input, viewOnly)}
        <TextInputRow>
          <NumericFormat
            thousandSeparator={currency?.thousand_separator}
            decimalSeparator={currency?.decimal}
            prefix={prefixValue ? `${prefixValue} ` : ''}
            suffix={suffixValue ? ` ${suffixValue}` : ''}
            disabled={input.disabled}
            allowNegative={true}
            className="number-formatter"
            inputMode="numeric"
            value={input.value}
            type={'tel'}
            placeholder={input.placeholder}
            onValueChange={(values) => input.onChange(values, input)}
            style={{
              border: input.hasError
                ? `1px solid ${messageColors.error}`
                : null,
              background: input.disabled ? colors.lightGrey : null,
            }}
          />
        </TextInputRow>
      </TextInputContainer>
    )
  );
};

const generateNumberInput = (key, input, currency, viewOnly) => {
  return (
    input.isVisible && (
      <TextInputContainer
        key={key}
        width={input.width}
        data-disabled={input.disabled}
      >
        {input.label && generateInputLabel(input, viewOnly)}
        <TextInputRow>
          <NumericFormat
            thousandSeparator={currency?.thousand_separator}
            decimalSeparator={currency?.decimal}
            prefix={''}
            suffix={''}
            allowNegative={true}
            className="number-formatter"
            inputMode="numeric"
            value={input.value}
            disabled={input.disabled}
            placeholder={input.placeholder}
            onValueChange={(values) => input.onChange(values, input)}
            style={{
              border: input.hasError
                ? `1px solid ${messageColors.error}`
                : null,
              background: input.disabled ? colors.lightGrey : null,
            }}
          />
        </TextInputRow>
      </TextInputContainer>
    )
  );
};

const generateTextInput = (key, input, currency, viewOnly) => {
  return (
    input.isVisible && (
      <TextInputContainer
        key={key}
        margin={input.margin}
        width={input.width}
        mobileWidth={input.mobileWidth}
        mobileMargin={input.mobileMargin}
        data-disabled={input.disabled}
      >
        {input.label && generateInputLabel(input, viewOnly)}
        <TextInputRow>
          <TextInput
            type={input.type}
            name={input.name}
            maxLength={input.maxLength}
            disabled={input.disabled}
            // id={input.id}
            currency={currency}
            hasError={input.hasError}
            required={input.required}
            placeholder={input.placeholder}
            value={input.value}
            hasSuffix={input.hasSuffix}
            suffixValue={input.suffixValue}
            onChange={input.onChange}
            onBlur={input.onBlur}
            withIcon={input.withIcon}
            iconSrc={input.iconSrc}
            iconName={input.iconName}
            icons={input.icons}
            ageDate={input.ageDate}
            onKeyPress={input.onKeyPress}
            onClick={input.onClick}
            onFocus={input.onFocus}
            onDateChange={input.onDateChange}
            dateValue={input.dateValue}
            dateFormat={input.dateFormat}
            justUpdated={input.justUpdated}
            // iconSrc={input.iconSrc}
            // onClick={input.onClick}
          />
          {input.inputImage && (
            <InputImage
              src={input.inputImage}
              alt={input.imageName}
              onClick={input.imageClick}
              data-image={input.imageName}
            />
          )}
        </TextInputRow>
      </TextInputContainer>
    )
  );
};

const generateDateInput = (key, input, viewOnly) => {
  return (
    input.isVisible && (
      <DatePickerContainer
        key={key}
        style={input.width ? { width: input.width } : null}
        data-disabled={input.disabled}
      >
        {input.label && generateInputLabel(input, viewOnly)}
        <DatePickerInput
          value={input.value}
          onChange={input.onChange}
          placeholder={input.placeholder}
          maxDate={input.maxDate}
          afterYearRange={input.afterYearRange}
          beforeYearRange={input.beforeYearRange}
          hasError={input.hasError}
          justUpdated={input.justUpdated}
          dateFormat={input.dateFormat}
          disabled={input.disabled}
        />
      </DatePickerContainer>
    )
  );
};

const generateCheckbox = (key, input) => {
  return (
    input.isVisible && (
      <CheckboxContainer
        key={key}
        margin={input.margin}
        width={input.width}
        data-disabled={input.disabled}
      >
        <CheckBox
          label={input.label}
          checked={input.value}
          onChange={input.onChange}
          disabled={input.disabled}
          isCustom={input.isCustom}
          labelStyling={input.labelStyling}
        />
      </CheckboxContainer>
    )
  );
};

const formatGroupLabel = (data) => (
  <GroupLabelContainer>
    <GroupLabelValue>{data.label}</GroupLabelValue>
    {/* <GroupLabelCount>{data.options.length}</GroupLabelCount> */}
  </GroupLabelContainer>
);

const generateSelectContainer = (key, input, viewOnly) => {
  return (
    input.isVisible && (
      <React.Fragment key={key}>
        <SelectContainer
          key={key}
          width={input.width}
          maxWidth={input.maxWidth}
          margin={input.margin}
          data-disabled={input.disabled}
        >
          {input.label && generateInputLabel(input, viewOnly)}
          <Select
            selectedOption={input.value}
            components={
              input.customComponent ? { Option: input.customComponent } : null
            }
            onChange={input.onChange}
            options={input.options}
            isSearchable={input.customSelect ? false : true}
            placeholder={input.placeholder}
            autoFocus={false}
            isDisabled={input.disabled}
            isLoading={input.isLoading}
            hasError={input.hasError}
            onEnter={input.onEnter}
            formatGroupLabel={formatGroupLabel}
            closeMenuOnSelect={input.customSelect ? false : true}
          />
        </SelectContainer>
        {input.hasSelected && input.showSelected && (
          <LinkedItems>
            {input.selectedOptions &&
              input.selectedOptions.map((item, index) => {
                return (
                  <LinkItem key={index}>
                    <p>
                      {item.name}
                      {item.age ? (
                        <span
                          style={
                            item.date_of_birth_estimated
                              ? { fontStyle: 'italic' }
                              : null
                          }
                        >
                          ({item.age}
                          {item.date_of_birth_estimated && 'e'})
                        </span>
                      ) : item.source ? (
                        <span>
                          ({item.source}) - {item.formattedValue}
                        </span>
                      ) : null}
                    </p>
                    {input.ratioKey && (
                      <RatioContainer>
                        <InputSuffix>
                          <input
                            type="number"
                            value={item[input.ratioKey]}
                            disabled={input.disabled}
                            onChange={(e) =>
                              input.optionOnChange(e, item, input.memberType)
                            }
                          />
                        </InputSuffix>
                      </RatioContainer>
                    )}
                  </LinkItem>
                );
              })}
          </LinkedItems>
        )}
      </React.Fragment>
    )
  );
};

const generateTextArea = (key, input) => {
  return (
    <TextEditor
      key={key}
      label={input.label}
      value={input.value}
      onChange={input.onChange}
      onBlur={input.onBlur}
      height={input.height}
      modules={modules}
      showClear={input.showClear}
      id={input?.id}
    />
  );
};

const generateMemberDisplay = (key, input) => {
  return (
    <MemberDisplayContainer key={key}>
      {input.label && <InputLabel htmlFor={input.id}>{input.label}</InputLabel>}
      <div>
        {input.value.length !== 0 ? (
          input.value.map((member, index) => {
            return (
              <LinkedLabel key={index}>
                <span>{member.memberObj.name}</span>
                {member.memberObj.member_type === 'member' && (
                  <MemberAge
                    style={
                      member.memberObj.date_of_birth_estimated
                        ? { fontStyle: 'italic' }
                        : null
                    }
                  >
                    {member.memberObj.age}
                    {member.memberObj.date_of_birth_estimated && 'e'}
                  </MemberAge>
                )}
              </LinkedLabel>
            );
          })
        ) : (
          <LinkedLabel>None</LinkedLabel>
        )}
      </div>
    </MemberDisplayContainer>
  );
};

export const GenerateInput = (input, index, currency, viewOnly) => {
  if (input.type === 'calculatorCurrency') {
    return generateCalculatorCurrencyInput(index, input, currency, viewOnly);
  } else if (input.type === 'currency') {
    return generateCurrencyInput(index, input, currency, viewOnly);
  } else if (input.type === 'number' && input.formatNumber) {
    return generateNumberInput(index, input, currency, viewOnly);
  } else if (
    input.type === 'text' ||
    input.type === 'password' ||
    input.type === 'number'
  ) {
    return generateTextInput(index, input, currency, viewOnly);
  } else if (input.type === 'checkbox') {
    return generateCheckbox(index, input);
  } else if (input.type === 'select') {
    return generateSelectContainer(index, input, viewOnly);
  } else if (input.type === 'date') {
    return generateDateInput(index, input, viewOnly);
  } else if (input.type === 'textarea') {
    return generateTextArea(index, input);
  } else if (input.type === 'member-display') {
    return generateMemberDisplay(index, input);
  }
};

const InputSuffix = styled.span`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  input {
    flex: 0 0 auto;
    border: 1px solid ${inputColors.border};
    border-radius: 3px;
    width: 32px;
    padding: 2px 15px 2px 5px;
    font-size: 10px;
    color: ${colors.darkGrey};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &:after {
    color: ${colors.paleGrey};
    content: '%';
    position: absolute;
    top: 0;
    right: 6px;
    line-height: 11px;
    transform: translateY(50%);
    display: block;
    font-size: 10px;
  }
`;

const ExpiryDate = styled.span`
  text-align: right;
  flex: 1 1 auto;
  color: ${colors.paleGrey};
`;

const Expired = styled(ExpiryDate)`
  color: ${messageColors.error};
  text-transform: uppercase;
`;

const RatioContainer = styled.div`
  display: flex;
`;

const MemberDisplayContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
`;

const LinkedLabel = styled.span`
  font-size: 11px;
  display: inline-block;
  background: ${colors.lightGrey};
  margin-right: 5px;
  margin-bottom: 3px;
  padding: 3px 5px;
  border-radius: 2px;
`;

const MemberAge = styled.span`
  margin-left: 4px;
  color: ${colors.paleGrey};
  font-size: 10px;
`;

const RequiredStar = styled.span`
  color: ${messageColors.error};
  margin-left: 2px;
`;

const LockImage = styled.img`
  margin-left: 5px;
  width: 100%;
  height: 100%;
  max-width: 9px;
  max-height: 9px;
`;

const InputImage = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 5px;
  flex: 0 0 auto;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transform: scale(1.01);
  }
`;

const LinkItem = styled.div``;

const LinkedItems = styled.div`
  background: ${colors.lightGrey};
  margin-bottom: 10px;
  width: 100%;
  border-radius: 0 0 6px 6px;
  margin-top: -13px;
  border: thin solid ${inputColors.border};
  ${LinkItem} {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 10px;
    &:first-child {
      padding-top: 7px;
    }
    &:last-child {
      padding-bottom: 5px;
    }
  }
  p {
    text-align: left;
    flex: 1 1 auto;
    font-size: 12px;
    color: ${colors.darkGrey};
    span {
      color: ${colors.paleGrey};
      margin-left: 4px;
    }
  }
`;

const GroupLabelContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const GroupLabelValue = styled.span`
  flex: 1 1 auto;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${colors.paleGrey};
`;

// const GroupLabelCount = styled.span`
//   margin: 0 0 0 5px;
//   flex: 0 0 auto;
//   font-size: 10px;
//   padding: 3px;
//   height: 15px;
//   width: 15px;
//   border-radius: 50px;
//   color: ${colors.darkGrey};
//   background: ${colors.lightGrey};
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
// `;
