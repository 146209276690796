/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { uploadFileStorageFile } from '../../store/actions';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import { integrationOptions, SendTrackingEvent } from '../../utils';
import { colors, fonts } from '../../styles/variables';
import DropZone from '../DropZone';

const FileUploadModal = ({ hide, fileUploadIntegration, uploadFolderId }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { uploadedFileId, failedFileId, failedFileMessage, invocationId } =
    useSelector((state) => ({
      uploadedFileId: state.households.uploadedFileId,
      failedFileId: state.households.failedFileId,
      failedFileMessage: state.households.failedFileMessage,
      invocationId: state.configs.invocationId,
    }));
  const [integration, setIntegration] = useState();
  const [convertedFile, setConvertedFile] = useState();

  useEffect(() => {
    if (convertedFile) {
      const fileData = {
        name: convertedFile?.name,
        content: convertedFile?.content.split(',')[1],
      };
      dispatch(
        uploadFileStorageFile(
          integration.id,
          uploadFolderId,
          fileData,
          convertedFile?.uuid
        )
      );
      SendTrackingEvent(
        invocationId,
        'action',
        'integration_upload_file',
        'integration_households',
        {
          integration: integration.id,
          file: convertedFile.uuid,
        }
      );
    }
  }, [convertedFile]);

  useEffect(() => {
    if (fileUploadIntegration) {
      const matchingIntegration = integrationOptions.find(
        (integration) => integration.id === fileUploadIntegration
      );
      setIntegration(matchingIntegration);
    }
  }, [fileUploadIntegration]);
  // On close of modal (x or close button) refresh update folder if something has been uploaded

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Upload Files</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <SubText>Upload files to your {integration?.name} account:</SubText>
            <TabContent style={{ marginBottom: 0 }}>
              <DropZone
                text="Drag and Drop file here to upload"
                buttonText="Or Click to Select"
                subtext="Max File Size: 8MB"
                maxMB={8}
                multiple={true}
                type="preview"
                accepted={false}
                clearFiles={true}
                setFileData={setConvertedFile}
                uploadedFileId={uploadedFileId}
                failedFileId={failedFileId}
                failedFileMessage={failedFileMessage}
                hide={hide}
              />
            </TabContent>
          </ModalContentContainer>
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const SubText = styled.p`
  margin-bottom: 15px;
  font-size: 12px;
  color: ${colors.darkGrey};
`;

export const SelectDropdownOption = styled.div`
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-start;
  label {
    flex: 1 1 auto;
    margin-left: 8px;
    width: 220px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SelectName = styled.span`
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
`;

export const SelectDescription = styled.span`
  font-weight: ${fonts.regular};
  margin-left: 8px;
  font-size: 11px;
  color: ${colors.paleGrey};
`;

FileUploadModal.propTypes = {
  hide: PropTypes.func,
  fileUploadIntegration: PropTypes.string,
  uploadFolderId: PropTypes.string,
};

export default FileUploadModal;
