/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '..';
import {
  toggleShowStencilModal,
  toggleShowStencilHelperModal,
} from '../../store/actions';
import { timesWhite, addStencil } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { ButtonThemes } from '../../styles/themes';
import { colors, inputColors } from '../../styles/variables';
import { STENCIL_LINK as stencilLink } from '../../utils/constants';

const StencilInfoModal = ({ hide }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [dismissHelper, setDismissHelper] = useState(false);

  const showStencils = () => {
    dispatch(toggleShowStencilModal(true));
    return hide();
  };

  const toggleDismissHelper = (value) => {
    setDismissHelper(value);
    dispatch(toggleShowStencilHelperModal(value));
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              Save Time with Stencils<sup>™</sup>
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <FlexContainer>
              <LeftContent>
                <img src={addStencil} alt="Stencil" data-image="add-stencil" />
              </LeftContent>
              <RightContent>
                <SubText>
                  {`Stencils let you quickly tailor an Asset-Map to your client's
                  financial situation. Choose from a pre-defined library,
                  including fact-finding, demographic peer models, and
                  time-of-life scenarios, or customize your own.`}
                  <LearnMoreLink
                    href={stencilLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click To Learn More
                  </LearnMoreLink>
                </SubText>
              </RightContent>
            </FlexContainer>

            <DismissContainer>
              <label>
                <input
                  type="checkbox"
                  checked={dismissHelper}
                  onChange={() => toggleDismissHelper(!dismissHelper)}
                />
                <span>{`Don't show this window in the future`}</span>
              </label>
            </DismissContainer>
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Close'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button text={'Show Stencils'} onClick={() => showStencils()} />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const FlexContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const LeftContent = styled.div`
  flex: 0 0 auto;
  img {
    width: 60px;
  }
`;

const RightContent = styled.div`
  flex: 1 1 auto;
  margin-left: 20px;
`;

const SubText = styled.p`
  text-align: left;
  font-size: 13px;
  color: ${colors.darkGrey};
`;

const LearnMoreLink = styled.a`
  color: ${colors.lightBlue};
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 5px;
  &:hover {
    color: ${colors.blue};
  }
`;

const DismissContainer = styled.div`
  text-align: left;
  font-size: 12px;
  margin: 10px 0 5px -3px;
  cursor: pointer;
  label {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: ${inputColors.label};
    span {
      margin-left: 5px;
    }
  }
`;

StencilInfoModal.propTypes = {
  hide: PropTypes.func,
};

export default StencilInfoModal;
