/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Select, LoadingAnimation, Error } from '../../../components';
import {
  linkHouseholdFileStorage,
  unlinkHouseholdFileStorage,
} from '../../../store/actions';
import { TertiaryHeading } from '../../../styles/library/fontStyles';
import { ErrorThemes, selectThemes } from '../../../styles/themes';
import { colors, inputColors, fonts } from '../../../styles/variables';
import { SendTrackingEvent } from '../../../utils';

const HouseholdFileStorage = ({ selectedIntegration }) => {
  const dispatch = useDispatch();
  const {
    currentHousehold,
    householdFolders,
    householdFolderError,
    invocationId,
    linkFileStorageError,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    householdFolders: state.households.householdFolders,
    householdFolderError: state.households.householdFolderError,
    invocationId: state.configs.invocationId,
    linkFileStorageError: state.households.linkFileStorageError,
  }));
  const [folderOptions, setFolderOptions] = useState([
    { label: 'Create New Folder for Household', value: 'new' },
  ]);
  const [selectedFolder, setSelectedFolder] = useState({
    label: 'Create New Folder for Household',
    value: 'new',
  });
  const [isUnlinkingHousehold, setIsUnlinkingHousehold] = useState(false);
  const [isLinkingHousehold, setIsLinkingHousehold] = useState(false);
  const [isLoadingIntegration, setIsLoadingIntegration] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  //HOOK THAT RESETS LOCAL STATE AND STARTS LOADING DISPLAY
  useEffect(() => {
    if (selectedIntegration) {
      setIsLoadingIntegration(true);
      setIsUnlinkingHousehold(false);
      setIsLinkingHousehold(false);
      setSelectedFolder({
        label: 'Create New Folder for Household',
        value: 'new',
      });
      setErrorMessage('');
    }
  }, [selectedIntegration]);

  //HOOK TO SET FOLDER OPTIONS RETURNED FROM API AND STOP LOADING DISPLAY
  useEffect(() => {
    if (householdFolders) {
      setIsUnlinkingHousehold(false);
      setIsLinkingHousehold(false);
      setIsLoadingIntegration(false);
      setErrorMessage('');
      let transformedFolders = [];
      if (householdFolders?.available_folders) {
        transformedFolders = householdFolders?.available_folders.reduce(
          (acc, folder) => {
            if (!folder.linked) {
              folder.label = folder.name;
              folder.value = folder.id;
              return [...acc, folder];
            }
            return acc;
          },
          []
        );
      }
      return setFolderOptions([
        { label: 'Create New Folder for Household', value: 'new' },
        ...transformedFolders,
      ]);
    }
  }, [householdFolders]);

  //HOOK TO SET ERROR DISPLAY FROM FETCHING HOUSEHOLD FOLDERS
  useEffect(() => {
    if (householdFolderError) {
      setIsUnlinkingHousehold(false);
      setIsLinkingHousehold(false);
      setIsLoadingIntegration(false);
      setErrorMessage(`Error communicating with ${selectedIntegration.name}. If the problem
          persists try deactivating and reactivating ${selectedIntegration.name}.`);
    }
  }, [householdFolderError]);

  //HOOK TO SET ERROR DURING LINK HOUSEHOLD TO FOLD
  useEffect(() => {
    if (linkFileStorageError) {
      setIsLinkingHousehold(false);
      setErrorMessage(linkFileStorageError?.data?.detail);
    } else if (linkFileStorageError === false) {
      setErrorMessage('');
    }
  }, [linkFileStorageError]);

  //FUNCTION TO ENABLE FILE STORAGE FOR HOUSEHOLD
  const enableFileStorage = () => {
    setIsLinkingHousehold(true);
    dispatch(
      linkHouseholdFileStorage(
        currentHousehold?.uuid,
        selectedIntegration.key,
        selectedFolder?.value
      )
    );
    return SendTrackingEvent(
      invocationId,
      'action',
      selectedFolder.id
        ? 'integration_household_link'
        : 'integration_household_create',
      'integration_households',
      {
        integration: selectedIntegration.id,
        household: currentHousehold.uuid,
      }
    );
  };

  //FUNCTION TO DISABLE FILE STORAGE FOR HOUSEHOLD
  const disableFileStorage = () => {
    setIsUnlinkingHousehold(true);
    dispatch(
      unlinkHouseholdFileStorage(currentHousehold.uuid, selectedIntegration.key)
    );
    return SendTrackingEvent(
      invocationId,
      'action',
      'integration_household_unlink',
      'integration_households',
      {
        integration: selectedIntegration.id,
        household: currentHousehold.uuid,
      }
    );
  };

  return (
    <>
      {isLoadingIntegration ? (
        <LoadingAnimation />
      ) : (
        <>
          {householdFolders?.linked ? (
            <>
              <FileStorageInfoContainer>
                <TertiaryHeading>
                  Manage {selectedIntegration.name} Integration for this
                  Household
                </TertiaryHeading>
                <InfoText>
                  {`You've successfully enabled ${selectedIntegration.name} for this household.`}
                </InfoText>
                <InfoText>
                  Visit the{' '}
                  <Link to={`/household/${currentHousehold.id}/summary`}>
                    Household Summary{' '}
                  </Link>
                  page to view/upload files.
                </InfoText>
              </FileStorageInfoContainer>
              <ButtonContainer style={{ justifyContent: 'flex-start' }}>
                <Button
                  text={`Unlink Household`}
                  onClick={() => disableFileStorage()}
                  loadingText={`Unlinking Household`}
                  showLoading={isUnlinkingHousehold}
                />
              </ButtonContainer>
            </>
          ) : (
            <>
              <FileStorageInfoContainer>
                <TertiaryHeading>
                  Enable {selectedIntegration.name} for this Household
                </TertiaryHeading>
                <InfoText>
                  You may create a new folder or choose an existing folder to
                  begin saving files to your {selectedIntegration.name} account
                  for this household.
                </InfoText>
              </FileStorageInfoContainer>
              <SelectContainerAlt>
                <InputLabel>Select Folder</InputLabel>
                <Select
                  selectedOption={selectedFolder}
                  onChange={(e) => setSelectedFolder(e)}
                  options={folderOptions}
                  isSearchable={true}
                  placeholder={'Select Folder'}
                  closeMenuOnSelect={true}
                  autoFocus={false}
                  theme={selectThemes.inputSearch}
                />
              </SelectContainerAlt>
              <ButtonContainer style={{ justifyContent: 'flex-start' }}>
                <Button
                  text={`Enable ${selectedIntegration.name}`}
                  onClick={() => enableFileStorage()}
                  loadingText={`Enabling ${selectedIntegration.name}`}
                  showLoading={isLinkingHousehold}
                />
              </ButtonContainer>
            </>
          )}
        </>
      )}
      {errorMessage && (
        <ErrorContainer>
          <Error
            errorMessage={errorMessage}
            theme={{ ...ErrorThemes.inverted, margin: '15px 0 ' }}
          />
        </ErrorContainer>
      )}
    </>
  );
};

const InputLabel = styled.label`
  margin: 0 0 5px 0;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const SelectContainerAlt = styled.div`
  margin: 10px 0 5px 0;
  display: flex;
  text-align: left;
  flex-direction: column;
  max-width: 500px;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

const FileStorageInfoContainer = styled.div`
  max-width: 500px;
  margin: 15px 0;
`;

const InfoText = styled.p`
  font-size: 13px;
  &:last-child {
    margin: 2px 0;
  }
  a {
    color: ${colors.lightBlue};
    font-weight: ${fonts.semiBold};
  }
`;

const ErrorContainer = styled.div`
  max-width: 400px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  div {
    flex: 0 0 auto;
  }
`;

HouseholdFileStorage.propTypes = {
  selectedIntegration: PropTypes.object,
};

export default HouseholdFileStorage;
