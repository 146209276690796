import styled, { ThemeProvider } from 'styled-components';
import { DatePickerIcon } from '../';
import { darken } from 'polished';
import * as styles from '../../styles/variables';
import { ImageIconBig } from '../../styles/library/imageStyles';
import { InputThemes } from '../../styles/themes';
import PropTypes from 'prop-types';
import { TransformCurrencySymbol } from '../../utils';

const TextInput = ({
  theme = InputThemes.outlineInput,
  bigInput = false,
  placeholder,
  maxLength,
  imageSrc,
  imageName,
  type = 'text',
  name,
  value,
  dateValue,
  disabled = false,
  required = false,
  hasSuffix = false,
  suffixValue = '%',
  currency,
  recurlyData,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  autoFocus,
  justUpdated = false,
  hasError = false,
  errors,
  validated,
  withIcon,
  iconName,
  icons = false,
  ageDate,
  iconSrc,
  onClick,
  id,
  lastpassIgnore = false,
  pattern = null,
  onDateChange,
  maxDate,
  dateFormat,
}) => {
  const inputTheme = {
    ...theme,
    bg: justUpdated ? styles.inputColors.updated : theme.bg,
  };

  const generateCurrencyInput = () => {
    if (currency) {
      return currency.symbol_location === 'prefix' ? (
        <InputPrefix>
          <Input
            type={'number'}
            disabled={disabled}
            name={name}
            id={id}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            errors={errors}
            validated={validated}
            required={required}
            hasError={hasError}
            pattern={pattern}
            data-lpignore={lastpassIgnore}
            autoFocus={autoFocus}
          />
        </InputPrefix>
      ) : (
        <InputSuffix>
          <Input
            type={'number'}
            disabled={disabled}
            name={name}
            id={id}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            errors={errors}
            validated={validated}
            required={required}
            hasError={hasError}
            pattern={pattern}
            data-lpignore={lastpassIgnore}
            autoFocus={autoFocus}
          />
        </InputSuffix>
      );
    }
    return (
      <Input
        type={'number'}
        disabled={disabled}
        name={name}
        id={id}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        errors={errors}
        validated={validated}
        required={required}
        hasError={hasError}
        pattern={pattern}
        data-lpignore={lastpassIgnore}
        autoFocus={autoFocus}
      />
    );
  };

  const generateTheme = () => {
    let themeObj = {
      ...inputTheme,
    };
    if (currency?.symbol_location === 'prefix') {
      themeObj.prefix = TransformCurrencySymbol(currency);
    }
    if (currency?.symbol_location === 'suffix') {
      themeObj.suffix = TransformCurrencySymbol(currency);
    }
    themeObj.currencyColor = styles.colors.darkGrey;
    return themeObj;
  };

  return (
    <ThemeProvider
      theme={
        type === 'currency'
          ? generateTheme()
          : { ...inputTheme, suffix: suffixValue }
      }
    >
      {bigInput ? (
        <BigInputContainer>
          <BigInputIcon type={type} placeholder={placeholder} />
          <IconBigInput>
            <ImageIconBig
              src={imageSrc}
              alt={imageName}
              data-image={imageName}
            />
          </IconBigInput>
        </BigInputContainer>
      ) : withIcon ? (
        <InputContainer length={icons.length ? icons.length + 1 : false}>
          <Input
            type={type}
            disabled={disabled}
            id={id}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            errors={errors}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            validated={validated}
            required={required}
            hasError={hasError}
            pattern={pattern}
            data-lpignore={lastpassIgnore}
            autoFocus={autoFocus}
          />
          {withIcon && icons.length ? (
            icons.map((icon, index) => {
              return (
                <InputIconRight
                  key={index}
                  order={index}
                  src={icon.src}
                  alt={icon.name}
                  data-image={icon.name}
                  onClick={icon.onClick}
                  ref={icon.ref}
                />
              );
            })
          ) : (
            <InputIconRight
              src={iconSrc}
              alt={iconName}
              data-image={iconName}
              onClick={onClick}
            />
          )}
        </InputContainer>
      ) : type === 'currency' ? (
        generateCurrencyInput()
      ) : hasSuffix ? (
        <InputSuffix>
          <Input
            type={type}
            disabled={disabled}
            name={name}
            id={id}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            errors={errors}
            validated={validated}
            required={required}
            hasError={hasError}
            pattern={pattern}
            data-lpignore={lastpassIgnore}
            autoFocus={autoFocus}
          />
        </InputSuffix>
      ) : ageDate ? (
        <AgeDateContainer>
          <Input
            type={type}
            disabled={disabled}
            name={name}
            id={id}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            errors={errors}
            validated={validated}
            required={required}
            hasError={hasError}
            pattern={pattern}
            data-lpignore={lastpassIgnore}
            autoFocus={autoFocus}
          />
          <DatePickerIcon
            onChange={onDateChange}
            value={dateValue}
            maxDate={maxDate}
            dateFormat={dateFormat}
            textValue={value}
          />
        </AgeDateContainer>
      ) : (
        <Input
          type={type}
          disabled={disabled}
          name={name}
          id={id}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          errors={errors}
          validated={validated}
          required={required}
          hasError={hasError}
          pattern={pattern}
          data-lpignore={lastpassIgnore}
          data-recurly={recurlyData ? recurlyData : null}
          autoFocus={autoFocus}
        />
      )}
    </ThemeProvider>
  );
};

//Main Input Styled Component - Pass themes as props to style when using
export const Input = styled.input`
  background: ${(props) => props.theme.bg};
  border: ${(props) => props.theme.border};
  color: ${styles.colors.darkGrey};
  padding: 9.5px 15px;
  padding: ${(props) => props.theme.padding};
  border-radius: 6px;
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: ${(props) =>
    props.theme.fontSize ? props.theme.fontSize : styles.fonts.primary};
  flex: 1 1 auto;
  transition: background-color 100ms linear;
  &::placeholder {
    color: ${styles.colors.paleGrey};
    opacity: 1;
  }
  &:hover {
    background-color: ${(props) =>
      props.theme.bg && darken(0.02, props.theme.bg)};
  }
  &:focus {
    background-color: ${(props) =>
      props.theme.bg && darken(0.02, props.theme.bg)};
    border: 1px solid
      ${(props) =>
        props.theme.focusBorder && darken(0.03, props.theme.focusBorder)};
  }
  ${({ hasError }) =>
    hasError &&
    `
    border: 1px solid ${styles.messageColors.error}
  `}
  ${({ disabled }) =>
    disabled &&
    `
    background: ${styles.colors.lightGrey};
      &:hover {
        background: ${styles.colors.lightGrey};
      }
    `}
  ${({ errors }) =>
    errors &&
    `
    border: 1px solid ${styles.messageColors.error}
    &:focus {
      border: thin solid ${styles.messageColors.error};
    }
  `}
  ${({ validated }) =>
    validated &&
    `
    border: 1px solid ${styles.messageColors.success}
    &:focus {
      border: 1px solid ${styles.messageColors.success}
    }
  `}
`;

//Flex container to make input full width
export const InputContainer = styled.div`
  display: flex;
  /* margin: 15px 0; */
  position: relative;
  width: 100%;
  ${Input} {
    padding: ${(props) =>
      props.length
        ? `9.1px ${17.5 * props.length}px 9.1px 12px`
        : '9.1px 30px 9.1px 12px'};
  }
`;

const InputSuffix = styled.span`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  ${Input} {
    padding: 9.5px 28px 9.5px 15px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &:after {
    color: ${styles.colors.paleGrey};
    color: ${(props) => {
      return props.theme.currencyColor;
    }};
    content: '';
    content: ${(props) => {
      return `'${props.theme.suffix}'`;
    }};
    position: absolute;
    top: 0;
    right: 8px;
    line-height: 20px;
    transform: translateY(50%);
    display: block;
    font-size: 13px;
  }
`;

const InputPrefix = styled.span`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  ${Input} {
    padding: 9.5px 15px 9.5px 20px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &:before {
    color: ${styles.colors.paleGrey};
    color: ${(props) => {
      return props.theme.currencyColor;
    }};
    content: '';
    content: ${(props) => {
      return `'${props.theme.prefix}'`;
    }};
    position: absolute;
    top: 0;
    left: 8px;
    line-height: 20px;
    transform: translateY(50%);
    display: block;
    font-size: 13px;
  }
`;

//Image formatting for input with icon - eg Password
export const InputIconRight = styled.img`
  position: absolute;
  right: ${(props) => (props.order === 0 ? '32px' : '8px')};
  top: 50%;
  width: 17px;
  height: 17px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease;
  z-index: 5;
  transform: translateY(-50%);
  &:hover {
    opacity: 0.8;
    transform: translateY(-50%) scale(1.01);
  }
  /* border: 1px solid transparent;
  display: block;
  width: 20px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 0px;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease;
  z-index: 5;
  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  } */
`;

const AgeDateContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  ${InputIconRight} {
    width: 18px;
    height: 18px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.6;
  }
`;

//Container for Big Input - eg Search Bar on Dashboard
export const BigInputContainer = styled(InputContainer)`
  background-color: ${styles.colors.white};
  height: auto;
  border-radius: 6px;
  transition: all 0.1s ease;
  box-shadow: ${styles.boxShadows.boxShadowDropdown};
  ${Input} {
    padding: 0 20px;
  }
  &:hover {
    transform: scale(1.01);
  }
`;

//Styling for input part of Big Input
export const BigInputIcon = styled(Input)`
  background-color: none;
  border: none;
  padding: 0 20px;
  height: 60px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: ${styles.fonts.primaryText};
  &:focus {
    border: none;
  }
`;

//Styling for icon part of Big Input
export const IconBigInput = styled.div`
  flex: 0 1 auto;
  width: 70px;
  height: 60px;
  background: ${styles.colors.paleGrey};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  box-shadow: ${styles.boxShadows.boxShadowDropdown};
`;

TextInput.propTypes = {
  theme: PropTypes.object,
  bigInput: PropTypes.bool,
  disabled: PropTypes.bool,
  imageSrc: PropTypes.string,
  imageName: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dateValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoFocus: PropTypes.bool,
  justUpdated: PropTypes.bool,
  hasError: PropTypes.bool,
  errors: PropTypes.string,
  recurlyData: PropTypes.string,
  validated: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.string,
  withIcon: PropTypes.bool,
  icons: PropTypes.array,
  iconName: PropTypes.string,
  ageDate: PropTypes.bool,
  required: PropTypes.bool,
  hasSuffix: PropTypes.bool,
  suffixValue: PropTypes.string,
  hasPrefix: PropTypes.bool,
  currency: PropTypes.object,
  prefixValue: PropTypes.string,
  iconSrc: PropTypes.string,
  onClick: PropTypes.func,
  lastpassIgnore: PropTypes.bool,
  pattern: PropTypes.string,
  onDateChange: PropTypes.func,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateFormat: PropTypes.string,
};

export default TextInput;
