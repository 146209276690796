/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Toggle } from '../../components';
import { toggleEnableSignal } from '../../store/actions';
import {
  colors,
  maxDevice,
  fonts,
  // signalColors,
} from '../../styles/variables';
import { signalLabels } from '../../utils';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';

const ManagerSignalsContainer = () => {
  const dispatch = useDispatch();
  const { customerSignalSettings, customer } = useSelector((state) => ({
    customerSignalSettings: state.user.customerSignalSettings,
    customer: state.user.user.customer,
  }));
  const [signalSettings, setSignalSettings] = useState([]);
  const [loadingSettings, setLoadingSettings] = useState(true);

  useEffect(() => {
    if (customerSignalSettings) {
      setLoadingSettings(false);
      setSignalSettings([
        ...signalLabels.map((signal) => {
          signal.active = customerSignalSettings[signal.key]?.enabled;
          return signal;
        }),
      ]);
    }
  }, [customerSignalSettings]);

  const signalOnClick = (signal) => {
    dispatch(toggleEnableSignal(customer?.id, signal));
  };

  // const generateWidth = (signal, color) => {
  //   const green = ((signal.max - signal.green) / signal.max) * 100;
  //   const red = ((signal.red - signal.min) / signal.max) * 100;
  //   const yellow = 100 - (green + red);
  //   switch (color) {
  //     case 'green':
  //       return green;
  //     case 'yellow':
  //       return yellow;
  //     case 'red':
  //       return red;
  //     default:
  //       return 0;
  //   }
  // };

  return (
    <SignalsSettingsContainer>
      <SignalsHeader>
        <SignalLabel>Signal</SignalLabel>
        <SignalDetails>Details</SignalDetails>
        {/* <MarkerLabel>Markers</MarkerLabel> */}
      </SignalsHeader>
      {loadingSettings
        ? [0, 1, 2, 3, 4, 5].map((item) => {
            return (
              <SignalContainer key={item} $active={true}>
                <SignalLabelContainer>
                  <SignalLabel>
                    <LoadingPlaceholder
                      width="120px"
                      height="16px"
                      margin="0"
                    />
                  </SignalLabel>
                  <SignalToggle>
                    <Toggle
                      props={{
                        value: false,
                        text: null,
                        theme: { smaller: true },
                      }}
                      onClick={() => null}
                    />
                  </SignalToggle>
                </SignalLabelContainer>
                <SignalDetails>
                  <LoadingPlaceholder width="400px" />
                </SignalDetails>
              </SignalContainer>
            );
          })
        : signalSettings.map((signal, index) => {
            return (
              <SignalContainer key={index} $active={signal?.active}>
                <SignalLabelContainer>
                  <SignalLabel>{signal.label}</SignalLabel>
                  <SignalToggle>
                    <Toggle
                      props={{
                        value: signal.active,
                        name: signal.key,
                        text: null,
                        theme: { smaller: true },
                      }}
                      onClick={signalOnClick}
                    />
                  </SignalToggle>
                </SignalLabelContainer>
                <SignalDetails>{signal.details}</SignalDetails>
                {/* <SignalsMarker>
              <SignalValues>
                {signal?.active && (
                  <>
                    <MinValue>{signal.min}</MinValue>
                    <RedValue
                      style={{
                        width: `${generateWidth(signal, 'red') + 1}%`,
                      }}
                    >
                      <span>{signal.red}</span>
                    </RedValue>
                    <YellowPlaceholder
                      style={{
                        width: `${generateWidth(signal, 'yellow') - 2}%`,
                      }}
                    />
                    <GreenValue
                      style={{
                        width: `${generateWidth(signal, 'green') + 1}%`,
                      }}
                    >
                      <span>{signal.green}</span>
                    </GreenValue>
                    <MaxValue>{signal.max}</MaxValue>
                  </>
                )}
              </SignalValues>
              <SignalBar $active={signal?.active}>
                <RedBar
                  style={{
                    width: `${generateWidth(signal, 'red')}%`,
                  }}
                />
                <YellowBar
                  style={{
                    width: `${generateWidth(signal, 'yellow')}%`,
                  }}
                />
                <GreenBar
                  style={{
                    width: `${generateWidth(signal, 'green')}%`,
                  }}
                />
              </SignalBar>
            </SignalsMarker> */}
              </SignalContainer>
            );
          })}
    </SignalsSettingsContainer>
  );
};

// const RedBar = styled.div`
//   background: ${signalColors.red};
//   height: 100%;
// `;

// const YellowBar = styled.div`
//   background: ${signalColors.yellow};
//   height: 100%;
// `;

// const GreenBar = styled.div`
//   background: ${signalColors.green};
//   height: 100%;
// `;

// const SignalBar = styled.div`
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: flex-start;
//   height: 16px;
//   background: ${colors.lightGrey};
//     opacity: ${(props) => (props.$active ? '1' : '.6')};
//   border-radius: 25px;
//   overflow: hidden;
//   ${RedBar}, ${YellowBar}, ${GreenBar} {
//     background: ${(props) => (props.$active ? null : colors.paleGrey)};
//   }
// `;

// const SignalValues = styled.div`
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: flex-start;
//   position: relative;
//   font-size: 10px;
//   height: 15px;
//   color: ${colors.paleGrey};
// `;

// const MinValue = styled.p`
//   position: absolute;
//   left: 3px;
//   bottom: 1px;
// `;

// const MaxValue = styled.p`
//   position: absolute;
//   right: 2px;
//   bottom: 1px;
// `;

// const RedValue = styled.div`
//   text-align: right;
//   font-size: 11px;
//   font-weight: ${fonts.semiBold};
//   color: ${signalColors.red};
//   span {
//     min-width: 11px;
//     display: inline-block;
//     text-align: center;
//   }
// `;

// const YellowPlaceholder = styled.div``;

// const GreenValue = styled.div`
//   text-align: left;
//   font-size: 11px;
//   font-weight: ${fonts.semiBold};
//   color: ${signalColors.green};
//   span {
//     min-width: 11px;
//     display: inline-block;
//     text-align: center;
//   }
// `;

const SignalsSettingsContainer = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 0px 20px 100px 20px;
  }
  padding: 0px 40px 100px 40px;
`;

const SignalContainer = styled.div`
  padding: 20px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${colors.lightGrey};
  opacity: ${(props) => (props.$active ? '1' : '.5')};
`;

const SignalLabelContainer = styled.div`
  @media ${maxDevice.laptop} {
    font-size: 13px;
  }
  font-weight: ${fonts.semiBold};
  font-size: 14px;
  flex: 1 1 15%;
  /* flex: 1 1 15%; */
  margin-right: 10px;
`;

const SignalDetails = styled.div`
  @media ${maxDevice.laptop} {
    /* font-size: 11px; */
    font-size: 12px;
  }
  /* font-size: 12px; */
  font-size: 14px;
  flex: 1 1 85%;
  /* flex: 1 1 35%; */
`;

// const SignalsMarker = styled.div`
//   flex: 1 1 50%;
//   margin-left: 30px;
// `;

const SignalLabel = styled.div`
  flex: 1 1 15%;
  min-width: 150px;

  /* flex: 1 1 50%; */
`;

// const MarkerLabel = styled.div`
//   flex: 1 1 50%;
//   margin-left: 40px;
// `;

const SignalsHeader = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${colors.darkGrey};
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  ${SignalLabel} {
    font-size: 12px;
    margin-right: 6px;
  }
  ${SignalDetails} {
    font-size: 12px;
  }
  div {
    padding: 0 5px 4px 0;
  }
`;

const SignalToggle = styled.div`
  margin-left: -5px;
  margin-top: 3px;
`;

export default ManagerSignalsContainer;
