import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { Button, TextInput, DetectModalKeys } from '../../components';
import { RecoverySentModal } from '../../containers';
import { showLoginScreen, sendResetPassword } from '../../store/actions';
import { CenterFlexContainer } from '../../styles/library/layoutStyles';
import { AuthText, LinkText } from '../../styles/library/fontStyles';
import {
  InputLabel,
  TextInputContainer,
} from '../../styles/library/inputStyles';
import { ButtonThemes } from '../../styles/themes';
import { DetectEnterKeyPress } from '../../utils';

const RecoveryModal = () => {
  const dispatch = useDispatch();
  const { sentReset, showError } = useSelector((state) => ({
    sentReset: state.user.sentReset,
    showError: state.user.responseError.showError,
  }));
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const buttonText = 'Recover My Password';

  useEffect(() => {
    const usernameCookie = Cookies.get('username');
    if (usernameCookie !== undefined) {
      setEmail(usernameCookie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = () => {
    let hasError;
    if (email === '') {
      setEmailError(true);
      hasError = true;
    }
    if (!hasError) {
      dispatch(sendResetPassword(email));
    }
  };

  useEffect(() => {
    if (showError) {
      setEmailError(true);
    }
  }, [showError]);

  const updateEmailInfo = (e) => {
    setEmail(e.currentTarget.value);
    setEmailError(false);
  };

  return (
    <>
      {sentReset ? (
        <RecoverySentModal />
      ) : (
        <>
          <DetectModalKeys onEnter={onSubmitForm} />
          <AuthText style={{ paddingBottom: '0' }}>
            Enter your username or email address and we&apos;ll send you
            instructions on how to recover your password.
          </AuthText>
          <TextInputContainer autoWidth={true} margin={'15px 0'}>
            <InputLabel>Email</InputLabel>
            <TextInput
              type="email"
              name="email"
              value={email}
              placeholder="Email Address"
              onChange={updateEmailInfo}
              hasError={emailError}
              onKeyPress={(e) => DetectEnterKeyPress(e, onSubmitForm)}
            />
          </TextInputContainer>
          <Button
            onClick={onSubmitForm}
            text={buttonText}
            theme={ButtonThemes.primaryFull}
          />
          <CenterFlexContainer>
            <LinkText
              style={{ flex: '1 1 auto', textAlign: 'left' }}
              onClick={() => {
                dispatch(showLoginScreen());
              }}
            >
              Cancel
            </LinkText>
          </CenterFlexContainer>
        </>
      )}
    </>
  );
};

RecoveryModal.propTypes = {
  isConsumer: PropTypes.bool,
};

export default RecoveryModal;
