/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import {
  Button,
  Error,
  GenerateInput,
  InfoBox,
  QRCodeGenerator,
  PermissionListItem,
} from '..';
import {
  timesWhite,
  copy as copyIcon,
  check,
  QRCodeIcon,
  cancel,
  // stop,
  launchLink,
  lock,
} from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ButtonsRowContainer,
  ButtonContainer,
  ModalIcon,
} from '../../styles/library/modalStyles';
import { ButtonThemes, ErrorThemes } from '../../styles/themes';
import {
  createHouseholdSharingInvite,
  getHouseholdRoles,
  getRolePermissions,
  toggleShowCancelSharingModal,
  toggleShowConnectPreviewModal,
  updateHouseholdSharingInvite,
  updateHouseholdStoreValue,
} from '../../store/actions';
import { TextInputRow } from '../../styles/library/inputStyles';
import { boxShadows, colors, fonts } from '../../styles/variables';
import {
  CapitalizeFirstLetter,
  GenerateStatusDisplay,
  MatchSelectItem,
  SuccessNotification,
  UseOutsideClick,
  UserRoleOption,
  DateFormatUTC,
} from '../../utils';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';

const HouseholdSharingModal = ({ showModal = true, hide, householdId }) => {
  const ref = useRef();
  const QRRef = useRef();
  const dispatch = useDispatch();
  const {
    currentHousehold,
    shareHouseholdDetails,
    shareInviteDetails,
    createInviteError,
    updateInviteError,
    hasConnectPreview,
    roles,
    rolePermissions,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    shareHouseholdDetails: state.households.shareHouseholdDetails,
    shareInviteDetails: state.households.shareInviteDetails,
    createInviteError: state.households.createInviteError,
    updateInviteError: state.households.updateInviteError,
    hasConnectPreview: state.user.hasConnectPreview,
    roles: state.households.roles,
    rolePermissions: state.households.rolePermissions,
  }));
  const [inviteDetails, setInviteDetails] = useState({ link: '' });
  const [loadingInvite, setLoadingInvite] = useState(true);
  const [inviteStatus, setInviteStatus] = useState('inactive');
  const [isNewInvite, setIsNewInvite] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [canPreview, setCanPreview] = useState(false);
  const [showShareableLink, setShowShareableLink] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [allowEditEmail, setAllowEditEmail] = useState(true);
  const [linkCopied, setLinkCopied] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [creatingInvite, setCreatingInvite] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [emailError, setEmailError] = useState('Email address cannot be empty');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Unknown Error');
  const [showQRCode, setShowQRCode] = useState(false);
  const [updatingInvite, setUpdatingInvite] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [loadedRoles, setLoadedRoles] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [showRole, setShowRole] = useState(false);
  const [hasRoleError, setHasRoleError] = useState(false);
  const [roleError, setRoleError] = useState('Permission Set is required');
  const [showPermissions, setShowPermissions] = useState(false);
  // const [permissionsList, setPermissionsList] = useState([
  //   {
  //     name: 'Asset-Map',
  //     view: 'assetmap',
  //     icon: 'assetmap',
  //     value: [],
  //     locked: true,
  //   },
  //   {
  //     name: 'Members',
  //     view: 'household_member_view',
  //     create: 'household_member_create',
  //     delete: 'household_member_delete',
  //     edit: 'household_member_edit',
  //     print: 'household_members_print',
  //     icon: 'members',
  //     value: [],
  //   },
  //   {
  //     name: 'Financials',
  //     view: 'household_financials_view',
  //     create: 'household_financials_create',
  //     delete: 'household_financials_delete',
  //     edit: 'household_financials_edit',
  //     print: 'household_financials_print',
  //     icon: 'financials',
  //     value: [],
  //   },
  //   {
  //     name: 'Signals',
  //     view: 'household_signals_view',
  //     icon: 'signals',
  //     value: [],
  //   },
  //   {
  //     name: 'Balance Sheet',
  //     view: 'household_balancesheet_view',
  //     print: 'household_balancesheet_print',
  //     icon: 'balancesheet',
  //     value: [],
  //   },
  //   {
  //     name: 'Policy Summary',
  //     view: 'household_policysummary_view',
  //     print: 'household_policysummary_print',
  //     icon: 'policysummary',
  //     value: [],
  //   },
  //   {
  //     name: 'Shared Reports',
  //     view: 'household_sharedreports_view',
  //     print: 'household_sharedreports_print',
  //     icon: 'comprep',
  //     value: [],
  //   },
  // ]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [loadedPermissions, setLoadedPermissions] = useState(false);

  //This list will have to change to hide/show what is allowed from team manager
  // const permissionOptions = [
  //   { label: 'Not Available', value: 'hidden' },
  //   { label: 'View Only', value: 'view' },
  // ];

  UseOutsideClick(QRRef, () => {
    if (showQRCode) {
      setShowQRCode(false);
    }
  });

  //RESET ALL VALUES WHEN MODAL CLOSES
  useEffect(() => {
    dispatch(getHouseholdRoles(householdId, false));
    return () => {
      resetErrorDisplay();
      dispatch(updateHouseholdStoreValue('roles', null));
      dispatch(updateHouseholdStoreValue('rolePermissions', null));
      setLoadingInvite(true);
      setUserEmail('');
      setAllowEditEmail(true);
      setIsNewInvite(true);
      setIsEdit(false);
      setCanPreview(false);
      setIsExpired(false);
      setLinkCopied(false);
      setCreatingInvite(false);
      setUpdatingInvite(false);
      setShowShareableLink(false);
      setInviteStatus('inactive');
      setInviteDetails({ link: '' });
    };
  }, []);

  useEffect(() => {
    if (shareHouseholdDetails) {
      setIsNewInvite(shareHouseholdDetails.status === 'inactive');
      setUserEmail(shareHouseholdDetails?.email);
      setInviteDetails({ ...shareHouseholdDetails, link: '' });
      setIsEdit(
        shareHouseholdDetails.status === 'active' ||
          shareHouseholdDetails.status === 'pending'
      );
      setCanPreview(
        hasConnectPreview &&
          (shareHouseholdDetails.status === 'active' ||
            shareHouseholdDetails.status === 'pending' ||
            shareHouseholdDetails.status === 'inactive')
      );
      setUpdatingInvite(false);
    }
  }, [shareHouseholdDetails]);

  useEffect(() => {
    if (shareInviteDetails) {
      const transformedDetails = {
        ...shareInviteDetails.householdmember,
        ...shareInviteDetails?.consumerinvite,
        status: shareInviteDetails?.consumerinvite?.status || 'inactive',
        last_login: shareInviteDetails?.consumerinvite?.last_login || null,
      };
      setIsNewInvite(transformedDetails.status === 'inactive');
      setUserEmail(transformedDetails?.email);
      setInviteDetails(transformedDetails);
      setCreatingInvite(false);
      setIsExpired(shareInviteDetails?.consumerinvite?.is_expired);
      setIsEdit(
        transformedDetails.status === 'active' ||
          transformedDetails.status === 'pending'
      );
      setCanPreview(
        hasConnectPreview &&
          (transformedDetails.status === 'active' ||
            transformedDetails.status === 'pending' ||
            transformedDetails.status === 'inactive')
      );
      resetErrorDisplay();
      setLoadingInvite(false);
      dispatch(updateHouseholdStoreValue('shareInviteDetails', null));
      setInviteStatus(transformedDetails.status);
      setShowShareableLink(
        transformedDetails.status !== 'inactive' &&
          transformedDetails.status !== 'active' &&
          transformedDetails.status !== 'rejected'
      );
      setShowRole(
        transformedDetails.status !== 'expired' &&
          transformedDetails.status !== 'rejected'
      );
      setUpdatingInvite(false);
    }
  }, [shareInviteDetails]);

  useEffect(() => {
    if (loadedRoles && !loadingInvite && isEdit) {
      const matched = MatchSelectItem(roles, inviteDetails?.role?.role);
      if (matched) {
        setSelectedRole(matched);
      }
    }
  }, [loadedRoles, loadingInvite, isEdit]);

  useEffect(() => {
    if (selectedRole?.value && loadedRoles) {
      setShowPermissions(true);
      setLoadedPermissions(false);
      dispatch(getRolePermissions(householdId, selectedRole?.value));
    }
  }, [selectedRole?.value]);

  useEffect(() => {
    if (roles) {
      const transformedRoles = roles.map((role) => {
        role.value = role.role;
        role.label = role.name;
        return role;
      });
      setRoleOptions(transformedRoles);
      setLoadedRoles(true);
    }
  }, [roles]);

  useEffect(() => {
    if (rolePermissions) {
      setTimeout(() => {
        setPermissionsList(rolePermissions?.scopes);
        setLoadedPermissions(true);
      }, 200);
      // const updatedPermissions = permissionsList.map((permission) => {
      //   permission.value = [];
      //   if (rolePermissions[permission.view] === true) {
      //     permission.value.push('view');
      //   }
      //   if (rolePermissions[permission.print] === true) {
      //     permission.value.push('print');
      //   }
      //   if (rolePermissions[permission.create] === true) {
      //     permission.value.push('create');
      //   }
      //   if (rolePermissions[permission.edit] === true) {
      //     permission.value.push('edit');
      //   }
      //   if (rolePermissions[permission.create] === true) {
      //     permission.value.push('delete');
      //   }
      //   if (permission.view === 'assetmap') {
      //     permission.value = ['view'];
      //   }
      //   return permission;
      // });
      // setTimeout(() => {
      //   setPermissionsList(updatedPermissions);
      //   setLoadedPermissions(true);
      // }, 200);
    }
  }, [rolePermissions]);

  //ERROR HANDLING FOR CREATING A SHARING INVITE
  useEffect(() => {
    if (createInviteError) {
      setCreatingInvite(false);
      setShowError(true);
      const errorData = createInviteError?.data;
      let errorMessage = 'Error creating invite';
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          if (key === 'email') {
            setHasEmailError(true);
            let message = value;
            if (Array.isArray(value)) {
              message = value[0];
            }
            setEmailError(CapitalizeFirstLetter(message));
          }
          if (key === 'role') {
            setHasRoleError(true);
            let message = value;
            if (Array.isArray(value)) {
              message = value[0];
            }
            setRoleError(CapitalizeFirstLetter(message));
          }
          errors.push({ field: key, message: value });
        }
        errorMessage = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      setErrorMessage(errorMessage);
    }
  }, [createInviteError]);

  //ERROR HANDLING FOR CREATING A SHARING INVITE
  useEffect(() => {
    if (updateInviteError) {
      setUpdatingInvite(false);
      setShowError(true);
      const errorData = updateInviteError?.data;
      let errorMessage = 'Error updating invite';
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          if (key === 'role') {
            setHasRoleError(true);
            let message = value;
            if (Array.isArray(value)) {
              message = value[0];
            }
            setRoleError(CapitalizeFirstLetter(message));
          }
          errors.push({ field: key, message: value });
        }
        errorMessage = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      setErrorMessage(errorMessage);
    }
  }, [updateInviteError]);

  const resetErrorDisplay = () => {
    setShowError(false);
    setHasEmailError(false);
    setHasRoleError(false);
    setErrorMessage('');
    dispatch(updateHouseholdStoreValue('createInviteError', null));
    dispatch(updateHouseholdStoreValue('updateInviteError', null));
  };

  const copyValue = (link) => {
    if (!linkCopied) {
      copy(link);
      setLinkCopied(true);
      SuccessNotification(`Link Copied`, 2.5, 'bottom-left', {
        size: ' 5px',
        style: 'solid',
      });
      setTimeout(() => {
        setLinkCopied(false);
      }, 2500);
    }
  };

  const createInvite = () => {
    let email = userEmail.trim();
    if (!allowEditEmail && !email) {
      setHasEmailError(true);
      setEmailError('Email address cannot be empty');
    }
    if (!selectedRole?.value) {
      setHasRoleError(true);
      setShowError(true);
      setErrorMessage('Must select a Permission Set.');
      return;
    }
    setCreatingInvite(true);
    dispatch(
      createHouseholdSharingInvite(currentHousehold?.uuid, {
        invite_type: 'connect',
        role: selectedRole?.value,
        email: email !== '' ? email : null,
        is_email_editable: allowEditEmail,
        household: currentHousehold?.uuid,
        member: null,
        householdmember: inviteDetails?.uuid,
      })
    );
  };

  const updatePermissions = () => {
    dispatch(
      updateHouseholdSharingInvite(
        currentHousehold?.uuid,
        inviteDetails?.uuid,
        {
          invite_type: 'connect',
          role: selectedRole?.value,
        }
      )
    );
    setUpdatingInvite(true);
  };

  const deleteFunction = () => {
    dispatch(toggleShowCancelSharingModal(true, inviteDetails));
  };

  // const permissionOnChange = (permission, e) => {
  //   const updatedPerms = [...permissionsList].map((perm) => {
  //     if (perm.id === permission) {
  //       perm.value = e;
  //     }
  //     return perm;
  //   });
  //   setPermissionsList(updatedPerms);
  // };

  const previewDisabled = () => {
    return inviteDetails?.status === 'inactive' && !selectedRole?.value;
  };

  const generateShareModalContent = () => {
    return loadingInvite ? (
      <>
        <MemberDetails>
          <MemberHeading>
            <LoadingPlaceholder margin="0 auto" width="160px" height="22px" />
          </MemberHeading>
          {showModal || (
            <MemberName>
              <LoadingPlaceholder
                margin="4px auto"
                width="120px"
                height="18px"
              />
            </MemberName>
          )}
          <MemberEmail>
            <LoadingPlaceholder
              margin="4px auto"
              width="180px"
              height={showModal ? '16px' : '14px'}
            />
          </MemberEmail>
        </MemberDetails>
        <LoadingPlaceholder width="50px" height="15px" />
        <LoadingPlaceholder width="100%" height="45px" />
        <LoadingPlaceholder width="100%" height="50px" />
        <ButtonsRowContainer>
          <ButtonContainer>
            <LoadingPlaceholder width="80px" height="35px" />
            <LoadingPlaceholder
              width="120px"
              height="34px"
              margin="0 0 0 15px"
            />
          </ButtonContainer>
        </ButtonsRowContainer>
      </>
    ) : (
      <div>
        <MemberDetails>
          <StatusContainer>
            {GenerateStatusDisplay(inviteStatus)}
          </StatusContainer>
          {showModal ? (
            <MemberHeading>{inviteDetails?.name}</MemberHeading>
          ) : (
            <MemberHeading>Client Portal</MemberHeading>
          )}
          {showModal || <MemberName>{inviteDetails?.name}</MemberName>}
          {isNewInvite ? null : (
            <MemberEmail hasEmail={inviteDetails?.email} showModal={showModal}>
              {inviteDetails?.email ? inviteDetails?.email : 'No Email'}
              {inviteDetails?.is_email_editable === false && (
                <img src={lock} alt="locked" />
              )}
            </MemberEmail>
          )}
        </MemberDetails>
        {isNewInvite && (
          <div>
            <InfoBox
              message={`If you want the user to sign up with a specific email address click the checkbox below. If not click Create Invite to create a shareable link.`}
            />
            <TextInputRow>
              {GenerateInput({
                type: 'checkbox',

                label: 'Require user to register with specific email address.',
                name: 'allowEditEmail',
                margin: '5px 0 15px 0',
                required: false,
                value: !allowEditEmail,
                isVisible: true,
                onChange: () => {
                  resetErrorDisplay();
                  setAllowEditEmail(!allowEditEmail);
                },
              })}
            </TextInputRow>
            {!allowEditEmail && (
              <TextInputRow>
                {GenerateInput({
                  isVisible: true,
                  width: '100%',
                  required: true,
                  label: 'Email (required)',
                  tooltip:
                    'This is the email the user must use to create an account, they cannot be linked to this household without this email address.',
                  type: 'text',
                  value: userEmail,
                  placeholder: `Enter user's email address`,
                  hasError: hasEmailError,
                  errorMessage: emailError,
                  onChange: (e) => {
                    resetErrorDisplay();
                    setUserEmail(e.currentTarget.value);
                  },
                })}
              </TextInputRow>
            )}
          </div>
        )}
        {showShareableLink && (
          <>
            <div
              style={{
                position: 'relative',
              }}
            >
              <TextInputRow>
                {GenerateInput({
                  isVisible: true,
                  width: '100%',
                  label: 'Shareable Link',
                  type: 'text',
                  name: 'link',
                  margin: '0 0 5px 0',
                  placeholder: 'Link',
                  value: inviteDetails?.link,
                  withIcon: true,
                  icons: [
                    {
                      name: linkCopied ? 'check' : 'copy',
                      src: linkCopied ? check : copyIcon,
                      onClick: isExpired
                        ? null
                        : () => copyValue(inviteDetails?.link),
                    },
                    {
                      name: 'qr-code',
                      src: QRCodeIcon,
                      onClick: isExpired
                        ? null
                        : () => setShowQRCode(!showQRCode),
                      ref: QRRef,
                    },
                  ],
                  onChange: () => null,
                  isExpired: isExpired,
                  expiryDisplay: isExpired
                    ? null
                    : `Valid Until ${DateFormatUTC(
                        inviteDetails?.expires_utc,
                        `MMM D, YYYY h:mma`,
                        true
                      )}`,
                  disabled: isExpired,
                  onFocus: isExpired ? null : (e) => e.target.select(),
                })}
                <QRCodeContainer show={showQRCode}>
                  <QRCodeGenerator
                    link={inviteDetails?.link}
                    showOnlyQRCode={true}
                    size={225}
                  />
                </QRCodeContainer>
              </TextInputRow>
            </div>
            {isExpired ? (
              <Error
                errorMessage="This link has expired. Please delete this invite and create a new one."
                theme={{
                  ...ErrorThemes.inverted,
                  margin: '15px 0',
                }}
              />
            ) : (
              <InfoBox
                message={`Copy the link and send to user to share this
                    household or have the user scan the QR code to get started.`}
              />
            )}
          </>
        )}
        {inviteStatus === 'rejected' && (
          <Error
            errorMessage={`<strong>${inviteDetails?.name}</strong> rejected your invite to share <strong>${currentHousehold?.name}</strong>. You can delete this invite and create a new one.`}
            theme={{
              ...ErrorThemes.inverted,
              margin: '15px 0',
            }}
          />
        )}
        {showRole && (
          <>
            <RoleContainer>
              {GenerateInput({
                type: 'select',
                width: '100%',
                isVisible: true,
                required: true,
                label: 'Permission Set',
                name: 'role',
                placeholder: 'Select Permission Set',
                tooltip:
                  'Select the permission set you would like this user to have. Permission sets determine what a user can see and do in their account.',
                value: selectedRole,
                customComponent: UserRoleOption,
                options: roleOptions,
                isLoading: !loadedRoles,
                hasError: hasRoleError,
                errorMessage: roleError,
                onChange: (e) => {
                  resetErrorDisplay();
                  setSelectedRole(e);
                },
              })}
            </RoleContainer>
            {showPermissions && (
              <PermissionsContainer>
                <PermissionsHeading>Permissions</PermissionsHeading>
                {loadedPermissions
                  ? permissionsList.map((permission, index) => {
                      return (
                        !permission.hidden && (
                          <PermissionListItem
                            key={index}
                            permission={permission}
                            // onChange={permissionOnChange}
                            viewOnly={true}
                          />
                        )
                      );
                    })
                  : [0, 1, 2].map((index) => {
                      return (
                        <PermissionListItem key={index} isLoading={true} />
                      );
                    })}
              </PermissionsContainer>
            )}
          </>
        )}
        {inviteStatus === 'active' && (
          <InfoBox
            message={`Changing permission sets will affect what this member can see in the Client Portal.`}
          />
        )}
        <ButtonsRowContainer>
          {!isNewInvite && isEdit && (
            <ModalIcon
              src={cancel}
              // src={stop}
              alt={inviteStatus === 'active' ? 'stop' : 'delete'}
              title={
                inviteStatus === 'active' ? 'Stop Sharing' : 'Delete Invite'
              }
              data-image={'delete-invite'}
              onClick={() => deleteFunction()}
            />
          )}
          {canPreview && (
            <ModalIcon
              src={launchLink}
              alt={'preview'}
              title={
                previewDisabled()
                  ? 'Must Select Permission before preview'
                  : 'Enter Client Preview'
              }
              data-image={'preview-client'}
              onClick={() =>
                previewDisabled()
                  ? null
                  : dispatch(
                      toggleShowConnectPreviewModal(true, {
                        ...inviteDetails,
                        role: selectedRole?.value,
                      })
                    )
              }
              $disabled={previewDisabled()}
            />
          )}
          <ButtonContainer>
            <Button
              text={'Cancel'}
              onClick={() => hide()}
              theme={ButtonThemes.cancel}
            />
            {isEdit ? (
              <Button
                text={'Update'}
                showLoading={updatingInvite}
                loadingText={'Updating'}
                onClick={() => updatePermissions()}
              />
            ) : (
              <Button
                text={isNewInvite ? 'Create Invite' : 'Delete Invite'}
                showLoading={creatingInvite}
                loadingText={'Creating'}
                theme={isNewInvite ? ButtonThemes.primary : ButtonThemes.error}
                onClick={() =>
                  isNewInvite
                    ? createInvite()
                    : dispatch(
                        toggleShowCancelSharingModal(true, inviteDetails)
                      )
                }
              />
            )}
          </ButtonContainer>
        </ButtonsRowContainer>
      </div>
    );
  };

  return showModal
    ? ReactDOM.createPortal(
        <>
          <ModalBackground />
          <ModalOverlay>
            <ModalContainer role="dialog" ref={ref} width="445px">
              <ModalHeadingContainer>
                <ModalHeading>{`${isEdit ? 'Edit ' : ''} Client Portal Permissions`}</ModalHeading>
                <CloseButton
                  src={timesWhite}
                  onClick={() => hide()}
                  data-image="close"
                />
              </ModalHeadingContainer>
              <ModalContentContainer>
                {generateShareModalContent()}
              </ModalContentContainer>
              {showError && <Error errorMessage={errorMessage} />}
            </ModalContainer>
          </ModalOverlay>
        </>,
        document.body
      )
    : generateShareModalContent();
};

const RoleContainer = styled.div``;

const StatusContainer = styled.div`
  position: absolute;
  right: 0;
`;

const MemberDetails = styled.div`
  margin-bottom: 15px;
  position: relative;
`;

const MemberHeading = styled.h1`
  font-size: 18px;
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
`;

const MemberName = styled.p`
  font-size: 16px;
  font-weight: ${fonts.regular};
  color: ${colors.darkGrey};
`;

const MemberEmail = styled.p`
  font-size: ${(props) => (props.showModal ? '14px' : '12px')};
  font-weight: ${fonts.regular};
  color: ${(props) => (props.hasEmail ? colors.lightBlue : colors.paleGrey)};
  img {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
`;

const QRCodeContainer = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? 'block' : 'none')};
  right: 0;
  top: 100%;
  background: white;
  padding: 3px 3px 0 3px;
  box-shadow: ${boxShadows.boxShadowModal};
  background: ${colors.lightGrey};
  border-radius: 6px;
  z-index: 1;
`;

const PermissionsContainer = styled.div`
  margin: 15px 0 15px 0;
  text-align: left;
`;

const PermissionsHeading = styled.p`
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.darkGrey};
  padding-bottom: 3px;
`;

HouseholdSharingModal.propTypes = {
  showModal: PropTypes.bool,
  hide: PropTypes.func,
  householdId: PropTypes.string,
};

export default HouseholdSharingModal;
