import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, fonts } from '../styles/variables';

const SubTabMenu = ({ tabs, activeTab, onClick, rightTabContent = '' }) => {
  const activeTabStyle = {
    background: 'white',
    color: colors.darkGrey,
    zIndex: '10',
    border: `1px solid ${colors.lightGrey}`,
    borderBottom: `1px solid transparent`,
  };

  return (
    <TabsContainer>
      <TabsMenu>
        {tabs.map((tab, index) => {
          return (
            <TabOption
              key={index}
              onClick={() => onClick(tab.value)}
              style={activeTab === tab.value ? activeTabStyle : null}
              title={tab.hovertext}
            >
              {tab.text}
            </TabOption>
          );
        })}
      </TabsMenu>
      <RightTabContent>{rightTabContent}</RightTabContent>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
`;

const TabsMenu = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  z-index: 0;
`;

const TabOption = styled.div`
  padding: 10px 25px;
  cursor: pointer;
  font-size: 13px;
  color: ${colors.paleGrey};
  border-bottom: 1px solid ${colors.lightGrey};
  border-radius: 3px 3px 0 0;
  font-weight: ${fonts.semiBold};
`;

const RightTabContent = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
`;

SubTabMenu.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  onClick: PropTypes.func,
  rightTabContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SubTabMenu;
