/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {
  Button,
  DetectModalKeys,
  Error,
  GenerateInput,
  LoadingAnimation,
} from '../../components';
import {
  registerConsumerAccount,
  getRegistrationDetails,
  updateUserStoreValue,
  resendVerifyEmail,
} from '../../store/actions';
import { check, checkCircle, eye, eyeClosed } from '../../assets';
import {
  CenterModal,
  ModalContentContainer,
  ModalHeader,
  ModalHeadingConfig,
  ModalOverlay,
  TabContent,
} from '../../styles/library/modalStyles';
import { CapsLockWarning, LinkText } from '../../styles/library/fontStyles';
import { LightBackgroundIcon } from '../../styles/library/imageStyles';
import { ButtonThemes } from '../../styles/themes';
import { colors, fonts, messageColors } from '../../styles/variables';
import { CONSUMER_BASENAME as consumerBasename } from '../../utils/constants';
import {
  CapsLockActive,
  CheckIsObject,
  DetectEnterKeyPress,
} from '../../utils';

const CreateAccountModal = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const {
    registrationDetails,
    registeredConsumerAccount,
    registerConsumerAccountError,
    verifyEmailResent,
    verifyEmailResentError,
  } = useSelector((state) => ({
    registrationDetails: state.user.registrationDetails,
    registeredConsumerAccount: state.user.registeredConsumerAccount,
    registerConsumerAccountError: state.user.registerConsumerAccountError,
    verifyEmailResent: state.user.verifyEmailResent,
    verifyEmailResentError: state.user.verifyEmailResentError,
  }));
  const [screen, setScreen] = useState('create');
  const [accountContent, setAccountContent] = useState({
    email: '',
    email_error: '',
    first_name: '',
    first_name_error: '',
    last_name: '',
    last_name_error: '',
    password: '',
    show_password: '',
    password_error: '',
    password_repeated: '',
    show_password_repeated: '',
    password_repeated_error: '',
    accepted_terms: false,
    is_transferable: false,
  });
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //GET ACCOUNT REGISTRATION DETAILS (IF THEY EXIST) WHEN USER WANTS TO CREATE ACCOUNT
  useEffect(() => {
    if (params?.id) {
      dispatch(getRegistrationDetails(params?.id));
    }
  }, [params?.id]);

  //IF THERE ARE REGISTRATION DETAILS UPDATE CREATE FORM WITH THOSE DETAILS
  useEffect(() => {
    if (registrationDetails) {
      if (registrationDetails) {
        setAccountContent({
          ...accountContent,
          email: registrationDetails?.email || '',
          first_name: registrationDetails?.first_name || '',
          last_name: registrationDetails?.last_name || '',
          accepted_terms: registrationDetails?.accepted_terms,
          is_transferable: registrationDetails?.is_transferable,
        });
      }
    }
  }, [registrationDetails]);

  //AFTER SUCCESSFUL REGISTRATION CHANGE SCREEN TO VERIFY CODE EMAIL SENT
  useEffect(() => {
    if (registeredConsumerAccount) {
      setScreen('sent');
      setIsCreatingAccount(false);
      setErrorMessage('');
      dispatch(updateUserStoreValue('registeredConsumerAccount', false));
    }
  }, [registeredConsumerAccount]);

  //ERROR HANDLING FOR TRYING TO REGISTER ACCOUNT
  useEffect(() => {
    if (registerConsumerAccountError) {
      setIsCreatingAccount(false);
      const errorData = registerConsumerAccountError?.data?.message;
      if (registerConsumerAccountError?.data?.status === 'already_exists') {
        setAccountContent({
          ...accountContent,
          email_error: errorData,
        });
        return setErrorMessage(
          `${errorData}. <strong><a href="${window.location.origin}/consumer/auth/i/${params.id}">Sign in<a><strong>`
        );
      }
      let errorMessage = 'Unknown Error';
      if (errorData) {
        if (!CheckIsObject(errorData)) {
          return setErrorMessage(errorData);
        } else {
          errorMessage = 'Please fix errors to continue.';
          let errors = [];
          const updatedObj = { ...accountContent };
          for (const [key, value] of Object.entries(errorData)) {
            if (accountContent.hasOwnProperty(key)) {
              if (CheckIsObject(value)) {
                const errorValues = Object.values(value);
                const errorsArray = [];
                let newErr = 'Passwords must have: ';
                const objLength = errorValues.length - 1;
                errorValues.forEach((e, index) => {
                  newErr += `${e}${index !== objLength ? ', ' : ''}`;
                });
                errorsArray.push(newErr);
                updatedObj[`${key}_error`] = errorsArray;
              } else {
                updatedObj[`${key}_error`] = value;
              }
            } else {
              errors.push({ field: key, message: value });
            }
          }
          setAccountContent(updatedObj);
          if (errors.length !== 0) {
            const errorMessages = errors.map((error) => {
              const fieldName = error.field.replaceAll('_', ' ');
              return `${fieldName}: ${error.message}`;
            });
            setErrorMessage(errorMessages);
          } else {
            setErrorMessage(errorMessage);
          }
        }
      } else {
        setErrorMessage(errorMessage);
      }
    }
  }, [registerConsumerAccountError]);

  //UPDATE UI WHEN VERIFY EMAIL IS RESENT
  useEffect(() => {
    if (verifyEmailResent) {
      setIsSendingEmail(false);
      setEmailSent(true);
      setTimeout(() => {
        setEmailSent(false);
      }, 3000);
      dispatch(updateUserStoreValue('verifyEmailResent', false));
    }
  }, [verifyEmailResent]);

  //IF ERROR - DISPLAY ERROR FOR VERIFY EMAIL RESEND
  useEffect(() => {
    if (verifyEmailResentError) {
      setIsSendingEmail(false);
      setErrorMessage('Error sending email');
    }
  }, [verifyEmailResentError]);

  //ON CHANGE HANDLER FOR CREATE ACCOUNT INPUTS
  const updateAccountInfo = (e) => {
    setAccountContent({
      ...accountContent,
      [e.currentTarget.name]: e.currentTarget.value,
      [`${e.currentTarget.name}_error`]: '',
    });
  };

  //FUNCTION TO CHECK THAT INPUTS ARE NOT EMPTY
  const validateField = (input, object) => {
    const { value, name, label } = input;
    if (value === '') {
      object[`${name}_error`] = `${label} cannot be empty`;
      return true;
    } else {
      object[`${name}_error`] = '';
      return false;
    }
  };

  //FUNCTION TO SUBMIT ACCOUNT DETAILS + BASIC VALIDATION
  const onSubmitForm = () => {
    const tempData = { ...accountContent };
    const hasErrorList = accountInputs.map((input) => {
      return validateField(input, tempData);
    });
    let hasError = hasErrorList.some((item) => item === true);
    if (
      accountContent.password !== '' &&
      accountContent.password_repeated !== ''
    ) {
      if (accountContent.password !== accountContent.password_repeated) {
        tempData.password_error = 'Passwords must match';
        tempData.password_repeated_error = 'Passwords must match';
        hasError = true;
      }
    }
    if (hasError) {
      setErrorMessage('Please fix errors to continue.');
      setAccountContent(tempData);
    } else {
      if (!accountContent.accepted_terms) {
        setErrorMessage('Must accept terms in order to create an account.');
      } else {
        setIsCreatingAccount(true);
        dispatch(registerConsumerAccount(params?.id, accountContent));
      }
    }
  };

  //ALL INPUTS FOR CREATE ACCOUNT FORM
  const accountInputs = [
    {
      type: 'text',
      label: 'Email',
      name: 'email',
      width: '100%',
      placeholder: 'Email Address',
      value: accountContent.email,
      title: 'Invite can only be redeemed by this email address',
      isVisible: true,
      disabled: accountContent.is_transferable === false,
      onKeyPress: (e) => DetectEnterKeyPress(e, onSubmitForm),
      onChange: (e) => updateAccountInfo(e),
      hasError: accountContent.email_error !== '',
      errorMessage: accountContent.email_error,
    },
    {
      type: 'text',
      label: 'First name',
      name: 'first_name',
      width: '48%',
      placeholder: 'First name',
      value: accountContent.first_name,
      isVisible: true,
      disabled: false,
      onChange: (e) => updateAccountInfo(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, onSubmitForm),
      hasError: accountContent.first_name_error !== '',
      errorMessage: accountContent.first_name_error,
    },
    {
      type: 'text',
      label: 'Last name',
      name: 'last_name',
      width: '48%',
      placeholder: 'Last name',
      value: accountContent.last_name,
      isVisible: true,
      disabled: false,
      onChange: (e) => updateAccountInfo(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, onSubmitForm),
      hasError: accountContent.last_name_error !== '',
      errorMessage: accountContent.last_name_error,
    },
    {
      type: accountContent.show_password ? 'text' : 'password',
      label: 'Password',
      name: 'password',
      width: '100%',
      required: false,
      placeholder: 'Create Password',
      value: accountContent.password,
      isVisible: true,
      disabled: false,
      onKeyPress: (e) => DetectEnterKeyPress(e, onSubmitForm),
      onChange: (e) => updateAccountInfo(e),
      hasError: accountContent.password_error !== '',
      errorMessage: accountContent.password_error,
      withIcon: true,
      iconName: accountContent.show_password ? 'closed eye' : 'eye',
      iconSrc: accountContent.show_password ? eyeClosed : eye,
      onClick: () =>
        setAccountContent({
          ...accountContent,
          show_password: !accountContent.show_password,
        }),
    },
    {
      type: accountContent.show_password_repeated ? 'text' : 'password',
      label: 'Retype Password',
      name: 'password_repeated',
      width: '100%',
      required: false,
      placeholder: 'Retype Password',
      value: accountContent.password_repeated,
      isVisible: true,
      disabled: false,
      onKeyPress: (e) => DetectEnterKeyPress(e, onSubmitForm),
      onChange: (e) => updateAccountInfo(e),
      hasError: accountContent.password_repeated_error !== '',
      errorMessage: accountContent.password_repeated_error,
      withIcon: true,
      iconName: accountContent.show_password_repeated ? 'closed eye' : 'eye',
      iconSrc: accountContent.show_password_repeated ? eyeClosed : eye,
      onClick: () =>
        setAccountContent({
          ...accountContent,
          show_password_repeated: !accountContent.show_password_repeated,
        }),
    },
    {
      type: 'checkbox',
      isVisible: true,
      label: `I agree to the following <a href="${window.location.origin}/${consumerBasename}/user/terms" target="_blank">Terms of Service</a>.`,
      width: '100%',
      required: false,
      isCustom: true,
      value: accountContent.accepted_terms,
      onChange: () => {
        setAccountContent({
          ...accountContent,
          accepted_terms: !accountContent.accepted_terms,
        });
        setErrorMessage('');
      },
    },
  ];

  /*
    TWO MODAL UIS CAN BE DISPLAYED
    - Create Account (create) - inputs to create new account
    - Verify Email Sent (sent) - message that 6 digit code was sent to email
  */
  return ReactDOM.createPortal(
    <>
      <ModalOverlay>
        <CenterModal width="375px">
          <ModalHeader>
            <ModalHeadingConfig>Create Asset-Map Account</ModalHeadingConfig>
          </ModalHeader>
          <ModalContentContainer>
            {screen === 'create' && (
              <CreateAccountContainer>
                <CapsLockActive style={{ display: 'block' }}>
                  {(active) =>
                    active && <CapsLockWarning>Caps Lock is on</CapsLockWarning>
                  }
                </CapsLockActive>
                <TabContent>
                  {accountInputs.map((input, index) => {
                    return GenerateInput(input, index);
                  })}
                </TabContent>
                <Button
                  onClick={onSubmitForm}
                  type="submit"
                  text={'Create Account'}
                  showLoading={isCreatingAccount}
                  loadingText={'Creating Account'}
                  theme={ButtonThemes.primaryFull}
                />
                <DetectModalKeys onEnter={onSubmitForm} />
              </CreateAccountContainer>
            )}
            {screen === 'sent' && (
              <EmailSentContainer>
                <LightBackgroundIcon
                  backgroundColor={messageColors.successLabelBg}
                  width="60px"
                  iconWidth="30px"
                >
                  <img src={checkCircle} alt="check" />
                </LightBackgroundIcon>
                <SentMessage>
                  <p>An email has been sent to</p>
                  <Email>{accountContent?.email}</Email>
                </SentMessage>
                <DetailsMessage>
                  Click on the link sent in the email to verify your email or
                  enter the code sent in the email.
                </DetailsMessage>
                <Button
                  onClick={() => {
                    navigate(`/i/${params.id}/verify`);
                  }}
                  type="submit"
                  text={'Enter Code'}
                  theme={ButtonThemes.primaryFull}
                />
                <ExtraActions>
                  <ExtraMessage>{`Didn't receive an email?`}</ExtraMessage>
                  {isSendingEmail ? (
                    <SendingEmail>
                      <LoadingAnimation dots={true} smaller={true} />
                      Sending Email
                    </SendingEmail>
                  ) : emailSent ? (
                    <EmailSent>
                      <img src={check} alt="check" />
                      Email Sent
                    </EmailSent>
                  ) : (
                    <LinkText
                      onClick={() => {
                        dispatch(resendVerifyEmail(params.id));
                        setIsSendingEmail(true);
                      }}
                    >
                      Resend Email
                    </LinkText>
                  )}
                </ExtraActions>
              </EmailSentContainer>
            )}
          </ModalContentContainer>
          {errorMessage !== '' && <Error errorMessage={errorMessage} />}
        </CenterModal>
      </ModalOverlay>
    </>,
    document.body
  );
};

const CreateAccountContainer = styled.div`
  position: relative;
  ${CapsLockWarning} {
    position: absolute;
    font-size: 10px;
    margin: 0;
    right: 0;
    top: 0;
    font-weight: ${fonts.bold};
  }
  ${TabContent} {
    @media (max-width: 425px) {
      display: block;
    }
  }
`;

const EmailSentContainer = styled.div`
  text-align: center;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const SentMessage = styled.div`
  font-size: 15px;
  margin: 8px 0 15px 0;
`;

const Email = styled.p`
  font-weight: ${fonts.bold};
`;

const DetailsMessage = styled.p`
  text-align: center;
  font-size: 11px;
  margin-bottom: 20px;
  padding: 0 30px;
`;

const ExtraActions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 11px;
  margin-top: 15px;
`;

const ExtraMessage = styled.div`
  flex: 1 1 auto;
  text-align: left;
`;

const SendingEmail = styled.span`
  color: ${colors.lightBlue};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
`;

const EmailSent = styled.span`
  color: ${colors.green};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`;

export default CreateAccountModal;
