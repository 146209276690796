import { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { selectThemes } from '../../styles/themes';
import { colors, inputColors } from '../../styles/variables';

const SelectInput = ({
  selectedOption,
  onChange,
  options = [],
  isSearchable = true,
  placeholder = 'Select',
  autoFocus = true,
  isDisabled = false,
  isClearable = false,
  isMulti = false,
  isLoading = false,
  closeMenuOnSelect = true,
  hasError = false,
  noOptionsMessage = 'No Options',
  styles = selectThemes.default,
  isSmaller = false,
  onMenuOpen,
  onMenuClose,
  onEnter,
  formatOptionLabel,
  formatOptionValue,
  formatGroupLabel,
  components,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onKeyDown = (e) => {
    if (e?.key === 'Enter' || e?.keyCode === 13) {
      if (!isMenuOpen) {
        if (onEnter) onEnter();
      }
    }
  };

  const onMenuCloseFunc = () => {
    setIsMenuOpen(false);
    if (onMenuClose) onMenuClose();
  };
  const onMenuOpenFunc = () => {
    setIsMenuOpen(true);
    if (onMenuOpen) onMenuOpen();
  };

  return (
    <Select
      value={selectedOption}
      onChange={onChange}
      options={options}
      isSearchable={isSearchable}
      placeholder={placeholder}
      autoFocus={autoFocus}
      styles={
        hasError
          ? isSmaller
            ? selectThemes.smallerDisabled
            : selectThemes.defaultError
          : styles
      }
      isMulti={isMulti}
      isLoading={isLoading}
      components={components}
      isDisabled={isDisabled}
      isClearable={isClearable}
      onMenuOpen={onMenuOpenFunc}
      onMenuClose={onMenuCloseFunc}
      closeMenuOnSelect={closeMenuOnSelect}
      formatOptionLabel={formatOptionLabel}
      formatOptionValue={formatOptionValue}
      formatGroupLabel={formatGroupLabel}
      onKeyDown={onKeyDown}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.blue,
          primary25: inputColors.primary25,
          primary50: inputColors.primary50,
          primary75: inputColors.primary75,
          neutral80: colors.darkGrey,
        },
      })}
      noOptionsMessage={() => {
        return noOptionsMessage;
      }}
      menuPlacement={'auto'}
    />
  );
};

SelectInput.propTypes = {
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  hasError: PropTypes.bool,
  styles: PropTypes.object,
  noOptionsMessage: PropTypes.string,
  isSmaller: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  onEnter: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  formatGroupLabel: PropTypes.func,
  formatOptionValue: PropTypes.func,
  components: PropTypes.object,
};

export default SelectInput;
