import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { chevronRight } from '../assets';
import { NavIcon, NavLabel, ItemLabel } from '../containers/SideNavContainer';
import { colors, fonts, inputColors } from '../styles/variables';
import { ChevronIcon } from '../styles/library/imageStyles';
import { LoadingPlaceholder } from '../styles/library/fontStyles';

const SideNavItem = ({ item, isExpanded, onClick, isLoading }) => {
  const location = useLocation();

  const isActive = () => {
    let locationArr = location.pathname.split('/');
    if (item.to === 'summary') {
      return locationArr.includes(item.to) || locationArr.includes('info');
    } else {
      return locationArr.includes(item.to);
    }
  };

  return onClick ? (
    <SideNavItemContainer
      onClick={onClick}
      title={!isExpanded ? item.label : null}
    >
      <SideNavItemContent
        style={item.lastItem ? { borderBottom: '1px solid transparent' } : null}
      >
        <NavIcon src={item.icon} data-image={`side-nav-${item.key}`} />
        {isExpanded && (
          <NavLabel>
            <ItemLabel>
              {item.label}
              {item.superscript && <sup>{item.superscript}</sup>}
            </ItemLabel>
            {item.hasChildren && (
              <ChevronIcon
                src={chevronRight}
                alt="arrow"
                data-image="arrow-right"
              />
            )}
          </NavLabel>
        )}
      </SideNavItemContent>
    </SideNavItemContainer>
  ) : isLoading ? (
    <SideNavItemContainerLink
      style={isActive() ? { background: colors.lightGrey } : null}
    >
      <SideNavItemContent
        style={item.lastItem ? { borderBottom: '1px solid transparent' } : null}
      >
        <LoadingPlaceholder
          margin="0"
          round={true}
          width="22px"
          height="22px"
        />
        {isExpanded && (
          <NavLabel>
            <ItemLabel>
              <LoadingPlaceholder margin="0" />
            </ItemLabel>
          </NavLabel>
        )}
      </SideNavItemContent>
    </SideNavItemContainerLink>
  ) : (
    <SideNavItemContainerLink
      to={item.to}
      title={item.label}
      style={isActive() ? { background: colors.lightGrey } : null}
    >
      {item.new && <NewIcon>NEW</NewIcon>}
      {item.beta && <NewIcon>BETA</NewIcon>}
      {item.to ? (
        <SideNavItemLink
          style={
            item.lastItem ? { borderBottom: '1px solid transparent' } : null
          }
        >
          <NavIcon src={item.icon} data-image={`side-nav-${item.key}`} />
          {isExpanded && (
            <NavLabel>
              <ItemLabel>
                {item.label}
                {item.superscript && <sup>{item.superscript}</sup>}
              </ItemLabel>
              {item.hasChildren && (
                <ChevronIcon
                  src={chevronRight}
                  alt="arrow"
                  data-image="arrow-right"
                />
              )}
            </NavLabel>
          )}
        </SideNavItemLink>
      ) : (
        <SideNavItemContent
          style={
            item.lastItem ? { borderBottom: '1px solid transparent' } : null
          }
          title={item.label}
        >
          <NavIcon src={item.icon} data-image={`side-nav-${item.key}`} />
          {isExpanded && (
            <NavLabel>
              <ItemLabel>
                {item.label}
                {item.superscript && <sup>{item.superscript}</sup>}
              </ItemLabel>
              {item.hasChildren && (
                <ChevronIcon
                  src={chevronRight}
                  alt="arrow"
                  data-image="arrow-right"
                />
              )}
            </NavLabel>
          )}
        </SideNavItemContent>
      )}
    </SideNavItemContainerLink>
  );
};

const NewIcon = styled.div`
  position: absolute;
  font-size: 6px;
  right: 5px;
  top: 4px;
  padding: 2px 4px;
  color: ${colors.blue};
  font-weight: ${fonts.semiBold};
  background: ${inputColors.primary25};
  border-radius: 10px;
  z-index: 1;
  /* width: 8px;
  height: 8px; */
`;

const SideNavItemContainerLink = styled(Link)`
  cursor: pointer;
  position: relative;
  display: block;
  padding: 0 13px;
  overflow: hidden;
  color: ${colors.darkGrey};
  overflow: hidden;
  border-bottom: 1px solid ${colors.lightGrey};
  &:hover {
    background: ${colors.lightGrey};
    ${NavIcon} {
      transform: scale(1.02);
    }
    ${NavLabel} {
      transform: scale(1.01);
    }
  }
`;

const SideNavItemContainer = styled.div`
  cursor: pointer;
  display: block;
  padding: 0 13px;
  color: ${colors.darkGrey};
  border-bottom: 1px solid ${colors.lightGrey};
  &:hover {
    background: ${colors.lightGrey};
    ${NavIcon} {
      transform: scale(1.02);
    }
    ${NavLabel} {
      transform: scale(1.01);
    }
  }
`;

const SideNavItemContent = styled.div`
  padding: 12px 5px;
  /* border-bottom: 1px solid ${colors.lightGrey}; */
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SideNavItemLink = styled.div`
  padding: 12px 5px;
  /* border-bottom: 1px solid ${colors.lightGrey}; */
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

SideNavItem.propTypes = {
  props: PropTypes.object,
  item: PropTypes.object,
  index: PropTypes.number,
  navWidth: PropTypes.string,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SideNavItem;
