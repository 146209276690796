/* eslint-disable array-callback-return */
export const FilterInstrumentsByType = (instruments, type) => {
  return instruments.filter(
    (instrument) => instrument.instrument_type === type
  );
};

export const HasNoOwnersCategory = (
  instruments,
  category,
  relation = 'owner'
) => {
  const copied = [...instruments];
  return copied.filter((instrument) => {
    if (instrument.category === category) {
      const hasOwner = instrument.members.some(
        (mem) => mem.relation === relation
      );
      if (!hasOwner) {
        return instrument;
      }
    }
  });
};

export const HasNoOwners = (instruments) => {
  const copied = [...instruments];
  return copied.filter((instrument) => {
    const hasOwner = instrument.members.some((mem) => mem.relation === 'owner');
    if (!hasOwner) {
      return instrument;
    }
  });
};

export const HasAssociatedAsset = (instruments, assetIds) => {
  return instruments.filter((instrument) => {
    if (assetIds.includes(instrument.associated)) {
      // instrument.noMargin = true;
      return instrument;
    }
  });
};

export const FilterOutJointInstruments = (
  instruments,
  member,
  relation = 'owner'
) => {
  return instruments.filter((instrument) => {
    if (!instrument.is_joint) {
      return instrument;
    } else {
      const hasSecondMember = instrument.members.some(
        (mem) => mem.id === member && mem.relation === relation
      );
      if (!hasSecondMember) {
        return instrument;
      }
    }
  });
};

export const FilterOutJointInsuranceInstruments = (
  instruments,
  member,
  relation = 'owner',
  showEntityRollup
) => {
  return instruments.filter((instrument) => {
    const hasSecondMember = instrument.members.some(
      (mem) => mem.id === member && mem.relation === relation
    );
    if (showEntityRollup) {
      if (!instrument.is_joint) {
        return instrument;
      }
    } else {
      if (!hasSecondMember) {
        return instrument;
      }
    }
  });
};

export const FilterJointInstruments = (
  instruments,
  member,
  relation = 'owner',
  showEntityRollup
) => {
  return instruments.filter((instrument) => {
    const hasSecondMember = instrument.members.some(
      (mem) => mem.id === member && mem.relation === relation
    );
    if (instrument.instrument_type === 'insurance') {
      instrument.calculated = instrument.cash_value;
      instrument.show_cash_value = true;
      if (hasSecondMember) {
        if (showEntityRollup) {
          if (instrument.is_joint) {
            return instrument;
          }
        } else {
          return instrument;
        }
      }
    }
    if (instrument.is_joint) {
      if (hasSecondMember) {
        return instrument;
      }
    }
  });
};

export const FindCustodianAssets = ([...assets], memberId) => {
  return assets.filter((asset) => {
    const custodians = asset.members.reduce((acc, mem) => {
      if (mem.relation === 'custodian') {
        return [...acc, mem.id];
      }
      return acc;
    }, []);
    const owners = asset.members.reduce((acc, mem) => {
      if (mem.relation === 'owner') {
        return [...acc, mem.id];
      }
      return acc;
    }, []);
    if (
      custodians &&
      custodians.length !== 0 &&
      custodians.includes(memberId) &&
      !owners.includes(memberId)
    ) {
      asset.owned_by_another = true;
      return asset;
    }
  });
};

export const FindCustodianAssetsMultiple = ([...assets], selectedMembers) => {
  const selectedMemberIds = selectedMembers.map((mem) => mem.id);
  /*
    x Going to be passed in a list of assets
    x Need to find if the asset has custodians
    x If it has custodians and custodian is one of selected member
    x Need to check if custodian is also owner - can't be an owner
    Need to check if owner is not one of selected member
    */
  return assets.filter((asset) => {
    const custodians = asset.members.reduce((acc, mem) => {
      if (mem.relation === 'custodian') {
        return [...acc, mem.id];
      }
      return acc;
    }, []);
    const custodianSelected = custodians.some((r) =>
      selectedMemberIds.includes(r)
    );
    if (custodianSelected) {
      const owners = asset.members.reduce((acc, mem) => {
        if (mem.relation === 'owner') {
          return [...acc, mem.id];
        }
        return acc;
      }, []);
      const notOwner = !custodians.some((r) => owners.includes(r));
      const ownerSelected = owners.some((r) => selectedMemberIds.includes(r));
      if (notOwner && !ownerSelected) {
        return asset;
      }
    }
  });
};

export const DetermineOwnedByAnother = (selectedMembers, items, memberId) => {
  const selectedMemberIds = selectedMembers.map((mem) => mem.id);
  return items.map((item) => {
    const owners = item.members.filter((mem) => mem.relation === 'owner');
    let ownerIsMember = true;
    if (memberId) {
      ownerIsMember = owners.some((o) => o.id === memberId);
    } else {
      ownerIsMember = owners.some((r) => selectedMemberIds.includes(r.id));
    }
    if (owners.length !== 0 && !ownerIsMember) {
      item.owned_by_another = true;
    } else {
      item.owned_by_another = false;
    }
    return item;
  });
};

export const RemoveDuplicates = (myArr) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos;
  });
};

export const FindIntersect = (a, b) => {
  var setA = new Set(a);
  var setB = new Set(b);
  var intersection = new Set([...setA].filter((x) => setB.has(x)));
  return Array.from(intersection);
};

export const FilterInstruments = (instruments, type) => {
  return instruments.filter(
    (instrument) => instrument.instrument_type === type
  );
};

export const FilterSplitMemberInstruments = (
  instrumentList,
  memberId,
  relation = 'owner'
) => {
  return instrumentList.filter((instrument) => {
    if (
      instrument.members.some(
        (member) => member.id === memberId && member.relation === relation
      )
    ) {
      // instrument.owned_by_another = false;
      return instrument;
    }
  });
};

export const GetMemberEntities = (entityList, memberId, selectedMembers) => {
  if (memberId) {
    return entityList.reduce((acc, entity) => {
      const entitiesOwners = entity.owners.map((ent) => ent.id);
      if (entitiesOwners.includes(memberId)) {
        return [...acc, entity];
      }
      return acc;
    }, []);
  } else {
    const selectedMemberIds = selectedMembers.map((mem) => mem.id);
    return entityList.reduce((acc, entity) => {
      const entitiesOwners = entity.owners.map((ent) => ent.id);
      if (entitiesOwners.some((item) => selectedMemberIds.includes(item))) {
        return [...acc, entity];
      }
      return acc;
    }, []);
  }
};

export const GetEntityInstruments = (
  instrumentList,
  entityList,
  relation = 'owner'
) => {
  //Find all applicable instruments for a list of entities??
  if (entityList && entityList.length !== 0) {
    return instrumentList.filter((instrument) => {
      const membersArr = instrument.members.reduce((acc, member) => {
        if (member.relation === relation) {
          return [...acc, member.id];
        }
        return acc;
      }, []);
      const selectedIds = entityList.map((mem) => mem.id);
      if (selectedIds && selectedIds.length !== 0) {
        if (membersArr.some((item) => selectedIds.includes(item))) {
          instrument.owned_by_entity = true;
          return { ...instrument };
        }
      }
    });
  }
  return [];
};

export const GetSingleEntityInstruments = (
  instrumentList,
  entity,
  relation = 'owner'
) => {
  if (entity) {
    return instrumentList.filter((instrument) => {
      const membersArr = instrument.members.reduce((acc, member) => {
        if (member.relation === relation) {
          return [...acc, member.id];
        }
        return acc;
      }, []);
      if (membersArr.includes(entity.id)) {
        instrument.owned_by_entity = true;
        return { ...instrument };
      }
    });
  }
  return [];
};

export const FilterMemberInstruments = (
  selectedMembers,
  instrumentList,
  relation = 'owner'
) => {
  return instrumentList.filter((instrument) => {
    const membersArr = instrument.members.reduce((acc, member) => {
      if (member.relation === relation) {
        return [...acc, member.id];
      }
      return acc;
    }, []);
    const selectedIds = selectedMembers.map((mem) => mem.id);
    if (selectedIds && selectedIds.length !== 0) {
      if (membersArr.some((item) => selectedIds.includes(item))) {
        return instrument;
      }
    } else {
      if (instrument.members.length === 0) {
        return instrument;
      }
    }
  });
};
