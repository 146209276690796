import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

/*
  Route takes in desired component to render and role boolean
  - Checks if user is logged in, if not they are redirect to /
  - Checks if user has passed in role, if they do they can proceed to route
  - If they don't have the role they are redirect to dashboard
*/
const PermissionRoute = ({ children, role }) => {
  const loggedIn = useSelector((state) => state.user.loggedIn);

  return role !== null ? (
    loggedIn && role ? (
      children
    ) : (
      <Navigate to="/" />
    )
  ) : (
    !loggedIn && <Navigate to="/" />
  );
};

PermissionRoute.propTypes = {
  children: PropTypes.object,
  role: PropTypes.bool,
};

export default PermissionRoute;
