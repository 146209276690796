/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ModalTabs, Error, EmptyImage, InfoBox } from '..';
import { LoadingAnimation, Select } from '../../components';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import {
  timesWhite,
  launchLink,
  check,
  noReports,
  share,
  cancel,
} from '../../assets';
import {
  colors,
  fonts,
  inputColors,
  messageColors,
} from '../../styles/variables';
import {
  CapitalizeFirstLetter,
  DateFormat,
  DateFormatUTC,
  integrationOptions,
  SendTrackingEvent,
} from '../../utils';
import {
  getReportShareTypeDetails,
  shareReport,
  stopSharingReport,
  toggleShowReportShareModal,
  updateHouseholdStoreValue,
} from '../../store/actions';
import { ErrorThemes } from '../../styles/themes';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { GenerateReportIcon } from '../Reports/ReportListItem';

const ReportShareModal = ({ report, hide, householdId }) => {
  // hooks
  const ref = useRef();
  const dispatch = useDispatch();

  // global state
  const {
    reportShareTypes,
    reportShareTypeDetails,
    sharingReport,
    invocationId,
    sharedReportDetails,
    reportShareFailed,
  } = useSelector((state) => ({
    reportShareTypes: state.households.reportShareTypes,
    reportShareTypeDetails: state.households.reportShareTypeDetails,
    sharingReport: state.households.sharingReport,
    invocationId: state.configs.invocationId,
    sharedReportDetails: state.households.sharedReportDetails,
    reportShareFailed: state.households.reportShareFailed,
  }));

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [shareModalTabs, setShareModalTabs] = useState();
  const [showTabs, setShowTabs] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [integration, setIntegration] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [sharedReport, setSharedReport] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [linkedMemberOptions, setLinkedMemberOptions] = useState([
    {
      id: 'all',
      label: 'All Linked Household Members',
      name: 'all linked household members',
    },
  ]);
  const [shareSelect, setShareSelect] = useState(false);
  const [selectedShareMember, setSelectedShareMember] = useState(
    linkedMemberOptions[0]
  );
  const [loadedConnectMembers, setLoadedConnectMembers] = useState(false);
  const [shareableMembers, setShareableMembers] = useState([]);

  // Listeners
  useEffect(() => {
    return () => {
      dispatch(updateHouseholdStoreValue('reportShareTypes', false));
      dispatch(updateHouseholdStoreValue('reportShareTypeDetails', false));
      dispatch(updateHouseholdStoreValue('sharedReportDetails', false));
      dispatch(toggleShowReportShareModal(false));
      setSharedReport();
      setErrorMessage();
    };
  }, []);

  useEffect(() => {
    if (reportShareTypes && !activeTab) {
      setActiveTab(reportShareTypes[0]);
      setShowTabs(reportShareTypes.length > 1);
    }
  }, [reportShareTypes, activeTab]);

  useEffect(() => {
    if (activeTab) {
      let matchedIntegration = integrationOptions.find(
        (int) => int.id === activeTab
      );
      setIntegration(matchedIntegration);
      dispatch(getReportShareTypeDetails(householdId, report, activeTab));
    }
  }, [activeTab]);

  useEffect(() => {
    if (reportShareTypes && report) {
      const reportShareTabs = reportShareTypes.map((rst) => {
        const matchedIntegration = integrationOptions.find(
          (int) => int.id === rst
        );
        return {
          value: rst,
          label: matchedIntegration?.name
            ? matchedIntegration?.name
            : CapitalizeFirstLetter(rst),
          icon: matchedIntegration?.icon ? matchedIntegration?.icon : false,
          showTextAndIcon: matchedIntegration?.icon ? true : false,
        };
      });
      setShareModalTabs(reportShareTabs);
      setIsLoading(false);
      setButtonLoading(false);
    }
  }, [reportShareTypes, report]);

  useEffect(() => {
    if (reportShareTypeDetails && reportShareTypeDetails?.details && report) {
      if (activeTab === 'connect') {
        let membersList =
          reportShareTypeDetails?.details?.shareable_members || [];
        membersList = membersList.map((member) => {
          member.isShared = member?.shared?.id ? true : false;
          member.sharedId = member?.shared?.id;
          member.sharedDate = member?.shared?.created_utc;
          return member;
        });
        setShareableMembers(membersList);
        setLoadedConnectMembers(true);
      } else {
        report.details = reportShareTypeDetails.details;
        if (activeTab === 'msaws') {
          const linkedMembers =
            reportShareTypeDetails.details.linked_householdmembers.map(
              (hhm) => {
                hhm.label = hhm.name;
                hhm.value = hhm.id;
                return hhm;
              }
            );
          if (linkedMembers.length) {
            if (linkedMembers.length === 1) {
              setSelectedShareMember(linkedMembers[0]);
            } else {
              // add ability to select who to share to
              setShareSelect(true);
              setLinkedMemberOptions(linkedMemberOptions.concat(linkedMembers));
            }
          }
        }
      }
    }
  }, [reportShareTypeDetails]);

  useEffect(() => {
    if (sharedReportDetails) {
      setErrorMessage();
      setButtonLoading(false);
      setSharedReport(sharedReportDetails);
    }
  }, [sharedReportDetails]);

  useEffect(() => {
    if (reportShareFailed) {
      setErrorMessage(reportShareFailed.data.detail);
      setButtonLoading(false);
    }
  }, [reportShareFailed]);

  const closeModal = () => {
    setActiveTab(false);
    setShareModalTabs(false);
    setIsLoading(true);
    hide();
  };

  const sendReportTo = (shareType) => {
    setButtonLoading(true);
    setErrorMessage();
    setSharedReport();
    if (activeTab === 'msaws') {
      const shareToMembers =
        selectedShareMember.id === 'all'
          ? linkedMemberOptions
              .filter((hhm) => hhm?.id !== 'all')
              .map((hhm) => hhm.id)
          : [selectedShareMember?.id];
      dispatch(shareReport(householdId, report, shareType, shareToMembers));
    } else {
      dispatch(shareReport(householdId, report, shareType));
    }
    SendTrackingEvent(
      invocationId,
      'action',
      'report_share',
      'household_reports',
      {
        household: householdId,
        report: report.id,
        share_type: shareType,
      }
    );
  };

  const generateSuccessMessage = () => {
    if (integration.id === 'msaws') {
      return `has successfully been shared to ${selectedShareMember.name} in your ${integration.name} account.`;
    }
    return `has successfully been shared to your ${integration.name} account.`;
  };

  const actionOnClick = (member) => {
    if (member.isShared) {
      dispatch(stopSharingReport(householdId, report, member?.sharedId));
    } else {
      dispatch(
        shareReport(householdId, report, 'connect', { member: member?.id })
      );
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Share Report</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => closeModal()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          {isLoading ? (
            <LoadingContainer>
              <LoadingAnimation />
            </LoadingContainer>
          ) : (
            <>
              {showTabs && (
                <ModalTabs
                  tabs={shareModalTabs}
                  activeTab={activeTab}
                  onClick={setActiveTab}
                />
              )}
              <ModalContentContainer>
                {activeTab === 'connect' ? (
                  <ConnectTabContent>
                    <ReportType>
                      <ReportIcon
                        src={GenerateReportIcon(report?.report_type)}
                        alt={report.report_type}
                        data-image={report.report_type}
                      />
                      <ReportTitle>
                        {report?.report_title || 'Unknown Report'}
                      </ReportTitle>
                    </ReportType>
                    <ReportSubtitle>
                      Share or stop sharing this report with these Client Portal
                      members.
                    </ReportSubtitle>
                    {loadedConnectMembers ? (
                      <ShareMemberList>
                        {shareableMembers.length ? (
                          shareableMembers.map((member, index) => {
                            return (
                              <MemberContainer
                                key={index}
                                isShared={member?.isShared}
                              >
                                <LeftContent>
                                  <MemberName>{member?.name}</MemberName>
                                  {member.isShared && member.sharedDate ? (
                                    <ShareDate>
                                      Shared on{' '}
                                      {DateFormatUTC(
                                        member?.sharedDate,
                                        `MMM D, YYYY h:mma`,
                                        true
                                      )}
                                    </ShareDate>
                                  ) : (
                                    <ShareDate>Not Shared</ShareDate>
                                  )}
                                </LeftContent>
                                <RightContent>
                                  <ActionButton
                                    onClick={() => actionOnClick(member)}
                                  >
                                    <img
                                      src={member.isShared ? cancel : share}
                                      alt={member.isShared ? 'cancel' : 'share'}
                                      title={
                                        member.isShared
                                          ? 'Stop Sharing'
                                          : 'Share'
                                      }
                                    />
                                  </ActionButton>
                                </RightContent>
                              </MemberContainer>
                            );
                          })
                        ) : (
                          <InfoBox
                            message={`There are no active Client Portal members for this household. Please send an invite to share this household or make sure the invite has been accepted.`}
                            fontSize="13px"
                            margin="15px 0"
                          />
                        )}
                      </ShareMemberList>
                    ) : (
                      <ShareMemberList>
                        {[0, 1].map((i) => {
                          return (
                            <MemberContainer key={i} isShared={false}>
                              <LeftContent>
                                <MemberName>
                                  <LoadingPlaceholder
                                    height="21.5px"
                                    width="150px"
                                    margin="0 0 2px 0"
                                  />
                                </MemberName>
                                <ShareDate>
                                  <LoadingPlaceholder
                                    margin="0"
                                    height="16px"
                                  />
                                </ShareDate>
                              </LeftContent>
                              <RightContent>
                                <ActionButton>
                                  <LoadingPlaceholder
                                    width="30px"
                                    height="30px"
                                    round={true}
                                    margin="0"
                                  />
                                </ActionButton>
                              </RightContent>
                            </MemberContainer>
                          );
                        })}
                      </ShareMemberList>
                    )}
                  </ConnectTabContent>
                ) : activeTab === 'morningstar' || activeTab === 'msaws' ? (
                  <RSTabContent>
                    <ImageContainer
                      style={{
                        maxWidth: integration?.maxWidth || null,
                      }}
                    >
                      <img
                        src={integration?.image}
                        alt={integration?.id}
                        data-image={integration?.id}
                      />
                    </ImageContainer>
                    <IntegrationContent>
                      <IntegrationTextContainer>
                        <IntegrationText>
                          Send the following report to your connected{' '}
                          {integration?.name} account.
                        </IntegrationText>
                        <IntegrationHeading>
                          Report Information
                        </IntegrationHeading>
                        <InfoContainer>
                          <InfoLabel>Report Type</InfoLabel>
                          <InfoContent>{report.report_title}</InfoContent>
                        </InfoContainer>
                        <InfoContainer>
                          <InfoLabel>Generated By</InfoLabel>
                          <InfoContent>{report.member.name}</InfoContent>
                        </InfoContainer>
                        <InfoContainer>
                          <InfoLabel>Created</InfoLabel>
                          <InfoContent>
                            {DateFormatUTC(
                              report.created_utc,
                              `MMM D, YYYY h:mma`,
                              true
                            )}
                          </InfoContent>
                        </InfoContainer>
                      </IntegrationTextContainer>
                      {shareSelect && (
                        <ShareSelectContainer>
                          <InputLabel>Send to:</InputLabel>
                          <Select
                            selectedOption={selectedShareMember}
                            onChange={(e) => setSelectedShareMember(e)}
                            options={linkedMemberOptions}
                            isSearchable={true}
                            placeholder={'All Linked Members'}
                            closeMenuOnSelect={true}
                            autoFocus={false}
                          />
                        </ShareSelectContainer>
                      )}
                      {sharedReport && (
                        <IntegrationSuccessContainer>
                          {sharedReport.name}
                          <img src={check} alt="check" data-image="check" />
                          <IntegrationSuccess>
                            <span>
                              {report.report_title}_
                              {DateFormat(report.created_utc, 'MM_DD_YYYY')}
                              {'.pdf'}
                            </span>{' '}
                            {generateSuccessMessage()}
                          </IntegrationSuccess>
                        </IntegrationSuccessContainer>
                      )}
                      {errorMessage && (
                        <Error
                          errorMessage={`There was a problem sharing your report to
                                ${integration?.name}. Error Message: ${errorMessage}`}
                          theme={ErrorThemes.inverted}
                        />
                      )}
                      {report?.details?.external_link && (
                        <IntegrationLink
                          href={report.details?.external_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={launchLink}
                            alt="link"
                            data-image="launch"
                          />
                          View Morningstar Office
                        </IntegrationLink>
                      )}
                    </IntegrationContent>
                    <ButtonsRowContainer>
                      <ButtonContainer>
                        <Button
                          text={'Send Report'}
                          onClick={() => sendReportTo(integration.id)}
                          showLoading={buttonLoading || sharingReport}
                          loadingText={'Sending'}
                        />
                      </ButtonContainer>
                    </ButtonsRowContainer>
                  </RSTabContent>
                ) : activeTab === 'blackdiamond' ? (
                  <RSTabContent>
                    <div>push report to blackdiamond</div>
                  </RSTabContent>
                ) : (
                  <EmptyContainer>
                    <EmptyImage
                      imageSrc={noReports}
                      imageName="No Reports"
                      text="No Report Sharing Options Available"
                      styles={{
                        fontSize: '16px',
                        imageHeight: '145px',
                        textMargin: '5px 10px',
                        containerMargin: '0px 20px',
                      }}
                    />
                  </EmptyContainer>
                )}
              </ModalContentContainer>
            </>
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const LoadingContainer = styled.div`
  height: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectTabContent = styled.div`
  /* min-height: 225px; */
`;

const ReportType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 10px;
`;

const ReportTitle = styled.span`
  font-size: 18px;
  font-weight: ${fonts.semiBold};
`;

const ReportIcon = styled.img`
  height: 22px;
  width: 22px;
  margin-right: 15px;
`;

const ReportSubtitle = styled.p`
  font-size: 14px;
  margin: 10px 0;
`;

const ShareMemberList = styled.div``;

const LeftContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
`;

const MemberName = styled.p`
  font-weight: ${fonts.semiBold};
  font-size: 16px;
`;

const ShareDate = styled.p`
  font-size: 13px;
  color: ${colors.paleGrey};
`;

const RightContent = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
`;

const ActionButton = styled.button`
  /* width: 40px;
  height: 40px; */
  padding: 0;
  margin: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* border-radius: 100%;
  &:hover {
    background: ${colors.lightGrey};
  } */
  img {
    width: 18px;
    height: 18px;
  }
`;

const MemberContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  background: ${(props) =>
    props.isShared ? colors.lighterGrey : colors.white};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 12px 15px;
  border-radius: 3px;
  ${MemberName} {
    color: ${(props) => (props.isShared ? colors.paleGrey : colors.darkGrey)};
  }
`;

const ImageContainer = styled.div`
  width: 370px;
  max-width: 200px;
  height: 50px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const IntegrationContent = styled.div`
  width: 100%;
  color: ${colors.darkGrey};
  text-align: left;
`;

const IntegrationHeading = styled.p`
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
`;

const IntegrationTextContainer = styled.div`
  margin-bottom: 15px;
`;

const IntegrationText = styled.p`
  font-size: 11px;
  color: ${colors.paleGrey};
  text-align: left;
  margin: 10px 0;
`;

const ShareSelectContainer = styled.div`
  margin: 10px 0 10px 0;
`;

const InputLabel = styled.label`
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const IntegrationSuccessContainer = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  background: ${messageColors.successBackground};
  border: 1px solid ${colors.green};
  border-radius: 5px;
  display: flex;
  align-items: center;
  img {
    margin: 0px 12px 0px 6px;
    width: 20px;
    height: 20px;
  }
`;

const IntegrationSuccess = styled.p`
  font-size: 11px;
  color: ${messageColors.success};
  text-align: left;
  margin: 5px 0;
  span {
    font-weight: ${fonts.semiBold};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  margin-left: 10px;
`;

const InfoLabel = styled.p`
  font-weight: ${fonts.semiBold};
  flex: 0 0 auto;
  width: 100px;
`;

const InfoContent = styled.p``;

const IntegrationLink = styled.a`
  color: ${colors.lightBlue};
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 10px;
    margin-right: 5px;
  }
`;

const RSTabContent = styled(TabContent)`
  flex-direction: column;
  min-height: 225px;
  ${ButtonContainer} {
    button {
      margin-right: 0;
    }
  }
`;

ReportShareModal.propTypes = {
  report: PropTypes.object,
  hide: PropTypes.func,
  householdId: PropTypes.string,
};

export default ReportShareModal;
