import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Select } from '..';
import {
  colors,
  maxDevice,
  messageColors,
  fonts,
} from '../../styles/variables';
import {
  chevronRight,
  gripLines,
  checkboxGreen,
  square,
  download,
  checkCircle,
  cancel,
} from '../../assets';
import { InputLabel } from '../../styles/library/inputStyles';
import { LoadingPlaceholder } from '../../styles/library/fontStyles';
import { selectThemes } from '../../styles/themes';

const ReportGenerateItem = ({
  report,
  last = false,
  full = true,
  expandItem,
  selectReport,
  downloadReport,
  selectAllChildren,
  loading = false,
  hasEntityRollup,
  amrEntityOwnership,
  setAmrEntityOwnership,
  amrOrientation,
  setAmrOrientation,
}) => {
  return (
    <ThemeProvider theme={{ full }}>
      <ReportContainer
        data-report-value={report.value}
        style={{
          display: !report.available ? 'none' : null,
          cursor: report.disabled && full ? 'not-allowed' : null,
          borderBottom: last ? '1px solid transparent' : null,
        }}
      >
        <ReportRowContainer>
          <ReportNameContainer
            style={report.hasOptions ? { cursor: 'pointer' } : null}
            onClick={loading ? null : () => expandItem(report.value)}
            data-report-name={report.name}
          >
            {report.hasOptions ? (
              <ChevronIcon
                src={chevronRight}
                alt="toggle"
                data-image={`${report.value}-arrow`}
                style={report.expanded ? { transform: 'rotate(90deg)' } : null}
              />
            ) : (
              <span style={{ width: full ? '12px' : '0px' }}></span>
            )}
            {loading ? (
              <LoadingPlaceholder
                width="20px"
                height="20px"
                margin="0 15px 0 13px"
                round="true"
              />
            ) : (
              <ReportIcon
                src={report.icon}
                alt={report.name}
                data-image={report.value}
                style={report.selected ? { opacity: 1 } : { opacity: 0.45 }}
              />
            )}
            {loading ? (
              <LoadingPlaceholder width="160px" margin="0px" />
            ) : (
              <span
                style={report.selected ? { opacity: 1 } : { opacity: 0.45 }}
              >
                {report.name} {report.disabled && <Required>*</Required>}
              </span>
            )}
          </ReportNameContainer>
          <ReportActionContainer>
            {full ? (
              loading ? (
                <>
                  <LoadingPlaceholder
                    width="20px"
                    height="20px"
                    margin="0"
                    round="true"
                  />
                  <LoadingPlaceholder
                    width="20px"
                    height="20px"
                    margin="0 10px 0 13px"
                    round="true"
                  />
                  <LoadingPlaceholder
                    width="20px"
                    height="20px"
                    margin="0"
                    round="true"
                  />
                </>
              ) : (
                <>
                  {report.showEntityOwnership && hasEntityRollup && (
                    <AMREntityOwnership>
                      <InputLabel margin="0 5px 0 0">
                        Entity Ownership
                      </InputLabel>
                      <SelectContainer>
                        <Select
                          selectedOption={amrEntityOwnership}
                          onChange={setAmrEntityOwnership}
                          options={report.options.entityOwnershipOptions}
                          isSearchable={true}
                          placeholder={'EntityOwnership'}
                          autoFocus={false}
                          styles={selectThemes.smallest}
                          isSmaller={true}
                        />
                      </SelectContainer>
                    </AMREntityOwnership>
                  )}
                  {report.showIncludeKFFs && (
                    <IncludeKFFContainer>
                      <InputLabel margin="0 5px 0 0">
                        Include Key Financials
                      </InputLabel>
                      <CheckboxItem
                        src={report.includeKffs ? checkboxGreen : square}
                        alt={report.includeKffs ? 'checked box' : 'box'}
                        data-image={`include-amr-kffs-checkbox-${
                          report.includeKffs ? 'checked' : 'unchecked'
                        }`}
                        onClick={
                          report.disabled || loading
                            ? null
                            : () => {
                                selectReport('includeKffs');
                              }
                        }
                        style={{
                          cursor:
                            report.disabled && full ? 'not-allowed' : null,
                          // opacity: report.selected ? 1 : 0.4,
                        }}
                      />
                    </IncludeKFFContainer>
                  )}
                  {report.showOrientation && (
                    <AMROrientationContainer>
                      <InputLabel margin="0 5px 0 0">Orientation</InputLabel>
                      <SelectContainer>
                        <Select
                          selectedOption={amrOrientation}
                          onChange={setAmrOrientation}
                          options={report.options.amrOrientationOptions}
                          isSearchable={true}
                          placeholder={'Orientation'}
                          autoFocus={false}
                          styles={selectThemes.smallest}
                          isSmaller={true}
                        />
                      </SelectContainer>
                    </AMROrientationContainer>
                  )}

                  <CheckboxItem
                    src={report.selected ? checkboxGreen : square}
                    alt={report.selected ? 'checked box' : 'box'}
                    data-image={`${report.value}-checkbox-${
                      report.selected ? 'checked' : 'unchecked'
                    }`}
                    onClick={
                      report.disabled || loading
                        ? null
                        : () => {
                            selectReport(report.value);
                          }
                    }
                    style={{
                      cursor: report.disabled && full ? 'not-allowed' : null,
                      // opacity: report.selected ? 1 : 0.4,
                    }}
                  />
                  <DownloadIcon
                    src={download}
                    alt="download"
                    data-image={`${report.value}-download`}
                    onClick={
                      loading ||
                      (report.hasOwnProperty('childrenSelected') &&
                        report.childrenSelected === false)
                        ? null
                        : () =>
                            downloadReport(
                              report,
                              report?.value === 'assetmap'
                                ? amrOrientation?.value
                                : null,
                              report?.value === 'assetmap'
                                ? amrEntityOwnership?.value
                                : null
                            )
                    }
                    style={{
                      opacity: report.selected ? 1 : 0.4,
                      cursor:
                        report.hasOwnProperty('childrenSelected') &&
                        report.childrenSelected === false
                          ? 'not-allowed'
                          : null,
                    }}
                  />
                  <GripLines
                    src={gripLines}
                    alt="grip"
                    data-image={`${report.value}-grip`}
                  />
                </>
              )
            ) : (
              <ReportSelected>
                {report.selected ? (
                  <img
                    src={checkCircle}
                    alt="check"
                    data-image={`${report.value}-checked`}
                  />
                ) : (
                  <TimesCircle
                    src={cancel}
                    alt="times"
                    data-image={`${report.value}-unselected`}
                  />
                )}
              </ReportSelected>
            )}
          </ReportActionContainer>
        </ReportRowContainer>
        <ReportExpandedContainer
          style={
            report.children && report.expanded && full && !loading
              ? { paddingBottom: '10px' }
              : null
          }
        >
          {report.children && report.expanded && !loading ? (
            <ReportExpandedContent>
              {full && (
                <SelectAllRow>
                  {report.value === 'targetmap' && report.cashflowAllowed && (
                    <>
                      <SelectAllText>Include Cashflow</SelectAllText>
                      <CheckboxItem
                        src={
                          report.cashFlowAllSelected ? checkboxGreen : square
                        }
                        data-image={`include-cf-checkbox-${
                          report.cashFlowAllSelected ? 'checked' : 'unchecked'
                        }`}
                        alt={report.cashFlowAllSelected ? 'checked box' : 'box'}
                        onClick={() => {
                          selectAllChildren(
                            report.value,
                            report.cashFlowAllSelected,
                            true
                          );
                        }}
                      />
                    </>
                  )}
                  {report.types && <LabelText>Insured</LabelText>}
                  <SelectAllText>Select All</SelectAllText>
                  <CheckboxItem
                    src={report.allSelected ? checkboxGreen : square}
                    alt={report.allSelected ? 'checked box' : 'box'}
                    data-image={`${report.value}-all-checkbox-${
                      report.allSelected ? 'checked' : 'unchecked'
                    }`}
                    onClick={() => {
                      selectAllChildren(report.value, report.allSelected);
                    }}
                  />
                </SelectAllRow>
              )}
              {report.children &&
                report.children.map((child, index) => {
                  return (
                    <ChildRow key={index}>
                      <ChildName
                        style={
                          child.selected ? { opacity: 1 } : { opacity: 0.4 }
                        }
                      >
                        {child.label}
                        {child.showAge && (
                          <MemberAge
                            style={
                              child.date_of_birth_estimated
                                ? { fontStyle: 'italic' }
                                : null
                            }
                          >
                            &#40;{child.age}
                            {child.date_of_birth_estimated && 'e'}
                            &#41;
                          </MemberAge>
                        )}
                      </ChildName>
                      {full ? (
                        <ChildActionContainer>
                          {report.value === 'targetmap' &&
                            !child.noCashflow && (
                              <CheckboxItemTM
                                src={child.cashFlow ? checkboxGreen : square}
                                alt={child.cashFlow ? 'checked box' : 'box'}
                                data-image={`tm-cf-checkbox-${
                                  report.cashFlow ? 'checked' : 'unchecked'
                                }`}
                                onClick={() => {
                                  selectReport(child.value, report.value, true);
                                }}
                                style={
                                  child.selected
                                    ? { opacity: 1 }
                                    : { opacity: 0.4 }
                                }
                              />
                            )}
                          <CheckboxItem
                            src={child.selected ? checkboxGreen : square}
                            alt={child.selected ? 'checked box' : 'box'}
                            onClick={() => {
                              selectReport(child.value, report.value);
                            }}
                            data-image={`${report.value}-child-checkbox-${
                              report.cashFlow ? 'checked' : 'unchecked'
                            }`}
                            // style={
                            //   child.selected ? { opacity: 1 } : { opacity: 0.4 }
                            // }
                          />
                          <DownloadIcon
                            src={download}
                            alt="download"
                            data-image={`${report.value}-download`}
                            onClick={
                              loading || !report.selected
                                ? null
                                : () => downloadReport(report)
                            }
                            hide={'true'}
                          />
                          <GripLines
                            src={gripLines}
                            alt="grip"
                            hide={'true'}
                            data-image={`${report.value}-grip`}
                          />
                        </ChildActionContainer>
                      ) : (
                        <ChildActionContainer style={{ display: 'none' }}>
                          <ReportSelected>
                            {child.selected ? (
                              <img
                                src={checkCircle}
                                alt="check"
                                data-image={`${report.value}-checked`}
                              />
                            ) : (
                              <TimesCircle
                                src={cancel}
                                alt="times"
                                data-image={`${report.value}-unselected`}
                              />
                            )}
                          </ReportSelected>
                        </ChildActionContainer>
                      )}
                    </ChildRow>
                  );
                })}
              {report.types && (
                <SelectAllRow>
                  <LabelText>Type</LabelText>
                  <SelectAllText>Select All Types</SelectAllText>
                  <CheckboxItem
                    src={report.allTypesSelected ? checkboxGreen : square}
                    alt={report.allTypesSelected ? 'checked box' : 'box'}
                    data-image={`${report.value}-all-types-checkbox-${
                      report.allTypesSelected ? 'checked' : 'unchecked'
                    }`}
                    onClick={() => {
                      selectAllChildren(
                        report.value,
                        report.allTypesSelected,
                        false,
                        true
                      );
                    }}
                  />
                </SelectAllRow>
              )}
              {report.types &&
                report.types.map((type, index) => {
                  return (
                    <ChildRow key={index}>
                      <ChildName
                        style={
                          type.selected ? { opacity: 1 } : { opacity: 0.4 }
                        }
                      >
                        {type.label}
                      </ChildName>
                      <ChildActionContainer>
                        <CheckboxItem
                          src={type.selected ? checkboxGreen : square}
                          alt={type.selected ? 'checked box' : 'box'}
                          data-image={`${report.value}-checkbox-${
                            report.allTypesSelected ? 'checked' : 'unchecked'
                          }`}
                          onClick={() => {
                            selectReport(type.value, report.value, false, true);
                          }}
                          // style={
                          //   type.selected ? { opacity: 1 } : { opacity: 0.4 }
                          // }
                        />
                        <DownloadIcon
                          src={download}
                          alt="download"
                          data-image={`${report.value}-download`}
                          onClick={
                            loading || !report.selected
                              ? null
                              : () => downloadReport(report)
                          }
                          hide={'true'}
                        />
                        <GripLines
                          src={gripLines}
                          alt="grip"
                          hide={'true'}
                          data-image={`${report.value}-grip`}
                        />
                      </ChildActionContainer>
                    </ChildRow>
                  );
                })}
            </ReportExpandedContent>
          ) : null}
        </ReportExpandedContainer>
      </ReportContainer>
    </ThemeProvider>
  );
};

const AMREntityOwnership = styled.div`
  margin: 0 20px 0 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const AMROrientationContainer = styled.div`
  margin: 0 20px 0 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const IncludeKFFContainer = styled.div`
  margin: 0 10px 0 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const SelectContainer = styled.div`
  flex: 1 1 auto;
  text-align: left;
  max-width: 85px;
  min-width: 85px;
  cursor: pointer;
`;

const ReportSelected = styled.span`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  img {
    width: 17px;
    height: 17px;
  }
`;

const ReportContainer = styled.div`
  width: 100%;
  color: ${colors.darkGrey};
  border-bottom: ${(props) =>
    props.theme.full
      ? `1px solid ${colors.paleGrey}`
      : `1px solid ${colors.lightGrey}`};
`;

const ReportRowContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 0;
`;

const ReportNameContainer = styled.div`
  @media ${maxDevice.mobileL} {
    font-size: 11px;
  }
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  flex: 1 1 auto;
`;

const ChevronIcon = styled.img`
  @media ${maxDevice.mobileL} {
    width: 9px;
    height: 9px;
  }
  width: 11px;
  height: 11px;
  opacity: 0.4;
  cursor: pointer;
`;

const ReportIcon = styled.img`
  @media ${maxDevice.mobileL} {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-left: 5px;
  }
  width: 18px;
  height: 18px;
  margin-right: 15px;
  margin-left: ${(props) => (props.theme.full ? '13px' : '10px')};
`;

const Required = styled.span`
  color: ${messageColors.error};
`;

const ReportActionContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  flex: 0 0 auto;
  img {
    &:hover {
      opacity: ${(props) => (props.theme.full ? '.8' : null)};
      transform: ${(props) => (props.theme.full ? 'scale(1.01)' : null)};
    }
  }
`;

const CheckboxItem = styled.img`
  @media ${maxDevice.mobileL} {
    width: 12px;
    height: 12px;
  }
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const TimesCircle = styled.img`
  opacity: 0.4;
  &:hover {
    opacity: 0.4;
  }
`;

const CheckboxItemTM = styled(CheckboxItem)`
  @media ${maxDevice.mobileL} {
    width: 12px;
    height: 12px;
    margin-right: 59px;
  }
  cursor: pointer;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 66px;
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const DownloadIcon = styled.img`
  @media ${maxDevice.mobileL} {
    width: 12px;
    height: 12px;
    display: ${(props) => (props.hide ? 'none' : null)};
  }
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-left: 13px;
  cursor: pointer;
  visibility: ${(props) => (props.hide ? 'hidden' : null)};
`;

const GripLines = styled.img`
  @media ${maxDevice.mobileL} {
    width: 12px;
    height: 12px;
    display: ${(props) => (props.hide ? 'none' : null)};
  }
  width: 14px;
  height: 18px;
  margin-right: 5px;
  opacity: 0.3;
  cursor: grab;
  visibility: ${(props) => (props.hide ? 'hidden' : null)};
`;

const ReportExpandedContainer = styled.div`
  @media ${maxDevice.mobileL} {
    padding-left: 20px;
  }
  padding-left: 55px;
  /* width: 100%; */
`;

const ReportExpandedContent = styled.div`
  @media ${maxDevice.mobileL} {
    font-size: 9px;
  }
  font-size: 12px;
`;

const ChildRow = styled.div`
  border-top: 1px solid ${colors.lightGrey};
  padding: ${(props) => (props.theme.full ? '6px 0' : `6px 0`)};
  color: ${colors.darkGrey};
  text-align: left;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const SelectAllRow = styled.div`
  @media ${maxDevice.mobileL} {
    padding: 8px 0 8px 0;
  }
  border-top: 1px solid ${colors.lightGrey};
  padding: 10px 57px 10px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

const LabelText = styled.div`
  flex: 1 1 auto;
  font-weight: ${fonts.bold};
`;

const SelectAllText = styled.span`
  @media ${maxDevice.mobileL} {
    font-size: 8px;
  }
  margin-right: 8px;
  margin-left: 15px;
  font-size: 10px;
  font-weight: ${fonts.semiBold};
`;

const ChildName = styled.div`
  flex: 1 1 auto;
`;

const MemberAge = styled.span`
  margin-left: 5px;
  color: ${colors.paleGrey};
`;

const ChildActionContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  img {
    &:hover {
      transform: scale(1.01);
      opacity: 0.8;
    }
  }
`;

ReportGenerateItem.propTypes = {
  report: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  last: PropTypes.bool,
  full: PropTypes.bool,
  expandItem: PropTypes.func,
  selectReport: PropTypes.func,
  downloadReport: PropTypes.func,
  selectAllChildren: PropTypes.func,
  loading: PropTypes.bool,
  amrEntityOwnership: PropTypes.object,
  setAmrEntityOwnership: PropTypes.func,
  amrOrientation: PropTypes.object,
  setAmrOrientation: PropTypes.func,
};

export default ReportGenerateItem;
