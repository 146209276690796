/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import {
  Button,
  Error,
  SmallTable,
  LoadingAnimation,
  DetectModalKeys,
} from '..';
import {
  colors,
  inputColors,
  maxDevice,
  messageColors,
  fonts,
} from '../../styles/variables';
import { timesWhite, search } from '../../assets';
import {
  CloseButton,
  ModalBackground,
  ModalOverlay,
} from '../../styles/library/modalStyles';
import { ButtonThemes } from '../../styles/themes';
import { DetectEnterKeyPress, UseOutsideClick } from '../../utils';

const SalesforceSearchModal = ({
  hide,
  searchType,
  searchValue,
  missingSearchValue,
  searchValueOnChange,
  searchFirstName,
  missingFirstName,
  searchFirstNameOnChange,
  searchLastName,
  missingLastName,
  searchLastNameOnChange,
  updateError,
  onEnter,
  error,
  disableClickOutside = true,
  marginTop,
  minWidth,
  results,
  selectResult,
  isLoadingResults,
}) => {
  const ref = useRef();

  UseOutsideClick(ref, () => {
    if (disableClickOutside) {
      return;
    }
    hide();
    if (updateError !== undefined) {
      updateError(false);
    }
  });

  useEffect(() => {
    onEnter();
  }, []);

  const hideFunction = () => {
    hide();
    if (updateError !== undefined) {
      updateError(false);
    }
  };

  const resultsHeadings = {
    household: [
      { text: 'Relationship Group', display: true },
      { text: 'Members', display: true, align: 'center' },
      { text: 'Modified', display: true, align: 'center' },
      { text: 'Actions', display: false },
    ],
    member: [
      { text: 'Name', display: true },
      { text: 'Type', display: true },
      { text: 'Status', display: true, align: 'center' },
      { text: 'DOB', display: true, align: 'left' },
      { text: 'Actions', display: false },
    ],
    entity: [
      { text: 'Name', display: true },
      { text: 'Type', display: true },
      { text: 'Status', display: true, align: 'center' },
      { text: 'Actions', display: false },
    ],
  };

  return ReactDOM.createPortal(
    <ThemeProvider
      theme={{
        marginTop: marginTop ? marginTop : '16vh',
      }}
    >
      <DetectModalKeys onEnter={onEnter} onEsc={hideFunction} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer
          role="dialog"
          ref={ref}
          style={{ minWidth: minWidth ? minWidth : '500px' }}
        >
          <ModalHeadingContainer>
            <ModalHeading>Search Salesforce</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hideFunction()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <SearchBarContainer>
              {searchType === 'member' ? (
                <>
                  <InputContainer style={{ marginRight: '10px' }}>
                    <SearchLabel>First Name</SearchLabel>
                    <SearchBoxContainer>
                      <SearchBox
                        type="text"
                        placeholder={`First Name`}
                        value={searchFirstName}
                        onChange={searchFirstNameOnChange}
                        onKeyPress={(e) => DetectEnterKeyPress(e, onEnter)}
                        style={{
                          marginRight: '10px',
                          border: missingFirstName
                            ? `1px solid ${messageColors.error}`
                            : null,
                        }}
                      />
                    </SearchBoxContainer>
                  </InputContainer>
                  <InputContainer>
                    <SearchLabel>Last Name</SearchLabel>
                    <SearchBoxContainer>
                      <SearchBox
                        type="text"
                        placeholder={`Last Name`}
                        value={searchLastName}
                        onChange={searchLastNameOnChange}
                        onKeyPress={(e) => DetectEnterKeyPress(e, onEnter)}
                        style={{
                          border: missingLastName
                            ? `1px solid ${messageColors.error}`
                            : null,
                        }}
                      />
                    </SearchBoxContainer>
                  </InputContainer>
                </>
              ) : (
                <InputContainer>
                  <SearchLabel>
                    {searchType === 'entity'
                      ? 'Name'
                      : 'Relationship Group Name'}
                  </SearchLabel>
                  <SearchBoxContainer>
                    <SearchBox
                      type="text"
                      placeholder={`Search ${
                        searchType === 'entity' ? 'Entities' : 'Relation Groups'
                      } by Name`}
                      value={searchValue}
                      onChange={searchValueOnChange}
                      onKeyPress={(e) => DetectEnterKeyPress(e, onEnter)}
                      style={{
                        border: missingSearchValue
                          ? `1px solid ${messageColors.error}`
                          : null,
                      }}
                    />
                  </SearchBoxContainer>
                </InputContainer>
              )}
              <SearchIconContainer onClick={onEnter}>
                <img src={search} alt="search" data-image="salesforce-search" />
              </SearchIconContainer>
            </SearchBarContainer>
            <ResultsContainer>
              {isLoadingResults ? (
                <LoadingContainer>
                  <LoadingAnimation />
                </LoadingContainer>
              ) : (
                <SmallTable
                  tableHeading={''}
                  columnHeadings={resultsHeadings[searchType]}
                  type={`align_${searchType}`}
                  items={results}
                  loaded={true}
                  marginBottom={'10px'}
                  editFunction={(e) => selectResult(e)}
                  customEmptyResults={true}
                />
              )}
            </ResultsContainer>
            {results && results.length !== 0 && (
              <ResultsCountContainer>
                {results.length} result{results.length !== 1 && 's'} found in
                Salesforce.
              </ResultsCountContainer>
            )}
            <ButtonsRowContainer>
              <div>
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
              </div>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {error && error !== '' && <Error errorMessage={error} />}
        </ModalContainer>
      </ModalOverlay>
    </ThemeProvider>,
    document.body
  );
};

const ResultsCountContainer = styled.p`
  font-size: 12px;
  color: ${colors.paleGrey};
  margin: 10px 0 -10px 0;
`;

const ResultsContainer = styled.div`
  text-align: left;
  max-height: 320px;
  overflow-y: auto;
  margin-top: 15px;
  table {
    thead {
      tr {
        th {
          font-size: 12px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 12px;
        }
      }
    }
  }
`;

const SearchBarContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

const InputContainer = styled.div`
  flex: 1 1 auto;
`;

const SearchBoxContainer = styled.div`
  @media ${maxDevice.tablet} {
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
  }
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  max-width: 500px;
`;

const SearchBox = styled.input`
  display: inline-block;
  padding: 8px 18px 8px 10px;
  font-size: 13px;
  flex: 1 1 auto;
  height: 20px;
  border-radius: 4px;
  border: 1px solid ${inputColors.border};
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const SearchIconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  top: 10px;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 18px;
  }
`;

const ModalContainer = styled.div`
  min-width: 280px;
  max-width: 320px;
  margin: 16vh 20px 20px 20px;
  margin-top: ${(props) => props.theme.marginTop};
  background: white;
  border-radius: 6px;
  text-align: center;
  z-index: 9999 !important;
`;

const ModalHeadingContainer = styled.div`
  padding: 12px 22px;
  background: ${colors.darkGrey};
  border-radius: 6px 6px 0 0;
  position: relative;
`;

const ModalHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  background: ${colors.darkGrey};
  color: ${colors.white};
  font-size: 15px;
`;

const ModalContentContainer = styled.div`
  padding: 15px 15px;
`;

const SearchLabel = styled.label`
  text-align: left;
  margin: 0 0 3px 3px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
  display: block;
`;

const ButtonsRowContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  div {
    width: 100px;
    button {
      width: 100%;
    }
  }
`;

SalesforceSearchModal.propTypes = {
  hide: PropTypes.func,
  searchType: PropTypes.string,
  searchValue: PropTypes.string,
  missingSearchValue: PropTypes.bool,
  searchValueOnChange: PropTypes.func,
  searchFirstName: PropTypes.string,
  missingFirstName: PropTypes.bool,
  searchFirstNameOnChange: PropTypes.func,
  searchLastName: PropTypes.string,
  missingLastName: PropTypes.bool,
  searchLastNameOnChange: PropTypes.func,
  updateError: PropTypes.func,
  onEnter: PropTypes.func,
  error: PropTypes.string,
  disableClickOutside: PropTypes.bool,
  marginTop: PropTypes.string,
  minWidth: PropTypes.string,
  results: PropTypes.array,
  selectResult: PropTypes.func,
  isLoadingResults: PropTypes.bool,
};

export default SalesforceSearchModal;
