/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Error, PermissionListItem } from '../../components';
import {
  updateCustomerScopes,
  updateUserStoreValue,
} from '../../store/actions';
import { colors, maxDevice } from '../../styles/variables';
import { ErrorThemes } from '../../styles/themes';
import {
  HeaderSubContent,
  TertiaryHeading,
} from '../../styles/library/fontStyles';
import { CapitalizeFirstLetter } from '../../utils';

const ManagerPortalPermissionsContainer = ({ customerId }) => {
  const dispatch = useDispatch();
  const { customerScopes, updateCustomerScopesError } = useSelector(
    (state) => ({
      customerScopes: state.user.customerScopes,
      updateCustomerScopesError: state.user.updateCustomerScopesError,
    })
  );
  const [permissionsList, setPermissionsList] = useState([]);
  const [loadedPermissions, setLoadedPermissions] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (customerScopes?.scopes) {
      setPermissionsList(customerScopes?.scopes);
      setLoadedPermissions(true);
      setErrorMessage();
      setIsUpdating(false);
    }
  }, [customerScopes?.scopes]);

  useEffect(() => {
    if (updateCustomerScopesError) {
      setIsUpdating(false);
      const errorData = updateCustomerScopesError?.data;
      let errorMsg = 'Error updating permissions';
      if (errorData) {
        let errors = [];
        for (let [key, value] of Object.entries(errorData)) {
          errors.push({ field: key, message: value });
        }
        errorMsg = errors.map((error) => {
          if (error.field === 'non_field_errors') {
            return `${error.message}`;
          }
          let fieldName = error.field.replaceAll('_', ' ');
          fieldName = CapitalizeFirstLetter(fieldName);
          return `${fieldName}: ${error.message}`;
        });
      }
      setErrorMessage(errorMsg);
      dispatch(updateUserStoreValue('updateCustomerScopesError', null));
    }
  }, [updateCustomerScopesError]);

  const permissionOnChange = (e) => {
    const updatedScopes = permissionsList.map((scope) => {
      if (e.name === scope.name) {
        scope.selected = !scope.selected;
      }
      return scope;
    });
    setPermissionsList(updatedScopes);
  };

  const onUpdate = () => {
    setIsUpdating(true);
    dispatch(updateCustomerScopes(customerId, { scopes: permissionsList }));
  };

  return (
    <ContainerLayout>
      <LeftContent>
        <HeadingContent>
          <TertiaryHeading>Permissions</TertiaryHeading>
          <HeaderSubContent $margin="0">
            Select which permissions to allow all advisors to have access to
            share.
          </HeaderSubContent>
        </HeadingContent>
        <PermissionsContainer>
          {loadedPermissions ? (
            permissionsList.length ? (
              permissionsList.map((permission, index) => {
                return (
                  !permission.hidden && (
                    <PermissionListItem
                      key={index}
                      permission={permission}
                      onChange={permissionOnChange}
                      showValue={true}
                      viewOnly={isUpdating}
                    />
                  )
                );
              })
            ) : (
              <EmptyDisplay>No permissions available</EmptyDisplay>
            )
          ) : (
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((index) => {
              return (
                <PermissionListItem
                  key={index}
                  isLoading={true}
                  showValue={true}
                />
              );
            })
          )}
        </PermissionsContainer>
        <ButtonBar>
          <Button
            text={'Update'}
            onClick={onUpdate}
            loadingText={'Updating'}
            showLoading={isUpdating}
          />
        </ButtonBar>
        {errorMessage && (
          <Error errorMessage={errorMessage} theme={ErrorThemes.inverted} />
        )}
      </LeftContent>
      <RightContent />
    </ContainerLayout>
  );
};

const ContainerLayout = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  margin: -25px 0px 0px 0px;
  background: ${colors.lighterGrey};
`;

const LeftContent = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
    flex: 1 1 auto;
  }
  @media ${maxDevice.sideNav} {
    padding: 20px 20px 100px 20px;
    overflow-x: hidden;
    min-height: 500px;
  }
  flex: 1 1 40%;
  min-height: 800px;
  /* max-width: 50%;
  min-width: 50%; */
  background: white;
  padding: 20px 40px 100px 40px;
`;

const RightContent = styled.div`
  @media ${maxDevice.tablet} {
    width: 100%;
    flex: 1 1 auto;
  }
  flex: 1 1 45%;
`;

const EmptyDisplay = styled.p`
  padding: 20px 0;
  color: ${colors.paleGrey};
`;

const ButtonBar = styled.div`
  margin: 20px 0 15px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-end;
`;

const PermissionsContainer = styled.div`
  text-align: left;
`;

const HeadingContent = styled.div`
  border-bottom: 1px solid ${colors.darkGrey};
  padding: 5px 0 15px 0;
  margin-bottom: 5px;
`;

ManagerPortalPermissionsContainer.propTypes = {
  customerId: PropTypes.string,
};

export default ManagerPortalPermissionsContainer;
