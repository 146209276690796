import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ checkIsDelegate = true, children }) => {
  const { loggedIn, isDelegate, isConsumer } = useSelector((state) => ({
    loggedIn: state.user.loggedIn,
    isDelegate: state.user.isDelegate,
    isConsumer: state.user.isConsumer,
  }));

  if (loggedIn === true) {
    if (checkIsDelegate && isDelegate && !isConsumer) {
      return <Navigate to="member/dashboard" />;
    } else {
      return children;
    }
  } else if (loggedIn === false) {
    return <Navigate to="/" />;
  } else {
    return null;
  }
};

PrivateRoute.propTypes = {
  checkIsDelegate: PropTypes.bool,
  children: PropTypes.object,
};

export default PrivateRoute;
